<template>
  <AdsListHelper :adType="adType" :userId="userId"> </AdsListHelper>
</template>

<script>
import AdsListHelper from "./AdsListHelper";
import Config from "../config.js";

export default {
  name: "UserAds",
  components: {
    AdsListHelper,
  },
  props: [],
  metaInfo: {
    title: "Mobilility Guru Ads",
    //titleTemplate: "%s | Public Ads",
    meta: [
      { name: "description", content: "Mobility Guru Live User Ads" },
      { name: "keywords", content: "advertisements, ads" },
    ],
  },
  data: () => ({
    prevRoute: null,
    adType: "",
    userId: "",
  }),

  mounted: async function () {
    this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  /* beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },*/

  methods: {
    init() {
      //this.userId = this.$route.query.userId;
      this.adType = Config.AD_TYPES_ENUM.USER_ADS;
      if (this.$route.query.userId !== undefined) {
        this.userId = this.$route.query.userId;
      } else {
        this.userId = "";
      }

      if (this.$store.state.isSuperAdmin === true) {
        this.debug("routed to user-ads for superadmin");
      } else if (this.$store.state.isCountrymanager === true) {
        this.debug("routed to user-ads for countrymanager");
      } else if (this.$store.state.isSales === true) {
        this.debug("routed to user-ads for countrymanager");
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },
  }, // methods
};
</script>
