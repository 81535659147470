<template>
  <v-card class="mx-auto" elevation="0" color="transparent" min-height="300">
    <v-progress-linear
      class="ma-1"
      indeterminate
      v-show="isChatScreenLoading"
    ></v-progress-linear>
    <v-row no-gutters justify="center" :key="chatBuildKey">
      <v-col
        v-if="showChatsSection"
        :cols="
          $vuetify.breakpoint.xs
            ? 12
            : $vuetify.breakpoint.sm
            ? 12
            : $vuetify.breakpoint.md
            ? 4
            : 3
        "
      >
        <div>
          <v-list-item class="mb-n3" dense v-if="channels.length > 0">
            <v-list-item-title>
              <h3 class="ma-1">
                {{ $t(`chats`) }}
                <span
                  v-show="$store.state.unreadChannels > 0"
                  class="green rounded px-1"
                >
                  {{ $store.state.unreadChannels }}</span
                >
              </h3>
            </v-list-item-title>

            <v-list-item-action>
              <v-menu bottom left transition="slide-x-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon small color="black">{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="menuClicked(i)"
                    v-for="(action, i) in menuActions"
                    :key="i"
                  >
                    <v-list-item-title>{{ $t(action) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <h4 v-else class="ma-3" v-show="!isChatScreenLoading">
            {{ $t(`noChat`) }}
          </h4>
          <!-- <ul class="list-group list-group-flush">
            <li
              @click="selectChat(channel.data.id)"
              class="list-group-item"
              
              :key="id"
            >
              {{ channel.data.name }}
            </li>
          </ul>-->

          <v-virtual-scroll
            :key="channelsBuildKey"
            class="ma-1"
            :items="channels"
            :height="screenHeight - 160"
            item-height="64"
          >
            <template v-slot:default="{ item }"
              ><v-divider class="pa-0"></v-divider>
              <!-- <template v-for="(channel, id) in channels">-->
              <!--  <v-subheader
          v-if="item.header"
          :key="item.header"
          v-text="item.header"
        ></v-subheader>

        <v-divider
          v-else-if="item.divider"
          :key="index"
          :inset="item.inset"
        ></v-divider>-->

              <v-list-item
                @click="selectChat(item)"
                dense
                :key="item.cid"
                v-show="item !== null || item !== undefined"
              >
                <v-list-item-avatar tile class="rounded">
                  <v-img
                    v-if="'image' in item && item.data.image.length > 0"
                    :src="item.image"
                  ></v-img>
                  <v-icon v-else>{{ mdiAccount }}</v-icon>
                  <!--   <v-img :src="channel.data.image"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    :class="
                      channel !== null && item.id == channel.id
                        ? 'green--text subtitle-1'
                        : 'transparent subtitle-1'
                    "
                    >{{ getChatOpponentName(item) }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="caption">
                    <v-row justify="end" no-gutters class="mt-1">
                      <!-- <span v-show="userId !== item.data.created_by.id">{{
                        item.data.created_by.name
                      }}</span>-->
                      <v-spacer></v-spacer>
                      {{ calculateDaysLocal(item.lastMessageTime) }}
                    </v-row></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action
                  v-show="
                    userId in item.info &&
                    'unread' in item.info[userId] &&
                    item.info[userId]['unread'] > 0
                  "
                >
                  <span class="black--text blue rounded px-1 caption">
                    {{ item.info[userId]["unread"] }}</span
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <hr />
          <v-row justify="center" no-gutters>
            <v-pagination
              v-model="chatsPageNumber"
              class=""
              :length="loadedNumberOfPages"
            >
            </v-pagination>

            <!-- <v-btn x-small v-show="chatsPageNumber > 0" @click="loadChatsPage(0)"
              >First
            </v-btn>
            <v-spacer></v-spacer>
            <v-list-item-title>
              <v-container>
                <v-tabs
                  hide-slider
                  v-show="loadedNumberOfPages > 1"
                  v-model="chatsPageNumber"
                >
                  <v-card
                    height="20"
                    width="20"
                    :style="` width: 50px !important; height: 50px !important;
                    `"
                    v-for="item in loadedNumberOfPages"
                    :key="item"
                  >
                    {{ item }}
                  </v-card>
                </v-tabs>
              </v-container>
            </v-list-item-title>
            <v-spacer> </v-spacer>
            <v-btn x-small @click="loadChatsPage()" v-show="showNextPageButton">
              Next
            </v-btn>-->
          </v-row>
        </div>
      </v-col>

      <v-col
        class="pa-1"
        :cols="
          $vuetify.breakpoint.xs
            ? 12
            : $vuetify.breakpoint.sm
            ? 12
            : $vuetify.breakpoint.md
            ? 8
            : 9
        "
      >
        <v-card
          v-show="hasJoinedChat"
          v-if="channel !== null"
          :max-height="screenHeight"
        >
          <v-toolbar dense class="" elevation="0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-3"
                  v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectChat"
                  color="green"
                  >{{ mdiMessageBulleted }}</v-icon
                >
              </template>
              <span>{{ $t(`view`) }}</span>
            </v-tooltip>

            <v-toolbar-title>
              <v-col>
                <span v-if="channel !== null">
                  {{ channel.info[getOtherMemberId(channel)].name }}
                </span>
                <!-- <v-card
                  class="caption"
                  elevation="0"
                  color="transparent"
                  v-for="(member, index) in Object.keys(channel.info)"
                  :key="index"
                  ><span v-if="member !== undefined" v-show="member != userId">
                    {{ channel[member].name }}</span
                  ></v-card
                >-->
              </v-col>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!--<v-btn
                text
                x-small
                v-show="'invoiceId' in channel.data"
                @click="viewInvoice(channel.data.invoiceId)"
              >
                Invoice
              </v-btn>-->
              <v-menu
                bottom
                left
                transition="slide-x-transition"
                v-show="this.$store.state.isSuperAdmin == true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon small color="black">{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- <v-list-item
                    v-show="!channelFrozen"
                    @click="goToAd(channel.data.adId, 1)"
                  >
                    <v-list-item-title> View Ad </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="'invoiceId' in channel.data"
                    @click="viewInvoice(channel.data.invoiceId)"
                  >
                    <v-list-item-title> View Invoice </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-show="
                      'invoiceId' in channel.data == false && userId == channel.data.owner
                    "
                    @click="createNewInvoice(channel.data.adId)"
                  >
                    <v-list-item-title> Create Invoice </v-list-item-title>
                  </v-list-item>-->
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>
          <hr />
          <v-progress-linear
            indeterminate
            v-show="moreMessagesLoading"
          ></v-progress-linear>
          <v-card
            id="chatScroll"
            :height="screenHeight - 240"
            class="flex-grow-1 overflow-y-auto"
          >
            <template>
              <v-col
                v-for="(item, index) in messages"
                :key="index"
                class="pa-0"
              >
                <v-row
                  v-show="item.text.length > 0"
                  class="pr-1 pl-1"
                  no-gutters
                  :justify="item.from == userId ? 'end' : 'start'"
                >
                  <v-card
                    min-width="90"
                    class="rounded mb-n3 pr-2 pl-2 pb-2"
                    :color="
                      item.from === userId
                        ? 'cyan darken-2'
                        : 'light-blue darken-1'
                    "
                  >
                    <span class="white--text" v-html="item.text"> </span>
                  </v-card>
                </v-row>
                <v-row
                  class="pr-2 pl-2"
                  :justify="item.from === userId ? 'end' : 'start'"
                  no-gutters
                >
                  <span
                    style="font-size: 10px; position: relative; bottom: 2px"
                    class=""
                  >
                    {{ calculateDaysLocal(item.time) }}</span
                  >
                </v-row>
                <v-row
                  class="ml-3 mb-3 mr-3"
                  no-gutters
                  v-show="item.type == 'invoice-inquiry' && userId == item.to"
                  :justify="item.from === userId ? 'end' : 'start'"
                >
                  <a @click="createNewInvoice(item.adId)">{{ $t(`create`) }}</a>
                </v-row>

                <v-row
                  class="ml-3 mb-3 mr-3"
                  no-gutters
                  v-show="item.type == 'invoice-update'"
                  :justify="item.from === userId ? 'end' : 'start'"
                >
                  <a @click="viewInvoice(item.invoiceId)" class="ml-2 mr-2">
                    {{ $t(`viewInvoice`) }}</a
                  >
                  <a @click="goToAd(item.adId)" class="ml-2 mr-2">
                    {{ $t(`viewAd`) }}</a
                  >
                </v-row>

                <v-row
                  class="ml-3 mb-3 mr-3"
                  no-gutters
                  v-show="item.type == 'inquiry'"
                  :justify="item.from === userId ? 'end' : 'start'"
                >
                  <a @click="goToAd(item.adId)"> {{ $t(`viewAd`) }}</a>
                </v-row>
              </v-col>
            </template>
          </v-card>
          <a
            @click="scrollToBottomOfChat"
            v-show="showGoToBottom"
            class="chat-bottom"
          >
            <v-card
              class="rounded"
              outlined
              width="28px"
              height="30px"
              color="black"
            >
              <v-icon color="white">{{ mdiChevronDoubleDown }}</v-icon>
            </v-card>
          </a>
          <hr />

          <form @submit.prevent="sendMessage" method="post" class="">
            <v-row
              class="mt-n2"
              no-gutters
              justify="start"
              v-show="showMessageChips"
            >
              <v-col>
                <v-sheet height="40" elevation="0" class="px-1">
                  <v-chip-group
                    v-model="selectedMessage"
                    show-arrows
                    active-class="primary--text"
                  >
                    <v-chip
                      @click="newMessage = tag"
                      v-for="tag in tags"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-sheet>
              </v-col>
            </v-row>

            <span class="caption red--text" v-show="channelFrozen">{{
              $t(`warning`)
            }}</span>
            <v-row no-gutters class="ma-1">
              <v-col>
                <v-textarea
                  :disabled="channelFrozen"
                  autofocus
                  ref="newMessage"
                  outlined
                  label="Type your message here"
                  v-model="newMessage"
                  background-color="fillColour"
                  counter="5000"
                  :rules="[rules.description5000]"
                  :rows="$vuetify.breakpoint.xs ? 1 : 3"
                ></v-textarea>
              </v-col>
              <div class="input-group-append pa-1">
                <v-row no-gutters class="">
                  <my-btn
                    v-show="!$vuetify.breakpoint.xs"
                    :disabled="newMessage.length <= 0 || channelFrozen"
                    :iconName="mdiSend"
                    @btn-clicked="sendMessage"
                    btnName="SEND"
                  ></my-btn>
                  <v-icon
                    :disabled="newMessage.length <= 0 || channelFrozen"
                    @click="sendMessage"
                    color="green"
                    v-show="$vuetify.breakpoint.xs"
                    >{{ mdiSend }}</v-icon
                  >
                </v-row>
              </div>
            </v-row>
          </form>
        </v-card>
        <v-card
          color="transparent"
          elevation="0"
          :max-height="screenHeight"
          v-show="
            !hasJoinedChat &&
            !($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)
          "
        >
          <v-row
            justify="center"
            v-show="!isChatScreenLoading && channels.length > 0"
            no-gutters
            class="ma-10"
          >
            {{ $t(`select_chat`) }}
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar color="orange" centered timeout="-1" v-model="showSnackbar">
      <span class="black--text" v-html="snackbarMessage"></span>

      <template v-slot:action>
        <v-btn @click="showSnackbar = false">{{ $t(`close`) }}</v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<!--<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.1.5/css/uikit.min.css";
</style>-->

<script>
//import { StreamChat } from "stream-chat";
import Config from "../config";
import {
  mdiMessage,
  mdiAccount,
  mdiCard,
  mdiMessageBulleted,
  mdiSend,
  mdiEmoticon,
  mdiAttachment,
  mdiDotsVertical,
  mdiChevronDoubleDown,
} from "@mdi/js";
import MyBtn from "./MyBtn.vue";

export default {
  components: {
    MyBtn,
  },
  name: "ChatScreen",
  metaInfo: {
    title: "Messages | Mobility Guru",
    meta: [
      {
        name: "description",
        content:
          "Ask for help, help other members or request new website features.",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  data() {
    return {
      mdiMessage: mdiMessage,
      mdiAccount: mdiAccount,
      mdiCard: mdiCard,
      mdiMessageBulleted: mdiMessageBulleted,
      mdiEmoticon: mdiEmoticon,
      mdiSend: mdiSend,
      mdiAttachment: mdiAttachment,
      mdiDotsVertical: mdiDotsVertical,
      mdiChevronDoubleDown: mdiChevronDoubleDown,
      screenHeight: 500,
      selectedChannelId: "",
      hasJoinedChat: false,
      token: "",
      userId: "",
      user: "",
      channel: null,
      channelListener: null,
      messages: [],
      newMessage: "",
      members: [],
      channelId: "",
      adId: "",
      query: {},
      channels: [],
      showChats: true,
      isChatScreenLoading: false,
      showSnackbar: false,
      snackbarMessage: "",
      STREAM_CHATS_PER_SCREEN: Config.STREAM_CHATS_PER_SCREEN,
      STREAM_CHATS_OFFSET: Config.STREAM_CHATS_OFFSET,
      STREAM_MESSAGES_PER_SCREEN: Config.STREAM_MESSAGES_PER_SCREEN,
      chatsPageNumber: 1,
      messagesPageIndex: 0,
      showNextPageButton: false,
      loadedNumberOfPages: 1,
      moreMessagesAvailable: true,
      moreMessagesLoading: false,
      scrollHeight: 0,
      menuActions: ["Mark all as read" /*"Refresh"*/],
      showGoToBottom: false,
      scrolledValue: 0,
      channelFrozen: false,
      channelsBuildIndex: 0,
      channelsBuildKey: "channelsBuildKey0",
      chatBuildIndex: 0,
      chatBuildKey: "chatBuildKey0",

      tags: [
        "Is this still available?",
        "Hi there!",
        "Hi, I would like to request you an invoice for this ad ?",
        /* "How are you?",
        "How much?",*/
      ],
      showMessageChips: false,
      selectedMessage: 0,

      rules: {
        required: (value) => value.length > 0 || "Required.",
        positive: (value) =>
          (typeof value !== "undefined" &&
            (value.length == 0 || parseFloat(value) > 0)) ||
          "Must be larger than 0",
        counter30: (value) =>
          value.length <= Config.TITLE_MAX ||
          "Max " + Config.TITLE_MAX + " characters",
        email: (value) => {
          return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
        },
        email345: (value) =>
          value.length <= Config.EMAIL_MAX ||
          "Max " + Config.EMAIL_MAX + " characters",
        description5000: (value) =>
          value.length <= Config.STREAM_MESSSAGE_MAX || "Max 5000 characters",
      },
    };
  },

  mounted: async function () {
    this.isChatScreenLoading = true;
    if (await this.isLoggedInOIDC(true)) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
    this.isChatScreenLoading = false;
  },

  watch: {
    async $route() {
      this.isChatScreenLoading = true;
      await this.initializeChat();
      this.isChatScreenLoading = false;
      if ("channelId" in this.$route.query) {
        this.scrollToBottomOfChat();
      }
    },

    async chatsPageNumber() {
      this.isChatScreenLoading = true;
      this.debug(this.chatsPageNumber);
      await this.filterChannelsByUserId();
      this.isChatScreenLoading = false;
    },

    "$store.state.newMessage": async function () {
      // if (messageObj.from == this.userId) {
      let messageObj = this.$store.state.newMessage;
      this.printJson(messageObj, "messageObj broadcated in chat screen");

      this.channels = this.getChatsLocal();

      if (this.hasJoinedChat == true && this.channel.id == messageObj.chatId) {
        for (let i = 0; i < this.channels.length; i++) {
          if (this.channels[i].id == this.channel.id) {
            this.channel = this.channels[i];
            break;
          }
        }
        this.messages.push(messageObj);
        await this.synchSetTimeout(100);
        this.scrollToBottomOfChat();

        await this.synchSetTimeout(500);

        this.markChatsAsRead(this.query.channelId);
      }

      //get the channels from main.js
      this.rebuildChannelsList();
    },

    //This is a duplicated task now with subscrib listender of chatMessage
    "$store.state.unreadMessages": async function () {
      this.channels = this.getChatsLocal();
      this.rebuildChannelsList();
      // if (this.showChatsSection == true) {
      //   this.isChatScreenLoading = true;
      // await this.filterChannelsByUserId();
      //  this.isChatScreenLoading = false;
      //}
    },
  },

  created() {
    window.addEventListener("resize", this.changeWidth);

    //This will stop watching the channel and disconnect the user
    window.addEventListener("beforeunload", this.leaving);
  },

  destroyed() {
    //this.$socket.$unsubscribe("chatMessage");
    //this.$socket.$unsubscribe("AllChatsMarkedAsRead");

    this.leaving();
    window.removeEventListener("resize", this.changeWidth);
  },

  computed: {
    showChatsSection() {
      this.printJson(this.$route.query, "showChatsSection()");
      let showChat = true;
      let query = this.$route.query;
      this.debug(
        `${query !== undefined} - ${
          Object.keys(query).length > 0
        }  == ${Object.keys(query)}`
      );
      if (query !== undefined && Object.keys(query).length > 0) {
        if (
          this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm"
        ) {
          showChat = false;
        } else {
          showChat = true;
        }
      } else {
        showChat = true;
      }
      this.debug(`${showChat}`);
      return showChat;
    },
  },

  methods: {
    async init() {
      this.moreMessagesLoading = false;
      this.userId = this.$store.state.accessTokenDecoded.sub;
      //Initialize the list of chats user interacted with
      await this.filterChannelsByUserId();

      await this.initializeChat();

      this.changeWidth();
      if ("channelId" in this.query) {
        //await this.synchSetTimeout(100)
        this.scrollToBottomOfChat();
      }

      /*this.$socket.$subscribe("chatMessage", async (messageObj) => {
       
      });*/

      /*this.$socket.$subscribe("AllChatsMarkedAsRead", async (userId) => {
        if (userId == this.$store.state.accessTokenDecoded.sub) {
          await this.filterChannelsByUserId();
        }
      });*/
    },

    async initializeChat() {
      this.moreMessagesAvailable = true;
      this.newMessage = "";
      this.showGoToBottom = false;
      //this.channel = null;
      this.messages = [];
      let channelIdexist = false;
      this.query = this.$route.query;
      this.hasJoinedChat = false;
      this.channel = null;
      this.channelId = "";
      //      this.userId = this.$store.state.accessTokenDecoded.sub;
      this.printJson(this.query, "query of path");
      if ("channelId" in this.query) {
        for (let i = 0; i < this.channels.length; i++) {
          if (this.channels[i].id == this.query.channelId) {
            this.channel = this.channels[i];
            channelIdexist = true;
            break;
          }
        }
        if (channelIdexist == false) {
          await this.filterChannelsByUserId();
          for (let i = 0; i < this.channels.length; i++) {
            if (this.channels[i].id == this.query.channelId) {
              this.channel = this.channels[i];
              channelIdexist = true;
              break;
            }
          }
        }

        if (channelIdexist) {
          //this.messages = (await this.channel.watch()).messages;
          this.messages = await this.getMessagesByChatId(this.query.channelId);
          // this.members = (await this.channel.watch()).members;
          //// this.channelFrozen = this.channel.data.frozen;

          this.printJson(this.messages);
          //this.printJson(this.members, "members");
          //this.printJson(this.channel.data, "channel data");
          this.hasJoinedChat = true;
          /*this.channelListener = this.channel.on("message.new", async (event) => {
            this.printJson(event, "new message");
            this.messages.push(
              event.message

            );

            this.channelFrozen = this.channel.data.frozen;
            await this.synchSetTimeout(100);
            this.scrollToBottomOfChat();
            if (this.showMessageChips == true) {
              this.showMessageChips = false;
            }
          });*/

          var scrolledDiv = document.getElementById("chatScroll");
          let self = this;
          scrolledDiv.onscroll = function () {
            /*console.log(
                scrolledDiv.scrollTop.toString() + self.moreMessagesLoading
              );*/
            self.scrolledValue =
              scrolledDiv.scrollHeight - scrolledDiv.scrollTop;
            // self.debug(self.scrolledValue + " ddsadasda");
            if (self.scrolledValue > 1500 && scrolledDiv.scrollHeight > 1000) {
              self.showGoToBottom = true;
            } else {
              self.showGoToBottom = false;
            }
            if (scrolledDiv.scr)
              if (
                scrolledDiv.scrollTop < 400 &&
                scrolledDiv.scrollTop > 0 &&
                self.moreMessagesLoading == false
              ) {
                self.scrollHeight = scrolledDiv.scrollTop;

                self.loadMoreMessages();
              }
          };

          //if hash of invoice-request exist
          if (this.$route.hash == "#request-invoice") {
            this.newMessage = this.tags[2];
            await this.sendMessage();
            //  let update = { set: { invoiceRequested: true } };
            //   this.channel.updatePartial(update);
            /*} else {
              this.debug("show snackbar on already requested");
              this.snackbarMessage = "Invoice requested already";
              this.showSnackbar = true;
            }*/
          }

          if (this.$route.hash == "#inquiry") {
            this.showMessageChips = true;
            this.newMessage = this.tags[this.selectedMessage];
          } else {
            this.showMessageChips = false;
          }

          this.markChatsAsRead(this.query.channelId);
        }

        // this.channel = channel;
      } else {
        this.hasJoinedChat = false;
      }

      this.rebuildChat();
      this.scrollToBottomOfChat();
    },

    async sendMessage() {
      if (
        this.newMessage.length > 0 &&
        this.newMessage.length <= Config.STREAM_MESSSAGE_MAX
      ) {
        this.debug(this.channel);
        /*await this.channel.sendMessage({
          text: this.newMessage,
        });*/

        let messageObj = {
          text: this.newMessage,
          from: this.userId,
          chatId: this.channel.id,
          type: "text",
        };

        if (
          this.$route.hash == "#request-invoice" &&
          "adId" in this.$route.query
        ) {
          messageObj["type"] = "invoice-inquiry";
          messageObj["adId"] = this.$route.query.adId;
        } else if (
          this.$route.hash == "#inquiry" &&
          "adId" in this.$route.query
        ) {
          if (this.newMessage == this.tags[2]) {
            messageObj["type"] = "invoice-inquiry";
          } else {
            messageObj["type"] = "inquiry";
          }
          messageObj["adId"] = this.$route.query.adId;
        }

        for (let i = 0; i < this.channel.members.length; i++) {
          if (this.channel.members[i] != this.userId) {
            messageObj["to"] = this.channel.members[i];
            break;
          }
        }
        this.printJson(messageObj, "message to be sernt");
        this.$socket.client.emit("newMessage", messageObj);
        this.newMessage = "";

        if (
          this.$route.hash == "#inquiry" ||
          this.$route.hash == "#request-invoice"
        ) {
          let query = {
            channelId: this.channel.id,
          };
          await this.pushRoute(Config.ROUTE_MESSAGES, query, "");
          if (this.showMessageChips == true) {
            this.showMessageChips = false;
          }
        }
      } else {
        this.snackbarMessage = "Invalid message";
        this.showSnackbar = true;
      }

      this.$refs.newMessage.focus();
    },

    async filterChannelsByUserId() {
      this.userId = this.$store.state.accessTokenDecoded.sub;

      this.channels = await this.getChatsFromBackend();
      this.rebuildChannelsList();
      if (
        this.channels.length == this.STREAM_CHATS_PER_SCREEN &&
        this.channels !== undefined
      ) {
        //show go to next page button
        this.showNextPageButton = true;

        if (this.chatsPageNumber >= this.loadedNumberOfPages) {
          this.loadedNumberOfPages = this.chatsPageNumber + 1;
        }
      } else {
        if (this.chatsPageNumber >= this.loadedNumberOfPages) {
          this.loadedNumberOfPages = this.chatsPageNumber;
        }
      }
    },

    async selectChat(channel) {
      let query = {};
      this.moreMessagesAvailable = true;
      //if (this.channel !== null && this.channel !== undefined) {
      // await this.channel.stopWatching();
      // }
      if (channel !== undefined && channel.id != undefined) {
        query = {
          channelId: channel.id,
        };
      }

      await this.pushRoute(Config.ROUTE_MESSAGES, query);
    },

    changeWidth() {
      this.screenHeight = window.innerHeight - 100;
      /*  if(this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm'){
        showChat = false;
      }else{
        this.showChats = true;
      }*/
      //this.getScroll();
    },

    async leaving() {
      /*  if (this.$store.state.streamConnected == true) {
        if (this.channel !== null && this.channel !== undefined) {
          await this.channel.stopWatching();
        }
        if (this.channelListener !== null && this.channelListener !== undefined) {
          this.channelListener.unsubscribe();
        }
      }*/
      // await this.disconnectStream();
    },

    async scrollToBottomOfChat() {
      // await this.synchSetTimeout(1);
      if ("channelId" in this.$route.query) {
        let chatElement = this.$el.querySelector("#chatScroll");
        chatElement.scrollTop = chatElement.scrollHeight;
      }
    },

    getStringOfChannelName(stringObj, key) {
      if (stringObj[0] == "{") {
        // console.log(typeof JSON.parse(stringObj));
        return JSON.parse(stringObj)[key];
      } else {
        return stringObj;
      }
      //return JSON.parse(stringObj).name;
    },

    async goToAd(adId, adType) {
      await this.gotoAdView(adId, adType);
    },

    calculateDaysLocal(date) {
      //this.debug(`calculateDaysLocal ${Date(date)}`);
      //date = date.toString();
      //this.debug(date !== undefined && date.length > 0)
      if (date !== undefined) {
        let label = "";
        //let present_date = Date.now();
        //let createdDate = Date.parse(date);

        //this.debug(this.isDateToday(date));
        //this.debug("isDateToday");
        // let days = (present_date - createdDate) / (1000 * 3600 * 24);
        // let hours = days * 24;
        let created_year = new Date(date).getFullYear();

        //this.debug(`created yeaer ${created_year}`);
        let current_year = new Date().getFullYear();
        //this.debug(created_year + " + " + current_year);
        let minutesDigits = new Date(date).getMinutes();
        if (minutesDigits.toString().length == 1) {
          minutesDigits = `0${minutesDigits}`;
        }

        let hoursDigits = new Date(date).getHours();
        if (hoursDigits.toString().length == 1) {
          hoursDigits = `0${hoursDigits}`;
        }
        /*if (hours < 1) {
          let numOfMins = Math.floor(hours * 60);
          this.debug(numOfMins);
          if (numOfMins < 1) {
            label = `Just now`;
          } else {
            label = `${numOfMins} minutes ago`;
          }
        } else if (days < 1) {
          let numOfHours = Math.floor(days * 24);
          this.debug(numOfHours);
          if (numOfHours == 1) {
            label = `${numOfHours} hour ago`;
          } else {
            label = `${numOfHours} hours ago`;
          }
        }*/ if (this.isDateToday(date) == true) {
          label = `${hoursDigits}:${minutesDigits}`;
        } else {
          label = `${hoursDigits}:${minutesDigits}, ${
            Config.MONTH_NAMES[new Date(date).getMonth()]
          } ${new Date(date).getDate()}`;
          if (current_year != created_year) {
            label += `, ${new Date(date).getFullYear()}`;
          }
          //let numOfDays = Math.floor(days);

          // this.debug(days);
          //this.debug(`Number of days${numOfDays}`);
          /*label = `${numOfDays} days ago`;*/
        }
        //this.debug(`calculateDaysLocal`);
        // this.debug(label);

        return label;
      }
    },

    /*loadChatsPage(index) {
      if (this.channels.length == this.STREAM_CHATS_PER_SCREEN) {
        this.debug("load more" + index);
        if (index !== undefined) {
          this.chatsPageNumber = index;
        } else {
          this.chatsPageNumber++;
        }
        this.filterChannelsByUserId();

        if (this.chatsPageNumber > this.loadedNumberOfPages) {
          this.loadedNumberOfPages = this.chatsPageNumber;
        }
        this.debug(this.loadedNumberOfPages);
        this.debug(this.chatsPageNumber);
        this.debug("finished");
      } else {
        this.debug("no pages available");
      }
    },

    */

    async loadMoreMessages() {
      if (this.moreMessagesAvailable == true) {
        this.moreMessagesLoading = true;
        //this.debug("loadMoreMssages");
        const lastMessageId = this.messages[0].id;

        // pick the parts you need; messages, members or watchers
        const result = await this.channel.query({
          messages: {
            limit: this.STREAM_MESSAGES_PER_SCREEN,
            id_lt: lastMessageId,
          },
          // members: { limit: 20, offset: 0 } ,
          // watchers: { limit: 20, offset: 0 },
        });
        this.printJson(result.messages.length, "dsdsds");
        const newMesages = result.messages;
        if (newMesages.length == this.STREAM_MESSAGES_PER_SCREEN) {
          this.moreMessagesAvailable = true;
        } else {
          this.moreMessagesAvailable = false;
        }
        if (newMesages.length > 0) {
          const joinedMessages = newMesages.concat(this.messages);
          this.messages = joinedMessages;
          //this.debug("scroll height");
          //this.debug(this.scrollHeight);
          var scrolledDiv = document.getElementById("chatScroll");
          scrolledDiv.scrollTo(0, this.scrollHeight);
          //  var lastMessageById = this.$el.querySelector(`#${lastMessageId}`);
          //  lastMessageById.scrollIntoView();
        }
        this.moreMessagesLoading = false;
      } else {
        this.debug("no more messages");
      }
    },

    getOtherMembers() {
      let membersString = "";
      for (let i = 0; this.members.length; i++) {
        if (this.members[i].user_id !== this.userId) {
          membersString += `${this.members[i].user.name} `;
        }
      }

      return membersString;
    },

    getOtherMemberId(chat) {
      let memberId = "";

      for (let i = 0; i < chat.members.length; i++) {
        if (chat.members[i] != this.userId) {
          memberId = chat.members[i];
        }
      }

      return memberId;
    },

    getChatOpponentName(chat) {
      let memberId = this.getOtherMemberId(chat);
      return chat["info"][memberId].name;
    },

    async menuClicked(index) {
      this.debug(index);
      if (index == 0) {
        //await this.markAllAsRead();

        this.$socket.client.emit("markAllAsRead", this.userId);
      } else {
        this.debug("refresh");
        await this.init();
      }
    },

    async viewInvoice(invoiceId) {
      this.debug(invoiceId);
      await this.pushRoute(Config.ROUTE_INVOICE, {
        id: invoiceId,
      });
    },

    async createNewInvoice(adId) {
      let buyerId = this.getOtherMemberId(this.channel);
      await this.pushRoute(Config.ROUTE_SUBMIT_INVOICE, {
        adId: adId,
        buyerId: buyerId,
      });
    },

    async markChatsAsRead(chatId) {
      this.debug(chatId + "markChatsAsRead");

      if (
        "info" in this.channel &&
        this.channel["info"][this.userId]["unread"] > 0
      ) {
        this.channel["info"][this.userId]["unread"] = 0;

        let data = {
          chatId: chatId,
          userId: this.userId,
        };
        this.$socket.client.emit("markAsRead", data);
      } else {
        this.debug("no messages to be marked as read");
      }
    },

    async getMessagesByChatId(chatId) {
      let messages = [];
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_CHAT_MESSAGES}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };
        let payload = new FormData();

        payload.append("chatId", chatId);

        console.log(`url = ${url}`);
        let fetchData = {
          method: "POST",
          headers: headers,
          body: payload,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson);
        if (resJson.ok == true) {
          messages = resJson.messages;
        }
      } catch (e) {
        console.error(e);
      }

      return messages;
    },

    rebuildChannelsList() {
      this.channelsBuildIndex++;
      this.channelsBuildKey = `channelsBuildKey${this.channelsBuildIndex}`;
    },

    rebuildChat() {
      this.chatBuildIndex++;
      this.chatBuildKey = `chatBuildIndex${this.chatBuildIndex}`;
    },
  },
};
</script>
<!--
  async initOrig() {
      this.moreMessagesLoading = false;
      this.debug("init screen of chat" + this.$store.state.streamConnected);
      this.query = this.$route.query;
      let users = [];
      let ok = true;

      //if (this.$store.state.streamConnected == false) {
      //connect the user to stream chat if already registered else will not connect
      let result = await this.connectClientToStream(users);
      this.printJson(result, "result of join");
      ok = result.ok;
      // }

      if (ok == true) {
        //Initialize the list of chats user interacted with
        await this.filterChannelsByUserId();

        await this.initializeChat();
      } else {
        this.debug("user not connected");
      }
      this.changeWidth();
      if ("channelId" in this.query) {
        //await this.synchSetTimeout(100)
        this.scrollToBottomOfChat();
      }
    },


     async initializeChat() {
      this.moreMessagesAvailable = true;
      this.newMessage = "";
      this.showGoToBottom = false;
      if (this.$store.state.streamConnected == true) {
        this.messages = [];
        let channelIdexist = false;
        this.query = this.$route.query;
        this.userId = this.$store.state.accessTokenDecoded.sub;

        if (this.channel !== null && this.channel !== undefined) {
          await this.channel.stopWatching();
        }
        //keep the selected channel's webhook active and unsubscribe the othe channels selected before
        if (this.channelListener !== null && this.channelListener !== undefined) {
          this.channelListener.unsubscribe();
        }

        //  await this.filterChannelsByUserId();

        if ("channelId" in this.query) {
          for (let i = 0; i < this.channels.length; i++) {
            if (this.channels[i].data.id == this.query.channelId) {
              this.channel = this.channels[i];
              channelIdexist = true;
              break;
            }
          }
          if (channelIdexist == false) {
            //check with chanelId in stream io query if channel ids
            const filter = {
              type: "messaging",
              id: { $eq: this.query.channelId },
            };
            const sort = [
              /*{ last_message_at: -1 }*/
            ];
            let options = {
              watch: true, // this is the default
              state: true,
              message_limit: this.STREAM_MESSAGES_PER_SCREEN,
              limit: this.STREAM_CHATS_PER_SCREEN,
              offset: this.STREAM_CHATS_OFFSET * (this.chatsPageNumber - 1),
            };
            let channelsRetrieved = await this.queryStreamChannels(filter, sort, options);
            // console.log(channelsRetrieved);
            this.debug("id equal channel");
            if (channelsRetrieved.length > 0 && channelsRetrieved !== undefined) {
              channelIdexist = true;
              this.channel = channelsRetrieved[0];
            }
          }
          if (channelIdexist) {
            this.messages = (await this.channel.watch()).messages;
            this.members = (await this.channel.watch()).members;
            this.channelFrozen = this.channel.data.frozen;
            if (this.messages.length == 0 && this.channelFrozen == false) {
              this.showMessageChips = true;
              this.newMessage = this.tags[this.selectedMessage];
            } else {
              this.showMessageChips = false;
            }
            this.printJson(this.messages);
            //this.printJson(this.members, "members");
            //this.printJson(this.channel.data, "channel data");
            this.hasJoinedChat = true;
            this.channelListener = this.channel.on("message.new", async (event) => {
              this.printJson(event, "new message");
              this.messages.push(
                event.message
                /*{
               text: event.message.text,
                user: event.message.user,
              }*/
              );
              this.channel.markRead();
              this.channelFrozen = this.channel.data.frozen;
              await this.synchSetTimeout(100);
              this.scrollToBottomOfChat();
              if (this.showMessageChips == true) {
                this.showMessageChips = false;
              }
            });
            this.channel.markRead();
            this.scrollToBottomOfChat();
            var scrolledDiv = document.getElementById("chatScroll");
            let self = this;
            scrolledDiv.onscroll = function () {
              /*console.log(
                scrolledDiv.scrollTop.toString() + self.moreMessagesLoading
              );*/
              self.scrolledValue = scrolledDiv.scrollHeight - scrolledDiv.scrollTop;
              self.debug(self.scrolledValue + " ddsadasda");
              if (self.scrolledValue > 1500 && scrolledDiv.scrollHeight > 1000) {
                self.showGoToBottom = true;
              } else {
                self.showGoToBottom = false;
              }
              if (scrolledDiv.scr)
                if (
                  scrolledDiv.scrollTop < 400 &&
                  scrolledDiv.scrollTop > 0 &&
                  self.moreMessagesLoading == false
                ) {
                  self.scrollHeight = scrolledDiv.scrollTop;

                  self.loadMoreMessages();
                }
            };

            //if hash of invoice-request exist
            if (this.$route.hash == "#request-invoice") {
              if ("invoiceRequested" in this.channel.data == false) {
                this.newMessage = this.tags[2];
                await this.sendMessage();
                let update = { set: { invoiceRequested: true } };
                this.channel.updatePartial(update);
              } else {
                this.debug("show snackbar on already requested");
                this.snackbarMessage = "Invoice requested already";
                this.showSnackbar = true;
              }
            }
          }
          // this.channel = channel;
        } else {
          this.hasJoinedChat = false;
        }
      }
    },

   async filterChannelsByUserId() {
      this.userId = this.$store.state.accessTokenDecoded.sub;
      try {
        const filter = { type: "messaging", members: { $in: [this.userId] } };
        const sort = [
          /*{ last_message_at: -1 }*/
        ];
        let options = {
          watch: true, // this is the default
          state: true,
          message_limit: this.STREAM_MESSAGES_PER_SCREEN,
          limit: this.STREAM_CHATS_PER_SCREEN,
          offset: this.STREAM_CHATS_OFFSET * (this.chatsPageNumber - 1),
        };
        this.channels = await this.queryStreamChannels(filter, sort, options);

        if (
          this.channels.length == this.STREAM_CHATS_PER_SCREEN &&
          this.channels !== undefined
        ) {
          //show go to next page button
          this.showNextPageButton = true;

          if (this.chatsPageNumber >= this.loadedNumberOfPages) {
            this.loadedNumberOfPages = this.chatsPageNumber + 1;
          }
        } else {
          if (this.chatsPageNumber >= this.loadedNumberOfPages) {
            this.loadedNumberOfPages = this.chatsPageNumber;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    

   
-->

<!-- <v-row class="mb-2" no-gutters>
                  <v-icon>{{ mdiAttachment }}</v-icon>
                </v-row>
                <v-row no-gutters class="mb-2">
                  <v-icon>{{ mdiEmoticon }}</v-icon>
                </v-row>-->
