<template>
  <v-col :key="componentBuildKey">
    <v-card height="150" outlined v-if="isLoading">
      <v-row no-gutters justify="center" class="ma-5">
        <v-progress-circular indeterminate color="brown"></v-progress-circular>
      </v-row>
    </v-card>

    <v-card v-else class="mx-auto" max-width="650" elevation="0">
      <h3 class="text-center grey rounded white--text">
        {{ $t(`paid_ads_valid_days`) }}
      </h3>
      <span class="title rounded">{{ $t(`option`) }} </span>
      <v-row no-gutters>
        <v-radio-group v-model="radioGroup" class="ma-1">
          <v-radio
            color="black"
            v-for="(adsCount, index) in availableAds"
            :key="index"
            :value="adsCount"
            :class="index == 0 ? `red` : index == 1 ? `blue` : `green`"
            class="rounded pa-1"
          >
            <template v-slot:label>
              <v-row no-gutters class="ma-1 white--text">
                <span
                  >{{ $t(`buy`) }} {{ adsCount }} {{ $t(`for`) }}
                  {{ currencyCode }} {{ (price * (index + 1)).toFixed(2) }}
                  {{ $t(`or`) }}
                  {{ (priceGuru * (index + 1)).toFixed(2) }}
                  {{ $t(`token`) }}
                </span>
              </v-row>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>
      <v-row no-gutters>
        <span class="title">{{ $t(`quantity`) }}</span></v-row
      >

      <v-row no-gutters>
        <MyDropDown
          :itemName="$t('numOfAds')"
          :dropDownItem="selectedNumAds"
          :dropDownItems="optionalNumAdsAvailable"
          @update-items="updateNumAds"
        ></MyDropDown>
      </v-row>
      <v-row no-gutters v-show="showPyamentOptions">
        <b class="green--text"
          >{{ $t(`purchaseAds`) }} {{ finalNumAds }} {{ $t(`for`) }}
          {{ currencyCode }} {{ finalPrice.toFixed(2) }} {{ $t(`or`) }}
          {{ finalPriceGuru.toFixed(2) }} {{ $t(`token`) }}</b
        ></v-row
      >
      <br />
      <v-row justify="center" no-gutters>
        <MyBtn
          btnName="confirm_&_proceed"
          @btn-clicked="showPaymentButtons()"
          :iconName="mdiShopping"
          :disabled="showPyamentOptions == false"
        ></MyBtn>
      </v-row>

      <v-card
        v-show="showPaymentLocal && showPyamentOptions"
        outlined
        :key="priceKey"
      >
        <Payments
          :price="finalPrice"
          :currencyCode="currencyCode"
          :priceGuru="finalPriceGuru"
          :description="description"
          @payment-success="completePayment"
        ></Payments>
      </v-card>
      <br />

      <v-divider></v-divider>
      <br />
      <v-row justify="start" no-gutters v-if="showHistorylocal == false">
        <MyBtn
          btnName="show_purchase_history"
          @btn-clicked="loadPurchaseHistory()"
          :iconName="mdiReceipt"
        ></MyBtn>
      </v-row>
      <v-card v-else outlined>
        <v-card-title> {{ $t(`purchase_history`) }} </v-card-title>
        <v-card
          v-show="paidAdsRecords.length > 0"
          v-for="(record, index) in paidAdsRecords"
          :key="index"
          outlined
          class="pa-1 mx-auto"
        >
          <span>{{ $t(`numOfAds`) }} {{ record.numAds }}</span>
          <br />
          <span>{{ $t(`paid`) }} </span>
          <span v-if="record.paymentType == 'depay'"
            >{{ record.priceGuru }} {{ $t(`token`) }}</span
          >
          <span v-if="record.paymentType == 'paypal'"
            >{{ record.currencyCode }} {{ record.price }}</span
          >
          <br />
          <span
            >{{ $t(`date`) }} - {{ getDateForMS(record.dateActivated) }}</span
          >
          <br />
          <span
            >{{ $t(`days_left`) }} -
            {{ calculateRemainingDays(record.dateActivated) }}
            {{ $t(`day`) }}</span
          >
          <br />
          <span
            >{{ $t(`status`) }} -
            <span
              :class="
                record.paymentStatus == 'COMPLETED'
                  ? 'green--text'
                  : 'red--text'
              "
              >{{ record.paymentStatus }}</span
            ></span
          >
        </v-card>
        <v-row
          v-show="paidAdsRecords.length == 0 || paidAdsRecords == undefined"
          justify="center"
          no-gutters
        >
          {{ $t(`noHistory`) }}
        </v-row>
      </v-card>
    </v-card>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
  </v-col>
</template>
<script>
import Payments from "./Payments.vue";
import Config from "../config.js";
import MyBtn from "./MyBtn.vue";
import MyDropDown from "./MyDropDown";
import MyAcknowledge from "./MyAcknowledge";
import { mdiShopping, mdiReceipt } from "@mdi/js";
export default {
  name: "PaidAds",
  //props: ["showHistory"],
  components: { Payments, MyBtn, MyDropDown, MyAcknowledge },

  mounted: async function () {
    if (await this.isLoggedInOIDC(true)) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  watch: {
    radioGroup: function () {
      this.printJson(this.radioGroup);
      if (this.radioGroup == null && this.selectedNumAds.length == 0) {
        this.showPyamentOptions = false;
      } else {
        this.showPyamentOptions = true;
        this.selectedNumAds = "";
      }
      this.calculatePriceOfAds();
    },
  },

  data: () => ({
    mdiShopping: mdiShopping,
    mdiReceipt: mdiReceipt,

    showPaymentLocal: false,
    showHistorylocal: false,
    isLoading: true,

    price: 0,
    priceGuru: 0,
    currencyCode: "AUD",
    description: "Paid Ads",
    numAds: 0,

    componentBuildKey: "build0",
    componentBuildIndex: 0,
    priceKey: "price0",
    priceIndex: 0,
    availableAds: [],
    radioGroup: null,
    showPyamentOptions: false,

    finalPrice: 0,
    finalPriceGuru: 0,
    finalNumAds: 0,

    selectedNumAds: "",
    optionalNumAdsAvailable: [],

    acknowledgeMessage: "",
    acknowledgeTitle: "",
    showAcknowledge: false,
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    paidAdsRecords: [],
    maxNumberAds: Config.MAX_ADS,
  }),

  methods: {
    async init() {
      this.isLoading = true;
      //this.showHistorylocal = this.showHistory;

      let result = await this.getPaidAdsPricingByCountry(
        this.$store.state.countryByIP,
        this.showHistorylocal
      );
      if (result.ok == true) {
        let priceRecord = result.priceRecord;
        this.printJson(result, "returned result record");
        if ("price" in priceRecord) {
          this.price = priceRecord.price;
        }
        if ("currencyCode" in priceRecord) {
          this.currencyCode = priceRecord.currencyCode;
        }
        if ("priceGuru" in priceRecord) {
          this.priceGuru = priceRecord.priceGuru;
        }
        if ("numAds" in priceRecord) {
          this.numAds = priceRecord.numAds;
        }

        if ("paidAdsRecords" in result) {
          this.paidAdsRecords = result.paidAdsRecords;
        }

        if ("maxNumberAds" in result) {
          this.maxNumberAds = result.maxNumberAds;
        }

        this.price = Number(this.price);
        this.numAds = Number(this.numAds);
        this.availableAds = [this.numAds * 1, this.numAds * 2, this.numAds * 3];
        this.optionalNumAdsAvailable = [
          this.numAds * 4,
          this.numAds * 5,
          this.numAds * 6,
          this.numAds * 7,
          this.numAds * 8,
          this.numAds * 9,
          this.numAds * 10,
        ];
      }

      this.isLoading = false;
      this.componentBuildIndex++;
      this.componentBuildKey = `build${this.componentBuildIndex}`;
    },

    calculatePriceOfAds() {
      if (this.showPyamentOptions) {
        if (this.radioGroup != null) {
          this.finalPrice =
            (Number(this.radioGroup) / this.numAds) * this.price;
          this.finalPriceGuru =
            (Number(this.radioGroup) / this.numAds) * this.priceGuru;
          this.finalNumAds = Number(this.radioGroup);
        } else {
          this.finalPrice =
            (Number(this.selectedNumAds) / this.numAds) * this.price;
          this.finalPriceGuru =
            (Number(this.selectedNumAds) / this.numAds) * this.priceGuru;
          this.finalNumAds = Number(this.selectedNumAds);
        }
        this.description = `Paid ads ${this.finalNumAds} for  ${this.currencyCode} ${this.finalPrice}`;
      } else {
        this.finalPrice = 0;
        this.finalNumAds = 0;
      }
      this.showPaymentLocal = false;
    },

    async updateNumAds(numAdsIndex) {
      this.radioGroup = null;
      await this.synchSetTimeout(10);
      this.selectedNumAds = this.optionalNumAdsAvailable[numAdsIndex];

      this.showPyamentOptions = true;
      this.calculatePriceOfAds();
    },

    showPaymentButtons() {
      this.priceIndex++;
      this.priceKey = `price${this.priceIndex}`;
      this.showPaymentLocal = true;
    },

    async completePayment(paymentData) {
      this.printJson(paymentData, "completePayment");
      this.isLoading = true;
      paymentData["numAds"] = this.finalNumAds;

      let result = await this.createPaidsAdsRecord(paymentData);
      if (result.ok == true) {
        this.showPyamentOptions = false;
        this.radioGroup = null;
        this.componentBuildIndex++;
        this.componentBuildKey = `build${this.componentBuildIndex}`;
        await this.loadPurchaseHistory();
      } else {
        this.setAcknowledgeError(result.message);
      }

      this.isLoading = false;
    },

    async createPaidsAdsRecord(data) {
      let ok = false;
      let message = "";
      let paidAdId = "";
      this.printJson(data, "data");

      let url = `${Config.NODE_SERVER_IP}${Config.NEW_PAID_ADS_RECORD}`;
      let headers = {
        Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        Accept: "application/json",
      };

      let payload = new FormData();
      payload.append("record", JSON.stringify(data));
      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      console.log(`url = ${url}`);
      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "createPaidsAdsRecord");

        ok = jsonRes.ok;
        message = jsonRes.message;
        if (ok === true) {
          paidAdId = jsonRes.paidAdId;
        }
      } catch (e) {
        console.error(e);
      }
      return {
        ok: ok,
        message: message,
        paidAdId: paidAdId,
      };
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.showAcknowledge = true;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    async loadPurchaseHistory() {
      this.showHistorylocal = true;
      await this.init();
    },

    calculateRemainingDays(dateActivated) {
      let daysElapsed =
        (Date.now() - Number(dateActivated)) / (1000 * 60 * 60 * 24);
      this.debug(`dayselapsed= ${daysElapsed}`);

      let daysLeftTrue = Number(Config.PAID_ADS_VALID_DAYS) - daysElapsed;
      this.debug(`daysLeftTrue= ${daysLeftTrue}`);
      return daysLeftTrue.toFixed(2);
    },
    getDateForMS(date) {
      return Date(Number(date)).toString();
    },
  },
};
</script>
