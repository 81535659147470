<template>
  <v-row justify="center" no-gutters>
    <v-card
      width="1600"
      class="mx-auto"
      color="transparent"
      elevation="0"
      id="transparentBack"
    >
      <!-- <div id="left" class="fillColour hidden-sm-and-down">
        <MyCardTitle
          title="Forum"
          v-if="hideFilter === true"
          @close-window="hideLeft()"
        ></MyCardTitle>
        <v-row justify="center">
          <MyBtn
            v-if="showPostTopicButton"
            class="ma-1"
            btnName="Post Topic"
            @btn-clicked="goToPostNewTopic()"
          ></MyBtn>
          <MyBtn
            v-else
            class="ma-1"
            btnName="Post Topic"
            @btn-clicked="$router.push(ROUTE_LOGIN)"
          ></MyBtn>
        </v-row>
        <v-row justify="center">
          <MyBtn
            v-if="MyTopicsButton"
            class="ma-1"
            btnName="My Topics"
            @btn-clicked="goToMyTopics(accessTokenDecoded.sub)"
          ></MyBtn>
          <MyBtn
            v-else
            class="ma-1"
            btnName="My Topics"
            @btn-clicked="$router.push(ROUTE_LOGIN)"
          ></MyBtn>
        </v-row>

        <hr />
        <v-card elevation="0" class="mx-auto" max-width="300" tile>
          <v-list-item-title
            style="font-weight: 900"
            class="text-center fillColour"
            >Categories</v-list-item-title
          >
          <v-list-item-group color="primary">
            <v-list-item
              class="fillColour"
              v-for="(item, index) in this.FORUM_CATEGORIES"
              :key="index"
            >
              <v-list-item-content @click="goToCategory(item)">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </div>-->
      <v-navigation-drawer
        id="scroll-target"
        elevation="0"
        class="overflow-y-auto fillColour"
        height="400px"
        width="280px"
        v-model="drawer"
        absolute
      >
        <MyCardTitle
          title="forum"
          @close-window="drawer = !drawer"
        ></MyCardTitle>
        <v-row justify="center" no-gutters>
          <MyBtn
            v-if="showPostTopicButton"
            class="ma-1"
            btnName="post"
            @btn-clicked="goToPostNewTopic()"
          ></MyBtn>
          <MyBtn
            v-else
            class="ma-1"
            btnName="post"
            @btn-clicked="$router.push(ROUTE_LOGIN)"
          ></MyBtn>
        </v-row>
        <v-row justify="center" no-gutters>
          <MyBtn
            v-if="MyTopicsButton"
            class="ma-1"
            btnName="my"
            @btn-clicked="goToMyTopics(accessTokenDecoded.sub)"
          ></MyBtn>
          <MyBtn
            v-else
            class="ma-1"
            btnName="my"
            @btn-clicked="$router.push(ROUTE_LOGIN)"
          ></MyBtn>
        </v-row>
        <hr />
        <v-card elevation="0" class="mx-auto" max-width="300" tile>
          <v-list-item-title
            style="font-weight: 900"
            class="text-center fillColour"
            >{{ $t(`categories`) }}</v-list-item-title
          >
          <v-list-item-group color="primary">
            <v-list-item
              v-if="category !== undefined && category.length > 0"
              class="fillColour"
            >
              <v-list-item-content @click="$router.push(ROUTE_FORUM)">
                <v-list-item-title>{{ $t(`all`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="fillColour"
              v-for="(item, index) in this.FORUM_CATEGORIES"
              :key="index"
            >
              <v-list-item-content @click="goToCategory(item)">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
        <!-- <v-row
          v-scroll:#scroll-target
          align="center"
          justify="center"
          style="height: 15px"
        ></v-row>-->
      </v-navigation-drawer>
      <v-card-title>
        <v-row justify="center" class="" no-gutters>
          <v-col :cols="$vuetify.breakpoint.xs ? 2 : 3">
            <div class="">
              <MyBtn
                class="ma-1 hidden-sm-and-down"
                @btn-clicked="hideLeft()"
                btnName="categories"
                :iconName="mdiForum"
              ></MyBtn>
              <v-icon
                left
                color="green"
                large
                class="hidden-md-and-up"
                @click="drawer = !drawer"
                >{{ mdiMenu }}</v-icon
              >
              <span>&nbsp;</span>
            </div>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 10 : 9">
            <h3 v-if="$vuetify.breakpoint.sm">{{ $t(`welcome`) }}</h3>
            <h5 v-else-if="$vuetify.breakpoint.xs">
              {{ $t(`welcome`) }}
            </h5>
            <h3 v-else class="display-1">{{ $t(`welcome`) }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>
        <v-row justify="center" no-gutters v-if="myTopics == false">
          <h4 v-if="category" class="">{{ this.category }}</h4>
          <h4 v-else class="">{{ $t(`latest`) }}</h4>
        </v-row>
        <v-row v-else justify="center" no-gutters>
          <h4>{{ $t(`my`) }}</h4>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-container v-if="this.posts.length > 0">
            <v-card
              outlined
              v-for="(post, index) in this.posts"
              :key="index"
              min-width="300px"
              max-width="1000px"
              class="ma-1"
              elevation="0"
            >
              <PostView :post="post"></PostView>
              <!--<v-divider class="pa-2"></v-divider>-->
            </v-card>
          </v-container>
          <h6 v-else v-show="isLoading == false" class="caption">
            {{ $t(`no`) }}
          </h6>
        </v-row>
      </div>
      <!--<div>
        <v-row no-gutters justify="center">
          <b class="">Latest Public Topics</b>
        </v-row>
        <v-row>
          <v-row></v-row>
          <v-container>
            <v-card
              v-for="(post, index) in this.postsFiltered"
              :key="index"
              min-width="300px"
              max-width="1000px"
              class="ma-1"
              elevation="0"
            >
              <PostView :post="post"></PostView>
            </v-card>
          </v-container>
        </v-row>
      </div>-->
      <Loading :isLoading="isLoading"></Loading>
    </v-card>
  </v-row>
</template>

<script>
import { mdiForum, mdiMenu } from "@mdi/js";

import Config from "../config.js";
import Loading from "./Loading";
import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";
import PostView from "./PostView";

// lodash
//import isEmpty from "lodash/isEmpty";

export default {
  name: "Forum",
  components: {
    MyBtn,
    MyCardTitle,
    PostView,
    Loading,
  },

  metaInfo: {
    title: "Mobility Guru Forum",
    meta: [
      {
        name: "description",
        content:
          "Ask for help, help other members or request new website features.",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  data: () => ({
    mdiForum: mdiForum,
    mdiMenu: mdiMenu,

    hideFilter: true,
    FORUM_CATEGORIES: [],
    drawer: false,
    ROUTE_LOGIN: "",
    collectionForum: null,
    accessTokenDecoded: {},
    category: "",
    id: "",
    posts: [],
    postsFiltered: [],
    levels: [],
    isLoading: false,
    myTopics: false,
    ROUTE_FORUM: Config.ROUTE_FORUM,
  }),
  mounted: function () {
    this.init();
  },

  computed: {
    showPostTopicButton() {
      let showTopic = this.$store.state.isLoggedIn;
      this.debug(`showTopic = ${showTopic}`);

      return showTopic;
    },
    MyTopicsButton() {
      let MyTopics = this.$store.state.isLoggedIn;
      this.debug(`MyTopics = ${MyTopics}`);

      return MyTopics;
    },
  },
  watch: {
    async $route() {
      await this.init();
    },
  },
  /* created() {
    window.addEventListener("resize", this.hideDrawer);
  },
  destroyed() {
    window.removeEventListener("resize", this.hideDrawer);
  },*/
  methods: {
    async init() {
      this.posts = [];
      this.isLoading = true;
      let results = {};
      //await this.mainInit();

      //let mustBeLoggedIn = this.$store.state.isLoggedIn;

      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.category = this.$route.query.category;
      this.id = this.$route.query.id;
      if (this.id !== undefined) {
        this.myTopics = true;
      } else {
        this.myTopics = false;
      }
      this.FORUM_CATEGORIES = Config.FORUM_CATEGORIES;
      this.debug("Forum Categories" + this.FORUM_CATEGORIES);
      this.ROUTE_LOGIN = Config.ROUTE_LOGIN;
      if (this.category !== undefined) {
        this.levels = ["category"];
        results = await this.refreshPosts("category", this.category);
        this.printJson(this.posts, "Collection Forum in Forum");
      } else if (this.id !== undefined) {
        this.levels = ["postedBy"];
        results = await this.refreshPosts("postedBy", this.id);
      } else {
        this.category = "";
        results = await this.refreshPosts("category", this.category);
        //fetch all posts
        /* if (isEmpty(this.postsFiltered)) {
          for (let i = 0; i < this.FORUM_CATEGORIES.length; i++) {
            let postsInCategory = [];
            this.levels = ["category"];
            postsInCategory = await this.refreshPosts(
              this.FORUM_CATEGORIES[i],
              this.levels
            );
            let numPosts = Math.min(postsInCategory.length, 5);
            for (let j = 0; j < numPosts; j++) {
              this.postsFiltered.push(postsInCategory[j]);
            }
          }
        }*/
      }
      if (results.ok == true) {
        this.posts = results.posts;
      }
      this.isLoading = false;
    },
    hideLeft() {
      /* This function is used to hide the left side filter section */
      /*var x = document.getElementById("left");
      if (x.style.display === "none") {
        x.style.display = "block";
        this.hideFilter = true;
      } else {
        x.style.display = "none";
        this.hideFilter = false;
      }*/
      this.drawer = !this.drawer;
    },
    async goToCategory(item) {
      this.debug("item " + item);
      if (item == this.FORUM_CATEGORIES[0]) {
        this.category = item;
      }
      if (item == this.FORUM_CATEGORIES[1]) {
        this.category = item;
      }
      if (item == this.FORUM_CATEGORIES[2]) {
        this.category = item;
      }

      await this.$router.push({
        path: Config.ROUTE_FORUM,
        query: {
          category: this.category,
        },
      });
    },

    async goToPostNewTopic() {
      await this.$router.push(Config.ROUTE_POST_NEW_TOPIC);
    },

    /*async goToMyTopics() {
      let id = this.accessTokenDecoded.sub;
     // this.levels = ["postedBy"]
     // this.posts = await this.refreshPosts(filter, this.levels);
       this.$router.push({
        path: Config.ROUTE_FORUM,
        query: {
          id: id
        }
      });
    }, */

    /*  async editPost(){
          this.debug("hiiiiiiiiiiiiii");
        // this.debug("This Edit Post "+edit);
        //this.$emit("btn-clicked");
        this.debug(`post Id = ${this.postId}`);
          await this.$router.push({
        path: Config.ROUTE_POST__NEW_TOPIC,
        query: {
          postId: this.postId
        }
      });
    }*/
  },
};
</script>
