<template>
  <v-dialog v-model="isLoadingLocal" width="300" :hide-overlay="hideOverlay">
    <v-card>
      <v-card-title class="fillColour"> {{ $t(`wait`) }}</v-card-title>
      <br />
      <div class="text-center">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </div>
      <br />
      <div v-if="showProgress" class="text-center">
        <v-progress-linear height="25" :value="percentage" color="primary">
          <strong>{{ Math.ceil(percentage) }}%</strong>
        </v-progress-linear>
        <div class="ok" v-html="loadData"></div>
      </div>
      <br />
      <v-layout justify-center>
        <div class="ok" v-html="message"></div>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Loading",
  props: ["isLoading", "message", "hideOverlay", "percentage", "loadData"],
  watch: {
    isLoading: function () {
      this.isLoadingLocal = this.isLoading;
    },
  },
  computed: {
    showProgress() {
      this.debug(`Loading.vue showProgress ${this.percentage}`);
      if (this.percentage === undefined || this.percentage === null) {
        return false;
      } else {
        return true;
      }
    },
  },

  data: () => ({
    isLoadingLocal: false,
    //showProgress: false,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.isLoadingLocal = this.isLoading;
    },
    /*
    initPercentage() {
      this.debug(`Loading.vue initPercentage ${this.percentage}`);
      if (this.percentage === undefined || this.percentage === null) {
        this.showProgress = false;
      } else {
        this.showProgress = true;
      }
    },
    */
  },
};
</script>
