<template>
  <SkeletonLoader v-if="isLoading" type="card"></SkeletonLoader>
  <v-card v-else elevation="0">
    <v-container fluid>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            <b v-if="localArticle.title" class=""> {{ localArticle.title }}</b>
          </v-card-title>

          <v-card-subtitle style="font-size: 15px">
            <b v-if="localArticle.category" class="">
              {{ localArticle.category }}</b
            >
          </v-card-subtitle>

          <v-card-subtitle style="font-size: 15px">
            <span
              v-if="localArticle.articleDescription"
              class="line-break-and-tab"
              v-html="localArticle.articleDescription"
            >
            </span>
          </v-card-subtitle>

          <v-card-actions>
            <span class="mr-5" style="font-size: 13px">{{
              this.calculateDays(localArticle.createdDate)
            }}</span>

            <v-spacer></v-spacer>

            <button
              type="button"
              class="btn btn-outline-primary"
              v-if="showEdit"
              @click="editArticle()"
              tile
              color="#ffffff"
            >
              {{ $t(`editArticle`) }}
            </button>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-container>
  </v-card>
</template>
<script>
import { mdiAccountCircle, mdiArrowDown, mdiArrowUp } from "@mdi/js";
import Config from "../config.js";
import SkeletonLoader from "../components/SkeletonLoader.vue";
export default {
  name: "ArticleView",
  components: { SkeletonLoader },

  computed: {
    showEdit() {
      let editArticle = false;
      if (
        this.$store.state.isSuperAdmin === true ||
        this.$store.state.isForumModerator == true
      ) {
        editArticle = true;
      }
      this.debug(`EditArticle = ${editArticle}`);
      return editArticle;
    },
  },

  data: () => ({
    mdiArrowDown: mdiArrowDown,
    mdiArrowUp: mdiArrowUp,
    mdiAccountCircle: mdiAccountCircle,

    isSuperAdmin: false,
    isForumModerator: false,
    collectionArticles: null,
    accessTokenDecoded: {},
    articleId: "",
    category: "",
    title: "",
    articleDescription: "",
    isLoading: false,
    localArticle: {},
  }),

  validations: {},

  mounted: async function () {
    await this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    async init() {
      this.isLoading = true;

      this.localArticle = this.article;
      this.articleId = this.$route.query.articleId;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.isForumModerator = this.$store.state.isForumModerator;
      this.debug("Super Admin", this.isSuperAdmin);
      this.debug(`article id from query = ${this.articleId}`);
      let result = await this.getArticleById(this.articleId);

      this.localArticle = result.article;
      this.isLoading = false;
    },

    async editArticle() {
      this.debug(`article Id = ${this.articleId}`);
      await this.$router.push({
        path: Config.ROUTE_EDIT_ARTICLE,
        query: {
          articleId: this.articleId,
        },
      });
    },
  },
};
</script>
