<template>
  <div>
    <h1 class="text-center">{{ $t(`wait`) }}</h1>

    <div class="text-center">
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";

import Config from "../config.js";
import KintoClient from "kinto-http";
import Keycloak from "keycloak-js";

export default {
  name: "LoginOIDC",
  data: () => ({
    SCOPES: "openid email",

    kintoClient: null,

    openid: {},

    tokens: {},
    accessTokenDecoded: {},

    user: {},

    initOptions: {
      url: Config.KEYCLOAK_SERVER_URL,
      realm: Config.KEYCLOAK_CLIENT_REALM,
      clientId: Config.KEYCLOAK_CLIENT_ID,
      //  clientSecret: "secret123"
    },

    keycloak: null,
  }),

  mounted: async function () {
    await this.initNew();
  },

  methods: {
    async initNew() {
      this.printJson(this.initOptions, "initOptions");
      this.keycloak = new Keycloak(this.initOptions);

      this.debug(
        `AUTHENTICATED FROM .................................${this.keycloak.token}`
      );

      this.keycloak
        .init({
          onLoad: "login-required",
          flow: "standard",
          redirectUri: window.location.origin + "/login",
          checkLoginIframe: false,
          promiseType: "native",
        })
        .then((authenticated) => {
          if (authenticated) {
            // Keycloak is successfully initialized and the user is authenticated
            // You can proceed with rendering your Vue.js app
            console.log(`AUTHENTICATED ${authenticated}`);
            console.log(this.keycloak);
            console.log(this.keycloak.refreshToken);
            let tokens = {
              access_token: this.keycloak.token,
              refresh_token: this.keycloak.refreshToken,
            };
            console.log("tokens");
            console.log(tokens);
            this.$store.commit("set", ["tokens", tokens]);
            this.accessTokenDecoded = jwt_decode(this.keycloak.token);
            this.$store.commit("set", [
              "accessTokenDecoded",
              this.accessTokenDecoded,
            ]);

            console.log("tokenDEcoded");
            console.log(this.accessTokenDecoded);

            this.debug("exp = " + this.accessTokenDecoded.exp * 1000);
            this.debug("now = " + Date.now());

            if (Date.now() >= this.accessTokenDecoded.exp * 1000) {
              this.debug("Token expired");
            } else {
              this.debug("Token fresh");
            }
            this.$router.push(Config.ROUTE_PROFILE);
          }
        });
    },
    async init() {
      this.$store.commit("set", ["openid", null]);
      await this.mainInit();

      this.openid = this.$store.state.openid;

      this.kintoClient = new KintoClient(Config.KINTO_SERVER_IP);

      // Check if the location contains the tokens (after being redirected)
      const authResult = this.parseToken();
      if (authResult) {
        const { access_token, token_type } = authResult;
        this.debug("access_token = " + access_token);
        this.debug("token_type = " + token_type);

        if (access_token) {
          // Set access token for requests to Kinto.
          this.kintoClient.setHeaders({
            Authorization: `${token_type} ${access_token}`,
          });
          // Show if Kinto authenticates me:
          let serverInfoLocal = await this.kintoClient.fetchServerInfo();
          this.printJson(this.serverInfoLocal, "serverInfoLocal");

          const { user } = serverInfoLocal;
          if (user != null) {
            this.debug("You are " + (user ? user.id : "unknown"));

            this.user = user;
            this.$store.commit("set", ["user", this.user]);

            this.accessTokenDecoded = jwt_decode(this.tokens.access_token);
            this.$store.commit("set", [
              "accessTokenDecoded",
              this.accessTokenDecoded,
            ]);
            this.debug("exp = " + this.accessTokenDecoded.exp * 1000);
            this.debug("now = " + Date.now());

            if (Date.now() >= this.accessTokenDecoded.exp * 1000) {
              this.debug("Token expired");
            } else {
              this.debug("Token fresh");
            }
            this.$router.push(Config.ROUTE_PROFILE);
          } else {
            console.error("no user");
          }
        } else {
          console.error("Authentication error", authResult);
        }
      }

      if ((await this.isLoggedInOIDC()) == false) {
        await this.loginWithOIDC();
      }
    },

    async loginWithOIDC() {
      const CALLBACK_URL = window.location.href + "#tokens=";

      // Redirect the browser to the authentication page.
      const callback = encodeURIComponent(CALLBACK_URL);

      this.debug("CALLBACK_URL = " + CALLBACK_URL);
      this.debug("callback = " + callback);

      let authPath = this.openid.providers[0].auth_path;

      let url = `${Config.KINTO_SERVER_IP}${authPath}?callback=${callback}&scope=${this.SCOPES}&redirectUrl=${Config.APP_SERVER_IP}`;
      this.debug("url = " + url);

      window.location = url;
    },

    parseToken() {
      const hash = decodeURIComponent(window.location.hash);
      this.debug("hash = " + hash);

      const tokensExtract = /tokens=([.\s\S]*)/.exec(hash);
      if (!tokensExtract) {
        // No token in URL bar.
        return null;
      }
      const tokens = atob(tokensExtract[1]);
      this.debug("tokens = " + tokens);

      const parsed = JSON.parse(tokens);

      this.tokens = parsed;
      this.$store.commit("set", ["tokens", this.tokens]);

      return parsed;
    },
  },
};
</script>

<!--https://github.com/grootan/keycloak-oidc-javascript/blob/main/index.html -->
