import Vue from "vue";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueI18n from "vue-i18n";
import store from "./store";

import VueRouter from "vue-router";

import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

//import Vuelidate from "vuelidate";

// lodash
import clone from "lodash/clone";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
//import uniqWith from "lodash/uniqWith";
//import unionWith from "lodash/unionWith";

import { StreamChat } from "stream-chat";
const clientStream = StreamChat.getInstance(Config.STREAM_API_KEY);
let streamUser;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
};

const firebaseapp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseapp);

//const Hashes = require("jshashes");
Vue.use(VueRouter);

Vue.use(VueMeta);

Vue.use(VueI18n);

//Vue.use(Vuelidate);

Vue.config.productionTip = false;

import jwt_decode from "jwt-decode";
import Kinto from "kinto";
import KintoClient from "kinto-http";

// 1. import components
import About from "./components/About";
import AdView from "./components/AdView";
import ArticleView from "./components/ArticleView";
import Articles from "./components/Articles";
import Careers from "./components/Careers";
import CountrymanagerDashboard from "./components/CountrymanagerDashboard";
import EditAd from "./components/EditAd";
import FavouriteAds from "./components/FavouriteAds";
import FAQ from "./components/FAQ";

import Forum from "./components/Forum";
import Home from "./components/Home";
import LiveAds from "./components/LiveAds";
import LoginOIDC from "./components/LoginOIDC";
import MyAds from "./components/MyAds";
import PostNewTopic from "./components/PostNewTopic";
import Privacy from "./components/Privacy";
import Profile from "./components/Profile";
import SalesDashboard from "./components/SalesDashboard";
import Raffle from "./components/Raffle";
import SubmitAd from "./components/SubmitAd";
import SuperAdminDashboard from "./components/SuperAdminDashboard";
import Terms from "./components/Terms";
import Test from "./components/Test";
import ThirdPartyLoginGuide from "./components/ThirdPartyLoginGuide";
import UserAds from "./components/UserAds";
import RotatingBannerSetup from "./components/RotatingBannerSetup";
import GuruGuide from "./components/GuruGuide";
import ChatScreen from "./components/ChatScreen";
import EditInvoice from "./components/EditInvoice";
import SubmitArticle from "./components/SubmitArticle";
import SubmitArticleHelper from "./components/SubmitArticleHelper";
import ArticleCell from "./components/ArticleCell";
import EditArticle from "./components/EditArticle";
import MyArticles from "./components/MyArticles";
import SubmitInvoice from "./components/SubmitInvoice";
import InvoiceView from "./components/InvoiceView";
import Invoices from "./components/Invoices";
import PaidAds from "./components/PaidAds";

import VueQuill from "vue-quill";
Vue.use(VueQuill);

import Welcome from "./components/Welcome";
import Wallet from "./components/Wallet";
import Unsubscribe from "./components/Unsubscribe";
import DevDashboard from "./components/DevDashboard";
import DeleteYourAccountGuide from "./components/DeleteYourAccountGuide";
import AirDrop from "./components/AirDrop.vue";

import Config from "./config.js";
//import { search } from "core-js/features/symbol";
//

import VueMatomo from "vue-matomo";

import Cookies from "js-cookie";

/*
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
*/

const socket = io(Config.NODE_SERVER_IP);

Vue.use(VueSocketIOExt, socket);

// 2. Add component to route if needed

const routes = [
  { path: Config.ROUTE_ABOUT, component: About },
  { path: Config.ROUTE_AD_VIEW, component: AdView },
  { path: Config.ROUTE_ARTICLE_VIEW, component: ArticleView },
  { path: Config.ROUTE_ARTICLES, component: Articles },
  { path: Config.ROUTE_AIRDROP_FORM, component: AirDrop },
  { path: Config.ROUTE_CAREERS, component: Careers },
  {
    path: Config.ROUTE_COUNTRYMANAGER_DASHBOARD,
    component: CountrymanagerDashboard,
  },
  {
    path: Config.ROUTE_DELETE_YOUR_ACCOUNT_GUIDE,
    component: DeleteYourAccountGuide,
  },
  {
    path: Config.ROUTE_DEV_DASHBOARD,
    component: DevDashboard,
  },
  { path: Config.ROUTE_EDIT_AD, component: EditAd },
  { path: Config.ROUTE_EDIT_ARTICLE, component: EditArticle },
  { path: Config.ROUTE_ARTICLE_CELL, component: ArticleCell },
  { path: Config.ROUTE_FAVOURITE_ADS, component: FavouriteAds },
  { path: Config.ROUTE_FAQ, component: FAQ },
  { path: Config.ROUTE_FORUM, component: Forum },
  { path: Config.ROUTE_HOME, component: Home },
  { path: Config.ROUTE_LIVE_ADS, component: LiveAds },
  { path: Config.ROUTE_LOGIN, component: LoginOIDC },
  { path: Config.ROUTE_MY_ADS, component: MyAds },
  { path: Config.ROUTE_POST_NEW_TOPIC, component: PostNewTopic },
  { path: Config.ROUTE_PRIVACY, component: Privacy },
  { path: Config.ROUTE_PROFILE, component: Profile },
  { path: Config.ROUTE_SALES_DASHBOARD, component: SalesDashboard },
  { path: Config.ROUTE_RAFFLE, component: Raffle },
  { path: Config.ROUTE_ROTATING_BANNER_SETUP, component: RotatingBannerSetup },
  { path: Config.ROUTE_SUBMIT_AD, component: SubmitAd },
  { path: Config.ROUTE_SUPERADMIN_DASHBOARD, component: SuperAdminDashboard },
  { path: Config.ROUTE_TERMS, component: Terms },
  { path: Config.ROUTE_TEST, component: Test },
  {
    path: Config.ROUTE_THIRD_PARTY_LOGIN_GUIDE,
    component: ThirdPartyLoginGuide,
  },
  { path: Config.ROUTE_USER_ADS, component: UserAds },
  { path: Config.ROUTE_WELCOME, component: Welcome },
  { path: Config.ROUTE_WALLET, component: Wallet },
  { path: Config.ROUTE_UNSUBSCRIBE, component: Unsubscribe },
  { path: Config.ROUTE_TOKEN_GUIDE, component: GuruGuide },
  { path: Config.ROUTE_MESSAGES, component: ChatScreen },
  { path: Config.ROUTE_SUBMIT_ARTICLE, component: SubmitArticle },
  { path: Config.ROUTE_SUBMIT_ARTICLE_HELPER, component: SubmitArticleHelper },
  { path: Config.ROUTE_SUBMIT_INVOICE, component: SubmitInvoice },
  { path: Config.ROUTE_MY_ARTICLES, component: MyArticles },
  { path: Config.ROUTE_EDIT_INVOICE, component: EditInvoice },
  { path: Config.ROUTE_INVOICE, component: InvoiceView },
  { path: Config.ROUTE_INVOICES, component: Invoices },
  { path: Config.ROUTE_PURCHASE_ADS, component: PaidAds },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
  scrollBehavior(/*to, from, savedPosition*/) {
    return { x: 0, y: 0 };
  },
});

// only use VueMatomo on production server
if (Config.IS_DEV === true) {
  console.log(`Matomo disabled for dev`);
} else {
  let userId = undefined;
  if (
    store.state.accessTokenDecoded !== null &&
    store.state.accessTokenDecoded.sub !== undefined
  ) {
    userId = store.state.accessTokenDecoded.sub;
    console.log(`Matomo enabled for ${userId}`);
  }
  Vue.use(VueMatomo, {
    // get these info from the tracker info when you setup a site to track
    host: Config.MATOMO_SERVER_IP,
    siteId: Config.MATOMO_SITE_ID,

    // Enables automatically registering pageviews on the router
    router: router,

    // Accurately measure the time spent on each page
    enableHeartBeatTimer: true,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    userId: userId,
  });
}

Vue.mixin({
  data: () => ({
    //var GOOGLE_TRANSLATE_API_MAX_CHARACTERS = Number(process.env.GOOGLE_TRANSLATE_API_MAX_CHARACTERS);
    nextSafeCall: 0,
    //var intervalMs = Number(process.env.GOOGLE_TRANSLATE_API_CALL_INTERVAL);
  }),

  methods: {
    getJsonPretty(jsonStr) {
      return JSON.stringify(jsonStr, null, 2);
    },

    printJson(jsonStr, name = null) {
      if (
        this.$store.state.debugOutputEnabled === true ||
        Config.IS_DEV === true
      ) {
        if (name != null) {
          this.debug(name);
        }
        this.debug(JSON.stringify(jsonStr, null, 2));
      }
    },

    debug(text) {
      if (
        this.$store.state.debugOutputEnabled === true ||
        Config.IS_DEV === true
      ) {
        console.log(text);
      }
    },

    capitalizeFirstLetter(sentence) {
      const words = sentence.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
      }
      return words.join(" ");
    },

    /* collection - collection to sync()
     * mustBeLoggedIn - true to enforce we are logged in. In this case syncs
     *   with JWT header else syncs anonymously
     *
     * If logged in will sync with the JWT header else will sync anonymously
     */
    async sync(collection, mustBeLoggedIn = true) {
      let ok = true;

      let syncOptions = {
        remote: Config.KINTO_SERVER_IP,
      };

      if (await this.isLoggedInOIDC()) {
        syncOptions.headers = {
          Authorization:
            this.$store.state.openid.providers[0].header_type +
            " " +
            this.$store.state.tokens.access_token,
        };
        this.printJson(syncOptions, "syncOptions");
      } else {
        if (mustBeLoggedIn === true) {
          console.error("sync() not logged in");
          ok = false;
        }
      }

      if (ok === true) {
        try {
          let result = await collection.sync(syncOptions);
          if (result.conflicts.length > 0) {
            ok = false;
          }
        } catch (e) {
          console.error(e);
          ok = false;
        }
      }

      return ok;
    },

    changeSpecialCharactersToHTML(str) {
      return str
        .replace(/\n/g, "<br>")
        .replace(/\t/g, "&emsp;")
        .replace(/  +/g, "&emsp;");
    },

    printState() {
      this.debug("printState");
      this.printJson(
        this.$store.state.bucketMobilityGuru,
        "bucketMobilityGuru"
      );

      this.printJson(this.$store.state.collectionAds, "collectionAds");
    },

    insertSpaces(str) {
      return str.split("").join(" ");
    },

    isCharNumber(c) {
      return c >= "0" && c <= "9";
    },

    insertSpacesForNumbers(str) {
      let changed = str;

      let len = changed.length;
      for (let i = 0; i < len; i++) {
        if (this.isCharNumber(changed.charAt(i))) {
          changed = changed.slice(0, i + 1) + " " + changed.slice(i + 1);
          len = changed.length;
        }
      }
      this.debug(`changed = ${changed}`);
      return changed;
    },

    async logoutPostCleanup() {
      this.$store.commit("set", ["syncedAds", false]);

      if (this.$store.state.collectionProfiles !== null) {
        //await this.$store.state.collectionProfiles.clear();
        //this.$store.commit("set", ["collectionProfiles", null]);

        this.debug("collectionProfiles is not null");
      } else {
        this.debug("collectionProfiles is null");
      }

      if (this.$store.state.collectionRaffles !== null) {
        //await this.$store.state.collectionRaffles.clear();
        //this.$store.commit("set", ["collectionRaffles", null]);

        this.debug("httpCollectionRaffles is not null");
      } else {
        this.debug("collectionRaffles is null");
      }

      this.$store.commit("set", ["isLoggedIn", false]);
      this.$store.commit("set", ["gotProfileFromDB", false]);

      this.$store.commit("set", ["pw", ""]);

      this.$store.commit("set", ["collectionProfiles", null]);
      this.$store.commit("set", ["profileId", ""]);

      this.$store.commit("set", ["kintoHttp", null]);
      this.$store.commit("set", ["httpBucketMobilityGuru", null]);
      this.$store.commit("set", ["httpCollectionProfiles", null]);

      this.$store.commit("set", ["httpCollectionAds", null]);
      this.$store.commit("set", ["httpCollectionForum", null]);
      this.$store.commit("set", ["httpCollectionArticles", null]);
      this.$store.commit("set", ["httpCollectionRaffles", null]);

      this.$store.commit("set", ["syncedAds", false]);

      this.$store.commit("set", ["syncedMyAdIds", false]);

      this.$store.commit("set", ["user", null]);
      this.$store.commit("set", ["tokens", null]);
      this.$store.commit("set", ["accessTokenDecoded", {}]);

      this.$store.commit("set", ["isSuperAdmin", false]);
      this.$store.commit("set", ["isForumModerator", false]);

      this.$store.commit("set", ["showEmailVerificationCodeInput", false]);

      this.$store.commit("set", ["addressObj", {}]);
      this.$store.commit("set", ["hasAddress", false]);

      this.$store.commit("set", ["phone", ""]);
      this.$store.commit("set", ["hasPhone", false]);
      this.$store.commit("set", ["merchant", ""]);

      this.$store.commit("set", ["superadminId", ""]);
      this.$store.commit("set", ["currentIP", ""]);
      this.$store.commit("set", ["countryByIP", ""]);
      this.$store.commit("set", ["lastIP", ""]);
      this.$store.commit("set", ["bonus", ""]);
      this.$store.commit("set", ["savedIP", false]);
      this.$store.commit("set", ["isCountrymanager", false]);
      this.$store.commit("set", ["isSales", false]);
      this.$store.commit("set", ["isDev", false]);
      this.$store.commit("set", ["phonePrefix", ""]);
      this.$store.commit("set", [
        "airdropFormObject",
        {
          status: null,
          email: "",
          facebookId: "",
          youTubeSubscribed: null,
          walletAddress: "",
          selectedGroup: null,
          twitterId: "",
          influencerCode: "",
        },
      ]);
      this.$store.commit("set", ["unreadMessages", 0]);
      this.$store.commit("set", ["unreadChannels", 0]);

      this.$store.commit("set", ["currencyCode", ""]);
      this.$store.commit("set", ["currencyCodeByIP", ""]);

      this.$store.commit("set", ["fcmWebTokenAuthorized", ""]);
      this.$store.commit("set", ["fcmWebToken", ""]);

      /*this.$store.commit("set", ["country", this.$store.state.countryByIP]);
      this.$store.commit("set", [
        "currencyCode",
        this.$store.state.countryObject["currency_code"],
      ]);*/

      if (this.$store.state.streamConnected == true) {
        //disconnect user from getstream and clear token

        await this.disconnectStream();
      }

      this.resetMatomoUserID();
    },

    async logout() {
      let ok = false;

      await this.mainInit();

      if ((await this.isLoggedInOIDC(false)) == true) {
        let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_LOGOUT}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.$store.state.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          let jsonRes = await res.json();

          ok = jsonRes.ok;
          this.debug(`fetch logout = ${ok}`);
          if (ok == true) {
            await this.logoutPostCleanup();
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        ok = true; // else alread logged out
      }

      this.$store.commit("set", ["isLoggedIn", false]);

      this.isShowMenu = false;

      return ok;
    },

    async connectBucketMobilityGuru() {
      let start = new Date();

      let ok = true;
      let refreshed = false;

      let bucketMobilityGuruTmp = this.$store.state.bucketMobilityGuru;

      if (bucketMobilityGuruTmp === null) {
        // first load
        refreshed = true;
      }

      try {
        bucketMobilityGuruTmp.collection(Config.COLLECTION_PROFILES_NAME);
        this.debug("Page not refreshed");
        refreshed = false;
      } catch (e) {
        this.debug("Page was refreshed");
        refreshed = true;
      }

      if (refreshed === true) {
        let options = {
          remote: Config.KINTO_SERVER_IP,
          bucket: Config.BUCKET_MOBILITY_GURU_NAME,
        };

        // kinto object
        let bucketMobilityGuru = new Kinto(options);
        this.$store.commit("set", ["bucketMobilityGuru", bucketMobilityGuru]);

        let collectionProfiles = bucketMobilityGuru.collection(
          Config.COLLECTION_PROFILES_NAME
        );
        this.$store.commit("set", ["collectionProfiles", collectionProfiles]);

        let collectionAds = bucketMobilityGuru.collection(
          Config.COLLECTION_ADS_NAME
        );
        this.$store.commit("set", ["collectionAds", collectionAds]);

        let collectionForum = bucketMobilityGuru.collection(
          Config.COLLECTION_FORUM_NAME
        );
        this.$store.commit("set", ["collectionForum", collectionForum]);

        let collectionRaffles = bucketMobilityGuru.collection(
          Config.KINTO_COLLECTION_RAFFLES_NAME
        );
        this.$store.commit("set", ["collectionRaffles", collectionRaffles]);

        let collectionArticles = bucketMobilityGuru.collection(
          Config.KINTO_COLLECTION_ARTICLES_NAME
        );
        this.$store.commit("set", ["collectionArticles", collectionArticles]);

        let collectionRotatingBannerAds = bucketMobilityGuru.collection(
          Config.COLLECTION_ROTATING_BANNER_ADS_NAME
        );
        this.$store.commit("set", [
          "collectionRotatingBannerAds",
          collectionRotatingBannerAds,
        ]);
        this.printJson(
          collectionRotatingBannerAds,
          " this.printJson() collectionRotatingBannerAds"
        );

        /*
        if (this.$store.state.isLoggedIn) {
          await this.syncAds(true);
        } else {
          await this.syncAds(false);
        }
        */
      }

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`connectBucketMobilityGuru load time = ${diffs} s`);

      return ok;
    },

    async connectKintoHttpAuth() {
      let ok = true;

      let refreshed = false;

      let kintoHttpTmp = this.$store.state.kintoHttp;
      if (kintoHttpTmp === null) {
        // first load
        refreshed = true;
      }

      try {
        kintoHttpTmp.bucket(Config.BUCKET_MOBILITY_GURU_NAME);
        this.debug("connectKintoHttpAuth() Page not refreshed");
        refreshed = false;
      } catch (e) {
        //this.debug()("connectKintoHttpAuth() Page was refreshed");
        refreshed = true;
      }

      if ((await this.isLoggedInOIDC()) == true && refreshed === true) {
        // http kinto object
        let headers = {
          Authorization:
            this.$store.state.openid.providers[0].header_type +
            " " +
            this.$store.state.tokens.access_token,
        };

        let kintoHttp = new KintoClient(Config.KINTO_SERVER_IP, {
          headers: headers,
        });

        this.$store.commit("set", ["kintoHttp", kintoHttp]);

        let httpBucketMobilityGuru = kintoHttp.bucket(
          Config.BUCKET_MOBILITY_GURU_NAME
        );
        this.$store.commit("set", [
          "httpBucketMobilityGuru",
          httpBucketMobilityGuru,
        ]);

        // http kinto object collections
        let httpCollectionProfiles = httpBucketMobilityGuru.collection(
          Config.COLLECTION_PROFILES_NAME
        );
        this.$store.commit("set", [
          "httpCollectionProfiles",
          httpCollectionProfiles,
        ]);
        let httpCollectionAds = httpBucketMobilityGuru.collection(
          Config.COLLECTION_ADS_NAME
        );
        this.$store.commit("set", ["httpCollectionAds", httpCollectionAds]);

        let httpCollectionForum = httpBucketMobilityGuru.collection(
          Config.COLLECTION_FORUM_NAME
        );
        this.$store.commit("set", ["httpCollectionForum", httpCollectionForum]);

        let httpCollectionArticles = httpBucketMobilityGuru.collection(
          Config.COLLECTION_ARTICLES_NAME
        );
        this.$store.commit("set", [
          "httpCollectionArticles",
          httpCollectionArticles,
        ]);

        let httpCollectionRotatingBannerAds = httpBucketMobilityGuru.collection(
          Config.COLLECTION_ROTATING_BANNER_ADS_NAME
        );
        this.$store.commit("set", [
          "httpCollectionRotatingBannerAds",
          httpCollectionRotatingBannerAds,
        ]);

        this.debug("initialized kintoHTTP");
      }

      return ok;
    },

    async syncAds(mustBeLoggedIn = true) {
      let ok = false;

      if (this.$store.state.syncedAds === false) {
        try {
          let collectionAds = this.$store.state.collectionAds;
          this.debug(`syncAds() called, mustBeLoggedIn = ${mustBeLoggedIn}`);
          ok = await this.sync(collectionAds, mustBeLoggedIn);

          if (ok) {
            this.$store.commit("set", ["syncedAds", true]);
          }
        } catch (e) {
          this.debug(e);
        }
      } else {
        ok = true;
      }

      return ok;
    },

    async mainInitParallel0() {
      let jobNames = ["getOIDCInfo()"];

      let jobs = [];

      //jobs.push(this.connectBucketMobilityGuru());

      jobs.push(this.getOIDCInfo());

      let results = [];

      let i = 0;
      for (let job of jobs) {
        results.push({
          ok: await job,
          jobName: jobNames[i],
        });
        i++;
      }

      this.debug("mainInitParallel0()");
      this.debug(results);

      return results;
    },

    // can be safely called multiple times
    async mainInit() {
      let ok = true;
      let start = new Date();

      let results = await this.taskRunner(
        this.mainInitParallel0,
        "mainInitParallel0"
      );

      this.$store.commit("set", ["currentPath", this.$route.path]);

      let len = results.length;
      for (let i = 0; i < len; i++) {
        ok = ok && results[i].ok;
      }

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`mainInit load time = ${diffs} s`);

      return ok;
    },

    isPrivilegedUser() {
      let privileged = false;
      if (
        this.$store.state.isSuperAdmin ||
        this.$store.state.isCountrymanager ||
        this.$store.state.isSales ||
        this.$store.state.isDev
      ) {
        privileged = true;
      } else {
        privileged = false;
      }
      return privileged;
    },

    calculateDays(date) {
      let label = "";
      let present_date = Date.now();
      let createdDate = Date.parse(date);
      let days = (present_date - createdDate) / (1000 * 3600 * 24);
      let hours = days * 24;

      if (hours < 1) {
        let numOfMins = Math.floor(hours * 60);
        this.debug(numOfMins);
        if (numOfMins < 2) {
          label = `Just now`;
        } else {
          label = `${numOfMins} minutes ago`;
        }
      } else if (days < 2) {
        let numOfHours = Math.floor(days * 24);
        this.debug(numOfHours);
        if (numOfHours == 1) {
          label = `${numOfHours} hour ago`;
        } else {
          label = `${numOfHours} hours ago`;
        }
      } else {
        let numOfDays = Math.floor(days);
        this.debug(days);
        this.debug(`Number of days${numOfDays}`);
        label = `${numOfDays} days ago`;
      }
      this.debug(label);

      return label;
    },

    checkIsDateExpired(date) {
      let expired = false;
      let present_date = Date.now();

      let expiryDate = Date.parse(date);
      this.debug(expiryDate + "->" + present_date);
      let days = (expiryDate - present_date) / (1000 * 3600 * 24);
      this.debug(days);
      if (expiryDate - present_date < 0) {
        expired = true;
      }
      return expired;
    },

    async getIPAddress() {
      let ok = true;

      try {
        let url = "https://ip.seeip.org/jsonip";

        let res = await fetch(url);
        let jsonRes = await res.json();
        this.printJson(jsonRes, "getIPAddress ip.seeip.org jsonRes");

        this.$store.commit("set", ["currentIP", jsonRes.ip]);
        ok = true;
      } catch (e) {
        ok = false;
        //this.debug(e);
      }

      if (ok === false) {
        try {
          let url = "https://api.ipify.org?format=json";

          let res = await fetch(url);
          let jsonRes = await res.json();
          this.printJson(jsonRes, "getIPAddress api.ipify.org jsonRes");

          this.$store.commit("set", ["currentIP", jsonRes.ip]);
          ok = true;
        } catch (e) {
          ok = false;
          //this.debug(e);
        }
      }
      return ok;
    },

    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },

    checkSwear(phrase) {
      /* check the word or phrase wwhether i does have any swear words */
      let wordArray = [];
      let swearWords = Config.SWEAR_WORDS;
      let check;
      for (let i = 0; i < swearWords.length; i++) {
        let word = " " + swearWords[i] + " ";
        check = phrase.toLowerCase().indexOf(word);
        this.debug(check);
        if (check != -1) {
          wordArray.push(word);
        }
      }
      return wordArray;
    },

    async isSubmitAdAvailable() {
      let ok = false;
      let acknowledgeTitle = "";
      let acknowledgeMessage = "";
      let showActionBtn = false;
      let actionName = "";

      if (this.$store.state.isLoggedIn === false) {
        acknowledgeTitle = "Login Required";
        acknowledgeMessage =
          "Please note you need to be a Signed up member to submit an ad. Please Signup or Login to place a free ad.";
        ok = true;
      } else {
        // user is logged in
        if (this.$store.state.accessTokenDecoded.email_verified === false) {
          acknowledgeTitle = "Email Unverified";
          acknowledgeMessage =
            "Please verify your email in My MobilityGuru before submitting an ad.";
          if (this.$route.path !== Config.ROUTE_PROFILE) {
            showActionBtn = true;
            actionName = "My MobilityGuru";
          }
          ok = true;
        } else if (this.$store.state.hasAddress === false) {
          acknowledgeTitle = "Set Address";
          acknowledgeMessage =
            "Please enter your address in My MobilityGuru first";
          if (this.$route.path !== Config.ROUTE_PROFILE) {
            showActionBtn = true;
            actionName = "My MobilityGuru";
          } else {
            this.goToProfileWithHash("#SetAddress");
          }
          ok = true;
        } else {
          // user is logged in and email is verified
          await this.$router.push(Config.ROUTE_SUBMIT_AD);
        }
      }

      return {
        ok: ok,
        acknowledgeMessage: acknowledgeMessage,
        acknowledgeTitle: acknowledgeTitle,
        showActionBtn: showActionBtn,
        actionName: actionName,
      };
    },

    async goToProfileWithHash(hash) {
      if (this.$route.hash !== hash) {
        await this.$router.push(Config.ROUTE_PROFILE + hash);
      }
    },

    /*
    async submitAdOrig() {
      if (
        this.$store.state.accessTokenDecoded.email_verified === true &&
        this.$store.state.isLoggedIn
      ) {
        await this.$router.push(Config.ROUTE_SUBMIT_AD);
      } else {
        if (
          !this.$store.state.accessTokenDecoded.email_verified &&
          this.$store.state.isLoggedIn
        ) {
          this.acknowledgeTitle = "Email Unverified";
          this.acknowledgeMessage =
            "Please verify your email before submitting an ad.";
        } else if (!this.$store.state.isLoggedIn) {
          this.acknowledgeTitle = "Login Required";
          this.acknowledgeMessage = "Please login first.";
        }
        this.showAcknowledge = true;
      }
    },
    */

    /* This function taskes queries from the live-ads route only to do the filter */
    routerModify(field, value, adType) {
      this.debug("adType here " + adType);
      let query = clone(this.$route.query);
      let queries = [
        "mainCategory",
        "subCategory",
        "title",
        "suburb",
        "postcode",
        "condition",
        "category",
        "search",
        "userId",
        "adStatus",
        "state",
      ];
      for (let key in query) {
        let object = queries.includes(key);
        if (object === false) {
          delete query[key];
        }
      }
      /*Keep mainCategory or subCategory on the route, both queries can not be in the route at a time 
      & also remove the category from the route when click a mainCategory or subCategory after category search */
      this.printJson(query, "queries in the path ");
      if (field == "mainCategory") {
        delete query["subCategory"];
        delete query["category"];
      }
      if (field == "subCategory") {
        let newField;
        for (let i = 0; i < Config.MAIN_CATEGORIES_ENUM.MISCELLANEOUS; i++) {
          let object = includes(Config.SUB_CATEGORIES_LIST[i], value);
          if (object === true) {
            newField = Config.MAIN_CATEGORIES[i];
          }
        }
        query["mainCategory"] = newField;
        delete query["category"];
      }
      if (field == "category") {
        delete query["subCategory"];
        delete query["mainCategory"];
      }
      /*Remove the filter if values are empty */
      if (value === "") {
        // delete it from query if it exists
        if (field in query) {
          delete query[field];
          this.printJson(query, "Deleting query");
        }
      }
      /*Add  new queries to route*/
      if (value !== undefined && value !== "") {
        // modify it if it existed, else this will add it in:
        query[field] = value;
        this.printJson(query, "Inserting query");
      }
      /*This part of code is used for the category filter when clicked on the icons showing in large screens.*/
      /*both my-ads anlive-ads routes are checked before routing */
      if (adType == undefined) {
        let path = this.$route.path;
        this.debug("path" + path);
        if (path == Config.ROUTE_MY_ADS) {
          adType = Config.AD_TYPES_ENUM.MY_ADS;
        } else if (path == Config.ROUTE_USER_ADS) {
          adType = Config.AD_TYPES_ENUM.USER_ADS;
        } else {
          adType = Config.AD_TYPES_ENUM.PUBLIC_ADS;
        }
      }
      let obj = clone(this.$route.query);
      if (isEqual(query, obj) == false) {
        if (adType == Config.AD_TYPES_ENUM.MY_ADS) {
          this.$router.push({
            path: Config.ROUTE_MY_ADS,
            query: query,
          });
        }
        if (adType == Config.AD_TYPES_ENUM.PUBLIC_ADS) {
          this.$router.push({
            path: Config.ROUTE_LIVE_ADS,
            query: query,
          });
        }
        if (adType == Config.AD_TYPES_ENUM.USER_ADS) {
          this.$router.push({
            path: Config.ROUTE_USER_ADS,
            query: query,
          });
        }
      } else {
        /*When Search Ads button pressed in app bar without any queries this will route to Live-ads route if it's not in Live-ads route */
        if (this.$route.path != Config.ROUTE_MY_ADS) {
          if (
            this.$route.path === Config.ROUTE_LIVE_ADS ||
            this.$route.path === Config.ROUTE_USER_ADS
          ) {
            this.debug(`No Routing for the same queries ${this.$route.path}`);
          } else {
            this.$router.push({
              path: Config.ROUTE_LIVE_ADS,
            });
          }
        } else {
          this.debug(`No Routing for the same queries ${this.$route.path}`);
        }

        // history.go();
      }
    },

    /* Checks if the URL query contains fromRegistration. If it does and is true, get the tab_id as well.
     *
     * query - the URL route query
     *
     * returns an object with these fields:
     *    fromRegistration - Boolean true or false.
     *    tab_id - the tab_id value
     *
     */
    checkFromRegistration() {
      let query = clone(this.$route.query);
      this.printJson(query, "queries on the registration");
      let tab_id = "";
      let fromRegistration = query.fromRegistration;
      this.debug(`fromRegistration = ${fromRegistration}`);
      this.printJson(this.$store.state.openid, "openid");
      this.debug(`typeof fromRegistration = ${typeof fromRegistration}`);

      if (fromRegistration !== undefined && fromRegistration === "true") {
        fromRegistration = true;
        tab_id = query.tab_id;
        this.debug(`tab_id = ${tab_id}`);
      } else {
        fromRegistration = false;
      }

      this.debug(`fromRegistration = ${fromRegistration}`);
      return {
        tab_id: tab_id,
        fromRegistration: fromRegistration,
      };
    },

    async checkCountryManagerStatus(id, role) {
      let ok = false;
      // let message = "";

      let url = `${Config.NODE_SERVER_IP}${Config.HAS_EFFECTIVE_ROLE}`;

      let headers = {
        Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        Accept: "application/json",
      };

      let payload = new FormData();
      payload.append("userID", id);
      payload.append("role", role);
      this.debug(id);

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "check id for country manager status ");

        ok = jsonRes.ok;
        if (ok === true) {
          this.debug(`Success ok = ${ok}`);
        }
      } catch (e) {
        console.error(e);
      }
      return ok;
    },

    goBackToRegistration(tab_id) {
      // eg. http://localhost:8180/auth/realms/mobilityguruLocal/login-actions/registration?client_id=mobilityguruClientLocal&tab_id=gFgc3yvc1j8
      let url =
        this.$store.state.openid.providers[0].issuer +
        "/login-actions/registration?client_id=" +
        this.$store.state.openid.providers[0].client_id +
        "&tab_id=" +
        tab_id;

      this.debug(`url = ${url}`);
      location.href = url;
    },

    toUpper(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          if (word[0] !== undefined) {
            this.debug("First capital letter: " + word[0]);
            this.debug("remain letters: " + word.substr(1));
            return word[0].toUpperCase() + word.substr(1);
          }
        })
        .join(" ");
    },

    getPosts() {
      let allPosts = this.$store.state.collectionForum.list();
      this.printJson(allPosts, "Posts111111111111111111111 of Main");
    },

    getArticles() {
      let allArticles = this.$store.state.collectionArticles.list();
      this.printJson(allArticles, "Article1111 of Main");
    },

    gotoAdView(adId, adType, hash = "") {
      this.$router.push({
        path: Config.ROUTE_AD_VIEW,
        query: {
          adType: adType,
          id: adId,
        },
        hash: hash,
      });
    },

    async goToArticleView(articleId) {
      this.$router.push({
        path: Config.ROUTE_ARTICLE_VIEW,
        query: {
          articleId: articleId,
        },
      });
    },

    async goToFavouriteAds(adId) {
      this.$router.push({
        path: Config.ROUTE_FAVOURITE_ADS,
        query: {
          adId: adId,
        },
      });
    },

    async goToArticles(articleId) {
      this.$router.push({
        path: Config.ROUTE_ARTICLES,
        query: {
          articleId: articleId,
        },
      });
    },

    async gotoSellerAds(userId, adType) {
      let path = "";
      if (adType == Config.AD_TYPES_ENUM.PUBLIC_ADS) {
        path = Config.ROUTE_LIVE_ADS;
      }
      if (adType == Config.AD_TYPES_ENUM.USER_ADS) {
        path = Config.ROUTE_USER_ADS;
      }
      this.$router.push({
        path: path,
        query: {
          userId: userId,
        },
      });
    },

    async gotoUserAds(userId) {
      //this.debug(item);
      this.$router.push({
        path: Config.ROUTE_USER_ADS,
        query: {
          userId: userId,
        },
      });
      /*if (this.$store.state.isSuperAdmin === true) {
        this.$router.push({
          path: Config.ROUTE_USER_ADS,
          query: {
            userId: item.id,
          },
        });
      } else if (this.$store.state.isCountrymanager === true) {
        if ("superadmin" in item && item.superadmin === true) {
          this.debug(`Access Denied`);
        } else {
          this.$router.push({
            path: Config.ROUTE_USER_ADS,
            query: {
              userId: item.id,
            },
          });
        }
      }*/
    },

    async clearFilter() {
      // let adType = "";
      if (this.$route.path == Config.ROUTE_LIVE_ADS) {
        // adType = Config.AD_TYPES_ENUM.PUBLIC_ADS;
        await this.$router.push(Config.ROUTE_LIVE_ADS);
      } else if (this.$route.path == Config.ROUTE_MY_ADS) {
        //adType = Config.AD_TYPES_ENUM.MY_ADS;
        await this.$router.push(Config.ROUTE_MY_ADS);
      } else if (this.$route.path == Config.ROUTE_USER_ADS) {
        //adType = Config.AD_TYPES_ENUM.USER_ADS;
        await this.$router.push(Config.ROUTE_USER_ADS);
      }
      /* if (adType == Config.AD_TYPES_ENUM.MY_ADS) {
        await this.$router.push(Config.ROUTE_MY_ADS);
      } else if (
        adType == Config.AD_TYPES_ENUM.USER_ADS
      ) {
    
        this.$router.push({
          path: Config.ROUTE_USER_ADS,
          query: {
            userId: this.$route.query.userId,
          },
        });
      } else if (adType == Config.AD_TYPES_ENUM.PUBLIC_ADS) {
        await this.$router.push(Config.ROUTE_LIVE_ADS);
      }*/
    },

    async goToMyTopics(id) {
      this.$router.push({
        path: Config.ROUTE_FORUM,
        query: {
          id: id,
        },
      });
    },

    hideDrawer() {
      /* The navigation drawer will be closed according to the screen size change  */
      if (window.innerWidth > "960") {
        this.drawer = false;
      }
    },
    /*
     * Tests whether the all the keys in levels are in object
     * example object:
     *  let a = {
     *    b: "b",
     *    c: {
     *      d: "d",
     *      e: "e"
     *    }
     *  }
     *
     *
     * levels - array of fields representing nested objects.
     *   eg. ["c", "d"] represents a.c.d
     *   eg. ["c"] represents a.c
     *   eg. ["c", "f", "g"] represents a.c.f.g
     *
     * object - the object
     */
    areLevelsInObject(levels, object) {
      /*return whether the level in the ad exists*/
      let allLevelsExist = true;

      let currentLevel = object;

      let numLevels = levels.length;

      for (let i = 0; i < numLevels; i++) {
        let field = levels[i];
        if (field in currentLevel === false) {
          allLevelsExist = false;
          break;
        }
        currentLevel = currentLevel[field];
      }
      return allLevelsExist;
    },

    regexAdFilter(pattern, searchString) {
      /*returns whether the string matches with the field in Ads*/
      //this.debug("pattern" + pattern);
      let regex = new RegExp(pattern, "i");
      let obj = searchString.match(regex);
      //this.debug("regex return" + obj);
      return obj;
    },

    /* get field value of the ad given the levels.
     *
     * eg.
     *
     * levels = ["addressObj", "suburb"];
     *
     * will return the ad.addressObj.suburb field
     */

    getFieldValue(levels, ad) {
      /*get the value of field for searchString*/
      let num = levels.length;
      let value = "";

      let object = ad;

      for (let i = 0; i < num; i++) {
        let field = levels[i];

        if (i === num - 1) {
          value = object[field];
          this.printJson("fsbbd", value);
        } else {
          object = object[field];
        }
      }
      return value;
    },

    applyFilter(levels, pattern, ads, adIds) {
      /*returns the ads after custom filtering*/
      let numberOfAds = ads.length;
      for (let i = numberOfAds - 1; i > -1; i--) {
        let ad = ads[i];
        let allLevelsExist = this.areLevelsInObject(
          levels,
          ad
        ); /*Check  the level existance in ad */
        this.debug(`allLevelsExist = ${allLevelsExist}`);
        if (allLevelsExist === true) {
          let searchString = this.getFieldValue(
            levels,
            ad
          ); /*returns the field value to searchString*/
          this.debug("search string" + searchString);

          let value = this.regexAdFilter(
            pattern,
            searchString
          ); /*returns the search pattern value */
          this.debug("Check Value" + value);
          if (value === null) {
            adIds.splice(adIds.indexOf(ad.id), 1);
            remove(ads, ad);
          }
        } else {
          adIds.splice(adIds.indexOf(ad.id), 1);
          remove(ads, ad);
        }
      }
      return {
        ads: ads,
        adIds: adIds,
      };
    },

    async refreshLiveAdIds(
      pageIndex,
      adsPerPage,
      adsFilters,
      searchFilters = {},
      sort
    ) {
      let liveAds = [];
      let liveAdIds = [];
      let hasNextPage = false;
      let totalAds = 0;
      let country = "";
      let topMainCategoryAds = [];
      let topMainCategoryAdIds = [];
      // if (this.$store.state.syncedLiveAdIds === false) {
      try {
        //  let collectionAds = this.$store.state.collectionAds;
        adsFilters = {};

        adsFilters = {
          published: [true],
        };

        if (this.$route.path == Config.ROUTE_HOME) {
          country = this.$store.state.countryByIP;
          this.debug(this.$route.path);
        } else {
          country = this.$store.state.country;
        }

        if (country !== "All" && country !== "") {
          this.debug(`filter for country ${this.$store.state.country}`);
          adsFilters["country"] = country;
        }

        if (this.$route.query.userId !== undefined) {
          this.debug(`filter for owner added ${this.$route.query.userId}`);
          adsFilters["owner"] = this.$route.query.userId;
        }
        this.printJson("refreshAdIds called");
        this.debug(searchFilters);
        let filteredAds = await this.refreshAdIds(
          pageIndex,
          adsPerPage,
          adsFilters,
          searchFilters,
          sort
        );
        liveAds = filteredAds.ads;
        liveAdIds = filteredAds.adIds;
        hasNextPage = filteredAds.hasNextPage;
        totalAds = filteredAds.totalAds;
        topMainCategoryAds = filteredAds.topMainCategoryAds;
        topMainCategoryAdIds = filteredAds.topMainCategoryAdIds;
        //this.printJson(liveAds, "Final filtered Live Ads in Main.js");
        this.$store.commit("set", ["liveAds", liveAds]);
        this.$store.commit("set", ["liveAdIds", liveAdIds]);
        this.$store.commit("set", ["syncedLiveAdIds", true]);
      } catch (e) {
        this.debug("err " + e);
      }
      /* } else {
         liveAds = this.$store.state.liveAds;
         liveAdIds = this.$store.state.liveAdIds;
       }*/

      return {
        ads: liveAds,
        adIds: liveAdIds,
        hasNextPage: hasNextPage,
        totalAds: totalAds,
        topMainCategoryAdIds: topMainCategoryAdIds,
        topMainCategoryAds: topMainCategoryAds,
      };
    },

    async refreshMyAdIds(
      pageIndex = 0,
      adsPerPage,
      adsFilters,
      searchFilters = {},
      sort
    ) {
      let myAds = [];
      let myAdIds = [];
      let hasNextPage = false;
      let totalAds = 0;
      let topMainCategoryAdIds = [];
      let topMainCategoryAds = [];

      if (await this.isLoggedInOIDC()) {
        //  if (this.$store.state.syncedMyAdIds === false) {
        try {
          //let collectionAds = this.$store.state.collectionAds;

          adsFilters = {
            owner: [this.$store.state.accessTokenDecoded.sub],
          };
          this.debug("refreshMyAdIds called" + searchFilters);

          let filteredAds = await this.refreshAdIds(
            pageIndex,
            adsPerPage,
            adsFilters,
            searchFilters,
            sort
          );
          myAds = filteredAds.ads;
          myAdIds = filteredAds.adIds;
          hasNextPage = filteredAds.hasNextPage;
          totalAds = filteredAds.totalAds;
          (topMainCategoryAdIds = filteredAds.topMainCategoryAdIds),
            (topMainCategoryAds = filteredAds.topMainCategoryAds);
          //this.printJson(myAds, "Final filtered Ads in Main.js");
          this.debug(this.$route.path);

          this.$store.commit("set", ["myAds", myAds]);
          this.$store.commit("set", ["myAdIds", myAdIds]);
          this.$store.commit("set", ["syncedMyAdIds", true]);
        } catch (e) {
          this.debug("err " + e);
        }
        /*} else {
          myAds = this.$store.state.myAds;
          myAdIds = this.$store.state.myAdIds;
        }*/
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ads: myAds,
        adIds: myAdIds,
        hasNextPage: hasNextPage,
        totalAds: totalAds,
        topMainCategoryAdIds: topMainCategoryAdIds,
        topMainCategoryAds: topMainCategoryAds,
      };
    },

    async refreshUserAdIds(
      pageIndex,
      adsPerPage,
      adsFilters,
      searchFilters = {},
      sort,
      userId
    ) {
      let userAds = [];
      let userAdIds = [];
      let hasNextPage = false;
      let totalAds = 0;
      adsFilters = {};
      let topMainCategoryAdIds = [];
      let topMainCategoryAds = [];

      if (
        (await this.isLoggedInOIDC()) &&
        (this.$store.state.isSuperAdmin === true ||
          this.$store.state.isCountrymanager === true ||
          this.$store.state.isSales === true)
      ) {
        try {
          if (userId.length > 0) {
            this.debug(`filter for owner added ${userId}`);
            adsFilters["owner"] = userId;
          }

          this.printJson("refreshUserAdIds called");
          this.debug(searchFilters);

          let filteredAds = await this.refreshAdIds(
            pageIndex,
            adsPerPage,
            adsFilters,
            searchFilters,
            sort
          );
          userAds = filteredAds.ads;
          userAdIds = filteredAds.adIds;
          hasNextPage = filteredAds.hasNextPage;
          totalAds = filteredAds.totalAds;
          (topMainCategoryAdIds = filteredAds.topMainCategoryAdIds),
            (topMainCategoryAds = filteredAds.topMainCategoryAds);
          //this.printJson(userAds, "Final filtered Ads in Main.js");
          this.debug(this.$route.path);

          this.$store.commit("set", ["userAds", userAds]);
          this.$store.commit("set", ["userAdIds", userAdIds]);
          this.$store.commit("set", ["syncedUserAdIds", true]);
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ads: userAds,
        adIds: userAdIds,
        hasNextPage: hasNextPage,
        totalAds: totalAds,
        topMainCategoryAdIds: topMainCategoryAdIds,
        topMainCategoryAds: topMainCategoryAds,
      };
    },

    async refreshPosts(key, value) {
      let resJson = {};
      try {
        this.debug(`refreshPosts() Forum`);

        let url = `${Config.NODE_SERVER_IP}${Config.GET_FORUM_POSTS}`;
        if (value.length > 0) {
          url = `${Config.NODE_SERVER_IP}${Config.GET_FORUM_POSTS}?${key}=${value}`;
        }

        let headers = {
          Accept: "application/json",
          /*Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,*/
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);

        /*let collectionForum = this.$store.state.collectionForum;
        let results = await collectionForum.list();

        posts = results.data;
        this.printJson(posts, "111111111111111111111");
        let postsObj = this.applyFilter(levels, filter, posts, postIds);
        posts = postsObj.ads;
        this.printJson(posts, "Collection Forum");*/
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async refreshArticles(key, value) {
      let resJson = {};
      try {
        this.debug(`refreshArticle() Articles`);

        let url = `${Config.NODE_SERVER_IP}${Config.GET_ARTICLES}`;
        if (value.length > 0) {
          url = `${Config.NODE_SERVER_IP}${Config.GET_ARTICLES}?${key}=${value}`;
        }

        let headers = {
          Accept: "application/json",
        };

        let fetchData = {
          method: "POST",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    /* Able to get OIDC Info without logging in, but we only need it if
     * we're logged in.
     */
    async getOIDCInfo() {
      let ok = false;
      if (this.$store.state.openid === null) {
        let kintoClient = new KintoClient(Config.KINTO_SERVER_IP);

        this.debug("kintoClient.fetchServerInfo()");
        let serverInfo = await kintoClient.fetchServerInfo();

        if ("capabilities" in serverInfo) {
          if ("openid" in serverInfo.capabilities) {
            if ("providers" in serverInfo.capabilities.openid) {
              this.$store.commit("set", [
                "openid",
                serverInfo.capabilities.openid,
              ]);
              this.printJson(this.$store.state.openid, "openid");
              ok = true;
            }
          }
        }
      } else {
        this.debug("openid persisted");
        ok = true;
      }

      return ok;
    },

    /* 1. Refreshes JWT
     * 2. Synchronizes the profile on Kinto to the JWT
     */
    async OIDCRefreshToken() {
      let ok = false;

      // try to obtain a new token via refresh token
      let tokenEndpoint =
        this.$store.state.openid.providers[0].issuer +
        "/protocol/openid-connect/token";
      this.debug("tokenEndpoint = " + tokenEndpoint);

      let headers = {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      };

      this.printJson(headers, "headers");

      try {
        let res = await fetch(tokenEndpoint, {
          method: "POST",
          headers: headers,
          body: `grant_type=refresh_token&client_id=${encodeURIComponent(
            this.$store.state.accessTokenDecoded.azp
          )}&refresh_token=${this.$store.state.tokens.refresh_token}`,
        });

        this.debug("refresh_token headers");
        this.debug(res.headers);

        this.debug("refresh_token res");
        this.debug(
          `grant_type=refresh_token&client_id=${encodeURIComponent(
            this.$store.state.accessTokenDecoded.azp
          )}&refresh_token=${this.$store.state.tokens.refresh_token}`
        );

        let tokens = await res.json();

        this.$store.commit("set", ["tokens", tokens]);
        let accessTokenDecoded = jwt_decode(tokens.access_token);
        this.$store.commit("set", ["accessTokenDecoded", accessTokenDecoded]);

        this.printJson(tokens, "refresh_token tokens");

        /* use the refreshed access token to update the Kinto profile
         * with any changes
         */
        this.updateKintoProfile(accessTokenDecoded);

        ok = true;
      } catch (e) {
        console.error(e);
      }

      return ok;
    },

    /* Checks if is access token is still valid? If access token expired,
     * tries to obtain new access token (via refresh token).
     *
     * refresh = true will try to refresh access token, if expired
     */
    async isLoggedInOIDC(refresh = true) {
      let loggedIn = false;

      if ("exp" in this.$store.state.accessTokenDecoded) {
        let now = new Date(); // ms
        this.debug("now = " + now);
        let timeLeftMins =
          (this.$store.state.accessTokenDecoded.exp * 1000 - now) / (1000 * 60);
        this.debug(`isLoggedInOIDC() timeLeftMins = ${timeLeftMins}`);

        let expDate = new Date(this.$store.state.accessTokenDecoded.exp * 1000);

        // iat in seconds
        let timeLeftMinsRefreshToken =
          ((this.$store.state.accessTokenDecoded.iat +
            this.$store.state.tokens.refresh_expires_in) *
            1000 -
            Date.now()) /
          (1000 * 60);

        this.debug(
          "Refresh Token has " + timeLeftMinsRefreshToken + " mins left. "
        );

        // now in ms
        if (
          now >=
          (this.$store.state.accessTokenDecoded.exp -
            Config.OIDC_REFRESH_TOKEN_BUFFER_SECS) *
            1000
        ) {
          this.debug(
            `Token has ${Config.OIDC_REFRESH_TOKEN_BUFFER_SECS} or less, refreshing`
          );

          if (refresh === true) {
            // check if refresh token has expired
            if (timeLeftMinsRefreshToken < 0) {
              this.debug("Refresh Token expired");
            } else {
              loggedIn = await this.OIDCRefreshToken();
            }
          }
        } else {
          this.debug("Token valid " + timeLeftMins + " mins left. " + expDate);

          loggedIn = true;
        }
      } else {
        this.debug("no exp in accessTokenDecoded");
      }

      this.$store.commit("set", ["isLoggedIn", loggedIn]);
      return loggedIn;
    },

    async deleteAd(adId, reason = "") {
      //let ok0 = false;
      let ok = false;
      let message = "";
      this.debug("deleteAd a");

      if (await this.isLoggedInOIDC()) {
        this.debug(`deleteAd b reason = ${reason}`);
        try {
          let url = `${Config.NODE_SERVER_IP}${Config.DELETE_AD}?adId=${adId}&reason=${reason}`;

          let headers = {
            Accept: "application/json",
            Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `deleteAd() resJson`);

          if (resJson.ok === true) {
            ok = true;
          } else {
            ok = false;
            message = resJson.message;
          }
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    //not used in any part of components
    async deleteAdOrig(adId, reason = "") {
      //let ok0 = false;
      let ok = false;
      let message = "";
      this.debug("deleteAd a");

      if (await this.isLoggedInOIDC()) {
        this.debug("deleteAd b");
        try {
          let result = await this.deleteRaffleTicket(adId);
          this.printJson(result, "delete res of raffle");
          if (result.ok == true) {
            this.debug(`Raffle ticket deleted for this ad ${result.ticket}`);
          } else {
            this.debug(
              `Raffle ticket not exist or this ad is a valid ticket for draw`
            );
          }

          result = await this.deleteTopAd(adId);

          if (result.ok === true) {
            this.debug(`top ad record deleted for this ad`);
          } else {
            this.debug(`top ad  not exist`);
          }
          let collectionAds = this.$store.state.collectionAds;

          // delete any attachments
          let url = `${Config.NODE_SERVER_IP}${Config.DELETE_ALL_ADFILES_FOR_SINGLE_AD}?adId=${adId}&reason=${reason}`;

          this.debug(`deleteAd url = ${url}`);

          let headers = {
            Accept: "application/json",
            Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `deleteAd() resJson`);

          if (resJson.ok === true) {
            // sync
            ok = await this.sync(collectionAds, true);

            this.$store.commit("set", ["syncedMyAdIds", false]);
            this.$store.commit("set", ["syncedLiveAdIds", false]);

            let updateNumResult = await this.updateNumAdsAndImages();
            ok = updateNumResult.ok;
            message = updateNumResult.message;
          } else {
            ok = false;
            message = resJson.message;
          }
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async updateKintoProfile(jsonObject) {
      let resJson;

      if (await this.isLoggedInOIDC()) {
        try {
          let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_KINTO_PROFILE}`;

          let headers = {
            Accept: "application/json",
            Authorization:
              this.$store.state.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
          };

          let payload = new FormData();
          if ("emailSubscription" in jsonObject) {
            payload.append("emailSubscription", jsonObject.emailSubscription);
          }

          if ("address" in jsonObject) {
            payload.append("address", JSON.stringify(jsonObject.address));
          }

          if ("phone" in jsonObject) {
            payload.append("phone", jsonObject.phone);
          }

          if ("merchant" in jsonObject) {
            payload.append("merchant", jsonObject.merchant);
          }

          let fetchData = {
            method: "POST",
            headers: headers,
            body: payload,
          };

          let res = await fetch(url, fetchData);

          resJson = await res.json();
        } catch (e) {
          console.error(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return resJson;
    },

    /* Update Kinto records for user given their access token
     * On return
     *  userHasProfile - true if user has an existing Kinto profile.
     *  profile - user's profile if user has profile, otherwise null
     *  changed - true if the user profile fields in accessTokenDecoded is
     *    different from the Kinto records and the Kinto ecords have been
     *    updated.
     *  error - empty string if no error, otherwise the error string
     */
    // TODO: Delete
    /*
    async updateKintoProfileOrig(accessTokenDecoded) {
      let userHasProfile = false;
      let profile = null;
      let changed = false;
      let error = "";

      if (await this.isLoggedInOIDC()) {
        try {
          let { ok, record } = await this.getProfile(accessTokenDecoded);

          if (ok === true && record.data.length != 0) {
            userHasProfile = true;

            profile = record.data[0];

            let patchRecord = { id: accessTokenDecoded.sub };

            if (profile.email_verified != accessTokenDecoded.email_verified) {
              patchRecord.email_verified = accessTokenDecoded.email_verified;
              changed = true;
            }

            if (profile.name != accessTokenDecoded.name) {
              patchRecord.name = accessTokenDecoded.name;
              changed = true;
            }

            // backwards compatibility
            if ("username" in profile === false) {
              patchRecord.username = accessTokenDecoded.preferred_username;
              changed = true;
            }

            if (profile.given_name != accessTokenDecoded.given_name) {
              patchRecord.given_name = accessTokenDecoded.given_name;
              changed = true;
            }

            if (profile.family_name != accessTokenDecoded.family_name) {
              patchRecord.family_name = accessTokenDecoded.family_name;
              changed = true;
            }

            this.printJson(profile, "updateKintoProfile profile");
            this.printJson(
              accessTokenDecoded,
              "updateKintoProfile accessTokenDecoded"
            );

            let collectionProfiles = this.$store.state.collectionProfiles;

            if (changed == true) {
              let options = {
                patch: true,
              };

              try {
                let result = await collectionProfiles.update(
                  patchRecord,
                  options
                );

                this.printJson(patchRecord, "patchRecord");

                ok = await this.sync(collectionProfiles, true);
                if (ok === false) {
                  error = "sync() error";
                }

                this.$store.commit("set", [
                  "collectionProfiles",
                  collectionProfiles,
                ]);
                this.printJson(result, "update()");
              } catch (e) {
                console.error("err " + e);

                error = e;
              }
            } else {
              // backwards compatible: remove unused fields
              patchRecord = profile;

              if ("email" in patchRecord === true) {
                delete patchRecord.email;
                changed = true;
              }

              if ("preferred_username" in patchRecord === true) {
                delete patchRecord.preferred_username;
                changed = true;
              }

              if (changed === true) {
                try {
                  let result = await collectionProfiles.update(patchRecord);

                  this.printJson(patchRecord, "patchRecord");

                  ok = await this.sync(collectionProfiles, true);
                  if (ok === false) {
                    error = "sync() error backwards compatiblle function";
                  }

                  this.$store.commit("set", [
                    "collectionProfiles",
                    collectionProfiles,
                  ]);
                  this.printJson(result, "update()");
                } catch (e) {
                  console.error("err " + e);

                  error = e;
                }
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return { userHasProfile, profile, changed, error };
    },
    */

    synchSetTimeout(ms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    },

    printArray(arr, str) {
      this.debug(str);
      for (let i = 0; i < arr.length; i++) {
        let e = arr[i];
        this.debug(`${i}`);
        this.debug(e);
      }
    },

    deepCopyArrary(array) {
      let copy = [];
      for (let i = 0; i < array.length; i++) {
        copy[i] = array[i];
      }
      return copy;
    },

    async clearLocalDB() {
      await this.$store.state.collectionProfiles.clear();
      await this.$store.state.collectionAds.clear();
    },

    /*
    checkVersion() {
      let needToReload = false;

      if (this.$store.state.version === "") {
        // first load, save version to Vuex store
        this.$store.commit("set", ["version", Config.VERSION]);

        this.debug("checkVersion() first load");
      } else {
        // subsequent loads, check version number
        if (Config.VERSION !== this.$store.state.version) {
          this.debug("checkVersion() different");
          this.$store.commit("set", ["version", Config.VERSION]);
          needToReload = true;
        } else {
          this.debug("checkVersion() same");
        }
      }

      this.debug(
        `version = ${this.$store.state.version}, ${Config.VERSION}`
      );

      return needToReload;
    },
    */

    replaceKintoPlaceholdersHelper(str, givenName = "", familyName = "") {
      let ok = false;
      let returnStr = "";
      if (str !== undefined) {
        returnStr = str;

        if (givenName !== "") {
          const GIVEN_NAME_PLACEHOLDER = "$" + Config.GIVEN_NAME;
          if (str.includes(GIVEN_NAME_PLACEHOLDER)) {
            returnStr = returnStr.replace(GIVEN_NAME_PLACEHOLDER, givenName);
          }
        }

        if (familyName !== "") {
          const FAMILY_NAME_PLACEHOLDER = "$" + Config.FAMILY_NAME;
          if (str.includes(FAMILY_NAME_PLACEHOLDER)) {
            returnStr = returnStr.replace(FAMILY_NAME_PLACEHOLDER, familyName);
          }
        }

        ok = true;
      } else {
        ok = false;
        console.error("replaceKintoPlaceholdersHelper() undefined inputs");
      }

      return {
        ok: ok,
        returnStr: returnStr,
      };
    },

    /* Checks string to see if any Kinto placeholdeers exists and replaces
     * them with the field value in object
     *
     * eg. if object is
     *  {
     *   "name": "kyahoo oyahoo",
     *   "phone": "+61234232342",
     *   "address": {
     *     "full": "97 Stirling Hwy, Nedlands WA 6009, Australia",
     *     "state": "Western Australia",
     *     "street": "Stirling Hwy",
     *     "suburb": "Nedlands",
     *     "postcode": "6009"
     *   },
     *   "created": "2020-05-07T13:37:01.903Z",
     *   "username": "kean_sum@yahoo.com.au",
     *   "given_name": "kyahoo",
     *   "superadmin": true,
     *   "family_name": "oyahoo",
     *   "email_verified": true,
     *   "id": "eaea1f1d-86fc-4d85-9bdb-a4be21d428ce",
     *   "last_modified": 1592080345750,
     *   "_status": "synced"
     *  }
     *
     *  str = "Dear $given_name $family_name"
     *
     * this will return "Dear kyahoo oyahoo"
     *
     * Currently supported placeholders:
     * - $given_name
     * - $family_name
     */
    replaceKintoPlaceholders(str, object) {
      return this.replaceKintoPlaceholdersHelper(
        str,
        object[Config.GIVEN_NAME],
        object[Config.FAMILY_NAME]
      );
    },

    async taskRunner(fn, label) {
      const start = Date.now();
      this.debug(`Task ${label} starting...`);
      let result = await fn();
      const end = Date.now();
      let diffs = (end - start) / 1000;
      this.debug(`Task ${label} finished in ${diffs} s with:`);
      this.debug(result);
      return result;
    },

    async sendDebugEmail(message, title = "[DEBUG EMAIL]") {
      let url = `${Config.NODE_SERVER_IP}${Config.SEND_DEBUG_EMAIL}`;
      let headers = {
        Accept: "application/json",
      };

      let payload = new FormData();
      payload.append("message", message);
      payload.append("title", title);

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      let res = await fetch(url, fetchData);
      let jsonRes = await res.json();

      this.printJson(jsonRes, "sendDebugEmail() jsonRes");
    },

    async addRaffleTicket(adId) {
      let ok = false;
      let message = "";
      this.debug("addRaffleTicket");

      if (await this.isLoggedInOIDC()) {
        try {
          //let collectionAds = this.$store.state.collectionAds;

          let url = `${Config.NODE_SERVER_IP}${Config.GET_NEW_UNIQUE_RAFFLE_TICKET}?adId=${adId}`;

          this.debug(`addRaffleTicket url = ${url}`);

          let headers = {
            Accept: "application/json",
            Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `addRaffleTicket() resJson`);

          if (resJson.ok === true) {
            message = resJson.message;
          } else {
            ok = false;
            message = resJson.message;
          }
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteRaffleTicket(adId) {
      let ok = false;
      let message = "";
      this.debug("deleteRaffleTicket" + adId);

      if (await this.isLoggedInOIDC()) {
        try {
          //let collectionAds = this.$store.state.collectionAds;

          // delete any attachments
          let url = `${Config.NODE_SERVER_IP}${Config.DELETE_RAFFLE_TICKET}?adId=${adId}`;

          this.debug(`deleteRaffleTicket url = ${url}`);

          let headers = {
            Accept: "application/json",
            Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `deleteRaffleTicket() resJson`);
          ok = resJson.ok;
          if (resJson.ok == true) {
            message = resJson.message;
          } else {
            ok = false;
            message = resJson.message;
          }
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteKintoUserProfile(userId) {
      let collectionProfiles = this.$store.state.collectionProfiles;
      let ok = await this.sync(collectionProfiles, true);

      let res = await collectionProfiles.delete(userId);

      this.printJson(res);

      ok = await this.sync(collectionProfiles, true);
      this.debug(`deleteKintoUserProfile ok = ${ok}`);
    },

    async deleteKeycloakUser(userId) {
      let ok = false;

      let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_DELETE_USER}?userId=${userId}`;
      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);
        let jsonRes = await res.json();

        ok = jsonRes.ok;
        if (ok == true) {
          this.debug("Deleted user from keycloak");
        }
      } catch (e) {
        console.error(e);
      }

      return ok;
    },

    async pickThreeWinners() {
      let ok = false;
      let winners = [];
      let message = "";

      let url = `${Config.NODE_SERVER_IP}${Config.PICK_THREE_WINNERS}`;

      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        winners = jsonRes.winners;
        message = jsonRes.message;
        this.debug(jsonRes, "pickThreeWinners() jsonRes");
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        winners: winners,
      };
    },

    // generates  n = number of digits
    generateCodeDigits(n) {
      let str = "";
      for (let i = 0; i < n; i++) {
        str += Math.floor(Math.random() * 10);
      }
      return str;
    },

    async getProfilesRecord() {
      let ok = false;
      let message = "";
      let result = {};

      if ((await this.isLoggedInOIDC(false)) == true) {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_PROFILES}?id=${this.$store.state.accessTokenDecoded.sub}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.$store.state.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          this.debug(res, "getProfilesRecord()");
          let jsonRes = await res.json();
          this.printJson(jsonRes);
          if (jsonRes.ok === true) {
            result = jsonRes.result;
            message = jsonRes.message;
            ok = jsonRes.ok;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        ok = false;
        message = "not logged in";
      }

      this.debug(`ok = ${ok}, message = ${message}`);

      return {
        ok: ok,
        message: message,
        result: result,
      };
    },

    //get country phone prefix
    /*
    async fetchPhoneprefix(country) {
      let ok = false;
      let message = "";
      let phonePrefix;
      this.debug("getPhonePrefix");

      if (await this.isLoggedInOIDC()) {
        try {
          //let collectionAds = this.$store.state.collectionAds;

          let url = `${Config.NODE_SERVER_IP}${Config.GET_COUNTRY_CODE}?country=${country}`;

          this.debug(`getPhonePrefix url = ${url}`);

          let headers = {
            Accept: "application/json",
            Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `getPhonePrefix() resJson`);
          ok = resJson.ok;
          if (ok === true) {
            message = resJson.message;
            phonePrefix = resJson.phonePrefix;
          } else {
            ok = false;
            message = resJson.message;
          }
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        phonePrefix: phonePrefix,
      };
    },
    */

    async updateProfilesRecord(updatedProfile) {
      let ok = false;
      let message = "";

      let url = Config.NODE_SERVER_IP + Config.UPDATE_PROFILE;

      this.debug(`url = ${url}`);

      let headers = {
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };
      this.printJson(headers, "headers");

      let payload = new FormData();

      payload.append("updatedProfile", JSON.stringify(updatedProfile));

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "updateProfilesRecord() POST result");
        ok = jsonRes.ok;
        message = jsonRes.message;
      } catch (e) {
        this.debug(e);
        this.setAcknowledgeError(e);
        //ok = false;
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getCountryByIP() {
      let ok = true;
      let message = "";
      let country = "";
      let currencyCode = "AUD";

      try {
        /*
        if (this.$store.state.currentIP === "") {
          //this.$store.commit("set", ["savedIP", false]);
        */

        // always get latest IP just in case user changes country.
        ok = await this.getIPAddress();

        //}
        //
        console.log(`ip = ${this.$store.state.currentIP}`);
        if (ok === true) {
          let url = `${Config.NODE_SERVER_IP}${Config.GET_COUNTRY_BY_IP}?IP=${this.$store.state.currentIP}`;

          this.debug(`getCountryByIP url = ${url}`);

          let headers = {
            Accept: "application/json",
            //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `getCountryByIP() resJson`);
          ok = resJson.ok;
          if (ok === true) {
            message = resJson.message;
            country = resJson.country;

            console.log(`Country: ${country}`);
            this.$store.commit("set", ["countryByIP", country]);

            if (this.$store.state.country == "") {
              this.$store.commit("set", ["country", country]);
              console.log(
                `default country set as : ${this.$store.state.country}`
              );
            }

            this.$store.commit("set", [
              "languageCodeByIP",
              resJson.languageCodeByIP,
            ]);
            if (Object.keys(resJson.languageCodesObject).length > 0) {
              this.$store.commit("set", [
                "languageCodesObject",
                resJson.languageCodesObject,
              ]);
            }
            if (this.$store.state.selectedLanguageCode.length == 0) {
              this.$store.commit("set", [
                "selectedLanguageCode",
                resJson.languageCodeByIP,
              ]);
            }

            this.$store.commit("set", [
              "currencyCodeByIP",
              resJson.currencyCode,
            ]);

            if (this.$store.state.currencyCode.length == 0) {
              this.$store.commit("set", ["currencyCode", resJson.currencyCode]);
            }
            this.$store.commit("set", [
              "notranslateApp",
              resJson.notranslateApp,
            ]);

            currencyCode = resJson.currencyCode;
            if ("countries" in resJson) {
              this.$store.commit("set", ["countries", resJson.countries]);
              this.printJson(`Countries: ${this.$store.state.countries}`);
            }

            /*
            this.printJson(
              this.$store.state.countryObject,
              "getCountryByIP countryObject"
            );
            */

            //  this.debug(`currency code: ${this.$store.state.currencyCode}`);

            //getstates await removed because of loading time increases
            /* let stateRes = await*/
            this.getStates(this.$store.state.country);
            /* ok = stateRes.ok;*/
          } else {
            ok = false;
            message = resJson.message;
          }
        } else {
          message = "IP address not defined";
          this.debug(message);
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, currencyCode: currencyCode };
    },

    async getCountries() {
      let ok = true;
      let message = "";

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_COUNTRIES}`;

        this.debug(`getCountries url = ${url}`);

        let headers = {
          Accept: "application/json",
          //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        //this.printJson(resJson, `getCountryByIP() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          let countries = [
            /* {
               leading:
                 "https://assets.change.org/photos/9/ko/qu/zukoqUlLjqFHMdi-800x450-noPad.jpg?1588683793",
               title: "All",
               trailing: `${resJson.countryNumOfAdsObject["All"]} ads`,
             },*/
          ];
          //countries.push('All');

          this.$store.commit("set", [
            "countries",
            countries.concat(resJson.countries),
          ]);
          this.printJson(`Countries: ${this.$store.state.countries}`);

          if (Object.keys(resJson.languageCodesObject).length > 0) {
            this.$store.commit("set", [
              "languageCodesObject",
              resJson.languageCodesObject,
            ]);
          }
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message };
    },

    async getStates(countryName) {
      let ok = true;
      let message = "";

      for (let i = 0; i < store.state.countries.length; i++) {
        let country = store.state.countries[i];
        if (
          country.name == countryName &&
          "states" in country &&
          country.states.length > 0
        ) {
          this.$store.commit("set", ["states", country.states]);
          this.printJson(`States: ${this.$store.state.states}`);
          ok = true;
          break;
        }
      }
      return { ok: ok, message: message };
    },
    /* async getStates(country) {
       let ok = true;
       let message = "";
       let reload = false;
 
       try {
         if (country !== "All") {
           this.$store.commit("set", ["statesLoading", true]);
           let url = `${Config.NODE_SERVER_IP}${Config.GET_STATES}?country=${country}`;
 
           this.debug(`getStates url = ${url}`);
 
           let headers = {
             Accept: "application/json",
             //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
           };
 
           let fetchData = {
             method: "GET",
             headers: headers,
           };
 
           let res = await fetch(url, fetchData);
 
           let resJson = await res.json();
           //this.printJson(resJson, `getStates() resJson`);
           ok = resJson.ok;
           if (ok === true) {
             message = resJson.message;
             let states = ["All"];
 
             this.$store.commit("set", [
               "states",
               states.concat(resJson.states),
             ]);
             this.printJson(`States: ${this.$store.state.states}`);
 
             //check for changes on notranslateApp
             if (this.$store.state.notranslateApp !== resJson.notranslateApp) {
               this.$store.commit("set", [
                 "notranslateApp",
                 resJson.notranslateApp,
               ]);
               reload = true;
               //location.reload();
             }
           } else {
             ok = false;
             message = resJson.message;
           }
         } else {
           this.$store.commit("set", ["states", []]);
         }
         this.$store.commit("set", ["statesLoading", false]);
       } catch (e) {
         console.error(e);
       }
 
       return { ok: ok, message: message, reload: reload };
     },*/

    async getCurrencyCode(countryCode) {
      let ok = true;
      let message = "";
      let currencyCode = "";

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_CURRENCY_CODE}?countryCode=${countryCode}`;

        this.debug(`getCurrencyCode url = ${url}`);

        let headers = {
          Accept: "application/json",
          //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getCurrencyCode() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          currencyCode = resJson.currencyCode;
          // this.$store.commit("set", ["currencyCode", currencyCode]);
          // this.debug(`currncy code: ${this.$store.state.currencyCode}`);
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, currencyCode: currencyCode };
    },

    async updateRotatingBannerAdBackend00(updatedRotatingBannerAd) {
      let ok = false;
      let message = "";
      let bannerId = "";

      let url = Config.NODE_SERVER_IP + Config.UPDATE_ROTATING_BANNER_AD;

      this.debug(`url = ${url}`);

      let headers = {
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };
      this.printJson(headers, "headers");

      let payload = new FormData();

      payload.append(
        "updatedRotatingBannerAd",
        JSON.stringify(updatedRotatingBannerAd)
      );

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "updateRotatingBannerAd() POST result");
        ok = jsonRes.ok;
        message = jsonRes.message;
        bannerId = jsonRes.bannerId;
        /*if (jsonRes.ok === false) {
          this.setAcknowledgeError(jsonRes.message);
        } else {
          this.$router.push(Config.ROUTE_HOME);
        }*/
      } catch (e) {
        this.debug(e);
        message = e;
        //  this.setAcknowledgeError(e);
        //ok = false;
      }

      return {
        ok: ok,
        message: message,
        bannerId: bannerId,
      };
    },

    getCurrentDateYYYYMMDD() {
      let date = new Date();

      let month = `${date.getMonth() + 1}`; // getMonth() returns 0 - 11
      if (month.length == 1) {
        month = `0${month}`;
      }

      let day = `${date.getDate()}`; // 0 - 31
      if (day.length == 1) {
        day = `0${day}`;
      }

      return `${date.getFullYear()}${month}${day}`;
    },

    async getStats() {
      let ok = true;
      let message = "";
      let numberOfUsers = 0;
      let numberOfAds = 0;
      let totalAdsValue = 0;
      let currencyConverted = true;
      let collectionMetricsData = [];
      let labelAndDataSets = {};
      let currencyCode = "AUD";
      let bannerAds = [];
      let randomAds = [];
      let countryNumOfAds = 0;
      try {
        //let countryObject = this.$store.state.countryObject;

        /*
        if (
          countryObject == null ||
          "currency_code" in countryObject === false
        ) {
        */

        // always refresh the country just in case the user travels overseas
        /*let res = await this.getCountryByIP();
        let country = this.$store.state.countryByIP;*/
        // always get latest IP just in case user changes country.
        ok = await this.getIPAddress();
        console.log(`ip = ${this.$store.state.currentIP}`);

        this.printJson(ok, "getIpAddress res");

        console.log(`res.currency_code before = ${currencyCode}`);
        /*if (res.ok === true) {
          currencyCode = res.currencyCode;
        }*/

        console.log(`res.currency_code after = ${currencyCode}`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_STATS}?IP=${this.$store.state.currentIP}`;
        //?currencyCode=${currencyCode}&country=${country}

        this.debug(`getStats url = ${url}`);

        let headers = {
          Accept: "application/json",
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getStats() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          numberOfUsers = resJson.numberOfUsers;
          numberOfAds = resJson.numberOfAds;
          totalAdsValue = resJson.totalAdsValue;
          currencyConverted = resJson.currencyConverted;
          //collectionMetricsData = resJson.collectionMetricsData;
          //labelAndDataSets = resJson.labelAndDataSets;
          bannerAds = resJson.bannerAds;
          randomAds = resJson.randomAds;
          countryNumOfAds = resJson.countryNumOfAds;
          this.printJson(`Stats: ${resJson}`);
          console.log(`Country: ${resJson.country}`);
          this.$store.commit("set", ["countryByIP", resJson.country]);
          currencyCode = resJson.currencyCode;

          //not needed for now
          //this.$store.commit("set", ["currencyCodeByIP", resJson.currencyCode]);

          this.$store.commit("set", [
            "languageCodeByIP",
            resJson.languageCodeByIP,
          ]);
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        numberOfUsers: numberOfUsers,
        numberOfAds: numberOfAds,
        totalAdsValue: totalAdsValue,
        collectionMetricsData: collectionMetricsData,
        currencyConverted: currencyConverted,
        labelAndDataSets: labelAndDataSets,
        currencyCode: currencyCode,
        randomAds: randomAds,
        bannerAds: bannerAds,
        countryNumOfAds: countryNumOfAds,
      };
    },

    async getCountryByIPStats() {
      let ok = true;
      let message = "";
      let APIUsageCounter = 0;
      let cacheMemoryUsageCounter = 0;
      let startDate;
      let totalAds;

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_COUNTRY_BY_IP_STATS}`;

        this.debug(`getCountryByIPStats url = ${url}`);

        let headers = {
          Accept: "application/json",
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getStats() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          APIUsageCounter = resJson.APIUsageCounter;
          cacheMemoryUsageCounter = resJson.cacheMemoryUsageCounter;
          startDate = resJson.startDate;
          totalAds = resJson.totalAds;

          this.printJson(`Stats: ${resJson}`);
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        APIUsageCounter: APIUsageCounter,
        cacheMemoryUsageCounter: cacheMemoryUsageCounter,
        startDate: startDate,
        totalAds: totalAds,
      };
    },
    // url looks like "http://localhost/ksonginx/files/i.jpeg"
    getFileNameFromURL(url) {
      let lastSlashIndex = url.lastIndexOf("/");
      let name = url.substring(lastSlashIndex + 1);
      return name;
    },

    /* Thisfunction returns the ads after proper filters for liveAds and myAds. Can be called from refreshLiveAdIds() & refreshMyAdIds() functions */
    async refreshAdIds(pageIndex, adsPerPage, adsFilters, searchFilters, sort) {
      let ok = false;
      let message = "";
      let hasNextPage = false;
      let ads = [];
      let adIds = [];
      let topMainCategoryAdIds = [];
      let topMainCategoryAds = [];
      let totalAds = 0;
      //This function should be above all other custom filters in Ad Filters section
      this.printJson(searchFilters, "refreshAdIds() filters");
      this.printJson(adsFilters, "adsFilters");
      if (this.$route.query.adStatus == "Published") {
        adsFilters["published"] = true;
      } else if (this.$route.query.adStatus == "Pending") {
        adsFilters["published"] = false;
      }
      if (searchFilters.category !== undefined) {
        this.printJson(searchFilters, "search filters");
      }

      if ("subCategory" in searchFilters) {
        adsFilters["subCategory"] = searchFilters.subCategory;
        delete searchFilters["subCategory"];
      }
      if ("mainCategory" in searchFilters) {
        adsFilters["mainCategory"] = searchFilters.mainCategory;
        delete searchFilters["mainCategory"];
      }

      let adsRes = await this.getAdsWithPaginations(
        pageIndex,
        adsPerPage,
        adsFilters,
        searchFilters,
        sort
      );
      ok = adsRes.ok;
      message = adsRes.message;
      ads = adsRes.ads;
      adIds = adsRes.adIds;
      hasNextPage = adsRes.hasNextPage;
      totalAds = adsRes.totalAds;
      (topMainCategoryAds = adsRes.topMainCategoryAds),
        (topMainCategoryAdIds = adsRes.topMainCategoryAdIds);

      this.$store.commit("set", ["totalAds", totalAds]);

      this.debug(this.$route.path);
      return {
        ok: ok,
        message: message,
        hasNextPage: hasNextPage,
        ads: ads,
        adIds: adIds,
        totalAds: totalAds,
        topMainCategoryAdIds: topMainCategoryAdIds,
        topMainCategoryAds: topMainCategoryAds,
      };
    },

    async getAdsWithPaginations(
      pageIndex,
      adsPerPage,
      adsFilters,
      searchFilters,
      sort
    ) {
      let ok = true;
      let message = "";
      let ads = [];
      let adIds = [];
      let topMainCategoryAds = [];
      let topMainCategoryAdIds = [];
      let hasNextPage = false;
      let totalAds = 0;
      this.printJson(pageIndex, "pageindex");
      this.printJson(adsPerPage, "adsPerPage");
      this.printJson(adsFilters, "adsFilters");
      this.printJson(searchFilters, "searchFilters");
      this.printJson(sort, "sort");
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ADS}`;

        this.debug(`getAds url = ${url}`);

        /*
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };
        this.printJson(headers, "headers");
        */

        let payload = new FormData();
        payload.append("pageIndex", pageIndex);
        payload.append("adsPerPage", adsPerPage);
        if (adsFilters !== undefined) {
          payload.append("adsFilters", JSON.stringify(adsFilters));
        }
        if (searchFilters !== undefined) {
          payload.append("searchFilters", JSON.stringify(searchFilters));
        }
        if (sort !== undefined) {
          payload.append("sort", sort);
        }

        let fetchData = {
          method: "POST",
          //headers: headers,
          body: payload,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          ads = resJson.ads;
          adIds = resJson.adIds;
          hasNextPage = resJson.hasNextPage;
          totalAds = resJson.totalAds;
          topMainCategoryAds = resJson.topAds;
          topMainCategoryAdIds = resJson.topAdIds;

          //this.$store.commit("set", ["allAdIds", resJson.allAdIds]);
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        hasNextPage: hasNextPage,
        ads: ads,
        adIds: adIds,
        totalAds: totalAds,
        topMainCategoryAdIds: topMainCategoryAdIds,
        topMainCategoryAds: topMainCategoryAds,
      };
    },

    async getAdRecordByAdId(
      adId,
      isInit = false,
      adsByOwner = false,
      increaseCount = false
    ) {
      let ok = true;
      let message = "";
      let ad = {};
      let topAd = {};

      try {
        /*let endpoint = "";
        if (isInit == true) {
          endpoint = Config.GET_AD_AND_ALL_ADIDS;
        } else {
          endpoint = Config.GET_AD_RECORD_BY_ADID;
        }*/
        let url = `${Config.NODE_SERVER_IP}${Config.GET_AD_RECORD_BY_ADID}?adId=${adId}&adsByOwner=${adsByOwner}&isInit=${isInit}&increaseCount=${increaseCount}`;

        this.debug(`getAdRecordByAdId url = ${url}`);
        //Adding country in query to fetch all the adIds in first launch to ad-view page. Reduce the loading time in backend00
        if (
          this.$store.state.country !== "All" &&
          this.$store.state.country != undefined
        ) {
          url += `&country=${this.$store.state.country}`;
        }

        let headers = {
          Accept: "application/json",
          //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getAdRecordByAdId() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          ad = resJson.ad;
          topAd = resJson.topAd;
          if (isInit == true) {
            this.$store.commit("set", ["allAdIds", resJson.allAdIds]);
          }
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, ad: ad, topAd: topAd };
    },

    async getcollectionDataByName(collectionName) {
      let ok = true;
      let message = "";
      let data = [];

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_COLLECTION_DATA_BY_NAME}?collectionName=${collectionName}`;

        this.debug(`getcollectionDataByName url = ${url}`);

        let headers = {
          Accept: "application/json",
          Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getcollectionDataByName() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          data = resJson.data;
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, data: data };
    },

    async getRecord(collectionName, id) {
      let ok = true;
      let message = "";
      let record = {};

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_RECORD}?collectionName=${collectionName}&id=${id}`;
        this.printJson(url, "getRecord()");
        let headers = {
          Accept: "application/json",
          Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          record = resJson.record;
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, record: record };
    },

    async getRotatingBannerAd(bannerId) {
      let ok = false;
      let bannerAd = [];
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ROTATING_BANNER_AD}?bannerId=${bannerId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        ok = resJson.ok;

        if (ok === true) {
          bannerAd = resJson.bannerAd;
        }
      } catch (e) {
        console.error(e);
      }

      this.printJson(bannerAd, "getRotatingBannerAd() bannerAd");
      return {
        ok: ok,
        bannerAd: bannerAd,
      };
    },

    async getRotatingBannerAdsAll() {
      let ok = false;
      let bannerAds = [];
      this.debug("getRotatingBannerAdsAll()");
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ROTATING_BANNER_ADS_ALL}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        ok = resJson.ok;

        if (ok === true) {
          bannerAds = resJson.bannerAds;
        }
      } catch (e) {
        console.error(e);
      }

      this.printJson(bannerAds, "getRotatingBannerAdsAll() bannerAds");
      return {
        ok: ok,
        bannerAds: bannerAds,
      };
    },

    async setBannerAdIsLive(bannerId, isLive) {
      this.debug(`setBannerAdIsLive bannerId = ${bannerId}`);
      let ok = false;
      if (await this.isLoggedInOIDC(true)) {
        //this.isLoading = true;
        let url =
          Config.NODE_SERVER_IP + Config.UPDATE_ROTATING_BANNER_AD_FIELD;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("field", "isLive");
        payload.append("value", isLive);
        payload.append("bannerId", bannerId);

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "UPDATE_ROTATING_BANNER_AD_FIELD");
          ok = jsonRes.ok;

          if (ok === true) {
            if (isLive) {
              this.setAcknowledge("Done.", "Banner Ad Published");
            } else {
              this.setAcknowledge("Done.", "Banner Ad Paused");
            }
          } else {
            if (isLive) {
              this.setAcknowledgeError(jsonRes.message);
            } else {
              this.setAcknowledgeError(jsonRes.message);
            }
          }
          await this.refreshRotatingBannerAds();
        } catch (e) {
          this.debug(e);
        }

        //this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return ok;
    },

    getDaysLeftNice(daysLeft, lastPublished) {
      let daysLeftText = "";

      if (typeof daysLeft === "undefined") {
        daysLeftText = "Never Published";
      } else {
        let daysElapsed = (Date.now() - lastPublished) / (1000 * 60 * 60 * 24);

        let daysLeftTrue = daysLeft - daysElapsed;
        if (daysLeftTrue < 0) {
          daysLeftText = "Expired";
        } else {
          let daysFloored = Math.floor(daysLeftTrue);
          let hours = (daysLeftTrue - daysFloored) * 24;
          let hoursFloored = Math.floor(hours);
          let minutes = ((hours - hoursFloored) * 60).toFixed(2);

          if (daysFloored > 0) {
            daysLeftText = daysFloored + " days, ";
          }

          if (hoursFloored > 0) {
            daysLeftText += hoursFloored + " hours, ";
          }

          daysLeftText += minutes + " minutes.";
        }
      }

      return daysLeftText;
    },

    async initKintoProfile() {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.INIT_KINTO_PROFILE}`;

        // to avoid localhost as the IP
        if (this.$store.state.isDev) {
          url += `?IPaddress=${this.$store.state.currentIP}`;
        }

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getUserProfileData() {
      let resJson = {};
      try {
        let country = this.$store.state.countryByIP;
        let url = `${Config.NODE_SERVER_IP}${Config.GET_USER_PROFILE_DATA}?country=${country}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "profile data");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async updateRaffleMeta(raflleMetaObject, oldName) {
      let ok = false;
      let message = "";

      let url = Config.NODE_SERVER_IP + Config.UPDATE_RAFFLE_META;

      this.debug(`url = ${url}`);

      let headers = {
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };
      this.printJson(headers, "headers");

      let payload = new FormData();

      payload.append("raffleMetaObject", JSON.stringify(raflleMetaObject));
      payload.append("oldRaffleName", oldName);

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "updateRaffleMeta() POST result");
        ok = jsonRes.ok;
        message = jsonRes.message;
      } catch (e) {
        this.debug(e);
        message = e;
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getRaffleMetaData() {
      let ok = false;
      let data = {};
      let message = "";
      let activeRaffleName = "";

      let url = `${Config.NODE_SERVER_IP}${Config.GET_RAFFLE_META_DATA}`;

      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        data = jsonRes.data;
        activeRaffleName = jsonRes.activeRaffleName;
        message = jsonRes.message;
        this.debug(jsonRes, "getRaffleMetaData() jsonRes");
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        data: data,
        activeRaffleName: activeRaffleName,
      };
    },

    async getRaffleTicketsByName(raffleName) {
      let ok = false;
      let tickets = [];
      let winners = [];
      let message = "";

      let url = `${Config.NODE_SERVER_IP}${Config.GET_RAFFLE_TICKETS_BY_NAME}?raffleName=${raffleName}`;

      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        tickets = jsonRes.tickets;
        winners = jsonRes.winners;
        message = jsonRes.message;
        this.debug(jsonRes, "getRaffleTickets() jsonRes");
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        tickets: tickets,
        winners: winners,
      };
    },

    async pickRaffleWinners(raffleName) {
      let ok = false;
      let winners = [];
      let message = "";

      let url = `${Config.NODE_SERVER_IP}${Config.PICK_RAFFLE_WINNERS}?raffleName=${raffleName}`;

      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        winners = jsonRes.winners;
        message = jsonRes.message;
        this.debug(jsonRes, "pickThreeWinners() jsonRes");
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        winners: winners,
      };
    },

    async deleteRafflesSuperAdmin(raffleName) {
      let ok = false;
      let message = "";

      let url = `${Config.NODE_SERVER_IP}${Config.DELETE_RAFFLES_SUPERADMIN}?raffleName=${raffleName}`;

      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        message = jsonRes.message;
        this.debug(jsonRes, "deleteRafflesSuperAdmin() jsonRes");
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
      };
    },
    async pushRoute(routeName, routeQuery, hash = "") {
      this.debug(this.$route.query);

      if (
        this.$route.path !== routeName ||
        !isEqual(this.$route.query, routeQuery) ||
        this.$route.hash !== hash
      ) {
        this.debug("different path");
        this.$router.push({
          path: routeName,
          query: routeQuery,
          hash: hash,
        });
      } else {
        this.debug(`Same path routed again`);
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    onScroll() {},

    async createTopAd(ad) {
      this.debug(`createTopAd = ${ad}`);
      let ok = false;
      let message = "";
      let availableCategories = [];
      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.NEW_TOP_AD;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("ad", JSON.stringify(ad));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "createTopAd");
          ok = jsonRes.ok;
          message = jsonRes.message;
          availableCategories = jsonRes.availableCategories;

          // await this.refreshRotatingBannerAds();
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        availableCategories: availableCategories,
      };
    },

    async deleteTopAd(adId) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_TOP_AD}?adId=${adId}&userId=${this.$store.state.accessTokenDecoded.sub}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getAvailableTopAdCategories(country, mainCategory, subCategory) {
      let availableCategories = [];
      let priceRecord = {};
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_AVAILABLE_TOP_AD_CATEGORIES}`;
        /*?country=${country}&mainCategory=${mainCategory}&subCategory=${subCategory}*/
        let filters = {
          country: country,
          mainCategory: mainCategory,
          subCategory: subCategory,
        };
        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };
        let payload = new FormData();

        payload.append("filters", JSON.stringify(filters));
        let fetchData = {
          method: "POST",
          headers: headers,
          body: payload,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          availableCategories = resJson.availableCategories;
          priceRecord = resJson.priceRecord;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        priceRecord: priceRecord,
        availableCategories: availableCategories,
      };
    },

    async updateAd(ad, topAd = false) {
      this.debug(`updateAd = ${ad}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.UPDATE_AD;
        if (topAd === true) {
          url = Config.NODE_SERVER_IP + Config.UPDATE_TOP_AD;
        }
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();
        if (topAd === true) {
          payload.append("topAd", JSON.stringify(ad));
        } else {
          payload.append("ad", JSON.stringify(ad));
        }
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateAd()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteUserRotatingBannerAds() {
      let ok = false;
      let message = "";

      if ((await this.isLoggedInOIDC(false)) == true) {
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_USER_ROTATING_BANNER_ADS}`;
        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.$store.state.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          let jsonRes = await res.json();

          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          console.error(e);
        }
      } else {
        ok = false;
        message = "not logged in";
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getAllTopAdsPricing() {
      let priceRecords = [];
      let PAYPAL_VALID_CURRENCIES = [];
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ALL_TOP_ADS_PRICING}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        // this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          priceRecords = resJson.priceRecords;
          PAYPAL_VALID_CURRENCIES = resJson.PAYPAL_VALID_CURRENCIES;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        priceRecords: priceRecords,
        PAYPAL_VALID_CURRENCIES: PAYPAL_VALID_CURRENCIES,
      };
    },

    async editTopAdPricing(priceRecord) {
      this.debug(`editTopAdPricing = ${priceRecord}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.EDIT_TOP_AD_PRICING;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("priceRecord", JSON.stringify(priceRecord));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "editTopAdPricing()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async createAirdropForm(airdropForm) {
      this.printJson(`createAirdropForm = ${airdropForm}`);
      let ok = false;
      let message = "";

      //if (await this.isLoggedInOIDC(true)) {
      let url = Config.NODE_SERVER_IP + Config.NEW_AIRDROP_FORM;
      /*let headers = {
        Authorization:
          this.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };*/

      let payload = new FormData();

      payload.append("airdropForm", JSON.stringify(airdropForm));

      try {
        let res = await fetch(url, {
          method: "POST",
          //headers: headers,
          body: payload,
        });

        let jsonRes = await res.json();
        this.printJson(jsonRes, "createAirdropForm");
        ok = jsonRes.ok;
        message = jsonRes.message;
      } catch (e) {
        console.error(e);
      }

      /* } else {
         this.$router.push(Config.ROUTE_LOGIN);
       }*/

      return {
        ok: ok,
        message: message,
      };
    },

    async getAllAirdropForms() {
      let airdropForms = [];

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SUPERADMIN_GET_ALL_AIRDROPS}`;
        this.debug(url);
        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        //this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          airdropForms = resJson.airdropForms;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        airdropForms: airdropForms,
      };
    },

    async getAllInfluencers() {
      let influencers = [];

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SUPERADMIN_GET_ALL_INFLUENCERS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          influencers = resJson.influencers;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        influencers: influencers,
      };
    },

    async createNewArticle(title, category, articleDescription) {
      this.debug(`newArticle`);
      let ok = false;
      let message = "";
      let articleId = "";
      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.NEW_ARTICLE;
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        this.debug(`newArticle`);
        let payload = new FormData();

        payload.append("title", title);
        payload.append("category", category);
        payload.append("articleDescription", articleDescription);

        let article = {
          title: title,
          category: category,
          articleDescription: articleDescription,
        };

        payload.append("article", JSON.stringify(article));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "newArticle");
          ok = jsonRes.ok;
          message = jsonRes.message;
          articleId = jsonRes.articleId;
        } catch (e) {
          this.debug(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        articleId: articleId,
      };
    },

    async updateArticle(record) {
      this.debug(`updateArticle = ${record}`);
      let ok = false;
      let message = "";
      let articleId = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.UPDATE_ARTICLE;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("record", JSON.stringify(record));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateArticle");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        articleId: articleId,
      };
    },

    async getArticleById(articleId) {
      let resJson = {};
      try {
        this.debug(`getArticleById()`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ARTICLE_BY_ID}?articleId=${articleId}`;

        let headers = {
          Accept: "application/json",
        };
        this.debug(url);
        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async getFavouriteAdsByIds(adIds) {
      let ok = true;
      let message = "";
      let ads = [];

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_FAVOURITE_ADS_BY_IDS}`;

        this.debug(`getFavouriteAdsByIds url = ${url}`);

        let headers = {
          Accept: "application/json",
          Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let payload = new FormData();
        payload.append("adIds", adIds);

        let fetchData = {
          headers: headers,
          method: "POST",
          body: payload,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          ads = resJson.ads;
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
        ads: ads,
      };
    },

    async deleteArticle(articleId) {
      let resJson = {};
      try {
        this.debug(`deleteArticle()`);
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_ARTICLE}?articleId=${articleId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async editOrCreateInfluencer(influencerRecord, edit = false) {
      this.debug(`influencerRecord = ${influencerRecord}`);
      let ok = false;
      let message = "";
      let url = "";
      if (await this.isLoggedInOIDC(true)) {
        if (edit == true) {
          url = Config.NODE_SERVER_IP + Config.SUPERADMIN_UPDATE_INFLUENCER;
        } else {
          url = Config.NODE_SERVER_IP + Config.SUPERADMIN_NEW_INFLUENCER;
        }
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("influencerRecord", JSON.stringify(influencerRecord));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, " editInfluencerRecord()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteInfluencer(influencerId) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SUPERADMIN_DELETE_INFLUENCER}?influencerId=${influencerId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      alert("Copied! " + text);
    },

    async updateBonusEntry(airdropRecord) {
      this.debug(`updateBonusEntry = ${airdropRecord}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP + Config.SUPERADMIN_VERIFY_AIRDROP_TWITTER_ID;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();
        payload.append("airdropRecord", JSON.stringify(airdropRecord));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateBonusEntry()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getAirdropWinners() {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SUPERADMIN_GET_AIRDROP_WINNERS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async pickAirdropMegaDrawWinners() {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SUPERADMIN_PICK_MEGA_DRAW_AIRDROP_WINNERS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async checkEmailVerified(email) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.CHECK_EMAIL_VERIFIED}?email=${email}`;

        /*let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };*/

        let fetchData = {
          method: "GET",
          //headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async sendVerifyEmailFromAirDrop(email) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SEND_VERIFY_EMAIL_CODE_FROM_AIRDROP}?email=${email}`;

        let fetchData = {
          method: "GET",
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async verifyCodeWithDB(email, code) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.VERIFY_EMAIL_CODE}?email=${email}&code=${code}`;

        let fetchData = {
          method: "GET",
          //headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    /*async getAbout(locale) {
      let resJson = {};
      this.showSnackbarTranslating(true);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ABOUT}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          //   headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();

        this.printJson(resJson, "resJson() about");
      } catch (e) {
        console.error(e);
      }

      this.showSnackbarTranslating(false);
      return resJson;
    },

    async getCareers(locale) {
      let resJson = {};

      this.showSnackbarTranslating(true);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_CAREERS}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          // headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      this.printJson(resJson, "resJson() careers");

      this.showSnackbarTranslating(false);
      return resJson;
    },

    async getPrivacyPolicy(locale) {
      let resJson = {};
      this.showSnackbarTranslating(true);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_PRIVACY_POLICY}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          // headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      this.printJson(resJson, "resJson() privacy");

      this.showSnackbarTranslating(false);
      return resJson;
    },

    async getTerms(locale) {
      let resJson = {};
      this.showSnackbarTranslating(true);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_TERMS}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          //   headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();

        this.printJson(resJson, "resJson() about");
      } catch (e) {
        console.error(e);
      }

      this.showSnackbarTranslating(false);
      return resJson;
    },

    async getAirdropContent(locale) {
      let resJson = {};
      this.showSnackbarTranslating(true);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_AIRDROP_CONTENT}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          //   headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();

        this.printJson(resJson, "resJson() about");
      } catch (e) {
        console.error(e);
      }

      this.showSnackbarTranslating(false);
      return resJson;
    },*/

    async getLabels(locale) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_LABELS}?locale=${locale}`;

        let fetchData = {
          method: "GET",
          // headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        if (resJson.ok == true) {
          if ("en" in resJson.messages) {
            let engMessages = await this.replaceAndCharacter(
              resJson.messages["en"]["message"]
            );
            resJson.messages["en"]["message"] = engMessages;
          }
          this.$store.commit("set", ["messages", resJson.messages]);
          //this.$i18n.locale = this.$store.state.selectedLanguageCode;
          //this.$store.commit("set", ["selectedLanguageCode", this.$i18n.locale]);

          app.$i18n.setLocaleMessage(
            this.$i18n.locale,
            resJson.messages[this.$i18n.locale]
          );
        }
      } catch (e) {
        console.error(e);
      }

      this.printJson(resJson, "resJson() labels");
      return resJson;
    },

    /*async guessLanguage(text) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GUESS_LANGUAGE}?text=${text}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }
      this.printJson(resJson, "detected language");
      return resJson;
    },*/

    /*async saveAdInEnglish(adId) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.SAVE_AD_IN_ENGLISH}?adId=${adId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },*/

    /*async deleteTranslatedAd(adId) {
      let resJson = {};
      this.debug(`deleteTranslatedAd() = ${adId}`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_TOP_AD_FROM_TRANSLATED_ADS}?adId=${adId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },*/

    //Translate json records
    async translateJsonWithSeparator(
      sourceLocale,
      targetLocale,
      jsonToTranslate
    ) {
      let ok = false;
      let message = "";
      let translatedJson = {};
      let translatedArray = [];
      let start = Date.now();

      this.$store.commit("set", ["showTranslatingSnackbar", true]);

      this.debug(`translateJsonWithSeparator()`);
      try {
        let seperator = await this.getUniqueFieldSeparatorForJson(
          jsonToTranslate
        );
        let keys = Object.keys(jsonToTranslate);
        let stringToTranslate = "";
        let arrayOfStringToTranslate = [];
        for (let i = 0; i < keys.length; i++) {
          let stringCount = 0;
          let key = keys[i];
          stringCount = stringCount + jsonToTranslate[key].length;
          this.debug(
            Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS +
              " characters limit" +
              Config.GOOGLE_TRANSLATE_API_CALL_INTERVAL
          );
          if (stringCount < Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS) {
            stringToTranslate += jsonToTranslate[key] + " " + seperator + " ";
          } else {
            arrayOfStringToTranslate.push(stringToTranslate);
            stringCount = 0;
            stringToTranslate = jsonToTranslate[key] + " " + seperator + " ";
          }
        }
        if (stringToTranslate.length > 0) {
          arrayOfStringToTranslate.push(stringToTranslate);
        }
        this.debug(
          `length of strings array = ${arrayOfStringToTranslate.length} `
        );
        for (let j = 0; j < arrayOfStringToTranslate.length; j++) {
          let text = arrayOfStringToTranslate[j];
          this.debug(`seperator = ${seperator}`);
          this.debug(`${text} - translate`);
          let res = await this.waitAndtranslate(
            sourceLocale,
            targetLocale,
            text // encodeURI(text)
          );
          ok = res.ok;
          message = res.message;
          if (ok === true) {
            for (let j = 0; j < res.translatedArray[0].length; j++) {
              translatedArray.push(res.translatedArray[0][j]);
            }
          } else {
            break;
          }
        }
        if (ok == true) {
          let translatedText = "";
          for (let i = 0; i < translatedArray.length; i++) {
            translatedText += translatedArray[i][0];
            //translatedJson[keys[i]] = translatedArray[i][0];
          }

          let splittedArray = translatedText.split(seperator);

          this.printJson(splittedArray, "splitted array");
          for (let j = 0; j < splittedArray.length; j++) {
            let receivedText = splittedArray[j].replace(seperator, "");
            translatedJson[keys[j]] = receivedText;
          }
        }

        this.printJson(translatedJson, "object translated");
        message = "transaletd";
      } catch (e) {
        console.error(e);
        message = `error in google translate API`;
      }

      delete translatedJson["undefined"];
      this.$store.commit("set", ["showTranslatingSnackbar", false]);
      let end = Date.now();
      this.debug(`loading time = ${(end - start) / 1000}sec`);
      return { ok: ok, message: message, translatedJson: translatedJson };
    },

    async getUniqueFieldSeparatorForJson(json) {
      let text = "";
      for (let key in json) {
        text += " " + json[key];
      }
      return await this.getUniqueFieldsSeparator(text);
    },

    // string is the concatentation of all title and description strings.
    async getUniqueFieldsSeparator(text) {
      //let separator = "<d^_^b>";
      let separator = "****";
      let match = true;
      let i = 0;
      do {
        if (text.includes(separator)) {
          //separator = "<d^_" + i + "^b>";
          separator = "**" + i + "**";
          i++;
        } else {
          match = false;
        }
      } while (match);

      return separator;
    },

    // put this in the Vue App somehwere. Later on we can turn this into a test.
    /*function testGetUniqueFieldsSeparator() {
      let text = "The quick brown fox <d^_^b> jumps over the lazy <b>dogs</b>."
      let separator = getUniqueFieldsSeparator(text);
      console.log(separator); // <d^_0^b>
    
      text = "The quick brown fox <d^_0^b> jumps over the lazy <b>dogs</b>."
      separator = getUniqueFieldsSeparator(text);
      console.log(separator); // <d^_^b>
    
      text = "The <d^_^b> quick brown <d^_0^b> fox <d^_1^b> jumps over the lazy <b>dogs</b>."
      separator = getUniqueFieldsSeparator(text);
      console.log(separator); // <d^_2^b>
    
      text = "The <d^_^b> quick brown <d^_0^b> fox <d^_1^b> jumps <d^_2^b> over the lazy <b>dogs</b>."
      separator = getUniqueFieldsSeparator(text);
      console.log(separator); // <d^_3^b>
    
      text = "The <d^_^b> quick brown <d^_0^b> fox <d^_1^b> jumps <d^_2^b> over <d^_3^b> the lazy <b>dogs</b>."
      separator = getUniqueFieldsSeparator(text);
      console.log(separator); // <d^_4^b>
    }*/

    async replaceAndCharacter(messages) {
      let reOrdered = {};
      for (let key in messages) {
        let text = messages[key];
        if (text !== "Random Member Ads") text = text.replace("and", "&");
        reOrdered[key] = text;
      }
      this.printJson(reOrdered);
      return reOrdered;
    },

    showSnackbarTranslating(show) {
      if (show == true && this.$i18n.locale !== "en") {
        this.$store.commit("set", ["showTranslatingSnackbar", show]);
      } else {
        this.$store.commit("set", ["showTranslatingSnackbar", false]);
      }
    },

    async translateMultipleAds(originalAds) {
      let ok = false;
      let objectToTranslate = {};
      let locales = [];
      let ads = originalAds;
      for (let i = 0; i < originalAds.length; i++) {
        if ("locale" in originalAds[i]) {
          let key = `title${i}`;
          objectToTranslate[key] = originalAds[i]["title"] + "\n";

          key = `description${i}`;
          objectToTranslate[key] = originalAds[i]["description"] + "\n";

          key = `country${i}`;
          objectToTranslate[key] = originalAds[i]["country"] + "\n";
          if (
            "suburb" in originalAds[i].addressObj &&
            originalAds[i].addressObj.suburb.length > 0
          ) {
            key = `suburb${i}`;
            objectToTranslate[key] = originalAds[i].addressObj["suburb"] + "\n";
          }
          if (
            "state" in originalAds[i].addressObj &&
            originalAds[i].addressObj.state.length > 0
          ) {
            key = `state${i}`;
            objectToTranslate[key] = originalAds[i].addressObj["state"] + "\n";
          }
          if (locales.includes(originalAds[i]["locale"]) === false) {
            locales.push(originalAds[i]["locale"]);
          }
        }
      }
      this.printJson(objectToTranslate, "objectToTranslate");

      // let resTrans;
      for (let j = 0; j < locales.length; j++) {
        let sourceLocale = locales[j];
        if (sourceLocale !== this.$i18n.locale) {
          this.debug(`source locale = ${sourceLocale}`);
          let resTrans = await this.translateJsonWithSeparator(
            sourceLocale,
            this.$i18n.locale,
            objectToTranslate
          );

          if (resTrans.ok == true) {
            ok = true;
            let translatedJson = resTrans.translatedJson;
            this.printJson(translatedJson, "translatedJSon");
            for (let i = 0; i < originalAds.length; i++) {
              let key = `title${i}`;
              if (key in translatedJson) {
                ads[i]["title"] = translatedJson[key];
              }
              key = `description${i}`;
              if (key in translatedJson) {
                ads[i]["description"] = translatedJson[key];
              }
              key = `country${i}`;
              if (key in translatedJson) {
                ads[i]["country"] = translatedJson[key];
              }

              key = `suburb${i}`;
              if (key in translatedJson) {
                ads[i].addressObj["suburb"] = translatedJson[key];
              }

              key = `state${i}`;
              if (key in translatedJson) {
                ads[i].addressObj["state"] = translatedJson[key];
              }
            }
            break;
          } else {
            ok = false;
            this.debug(resTrans.message);
            if (resTrans.message !== "unsupported locale") {
              break;
            }
          }
        }
      }

      return { ok: ok, ads: ads };
    },

    async translateText(sourceLocale, targetLocale, text) {
      let ok = false;
      let message = "";
      let translatedText = "";
      let translatedArray = [];
      //store.commit("set", ["showTranslatingSnackbar", true]);
      //let start = Date.now();
      try {
        /*if (
          this.isSupportedLocale(targetLocale) &&
          this.isSupportedLocale(sourceLocale)
        ) {*/
        //  text = encodeURI(text);
        let url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sourceLocale}&tl=${targetLocale}&dt=t&q=${text}`;

        const fetchResult = await fetch(url);

        const res = await fetchResult.json();
        //console.log(res );
        translatedArray = res;
        for (let i = 0; i < res[0].length; i++) {
          translatedText += res[0][i][0];
          //printJson(res[0][i][0]);
        }
        this.printJson(translatedArray);
        ok = true;
        message = "transaletd";
        /*} else {
          ok = false;
          message = "unsupported locale";
          console.log(message);
        }*/
      } catch (e) {
        console.error(e);
      }
      //let end = Date.now();
      //console.log(`translate api loading time ${(end - start) / 1000} sec`);
      // store.commit("set", ["showTranslatingSnackbar", false]);
      return {
        ok: ok,
        message: message,
        translatedText: translatedText,
        translatedArray: translatedArray,
      };
    },

    isSupportedLocale(locale) {
      let exist = false;
      if (locale in store.state.languageCodesObject) {
        this.debug(`country ${store.state.languageCodesObject[locale]}`);
        exist = true;
      }
      return exist;
    },

    async waitAndtranslate(sourceLocale, targetLocale, text) {
      let waitTimeMs = 0;
      // text = encodeURI(text);
      if (this.nextSafeCall === 0 || Date.now() > this.nextSafeCall) {
        this.nextSafeCall =
          Date.now() + Config.GOOGLE_TRANSLATE_API_CALL_INTERVAL;
        return await this.translateText(sourceLocale, targetLocale, text);
      } else {
        waitTimeMs = this.nextSafeCall - Date.now();
        this.nextSafeCall += Config.GOOGLE_TRANSLATE_API_CALL_INTERVAL;
        await this.synchSetTimeout(waitTimeMs);

        return await this.translateText(sourceLocale, targetLocale, text);
      }
    },

    async translateTextSafe(sourceLocale, targetLocale, text) {
      let translatedArray = [];
      let translatedTextFinal = "";
      let ok = false;
      let message = "";
      this.debug(`length of the text to translate = ${text.length}`);
      if (text.length > Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS) {
        let len = Math.ceil(
          text.length / Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS
        );
        this.debug(`broken string length = ${len}`);
        for (let i = 0; i < len; i++) {
          let start = i * Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS;
          let limit = (i + 1) * Config.GOOGLE_TRANSLATE_API_MAX_CHARACTERS - 1;
          let textToTranslate = this.limit(text, start, limit);

          // console.log(`translateTextSafe() ${i} orig text`);
          // console.log(textToTranslate);

          let res = await this.waitAndtranslate(
            sourceLocale,
            targetLocale,
            textToTranslate
          );

          ok = res.ok;
          message = res.message;

          if (ok === true) {
            translatedArray.push(res.translatedText);
            /*
            for (let j = 0; j < res.translatedArray[0].length; j++) {
              translatedArray.push(res.translatedArray[0][j]);
            }
            */
          } else {
            break;
          }
          //TODO returning arra should be rearranges
        }

        translatedTextFinal = translatedArray.join("");
        return {
          ok: ok,
          message: message,
          translatedText: translatedTextFinal,
        };
      } else {
        let res = await this.waitAndtranslate(sourceLocale, targetLocale, text);
        ok = res.ok;
        message = res.message;
        if (ok === true) {
          translatedTextFinal = res.translatedText;
          /*
          for (let j = 0; j < res.translatedArray[0].length; j++) {
            translatedArray.push(res.translatedArray[0][j]);
          }
          */
        }
        return {
          ok: ok,
          message: message,
          translatedText: translatedTextFinal,
        };
      }
    },

    async getLocaleOfGT() {
      let cookieStr = Cookies.get(Config.GOOGLE_TRANSLATE_COOKIE_NAME);
      console.log(`cookieStr = ${cookieStr}`);
      let locale = "";
      if (cookieStr === null || cookieStr === undefined) {
        locale = "en";
      } else {
        locale = cookieStr.substring(4);
      }

      //this.$store.commit("set", ["selectedLanguageCode", locale]);
      this.debug(`locale length = ${locale.length}`);

      return locale;
    },

    /*
    async getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    */

    limit(string = "", start = 0, limit = 0) {
      return string.substring(start, limit);
    },

    async getAllRotatingBannerAdsPricing() {
      let priceRecords = [];
      let PAYPAL_VALID_CURRENCIES = [];
      this.debug(`getAllRotatingBannerAdsPricing()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ALL_ROTATING_BANNER_ADS_PRICING}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        // this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          priceRecords = resJson.priceRecords;
          PAYPAL_VALID_CURRENCIES = resJson.PAYPAL_VALID_CURRENCIES;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        priceRecords: priceRecords,
        PAYPAL_VALID_CURRENCIES: PAYPAL_VALID_CURRENCIES,
      };
    },

    async editRotatingBannerAdPricing(priceRecord) {
      this.debug(`editRotatingBannerAdPricing = ${priceRecord}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP + Config.EDIT_ROTATING_BANNER_AD_PRICING;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("priceRecord", JSON.stringify(priceRecord));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "editRotatingBannerAdPricing()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getRotatingBannerAdPricing(country) {
      // let availableCategories = [];
      //let priceRecord = {};
      let resJson = {};
      this.debug(`getRotatingBannerAdPricing()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ROTATING_BANNER_AD_PRICING}?country=${country}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
        /* if (resJson.ok === true) {
          priceRecord = resJson.priceRecord;
        }*/
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    getVueVersion() {
      return Vue.version;
    },

    /* googleTranslateElementInit() {
      let GTE = window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );

      console.log("googleTranslateElementInit()");
      console.log(GTE);
    },*/

    async getAllCountryLanguageTranslations() {
      let records = [];
      this.debug(`getAllCountryLanguageTranslations()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ALL_COUNTRY_LANGUAGE_TRANSLATIONS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        // this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          records = resJson.records;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        records: records,
      };
    },

    async toggleCountryLanguageTranslationStatus(record) {
      this.debug(`toggleCountryLanguageTranslationStatus = ${record}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP +
          Config.TOGGLE_COUNTRY_LANGUAGE_TRANSLATION_STATUS;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("record", JSON.stringify(record));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "toggleCountryLanguageTranslationStatus()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    setMatomoUserID() {
      if (Config.IS_DEV === false) {
        if (
          store.state.accessTokenDecoded !== null &&
          store.state.accessTokenDecoded.sub !== undefined
        ) {
          let userId = store.state.accessTokenDecoded.sub;
          window._paq.push(["setUserId", userId]);
          //this.$matomo && this.$matomo.setUserId(userId);
          console.log(`Matomo enabled for ${this.$matomo}, ${userId}`);
        }
        window._paq.push(["trackPageView"]);
        //this.$matomo && this.$matomo.trackPageView("test");

        window._paq.push(["enableLinkTracking"]);
        //let enable = true;
        //this.$matomo && this.$matomo.enableLinkTracking(enable);
      }
    },

    trackUser() {
      window._paq.push(["trackPageView"]);
      window._paq.push(["enableLinkTracking"]);
    },

    resetMatomoUserID() {
      if (Config.IS_DEV === false) {
        // User has just logged out, we reset the User ID
        window._paq.push(["resetUserId"]);

        // we also force a new visit to be created for the pageviews after logout
        window._paq.push(["appendToTrackingUrl", "new_visit=1"]);

        window._paq.push(["trackPageView"]);

        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        window._paq.push(["appendToTrackingUrl", ""]);
      }
    },

    async getCountryNumOfAdsObject() {
      let ok = true;
      let message = "";

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_COUNTRY_NUM_OF_ADS_OBJECT}`;

        this.debug(`getcountryNumOfAdsObject url = ${url}`);

        let headers = {
          Accept: "application/json",
          //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getcountryNumOfAdsObject() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          message = resJson.message;
          let countries = this.$store.state.countries;
          for (let i = 0; i < countries.length; i++) {
            if (countries[i].title in resJson.countryNumOfAdsObject) {
              let numOfAds = resJson.countryNumOfAdsObject[countries[i].title];
              if (Number(numOfAds) == 1) {
                countries[i]["trailing"] = `${numOfAds} ad`;
              } else {
                countries[i]["trailing"] = `${numOfAds} ads`;
              }
            }
            this.printJson(countries[i]);
          }

          this.$store.commit("set", ["countries", countries]);
          // this.printJson(`Countries: ${this.$store.state.countries}`);
        } else {
          ok = false;
          message = resJson.message;
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message };
    },

    async modifyAd(ad) {
      let ok = false;
      let message = "";

      let url = Config.NODE_SERVER_IP + Config.MODIFY_AD;

      let headers = {
        Authorization:
          this.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      let payload = new FormData();

      payload.append("ad", JSON.stringify(ad));

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);
        let jsonRes = await res.json();
        ok = jsonRes.ok;
        message = jsonRes.message;
      } catch (e) {
        this.debug(e);
        message = e;
        ok = false;
      }

      this.debug(`modifyAd() ok = ${ok}, message = ${message}`);
      return {
        ok: ok,
        message: message,
      };
    },

    async fetchDataOfMetrics(date, period) {
      let resJson = {};
      this.debug(`fetchDataOfMetrics()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_MATOMO_METRICS}?date=${date}&period=${period}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async increaseRotatingBannerAdClicks(bannerId) {
      let resJson = {};
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_ROTATING_BANNER_AD_CLICKS}?bannerId=${bannerId}`;

        let fetchData = {
          method: "GET",
          //headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async increaseTopAdClicks(adId, topAdType) {
      let resJson = {};
      this.debug(topAdType);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_TOP_AD_CLICKS}?adId=${adId}&topAdType=${topAdType}`;

        let fetchData = {
          method: "GET",
          //headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    /*async increaseAdViews(adId) {
      let resJson = {};

      try {
        let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_AD_VIEWS}?adId=${adId}`;

        let fetchData = {
          method: "GET",
          //headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },*/

    async getAdViewsMonthly(months, country) {
      let resJson = {};
      this.debug(`getAdViewsMonthly()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_AD_VIEWS_MONTHLY}?months=${months}&country=${country}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getAdViewsWeekly(months, country) {
      let resJson = {};
      this.debug(`getAdViewsWeekly()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_AD_VIEWS_WEEKLY}?months=${months}&country=${country}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getTopAdsMetrics() {
      let resJson = {};
      this.debug(`getTopAdsMetrics()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_TOP_ADS_METRICS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getUserRegistrationsMetrics() {
      let resJson = {};
      this.debug(`getUserRegistrationsMetrics()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_USER_REGISTRATIONS_METRICS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getRotatingBannerAdsClicksMetrics(country) {
      let resJson = {};
      this.debug(`getAdViewsWeekly()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ROTATING_BANNER_ADS_CLICKS_METRICS}?country=${country}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getNewDailyAdsReportTime() {
      let resJson = {};
      this.debug(`getNewDailyAdsReportTime()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_NEW_DAILY_ADS_REPORT_TIME}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async updateNewDailyAdsReportTime(time) {
      let resJson = {};
      this.debug(`updateNewDailyAdsReportTime() ${time.length}`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_NEW_DAILY_ADS_REPORT_TIME}?time=${time}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async createNewForumPost(title, postMessage, category) {
      this.debug(`createNewForumPost`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.NEW_FORUM_POST;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("title", title);
        payload.append("category", category);
        payload.append("message", postMessage);
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "createNewForumPost");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async updateForumPost(record) {
      this.debug(`updateForumPost = ${record}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.UPDATE_FORUM_POST;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("record", JSON.stringify(record));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateForumPost");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getForumPostById(postId) {
      let resJson = {};
      try {
        this.debug(`getForumPostById()`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_FORUM_POST_BY_ID}?postId=${postId}`;

        let headers = {
          Accept: "application/json",
          /*Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,*/
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async deleteForumPost(postId) {
      let resJson = {};
      try {
        this.debug(`deleteForumPost()`);
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_FORUM_POST}?postId=${postId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async addForumReply(forumParentId, reply) {
      this.debug(`addForumReply = ${reply}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.ADD_REPLY;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("forumParentId", forumParentId);
        payload.append("reply", reply);
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "addForumReply");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async updateForumReply(replyId, reply) {
      this.debug(`updateForumReply = ${reply}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.UPDATE_REPLY;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("replyId", replyId);
        payload.append("reply", reply);
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateForumReply");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteForumReply(replyId) {
      let resJson = {};
      try {
        this.debug(`deleteForumPost()`);
        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_REPLY}?replyId=${replyId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async getAllUserProfiles(filters = {}) {
      let ok = false;
      let message = "";
      let profiles = {};

      let url = Config.NODE_SERVER_IP + Config.GET_ALL_USER_PROFILES;

      if ("country" in filters && filters.country.length > 0) {
        url = url + `?country=${filters.country}`;
      }

      let headers = {
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      let fetchData = {
        method: "GET",
        headers: headers,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        message = jsonRes.message;
        profiles = jsonRes.profiles;
      } catch (e) {
        this.debug(e);
      }

      return {
        ok: ok,
        profiles: profiles,
        message: message,
      };
    },

    async deleteUser(userId) {
      let ok = false;

      if ((await this.isLoggedInOIDC()) == true) {
        this.tokens = this.$store.state.tokens;
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_USER}?userId=${userId}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);

          let jsonRes = await res.json();

          ok = jsonRes.ok;
        } catch (e) {
          ok = false;
          console.error(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
      return ok;
    },

    async deleteAllUserAds(userId) {
      let ok = false;

      if ((await this.isLoggedInOIDC()) == true) {
        this.tokens = this.$store.state.tokens;
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        let url = `${Config.NODE_SERVER_IP}${Config.DELETE_USER_ADS}?userId=${userId}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);

          let jsonRes = await res.json();

          ok = jsonRes.ok;
        } catch (e) {
          ok = false;
          console.error(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return ok;
    },

    //this function will return true if the device of user has touch screen
    isTouchSupported() {
      this.debug("touchEnabled");
      var msTouchEnabled = window.navigator.msMaxTouchPoints;
      var generalTouchEnabled = "ontouchstart" in document.createElement("div");

      this.debug(msTouchEnabled);
      this.debug(generalTouchEnabled);
      if (msTouchEnabled || generalTouchEnabled) {
        return true;
      }
      return false;
    },

    async getTopAdPaymentStatus(topAdId) {
      let resJson = {};
      try {
        this.debug(`getTopAdPaymentStatus()`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_TOP_AD_PAYMENT_STATUS}?topAdId=${topAdId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async getAlladsValueFilteredByCountry() {
      let ok = false;
      let message = "";
      let records = [];
      let finalTotalObject = {};

      let url = Config.NODE_SERVER_IP + Config.GET_ALL_ADS_VALUE;

      let headers = {
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      let fetchData = {
        method: "GET",
        headers: headers,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        this.printJson(jsonRes, "res");

        message = jsonRes.message;
        records = jsonRes.records;
        finalTotalObject = jsonRes.finalTotalObject;
      } catch (e) {
        this.debug(e);
      }

      return {
        ok: ok,
        records: records,
        message: message,
        finalTotalObject: finalTotalObject,
      };
    },

    async loadRecentAds(country) {
      let ok = false;
      let message = false;
      let ads = [];

      let url = `${Config.NODE_SERVER_IP}${Config.GET_INIT_DATA_ON_BACKGROUND}?country=${country}`;

      let fetchData = {
        method: "GET",
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        this.printJson(jsonRes, "res");

        message = jsonRes.message;
        ads = jsonRes.ads;
        if ("cryptoEnabled" in jsonRes) {
          this.$store.commit("set", ["cryptoEnabled", jsonRes.cryptoEnabled]);
        }
      } catch (e) {
        this.debug(e);
      }

      return {
        ok: ok,
        ads: ads,
        message: message,
      };
    },

    async clearCollection(collection) {
      try {
        let result = await collection.list();
        if (result.data.length > 0) {
          await collection.clear();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /* A backwards compatibility / cleanup function to clear IndexedDB
     * of database data
     */
    async clearIndexedDB() {
      await this.connectBucketMobilityGuru();

      let collections = [];

      let bucketMobilityGuru = this.$store.state.bucketMobilityGuru;
      let collectionProfileRO = bucketMobilityGuru.collection("ProfileRO");
      collections.push(collectionProfileRO);

      let collectionProfiles = this.$store.state.collectionProfiles;
      collections.push(collectionProfiles);

      let collectionAds = this.$store.state.collectionAds;
      collections.push(collectionAds);

      let collectionForum = this.$store.state.collectionForum;
      collections.push(collectionForum);

      let collectionArticles = this.$store.state.collectionArticles;
      collections.push(collectionArticles);

      let collectionRaffles = this.$store.state.collectionRaffles;
      collections.push(collectionRaffles);

      let collectionRotatingBannerAds =
        this.$store.state.collectionRotatingBannerAds;
      collections.push(collectionRotatingBannerAds);

      let len = collections.length;
      for (let i = 0; i < len; i++) {
        await this.clearCollection(collections[i]);
      }

      /*
      try {
        let result = await collectionProfiles.list();
        if (result.data.length > 0) {
          await collectionProfiles.clear();
        }

        let result = await collectionProfileRO.list();
        if (result.data.length > 0) {
          await collectionProfileRO.clear();
        }

        result = await collectionAds.list();
        if (result.data.length > 0) {
          await collectionAds.clear();
        }

        result = await collectionForum.list();
        if (result.data.length > 0) {
          await collectionForum.clear();
        }

        result = await collectionRaffles.list();
        if (result.data.length > 0) {
          await collectionRaffles.clear();
        }
      } catch (e) {
        console.error(e);
      }
      */
    },

    async isUserJoinedStream() {
      let ok = false;
      let message = false;
      let token = "";
      this.debug(`isUserJoinedStream`);
      let url = `${Config.NODE_SERVER_IP}${Config.JOIN_STREAM}`;
      let headers = {
        Accept: "application/json",
        Authorization:
          this.$store.state.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
      };
      //let payload = new FormData();
      /*if (users.length > 0) {
        payload.append("users", users);
      }*/
      let fetchData = {
        method: "POST",
        headers: headers,
      };

      try {
        let res = await fetch(url, fetchData);
        this.printJson(res);
        let jsonRes = await res.json();
        this.printJson(jsonRes, "res isUserJoinedStream");
        ok = jsonRes.ok;

        message = jsonRes.message;
        token = jsonRes.token;
      } catch (e) {
        this.debug(e);
      }

      return {
        ok: ok,
        message: message,
        token: token,
      };
    },

    async connectClientToStream() {
      let ok = false;
      let message = "";
      this.debug(`connectClientToStream()`);
      try {
        if (streamUser == undefined) {
          let result = await this.isUserJoinedStream();

          if (result.ok == true) {
            ok = true;
            this.printJson(this.$store.state.accessTokenDecoded.sub, "id");
            streamUser = await clientStream.connectUser(
              {
                id: this.$store.state.accessTokenDecoded.sub,
                name: this.$store.state.accessTokenDecoded.given_name,
                //  image: 'https://getstream.io/random_svg/?name=John',
              },
              result.token
            );
            if (streamUser.me !== undefined) {
              this.$store.commit("set", [
                "unreadMessages",
                streamUser.me.total_unread_count,
              ]);
              this.$store.commit("set", [
                "unreadChannels",
                streamUser.me.unread_channels,
              ]);
              this.printJson(streamUser, "user deyails of stream");
            }
            clientStream.on((event) => {
              if (
                event.total_unread_count !== null &&
                event.total_unread_count !== undefined
              ) {
                this.debug(
                  `unread messages count is now: ${event.total_unread_count}`
                );
                if (event.total_unread_count !== undefined) {
                  this.$store.commit("set", [
                    "unreadMessages",
                    event.total_unread_count,
                  ]);
                }
              }

              if (
                event.unread_channels !== null &&
                event.unread_channels !== undefined
              ) {
                this.debug(
                  `unread channels count is now: ${event.unread_channels}`
                );

                if (event.total_unread_count !== undefined) {
                  this.$store.commit("set", [
                    "unreadChannels",
                    event.unread_channels,
                  ]);
                }
              }
            });
            this.$store.commit("set", ["streamConnected", true]);
            message = `${this.$store.state.accessTokenDecoded.given_name} connetcted to Stream`;
          } else {
            message = result.message;
          }
        } else {
          ok = true;
          message = "user is defined already";
        }
      } catch (e) {
        console.error(e);
      }
      this.debug(message);
      return {
        ok: ok,
        message: message,
      };
    },

    async initializeChannel(channelId /*users = [], title = ""*/) {
      this.debug("initializing channel");
      //this.client = new StreamChat(Config.STREAM_API_KEY);
      /*if (users.length > 0) {
        channelId = await this.createHash(channelId);
      }*/
      let channel = clientStream.channel(
        "messaging",
        channelId
        // name: title,
        //  members: users,
      );
      //await channel.addMembers(users);
      this.debug("initialized channel");
      return channel;
      // listen for new messages
    },

    async queryStreamChannels(filter = {}, sort = [], options = {}) {
      return await clientStream.queryChannels(filter, sort, options);
    },

    async disconnectStream() {
      if (this.$store.state.streamConnected == true) {
        this.debug(`disconnecting...`);
        await clientStream.disconnectUser();
        //console.log(cli)
        this.debug("stream disconnected");
        this.$store.commit("set", ["streamConnected", false]);
      }
    },

    /*async createHash(str) {
      var SHA1 = new Hashes.SHA256().hex(str);
      return SHA1.toString();
    },*/

    async initializeChannelInBackend(adOwner) {
      let ok = false;
      let message = "";
      let channelId = "";

      this.debug(`initializeChannelInBackend()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_CHAT_ID}?to=${adOwner}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        this.printJson(jsonRes, "res");

        message = jsonRes.message;
        channelId = jsonRes.chatId;
      } catch (e) {
        console.error(e);
      }
      this.debug(message);
      return {
        ok: ok,
        message: message,
        channelId: channelId,
      };
    },

    async initializeChannelInBackendOrig(adId) {
      let ok = false;
      let message = "";
      let channelId = "";

      this.debug(`initializeChannelInBackend()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.CREATE_GET_STREAM_CHANNEL_FOR_AD}?adId=${adId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        ok = jsonRes.ok;
        this.printJson(jsonRes, "res");

        message = jsonRes.message;
        channelId = jsonRes.channelId;
      } catch (e) {
        console.error(e);
      }
      this.debug(message);
      return {
        ok: ok,
        message: message,
        channelId: channelId,
      };
    },

    async markAllAsRead() {
      return await clientStream.markAllRead();
    },

    isDateToday(date) {
      let oldDate = new Date(date);
      let newDate = new Date();

      /*console.log(
        `${oldDate.getDate()} === ${newDate.getDate()} && ${oldDate.getMonth()} === ${newDate.getMonth()} && ${oldDate.getFullYear()} === ${newDate.getFullYear()}`
      );*/
      return (
        oldDate.getDate() === newDate.getDate() &&
        oldDate.getMonth() === newDate.getMonth() &&
        oldDate.getFullYear() === newDate.getFullYear()
      );
    },

    async fetchLabelAndDataSets(currencyCode) {
      let labelAndDataSets = {
        labels: [],
        totalAdsValueData: [],
        numberOfAdsData: [],
        numberOfUsersData: [],
      };
      let ok = false;

      try {
        console.log(`res.currency_code after = ${currencyCode}`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_LABEL_AND_DATASETS}?currencyCode=${currencyCode}`;

        this.debug(`getStats url = ${url}`);

        let headers = {
          Accept: "application/json",
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson, `getStats() resJson`);
        ok = resJson.ok;
        if (ok === true) {
          labelAndDataSets = resJson.labelAndDataSets;
        }
      } catch (e) {
        console.error(e);
      }
      return labelAndDataSets;
    },

    async createInvoice(invoice) {
      this.debug(`newInvoice`);
      let ok = false;
      let message = "";
      let invoiceId = "";
      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.NEW_INVOICE;
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        this.debug(`createInvoice`);
        let payload = new FormData();

        payload.append("invoice", JSON.stringify(invoice));
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "newInvoice");
          ok = jsonRes.ok;
          message = jsonRes.message;
          invoiceId = jsonRes.invoiceId;
        } catch (e) {
          this.debug(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        invoiceId: invoiceId,
      };
    },

    async updateInvoice(
      invoice,
      hasBuyerPaid = false,
      hasPaidToSeller = false
    ) {
      this.debug(`updateInvoice = ${invoice}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.UPDATE_INVOICE;
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("invoice", JSON.stringify(invoice));
        if (hasBuyerPaid == true) {
          payload.append("hasBuyerPaid", hasBuyerPaid);
        }
        if (hasPaidToSeller == true) {
          payload.append("hasPaidToSeller", hasPaidToSeller);
        }

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateInvoice");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getInvoiceById(invoiceId) {
      let resJson = {};
      try {
        this.debug(`getInvoiceById()`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_INVOICE}?invoiceId=${invoiceId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async getNewInvoiceData(adId, buyerId) {
      let resJson = {};
      try {
        this.debug(`getNewInvoiceData()`);
        let url = `${Config.NODE_SERVER_IP}${Config.GET_DATA_FOR_NEW_INVOICE}?adId=${adId}&buyerId=${buyerId}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    async getAllInvoices(filters = {}) {
      this.debug(`getAllInvoices = ${filters}`);
      let ok = false;
      let message = "";
      let invoices = [];

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.GET_ALL_INVOICES;
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("filters", JSON.stringify(filters));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes.invoices.length, "getAllInvoices");
          ok = jsonRes.ok;
          message = jsonRes.message;
          if (ok == true) {
            invoices = jsonRes.invoices;
          }
        } catch (e) {
          this.debug(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        invoices: invoices,
      };
    },

    async getFirebaseToken() {
      let self = this;
      getToken(messaging, { vapidKey: Config.FIREBASE_MESSAGING_KEY_PAIR })
        .then((currentToken) => {
          if (currentToken) {
            self.debug("current token");
            self.debug(currentToken);
            try {
              //this.debug("save data of token in database");
              /*if (await this.isLoggedInOIDC(true) && currentToken !== self.$store.state.fcmWebTokenAuthorized) {
                //save to database
                let tokenRecord = {
                  type: "fcmWeb",
                  token: currentToken
                }
                self.updateFcmTokenForUser(tokenRecord);
              } else if (currentToken !== self.$store.state.fcmWebToken) {
                //save to database
              
              }*/
              let tokenRecord = {
                type: "fcmWeb",
                token: currentToken,
              };
              self.updateFcmTokenForUser(tokenRecord);
              //listen for messages foreground
              onMessage(messaging, (payload) => {
                console.log("Message received. ", payload);
                const options = {
                  body:
                    "This notification has data attached to it that is printed " +
                    "to the console when it's clicked.",
                  tag: "data-notification",
                  data: {
                    time: new Date(Date.now()).toString(),
                    message: "Hello, World!",
                  },
                };
                console.log(self.registration);
                self.registration.showNotification("title", options);
                // ...
              });
            } catch (e) {
              console.error(e);
            }
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);

          // ...
        });
    },

    async updateFcmTokenForUser(tokenRecord) {
      this.debug(`updateFcmTokenForUser = ${tokenRecord}`);
      let ok = false;
      let message = "";
      let isLoggedIn = false;
      let url = Config.NODE_SERVER_IP + Config.SAVE_FCM_USER_TOKEN;
      let headers = {};
      if (
        (await this.isLoggedInOIDC(true)) &&
        tokenRecord.token !== this.$store.state.fcmWebTokenAuthorized
      ) {
        isLoggedIn = true;
        headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        tokenRecord["userId"] = this.$store.state.accessTokenDecoded.sub;
        ok = true;
      } else if (tokenRecord.token !== this.$store.state.fcmWebToken) {
        //save to database
        ok = true;
      }

      let payload = new FormData();

      payload.append("tokenRecord", JSON.stringify(tokenRecord));

      try {
        if (ok == true) {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "updateFcmTokenForUser");
          ok = jsonRes.ok;
          message = jsonRes.message;
          if (ok == true) {
            if (isLoggedIn == true) {
              this.$store.commit("set", [
                "fcmWebTokenAuthorized",
                tokenRecord.token,
              ]);
            } else {
              this.$store.commit("set", ["fcmWebToken", tokenRecord.token]);
            }
          }
        } else {
          this.debug(`token already saved to database`);
        }
      } catch (e) {
        this.debug(e);
      }
      /* } else {
         this.$router.push(Config.ROUTE_LOGIN);
       }*/

      return {
        ok: ok,
        message: message,
      };
    },

    async sendNotificationsFromSuperadmin(notification) {
      this.debug(`sendNotificationsFromSuperadmin = ${notification}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP + Config.SEND_NOTIFICATIONS_TO_ALL_USERS;
        let headers = {
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("notification", JSON.stringify(notification));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "sendNotificationsFromSuperadmin");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async updateFavouriteAds(adId, favourite = true) {
      let resJson = {};
      try {
        this.debug(`updateFavouriteAds() FavouriteAds`);
        this.debug(adId);
        let url = `${Config.NODE_SERVER_IP}${Config.UPDATE_FAVOURITE_ADS}?adId=${adId}&favourite=${favourite}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson);
      } catch (e) {
        this.debug("err " + e);
      }

      return resJson;
    },

    /**
     * this function can be called from anywhere to sync certain data from profile locally from server. please addd the data in function you want to sync in background
     */
    async syncProfileFromServer() {
      this.debug(`syncProfileFromServer()`);
      let ok = false;

      if (
        this.$route.path == Config.ROUTE_LOGIN ||
        this.$route.path == Config.ROUTE_PROFILE
      ) {
        this.debug(`background sync disabled for this route`);
      } else {
        if (await this.isLoggedInOIDC(true)) {
          let result = await this.getUserProfileData();

          if (result.ok == true) {
            let profile = result.profile;
            if ("favouriteAds" in profile) {
              this.$store.commit("set", ["favouriteAds", profile.favouriteAds]);
            }
          }
        } else {
          this.debug(`Not logged in`);
        }
      }
      return ok;
    },

    async getAllCountryCryptoStatus() {
      let records = [];
      this.debug(`getAllCountryCryptoStatus()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ALL_COUNTRY_CRYPTO_ENABLED_STATUS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        // this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          records = resJson.records;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        records: records,
      };
    },

    async toggleCountryCryptoenabledStatus(record) {
      this.debug(`toggleCountryCryptoenabledStatus = ${record}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP + Config.TOGGLE_COUNTRY_CRYPTO_ENABLED_STATUS;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("record", JSON.stringify(record));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "toggleCountryCryptoenabledStatus()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async fetchAddressByquery(query) {
      let addresses = [];

      let url =
        Config.HERE_GEOCODING +
        "?q=" +
        encodeURIComponent(query) +
        "&apiKey=" +
        Config.HERE_API_KEY;

      this.debug("url = " + url);

      try {
        let res = await fetch(url);
        let jsonRes = await res.json();

        this.printJson(jsonRes, "jsonRes");

        let viewLength = jsonRes.items.length;

        if (viewLength > 0) {
          let result = jsonRes.items;
          /*let len = result.length;*/
          this.debug(`len = ${viewLength}`);

          for (let i = 0; i < viewLength; i++) {
            let location = result[i];

            let addressLabel;

            if ("title" in location === true) {
              this.debug("a");
              addressLabel = location.title;
            }

            addresses.push(location);
            this.debug(i + ". " + addressLabel);
          }
        }
      } catch (e) {
        this.debug("err " + e);
      }

      return addresses;
    },

    async convertPricesForCountries(baseCurrency, price, countries) {
      this.debug(`convertPricesForCountries = ${countries}`);
      let ok = false;
      let message = "";
      let result = {};

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.CONVERT_PRICES_FOR_COUNTRIES;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("baseCurrency", baseCurrency);
        payload.append("price", price);
        payload.append("countries", JSON.stringify(countries));
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "convertPricesForCountries()");
          ok = jsonRes.ok;
          message = jsonRes.message;
          result = jsonRes.result;
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
        result: result,
      };
    },

    async checkForTranlationAvailability(text, countries) {
      this.debug(`checkForTranlationAvailability = ${countries}`);
      let result = {};

      if (await this.isLoggedInOIDC(true)) {
        let url =
          Config.NODE_SERVER_IP + Config.CHECK_FOR_TRANSLATION_AVAILABILITY;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("text", text);
        payload.append("countries", JSON.stringify(countries));
        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "checkForTranlationAvailability()");

          result = jsonRes;
        } catch (e) {
          this.debug("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return result;
    },

    async getAllPaidAdsPricing() {
      let priceRecords = [];
      let PAYPAL_VALID_CURRENCIES = [];
      this.debug(`getAllPaidAdsPricing()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_ALL_PAID_ADS_PRICING}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        // this.printJson(resJson, "resJson");
        if (resJson.ok === true) {
          priceRecords = resJson.priceRecords;
          PAYPAL_VALID_CURRENCIES = resJson.PAYPAL_VALID_CURRENCIES;
        }
      } catch (e) {
        console.error(e);
      }

      return {
        priceRecords: priceRecords,
        PAYPAL_VALID_CURRENCIES: PAYPAL_VALID_CURRENCIES,
      };
    },

    async editPaidAdsPricing(priceRecord) {
      this.debug(`editPaidsAdsPricing = ${priceRecord}`);
      let ok = false;
      let message = "";

      if (await this.isLoggedInOIDC(true)) {
        let url = Config.NODE_SERVER_IP + Config.EDIT_PAID_ADS_PRICING;

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        let payload = new FormData();

        payload.append("priceRecord", JSON.stringify(priceRecord));

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "editPaidsAdsPricing()");
          ok = jsonRes.ok;
          message = jsonRes.message;
        } catch (e) {
          this.debug(e);
        }

        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async getPaidAdsPricingByCountry(country, fetchHistory = false) {
      // let availableCategories = [];
      //let priceRecord = {};
      let resJson = {};
      this.debug(`getPaidAdsPricingByCountry()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_PAID_ADS_PRICING_BY_COUNTRY}?country=${country}&fetchHistory=${fetchHistory}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };

        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        resJson = await res.json();
        this.printJson(resJson, "resJson");
        /* if (resJson.ok === true) {
          priceRecord = resJson.priceRecord;
        }*/
      } catch (e) {
        console.error(e);
      }

      return resJson;
    },

    async getChatsFromBackend() {
      let message = "";

      this.debug(`connectClientToStream()`);
      try {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_CHATS}`;

        let headers = {
          Accept: "application/json",
          Authorization:
            this.$store.state.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
        };
        console.log(`url = ${url}`);
        let fetchData = {
          method: "GET",
          headers: headers,
        };

        let res = await fetch(url, fetchData);

        let resJson = await res.json();
        this.printJson(resJson);
        // let userId = this.$store.state.accessTokenDecoded.sub;

        if (resJson.ok == true) {
          channels = resJson.chats;
          this.updateChatCounts();
        }
      } catch (e) {
        console.error(e);
      }
      this.debug(message);
      return channels;
    },

    getChatsLocal() {
      return channels;
    },

    updateChatCounts() {
      let userId = this.$store.state.accessTokenDecoded.sub;
      let unread_chats = 0;
      let unread_messages = 0;
      for (let i = 0; i < channels.length; i++) {
        let chat = channels[i];
        if ("info" in chat && userId in chat.info) {
          let info = chat.info;
          if (info[userId]["unread"] > 0) {
            unread_chats++;
            unread_messages += info[userId]["unread"];
          }
        }
      }
      this.$store.commit("set", ["unreadMessages", unread_messages]);
      this.$store.commit("set", ["unreadChannels", unread_chats]);
    },

    async updateOnNewMessage(messageObj) {
      for (let i = 0; i < channels.length; i++) {
        let chat = channels[i];
        if (chat.id == messageObj.chatId) {
          if ("info" in chat && messageObj.to in chat.info) {
            chat.info[messageObj.to]["unread"] += 1;
            chat["lastMessageTime"] = Date.now();
          }
          this.printJson(chat, "chat updated to be Shifted");

          channels.splice(i, 1);
          channels.unshift(chat);
          this.printJson(channels, "Shifted fromarray");
          if (messageObj.to == this.$store.state.accessTokenDecoded.sub) {
            this.updateChatCounts();
          }
        }
      }
    },

    async chatMarkedAsRead(data) {
      let userId = this.$store.state.accessTokenDecoded.sub;
      if (data.userId == userId) {
        this.printJson(data, "data of chatMarkedAsRead ");

        for (let i = 0; i < channels.length; i++) {
          let chat = channels[i];
          if (data.chatId == chat.id) {
            if ("info" in chat && userId in chat.info) {
              let info = chat.info;
              this.debug(
                `unread ${info[userId]["unread"]} - ${typeof info[userId][
                  "unread"
                ]}`
              );
              if (info[userId]["unread"] > 0) {
                this.debug(`0`);
                info[userId]["unread"] = 0;
                this.debug(`1`);
                chat["info"] = info;
              }
              this.debug(`2`);
              channels[i] = chat;
              // this.rebuildChannelsList();
              this.debug(`3`);

              this.updateChatCounts();
              break;
            }
          }
        }
      }
    },

    async getAppData() {
      let ok = true;
      let message = "";
      let country = "";
      let currencyCode = "AUD";

      try {
        /*
        if (this.$store.state.currentIP === "") {
          //this.$store.commit("set", ["savedIP", false]);
        */

        // always get latest IP just in case user changes country.
        ok = await this.getIPAddress();

        //}
        //
        console.log(`ip = ${this.$store.state.currentIP}`);
        if (ok === true) {
          let url = `${Config.NODE_SERVER_IP}${Config.GET_APP_DATA}?IP=${this.$store.state.currentIP}`;

          this.debug(`getAppData url = ${url}`);

          let headers = {
            Accept: "application/json",
            //Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
          };

          let fetchData = {
            method: "GET",
            headers: headers,
          };

          let res = await fetch(url, fetchData);

          let resJson = await res.json();
          this.printJson(resJson, `getAppData() resJson`);
          ok = resJson.ok;
          if (ok === true) {
            message = resJson.message;
            country = resJson.countryByIP;

            console.log(`Country by IP: ${country}`);
            this.$store.commit("set", ["countryByIP", country]);

            if (
              this.$store.state.country == "" ||
              this.$store.state.country == undefined
            ) {
              this.$store.commit("set", ["country", country]);
              console.log(
                `default country set as : ${this.$store.state.country}`
              );
            }

            let languageCode = "en";
            if (
              "countryByIPObject" in resJson &&
              "languageCode" in resJson.countryByIPObject
            ) {
              languageCode = resJson.countryByIPObject["languageCode"];
            }
            this.$store.commit("set", ["languageCodeByIP", languageCode]);

            let languageCodesObject = {
              en: " English ",
            };
            if ("languages" in resJson && resJson.languages.length > 0) {
              this.printJson(resJson.languages, `resJson.languages  resJson`);
              for (let i = 0; i < resJson.languages.length; i++) {
                languageCodesObject[resJson.languages[i].code] =
                  resJson.languages[i].name;
              }
            }
            this.$store.commit("set", [
              "languageCodesObject",
              languageCodesObject,
            ]);

            if (languageCode in languageCodesObject == false) {
              languageCode = "en";
            }

            if (this.$store.state.selectedLanguageCode.length == 0) {
              this.$store.commit("set", ["selectedLanguageCode", languageCode]);
            }

            if (
              "countryByIPObject" in resJson &&
              "currencyCode" in resJson.countryByIPObject
            ) {
              currencyCode = resJson.countryByIPObject["currencyCode"];

              this.$store.commit("set", ["currencyCodeByIP", currencyCode]);
            }
            if (this.$store.state.currencyCode.length == 0) {
              this.$store.commit("set", ["currencyCode", currencyCode]);
            }
            /*this.$store.commit("set", [
              "notranslateApp",
              resJson.notranslateApp,
            ]);*/

            //currencyCode = resJson.currencyCode;
            if (
              "countries" in resJson &&
              Object.keys(resJson.countries).length > 0
            ) {
              let countries = Object.values(resJson.countries);
              for (let i = 0; i < countries.length; i++) {
                countries[i]["title"] = countries[i]["name"];
                countries[i]["leading"] = countries[i]["flag"];
                if (countries[i]["ads"] > 0) {
                  countries[i]["trailing"] = countries[i]["ads"] + " ads";
                }
              }
              this.$store.commit("set", ["countries", countries]);
              this.printJson(`Countries: ${this.$store.state.countries}`);
            }

            /*
            this.printJson(
              this.$store.state.countryObject,
              "getCountryByIP countryObject"
            );
            */

            //  this.debug(`currency code: ${this.$store.state.currencyCode}`);
            if ("IP" in resJson) {
              this.$store.commit("set", ["currentIP", resJson.IP]);
            }
            //getstates await removed because of loading time increases
            /* let stateRes = await*/ this.getStates(this.$store.state.country);
            /* ok = stateRes.ok;*/

            await this.loadMessages(languageCode);
          } else {
            ok = false;
            message = resJson.message;
          }
        } else {
          message = "IP address not defined";
          this.debug(message);
        }
      } catch (e) {
        console.error(e);
      }

      return { ok: ok, message: message, currencyCode: currencyCode };
    },

    async loadMessages(localeCode) {
      if (localeCode in messages == false) {
        let url = `${Config.BACKEND00_FILES_URL}/static/${localeCode}.json`;

        try {
          return await fetch(url)
            .then((response) => response.json())
            .then((msgs) => {
              this.printJson(msgs, "messages");
              //  loadedLanguages.push(lang)
              return i18n.setLocaleMessage(localeCode, msgs);
              //  setI18nLanguage(lang)
            });
        } catch (e) {
          console.error(e);
        }
      } else {
        this.debug(`${localeCode} already loaded`);
      }
    },

    async resetMessages(locale) {
      messages = {};
      await this.loadMessages(locale);
    },
  }, // methods
});

//const messages = store.state.messages;
let messages = {
  /*  en: {
      message: {
        hello: "Hello, {name}!",
      },
    },
    de: {
      message: {
        hello: "Guten Tag, {name}!",
      },
    },*/
};

let channels = [];

const i18n = new VueI18n({
  locale: store.state.selectedLanguageCode, // set locale
  messages: messages, // set locale messages
  fallbackLocale: "en",
});

const app = new Vue({
  vuetify,
  render: (h) => h(App),
  router,
  sockets: {
    connect() {
      console.log("socket connected");
    },
  },
  i18n,
}).$mount("#app");
