<template>
  <v-dialog v-model="isShowMyAddressLocal" width="800" persistent>
    <v-card>
      <MyCardTitle
        title="setAddress"
        @close-window="closeWindow()"
      ></MyCardTitle>
      <br />

      <v-card-text>
        <v-container fluid>
          <SelectCountry @country-finalized="changeCountry"></SelectCountry>
          <br />

          <SelectState
            :states="states"
            :selectedState="addressObj.state"
            @state-finalized="updateState"
            :key="myStateKey"
          >
          </SelectState>
          <v-row
            no-gutters
            justify="end"
            v-if="validateWithoutNoAndStreetLocal"
          >
            <a @click="showStreetNameAndNo = !showStreetNameAndNo">
              <span
                >{{
                  showStreetNameAndNo == true
                    ? "Hide"
                    : "Search with number & street"
                }}
                <v-icon x-small color="blue">
                  {{
                    showStreetNameAndNo == true ? mdiArrowUp : mdiArrowDown
                  }}</v-icon
                ></span
              >
            </a>
          </v-row>
          <br />
          <v-text-field
            v-show="
              validateWithoutNoAndStreetLocal == false ||
              (validateWithoutNoAndStreetLocal == true && showStreetNameAndNo)
            "
            :label="$t(`number`)"
            v-model="number"
            placeholder="1/111"
            @input="getNumber = true"
          />

          <v-text-field
            v-show="
              validateWithoutNoAndStreetLocal == false ||
              (validateWithoutNoAndStreetLocal == true && showStreetNameAndNo)
            "
            :label="$t(`street`)"
            v-model="street"
            @input="getStreet = true"
          />

          <v-text-field
            :label="$t(`suburb`)"
            v-model="suburb"
            @input="getSuburb = true"
          />

          <!--<MyDropDown
            itemName="State"
            :dropDownItem="addressObj.state"
            :dropDownItems="states"
            @update-items="updateState"
          ></MyDropDown>-->

          <!--<v-text-field
            label="State/Province"
            v-model="state"
            @input="getState = true"
          />-->

          <v-text-field
            :label="$t(`postCode`)"
            v-model="postcode"
            @input="getPostcode = true"
          />

          <br />
          <!--  <v-progress-linear
            indeterminate
            color="blue darken-2"
            v-if="
              this.getNumber && F
                this.getStreet &&
                this.getSuburb &&
                this.getPostcode //||
              // this.getAddresses()
            "
          ></v-progress-linear>-->
          <v-progress-linear
            indeterminate
            color="green darken-2"
            v-if="this.loading"
          ></v-progress-linear>

          <br />

          <div v-if="showAddresses">
            <p class="subtitle-1 font-weight-bold">
              {{ $t(`selectAddress`) }}
            </p>
            <v-radio-group>
              <div>
                <v-radio
                  v-for="(item, index) in addresses"
                  :key="index"
                  :label="item"
                  :value="item"
                  @change="radioSelected(index)"
                ></v-radio>
              </div>
            </v-radio-group>
          </div>

          <v-layout justify-center>
            <MyBtn
              btnName="useAddress"
              :disabled="!addressSelected"
              :iconName="mdiThumbUpOutline"
              @btn-clicked="finalizeAddress()"
              ref="useSelectedAddressBtn"
            ></MyBtn>
          </v-layout>

          <div v-if="showTypedAddress">
            <p class="subtitle-1 font-weight-bold">{{ $t(`type`) }} <br /></p>
            <p>{{ typedAddress }}</p>
            <v-layout justify-center>
              <MyBtn
                btnName="useType"
                :iconName="mdiThumbUpOutline"
                @btn-clicked="finalizeTypedAddress()"
              ></MyBtn>
            </v-layout>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiThumbUpOutline, mdiArrowDown, mdiArrowUp } from "@mdi/js";

import Config from "../config.js";
import MyBtn from "./MyBtn";
//import MyDropDown from "./MyDropDown";
import MyCardTitle from "./MyCardTitle";
import SelectState from "./SelectState";
import SelectCountry from "./SelectCountry";

// lodash
import debounce from "lodash/debounce";

export default {
  name: "MyAddressInternational",
  components: {
    MyBtn,
    MyCardTitle,
    // MyDropDown,
    SelectState,
    SelectCountry,
  },
  props: ["isShowMyAddress", "inAddressObj", "validateWithoutNoAndStreet"],
  data: () => ({
    isShowMyAddressLocal: false,

    mdiThumbUpOutline: mdiThumbUpOutline,
    mdiArrowDown: mdiArrowDown,
    mdiArrowUp: mdiArrowUp,
    number: "",
    street: "",
    suburb: "",
    postcode: "",
    state: "",
    getNumber: false,
    getStreet: false,
    getSuburb: false,
    getPostcode: false,
    getState: false,
    loading: false,

    addressObj: {
      number: "",
      street: "",
      suburb: "",
      state: "",
      postcode: "",
      country: "",
    },
    states: [],

    /*states: [
      "New South Wales",
      "Northen Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia",
    ],*/

    addresses: [],

    showAddresses: false,
    addressesIndex: "",
    addressesJson: {},

    addressSelected: false,
    myStateKey: 1,

    typedAddress: "",
    showTypedAddress: false,
    countriesToShowTypedAddress: [
      /*"Sri Lanka"*/
    ],
    countriesWithCurrencyCode: {
      "Sri Lanka": "LKR",
    },

    validateWithoutNoAndStreetLocal: false,
    showStreetNameAndNo: false,
  }),

  mounted: async function () {
    await this.init();
  },

  watch: {
    number: debounce(function () {
      this.getNumber = false;
    }, 1000),
    getNumber: function (val) {
      if (!val) {
        this.addressObj.number = this.number;
        this.getAddresses();
      }
    },

    street: debounce(function () {
      this.getStreet = false;
    }, 1000),
    getStreet: function (val) {
      if (!val) {
        this.addressObj.street = this.street;
        this.getAddresses();
      }
    },

    suburb: debounce(function () {
      this.getSuburb = false;
    }, 1000),
    getSuburb: function (val) {
      if (!val) {
        this.addressObj.suburb = this.suburb;
        this.getAddresses();
      }
    },

    postcode: debounce(function () {
      this.getPostcode = false;
    }, 1000),
    getPostcode: function (val) {
      if (!val) {
        this.addressObj.postcode = this.postcode;
        this.getAddresses();
      }
    },
    state: debounce(function () {
      this.getState = false;
    }, 1000),
    getState(val) {
      if (!val) {
        this.addressObj.state = this.state;
        this.getAddresses();
      }
    },
  },

  methods: {
    async init() {
      this.isShowMyAddressLocal = this.isShowMyAddress;
      if (
        this.validateWithoutNoAndStreet == undefined ||
        this.validateWithoutNoAndStreet == null
      ) {
        this.validateWithoutNoAndStreetLocal = false;
      } else {
        this.validateWithoutNoAndStreetLocal = this.validateWithoutNoAndStreet;
      }

      this.showTypedAddress = false;
      this.typedAddress = "";
      this.printJson(this.inAddressObj, "MyAddress inAddressObj");
      this.states = this.$store.state.states;
      if (this.states[0] === "All") {
        this.states.splice(0, 1);
      }
      this.addressObj.state = this.states[0];

      this.debug(`aa country = ${this.$store.state.country}`);

      if ("country" in this.inAddressObj) {
        if (this.$store.state.country !== this.inAddressObj.country) {
          this.$store.commit("set", ["country", this.inAddressObj.country]);
          await this.getStates(this.$store.state.country);
        }
        this.myStateKey++;
      }

      if ("number" in this.inAddressObj) {
        if ("unit" in this.inAddressObj) {
          this.addressObj.number = `${this.inAddressObj.unit}/${this.inAddressObj.number}`;
        } else {
          this.addressObj.number = this.inAddressObj.number;
        }
      } else {
        this.addressObj.number = "";
      }
      this.number = this.addressObj.number;

      if ("street" in this.inAddressObj) {
        this.addressObj.street = this.inAddressObj.street;
      } else {
        this.addressObj.street = "";
      }
      this.street = this.addressObj.street;

      if ("suburb" in this.inAddressObj) {
        this.addressObj.suburb = this.inAddressObj.suburb;
      } else {
        this.addressObj.suburb = "";
      }
      this.suburb = this.addressObj.suburb;

      if ("state" in this.inAddressObj) {
        this.addressObj.state = this.inAddressObj.state;
      } else {
        this.addressObj.state = "";
      }

      if ("postcode" in this.inAddressObj) {
        this.addressObj.postcode = this.inAddressObj.postcode;
      } else {
        this.addressObj.postcode = "";
      }
      this.postcode = this.addressObj.postcode;

      if ("country" in this.inAddressObj) {
        this.addressObj.country = this.inAddressObj.country;
      } else {
        this.addressObj.country = this.$store.state.country;
      }

      await this.getAddresses();
    },

    async getAddresses() {
      let validate = true;
      this.printJson(this.addressObj, "addressObj");
      if (this.validateWithoutNoAndStreetLocal == false) {
        if (
          this.addressObj.number == "" ||
          this.addressObj.street == "" ||
          this.addressObj.suburb == "" ||
          this.addressObj.postcode == ""
        ) {
          this.showAddresses = false;
          validate = false;
        } else if (this.addressObj.postcode.length < 4) {
          this.showAddresses = false;
          validate = false;
        }
      } else {
        if (this.addressObj.suburb == "" || this.addressObj.postcode == "") {
          this.showAddresses = false;
          validate = false;
        } else if (this.addressObj.postcode.length < 4) {
          this.showAddresses = false;
          validate = false;
        }
      }
      if (validate == true) {
        this.loading = true;
        this.debug(`Config.HERE_GEOCODING = ${Config.HERE_GEOCODING}`);
        this.debug(`Config.HERE_API_KEY = ${Config.HERE_API_KEY}`);

        //let countryCode = "AUS";
        //        let streetWithNumber = `${this.addressObj.number} ${this.addressObj.street}`
        let Address =
          this.addressObj.number +
          " " +
          this.addressObj.street +
          ", " +
          this.addressObj.suburb +
          " " +
          this.addressObj.state +
          " " +
          this.addressObj.postcode +
          " " +
          this.addressObj.country;

        /*let url =
          Config.HERE_GEOCODING +
          "?apiKey=" +
          Config.HERE_API_KEY +
          `&Country=${countryCode}` +
          `&searchtext=${encodeURIComponent(searchtext)}` +
          "&additionaldata=IncludeMicroPointAddresses,true";*/
        let url =
          Config.HERE_GEOCODING +
          "?q=" +
          encodeURIComponent(Address) +
          "&apiKey=" +
          Config.HERE_API_KEY;

        /*
        let url = Config.HERE_GEOCODING + '?apiKey=' + Config.HERE_API_KEY +
          `&Country=${countryCode}` +
          `&state=${countryCode}:${encodeURIComponent(this.addressObj.state)}`
          + `&postalcode=${this.addressObj.postcode}` +
          `&city=${countryCode}:${encodeURIComponent(this.addressObj.suburb)}` 
          + `&street=${encodeURIComponent(streetWithNumber)}`
          + '&additionaldata=IncludeMicroPointAddresses,true'
          */

        this.debug("url = " + url);

        try {
          let res = await fetch(url);
          let jsonRes = await res.json();

          this.printJson(jsonRes, "jsonRes");

          let viewLength = jsonRes.items.length;

          if (viewLength > 0) {
            let result = jsonRes.items;
            /*let len = result.length;*/
            this.debug(`len = ${viewLength}`);

            this.addresses = [];
            for (let i = 0; i < viewLength; i++) {
              let location = result[i];

              let addressLabel;

              if ("title" in location === true) {
                this.debug("a");
                addressLabel = location.title;
              } /* else {
                this.debug("b");
                addressLabel = location.Address.Label;
              }*/

              this.addresses.push(addressLabel);
              this.debug(i + ". " + addressLabel);
            }
          }

          this.showAddresses = true;

          this.addressesIndex = 0;

          this.addressesJson = jsonRes;

          if (
            this.countriesToShowTypedAddress.includes(this.addressObj.country)
          ) {
            this.typedAddress = await this.capitalizeFirstLetter(Address);

            this.showTypedAddress = true;
          }
          //          this.$refs.useSelectedAddressBtn.$el.scrollIntoView()
        } catch (e) {
          this.debug("err " + e);
        }
        this.loading = false;
      }
    },

    /*
    async getAddressesOrig() {
      if ((this.addressObj.number == '') ||
          (this.addressObj.street == '') ||
          (this.addressObj.suburb == '') ||
          (this.addressObj.postcode == '')) {
        this.showAddresses = false
      } else if (this.addressObj.postcode.length < 4) {
        this.showAddresses = false
      } else {
        this.debug('not null')

        let url = Config.MAPBOX_PLACES + 
          encodeURIComponent(this.addressObj.number) + '%20' +
          this.addressObj.street + '%20' +
          this.addressObj.suburb + '%20' +
          this.addressObj.state + '%20' +
          this.addressObj.postcode +
          '.json?country=AU&types=address&access_token=' +
          Config.MAPBOX_TOKEN

        this.debug('url = ' + url)
        try {
          let res = await fetch(url)
          let jsonRes = await res.json()

          let len = jsonRes.features.length
          this.addresses = [] 
          for (let i = 0; i < len; i++) {
            let place_name = jsonRes.features[i].place_name
            this.addresses.push(place_name)
            this.debug(i + '. ' + place_name)
          }

          this.showAddresses = true

          this.addressesIndex = 0

          this.addressesJsonResponse = jsonRes

//          this.$refs.useSelectedAddressBtn.$el.scrollIntoView()

        } catch (e) {
          this.debug('err ' + e)
        }

      }
    },
    */

    async updateState(index) {
      this.addressObj.state = this.states[index];

      await this.getAddresses();
    },

    /* Returns -1 if not found
     */
    getAddressContextIndex(addressJson, field) {
      let len = addressJson.context.length;
      let index = -1;
      for (let i = 0; i < len; i++) {
        let singleContext = addressJson.context[i];
        if (singleContext.id.includes(field)) {
          index = i;
          break;
        }
      }
      return index;
    },

    async finalizeAddress() {
      let location = this.addressesJson.items[this.addressesIndex].address;

      this.printJson(location, "finalizeAddress() location");

      this.addressObj.number = location.houseNumber;

      this.addressObj.street = location.street;

      this.addressObj.suburb = location.district;

      this.addressObj.state = location.state;

      this.addressObj.country =
        /*location.countryName*/ this.$store.state.country;

      if ("district" in location) {
        this.addressObj.suburb = location.district;
      } else if ("county" in location) {
        this.addressObj.suburb = location.county;
      }

      //this.$store.commit("set", ["country", location.countryName]);

      /*let additionalData = location.Address.AdditionalData;
      let len = additionalData.length;
      for (let i = 0; i < len; i++) {
        if (additionalData[i].key === "StateName") {
          this.addressObj.state = additionalData[i].value;
          break;
        }
      }*/
      // assume the above for() will always find it
      if ("postalCode" in location) {
        this.addressObj.postcode = location.postalCode;
      }
      /* if ("Related" in location === true) {
        // additional info (eg. unit number)
        this.addressObj.unit = location.Related[0].Location.Address.Unit;

        this.addressObj.full = location.Related[0].Location.Address.Label;
      } else {*/
      this.addressObj.full = location.label;

      // }
      let resCurrency = await this.getCurrencyCode(location.countryCode);
      let currencyCode = "";
      if (resCurrency.ok === true) {
        currencyCode = resCurrency.currencyCode;
      }
      this.$emit("address-finalized", this.addressObj, currencyCode);
      this.debug(currencyCode);
    },

    //TODO: Add currency code
    async finalizeTypedAddress() {
      this.$store.commit("set", ["country", this.addressObj.country]);

      this.addressObj.full = this.typedAddress;

      let currencyCode =
        this.countriesWithCurrencyCode[this.addressObj.country];
      this.$emit("address-finalized", this.addressObj, currencyCode);
      this.debug(currencyCode);
    },

    /*
    finalizeAddressOrig() {

      let addressJson = this.addressesJsonResponse.features[this.addressesIndex]
      this.addressObj.number = addressJson.address
      this.addressObj.street = addressJson.text

      let suburbIndex = this.getAddressContextIndex(addressJson, 'locality')

      this.message = ''
      this.showErrorMessage = false

      if (suburbIndex == -1) {
        this.message = "error can't find locality"
        this.showErrorMessage = true
      } else {
        this.addressObj.suburb = addressJson.context[suburbIndex].text
      }

      let stateIndex = this.getAddressContextIndex(addressJson, 'region')
      if (stateIndex == -1) {
        this.message = "error can't find region"
        this.showErrorMessage = true
      } else {
        this.addressObj.state = addressJson.context[stateIndex].text
      }

      let postcodeIndex = this.getAddressContextIndex(addressJson, 'postcode')
      if (stateIndex == -1) {
        this.message = "error can't find postcode"
        this.showErrorMessage = true
      } else {
        this.addressObj.postcode = addressJson.context[postcodeIndex].text
      }

      this.$emit('address-finalized', this.addressObj);
    },
    */

    closeWindow() {
      this.$emit("close-window");
    },

    radioSelected(index) {
      this.debug(index);
      this.addressesIndex = index;
      this.addressSelected = true;

      this.$refs.useSelectedAddressBtn.$el.scrollIntoView();
    },

    async changeCountry() {
      this.addressObj.country = this.$store.state.country;
      this.states = this.$store.state.states;
      if (this.states[0] === "All") {
        this.states.splice(0, 1);
      }

      this.addressObj.state = this.states[0];
      this.myStateKey++;
      await this.getAddresses();
    },
  }, // methods
};
</script>
