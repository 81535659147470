<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>modifyAd</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            Go to <strong>My Ads</strong> and select the Ad you want to
            modify<MeSpeak
              text="Go to My Ads and select the Ad you want to modify"
            ></MeSpeak>
            <ul>
              <ul>
                <v-img
                  :src="require(`./pics/myAdsPc0.png`)"
                  class="ma-3 hidden-xs-only"
                  contain
                  alt="My Mobilityguru"
                />
                <v-img
                  :src="require(`./pics/myAdsPhone0.png`)"
                  class="mr-5 mt-5 hidden-sm-and-up"
                  contain
                  alt="My Mobilityguru"
                />
              </ul>
              <v-divider class="ma-5"></v-divider>
              <ul>
                <v-img
                  :src="require(`./pics/myAds1.png`)"
                  class="mr-5 mt-5"
                  contain
                  alt="My Ads"
                />
              </ul>
            </ul>
          </li>
          <li>
            Click the <strong>EDIT AD</strong> &amp; do the changes.
            <MeSpeak
              text=" Click the EDIT AD button&amp; do the changes."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/modifyAdsPc0.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Edit Ad"
              />
              <v-img
                :src="require(`./pics/modifyAdsPhone0.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Edit Ad"
              />
            </ul>
          </li>
          <li>
            Hit the <strong>SAVE CHANGES</strong> to save the changes you made!!
            Done!!
            <MeSpeak
              text="   Hit the SAVE CHANGES button to save the changes you made"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/modify1.png`)"
                class="mr-5 mt-5"
                contain
                alt="Save Changes"
              />
            </ul>
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "modifyAd",
  components: { MeSpeak },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
