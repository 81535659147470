<template>
  <v-card elevation="0" color="transparent">
    <v-card
      @click="gotoAdView(ad.id, adType)"
      color="transparent"
      elevation="0"
    >
      <div class="text-center notranslate">
        <b>{{ ad.title }}</b>
      </div>
      <div class="body-2">
        {{ $t(`${ad.mainCategory}`) }}
        <!--  {{ $t(`message.${ad.mainCategory}`) }}-->
        &gt;
        <v-col v-if="ad.mainCategory == MISCELLANEOUS_STR"></v-col>
        {{ $t(`${ad.subCategory}`)
        }}<!--{{ $t(`message.${ad.subCategory}`) }}-->
      </div>
      <v-row dense class="mb-2">
        <v-col class="body-2"
          ><span v-if="'price' in ad" class="notranslate"
            >{{ ad.currencyCode }} {{ ad.price }}</span
          ></v-col
        >
        <v-col
          v-if="
            ad.mainCategory != JOBS_STR &&
            ad.mainCategory != SERVICES_STR &&
            ad.mainCategory != TRAVEL_STR
          "
        >
          <b>
            <span class="green--text caption" v-if="ad.condition == 'new'">
              <!-- $t(`message.new`)-->
              {{ $t("new") }}</span
            >
            <span class="blue--text" v-if="ad.condition == 'used'">
              <!--  $t(`message.used`)-->
              {{ $t("used") }}</span
            >
          </b>
          <br />
        </v-col>
      </v-row>

      <v-card
        color="transparent"
        v-if="'savedFiles' in ad && ad.savedFiles.length > 0"
        max-height="150px"
        align="center"
        justify="center"
      >
        <!--
      <img
        :src="BACKEND00_FILES_URL + '/' + ad.savedFiles[0]"
        style="object-fit: contain; width: 250px; height: 150px;"
        :id="'image' + ad.id"
        :lazy-src="BACKEND00_FILES_URL + '/' + ad.savedFiles[0]"
              />
      -->
        <v-img
          :src="BACKEND00_FILES_URL + '/' + ad.savedFiles[0]"
          transition="scale-transition"
          height="150px"
          contain
          :id="'image' + ad.id"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="brown"
              ></v-progress-circular>
            </v-row> </template
        ></v-img>
      </v-card>

      <v-card v-else height="150px" color="transparent" class="pt-1">
        <v-row align="center" justify="center" style="height: 150px">
          <div>
            <v-icon color="brown">{{ mdiImageOffOutline }}</v-icon>
          </div>
        </v-row>
      </v-card>

      <div class="text-center body-2 notranslate">
        <span
          v-if="'suburb' in ad.addressObj && ad.addressObj.suburb.length > 0"
        >
          {{ ad.addressObj.suburb }}
        </span>
        <span v-if="'state' in ad.addressObj && ad.addressObj.state.length > 0"
          >,{{ ad.addressObj.state }}</span
        >

        <br />
      </div>

      <div class="text-center body-2 red--text">
        {{ ad.country }}
        <br />
      </div>
      <!--
    E: {{ad.email}} <br/>
    P: <span v-if='ad.phone != ""'>{{ad.phone}}</span>
    -->

      <v-card
        elevation="0"
        height="50px "
        class="black--text notranslate"
        color="transparent"
      >
        <span v-if="ad.description.length > 50"
          >{{ ad.description.substring(0, 50) }}...</span
        >
        <span v-else>{{ ad.description.substring(0, 53) }}</span>
      </v-card>
      <v-row
        justify="end"
        no-gutters
        dense
        class="mt-n3 rightcornered"
        v-if="topAd === undefined"
      >
        <span style="font-size: 11px">{{ label }} </span>
      </v-row>
      <p class="text-center" v-if="showLive">
        <b>
          <span v-if="ad.published" class="green--text">Live</span>
          <span v-else class="red--text"
            >Paused
            <!--{{ $t(`message.paused`) }}--></span
          >
        </b>
      </p>
      <p
        v-show="showLive"
        class="text-center"
        v-if="'topAdId' in ad && ad.topAdId.length > 0"
      >
        <b>
          <span class="green--text"
            >Top Ad
            <!--{{ $t(`message.top_ad`) }}--></span
          >
        </b>
      </p>
    </v-card>

    <v-card outlined color="transparent" id="rightTop" elevation="0">
      <MyIcon
        :iconName="favourite ? mdiHeart : mdiHeartOutline"
        @btn-clicked="updateFavourite()"
      ></MyIcon>
    </v-card>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
      :showActionBtn="showActionBtn"
      :actionName="actionName"
      @action-clicked="goToProfileWithHash('#SetAddress')"
    ></MyAcknowledge>
  </v-card>
</template>

<script>
import MyAcknowledge from "./MyAcknowledge";
import Config from "../config.js";
import MyIcon from "../components/MyIcon.vue";
// import AdView from "../components/AdView.vue";
import { mdiImageOffOutline, mdiHeart, mdiHeartOutline } from "@mdi/js";

export default {
  name: "AdCell",
  props: ["ad", "adType", "topAd"],
  components: {
    MyIcon,
    MyAcknowledge,
  },
  computed: {
    showMerchant() {
      let showMerchant = true;
      if (this.ad.merchant === undefined || this.ad.merchant === "") {
        showMerchant = false;
      }

      return showMerchant;
    },

    showLive() {
      let showLive = false;
      if (
        this.adType == Config.AD_TYPES_ENUM.MY_ADS ||
        this.adType == Config.AD_TYPES_ENUM.USER_ADS
      ) {
        showLive = true;
      }

      return showLive;
    },
  },

  data: () => ({
    BACKEND00_FILES_URL: Config.BACKEND00_FILES_URL,

    JOBS_STR: "Jobs",
    SERVICES_STR: "Services",
    TRAVEL_STR: "Travel",
    MISCELLANEOUS_STR: "Miscellaneous",
    mdiImageOffOutline: mdiImageOffOutline,

    label: "",
    mdiHeart: mdiHeart,
    mdiHeartOutline: mdiHeartOutline,
    favourite: false,
    allAdIds: [],
    adId: "",

    isSuperAdmin: false,
    isForumModerator: false,
    accessTokenDecoded: {},
    isLoading: false,
    favouriteAds: false,
    showActionBtn: false,
    actionName: "",

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    myAcknowledgeKey: "MyAcknowledge0",
    myAcknowledgeKeyCounter: 0,
  }),

  mounted: async function () {
    this.applyRotationAngle();
    this.label = this.calculateDays(this.ad.lastEdited);
    this.debug(this.ad.id);
    this.adId = this.ad.id;
    this.favourite = this.$store.state.favouriteAds.includes(this.ad.id);
  },

  methods: {
    applyRotationAngle() {
      if ("rotationAngles" in this.ad) {
        if (this.ad.rotationAngles.length > 0) {
          let img = document.getElementById("image" + this.ad.id);
          let deg = this.ad.rotationAngles[0];

          if (deg !== 0) {
            img.style.transform = "rotate(" + deg + "deg)";

            if (deg == 90) {
              img.style.transform += " scaleX(0.6)";
            }
          }
        }
      }
    },

    async updateFavourite() {
      if (await this.isLoggedInOIDC(true)) {
        this.favourite = !this.favourite;
        //update to server
        await this.updateFavouriteAds(this.adId, this.favourite);
        //when this function successfully completed the socket.io will send broadcast message favourited and the related userId will receive it store it
        this.$emit("favourite-removed");
      } else {
        //Code for showing the alert (MyAcknowledge)
        this.acknowledgeMessage = `Please LOGIN or SIGNUP`;
        this.acknowledgeTitle = "Login Required";
        this.showMyAcknowledge();
      }
    },

    showMyAcknowledge() {
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
      this.showAcknowledge = true;
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },

    calculateDays(lastEdited) {
      this.debug(lastEdited);
      let label = "";
      let present_date = new Date();
      lastEdited = Date.parse(lastEdited);
      let days = (present_date - lastEdited) / (1000 * 3600 * 24);
      let hours = days * 24;
      this.debug(`hours = ${hours}`);

      if (hours < 1) {
        let numOfMins = Math.floor(hours * 60);
        this.debug(numOfMins);
        if (numOfMins < 2) {
          label = `Just now`;
          //label = this.$t("message.just_now"); //`Just now`;
        } else {
          label = `${numOfMins} minutes ago`;
          //label = `${numOfMins} ${this.$t("message.minutes_ago")}`; //minutes ago
        }
      } else if (days < 1) {
        let numOfHours = Math.floor(days * 24);
        this.debug(numOfHours);
        //label = `${numOfHours} hours ago`;
        if (numOfHours == 1) {
          label = `${numOfHours} hour ago`;
          //label = `${numOfHours}  ${this.$t("message.hour_ago")}`; //hour ago
        } else {
          label = `${numOfHours} hours ago`;
          //   label = `${numOfHours}  ${this.$t("message.hours_ago")}`; //hours ago
        }
      } else if (Math.floor(days) == 1) {
        let numOfDays = Math.floor(days);
        this.debug(`Number of days${numOfDays}`);
        label = `${numOfDays} day ago`; //day ago
      } /* if (days < 14)*/ else {
        let numOfDays = Math.floor(days);
        this.debug(days);
        this.debug(`Number of days${numOfDays}`);
        label = `${numOfDays} days ago`; //days ago
      } /*else {
        let numOfDays = Math.floor(days);
        this.debug(days);
        this.debug(`Number of days${numOfDays}`);
        label = `${numOfDays} ${this.$t("message.days_ago")}`;
        label = new Date(lastEdited).toDateString();
      }*/
      return label;
    },
  },
};
</script>
