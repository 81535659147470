<template>
  <v-card class="mx-auto" max-width="800">
    <v-card-title>
      {{ $t(`invoice`) }}
      <v-spacer></v-spacer>
      <v-progress-circular indeterminate v-show="isLoading">
      </v-progress-circular>
    </v-card-title>
    <v-card-text v-if="!isLoading && invoiceError == false">
      <seller-and-buyer-info
        :buyer="invoice.buyer"
        :seller="invoice.seller"
      ></seller-and-buyer-info>

      <br />

      <v-col cols="12" lg="6" md="6" sm="6" class="pa-0">
        <h3>{{ $t(`adDetails`) }}</h3>
        <h4 class="mb-5">
          {{ $t(`title`) }} :
          <a @click="gotoAdView(invoice.ad.id, 1)">
            {{ invoice.ad.title }}
          </a>
        </h4>
        <br />

        <h3>{{ $t(`paymentDetails`) }}</h3>
        <v-row no-gutters class="">
          <span>Price ({{ invoice.currencyCode }})</span> <v-spacer></v-spacer>
          {{ parseFloat(invoice.price).toFixed(2) }}
        </v-row>

        <v-row no-gutters>
          <span>{{ $t(`discount`) }} ({{ invoice.currencyCode }})</span>
          <v-spacer></v-spacer>
          {{
            invoice.discount.length > 0
              ? parseFloat(invoice.discount).toFixed(2)
              : `0.00`
          }}
        </v-row>

        <v-row
          no-gutters
          v-if="
            'freightChargeType' in invoice &&
            'freightCharge' in invoice &&
            invoice.freightChargeType.length > 0
          "
        >
          <span
            >{{ $t(`freight_charge`) }} ({{ invoice.freightChargeType }}) ({{
              invoice.currencyCode
            }})</span
          >
          <v-spacer></v-spacer>
          {{
            invoice.freightCharge.length > 0
              ? parseFloat(invoice.freightCharge).toFixed(2)
              : "0.00"
          }}
        </v-row>

        <v-row
          no-gutters
          v-if="'gstTax' in invoice && invoice.gstTax.length > 0"
        >
          <span>{{ $t(`gst_tax`) }} ({{ invoice.currencyCode }})</span>
          <v-spacer></v-spacer>
          {{
            invoice.gstTax.length > 0
              ? parseFloat(invoice.gstTax).toFixed(2)
              : "0.00"
          }}
        </v-row>

        <v-row no-gutters class="subtitle-2">
          <span>{{ $t(`grand_total`) }} ({{ invoice.currencyCode }})</span>
          <v-spacer></v-spacer> {{ parseFloat(invoice.finalPrice).toFixed(2) }}
        </v-row>
        <br />

        <v-row no-gutters class="subtitle-2">
          <span>{{ $t(`price`) }}</span> <v-spacer></v-spacer>
          {{ parseFloat(invoice.guruPrice).toFixed(4) }}
        </v-row>

        <v-row no-gutters class="subtitle-2">
          <span>{{ $t(`guru_buyer_should_pay`) }}</span> <v-spacer></v-spacer>
          {{ parseFloat(invoice.guruBuyerShouldPay).toFixed(4) }}
        </v-row>
        <v-divider></v-divider>
        <!--Add payment option if GURU is acceptable -- for buyer-->
        <v-row
          justify="start"
          v-show="userId == invoice.buyerId"
          no-gutters
          class=""
        >
          <DepayWidget
            v-if="hasBuyerPaid == false"
            :payment="true"
            :amount="guruBuyerShouldPay"
            :showBuyGuru="true"
            @depay-payment-confirmed="depayPaymentConfirmed"
          ></DepayWidget>

          <div v-else>
            <span class="red--text" v-show="buyerPaymentStatus == 'PENDING'">{{
              $t(`pending_payment`)
            }}</span>

            <span
              class="green--text"
              v-show="buyerPaymentStatus == 'COMPLETED'"
            >
              {{ $t(`payment_completed`) }}</span
            >
          </div>
        </v-row>

        <v-divider></v-divider>
        <!--options for seller-->
        <br />
        <v-col v-show="userId == invoice.sellerId" class="pa-0">
          <v-row no-gutters class="subtitle-2">
            <span>{{ $t(`guru_you_will_recieve`) }}</span> <v-spacer></v-spacer>
            {{ parseFloat(invoice.offerAddedPrice).toFixed(4) }}
          </v-row>

          <span v-show="hasBuyerPaid == false">
            {{ $t(`has_not_paid_yet`) }}
          </span>
          <br />
          <v-card class="mx-auto" elevation="0" v-show="hasBuyerPaid == true">
            <span class="red--text" v-show="buyerPaymentStatus == 'PENDING'">{{
              $t(`pending_buyer`)
            }}</span>

            <span
              class="green--text"
              v-show="buyerPaymentStatus == 'COMPLETED'"
            >
              {{ $t(`completed_buyer`) }}</span
            >
            <br />

            <span v-show="hasPaidToSeller == false">
              {{ $t(`admin_transfer_wait_msg`) }}</span
            >
            <span
              v-show="
                hasPaidToSeller == true && sellerPaymentStatus == 'COMPLETED'
              "
              class="green--text"
            >
              {{ $t(`completed_admin`) }}</span
            >
            <span
              v-show="
                hasPaidToSeller == true && sellerPaymentStatus == 'PENDING'
              "
              class="red--text"
            >
              {{ $t(`pending_admin`) }}</span
            >
          </v-card>
        </v-col>
        <br />
        <v-divider></v-divider>
        <!--options for admin-->
        <v-col class="pa-0" v-if="isSuperAdmin">
          <v-card class="mx-auto" elevation="0" v-show="hasBuyerPaid == true">
            <h3>{{ $t(`feature`) }}</h3>
            <span v-show="buyerPaymentStatus == 'PENDING'" class="red--text">{{
              $t(`pending_buyer`)
            }}</span>

            <span
              v-show="buyerPaymentStatus == 'COMPLETED'"
              class="green--text"
            >
              {{ $t(`completed_buyer`) }}</span
            >
            <br />

            <!--Add option to change receiver's wallet address-->
            <DepayWidget
              v-show="
                hasPaidToSeller == false && buyerPaymentStatus == 'COMPLETED'
              "
              :payment="true"
              :amount="offerAddedPrice"
              :showBuyGuru="true"
              @depay-payment-confirmed="depayPaymentConfirmedByAdmin"
              :receiverAddress="sellerWalletAddress"
            ></DepayWidget>

            <span
              v-show="
                hasPaidToSeller == true && sellerPaymentStatus == 'COMPLETED'
              "
              class="green--text"
            >
              {{ $t(`completed_admin`) }}</span
            >
            <span
              v-show="
                hasPaidToSeller == true && sellerPaymentStatus == 'PENDING'
              "
              class="red--text"
            >
              {{ $t(`pending_admin`) }}</span
            >
          </v-card>
        </v-col>
        <br />
        <MyBtn
          v-show="isSuperAdmin || userId == invoice.sellerId"
          :disabled="hasBuyerPaid"
          :iconName="mdiContentSaveEdit"
          btnName="edit_invoice"
          @btn-clicked="editInvoice()"
        ></MyBtn>
      </v-col>
    </v-card-text>
    <v-card-text class="text-center red--text" v-show="invoiceError">
      {{ $t(`invoice_error`) }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiContentSaveEdit } from "@mdi/js";
import Config from "../config.js";
import SellerAndBuyerInfo from "./SellerAndBuyerInfo.vue";
import DepayWidget from "./DepayWidget.vue";
import MyBtn from "./MyBtn";
export default {
  name: "InvoiceView",
  components: { SellerAndBuyerInfo, MyBtn, DepayWidget },

  metaInfo: {
    title: "Invoice | Mobility Guru",
    meta: [
      {
        name: "description",
        content: "View invoice, GURU token invoice",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  props: [],

  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({
    mdiContentSaveEdit: mdiContentSaveEdit,
    invoiceId: "",
    invoice: {},
    isLoading: true,
    seller: {},
    buyer: {},
    userId: "",
    priceInGuru: 0,
    guruBuyerShouldPay: 0,
    offerAddedPrice: 0,
    hasBuyerPaid: false,
    buyerPaymentStatus: "",
    sellerPaymentStatus: "",
    hasPaidToSeller: false,
    isSuperAdmin: false,
    sellerWalletAddress: "",
    invoiceError: false,
  }),

  beforeMount: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.invoiceId = this.$route.query.id;
      this.userId = this.$store.state.accessTokenDecoded.sub;
      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.debug("EditInvoice.vue invoiceId = " + this.invoiceId);
      let result = await this.getInvoiceById(this.invoiceId);
      if (result.ok == true) {
        this.invoice = result.invoice;
        this.seller = result.seller;
        this.buyer = result.buyer;
        this.priceInGuru = this.invoice.guruPrice;
        this.guruBuyerShouldPay = this.invoice.guruBuyerShouldPay;
        this.offerAddedPrice = this.invoice.offerAddedPrice;
        this.hasBuyerPaid = this.invoice.hasBuyerPaid;
        this.buyerPaymentStatus = this.invoice.buyerPaymentStatus;
        this.hasPaidToSeller = this.invoice.hasPaidToSeller;
        this.sellerWalletAddress = this.invoice.walletAddress;
        this.sellerPaymentStatus = this.invoice.sellerPaymentStatus;

        this.invoiceError = false;
      } else {
        this.invoiceError = true;
      }
      this.debug(this.hasBuyerPaid);
      this.isLoading = false;
    },

    async editInvoice() {
      let query = {
        invoiceId: this.invoice.id,
      };
      await this.pushRoute(Config.ROUTE_EDIT_INVOICE, query);
    },

    async depayPaymentConfirmed(data) {
      this.debug("paymemt completed");
      this.debug(data);
      let invoiceData = {};
      data["guruAmount"] = this.guruBuyerShouldPay;
      data["token"] = Config.GURU_TOKEN_ADDRESS;
      invoiceData["id"] = this.invoiceId;
      invoiceData["depayDataBuyer"] = data;
      invoiceData["paymentType"] = "depay";
      invoiceData["buyerId"] = this.invoice.buyerId;
      invoiceData["sellerId"] = this.invoice.sellerId;
      let result = await this.updateInvoice(invoiceData, true, false);
      if (result.ok == true) {
        await this.init();
      }
    },

    async depayPaymentConfirmedByAdmin(data) {
      this.debug("paymemt completed");
      this.debug(data);
      let invoiceData = {};
      data["guruAmount"] = this.offerAddedPrice;
      data["token"] = Config.GURU_TOKEN_ADDRESS;
      invoiceData["id"] = this.invoiceId;
      invoiceData["depayDataSeller"] = data;
      invoiceData["paymentType"] = "depay";
      invoiceData["buyerId"] = this.invoice.buyerId;
      invoiceData["sellerId"] = this.invoice.sellerId;
      let result = await this.updateInvoice(invoiceData, false, true);
      if (result.ok == true) {
        await this.init();
      }
    },
  },
};
</script>
