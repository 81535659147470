<template>
  <v-col id="transparentBack">
    <div id="left" class="hidden-sm-and-down">
      <FilterSection @hide-drawer="hideLeft()"></FilterSection>
    </div>

    <v-navigation-drawer
      id="scroll-target"
      elevation="0"
      class="overflow-y-auto fillColour"
      height="600px"
      width="280px"
      v-model="drawer"
      absolute
    >
      <FilterSection @hide-drawer="drawer = !drawer"></FilterSection>

      <v-row
        v-scroll:#scroll-target="onScroll"
        align="center"
        justify="center"
        style="height: 450px"
      ></v-row>
    </v-navigation-drawer>

    <br />

    <v-card
      elevation="0"
      color="transparent"
      max-width="1700"
      min-width="320"
      class="mx-auto"
    >
      <v-toolbar class="mt-n9" color="transparent" elevation="0">
        <v-row class="">
          <v-btn
            v-if="hideFilter === false"
            class="mgbtn hidden-sm-and-down"
            @click="hideLeft()"
          >
            <v-icon left color="green" large> {{ mdiFilter }} </v-icon>
            <span>&nbsp; {{ $t(`filter`) }}</span>
          </v-btn>
          <v-btn
            v-show="!$vuetify.breakpoint.xs"
            class="mgbtn"
            @click="drawer = !drawer"
          >
            <v-icon left color="green" large> {{ mdiFilter }} </v-icon>
            <span>&nbsp; {{ $t(`filter`) }}</span>
          </v-btn>
          <v-icon
            left
            color="green"
            large
            v-show="$vuetify.breakpoint.xs"
            @click="drawer = !drawer"
          >
            {{ mdiFilter }}
          </v-icon>
          <v-spacer></v-spacer>
          <v-col
            :key="titleKey"
            v-show="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          >
            <v-row
              justify="center"
              v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            >
              <!--  <h1 v-if="$vuetify.breakpoint.lg" class="display-1 mt-10">{{ Title }}</h1>
                 <h1 v-else class="display-1 mt-n7">{{ Title }}</h1>-->

              <h3 v-if="this.$route.query.userId == undefined">
                {{ $t(`${Title}`) }}
              </h3>
              <h3 v-else>{{ Title }}</h3>
            </v-row>

            <!-- <v-row class="mt-n5" justify="center">
                  <h4 v-if="userId.length > 0">{{ userString }}</h4>
                </v-row>
                <v-row justify="center">
                  <h4 v-if="search">{{ searchString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="mainCategory">{{ mainCategoryString }}</h4>
                </v-row>
                <v-row justify="center">
                  <h4 v-if="subCategory">{{ subCategoryString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="title">{{ titleString }}</h4>
                </v-row>
                <br />
                <v-row justify="center">
                  <h4 v-if="suburb">{{ suburbString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="postcode">{{ postcodeString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="category">{{ categoryString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="setCondition">{{ conditionString }}</h4>
                </v-row>
                <v-row justify="center">
                  <h4 v-if="setAdStatus">{{ adStatusString }}</h4>
                </v-row>

                <v-row justify="center">
                  <h4 v-if="state">{{ stateString }}</h4>
                </v-row>-->
          </v-col>
          <v-spacer></v-spacer>
          <SelectCountry
            :key="selectCountryKey"
            :reloadOnChange="false"
            @country-finalized="changeCountry"
          >
          </SelectCountry>
        </v-row>
      </v-toolbar>
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="mb-2" v-if="!isLoading">
          <v-row
            v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            justify="center"
            class="mb-3"
          >
            <!--  <h1 v-if="$vuetify.breakpoint.lg" class="display-1 mt-10">{{ Title }}</h1>
              <h1 v-else class="display-1 mt-n7">{{ Title }}</h1>-->
            <h3 v-if="this.$route.query.userId == undefined" key="titleKey">
              {{ $t(`${Title}`) }}
            </h3>
            <h3 v-if="this.$route.query.userId !== undefined" key="titleKey">
              {{ Title }}
            </h3>
          </v-row>

          <v-row class="mt-n5" justify="center">
            <h4 v-if="userId.length > 0">{{ userString }}</h4>
          </v-row>
          <v-row justify="center">
            <h4 v-if="search">{{ $t(`search`) }} : {{ searchString }}</h4>
          </v-row>

          <v-row justify="center">
            <h4 v-if="mainCategory">
              {{ $t(`mainCategory`) }} : {{ $t(`${mainCategoryString}`) }}
            </h4>
          </v-row>
          <v-row justify="center">
            <!--<h4 v-if="subCategory">{{ subCategoryString }}-->
            <h4 v-if="subCategory">
              {{ $t(`subCategory`) }} : {{ $t(`${subCategoryString}`) }}
            </h4>
          </v-row>

          <v-row justify="center">
            <h4 v-if="title">{{ $t(`title`) }} {{ titleString }}</h4>
          </v-row>
          <br />
          <v-row justify="center">
            <!-- <h4 v-if="suburb">{{ suburbString }}</h4>-->
            <h4 v-if="suburb">{{ $t(`suburb`) }} : {{ suburbString }}</h4>
          </v-row>

          <v-row justify="center">
            <!--  <h4 v-if="postcode">{{ postcodeString }}</h4>-->
            <h4 v-if="postcode">{{ $t(`postCode`) }} : {{ postcodeString }}</h4>
          </v-row>

          <v-row justify="center">
            <h4 v-if="category">{{ $t(`category`) }} : {{ categoryString }}</h4>
          </v-row>

          <v-row justify="center">
            <h4 v-if="setCondition != 'All'">
              {{ $t(`condition`) }} :
              {{ $t(`${conditionString.toLowerCase()}`) }}
            </h4>
          </v-row>
          <v-row justify="center">
            <h4 v-if="setAdStatus != 'All'">
              {{ $t(`status`) }} : {{ adStatusString }}
            </h4>
          </v-row>

          <v-row justify="center">
            <h4 v-if="state">{{ $t(`state`) }} :{{ stateString }}</h4>
          </v-row>
        </v-col>
      </v-row>
      <v-container
        class="mt-n4"
        style="text-align: center"
        fluid
        v-if="$route.path === '/live-ads' && topAds.length > 0"
      >
        <span class="text--center subtitle">{{ $t(`topAdIds`) }}</span>
        <v-row justify="center" no-gutters v-if="topAds.length > 0">
          <v-card
            v-for="ad in topAds"
            :key="ad.id"
            min-width="250px"
            max-width="250px"
            class="ma-1 topAdBorder"
            hover
          >
            <v-card
              @click="
                gotoAdView(ad.id, adType),
                  increaseTopAdClicks(ad.topAdId, ad.type)
              "
              elevation="0"
              color="transparent"
            >
              <AdCell :ad="ad" :adType="adType" :topAd="true"></AdCell>
            </v-card>
            <a
              v-show="
                'website' in ad &&
                ad.website.length > 0 &&
                ad.website !== undefined
              "
              style="text-decoration: none"
              :href="`https://${ad.website}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-card width="250px"> {{ $t(`clickVisit`) }} </v-card>
            </a>
          </v-card>
        </v-row>
      </v-container>
      <v-row justify="center" no-gutters class="pa-1">
        <MyBtn
          v-if="$route.path == ROUTE_MY_ADS || $route.query.myAds === true"
          btnName="publicAds"
          :iconName="mdiDatabaseSearch"
          @btn-clicked="pushRoute(ROUTE_LIVE_ADS, {})"
          class=""
        ></MyBtn>
      </v-row>
      <v-data-iterator
        v-if="!isLoading && ads.length > 0"
        :items="ads"
        :items-per-page.sync="adsPerPage"
        :page.sync="pageIndex"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header> </template>

        <template v-slot:default="props">
          <v-row justify="center" no-gutters>
            <v-card
              v-show="
                $route.path === '/live-ads' ? !topAdIds.includes(ad.id) : true
              "
              v-for="ad in props.items"
              :key="ad.id"
              min-width="250px"
              max-width="250px"
              class="ma-1 cellBorder"
              hover
            >
              <AdCell :ad="ad" :adType="adType"></AdCell>
            </v-card>
          </v-row>
        </template>
        <template v-slot:footer v-if="ads.length > 0">
          <v-col class="pa-5">
            <v-row class="" align="center" justify="center"
              ><span class="grey--text"
                >{{ totalAds }} {{ $t(`ads`) }} {{ $t(`available`) }}.
                {{ $t(`number`) }} {{ pageIndex + 1 }} {{ $t(`of`) }}
                {{ lastPageIndex + 1 }}</span
              >
            </v-row>
            <br />
            <v-row justify="center">
              <v-btn
                small
                class="mgPagBtn ma-1"
                :disabled="pageIndex == 0"
                @click="updatePageindex(0)"
              >
                <v-icon>{{ mdiPageFirst }}</v-icon> {{ $t(`first`) }}
              </v-btn>
              <v-btn
                small
                :disabled="pageIndex == 0"
                fab
                @click="updatePageindex(pageIndex - 1)"
                class="mgPagBtn ma-1"
              >
                <v-icon size="35">{{ mdiChevronLeft }}</v-icon>
              </v-btn>

              <v-btn
                class="mgPagBtn ma-1"
                small
                :disabled="pageIndex == lastPageIndex || lastPageIndex < 0"
                fab
                @click="updatePageindex(pageIndex + 1)"
              >
                <v-icon size="35">{{ mdiChevronRight }}</v-icon>
              </v-btn>

              <v-btn
                class="mgPagBtn ma-1"
                small
                :disabled="pageIndex == lastPageIndex || lastPageIndex < 0"
                @click="updatePageindex(lastPageIndex)"
              >
                {{ $t(`last`) }} <v-icon>{{ mdiPageLast }}</v-icon>
              </v-btn>
            </v-row>
            <br />
            <v-row justify="center">
              <v-card elevation="0" color="transparent" width="165">
                <v-text-field
                  :placeholder="$t(`number`)"
                  v-model="typedIndex"
                  class=""
                  color="#612E11"
                  dense
                  outlined
                  type="number"
                  v-on:keyup.enter="searchPage(typedIndex)"
                  background-color="white"
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="typedIndex.length == 0"
                          fab
                          v-on="on"
                          class="mgPagBtn mt-n2"
                          small
                          @click="searchPage(typedIndex)"
                          >{{ $t(`go`) }}</v-btn
                        >
                      </template>
                      <span>{{ $t(`goto`) }}</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-card>
            </v-row>
          </v-col>
        </template>
      </v-data-iterator>
      <v-row class="pt-2" justify="center">
        <v-row v-if="isLoading" justify="center">
          <v-card
            min-width="250px"
            max-width="250px"
            v-for="n in $vuetify.breakpoint.xs ? 5 : 10"
            :key="n"
            class="ma-1"
            outlined
          >
            <SkeletonLoader type="text, image, paragraph"> </SkeletonLoader>
          </v-card>
        </v-row>
        <!-- <v-progress-circular
          color="green"
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>-->
        <span v-else v-show="ads.length === 0"> {{ $t(`noAds`) }}</span>
      </v-row>
    </v-card>
    <v-snackbar
      color="orange"
      timeout="-1"
      v-model="showSnackbar"
      multi-line
      centered
    >
      <span class="black--text" v-html="snackbarMessage"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="closeSnackbar()"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <!-- <Loading :isLoading="isLoading"></Loading> -->
  </v-col>
</template>

<script>
import {
  mdiDatabaseSearch,
  mdiMenu,
  mdiFilter,
  mdiPageFirst,
  mdiPageLast,
  mdiChevronLeft,
  mdiChevronRight,
} from "@mdi/js";

import AdCell from "./AdCell";
//import Loading from "./Loading";
//import MyDropDown from "./MyDropDown";
//import MyCardTitle from "./MyCardTitle";
import Config from "../config.js";
//import MyBtn from "./MyBtn";
import FilterSection from "./FilterSection";
import SelectCountry from "./SelectCountry";
import MyBtn from "./MyBtn";
import SkeletonLoader from "./SkeletonLoader.vue";
// lodash
//import debounce from "lodash/debounce";
//import includes from "lodash/includes";
//import isEmpty from "lodash/isEmpty";

export default {
  name: "AdsListHelper",
  components: {
    AdCell,
    //Loading,
    //MyDropDown,
    //MyCardTitle,
    MyBtn,
    FilterSection,
    SelectCountry,
    SkeletonLoader,
  },
  props: ["adType", "userId"],
  metaInfo() {
    return {
      title:
        this.mainCategory !== undefined && this.subCategory !== undefined
          ? `${this.subCategory} | Mobility Guru`
          : this.mainCategory !== undefined
          ? `${this.mainCategory} | Mobility Guru`
          : "Mobility Guru Ads",
      meta: [
        {
          name: "description",
          content:
            this.mainCategory !== undefined && this.subCategory !== undefined
              ? this.subCategory + " for sale"
              : this.mainCategory !== undefined
              ? "Mobility Guru " + this.mainCategory
              : "Ask for help, help other members or request new website features.",
        },
        {
          name: "keywords",
          content:
            this.mainCategory !== undefined && this.subCategory !== undefined
              ? this.subCategory
              : this.mainCategory !== undefined
              ? this.mainCategory
              : "advertisements, ads, live, public",
        },
      ],
    };
  },
  data: () => ({
    //itemsPerPageArray: [20, 40, 60],

    filterIterator: {},
    sortDesc: false,
    pageIndex: 0,
    adsPerPage: Config.VUE_APP_ADS_PER_PAGE,
    sortBy: "",
    keys: [],
    snackbarMessage: "",
    showSnackbar: false,
    totalAds: 0,
    hasNextPage: false,
    lastPageIndex: 0,
    mdiPageFirst: mdiPageFirst,
    mdiPageLast: mdiPageLast,
    mdiDatabaseSearch: mdiDatabaseSearch,
    mdiMenu: mdiMenu,
    mdiFilter: mdiFilter,
    mdiChevronLeft: mdiChevronLeft,
    mdiChevronRight: mdiChevronRight,
    typedIndex: "",
    Title: "",
    drawer: false,
    subCategory: "",
    mainCategory: "",

    MAIN_CATEGORIES_ENUM: {},
    MAIN_CATEGORIES: [],
    SUB_CATEGORIES_LIST: [],
    AD_TYPES_ENUM: {},
    ROUTE_USER_ADS: "",

    ads: [],
    adsFilesFirst: [],
    suburb: "",
    title: "",
    postcode: "",
    state: "",
    dateToRefreshSuburbDone: false,
    setCondition: "",
    setAdStatus: "",
    //setAdState: "",
    category: "",
    categories: [],
    search: "",

    mainCategoryString: "",
    subCategoryString: "",
    suburbString: "",
    titleString: "",
    postcodeString: "",
    conditionString: "",
    categoryString: "",
    searchString: "",
    userString: "",
    stateString: "",
    adStatusString: "",
    //adStateString: "",
    condition: ["All", "New", "Used"],
    adStatusList: ["All", "Published", "Pending"],

    isLoading: false,
    searchLoading: false,
    hideFilter: true,
    toggle: 0,
    userName: "",
    titleKey: 0,
    changeToCountryByIP: true,
    topAds: [],
    topAdIds: [],
    originalAds: [],
    ROUTE_MY_ADS: "",
    ROUTE_LIVE_ADS: "",
    selectCountryCounter: 0,
    selectCountryKey: "country0",
  }),
  mounted: async function () {
    await this.init();
    await this.getCountryNumOfAdsObject();
    this.selectCountryCounter++;
    this.selectCountryKey = `country${this.selectCountryCounter}`;
  },

  watch: {
    async $route() {
      await this.init();
    },

    /*"$store.state.selectedLanguageCode": async function () {
      await this.init();
    },*/

    /*Detect the changes in Confition toggle button */
    /*toggle: function () {
      this.debug(this.toggle);
      this.updateState(this.toggle);
    },*/
    /* This watch the title text field and if it is empty the field will be removed from the route */
    /* title: debounce(function () {
      if (this.title === "") {
        this.routerModify("title", "", this.adType);
      }
    }, 1000),*/
    /* This watch the suburb text field and if it is empty the field will be removed from the route */
    /*suburb: debounce(function () {
      if (this.suburb === "") {
        this.routerModify("suburb", "", this.adType);
      }
    }, 1000),*/
    /* This watch the postcode text field and if it is empty the field will be removed from the route */
    /*postcode: debounce(function () {
      if (this.postcode === "") {
        this.routerModify("postcode", "", this.adType);
      }
    }, 1000),*/
    /* This watch the postcode text field and if it is empty the field will be removed from the route */
    /*category: debounce(function () {
      if (this.category === "") {
        this.routerModify("category", "", this.adType);
      }
    }, 1000),*/
    /* search: debounce(function () {
      if (this.search !== undefined && screen.width > 963) {
        this.hideLeft();
        //this.routerModify("category", "", this.adType);
      }
    }, 1000),*/
  },

  computed: {
    /* showCondition() {
      let conditionState;
      let removeMainCat = [
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.JOBS],
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.SERVICES],
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.TRAVEL],
      ];
      let ENUMofCat = [
        this.MAIN_CATEGORIES_ENUM.JOBS,
        this.MAIN_CATEGORIES_ENUM.SERVICES,
        this.MAIN_CATEGORIES_ENUM.TRAVEL,
      ];
      if (this.mainCategory !== undefined) {
        let object = includes(removeMainCat, this.mainCategory);
        if (object === false) {
          conditionState = true;
        } else {
          conditionState = false;
        }
      } else if (this.category !== undefined) {
        let conArray = [];
        for (let i = 0; i < removeMainCat.length; i++) {
          let mainCategoryMatch = this.regexAdFilter(
            this.category,
            removeMainCat[i]
          );
          let searchArray = this.SUB_CATEGORIES_LIST[ENUMofCat[i]];
          for (let j = 0; j < searchArray.length; j++) {
            let subCategoryMatch = this.regexAdFilter(
              this.category,
              searchArray[j]
            );
            this.debug(mainCategoryMatch, subCategoryMatch);
            if (mainCategoryMatch !== null || subCategoryMatch !== null) {
              conArray.push(removeMainCat[i]);
            }
          }
        }
        this.debug("Array value:" + conArray);
        conditionState = isEmpty(conArray);
        this.debug("Check array:" + conditionState);
      }
      return conditionState;
    },*/
    /*showClearFilters() {
      let showFilter = false;
      if (
        this.$route.path == Config.ROUTE_LIVE_ADS ||
        this.$route.path == Config.ROUTE_MY_ADS ||
        this.$route.path == Config.ROUTE_USER_ADS
      ) {
        if (isEmpty(this.$route.query) === false) {
          showFilter = true;
        }
        
      }
      return showFilter;
    },*/
  },
  /*created() {
    window.addEventListener("resize", this.showDrawer);
  },
  destroyed() {
    window.removeEventListener("resize", this.showDrawer);
  },*/

  beforeRouteEnter(to, from, next) {
    this.debug(`AdsListHelperbeforeRouteEnter from = ${from}`);
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  methods: {
    async init() {
      this.ROUTE_MY_ADS = Config.ROUTE_MY_ADS;
      this.ROUTE_LIVE_ADS = Config.ROUTE_LIVE_ADS;
      //commented below to add GTE enable/disable according to country
      /*if (
        this.$store.state.country !== this.$store.state.countryByIP &&
        this.changeToCountryByIP === true
      ) {
        this.changeToCountryByIP = false;
        this.$store.commit("set", ["country", this.$store.state.countryByIP]);
        await this.getStates(this.$store.state.country);
      }*/
      /* var x = document.getElementById("left");
      this.debug("fggfsgf" + x.style.display + window.innerWidth);
      if (window.innerWidth >= 963) {
        this.debug("fggfsgf" + x.style.display + window.innerWidth);
        if (x.style.display == "none") {
          x.style.display = "block";
          this.hideFilter = true;
        }
      }*/
      let start = new Date();
      this.isLoading = true;
      this.MAIN_CATEGORIES_ENUM = Config.MAIN_CATEGORIES_ENUM;
      this.MAIN_CATEGORIES = Config.MAIN_CATEGORIES;
      this.SUB_CATEGORIES_LIST = Config.SUB_CATEGORIES_LIST;
      this.AD_TYPES_ENUM = Config.AD_TYPES_ENUM;
      this.ROUTE_USER_ADS = Config.ROUTE_USER_ADS;

      /*for (var i = 0; i < this.MAIN_CATEGORIES.length; i++) {
        this.categories[i] = {
          mainCategories: this.MAIN_CATEGORIES[i],
          subCategories: this.SUB_CATEGORIES_LIST[i],
        };
      }*/
      /* This part is used for the saved URL to get the queries*/
      this.subCategory = this.$route.query.subCategory;
      this.mainCategory = this.$route.query.mainCategory;
      this.title = this.$route.query.title;
      this.suburb = this.$route.query.suburb;
      this.postcode = this.$route.query.postcode;
      this.search = this.$route.query.search;

      this.state = this.$route.query.state;
      /* if (this.state === undefined) {
        this.setAdStatus = "All";
      }*/

      /*if (this.setCondition === undefined || this.setCondition === "") {
        this.setCondition = "All";
      } else */
      this.setCondition = this.$route.query.condition;
      if (this.setCondition === undefined) {
        this.setCondition = "All";
      }
      this.setAdStatus = this.$route.query.adStatus;
      if (this.setAdStatus === undefined) {
        this.setAdStatus = "All";
      }

      this.category = this.$route.query.category;

      /*Prints the queries in the rote */
      this.printJson(this.$route.query, "AdsListHelper query");

      let result;

      this.debug(`prevPath = ${this.$store.state.currentPath}`);
      if (this.$store.state.currentPath !== Config.ROUTE_LIVE_ADS) {
        // Flag sync as false to force refresh whenever user goes to /home
        this.$store.commit("set", ["syncedAds", false]);
        this.$store.commit("set", ["syncedLiveAdIds", false]);
      }

      await this.mainInit();

      let filters = {};
      this.suburbString = "";
      this.titleString = "";
      this.postcodeString = "";
      this.conditionString = "";
      this.categoryString = "";
      this.searchString = "";
      this.userString = "";

      //Hides the AD Filters section by defaukt
      // Put this part inside the below if statement if wanted to minimize only for search in defined
      /* if (screen.width > 963 && this.hideFilter === true) {
        this.hideLeft();
      }*/
      // Filter for search key word
      if (this.search !== undefined) {
        filters["search"] = this.search;
        this.searchString = +this.search;
      }
      // Filter for mainCategory
      if (this.mainCategory !== undefined && this.subCategory == undefined) {
        filters["mainCategory"] = this.mainCategory;
        this.mainCategoryString = this.mainCategory;
      }
      //Filter for  subcategory
      if (this.subCategory !== undefined && this.mainCategory !== undefined) {
        filters["subCategory"] = this.subCategory;
        this.subCategoryString = this.subCategory;
      }
      // Filter for suburb
      if (this.suburb !== undefined) {
        filters["suburb"] = this.suburb;
        this.suburbString = this.suburb;
      }
      // Filter for postcode
      if (this.postcode !== undefined) {
        filters["postcode"] = this.postcode;
        this.postcodeString = this.postcode;
      }
      // Filter for title
      if (this.title !== undefined) {
        filters["title"] = this.title;
        this.titleString = this.title;
      }
      // Filter for condition
      this.debug("dropdown item" + this.setCondition);
      if (this.setCondition !== "All") {
        filters["condition"] = this.setCondition;
        this.conditionString = this.setCondition;
      } else if (this.setCondition == "All") {
        this.setCondition = "All";
      }
      // Filter for category search
      if (this.category !== undefined) {
        filters["category"] = this.category;
        this.categoryString = this.category;
      }
      if (this.userId.length > 0 && this.userId !== undefined) {
        //filters["userId"] = this.userId;
        this.userString = "\nSeller Ads";
      }
      // Filter for condition
      this.debug("dropdown item" + this.setAdStatus);
      if (this.setAdStatus == "Published") {
        filters["published"] = true;
        this.adStatusString = this.setAdStatus;
      } else if (this.setAdStatus == "Pending") {
        filters["published"] = false;
        this.adStatusString = this.setAdStatus;
      } else {
        this.adStatusString = "";
      }

      // Filter for state of country
      if (this.state !== undefined && this.state !== "All") {
        filters["state"] = this.state;
        this.stateString = this.state;
      } else {
        this.stateString = "";
      }
      this.debug("Filter in Adlist helper" + filters);
      this.debug(`currentPath = ${this.$store.state.currentPath}`);
      this.printJson(filters, "Filters updated to main.js");

      if (
        (await this.isLoggedInOIDC()) &&
        this.adType == this.AD_TYPES_ENUM.MY_ADS
      ) {
        this.Title = "My Ads";
        this.$store.commit("set", ["syncedMyAdIds", false]);
        result = await this.refreshMyAdIds(
          this.pageIndex,
          this.adsPerPage,
          {},
          filters,
          undefined
        );
      } else if (
        (await this.isLoggedInOIDC()) &&
        this.adType == this.AD_TYPES_ENUM.USER_ADS
      ) {
        this.Title = "User Ads";
        this.$store.commit("set", ["syncedUserAdIds", false]);
        result = await this.refreshUserAdIds(
          this.pageIndex,
          this.adsPerPage,
          {},
          filters,
          undefined,
          this.userId
        );
      } else if (this.adType == this.AD_TYPES_ENUM.PUBLIC_ADS) {
        this.$store.commit("set", ["syncedLiveAdIds", false]);
        result = await this.refreshLiveAdIds(
          this.pageIndex,
          this.adsPerPage,
          {},
          filters,
          undefined
        );
        this.Title = "Public Ads";
      }
      console.log(`adType = ${this.adType}`);
      this.printJson(result, `This is the results for ads`);

      this.ads = result.ads;
      this.hasNextPage = result.hasNextPage;
      this.totalAds = result.totalAds;
      this.topAds = result.topMainCategoryAds;
      this.topAdIds = result.topMainCategoryAdIds;
      this.$store.commit("set", ["topAdIds", this.topAdIds]);

      this.$store.commit("set", ["pageIndex", this.pageIndex]);
      this.$store.commit("set", ["totalAds", this.totalAds]);

      this.lastPageIndex =
        Math.ceil(parseInt(this.totalAds.toString()) / this.adsPerPage) - 1;
      if (this.$route.query.userId !== undefined && this.ads.length > 0) {
        this.printJson(this.ads[0]["ownerGivenName"], "ads");
        if (this.ads[0]["ownerGivenName"] != undefined) {
          this.userName = this.ads[0]["ownerGivenName"];
        } else {
          this.userName = "User";
        }
        this.titleKey++;
        this.Title = `${this.userName}'s Ads\n`;
      }

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`AdsListHelper load time = ${diffs} s`);

      await this.synchSetTimeout(1);
      this.isLoading = false;
      this.scrollToTop();
      await this.synchSetTimeout(1);

      //translating multiple ads
      /*this.originalAds = this.ads;
      let resTrans = await this.translateMultipleAds(this.originalAds);
      if (resTrans.ok == true) {
        this.ads = resTrans.ads;
      }*/
    },

    /*async updateState(index) {
      this.setCondition = this.condition[index];
      this.debug("check condition" + this.setCondition);
      await this.routerModify("condition", this.setCondition, this.adType);
    },*/

    /*
    async updateAdStatus(index) {
      this.setAdStatus = this.adStatusList[index];
      this.debug("check condition" + this.setAdStatus);
      await this.routerModify("adStatus", this.setAdStatus, this.adType);
    },
    */

    hideLeft() {
      /* This function is used to hide the left side filter section */
      this.debug(this.hideFilter);
      var x = document.getElementById("left");
      if (this.hideFilter === false) {
        x.style.display = "block";
        this.hideFilter = true;
      } else {
        x.style.display = "none";
        this.hideFilter = false;
      }
    },

    /*  async clearFilter(adType) {
      if (adType == this.AD_TYPES_ENUM.MY_ADS) {
        await this.$router.push(Config.ROUTE_MY_ADS);
      } else if (adType == this.AD_TYPES_ENUM.USER_ADS) {
        await this.$router.push(Config.ROUTE_USER_ADS);
      } else if (adType == this.AD_TYPES_ENUM.PUBLIC_ADS) {
        await this.$router.push(Config.ROUTE_LIVE_ADS);
      }
    },*/

    showDrawer() {
      this.debug(window.innerWidth);
      var x = document.getElementById("left");
      // if (window.innerWidth >= 960) {
      if (x.style.display === "none") {
        x.style.display = "block";
        this.hideFilter = true;
        //  }
      }
    },

    async changeCountry() {
      this.isLoading = true;
      if (Object.keys(this.$route.query).length > 0) {
        await this.clearFilter();
      }

      await this.init();
      //this.isLoading = false;
    },

    async updatePageindex(index) {
      this.pageIndex = index;
      this.scrollToTop();
      await this.init();
    },

    async searchPage(index) {
      let searchPageIndex = parseInt(index.toString()) - 1;
      this.debug("search index of page " + searchPageIndex);
      if (searchPageIndex <= this.lastPageIndex && searchPageIndex >= 0) {
        await this.updatePageindex(searchPageIndex);
        this.typedIndex = "";
      } else {
        this.snackbarMessage = "Invalid page number";
        this.showSnackbar = true;
      }
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarMessage = "";
    },
  }, // methods
};
</script>
