<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>rotatingBanner</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            If you want display a <strong>Rotating Banner</strong> with an image
            and a link on the home, Press the
            <strong>My MobilityGuru</strong> button in the menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> like as a
            below.
            <MeSpeak
              text="If you want display a Rotating Banner with an image and a link on the home, Press the My MobilityGuru button in the menu dialog/ Profile like as a below"
            >
            </MeSpeak>
            <br />
            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
          <li>
            After clicking the <strong>My MobilityGuru</strong> button, your
            profile will pop up and you have to Click the
            <a href="https://mobilityguru.org/rotating-banner-setup"
              >Rotating Banner</a
            >
            button.
            <MeSpeak
              text="
            After clicking the My MobilityGuru button, your profile will pop up and you have to Click the Rotating Banner button."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/banner.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="banner"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <li>
            Now you can see the <strong>Rotating Banner</strong> page<MeSpeak
              text="
           Now you can see the Rotating Banner page"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/bannerpay.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="bannerPay"
              />
            </ul>
          </li>
          <li>
            Choose the <strong>Payment Method</strong> you are willing to pay.
            <MeSpeak
              text="Choose the payment method you are willing to pay."
            ></MeSpeak>
          </li>

          <li>
            <strong>Credit/ Debit Card</strong> - If you are willing to pay
            using <strong>Credit/Debit Card</strong>, you can fill the
            form.<MeSpeak
              text="Credit/ Debit Card- If you are willing to pay using Credit/Debit Card, you can fill the form."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/cardPayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="cardPayment"
                />
              </v-row>
            </ul>
          </li>
          <br />
          <li>
            <strong>PayPal</strong> - If you are willing to pay using
            <strong>PayPal</strong>, you can fill the form.<MeSpeak
              text="PayPal- If you are willing to pay using PayPal, you can fill the form."
            ></MeSpeak>

            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/pay2.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="Pay2"
                /> </v-row
              ><br />
              <ul>
                <br />
                <v-row justify="center">
                  <v-img
                    :src="require(`./pics/paypal.png`)"
                    align-center
                    height="650"
                    width="450"
                    contain
                    alt="paypal"
                  />
                </v-row>
              </ul>
            </ul>
            <br /><br />
          </li>
          <br />
          <li>
            <strong>Pay Using Guru Token</strong> - If you are willing to pay
            using <strong>Guru Token</strong>, you can select a
            <strong>wallet and scan the QR code</strong> relevant to
            wallet.<MeSpeak
              text="Pay Using Guru Token- If you are willing to pay using Guru Token, you can select a wallet and scan the QR code relevant to wallet."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/payDepay.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="payDepay"
                />
              </v-row>
            </ul>
          </li>
          <br />

          <ul>
            <v-row justify="center">
              <v-img
                :src="require(`./pics/walletQR.png`)"
                align-center
                height="650"
                width="450"
                contain
                alt="walletQR"
              />
            </v-row>
          </ul>
          <br />
          <v-divider class="ma-5"></v-divider>

          <li>
            After scanning the <strong>QR code</strong> you will see the popup
            for the payment. and also there are show payment values of
            <strong>GURU token</strong> and
            <strong>your currency</strong>.<MeSpeak
              text="After scanning the QR code you will see the popup for the payment. and also there are show payment values of GURU token and your currency."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/changePayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="changePayment"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <br />
          <li>
            Now you can see the <strong>Rotating Banner</strong> page. and you
            can choose your image and type the URL your own website. The system
            is limited to upload four rotating banners. and wait till admin
            aprove the rotating banner.<MeSpeak
              text="
           Now you can see the Rotating Banner page, and you can choose your image and type the URL your own website. The system is limited to upload four rotatong banners. and wait till admin aprove the rotating banner."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/bannerPayment.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="bannerPayment"
              />
            </ul>
            <br />
          </li>
          <br />
          <li>
            Now you can see the <strong>Rotating Banner</strong> in
            Homepage<MeSpeak
              text="
           Now you can see the Rotating Banner in Homepage"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/homebannerPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="homebannerPc"
              />
              <v-img
                :src="require(`./pics/homebannerMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="homebannerMob"
              />
            </ul>
            <br />
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
