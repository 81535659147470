<template>
  <v-row no-gutters justify="end">
    <MyDropdownNew
      :showTrailing="showTrailing"
      :key="countryKey"
      :itemName="$t('selectCountry')"
      :dropDownItem="selectedCountryObj"
      :dropDownItems="$store.state.countries"
      @update-items="updateCountry"
    ></MyDropdownNew>
    <v-progress-circular
      color="green"
      indeterminate
      v-if="isLoading"
    ></v-progress-circular
  ></v-row>
</template>

<script>
import Config from "../config.js";

import MyDropdownNew from "./MyDropdownNew";

export default {
  name: "SelectCountry",
  props: ["reloadOnChange"],
  components: {
    MyDropdownNew,
  },
  // props: [],
  data: () => ({
    isLoading: false,
    countryKey: 0,
    selectedCountryObj: {
      title: "",
      leading: "",
    },
    showTrailing: false,
  }),

  mounted: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.debug(`country = ${this.$store.state.country}`);
      if (this.$store.state.countries.length === 0) {
        this.isLoading = true;
        await this.getCountries();
        this.debug("Countries loaded from backend");
        this.isLoading = false;
      } else {
        this.debug("countries loaded already");
      }
      for (let i = 0; i < this.$store.state.countries.length; i++) {
        if (this.$store.state.countries[i].title == this.$store.state.country) {
          this.selectedCountryObj = this.$store.state.countries[i];
          break;
        }
      }

      if (
        this.$route.path == Config.ROUTE_PROFILE ||
        this.$route.path == Config.ROUTE_SUBMIT_AD
      ) {
        this.showTrailing = false;
      } else {
        this.showTrailing = true;
      }
    },

    async updateCountry(index) {
      this.isLoading = true;
      if (
        this.$route.path === Config.ROUTE_PROFILE &&
        this.$store.state.countries[index].title === "All"
      ) {
        this.debug("Can not select All");
      } else {
        let country = this.$store.state.countries[index].title;
        this.selectedCountryObj = this.$store.state.countries[index];
        this.debug("check condition" + country);
        this.$store.commit("set", ["country", country]);

        let result = await this.getStates(this.$store.state.country);

        //location.reload(true);
        if (
          result.ok == true &&
          // result.reload == true &&
          this.reloadOnChange == true
        ) {
          location.reload();
        }
        this.$emit("country-finalized");
        //await this.routerModify("condition", this.setCondition, this.adType);
      }
      this.countryKey++;
      this.isLoading = false;
    },
  },
};
</script>
