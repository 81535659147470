<template>
  <v-col>
    <v-row justify="center">
      <h1 class="display-1">{{ $t(`wallet.my`) }}</h1>
    </v-row>
    <br />
    <v-row justify="center">
      <span>{{ $t(`wallet.bonus`) }} {{ $store.state.bonus }}</span>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Welcome",

  data: () => ({}),
  mounted: function () {
    this.init();
  },

  methods: {
    async init() {},
  },
};
</script>
