<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>deleteAd</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            For deleting your Ad please go to <strong>My Ads</strong> as
            instructed above in <strong>Edit Ad</strong> and select the ad you
            want to delete.
            <MeSpeak
              text="For deleting your Ad please go to My Ads as
            instructed above in Edit Ad and select the ad you
            want to delete. "
            ></MeSpeak>
          </li>
          <li>
            Then click the <strong>DELETE AD</strong> and confirm again before
            deleting the Ad.<MeSpeak
              text="Then click the DELETE AD button and confirm again before
            deleting the Ad."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/deletePc0.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Delete Ad"
              />
              <v-img
                :src="require(`./pics/deletePhone0.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Delete Ad"
              />
            </ul>
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "deleteAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
