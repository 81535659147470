<template>
  <v-card class="mx-auto" max-width="900">
    <v-card-title>
      <span v-if="newInvoice">{{ $t(`new`) }}</span>

      <span v-else>{{ $t(`edit_invoice`) }}</span>
      <v-spacer></v-spacer>
      <v-progress-circular
        indeterminate
        v-show="isLoading"
      ></v-progress-circular>
    </v-card-title>
    <v-card-text v-show="!isLoading">
      <seller-and-buyer-info
        :buyer="buyer"
        :seller="seller"
      ></seller-and-buyer-info>

      <v-col cols="12" lg="6" md="6" sm="6" class="pa-0">
        <h3>{{ $t(`adDetails`) }}</h3>
        <h4 class="mb-5">
          {{ $t(`title`) }}: <a @click="gotoAdView(ad.id, 1)"> {{ title }} </a>
        </h4>

        <v-text-field
          type="number"
          outlined
          disabled
          class="black--text"
          @input="calculateTotal"
          :label="$t('price')"
          dense
          v-model="price"
          :prefix="currencyCode"
        ></v-text-field>
        <v-text-field
          type="number"
          outlined
          class=""
          @input="calculateTotal"
          :label="$t('discount')"
          dense
          v-model="discount"
          :prefix="currencyCode"
        ></v-text-field>

        <MyDropDown
          class="mb-3"
          :itemName="$t('freight_charge')"
          :dropDownItem="selectedChargeType"
          :dropDownItems="freightChargeTypes"
          @update-items="updateChargeType"
        ></MyDropDown>

        <v-text-field
          v-show="selectedChargeType.length > 0"
          type="number"
          outlined
          class="mt-1"
          @input="calculateTotal"
          :label="`Freight Charge (${selectedChargeType})`"
          dense
          :placeholder="$t(`optional`)"
          v-model="freightCharge"
          :prefix="currencyCode"
        ></v-text-field>

        <v-text-field
          type="number"
          outlined
          class=""
          @input="calculateTotal"
          :label="$t(`gst_tax`)"
          :placeholder="$t(`opt`)"
          dense
          v-model="GstTax"
          :prefix="currencyCode"
        ></v-text-field>

        <v-text-field
          class="black--text"
          type="number"
          disabled
          outlined
          @input="calculateTotal"
          :label="$t(`grand_total`)"
          dense
          v-model="finalPrice"
          :prefix="currencyCode"
        ></v-text-field>
        <div :key="paymentDetailKey">
          <h3>{{ $t(`paymentDetails`) }}</h3>
          <span class="caption red--text">{{ $t(`wallet_warning`) }}</span>
          <v-text-field
            class="mt-2"
            outlined
            :label="$t(`wallet_address`)"
            dense
            v-model="walletAddress"
          ></v-text-field>

          <v-row no-gutters class="">
            <span>{{ $t(`price`) }} ({{ currencyCode }})</span>
            <v-spacer></v-spacer>
            {{ parseFloat(price).toFixed(2) }}
          </v-row>

          <v-row no-gutters>
            <span>{{ $t(`discount`) }} ({{ currencyCode }})</span>
            <v-spacer></v-spacer>
            {{
              discount.toString().length > 0
                ? parseFloat(discount).toFixed(2)
                : "0.00"
            }}
          </v-row>

          <v-row no-gutters v-show="selectedChargeType.toString().length > 0">
            <span
              >{{ $t(`freight_charge`) }} ({{ selectedChargeType }}) ({{
                currencyCode
              }})</span
            >
            <v-spacer></v-spacer>
            {{
              freightCharge.toString().length > 0
                ? parseFloat(freightCharge).toFixed(2)
                : "0.00"
            }}
          </v-row>

          <v-row no-gutters v-show="GstTax.toString().length > 0">
            <span>{{ $t(`gst_tax`) }} ({{ currencyCode }})</span>
            <v-spacer></v-spacer>
            {{
              GstTax.toString().length > 0
                ? parseFloat(GstTax).toFixed(2)
                : "0.00"
            }}
          </v-row>

          <v-row no-gutters class="subtitle-2">
            <span>{{ $t(`grand_total`) }} ({{ currencyCode }})</span>
            <v-spacer></v-spacer>
            {{
              finalPrice.toString().length > 0
                ? parseFloat(finalPrice).toFixed(2)
                : "0.00"
            }}
          </v-row>
          <br />
        </div>
        <v-row justify="center" no-gutters>
          <MyBtn
            v-if="newInvoice"
            :iconName="mdiContentSave"
            btnName="save_invoice"
            @btn-clicked="saveInvoice()"
          ></MyBtn>
          <MyBtn
            v-else
            :iconName="mdiContentSave"
            btnName="update_invoice"
            @btn-clicked="updateInvoiceLocal()"
          ></MyBtn>
        </v-row>
        <br />
        <v-row
          justify="center"
          no-gutters
          v-show="$store.state.isSuperAdmin == true"
        >
          <MyBtn
            :iconName="mdiDeleteOutline"
            btnName="delete_invoice"
            @btn-clicked="toggleDeleteYesNo()"
            iconColor="red"
          ></MyBtn>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-text class="text-center" v-show="isLoading">
      <span>
        {{ loadingMessage }}
      </span>
    </v-card-text>
    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>

    <MyYesNo
      :key="yesNoKey"
      :showYesNo="showYesNoDeleteInvoice"
      :title="$t('delete_invoice')"
      :message="$t('invoice_delete_msg')"
      @yes-clicked="deleteInvoiceLocal()"
      @no-clicked="toggleDeleteYesNo()"
    ></MyYesNo>
  </v-card>
</template>

<script>
import { mdiContentSave, mdiDeleteOutline } from "@mdi/js";

import Config from "../config.js";
import MyBtn from "./MyBtn";
import MyAcknowledge from "./MyAcknowledge.vue";
import SellerAndBuyerInfo from "./SellerAndBuyerInfo.vue";
import MyDropDown from "./MyDropDown";
import MyYesNo from "./MyYesNo";

export default {
  name: "SubmitInvoiceHelper",
  components: { MyBtn, MyAcknowledge, SellerAndBuyerInfo, MyDropDown, MyYesNo },
  props: ["invoiceId"],

  metaInfo: {
    title: "Invoice | Mobility Guru",
    meta: [
      {
        name: "description",
        content: "GURU token invoice",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  computed: {},

  data: () => ({
    mdiContentSave: mdiContentSave,
    mdiDeleteOutline: mdiDeleteOutline,
    newInvoice: false,
    adId: "",
    buyerId: "",
    isLoading: false,
    loadingMessage: "",
    ad: {},
    buyer: {},
    seller: {},
    price: "",
    discount: "",
    finalPrice: "",
    currencyCode: "",
    title: "",
    invoice: {},
    paymentDetailKey: "payment0",
    paymentDetailIndex: 0,

    showAcknowledge: false,
    acknowledgeMessage: "",
    acknowledgeTitle: "Error",
    acknowledgeOk: false,
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    walletAddress: "",
    selectedChargeType: "",
    freightChargeTypes: ["Domestic", "International"],
    freightCharge: "",
    GstTax: "",
    showYesNoDeleteInvoice: false,
    yesNoIndex: 0,
    yesNoKey: "yes0",
  }),

  validations: {},

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.loadingMessage = `Loading invoice data`;
      if (this.invoiceId == null || this.invoiceId == undefined) {
        //new invoice
        this.newInvoice = true;
        if ("adId" in this.$route.query && "buyerId" in this.$route.query) {
          //fetch the details of ad and user(buyer) - adId, buyerId
          this.adId = this.$route.query.adId;
          this.buyerId = this.$route.query.buyerId;
          let result = await this.getNewInvoiceData(this.adId, this.buyerId);
          this.printJson(result);
          if (result.ok == true) {
            //
            if (result.edit == false) {
              this.ad = result.ad;
              this.buyer = result.buyer;
              this.seller = result.seller;

              if ("currencyCode" in this.ad) {
                this.currencyCode = this.ad.currencyCode;
              }

              if ("price" in this.ad) {
                this.price = this.ad.price;
              }

              if ("title" in this.ad) {
                this.title = this.ad.title;
              }
            } else {
              //edit true
              this.newInvoice = false;
              this.invoice = result.invoice;

              this.retreiveDataFromInvoice();
            }
          } else {
            //show alert of error
          }
        } else {
          //show alert of errror
        }
      } else {
        //fetch and edit invoice
        this.newInvoice = false;
        let result = await this.getInvoiceById(this.invoiceId);
        this.printJson(result);
        if (result.ok == true) {
          this.invoice = result.invoice;
          this.retreiveDataFromInvoice();
        } else {
          //error
        }
      }
      this.loadingMessage = "";
      this.isLoading = false;
    },

    retreiveDataFromInvoice() {
      this.ad = this.invoice.ad;
      this.buyer = this.invoice.buyer;
      this.seller = this.invoice.seller;

      if ("currencyCode" in this.ad) {
        this.currencyCode = this.ad.currencyCode;
      }

      if ("price" in this.ad) {
        this.price = this.ad.price;
      }

      if ("title" in this.ad) {
        this.title = this.ad.title;
      }
      if ("discount" in this.invoice) {
        this.discount = this.invoice.discount;
      }
      if ("finalPrice" in this.invoice) {
        this.finalPrice = this.invoice.finalPrice;
      }

      if ("walletAddress" in this.invoice) {
        this.walletAddress = this.invoice.walletAddress;
      }

      if ("freightChargeType" in this.invoice) {
        this.selectedChargeType = this.invoice.freightChargeType;
      }

      if ("freightCharge" in this.invoice) {
        this.freightCharge = this.invoice.freightCharge;
      }
      if ("gstTax" in this.invoice) {
        this.GstTax = this.invoice.gstTax;
      }
    },

    calculateTotal() {
      if (this.discount.length > 0 && this.price.length > 0) {
        this.finalPrice = Number(this.price) - Number(this.discount);
      } else {
        this.finalPrice = this.price;
      }

      if (
        this.selectedChargeType.toString().length > 0 &&
        this.freightCharge.toString().length > 0
      ) {
        this.finalPrice = Number(this.finalPrice) + Number(this.freightCharge);
      }

      if (this.GstTax.toString().length > 0) {
        this.finalPrice = Number(this.finalPrice) + Number(this.GstTax);
      }
      //this.paymentDetailIndex++;
      //this.paymentDetailKey = `payment${this.paymentDetailIndex}`
    },
    validate() {
      let ok = true;
      if (this.currencyCode.length == 0) {
        ok = false;
        this.setAcknowledgeError("Invalid currency code");
      }
      if (ok == true) {
        if (this.price.length == 0) {
          ok = false;
          this.setAcknowledgeError("Price can not be empty");
        }
      }

      if (ok == true) {
        if (Number(this.price) < 0) {
          ok = false;
          this.setAcknowledgeError("Invalid price amount");
        }
      }

      if (ok == true && this.discount.toString().length > 0) {
        if (Number(this.discount) < 0) {
          ok = false;
          this.setAcknowledgeError("Invalid discount amount");
        }
      }

      if (ok == true && this.finalPrice.toString().length > 0) {
        if (Number(this.finalPrice) < 0) {
          ok = false;
          this.setAcknowledgeError("Invalid final total amount");
        }
      }

      if (ok == true) {
        if (
          this.selectedChargeType.length > 0 &&
          this.freightCharge.toString().length > 0
        ) {
          if (Number(this.freightCharge) < 0) {
            ok = false;
            this.setAcknowledgeError("Invalid freight charge amount");
          }
        }
      }

      if (ok == true) {
        if (this.GstTax.toString().length > 0) {
          if (Number(this.GstTax) < 0) {
            ok = false;
            this.setAcknowledgeError("Invalid GST tax amount");
          }
        }
      }

      if (ok == true) {
        if (this.walletAddress.length == 0) {
          ok = false;
          this.setAcknowledgeError("Wallet address can not be empty");
        }
      }
      return ok;
    },

    async saveInvoice() {
      this.debug(`create invoice`);
      this.isLoading = true;
      this.loadingMessage = `Creating invoice, Please wait until generating invoice number. This may take a while`;
      let ok = this.validate();
      if (ok == true) {
        let invoice = {
          adId: this.adId,
          sellerId: this.seller.id,
          buyerId: this.buyer.id,
          currencyCode: this.currencyCode,
          price: this.price,
          discount: this.discount,
          finalPrice: this.finalPrice,
          walletAddress: this.walletAddress,
          freightChargeType: this.selectedChargeType,
          freightCharge: this.freightCharge,
          gstTax: this.GstTax,
        };
        /*if (this.discount.length == 0) {
          invoice["discount"] = 0.0;
        }*/
        this.debug(invoice);

        let result = await this.createInvoice(invoice);
        if (result.ok == true) {
          let query = {
            id: result.invoiceId,
          };
          await this.pushRoute(Config.ROUTE_INVOICE, query);
        } else {
          this.setAcknowledgeError(result.message);
        }
      }
      this.loadingMessage = "";
      this.isLoading = false;
    },

    closeAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.acknowledgeOk = false;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.acknowledgeOk = true;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    //TODO: check for PAYMENT status before update invoices
    async updateInvoiceLocal() {
      this.debug(`update invoice`);
      this.loadingMessage = `Updating invoice data`;
      this.isLoading = true;
      let ok = this.validate();
      if (ok == true) {
        let invoice = {
          id: this.invoice.id,
          sellerId: this.invoice.sellerId,
          buyerId: this.invoice.buyerId,
          currencyCode: this.currencyCode,
          price: this.price,
          discount: this.discount,
          finalPrice: this.finalPrice,
          walletAddress: this.walletAddress,
          freightChargeType: this.selectedChargeType,
          freightCharge: this.freightCharge,
          gstTax: this.GstTax,
        };
        this.debug(invoice);

        let result = await this.updateInvoice(invoice, false, false);
        if (result.ok == true) {
          let query = {
            id: this.invoice.id,
          };
          await this.pushRoute(Config.ROUTE_INVOICE, query);
        } else {
          this.setAcknowledgeError(result.message);
        }
      }
      this.loadingMessage = "";
      this.isLoading = false;
    },

    updateChargeType(index) {
      this.debug(index);
      this.selectedChargeType = this.freightChargeTypes[index];
      this.freightCharge = "";
    },

    toggleDeleteYesNo() {
      this.showYesNoDeleteInvoice = !this.showYesNoDeleteInvoice;
      this.yesNoIndex++;
      this.yesNoKey = `yesNo${this.yesNoIndex}`;
    },

    async deleteInvoiceLocal() {
      this.toggleDeleteYesNo();
      this.isLoading = true;
      let result = await this.deleteInvoice(this.invoice.id);

      this.isLoading = false;
      if (result.ok == true) {
        let query = {
          action: `invoices`,
        };
        await this.pushRoute(Config.ROUTE_SUPERADMIN_DASHBOARD, query);
      } else {
        this.setAcknowledgeError(result.message);
      }
    },
  }, // methods
};
</script>
