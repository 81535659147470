<template>
  <v-sheet
    :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
    class="pa-3"
  >
    <v-skeleton-loader class="mx-auto" :type="type"></v-skeleton-loader>
  </v-sheet>
</template>
<script>
export default {
  name: "SkeletonLoader",
  props: ["type"],
  // Vuetify components provide
  // a theme variable that is
  // used to determine dark
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
};
</script>
