<template>
  <SubmitAdHelper> </SubmitAdHelper>
</template>

<script>
//  minLength,

import SubmitAdHelper from "./SubmitAdHelper";

export default {
  name: "SubmitAd",
  components: {
    SubmitAdHelper,
  },
  props: [],

  //  computed: () => ({
  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({}),

  validations: {},

  mounted: async function () {},

  methods: {}, // methods
};
</script>
