<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <p>
          For the first time users you will recieve a
          <strong>verification code</strong> to your email when your browser
          window redirects to your profile.<br />
          Please check your mail inbox or spam mail for the code.
          <MeSpeak
            text="
          For the first time users you will recieve a verification code to your email when your browser
          window redirects to your profile.
          Please check your mail inbox or spam mail for the code."
          ></MeSpeak>
        </p>

        <v-img
          :src="require(`./pics/verifyPc0.png`)"
          class="ma-3 hidden-xs-only"
          contain
          alt="Verify Email"
        />
        <v-img
          :src="require(`./pics/verifyPhone0.png`)"
          class="mr-5 mt-5 hidden-sm-and-up"
          contain
          alt="Verify Email"
        />
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>

<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "verifyEmail",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru FAQ",
      },
      {
        name: "keywords",
        content: "Sign Up",
      },
    ],
  },
};
</script>
