<template>
  <v-row justify="center" v-if="insufficientPermission" no-gutters>
    <span class="caption">{{ $t(`permission`) }}</span>
  </v-row>

  <v-card class="mx-auto" max-width="900" v-else>
    <v-card-title>
      <span v-if="newTopic">{{ $t(`newArticle`) }}</span>
      <span v-else>{{ $t(`editArticle`) }}</span>
    </v-card-title>

    <div v-if="$store.state.isLoggedIn">
      <v-container>
        <MyDropDown
          :itemName="$t('category')"
          :dropDownItem="category"
          :dropDownItems="FORUM_CATEGORIES"
          :disabled="!newTopic"
          @update-items="updateCategory"
          ref="category"
        ></MyDropDown>

        <br />
        <b>{{ $t(`title`) }}</b>
        <v-text-field
          @keydown.space="preventLeadingSpace"
          :label="$t('title')"
          v-model="title"
          background-color="fillColour"
          counter="120"
          :rules="[rules.required, rules.title120]"
          clearable
          filled
          outlined
          solo
        />
        <br />
        <b>{{ $t(`description`) }}</b>

        <!--<quill
          v-if="articleDescription"
          v-model="articleDescription"
          output="html"
        />
        :options="quillConfig"
          
        -->
        <quill-editor
          ref="quillEditor"
          class="editor"
          v-model="articleDescription"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        />
        <br />

        <v-row align="center" justify="center" v-if="newTopic">
          <MyBtn
            btnName="saveArticle"
            @btn-clicked="saveTopic()"
            :iconName="mdiContentSave"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>

        <v-row align="center" justify="center" v-if="!newTopic" no-gutters>
          <MyBtn
            btnName="saveChanges"
            :disabled="isLoading || showSaveChanges"
            :iconName="mdiContentSave"
            @btn-clicked="saveChanges()"
          ></MyBtn>
        </v-row>

        <br />
        <v-row align="center" justify="center" v-if="!newTopic" no-gutters>
          <MyBtn
            btnName="deleteArticle"
            @btn-clicked="showDeleteArticle"
            :iconName="mdiTrashCanOutline"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>

        <br />
      </v-container>
    </div>

    <Loading :isLoading="isLoading" :message="messageLoader"></Loading>
    <v-dialog v-model="showDelConfirm">
      <v-card>
        <v-layout justify-center>
          <v-btn @click="showDelConfirm = false" class="bg fg--text">
            <v-icon left large color="green">
              {{ mdiArrowLeftBoldOutline }}
            </v-icon>
            &nbsp; {{ $t(`no`) }}
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn @click="DeleteArticle()" class="bg fg--text">
            <v-icon left large color="red"> {{ mdiTrashCanOutline }} </v-icon>
            &nbsp; {{ $t(`yes`) }}
          </v-btn>
        </v-layout>
        <br />
      </v-card>
    </v-dialog>
    <MyAcknowledge
      :key="acknowledgeKey"
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeDescription"
      @btn-clicked="closeAcknowledge"
    ></MyAcknowledge>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "quill-vuejs";

import {
  mdiSelect,
  mdiContentSave,
  mdiAccountCircle,
  mdiTransferLeft,
  mdiTrashCanOutline,
  mdiArrowLeftBoldOutline,
} from "@mdi/js";

import Config from "../config.js";
import MyBtn from "./MyBtn";
import MyDropDown from "./MyDropDown";
import Loading from "./Loading";
import MyAcknowledge from "./MyAcknowledge";

export default {
  name: "SubmitArticle",
  components: {
    Loading,
    MyDropDown,
    MyBtn,
    MyAcknowledge,
    quillEditor,
  },
  props: ["articleId"],

  data: () => ({
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiContentSave: mdiContentSave,
    mdiSelect: mdiSelect,
    mdiAccountCircle: mdiAccountCircle,
    mdiTransferLeft: mdiTransferLeft,
    mdiTrashCanOutline: mdiTrashCanOutline,
    checkYes: false,
    ArticleView: Config.ROUTE_ARTICLE_VIEW,
    Articles: Config.ROUTE_ARTICLES,
    newTopic: false,
    isLoading: false,
    disabled: false,

    category: "",
    FORUM_CATEGORIES: null,
    articleDescription: "",

    messageLoader: "",
    currentYear: 0,
    accessTokenDecoded: {},
    showAcknowledge: false,
    acknowledgeDescription: "",
    acknowledgeTitle: "Error",
    message: "",
    title: "",
    collectionArticles: null,
    order: "",

    articleType: "",
    articleOrig: {},
    check: "",
    length: "",
    showDelConfirm: false,
    isSuperAdmin: false,
    isArticleModerator: false,

    quillConfig: {
      //debug: 'info',
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["image", "code-block", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],

          // outdent/indent
          [{ indent: "-1" }, { indent: "+1" }],

          // dropdown with defaults from theme
          [{ align: [] }],
        ],
      },
      //readOnly: false,
      placeholder: "Compose an epic...",
      theme: "snow",
    },
    //content: "<h2>I am Example</h2>",
    rules: {
      required: (value) => value.length > 0 || "Required.",
      title120: (value) =>
        value.length <= Config.EMAIL_SUBJECT_MAX_LENGTH ||
        "Max " + Config.EMAIL_SUBJECT_MAX_LENGTH + " characters",
    },
    acknowledgeKey: "acknowledge0",
    acknowledgeCounter: 0,
    insufficientPermission: false,
  }),
  validations: {},

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      if (
        this.$store.state.isSuperAdmin === true ||
        this.$store.state.isForumModerator == true
      ) {
        //call other init functions if there any by declaring it inside methods{}
        await this.init();
      } else {
        this.insufficientPermission = true;
      }
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }

    console.log("this is current quill instance object", this.editor);
  },

  computed: {
    showSaveChanges() {
      let disableEditBtn = true;
      if (this.articleId !== undefined) {
        if (
          this.checkTitle !== this.title ||
          this.checkmessage !== this.articleDescription
        ) {
          disableEditBtn = false;
        }

        this.debug(`showEdit = ${disableEditBtn}`);
      }
      return disableEditBtn;
    },

    // ArticlesButton() {
    //   let MyTopics = this.$store.state.isLoggedIn;
    //   this.debug(`MyArticles = ${MyTopics}`);

    //   return MyTopics;
    // },

    editor() {
      return this.$refs.quillEditor.quill;
    },
  },

  methods: {
    updateCategory(index) {
      this.debug("updateMainCategory()");
      this.category = this.FORUM_CATEGORIES[index];
    },

    // selectionChange(editor, range) {
    //     if (range) {
    //         if (range.start !== range.end) {
    //             this.selectedText = editor.getText(range.start, range.end)
    //             editor.formatText(range, 'custom', 'hello world')
    //         }
    //     }
    // },

    async saveTopic() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";
        let ok = this.checkInputs();
        let order = 0;
        this.debug("Length of the Article ", order);

        if (ok) {
          this.messageLoader += "ok<br/>";
          try {
            let result = await this.createNewArticle(
              this.title,
              this.category,
              this.articleDescription
            );
            this.printJson(result, "recordHttp createRecord");
            if (result.ok == true) {
              let articleId = result.articleId;
              this.debug(`articleId = ${articleId}`);
              this.goToArticleView(articleId);
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async DeleteArticle() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.debug("Delete Article-" + this.articleId);
        let res = await this.deleteArticle(this.articleId);
        this.debug("Article Deleted");
        if (res.ok == true) {
          this.goToArticles(this.accessTokenDecoded.sub);
        }
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async saveChanges() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        let ok = this.checkInputs();
        if (ok == true) {
          this.messageLoader = "";
          await this.articleChanges();
        }
        await this.refreshArticle();
        this.isLoading = false;
        await this.$router.push({
          path: Config.ROUTE_ARTICLES,
          query: {
            articleId: this.articleId,
          },
        });
      }
    },

    async init() {
      this.isLoading = true;
      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.debug("Super Admin", this.isSuperAdmin);
      this.isForumModerator = this.$store.state.isForumModerator;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.printJson(this.accessTokenDecoded, "Collection Articles ");

      await this.refreshArticle();
      this.isLoading = false;
    },

    async articleChanges() {
      this.message = "";
      this.showErrorDescription = false;
      let record = {
        id: this.articleId,
      };
      let changed = false;
      if (this.articleOrig.title != this.title) {
        record["title"] = this.title;
        changed = true;
      }
      if (this.articleOrig.articleDescription != this.articleDescription) {
        record["articleDescription"] = this.articleDescription;
        changed = true;
      }

      if (changed == true) {
        this.messageLoader += "updating article...";

        this.printJson(record, "patch record");
        try {
          let res = await this.updateArticle(record);
          if (res.ok == true) {
            this.messageLoader += "ok<br/>";
          } else {
            this.messageLoader += "error in update<br/>";
            this.showErrorDescription = true;
            this.message = res.message;
          }
        } catch (e) {
          this.message = e;
          this.showErrorDescription = true;
          this.debug(e);
          this.messageLoader += "failed<br/>";
        }
      }
    },

    async goToArticleView(articleId) {
      this.$router.push({
        path: Config.ROUTE_ARTICLE_VIEW,
        query: {
          articleId: articleId,
        },
      });
    },

    async refreshArticle() {
      this.FORUM_CATEGORIES = Config.FORUM_CATEGORIES;
      if (this.articleId !== undefined) {
        let res = await this.getArticleById(this.articleId);
        this.articleOrig = res.article;
        this.printJson(this.articleOrig, "Article Orig");
        this.newTopic = false;
        this.category = this.articleOrig.category;
        this.title = this.articleOrig.title;
        this.articleDescription = this.articleOrig.articleDescription;
      } else {
        this.newTopic = true;
      }
    },

    checkInputs() {
      let ok = true;
      let message = "";

      if (ok == true) {
        if (this.category === "") {
          message = "Please select a Category";
          this.$refs.category.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          ok = false;
        }
      }

      if (ok == true) {
        if (this.title.length == 0) {
          message = "Please enter a Title.";
          this.setAcknowledgeError(message);
          ok = false;
        } else if (this.title.length > Config.EMAIL_SUBJECT_MAX_LENGTH) {
          this.setAcknowledgeError(
            "Message: Please do not exceed " +
              Config.EMAIL_SUBJECT_MAX_LENGTH +
              " characters."
          );
          ok = false;
        }
      }

      if (ok == true) {
        if (this.articleDescription.length == 0) {
          message = "Please enter a Description.";
          this.setAcknowledgeError(message);
          ok = false;
        } else if (this.articleDescription.length > Config.ARTICLE_DESC_MAX) {
          this.setAcknowledgeError(
            "Message: Please do not exceed " +
              Config.ARTICLE_DESC_MAX +
              " characters."
          );
          ok = false;
        }
      }

      return ok;
    },

    showDeleteArticle() {
      this.showDelConfirm = true;
    },

    setAcknowledgeError(message) {
      this.acknowledgeDescription = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    setAcknowledge(message, title) {
      this.acknowledgeDescription = message;
      this.acknowledgeTitle = title;
      this.showAcknowledge = true;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    closeAcknowledge() {
      this.showAcknowledge = false;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    showLoading() {
      this.loadingIndex++;
      this.loadingKey = `loadingKey${this.loadingIndex}`;
      this.isLoading = true;
    },

    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.articleDescription = html;
    },
  }, // methods
};
</script>
