<template>
  <DashboardHelper :roleType="roleType"> </DashboardHelper>
</template>

<script>
import DashboardHelper from "./DashboardHelper";
import Config from "../config.js";

export default {
  name: "SuperAdminDashboard",
  components: {
    DashboardHelper,
  },
  metaInfo: {
    title: "Mobilility Guru Super Admin",
    //titleTemplate: "%s | Public Ads",
    meta: [
      { name: "description", content: "Mobility Guru SuperAdmin Dashboard" },
      { name: "keywords", content: "admin" },
    ],
  },
  data: () => ({
    roleType: "",
  }),

  mounted: async function () {
    this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    init() {
      if (this.$store.state.isSuperAdmin === true) {
        this.roleType = Config.ROLE_TYPE_ENUM.SUPERADMIN;
      }
    },
  }, // methods
};
</script>
