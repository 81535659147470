<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            After posting an advertisement (Ad), if want it to promote as a
            <strong>Top Ad</strong> Press the
            <strong>My MobilityGuru</strong> button in the menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> like as a
            below.
            <MeSpeak
              text="After posting an advertisement (Ad), if want it to promote as a top ad, Press the My MobilityGuru button in the menu dialog/ Profile like as a below"
            >
            </MeSpeak>
          </li>
          <br />

          <li>
            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
          <li>
            After clicking the <strong>My MobilityGuru</strong> button, your
            profile will pop up and you have to Click the
            <a href="https://mobilityguru.org/my-ads">View My Ads</a> button.
            <MeSpeak
              text="
            After clicking the My MobilityGuru button, your profile will pop up and you have to Click the View My Ads button."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/profile.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="profile"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <li>
            Now you can see the <strong>View My Ads</strong> page<MeSpeak
              text="
           Now you can see the View My Ads page"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/myAds.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="myAds"
              />
              <v-img
                :src="require(`./pics/myadsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="myadsMob"
              />
            </ul>
            <br />
          </li>

          <li>
            Click on the ad you want to promote
            <MeSpeak text="Click on the ad you want to promote"></MeSpeak>
          </li>
          <li>
            After that, you will see your selected related ad details page and
            you will see the <strong>Promote Ad</strong> button also
            there.<MeSpeak
              text="After that, you will see your selected related ad details page and you will see the Promote Ad button also there."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/selected.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="selected"
              />
              <v-img
                :src="require(`./pics/selectedMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="selectedMob"
              />
            </ul>
            <br />
          </li>

          <li>
            If you wish to <strong>Promote</strong> this ad you can press the
            button and will appear the <strong>Promote</strong> options and
            <strong>Payment Methods</strong>. and also you can
            <strong>Promote</strong> your website.<MeSpeak
              text="If you wish to promote this ad you can press the button and will appear the promote options and payment methods. and also you can Promote your website."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/clickToPromote.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="clickToPromote"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <br />
          <li>
            Choose the <strong>Payment Method</strong> you are willing to pay.
            <MeSpeak
              text="Choose the payment method you are willing to pay."
            ></MeSpeak>
          </li>

          <li>
            <strong>Credit/ Debit Card</strong> - If you are willing to pay
            using <strong>Credit/Debit Card</strong>, you can fill the
            form.<MeSpeak
              text="Credit/ Debit Card- If you are willing to pay using Credit/Debit Card, you can fill the form."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/cardPayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="cardPayment"
                />
              </v-row>
            </ul>
          </li>
          <br />
          <li>
            <strong>PayPal</strong> - If you are willing to pay using
            <strong>PayPal</strong>, you can fill the form.<MeSpeak
              text="PayPal- If you are willing to pay using PayPal, you can fill the form."
            ></MeSpeak>

            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/pay2.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="Pay2"
                /> </v-row
              ><br />
              <ul>
                <br />
                <v-row justify="center">
                  <v-img
                    :src="require(`./pics/paypal.png`)"
                    align-center
                    height="650"
                    width="450"
                    contain
                    alt="paypal"
                  />
                </v-row>
              </ul>
            </ul>
            <br /><br />
          </li>
          <br />
          <li>
            <strong>Pay Using Guru Token</strong> - If you are willing to pay
            using <strong>Guru Token</strong>, you can select a
            <strong>wallet and scan the QR code</strong> relevant to
            wallet.<MeSpeak
              text="Pay Using Guru Token- If you are willing to pay using Guru Token, you can select a wallet and scan the QR code relevant to wallet."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/payDepay.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="payDepay"
                />
              </v-row>
            </ul>
          </li>

          <ul>
            <v-row justify="center">
              <v-img
                :src="require(`./pics/walletQR.png`)"
                align-center
                height="650"
                width="450"
                contain
                alt="walletQR"
              />
            </v-row>
          </ul>
          <br />

          <br />

          <li>
            After scanning the <strong>QR code</strong> you will see the popup
            for the payment. and also there are show payment values of
            <strong>GURU token</strong> and
            <strong>your currency</strong>.<MeSpeak
              text="After scanning the QR code you will see the popup for the payment. and also there are show payment values of GURU token and your currency."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/changePayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="changePayment"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <br />
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
