<template>
  <SubmitArticleHelper> </SubmitArticleHelper>
</template>

<script>
//  minLength,

import SubmitArticleHelper from "./SubmitArticleHelper";

export default {
  name: "SubmitArticle",
  components: {
    SubmitArticleHelper,
  },
  props: [],

  //  computed: () => ({
  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({}),

  validations: {},

  mounted: async function () {},

  methods: {}, // methods
};
</script>
