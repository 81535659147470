<template>
  <div>
    <H1 class="text-center">{{ $t(`unsub`) }}</H1>
    <p class="green--text text-center" v-if="unsubscribedOk">{{ okMsg }}</p>

    <p class="red--text text-center" v-if="unsubscribedOk === false">
      {{ failedMsg }}
    </p>
  </div>
</template>

<script>
import Config from "../config.js";

export default {
  name: "Unsubscribe",

  data: () => ({
    unsubscribedOk: true,
    okMsg: "",
    failedMsg: "",
    unsubscribeCode: 0,
  }),

  mounted: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.unsubscribeCode = this.$route.query.code;
      this.debug(`unsubscribeCode = ${this.unsubscribeCode}`);

      let url = `${Config.NODE_SERVER_IP}${Config.CHECK_EMAIL_UNSUBSCRIBE_CODE}?code=${this.unsubscribeCode}`;
      let fetchData = {
        method: "GET",
      };
      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        if (jsonRes.ok === true) {
          this.unsubscribedOk = true;
          this.okMsg = jsonRes.message;
        } else {
          this.unsubscribedOk = false;
          this.failedMsg = jsonRes.message;
        }
      } catch (e) {
        this.unsubscribedOk = false;
        this.failedMsg = e;
      }
    },
  },
};
</script>
