<template>
  <!-- <!DOCTYPE html>-->
  <html xmlns="http://www.w3.org/1999/xhtml" lang xml:lang>
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>MobilityGuru-Privacy-Policy</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <div class="ma-2">
        <!--<div ref="top"></div>-->
        <center>
          <h1>
            <span>PRIVACY POLICY</span>
            <MeSpeak size="40" :text="meSpeakText"></MeSpeak>
          </h1>
        </center>
        <v-row justify="center" v-if="fromRegistration === true">
          <MyBtn
            btnName="Go back to Registration"
            iconName="mdi-transfer-left"
            @btn-clicked="goBackToRegistration(tab_id)"
          ></MyBtn>
        </v-row>

        <!--     <div v-html="content"></div> <div v-if="Object.keys(contentObject).length > 0">
          <div v-for="(i, index) in totalNumbers" :key="index">
            <span
              v-if="'title' in contentObject[i]"
              v-html="`${i}. ${contentObject[i].title}<br>`"
              class="title"
            ></span>
            <div
              class="ml-5"
              v-for="(value, key) in contentObject[i]"
              :key="key"
            >
              <span
                v-if="key !== 'title'"
                v-html="`<b>(${key})</b> ${value}<br><br>`"
              ></span>
            </div>
            <br />
          </div>
        </div>-->
        <p>MobilityGuru Ltd</p>
        <ol type="1">
          <li>
            <p>
              <strong>We respect your privacy</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd respects your right to privacy and is
                  committed to safeguarding the privacy of our customers and
                  website visitors. This policy sets out how we collect and
                  treat your personal information.
                </p>
              </li>
              <li>
                <p>
                  We adhere to the Australian Privacy Principles contained in
                  the <strong><em>Privacy Act</em></strong> 1988 (Cth) and to
                  the extent applicable, the EU General Data Protection
                  Regulation (GDPR).
                </p>
              </li>
              <li>
                <p>
                  “Personal information” is information we hold which is
                  identifiable as being about you. This includes information
                  such as your name, email address, identification number, or
                  any other type of information that can reasonably identify an
                  individual, either directly or indirectly.
                </p>
              </li>
              <li>
                <p>
                  You may contact us in writing at 1st Floor 85 Great Portland
                  St, London, W1W7LT, United Kingdom or email
                  admin@mobilityguru.org for further information about this
                  Privacy Policy.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>What personal information is collected</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd will, from time to time, receive and store
                  personal information you submit to our website, provided to us
                  directly or given to us in other forms.
                </p>
              </li>
              <li>
                <p>
                  You may provide basic information such as your name, phone
                  number, address and email address to enable us to send you
                  information, provide updates and process your product or
                  service order.
                </p>
              </li>
              <li>
                <p>
                  We may collect additional information at other times,
                  including but not limited to, when you provide feedback, when
                  you provide information about your personal or business
                  affairs, change your content or email preference, respond to
                  surveys and/or promotions, provide financial or credit card
                  information, or communicate with our customer support.
                </p>
              </li>
              <li>
                <p>
                  Additionally, we may also collect any other information you
                  provide while interacting with us.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>How we collect your personal information</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd collects personal information from you in a
                  variety of ways, including when you interact with us
                  electronically or in person, when you access our website and
                  when we engage in business activities with you. We may receive
                  personal information from third parties. If we do, we will
                  protect it as set out in this Privacy Policy. set out in this
                  Privacy Policy."
                </p>
              </li>
              <li>
                <p>
                  By providing us with personal information, you consent to the
                  supply of thatinformation subject to the terms of this Privacy
                  Policy.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>How we use your personal information</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd may use personal information collected from
                  you to provide you with information about our products or
                  services. We may also make you aware of new and additional
                  products, services and opportunities available to you.
                </p>
              </li>
              <li>
                <p>
                  MobilityGuru Ltd will use personal information only for the
                  purposes that you consent to. This may include to:
                </p>
                <ul>
                  <li>
                    <p>
                      provide you with products and services during the usual
                      course of our business activities;
                    </p>
                  </li>
                  <li>
                    <p>administer our business activities;</p>
                  </li>
                  <li>
                    <p>
                      manage, research and develop our products and services;
                    </p>
                  </li>
                  <li>
                    <p>
                      provide you with information about our products and
                      services;
                    </p>
                  </li>
                  <li>
                    <p>
                      communicate with you by a variety of measures including,
                      but not limited to, by telephone, email, sms or mail; and
                    </p>
                  </li>
                  <li>
                    <p>
                      investigate any complaints. If you withhold your personal
                      information, it may not be possible for us to provide you
                      with our products and services or for you to fully access
                      our website.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  We may disclose your personal information to comply with a
                  legal requirement, such as a law, regulation, court order,
                  subpoena, warrant, legal proceedings or in response to a law
                  enforcement agency request.
                </p>
              </li>
              <li>
                <p>
                  If there is a change of control in our business or a sale or
                  transfer of business assets, we reserve the right to transfer
                  to the extent permissible at law our user databases, together
                  with any personal information and non-personal information
                  contained in those databases.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Disclosure of your personal information</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd may disclose your personal information to any
                  of our employees, officers, insurers, professional advisers,
                  agents, suppliers or subcontractors insofar as reasonably
                  necessary for the purposes set out in this privacy policy.
                </p>
              </li>
              <li>
                <p>
                  If we do disclose your personal information to a third party,
                  we will protect it in accordance with this privacy policy.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong
                >General Data Protection Regulation (GDPR) for the European
                Union (EU)</strong
              >
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd will comply with the principles of data
                  protection set out in the GDPR for the purpose of fairness,
                  transparency and lawful data collection and use.
                </p>
              </li>
              <li>
                <p>
                  We process your personal information as a Processor and/or to
                  the extent that we are a Controller as defined in the GDPR.
                </p>
              </li>
              <li>
                <p>
                  We must establish a lawful basis for processing your personal
                  information. The legal basis for which we collect your
                  personal information depends on the data that we collect and
                  how we use it.
                </p>
              </li>
              <li>
                <p>
                  We will only collect your personal information with your
                  express consent for a specific purpose and any data collected
                  will be to the extent necessary and not excessive for its
                  purpose. We will keep your data safe and secure.
                </p>
              </li>
              <li>
                <p>
                  We will also process your personal information if it is
                  necessary for our legitimate interests, or to fulfil a
                  contractual or legal obligation.
                </p>
              </li>
              <li>
                <p>
                  We process your personal information if it is necessary to
                  protect your life or in a medical situation, it is necessary
                  to carry out a public function, a task of public interest or
                  if the function has a clear basis in law.
                </p>
              </li>
              <li>
                <p>
                  We do not collect or process any personal information from you
                  that is considered “Sensitive Personal Information” under the
                  GDPR, such as personal information relating to your sexual
                  orientation or ethnic origin unless we have obtained your
                  explicit consent, or if it is being collected subject to and
                  in accordance with the GDPR.
                </p>
              </li>
              <li>
                <p>
                  You must not provide us with your personal information if you
                  are under the age of 16 without the consent of your parent or
                  someone who has parental authority for you. We do not
                  knowingly collect or process the personal information of
                  children.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Your rights under the GDPR</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  If you are an individual residing in the EU, you have certain
                  rights as to how your personal information is obtained and
                  used. MobilityGuru Ltd complies with your rights under the
                  GDPR as to how your personal information is used and
                  controlled if you are an individual residing in the EU
                </p>
              </li>
              <li>
                <p>
                  Except as otherwise provided in the GDPR, you have the
                  following rights:
                </p>
                <ul>
                  <li>
                    <p>
                      to be informed how your personal information is being
                      used;
                    </p>
                  </li>
                  <li>
                    <p>
                      access your personal information (we will provide you with
                      a free copy of it);
                    </p>
                  </li>
                  <li>
                    <p>
                      to correct your personal information if it is inaccurate
                      or incomplete;
                    </p>
                  </li>
                  <li>
                    <p>
                      to delete your personal information (also known as “the
                      right to be forgotten”);
                    </p>
                  </li>
                  <li>
                    <p>to restrict processing of your personal information;</p>
                  </li>
                  <li>
                    <p>
                      to retain and reuse your personal information for your own
                      purposes;
                    </p>
                  </li>
                  <li>
                    <p>
                      to object to your personal information being used; and
                    </p>
                  </li>
                  <li>
                    <p>
                      to object against automated decision making and profiling.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Please contact us at any time to exercise your rights under
                  the GDPR at the contact details in this Privacy Policy.
                </p>
              </li>
              <li>
                <p>
                  We may ask you to verify your identity before acting on any of
                  your requests.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Hosting and International Data Transfers</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  Information that we collect may from time to time be stored,
                  processed in or transferred between parties or sites located
                  in countries outside of Australia. These may include, but are
                  not limited to countries world wide.
                </p>
              </li>
              <li>
                <p>
                  We and our other group companies have offices and/or
                  facilities in World Wide. Transfers to each of these countries
                  will be protected by appropriate safeguards, these include one
                  or more of the following: the use of standard data protection
                  clauses adopted or approved by the European Commission which
                  you can obtain from the European Commission Website; the use
                  of binding corporate rules, a copy of which you can obtain
                  from MobilityGuru Ltd’s Data Protection Officer.
                </p>
              </li>
              <li>
                <p>
                  The hosting facilities for our website are situated in World
                  Wide. Transfers to each of these Countries will be protected
                  by appropriate safeguards, these include one or more of the
                  following: the use of standard data protection clauses adopted
                  or approved by the European Commission which you can obtain
                  from the European Commission Website; the use of binding
                  corporate rules, a copy of which you can obtain from
                  MobilityGuru Ltd’s Data Protection Officer.
                </p>
              </li>
              <li>
                <p>
                  Our Suppliers and Contractors are situated in World Wide.
                  Transfers to each of these Countries will be protected by
                  appropriate safeguards, these include one or more of the
                  following: the use of standard data protection clauses adopted
                  or approved by the European Commission which you can obtain
                  from the European Commission Website; the use of binding
                  corporate rules, a copy of which you can obtain from
                  MobilityGuru Ltd’s Data Protection Officer.
                </p>
              </li>
              <li>
                <p>
                  You acknowledge that personal data that you submit for
                  publication through our website or services may be available,
                  via the internet, around the world. We cannot prevent the use
                  (or misuse) of such personal data by others.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Security of your personal information</strong></p>

            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd is committed to ensuring that the information
                  you provide to us is secure. In order to prevent unauthorised
                  access or disclosure, we have put in place suitable physical,
                  electronic and managerial procedures to safeguard and secure
                  information and protect it from misuse, interference, loss and
                  unauthorised access, modification and disclosure.
                </p>
              </li>
              <li>
                <p>
                  Where we employ data processors to process personal
                  information on our behalf, we only do so on the basis that
                  such data processors comply with the requirements under the
                  GDPR and that have adequate technical measures in place to
                  protect personal information against unauthorised use, loss
                  and theft.
                </p>
              </li>
              <li>
                <p>
                  The transmission and exchange of information is carried out at
                  your own risk. We cannot guarantee the security of any
                  information that you transmit to us, or receive from us.
                  Although we take measures to safeguard against unauthorised
                  disclosures of information, we cannot assure you that personal
                  information that we collect will not be disclosed in a manner
                  that is inconsistent with this Privacy Policy.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Access to your personal information</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  You may request details of personal information that we hold
                  about you in accordance with the provisions of the
                  <strong><em>Privacy Act</em></strong> 1988 (Cth), and to the
                  extent applicable the EU GDPR. If you would like a copy of the
                  information which we hold about you or believe that any
                  information we hold on you is inaccurate, out of date,
                  incomplete, irrelevant or misleading, please email us at
                  admin@mobilityguru.org.
                </p>
              </li>
              <li>
                <p>
                  We reserve the right to refuse to provide you with information
                  that we hold about you, in certain circumstances set out in
                  the Privacy Act or any other applicable law.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Complaints about privacy</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  If you have any complaints about our privacy practices, please
                  feel free to send in details of your complaints to
                  admin@mobilityguru.org. We take complaints very seriously and
                  will respond shortly after receiving written notice of your
                  complaint.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Changes to Privacy Policy</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  Please be aware that we may change this Privacy Policy in the
                  future. We may modify this Policy at any time, in our sole
                  discretion and all modifications will be effective immediately
                  upon our posting of the modifications on our website or notice
                  board. Please check back from time to time to review our
                  Privacy Policy.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Website</strong></p>
            <ul class="numeric-decimals">
              <li style="font-weight: bold">
                <p>When you visit our website</p>
              </li>
              <p>
                When you come to our website (<a href="http://MobilityGuru.org"
                  >MobilityGuru.org</a
                >
                or MobilityGuru.io), we may collect certain information such as
                browser type, operating system, website visited immediately
                before coming to our site, etc. This information is used in an
                aggregated manner to analyse how people use our site, such that
                we can improve our service.
              </p>
              <li style="font-weight: bold">
                <p>Cookies</p>
              </li>
              <p>
                We may from time to time use cookies on our website. Cookies are
                very small files which a website uses to identify you when you
                come back to the site and to store details about your use of the
                site. Cookies are not malicious programs that access or damage
                your computer. Most web browsers automatically accept cookies
                but you can choose to reject cookies by changing your browser
                settings. However, this may prevent you from taking full
                advantage of our website. Our website may from time to time use
                cookies to analyses website traffic and help us provide a better
                website visitor experience. In addition, cookies may be used to
                serve relevant ads to website visitors through third party
                services such as Google AdWords. These ads may appear on this
                website or other websites you visit.
              </p>
              <li style="font-weight: bold">
                <p>Third party sites</p>
              </li>
              <p>
                Our site may from time to time have links to other websites not
                owned or controlled by us. These links are meant for your
                convenience only. Links to third party websites do not
                constitute sponsorship or endorsement or approval of these
                websites. Please be aware that MobilityGuru Ltd is not
                responsible for the privacy practises of other such websites. We
                encourage our users to be aware, when they leave our website, to
                read the privacy statements of each and every website that
                collects personal identifiable information.
              </p>
            </ul>
          </li>
        </ol>
        <p>
          <strong>Approved by</strong>
        </p>
        <v-img :src="signature" contain width="150" alt="Signature"> </v-img>
        <!--<p>Signature pastedGraphic.png ¬</p>-->
        <p>Full Name: Anthony Cash</p>
        <p>Date: 02 April, 2021</p>

        <v-row justify="center" v-if="fromRegistration === true">
          <MyBtn
            btnName="Go back to Registration"
            iconName="mdi-transfer-left"
            @btn-clicked="goBackToRegistration(tab_id)"
          ></MyBtn>
        </v-row>
      </div>
    </body>
    <!--  <Loading :isLoading="isLoading"></Loading>-->
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}

ol li {
  display: block;
} /* hide original list counter */
ol > li:first-child {
  counter-reset: item;
} /* reset counter */
ol > li {
  counter-increment: item;
  position: relative;
} /* increment counter */
ol > li:before {
  content: counters(item, ".") ".   ";
  position: absolute;
  margin-right: 100%;
  right: 8px;
} /* print counter */

/* Nested lower alphabet list*/
ul.numeric-decimals {
  counter-reset: section;
}
ul.numeric-decimals > li {
  list-style-type: none;
}
ul.numeric-decimals > li:before {
  counter-increment: section;
  content: counter(section, lower-alpha) ")";
  position: absolute;
  left: 5px;
}

/*Nested lower roman numbered listed inside the alphabet list */
ul.numeric-decimals li ul {
  counter-reset: subsection;
}
ul.numeric-decimals li ul li:before {
  counter-increment: subsection;
  content: counter(subsection, lower-roman) ". ";
  position: absolute;
  left: 25px;
}
</style>

<script>
import MeSpeak from "./MeSpeak";
import MyBtn from "./MyBtn";
//import Loading from "./Loading.vue";
import signature from "./pics/signature.png";
export default {
  name: "Privacy",

  metaInfo: {
    title: "Mobility Guru",
    template: "%s|vfvdvd",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Privacy Policy",
      },
      {
        name: "keywords",
        content: "privacy, policy, personal, information",
      },
    ],
  },
  components: {
    MyBtn,
    MeSpeak,
    //Loading,
  },
  data: () => ({
    fromRegistration: false,
    tab_id: "",
    signature: signature,
    meSpeakText: "",
    title: "",
    content: "",
    contentObject: {},
    isLoading: false,
    totalNumbers: 13,
  }),
  mounted: async function () {
    this.init();
  },

  /*watch: {
    "$store.state.selectedLanguageCode": async function () {
      await this.init();
    },
  },*/
  methods: {
    async init() {
      this.isLoading = true;
      let results = this.checkFromRegistration();
      this.tab_id = results.tab_id;
      this.fromRegistration = results.fromRegistration;
      /*let res = await this.getPrivacyPolicy(this.$i18n.locale);
      this.printJson(res);

      this.contentObject = res.translatedContent;
      this.title = this.contentObject.title;
      this.content = this.contentObject.content;
      this.meSpeakText = `${this.title}`;
      this.meSpeakText += `${this.content}`;
      this.isLoading = false;*/
      this.loadMeSpeakText();
    },

    async loadMeSpeakText() {
      this.meSpeakText = "PRIVACY POLICY: MobilityGuru Ltd";
      this.meSpeakText += `1. We respect your privacy

      (a)MobilityGuru Ltd respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. This policy sets out how we collect and treat your personal information. 

      (b)We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) and to the extent applicable, the EU General Data Protection Regulation (GDPR). 

      (c)“Personal information” is information we hold which is identifiable as being about you. This includes information such as your name, email address, identification number, or any other type of information that can reasonably identify an individual, either directly or indirectly. 

      (C)You may contact us in writing at 1st Floor 85 Great Portland St, London, W1W7LT, United Kingdom or email admin@mobilityguru.org for further information about this Privacy Policy.`;

      this.meSpeakText += `2. What personal information is collected

      (a)MobilityGuru Ltd will, from time to time, receive and store personal information you submit to our website, provided to us directly or given to us in other forms. 

      (b)You may provide basic information such as your name, phone number, address and email address to enable us to send you information, provide updates and process your product or service order. 

      (c)We may collect additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, provide financial or credit card information, or communicate with our customer support. 

      (d)Additionally, we may also collect any other information you provide while interacting with us.`;

      this.meSpeakText += `3. How we collect your personal information

      (a)MobilityGuru Ltd collects personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we engage in business activities with you. We may receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy. 

      (b)By providing us with personal information, you consent to the supply of thatinformation subject to the terms of this Privacy Policy. `;

      this.meSpeakText += `4.	How we use your personal information

      (a)	MobilityGuru Ltd may use personal information collected from you to provide you with information about our products or services. We may also make you aware of new and additional products, services and opportunities available to you.

      (b)	MobilityGuru Ltd will use personal information only for the purposes that you consent to. This may include to:

      (i)	provide you with products and services during the usual course of our business activities;

      (ii)	administer our business activities;

      (iii)	manage, research and develop our products and services;

      (iv)	provide you with information about our products and services;

      (v)	communicate with you by a variety of measures including, but not limited to, by telephone, email, sms or mail; and

      (vi)	investigate any complaints.

      If you withhold your personal information, it may not be possible for us to provide you with our products and services or for you to fully access our website.

      (c)	We may disclose your personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, legal proceedings or in response to a law enforcement agency request.

      (d)	If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases.`;

      this.meSpeakText += `5.	Disclosure of your personal information

      (a)	MobilityGuru Ltd may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this privacy policy.

      (b)	If we do disclose your personal information to a third party, we will protect it in accordance with this privacy policy.
      `;

      this.meSpeakText += `6.	General Data Protection Regulation (GDPR) for the European Union (EU)

      (a)	MobilityGuru Ltd will comply with the principles of data protection set out in the
      
      GDPR for the purpose of fairness, transparency and lawful data collection and use.

      (b)	We process your personal information as a Processor and/or to the extent that we are a Controller as defined in the GDPR.

      (c)	We must establish a lawful basis for processing your personal information. The legal basis for which we collect your personal information depends on the data that we collect and how we use it.

      (d)	We will only collect your personal information with your express consent for a specific purpose and any data collected will be to the extent necessary and not excessive for its purpose. We will keep your data safe and secure.

      (e)	We will also process your personal information if it is necessary for our legitimate interests, or to fulfil a contractual or legal obligation.

      (f)	We process your personal information if it is necessary to protect your life or in a medical situation, it is necessary to carry out a public function, a task of public interest or if the function has a clear basis in law.

      (g)	We do not collect or process any personal information from you that is considered "Sensitive Personal Information" under the GDPR, such as personal information relating to your sexual orientation or ethnic origin unless we have obtained your explicit consent, or if it is being collected subject to and in accordance with the GDPR.

      (h)	You must not provide us with your personal information if you are under the age of 16 without the consent of your parent or someone who has parental authority for you. We do not knowingly collect or process the personal information of children.`;

      this.meSpeakText += `7.	Your rights under the GDPR

      (a)	If you are an individual residing in the EU, you have certain rights as to how your personal information is obtained and used. MobilityGuru Ltd complies with your rights under the GDPR as to how your personal information is used and controlled if you are an individual residing in the EU

      (b)	Except as otherwise provided in the GDPR, you have the following rights:

      (i)	to be informed how your personal information is being used;

      (ii)	access your personal information (we will provide you with a free copy of it);

      (iii)	to correct your personal information if it is inaccurate or incomplete;

      (iv)	to delete your personal information (also known as "the right to be forgotten");
      
      (v)	to restrict processing of your personal information;

      (vi)	to retain and reuse your personal information for your own purposes;

      (vii)	to object to your personal information being used; and

      (viii)	to object against automated decision making and profiling.

      (c)	Please contact us at any time to exercise your rights under the GDPR at the contact details in this Privacy Policy.

      (d)	We may ask you to verify your identity before acting on any of your requests.
      `;

      this.meSpeakText += `8.	Hosting and International Data Transfers

      (a)	Information that we collect may from time to time be stored, processed in or transferred between parties or sites located in countries outside of Australia. These may include, but are not limited to countries world wide.

      (b)	We and our other group companies have offices and/or facilities in World Wide. Transfers to each of these countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of which you can obtain from MobilityGuru Ltd's Data Protection Officer.

      (c)	The hosting facilities for our website are situated in World Wide. Transfers to each of these Countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of which you can obtain from MobilityGuru Ltd's Data Protection Officer.

      (d)	Our Suppliers and Contractors are situated in World Wide. Transfers to each of these Countries will be protected by appropriate safeguards, these include one or more of the following: the use of standard data protection clauses adopted or approved by the European Commission which you can obtain from the European Commission Website; the use of binding corporate rules, a copy of which you can obtain from MobilityGuru Ltd's Data Protection Officer.

      (e)	You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.`;

      this.meSpeakText += `9.	Security of your personal information

      (a)	MobilityGuru Ltd is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in
      

      place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.

      (b)	Where we employ data processors to process personal information on our behalf, we only do so on the basis that such data processors comply with the requirements under the GDPR and that have adequate technical measures in place to protect personal information against unauthorised use, loss and theft.

      (c)	The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.`;

      this.meSpeakText += `10.	Access to your personal information

      (a)	You may request details of personal information that we hold about you in accordance with the provisions of the Privacy Act 1988 (Cth), and to the extent applicable the EU GDPR. If you would like a copy of the information which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at admin@mobilityguru.org.

      (b)	We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act or any other applicable law.`;

      this.meSpeakText += `11.	Complaints about privacy

      (a)	If you have any complaints about our privacy practices, please feel free to send in details of your complaints to admin@mobilityguru.org. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.`;

      this.meSpeakText += `12.	Changes to Privacy Policy

      (a)	Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.`;

      this.meSpeakText += `
      13.	Website

      (a)	When you visit our website

      When you come to our website (MobilityGuru.org or MobilityGuru.io), we may collect certain information such as browser type, operating system, website visited immediately
      
      before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.`;

      this.meSpeakText += `(b)	Cookies

      We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyses website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google AdWords. These ads may appear on this website or other websites you visit.

      (c)	Third party sites

      Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that MobilityGuru Ltd is not responsible for the privacy practises of other such websites. We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personal identifiable information.
      `;

      this.meSpeakText += `Approved by
      Full Name:	Anthony Cash
      Date:	02 April, 2021`;
    },
  },
};
</script>
