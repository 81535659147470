<template>
  <v-dialog persistent v-model="isShowChartsLocal" width="800">
    <v-card min-height="500">
      <MyCardTitle
        :title="$t('metrics')"
        @close-window="closeWindow()"
      ></MyCardTitle>
      <v-row justify="center" no-gutters>
        <v-progress-circular
          indeterminate
          v-if="isLaodingGraphs == true"
        ></v-progress-circular>

        <v-container v-else>
          <LineChart
            :chartData="dataCollectionAdsValue"
            :options="optionsAdsValue"
          ></LineChart>
          <LineChart
            :chartData="multiLineGraphData"
            :options="optionsMulti"
          ></LineChart>

          <v-divider class="pa-3"></v-divider>

          <!-- <LineChart
            :chartData="dataCollectionNumberOfUsers"
            :options="options"
          ></LineChart>

          <v-divider class="pa-3"></v-divider>

          <LineChart
            :chartData="dataCollectionNumberOfAds"
            :options="options"
          ></LineChart>-->
        </v-container>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import MyCardTitle from "./MyCardTitle";
import LineChart from "./LineChart";
export default {
  name: "StatCharts",
  props: [
    "isShowCharts",
    /* "collectionMetricsData", "labelAndDataSets",*/
    "currencyCode",
  ],
  components: {
    MyCardTitle,
    LineChart,
  },
  /*watch: {
    isShowCharts: async function () {
      if (this.isShowCharts == true) {
        this.isShowChartsLocal = true;
        await this.extractData();
      }
    },
  },*/
  mounted: function () {
    this.init();
  },
  data: () => ({
    labelAndDataSets: {},
    isLaodingGraphs: false,
    isShowChartsLocal: false,
    labels: [],
    totalAdsValueData: [],
    numberOfAdsData: [],
    numberOfUsersData: [],

    //optionsAdsValue: {},
    //optionsNUmberOfAds: {},
    //optionNumberOfUsers: {},

    multiLineGraphData: {
      datasets: [],
    },

    dataCollectionAdsValue: {
      //Data to be represented on x-axis
      /* labels:*/
      datasets: [
        {
          label: "Total Ads value",
          backgroundColor: "white",
          borderColor: "purple",
          pointBackgroundColor: "purple",
          borderWidth: 1,
          pointBorderColor: "purple",

          //Data to be represented on y-axis
          //data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
        },
      ],
    },

    dataCollectionNumberOfAds: {
      //Data to be represented on x-axis
      /* labels:*/
      datasets: [
        {
          label: "Number of ads",
          backgroundColor: "white",
          pointBackgroundColor: "blue",
          borderWidth: 1,
          pointBorderColor: "blue",
          borderColor: "blue",
          //Data to be represented on y-axis
          //data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
        },
      ],
    },

    dataCollectionNumberOfUsers: {
      //Data to be represented on x-axis
      /* labels:*/
      datasets: [
        {
          label: "Number of users",
          backgroundColor: "white",
          pointBackgroundColor: "red",
          borderWidth: 1,
          pointBorderColor: "red",
          borderColor: "red",
          //Data to be represented on y-axis
          //data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
        },
      ],
    },
    //Chart.js options that controls the appearance of the chart
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    optionsAdsValue: {
      scales: {
        yAxes: [
          {
            /*  scaleLabel: {
              display: true,
              labelString: this.$store.state.cuurrencyCode,
            },*/
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
    },

    optionsMulti: {
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: true,
      plugins: {
        title: {
          display: true,
          text: "",
        },
      },
      /*scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",

          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },*/
    },
  }),

  methods: {
    async init() {
      this.isShowChartsLocal = this.isShowCharts;
      if (this.isShowCharts == true) {
        this.extractData();
      }
      // await this.synchSetTimeout(100);
      // this.isLaodingGraphs = false;
    },

    async extractData() {
      this.isLaodingGraphs = true;

      //No need of collectionMetricsData
      /* for (let i = 0; i < this.collectionMetricsData.length; i++) {
        this.printJson(`${i}`, this.collectionMetricsData[i]);
        let date = new Date(this.collectionMetricsData[i]["date"]);
        this.labels.push(
          date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()
        );
        this.totalAdsValueData.push(
          this.collectionMetricsData[i]["totalAdsValue"].toFixed(0)
        );
        this.numberOfAdsData.push(this.collectionMetricsData[i]["numberOfAds"]);
        this.numberOfUsersData.push(
          this.collectionMetricsData[i]["numberOfUsers"]
        );
      }*/
      this.labelAndDataSets = await this.fetchLabelAndDataSets(
        this.currencyCode
      );
      this.labels = this.labelAndDataSets.labels;
      this.totalAdsValueData = this.labelAndDataSets.totalAdsValueData;
      this.numberOfAdsData = this.labelAndDataSets.numberOfAdsData;
      this.numberOfUsersData = this.labelAndDataSets.numberOfUsersData;

      /*this.optionsAdsValue = this.options;*/
      this.optionsAdsValue.scales["yAxes"][0]["scaleLabel"] = {
        display: true,
        labelString: this.currencyCode,
      };
      this.dataCollectionAdsValue["labels"] = this.labels;
      this.dataCollectionAdsValue.datasets[0]["data"] = this.totalAdsValueData;

      this.dataCollectionNumberOfAds["labels"] = this.labels;
      this.dataCollectionNumberOfAds.datasets[0]["data"] = this.numberOfAdsData;

      this.dataCollectionNumberOfUsers["labels"] = this.labels;
      this.dataCollectionNumberOfUsers.datasets[0]["data"] =
        this.numberOfUsersData;

      this.multiLineGraphData["labels"] = this.labels;
      /*this.multiLineGraphData.datasets[0] =
        this.dataCollectionAdsValue.datasets[0];*/

      this.multiLineGraphData.datasets[0] =
        this.dataCollectionNumberOfAds.datasets[0];
      this.multiLineGraphData.datasets[1] =
        this.dataCollectionNumberOfUsers.datasets[0];

      this.printJson(this.totalAdsValueData);
      this.isLaodingGraphs = false;
    },
    closeWindow() {
      this.$emit("close-window");
      // this.isShowChartsLocal = false;
    },
  },
};
</script>
