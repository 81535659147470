<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>About-MobilityGuru-V1-2019</title>
      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <center>
        <h1>
          About - MobilityGuru
          <MeSpeak streamKey="about" size="40" :text="meSpeakText"></MeSpeak>
        </h1>
      </center>
      <div class="ma-2">
        <p>
          Is a site developed by Anthony and Kean to assist in finding products
          and services that are an every day requirement for more than 1.2
          Billion people in the world.
        </p>
        <p>
          Whether you need to find a vehicle or personal care professional or
          wheelchair or disability compliant accommodation while on holiday,
          this is the site for you.
        </p>
        <p>
          Being wheelchair bound I found it very difficult to find so many types
          of products and services like accommodation, travel, mobility support
          items readily available as new or second hand in one source. Many
          hotels and motels that state that they have wheelchair compliant
          accommodation barely fit a wheel chair when entering the bathroom and
          many the bed heights are much higher than the average wheel chair
          sitting height, doors have knobs not handles and open frontwards
          etc... This is not only the case for disabled but also elderly for
          which the numbers are increasing with people living longer and longer
          each year.
        </p>
        <p>
          We have put together a site plus forum and mostly cost free
          advertising to promote the buy/sales of any products and services in
          this industry. Over the coming months we will be bring many innovative
          and exiting developments to our members/users.
        </p>
        <p>
          It is our dream that every person have a happy and independent life.
        </p>
        <p>
          Use of the site in governed by the Terms and Conditions and Privacy
          Policy published by the site.
        </p>
      </div>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </body>
    <!--<Loading :isLoading="isLoading"></Loading>-->
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
//import Loading from "./Loading.vue";
import MeSpeak from "./MeSpeak";
export default {
  name: "About",
  components: {
    MeSpeak,
    // Loading,
  },
  metaInfo: {
    title: "Mobility Guru About",

    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  data: () => ({
    meSpeakText: "",
    title: "",
    content: "",
    isLoading: false,
  }),

  mounted: async function () {
    await this.init();
  },

  /* watch: {
    "$store.state.selectedLanguageCode": async function () {
      await this.init();
    },
  },*/
  methods: {
    async init() {
      await this.loadMeSpeakText();

      //this.trackUser();
      /*this.isLoading = true;
      let res = await this.getAbout(this.$i18n.locale);
      this.printJson(res);
      this.title = res.translatedContent.title;
      this.content = res.translatedContent.content;
      this.meSpeakText = `${this.title}`;
      this.meSpeakText += `${this.content}`;
      this.isLoading = false;*/
    },
    async loadMeSpeakText() {
      this.meSpeakText = `About MobilityGuru  \n`;
      this.meSpeakText += `Is a site developed by Anthony and Kean to assist in finding products and services that are an every day requirement for more than 1.2 Billion people in the world. ;`;

      this.meSpeakText += `Whether you need to find a vehicle or personal care professional or wheelchair or disability compliant accommodation while on holiday, this is the site for you. `;
      this.meSpeakText += `Being wheelchair bound I found it very difficult to find so many types of products and services like accommodation, travel, mobility support items readily available as new or second hand in one source. Many hotels and motels that state that they have wheelchair compliant accommodation barely fit a wheel chair when entering the bathroom and many the bed heights are much higher than the average wheel chair sitting height, doors have knobs not handles and open frontwards etc This is not only the case for disabled but also elderly for which the numbers are increasing with people living longer and longer each year. `;

      this.meSpeakText += `We have put together a site plus forum and mostly cost free advertising to promote the buy/sales of any products and services in this industry. Over the coming months we will be bring many innovative and exiting developments to our members/users. `;

      this.meSpeakText += `It is our dream that every person have a happy and independent life. `;

      this.meSpeakText += `Use of the site in governed by the Terms and Conditions and Privacy Policy published by the site. \n`;
    },
  },
};
</script>
