<template>
  <span>
    <!--<audio ref="googleTTS" controls hidden>
      <source v-bind:src="audioUrl" />
      Your browser does not support the audio element.
    </audio>-->
    <v-tooltip top v-if="playing === false">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :disabled="disabled"
          :size="iconsSize"
          color="brown"
          v-bind="attrs"
          v-on="on"
          @click="speak()"
          >{{ mdiTextToSpeech }}</v-icon
        >
      </template>
      <span>{{ $t(`text`) }}</span>
    </v-tooltip>
    <v-badge v-else color="transparent" overlap avatar>
      <template v-slot:badge>
        <span class="green--text">{{ $t(`playing`) }}</span>
        <v-progress-linear
          v-if="playing === true"
          color="primary"
          buffer-value="0"
          stream
        ></v-progress-linear>
      </template>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :size="iconsSize"
            color="brown"
            v-bind="attrs"
            v-on="on"
            @click="stop()"
          >
            {{ mdiTextToSpeechOff }}</v-icon
          >
        </template>
        <span>{{ $t(`stop`) }}</span>
      </v-tooltip>
    </v-badge>

    <v-snackbar
      color="orange"
      timeout="-1"
      v-model="showSnackbar"
      centered
      multi-line
    >
      <span class="black--text" v-html="snackbarMessage"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        ></v-btn>

        <v-icon
          class="ml-3"
          color="pink"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
          >{{ mdiCloseCircle }}</v-icon
        >
      </template>
    </v-snackbar>
  </span>
</template>
<script
  type="text/javascript"
  charset="UTF-8"
  src="../public/mespeak/mespeak.js"
></script>
<script>
import { mdiTextToSpeech, mdiTextToSpeechOff, mdiCloseCircle } from "@mdi/js";
import Config from "../config";
export default {
  name: "MeSpeak",
  props: ["text", "size", "locale", "streamKey"],

  data: () => ({
    disabled: false,
    localeLocal: "",
    localeForMeSpeak: "",
    mdiTextToSpeech: mdiTextToSpeech,
    mdiTextToSpeechOff: mdiTextToSpeechOff,
    mdiCloseCircle: mdiCloseCircle,
    playing: false,
    speakId: null,
    iconsSize: "",
    snackbarMessage: "",
    showSnackbar: false,
    streamObject: {},
    /*audioUrl: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Example.ogg",
    googleTTS: "",
    ttsKey: 0,*/
  }),

  mounted: function () {
    this.init();
  },

  watch: {
    "$store.state.selectedLanguageCode": async function () {
      this.init();
    },
    /*audioUrl: async function () {
      this.debug(`watching load`);
      this.$refs.googleTTS.load();
    },*/
  },

  methods: {
    async init() {
      if (this.size === undefined) {
        this.iconsSize = 25;
      } else {
        this.iconsSize = this.size;
      }
      this.streamObject = {};

      /*if (this.locale == undefined || this.locale == null) {
        this.localeLocal = this.$i18n.locale;
      } else {
        this.localeLocal = this.locale;
      }
      if (this.localeLocal in Config.ME_SPEAK_LOCALES === false) {
        this.disabled = true;
      } else {
        this.disabled = false;
        this.localeForMeSpeak = Config.ME_SPEAK_LOCALES[this.localeLocal];
      }*/
    },

    async speak() {
      this.disabled = true;
      this.debug("cookie saved for locale");
      this.debug(await this.getLocaleOfGT());
      this.localeLocal = await this.getLocaleOfGT();
      this.localeLocal = this.localeLocal.slice(0, 2);
      let cacheExist = false;
      this.debug(`sliced string of locale = ${this.localeLocal}`);

      if (this.localeLocal in Config.ME_SPEAK_LOCALES) {
        this.localeForMeSpeak = Config.ME_SPEAK_LOCALES[this.localeLocal];
        let ok = true;
        let textToSpeak = this.text;
        if (
          this.streamKey !== undefined &&
          this.streamKey in this.$store.state.meSpeakStreamCache
        ) {
          this.streamObject =
            this.$store.state.meSpeakStreamCache[this.streamKey];
          if (this.localeForMeSpeak in this.streamObject) {
            //cachedStream = this.streamObject[this.localeForMeSpeak];
            cacheExist = true;
          }
        } else {
          if (this.localeForMeSpeak in this.streamObject) {
            cacheExist = true;
          }
        }
        if (this.localeLocal !== "en" && cacheExist == false) {
          this.debug(`start translating`);

          this.$store.commit("set", ["showTranslatingSnackbar", true]);
          let res = await this.translateTextSafe(
            "en",
            this.localeForMeSpeak,
            this.text
          );

          this.$store.commit("set", ["showTranslatingSnackbar", false]);
          if (res.ok == true) {
            ok = true;
            textToSpeak = res.translatedText;
          } else {
            ok = false;
            this.debug(`translation error`);
            this.showSnackbar = true;
            this.snackbarMessage = `Translation failed`;
          }
        }
        if (ok == true) {
          this.debug(`start playing`);
          this.playing = true;
          //this.meSpeakKey++;
          this.debug(this.localeForMeSpeak);
          this.debug(textToSpeak);
          //MeSpeak
          meSpeak.loadVoice(`voices/${this.localeForMeSpeak}.json`);
          /*this.speakId = meSpeak.speak(
            textToSpeak,
            { speed: 140 },
            this.hideStop
          );*/

          await this.getStream(textToSpeak);

          //Google trasnlate TTS
          //this.playGoogleTTS(textToSpeak, this.localeForMeSpeak);
        }
      } else {
        this.debug(`unsupported locale  for meSpeak`);
        this.showSnackbar = true;
        this.snackbarMessage = `Language not supported`;
      }
      this.disabled = false;
    },
    hideStop() {
      this.playing = false;
    },
    stop() {
      meSpeak.stop(/*this.speakId*/);
      this.hideStop();
      //this.pauseaTTS();
    },

    async getStream(text) {
      let start = Date.now();
      let self = this;
      let cachedStream;
      let cacheExist = false;
      this.debug(`start streaming`);
      meSpeak.loadVoice(
        `voices/${this.localeForMeSpeak}.json`,
        async function (networkerror, dataerror, fileerror) {
          self.debug(`errors`);
          self.debug(networkerror);
          self.debug(dataerror);
          self.debug(fileerror);
        }
      );
      if (this.localeForMeSpeak in this.streamObject) {
        cachedStream = this.streamObject[this.localeForMeSpeak];
        cacheExist = true;
      }
      if (cacheExist == false) {
        this.debug(`caching and played`);
        self.printJson(
          meSpeak.isVoiceLoaded(this.localeForMeSpeak),
          "is loaded"
        );
        if (meSpeak.isVoiceLoaded(this.localeForMeSpeak) == false) {
          self.debug(`waiting for voice module loading`);
          await self.synchSetTimeout(1000);
        }
        meSpeak.speak(
          text,
          { rawdata: "array" },
          async function (success, id, stream) {
            // data is ArrayBuffer of 8-bit uint
            self.debug(success, id);
            let end = Date.now();
            self.debug(`stream loading time = ${end - start}`);
            cachedStream = stream;
            self.debug(cachedStream);
            //  this.$emit("stream-cached", cachedStream);
            self.streamObject[self.localeForMeSpeak] = cachedStream;
            if (self.streamKey !== undefined) {
              let meSpeakStreamCache = self.$store.state.meSpeakStreamCache;
              meSpeakStreamCache[self.streamKey] = self.streamObject;
              self.$store.commit("set", [
                "meSpeakStreamCache",
                meSpeakStreamCache,
              ]);
            }
            //meSpeak.setFilter({ speed: 140 });
            self.speakId = meSpeak.play(cachedStream, 1, async function () {
              self.debug(`stopped`);
              self.hideStop();
            });
            //  return stream;
          }
        );
      } else {
        self.debug(`playing streamed cache`);
        this.speakId = meSpeak.play(cachedStream, 1, async function () {
          self.debug(`stopped`);
          self.hideStop();
        });
      }
    },

    /*async playGoogleTTS(text, locale) {
      var audio = this.$refs.googleTTS;
      text = encodeURIComponent(text);

      //uncomment below line and comment below that for checking the google trasnlate api

      this.audioUrl = `https://translate.google.com/translate_tts?ie=UTF-8&q=${text}&tl=${locale}`;
      //this.audioUrl = "https://upload.wikimedia.org/wikipedia/en/4/45/ACDC_-_Back_In_Black-sample.ogg";
      await this.synchSetTimeout(1000);
      audio.play();
      audio.addEventListener("ended", this.hideStop);
    },

    async pauseTTS() {
      this.hideStop();
      var audio = this.$refs.googleTTS;
      audio.pause();
    },*/
  },
};
</script>
