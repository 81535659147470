<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            If want to know about the product, firsly you want to choose the
            relavent Ad from the
            <strong>Homepage Random Ads </strong> area or
            <strong>Filter</strong> in
            <a href="https://mobilityguru.org/home">Homepage</a> filter section
            as below.
            <MeSpeak
              text="If want to know about the product, firsly you want to choose the relavent Ad from the Homepage Random Ads area or filter in homepage filter section as
            below."
            >
            </MeSpeak>
            <br />
            <ul>
              <v-img
                :src="require(`./pics/adsPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/adsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
          <li>
            After choosing the <strong>Ad</strong> You will directing to the
            <strong>Relevant Ad</strong> view page.
            <MeSpeak
              text="
            After choosing the Ad You will directing to the Relevant Ad view page."
            ></MeSpeak>
          </li>
          <br />

          <li>
            Now you can see the <strong>Message Seller </strong> Button there.
            <MeSpeak
              text="
            Now you can see the Message Seller Button there."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/messagePc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/messageMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
          </li>
          <li>
            After clicking <strong>Message Seller</strong> button. you will
            directing to the chat.
            <MeSpeak
              text="
           After clicking Message Seller button. you will directing to the chat."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/chatPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/chatMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
