<template>
  <!--v-show="$store.state.isSuperAdmin"-->
  <!-- <v-col class="" v-if="$store.state.cryptoEnabled == true">
    <v-col v-show="payment == true">
      <v-btn class="mgbtn" @click="showPayment">
        <v-img
          contain
          content-class="transparent"
          height="25"
          width="40"
          src="./pics/guruToken.jpg"
        ></v-img>
        Pay Using GURU</v-btn
      >

      <MyTooltipInfo
        info="Use GURU from your crypto wallet (MetaMask, TrustWallet etc..) to pay for this service"
      ></MyTooltipInfo>
      <br />
      </v-col>

    <v-col v-show="sale == true">
      <v-btn class="mgbtn" @click="showSale">
        <v-img
          contain
          content-class="transparent"
          height="25"
          width="40"
          src="./pics/guruToken.jpg"
        ></v-img>
        Buy GURU using crypto</v-btn
      >

      <MyTooltipInfo
        info="Use coins from your crypto wallet (MetaMask, TrustWallet etc..) to buy GURU"
      ></MyTooltipInfo>

      <br />
    </v-col>

    <v-row justify="center" no-gutters v-show="showBuyGuru == true">
      <span>Don't have GURU ? <a @click="showBuyGuruDialog"> Buy GURU</a></span>
    </v-row>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
  </v-col>
  <v-col v-else>
    <span>Crypto payments are not allowed in your country</span>
  </v-col>-->
  <span>Crypto payments coming soon...</span>
</template>

<script>
//import DePayWidgets from "@depay/widgets";
//import MyTooltipInfo from "./MyTooltipInfo.vue";
import Config from "../config";
//import MyAcknowledge from "./MyAcknowledge.vue";
export default {
  name: "DepayWidget",
  props: ["payment", "sale", "receiverAddress", "amount", "showBuyGuru"],
  myAcknowledgeKey: "MyAcknowledge0",
  myAcknowledgeKeyCounter: 0,
  components: {
    //MyTooltipInfo, MyAcknowledge
  },
  data: () => ({
    API_KEY: Config.DEPAY_API_KEY,
    receiver: Config.ADMIN_WALLET_ADDRESS,
    BLOCKCHAIN_BSC: "bsc",
    BLOCKCHAIN_ETHEREUM: "ethereum",
    token_address: Config.GURU_TOKEN_ADDRESS,

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    acknowledgeOk: true,
    myAcknowledgeKey: "MyAcknowledge0",
    myAcknowledgeKeyCounter: 0,
  }),
  mounted: async function () {
    await this.init();
  },
  methods: {
    async init() {
      /*if (this.payment == true) {
      }
      if (this.sale == true) {
      }*/
    },
    /* showPayment() {
      let walletAddress = this.receiver;
      if (this.receiverAddress != null && this.receiverAddress != undefined) {
        walletAddress = this.receiverAddress;
      }
      DePayWidgets.Payment({
        accept: [
          {
            amount: this.amount,
            token: this.token_address,
            blockchain: this.BLOCKCHAIN_BSC,
            receiver: walletAddress,
          },
        ],
        connected: (address) => {
          this.debug(`connected`);
          this.debug(address);
        },
        sent: (transaction) => {
          // called when payment transaction has been sent to the network
          this.printJson(transaction, "sent");
        },
        // confirmed: (transaction) => {
         // this.printJson(transaction, "confirmed");
          //this.$emit("depay-payment-confirmed", transaction);
       // },

        track: {
          method: async (payment) => {
            this.printJson(payment, "confirmed");
            this.$emit("depay-payment-confirmed", payment);
          },
          async: true,
        },
        ensured: (transaction) => {
          this.printJson(transaction, "confirmed");
          // called when payment transaction has been confirmed X times by the network
        },
        failed: (transaction) => {
          // called when payment transaction failed on the blockchain
          // handled by the widget, no need to display anything
          this.printJson(transaction, "failed");
        },
        critical: (error) => {
          // render and display the error with error.toString()
          this.acknowledgeOk = false;
          this.showAcknowledge("ERROR", error.toString());
        },
        error: (error) => {
          // maybe do some internal tracking with error.toString()
          // no need to display anything as widget takes care of displaying the error
          this.printJson(error, "error");
        },
        succeeded: (transaction) => {
          // called when payment transaction has been confirmed once by the network
          this.printJson(transaction, "succeeded");
        },
        validated: (successful) => {
          // successful (true or false)
          this.printJson(successful, "validated");
        },
        whitelist: {
          bsc: [this.token_address],
        },
        style: {
          css: `notranslate`,
        },
        class: "notranslate",
      });
    },*/

    /* showSale() {
      DePayWidgets.Sale({
        sell: {
          bsc: this.token_address,
        },
        //amount: {
         // start: 1,
        //  step: 1,
        //  min: 1,
        //},
        currency: "USD",
        tokenImage:
          "https://mobilityguru.io/wp-content/uploads/2021/06/GURU-Token.png",

        connected: (address) => {
          this.debug(`connected`);
          this.debug(address);
        },
        sent: (transaction) => {
          this.printJson(transaction, "sent");
        },
        confirmed: (transaction) => {
          this.printJson(transaction, "confirmed");
          this.$emit("depay-sale-confirmed", transaction);
        },
        failed: (transaction) => {
          // called when payment transaction failed on the blockchain
          // handled by the widget, no need to display anything
          this.printJson(transaction, "failed");
        },
        critical: (error) => {
          // render and display the error with error.toString()
          this.acknowledgeOk = false;
          this.showAcknowledge("ERROR", error.toString());
        },
        error: (error) => {
          // maybe do some internal tracking with error.toString()
          // no need to display anything as widget takes care of displaying the error
          this.printJson(error, "error");
        },
        style: {
          css: `notranslate`,
        },
        class: "notranslate",
      });
    },*/

    showBuyGuruDialog() {
      this.$store.commit("set", [
        "toggleBuyGuru",
        !this.$store.state.toggleBuyGuru,
      ]);
    },

    showAcknowledgeMessage(title, message) {
      this.acknowledgeTitle = title;
      this.acknowledgeMessage = message;

      this.showAcknowledge = true;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },
    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },
  },
};
</script>

<!--
SALE returned
sent
main.js:378 {
  "blockchain": "bsc",
  "from": "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4",
  "to": "0x0Dfb7137bC64b63F7a0de7Cb9CDa178702666220",
  "api": [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_configuration",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "ETH",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "configuration",
      "outputs": [
        {
          "internalType": "contract DePayRouterV1Configuration",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "pluginAddress",
          "type": "address"
        }
      ],
      "name": "isApproved",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address[]",
          "name": "path",
          "type": "address[]"
        },
        {
          "internalType": "uint256[]",
          "name": "amounts",
          "type": "uint256[]"
        },
        {
          "internalType": "address[]",
          "name": "addresses",
          "type": "address[]"
        },
        {
          "internalType": "address[]",
          "name": "plugins",
          "type": "address[]"
        },
        {
          "internalType": "string[]",
          "name": "data",
          "type": "string[]"
        }
      ],
      "name": "route",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "token",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "withdraw",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "stateMutability": "payable",
      "type": "receive"
    }
  ],
  "method": "route",
  "params": {
    "path": [
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      "0xc166A8dd8e48355774ac95933f746b57A724A464"
    ],
    "amounts": [
      "15000000000000",
      "1280000000000000000",
      1645716501
    ],
    "addresses": [
      "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4",
      "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4"
    ],
    "plugins": [
      "0xAC3Ec4e420DD78bA86d932501E1f3867dbbfb77B",
      "0x8B127D169D232D5F3ebE1C3D06CE343FD7C1AA11"
    ],
    "data": []
  },
  "value": "15000000000000",
  "_confirmed": false,
  "_failed": false,
  "id": "0x2d97f4556e01e845b3fa6f8414988eb23db90823bbf7478db289fde03e8320b4",
  "url": "https://bscscan.com/tx/0x2d97f4556e01e845b3fa6f8414988eb23db90823bbf7478db289fde03e8320b4"
}

confirmed
main.js:378 {
  "blockchain": "bsc",
  "from": "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4",
  "nonce": 1,
  "to": "0x0Dfb7137bC64b63F7a0de7Cb9CDa178702666220",
  "api": [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_configuration",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "ETH",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "configuration",
      "outputs": [
        {
          "internalType": "contract DePayRouterV1Configuration",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "pluginAddress",
          "type": "address"
        }
      ],
      "name": "isApproved",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address[]",
          "name": "path",
          "type": "address[]"
        },
        {
          "internalType": "uint256[]",
          "name": "amounts",
          "type": "uint256[]"
        },
        {
          "internalType": "address[]",
          "name": "addresses",
          "type": "address[]"
        },
        {
          "internalType": "address[]",
          "name": "plugins",
          "type": "address[]"
        },
        {
          "internalType": "string[]",
          "name": "data",
          "type": "string[]"
        }
      ],
      "name": "route",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "token",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "withdraw",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "stateMutability": "payable",
      "type": "receive"
    }
  ],
  "method": "route",
  "params": {
    "path": [
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      "0xc166A8dd8e48355774ac95933f746b57A724A464"
    ],
    "amounts": [
      "15000000000000",
      "1280000000000000000",
      1645716501
    ],
    "addresses": [
      "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4",
      "0xB356669cbCD86f04EBb1274670827E4Be50E0FA4"
    ],
    "plugins": [
      "0xAC3Ec4e420DD78bA86d932501E1f3867dbbfb77B",
      "0x8B127D169D232D5F3ebE1C3D06CE343FD7C1AA11"
    ],
    "data": []
  },
  "value": "15000000000000",
  "_confirmed": true,
  "_failed": false,
  "id": "0x2d97f4556e01e845b3fa6f8414988eb23db90823bbf7478db289fde03e8320b4",
  "url": "https://bscscan.com/tx/0x2d97f4556e01e845b3fa6f8414988eb23db90823bbf7478db289fde03e8320b4"
}
-->
