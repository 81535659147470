<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            Posting an advertisement (Ad) is simple in MobilityGuru. Press the
            <strong>Submit Ad</strong> button in menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a>/
            <a href="https://mobilityguru.org/home">Home page</a> and fill the
            information requested to post an ad as below.<MeSpeak
              text=" Posting an advertisement (Ad) is simple in MobilityGuru. Press the Submit Ad button in menu dialog and fill the
            information requested to post an ad as below."
            ></MeSpeak
            ><br />
            <ul>
              <v-img
                :src="require(`./pics/submit0.1.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="Submit Ad"
              />
            </ul>
            <v-divider class="ma-5"></v-divider>
            <ul>
              <v-img
                :src="require(`./pics/submit0.2.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/submitPhone0.2.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
          </li>
          <li>
            Select the main category and sub category for your Ad in the popup
            menu of Categories.
            <MeSpeak
              text="
            Select the main category and sub category for your Ad in the popup
            menu of Categories."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/submit1.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="Select Category"
              />
            </ul>
          </li>
          <li>
            Give a suitable title for the Ad (Not more than 30
            characters)<MeSpeak
              text="
           Give a suitable title for the Ad (Not more than 30 characters)"
            ></MeSpeak
            ><br />
          </li>
          <li>
            Fill the price options
            <MeSpeak text="Fill the price options"></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/submit2.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="Price"
              />
            </ul>
          </li>
          <li>
            Fill the contact details
            <MeSpeak text="Fill the contact details"></MeSpeak>
            <ol type="1">
              <li>
                Address Options- If you have already given your address in your
                profile you can select the same address by clicking
                <strong>GET FROM MY ADDRESS</strong> or you can choose a new
                address by clicking
                <strong>CHOOSE ANOTHER ADDRESS</strong>.<MeSpeak
                  text="Address Options- If you have already given your address in your profile you can select the same address by clicking GET FROM MY ADDRESS or you can choose a new  address by clicking CHOOSE ANOTHER ADDRESS"
                ></MeSpeak
                ><br />
              </li>
              <li>
                Phone Options - If you have already given your phone in your
                profile you can select the same phone by clicking
                <strong>USE MY PHONE</strong> or you can choose a new phone by
                clicking <strong>SET PHONE</strong>.<MeSpeak
                  text="Phone Options - If you have already given your phone in your
                profile you can select the same phone by clicking USE MY PHONE or you can choose a new phone by clicking SET PHONE"
                ></MeSpeak>
                <ul>
                  <li>
                    Click <strong>ONLY SHOW TO LOGGED IN USERS</strong> dropdown
                    button to change the privacy level for your Ad's
                    Phone.<MeSpeak
                      text="Click ONLY SHOW TO LOGGED IN USERS dropdown
                    button to change the privacy level for your Ad's Phone."
                    ></MeSpeak
                    ><br />
                  </li>
                </ul>
              </li>
            </ol>
            <ul>
              <v-img
                :src="require(`./pics/submit3.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="Personal Details"
              />
            </ul>
          </li>
          <li>
            According to Ad category there will be some available fields to be
            filled. (Ex: For Mobility Transport category the vehicle’s details
            should be filled as required)<MeSpeak
              text="According to Ad category there will be some available fields to be
            filled. (Ex: For Mobility Transport category the vehicle’s details
            should be filled as required)"
            ></MeSpeak
            ><br />
          </li>
          <li>
            Add a <strong>Ad Description</strong> so the users can know more
            details about Ad.<MeSpeak
              text="Add a Ad Description so the users can know more
            details about Ad"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/submit4.0.png`)"
                class="mr-5 mt-5"
                contain
                alt="Description"
              />
            </ul>
            <v-divider class="ma-5"></v-divider>
            <ul>
              <v-img
                :src="require(`./pics/submit4.1.png`)"
                class="mr-5 mt-5 mb-5"
                contain
                alt="Description"
              />
            </ul>
          </li>
          <li>
            The best way to reach users is adding photos. Add upto 8 photos for
            Ad.<MeSpeak
              text="The best way to reach users is adding photos. Add upto 8 photos for
            Ad."
            ></MeSpeak
            ><br />
          </li>
          <li>
            Thats all!! Hit <strong>Save New Ad</strong> button to finish and
            Publish your Ad!!<MeSpeak
              text="Thats all. Hit Save New Ad button to finish and
            Publish your Ad"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/submit5.png`)"
                class="mr-5 mt-5 mb-5 hidden-xs-only"
                contain
                alt="Save Ad"
              />
              <v-img
                :src="require(`./pics/submitPhone5.png`)"
                class="mr-5 mt-5 mb-5 hidden-sm-and-up"
                contain
                alt="Save Ad"
              />
            </ul>
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
