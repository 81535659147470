<template>
  <v-bottom-sheet
    elevation="0"
    class="transparent"
    v-model="isShowBottomSheetLocal"
    style="z-index: 100000"
  >
    <v-card class="mx-auto" max-width="800" elevation="0">
      <MyCardTitle
        title="Select countries"
        @close-window="isShowBottomSheetLocal = false"
      >
      </MyCardTitle>
      <span
        >{{ selections.length }} {{ $t(`selected`) }}
        {{ maxLimit - selections.length }} {{ $t(`available`) }}</span
      >
      <v-container class="py-0">
        <v-row align="center" justify="start">
          <v-col
            v-for="(selection, i) in selections"
            :key="selection"
            class="shrink"
          >
            <v-chip
              :disabled="loading"
              close
              @click:close="selected.splice(i, 1)"
            >
              {{ selection }}
            </v-chip>
          </v-col>

          <v-col v-if="!allSelected" cols="12">
            <v-text-field
              outlined
              dense
              ref="search"
              v-model="search"
              full-width
              hide-details
              :label="$t('search')"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-divider v-if="!allSelected"></v-divider>
      <v-virtual-scroll :items="categories" :item-height="50" height="300">
        <template v-slot="{ item }">
          <v-list-item
            :key="item"
            :disabled="
              loading ||
              selected.includes(item) ||
              disabledCountries.includes(item)
            "
            @click="selectCountry(item)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!--   <v-list>
                <template v-for="item in categories">
                    <v-list-item v-if="!selected.includes(item)" :key="item.text" :disabled="loading"
                        @click="selected.push(item)">
                        <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>-->
      </v-virtual-scroll>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <MyBtn
          btnName="submitAd.confirm"
          @btn-clicked="next()"
          :iconName="mdiMap"
          :disabled="!selected.length"
        ></MyBtn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import MyCardTitle from "./MyCardTitle.vue";
import MyBtn from "./MyBtn.vue";
import { mdiMap } from "@mdi/js";
export default {
  name: "SelectMultipleCountries",
  props: [
    "isShowBottomSheet",
    "maxLimit",
    "selectedCountries",
    "disabledCountries",
  ],
  components: {
    MyCardTitle,
    MyBtn,
  },
  mounted: function () {
    this.init();
  },
  data: () => ({
    mdiMap: mdiMap,

    items: [],
    isShowBottomSheetLocal: false,
    loading: false,
    search: "",
    selected: [],
    virtualListKey: 0,
  }),
  watch: {
    selected() {
      this.search = "";
    },
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length;
    },
    categories() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selections() {
      const selections = [];

      for (const selection of this.selected) {
        selections.push(selection);
      }

      return selections;
    },
  },

  methods: {
    async init() {
      this.isShowBottomSheetLocal = this.isShowBottomSheet;
      for (let i = 0; i < this.$store.state.countries.length; i++) {
        if (this.$store.state.countries[i].title.toLowerCase() !== "all") {
          this.items.push(this.$store.state.countries[i].title);
        }
      }

      if (
        this.selectedCountries !== undefined &&
        this.selectedCountries.length > 0
      ) {
        this.selected = this.selectedCountries;
      }
    },

    selectCountry(item) {
      if (this.selections.length < this.maxLimit) {
        this.selected.push(item);
        this.virtualListKey++;
      } else {
        this.$emit("reached-maxads");
      }
    },

    next() {
      this.$emit("countries-selected", this.selected);
      this.isShowBottomSheetLocal = false;
    },
  },
};
</script>
