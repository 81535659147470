<template>
  <!--<!doctype html>-->
  <html xmlns="http://www.w3.org/1999/xhtml" lang xml:lang>
    <head>
      <meta charset="utf-8" />
      <meta
        name="Delete Your Account Guide"
        content="Delete Your Account Guide"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>{{ $t(`delete_account_guide`) }}</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body class="ml-1">
      <div class="ma-2">
        <h2 class="text-center">{{ $t(`delete_account_guide`) }}</h2>
        <!-- <div v-html="content"></div>-->
      </div>
      <p>
        {{ $t(`clickThe`) }} <b>{{ $t(`menu`) }}</b>
        {{ $t(`button`) }}
      </p>

      <v-img
        :src="require(`./pics/DeleteYourAccountGuide/menu.png`)"
        contain
        max-width="100px"
        alt="menu"
      />
      <br />
      <p>
        {{ $t(`click`) }} <b>{{ $t(`log`) }}</b
        >:
      </p>
      <v-img
        :src="require(`./pics/DeleteYourAccountGuide/logIn.png`)"
        contain
        max-width="400px"
        alt="Log in"
      />

      <br />
      <p>{{ $t(`enter`) }}</p>
      <v-img
        :src="require(`./pics/DeleteYourAccountGuide/enterCredentials.png`)"
        contain
        max-width="500px"
        alt="enter credentials"
      />
      <br />
      <p>
        {{ $t(`once`) }}
        <b>{{ $t(`deleteAccount`) }}</b> {{ $t(`follow`) }}
      </p>
      <v-img
        :src="require(`./pics/DeleteYourAccountGuide/deleteAccountButton.png`)"
        contain
        max-width="500px"
        alt="enter credentials"
      />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>

<script>
export default {
  name: "ThirdPartyLoginGuide.vue",
  components: {},
  metaInfo: {
    title: "Third Pary Login Guide",
    meta: [
      {
        name: "Third Pary Login Guide",
        content: "Third Pary Login Guide",
      },
    ],
  },
  data: () => ({
    imageWidth: "600px",
  }),
  mounted: async function () {},
  methods: {},
};
</script>
