<template>
  <v-dialog
    v-model="isShowMyEmailLocal"
    width="800"
    persistent
    :key="myEmailKey"
  >
    <v-card>
      <MyCardTitle
        :title="dialogTitle"
        @close-window="closeWindow()"
      ></MyCardTitle>
      <br />

      <v-card-text class="ma=2">
        <v-container>
          <v-text-field
            v-model="myFrom"
            :disabled="fromDisabled"
            :label="$t(`from`)"
            @input="checkIsValid()"
            counter="345"
            placeholder="Your Email"
            :rules="[rules.required, rules.email, rules.email345]"
            required
          >
          </v-text-field>

          <v-text-field
            v-show="showToField"
            v-model="toLocal"
            :disabled="true"
            :label="$t(`to`)"
            @input="checkIsValid()"
            counter="345"
            placeholder="To"
            :rules="[rules.required, rules.email, rules.email345]"
            required
          >
          </v-text-field>

          <v-text-field
            v-model="mySubject"
            :label="$t(`subject`)"
            @input="checkIsValid()"
            counter="120"
            :rules="[rules.required, rules.subject100]"
          >
          </v-text-field>

          <v-textarea
            v-model="message"
            :label="$t(`message`)"
            @input="checkIsValid()"
            counter="1000"
            :rules="[rules.required, rules.message1000]"
          />

          <v-row justify="center">
            <MyBtn
              btnName="send"
              :disabled="!isValid"
              :iconName="mdiEmailSendOutline"
              @btn-clicked="sendEmail()"
            >
            </MyBtn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <Loading :isLoading="isLoading" :message="messageLoader"></Loading>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    >
    </MyAcknowledge>
  </v-dialog>
</template>

<script>
import { mdiEmailSendOutline } from "@mdi/js";

import Config from "../config.js";
import Loading from "./Loading";
import MyAcknowledge from "./MyAcknowledge";
import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";

export default {
  name: "MyEmail",

  components: {
    Loading,
    MyAcknowledge,
    MyBtn,
    MyCardTitle,
  },

  props: [
    "adId",
    "dialogTitle",
    "isShowMyEmail",
    "from",
    "fromDisabled",
    "subject",
    "ownerGivenName",
    "to",
  ],

  data: () => ({
    isShowMyEmailLocal: false,
    myEmailKey: 0,

    mdiEmailSendOutline: mdiEmailSendOutline,

    tokens: null,
    accessTokenDecoded: {},

    myFrom: "",
    mySubject: "",
    message: "",

    isValid: false,

    isLoading: false,
    messageLoader: "",

    showAcknowledge: false,
    acknowledgeTitle: "Email Sending",
    acknowledgeMessage: "",
    acknowledgeOk: true,
    myAcknowledgeKey: 0,

    copyMyself: true,
    toLocal: "",
    showToField: false,

    rules: {
      required: (value) => value.length > 0 || "Required.",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      subject100: (value) =>
        value.length <= Config.EMAIL_SUBJECT_MAX_LENGTH ||
        "Max " + Config.EMAIL_SUBJECT_MAX_LENGTH + " characters",
      message1000: (value) =>
        value.length <= Config.EMAIL_MESSAGE_MAX_LENGTH ||
        "Max " + Config.EMAIL_MESSAGE_MAX_LENGTH + " characters",
    },
  }),

  mounted: async function () {
    this.init();
  },

  watch: {
    isShowMyEmail: function () {
      this.myEmailKey++;
    },
  },

  methods: {
    init() {
      this.isShowMyEmailLocal = this.isShowMyEmail;

      this.tokens = this.$store.state.tokens;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

      this.myFrom = this.from;

      let fromName = "Your Name";
      if (this.accessTokenDecoded.given_name !== undefined) {
        fromName = this.accessTokenDecoded.given_name;
      }

      if (this.ownerGivenName !== undefined || this.ownerGivenName !== null) {
        this.message = `Hi ${this.ownerGivenName}`;
      }

      if (this.accessTokenDecoded.given_name !== undefined) {
        this.message += `\n\n${fromName}`;
      }
      this.mySubject = this.subject;
      if (this.to != undefined) {
        this.showToField = true;
        this.toLocal = this.to;
      } else {
        this.showToField = false;
      }
    },

    closeWindow(obj) {
      this.$emit("close-window", obj);
    },

    checkIsValid() {
      let isValid = false;
      this.debug(
        "a " +
          (this.myFrom.length > 0) +
          ", " +
          Config.EMAIL_PATTERN.test(this.myFrom)
      );

      if (
        this.mySubject.length > 0 &&
        this.message.length > 3 &&
        this.myFrom.length > 0 &&
        Config.EMAIL_PATTERN.test(this.myFrom)
      ) {
        isValid = true;
      }

      this.isValid = isValid;
    },

    async sendEmailHelper(url, fetchData) {
      let ok = false;
      let message = "";

      try {
        this.debug(`sendEmailHelper() url = ${url}`);
        this.printJson(fetchData, "fetchData");
        let res = await fetch(url, fetchData);
        let jsonRes = await res.json();

        this.printJson(jsonRes, "sendEmailHelper() jsonRes");

        ok = jsonRes.ok;
        message = jsonRes.message;
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async sendEmail() {
      this.isLoading = true;

      let ok = false;
      this.debug("sendEmail() a");

      let payload = new FormData();
      payload.append("subject", this.mySubject);
      payload.append("message", this.message);

      if (this.dialogTitle == "Email Seller") {
        if (await this.isLoggedInOIDC()) {
          let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_SEND_EMAIL}`;

          let headers = {
            Authorization: `${this.accessTokenDecoded.typ} ${this.tokens.access_token}`,

            Accept: "application/json",
          };

          payload.append("adId", this.adId);

          /*
          let body = {
            adId: this.adId,
            subject: this.mySubject,
            message: this.message
          };
          */

          let fetchData = {
            method: "POST",
            headers: headers,
            body: payload,
          };

          let emailSellerResult = await this.sendEmailHelper(url, fetchData);
          ok = emailSellerResult.ok;

          if (ok === true) {
            this.acknowledgeMessage = "Email sending successful!";
            this.acknowledgeOk = true;
          } else {
            this.acknowledgeMessage = `Email sending failure: ${emailSellerResult.message}`;
            this.acknowledgeOk = false;
          }
        } else {
          this.acknowledgeMessage =
            "Not logged in! Please copy the URL, login then revisit the URL and try again";
          this.acknowledgeOk = false;
        }
      } else if (this.dialogTitle == "Contact Us") {
        // Rusaik hit your anonymous email to admin endpoint here.
        let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_CONTACT_US}`;

        this.debug(`sendEmail() url = ${url}`);

        let headers = {
          Accept: "application/json",
        };

        payload.append("from", this.myFrom);

        let fetchData = {
          method: "POST",
          headers: headers,
          body: payload,
        };

        let contactUsResult = await this.sendEmailHelper(url, fetchData);
        ok = contactUsResult.ok;

        if (ok === true) {
          this.acknowledgeMessage = "Email sending successful!";
          this.acknowledgeOk = true;
        } else {
          this.acknowledgeMessage = `Email sending failure: ${contactUsResult.message}`;
          this.acknowledgeOk = false;
        }
      } else {
        this.acknowledgeMessage = "Unknown Mode";
        this.acknowledgeOk = false;
      }

      let result = {
        ok: ok,
        message: this.acknowledgeMessage,
      };

      this.closeWindow(result);

      this.myAcknowledgeKey++;
      this.showAcknowledge = true;

      this.isLoading = false;

      return ok;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKey++;
    },
  },
};
</script>
