<template>
  <AdsListHelper :adType="adType" :userId="''"> </AdsListHelper>
</template>

<script>
import AdsListHelper from "./AdsListHelper";

import Config from "../config.js";

//import Loading from './Loading'

export default {
  name: "MyAds",
  components: {
    AdsListHelper,
    //    Loading
  },
  metaInfo: {
    title: "Search Ads",
    titleTemplate: "%s | My Ads",
    meta: [{ name: "description", content: "SEO Description" }],
  },
  data: () => ({
    adType: "",
  }),
  mounted: async function () {
    this.adType = Config.AD_TYPES_ENUM.MY_ADS;
    if ((await this.isLoggedInOIDC()) == false) {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {}, // methods
};
</script>
