<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>Raffle-MobilityGuru</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <div class="ma-5">
        <center>
          <h1>
            Raffle 1 - Terms and Conditions<MeSpeak
              size="40"
              :text="meSpeakText"
            ></MeSpeak>
          </h1>
        </center>
        <ol type="1">
          <li>
            The promoter is MobilityGuru a trading name registered under
            Longhorn Pty Ltd with no 167556836 a company duly incorporated in
            Queensland, Australia with it’s postal address at PO Box 29,
            Westcourt, 4870 Qld.
          </li>
          <li>
            The competition is open to residents of the Australia aged 18 years
            and over, except employees of MobilityGuru and their close relatives
            and anyone otherwise connected with the organisation or raffle.
          </li>
          <li>
            There is no entry fee and no purchase necessary to enter this
            raffle.
          </li>
          <li>
            To enter the raffle you need to use the free submits ad on the
            <a href="MobilityGuru.org">MobilityGuru.org</a> website.
          </li>
          <li>
            By entering this raffle, an entrant is indicating his/her agreement
            to be bound by these terms and conditions.
          </li>
          <li>Multiple entries will be accepted per person.</li>
          <li>
            Closing date for entry will be 23rd December at 10.00am. After this
            date the no further entries to the competition will be permitted.
          </li>
          <li>
            No responsibility can be accepted for entries not received for
            whatever reason.
          </li>
          <li>
            To enter the raffle is free and the rules of the raffle are as
            follows: you need to signup and submit at least one free ad on the
            <a href="MobilityGuru.org">MobilityGuru.org</a> website. The ad
            needs to have been published for minimum 7 days or the items/service
            must have been sold within 7 days of publishing. Except in so far
            that the ad was published within 7 days of the closing date and
            time, the entrants ad will be valid.
          </li>
          <li>
            The promoter reserves the right to cancel or amend the raffle and
            these terms and conditions without notice in the event of a
            catastrophe, war, civil or military disturbance, act of God or any
            actual or anticipated breach of any applicable law or regulation or
            any other event outside of the promoter’s control. Any changes to
            the raffle will be notified to entrants as soon as possible by the
            promoter.
          </li>
          <li>
            The promoter is not responsible for inaccurate prize details
            supplied to any entrant by any third party connected with this
            competition.
          </li>
          <li>
            There are three prizes as follows:
            <ul>
              <li>1st Prize $100</li>
              <li>2nd Prize $50</li>
              <li>3rd Prize $25</li>
            </ul>
            The prizes are not transferable.
          </li>
          <li>
            Winners will be chosen at random by software, from all entries
            received and verified by Promoter and or its agents.
          </li>
          <li>
            The winner will be notified by email. If the winner cannot be
            contacted or do not claim the prize after 3 months of notification,
            we reserve the right to withdraw the prize from the winner and pick
            a replacement winner.
          </li>
          <li>
            The promoter will notify the winner when and where the prize can be
            collected / is delivered.
          </li>
          <li>
            The promoter’s decision in respect of all matters to do with the
            raffle will be final and no correspondence will be entered into.
          </li>
          <li>
            By entering this raffle, an entrant is indicating his/her agreement
            to be bound by these terms and conditions.
          </li>
          <li>
            The raffle and these terms and conditions will be governed by
            Queensland law and any disputes will be subject to the exclusive
            jurisdiction of the courts of Queensland, Australia.
          </li>
          <li>
            The winner agrees to the use of his/her first name and raffle no.
            Any personal data relating to the winner or any other entrants will
            be used solely in accordance with current Queensland data protection
            legislation and will not be disclosed to a third party without the
            entrant’s prior consent.
          </li>
          <li>
            The winner’s name will be available on the 24th of December at
            10.00am and an email will be sent to the winners verified email
            registered with MobilityGuru.
          </li>
          <li>
            Entry into the raffle will be deemed as acceptance of these terms
            and conditions.
          </li>
          <li>
            The information provided will be used in conjunction with the
            following Privacy Policy found at
            <a href="http://mobilityguru.org">http://mobilityguru.org</a>,
          </li>
        </ol>
      </div>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "./MeSpeak";
export default {
  name: "About",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Raffle Mobility Guru",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Raffles",
      },
      {
        name: "keywords",
        content: "raffles, ticket, MobilityGuru, draw",
      },
    ],
  },
  data: () => ({
    meSpeakText: "",
  }),
  mounted: async function () {
    this.loadMeSpeakText();
  },
  methods: {
    async loadMeSpeakText() {
      this.meSpeakText = `Raffle 1 - Terms and Conditions

        1.	The promoter is MobilityGuru a trading name registered under Longhorn Pty Ltd with no 167556836 a company duly incorporated in Queensland, Australia with it’s postal address at PO Box 29, Westcourt, 4870 Qld.
        2.	The competition is open to residents of the Australia aged 18 years and over, except employees of MobilityGuru and their close relatives and anyone otherwise connected with the organisation or raffle.
        3.	There is no entry fee and no purchase necessary to enter this raffle.
        4.	To enter the raffle you need to use the free submits ad on the [MobilityGuru.com.au](MobilityGuru.com.au) website.
        5.	By entering this raffle, an entrant is indicating his/her agreement to be bound by these terms and conditions.
        6.	Multiple entries will be accepted per person.
        7.	Closing date for entry will be 23rd December at 10.00am. After this date the no further entries to the competition will be permitted.
        8.	No responsibility can be accepted for entries not received for whatever reason.
        9.	To enter the raffle is free and the rules of the raffle are as follows: you need to signup and submit at least one free ad on the [MobilityGuru.com.au](MobilityGuru.com.au) website. The ad needs to have been published for minimum 7 days or the items/service must have been sold within 7 days of publishing. Except in so far that the ad was published within 7 days of the closing date and time, the entrants ad will be valid.
        10. The promoter reserves the right to cancel or amend the raffle and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual or anticipated breach of any applicable law or regulation or any other event outside of the promoter’s control. Any changes to the raffle will be notified to entrants as soon as possible by the promoter.
        11. The promoter is not responsible for inaccurate prize details supplied to any entrant by any third party connected with this competition.
        12. There are three prizes as follows:
            * 1st Prize $100
          * 2nd Prize $50
          * 3rd Prize $25
        The prizes are not transferable.
        13. Winners will be chosen at random by software, from all entries received and verified by Promoter and or its agents.
        14. The winner will be notified by email. If the winner cannot be contacted or do not claim the prize after 3 months of notification, we reserve the right to withdraw the prize from the winner and pick a replacement winner.
        15. The promoter will notify the winner when and where the prize can be collected / is delivered.
        16. The promoter’s decision in respect of all matters to do with the raffle will be final and no correspondence will be entered into.
        17. By entering this raffle, an entrant is indicating his/her agreement to be bound by these terms and conditions.
        18. The raffle and these terms and conditions will be governed by Queensland law and any disputes will be subject to the exclusive jurisdiction of the courts of Queensland, Australia.
        19. The winner agrees to the use of his/her first name and raffle no. Any personal data relating to the winner or any other entrants will be used solely in accordance with current Queensland data protection legislation and will not be disclosed to a third party without the entrant’s prior consent.
        20. The winner’s name will be available on the 24th of December at 10.00am and an email will be sent to the winners verified email registered with MobilityGuru.
        21. Entry into the raffle will be deemed as acceptance of these terms and conditions.
        22. The information provided will be used in conjunction with the following Privacy Policy found at (http://mobilityguru.org),`;
    },
  },
};
</script>
