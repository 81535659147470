//require('dotenv').config()

export default Object.freeze({
  IS_DEV: process.env.NODE_ENV === "development",

  KINTO_SERVER_IP: process.env.VUE_APP_KINTO_SERVER_IP,

  APP_SERVER_IP: process.env.VUE_APP_APP_SERVER_IP,

  NODE_SERVER_IP: process.env.VUE_APP_NODE_SERVER_IP,

  // only needed in production
  MATOMO_SERVER_IP: process.env.VUE_APP_MATOMO_SERVER_IP,
  MATOMO_SITE_ID: process.env.VUE_APP_MATOMO_SITE_ID,

  KINTO_BASIC_USER: process.env.KINTO_BASIC_USER,
  KINTO_BASIC_PASS: process.env.KINTO_BASIC_PASS,

  KEYCLOAK_SERVER_URL: process.env.VUE_APP_KEYCLOAK_SERVER_URL,
  KEYCLOAK_CLIENT_REALM: process.env.VUE_APP_KEYCLOAK_CLIENT_REALM,
  KEYCLOAK_CLIENT_ID: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,

  // backend00 routes
  ASSIGN_SALES_ROLE: "/assignSalesRole",
  ADD_REPLY: "/addReply",
  CHECK_EMAIL_UNSUBSCRIBE_CODE: "/checkEmailUnsubscribeCode",
  //CHECK_PAYMENT_CLEARED: "/checkPaymentCleared",
  CHECK_EMAIL_VERIFIED: "/checkEmailVerified",
  CREATE_ROTATING_BANNER_AD: "/createRotatingBannerAd",
  CREATE_GET_STREAM_CHANNEL_FOR_AD: "/createGetStreamChannelForAd",
  CONVERT_PRICES_FOR_COUNTRIES: "/convertPricesForCountries",
  CHECK_FOR_TRANSLATION_AVAILABILITY: "/checkForTranlationAvailability",
  SEND_DEBUG_EMAIL: "/sendDebugEmail",
  DELETE_AD: "/deleteAd2",
  DELETE_REPLY: "/deleteReply",
  DELETE_ALL_ADFILES_FOR_SINGLE_AD: "/deleteAllAdFilesForSingleAd",
  DELETE_ADFILES_FOR_SINGLE_AD: "/deleteAdFilesForSingleAd",
  DELETE_RAFFLES_SUPERADMIN: "/deleteRafflesSuperAdmin",
  DELETE_RAFFLE_TICKET: "/deleteRaffleTicket",
  DELETE_ROTATING_BANNER_AD_IMAGE: "/deleteRotatingAdImage",
  DELETE_TOP_AD: "/deleteTopAd",
  DELETE_USER_ROTATING_BANNER_ADS: "/deleteUserRotatingBannerAds",
  DELETE_USER: "/deleteUser",
  DELETE_USER_ADS: "/deleteUserAds",
  DELETE_AD_FROM_TRANSLATED_ADS: "/deleteAdFromTranslatedAds",
  DELETE_FORUM_POST: "/deleteForumPost",
  DELETE_ARTICLE: "/deleteArticle",
  EDIT_PAID_ADS_PRICING: "/editPaidAdsPricing",
  EDIT_TOP_AD_PRICING: "/editTopAdPricing",
  EDIT_ROTATING_BANNER_AD_PRICING: "/editRotatingBannerAdPricing",
  GET_APP_DATA: "/getAppData",
  GET_ALL_TOP_ADS_PRICING: "/getAllTopAdsPricing",
  GET_AVAILABLE_TOP_AD_CATEGORIES: "/getAvailableTopAdCategories",
  GET_ALL_ROTATING_BANNER_ADS_PRICING: "/getAllRotatingBannerAdsPricing",
  GET_ALL_PAID_ADS_PRICING: "/getAllPaidAdsPricing",
  GET_PAID_ADS_PRICING_BY_COUNTRY: "/getPaidAdsPricingByCountry",
  GET_ROTATING_BANNER_AD_PRICING: "/getRotatingBannerAdPricing",
  GET_ADS: "/getAds",
  GET_AD_RECORD_BY_ADID: "/getAdRecordByAdId",
  GET_FAVOURITE_ADS_BY_IDS: "/getFavouriteAdsByIds",
  //GET_AD_AND_ALL_ADIDS: "/getAdAndAllAdIds",
  GET_SUPERADMIN_ID: "/getSuperadminId",
  GET_COUNTRY_CODE: "/getPhonePrefix",
  GET_COLLECTION_DATA_BY_NAME: "/getCollectionDataByName",
  GET_COUNTRIES: "/getCountries",
  GET_COUNTRY_BY_IP: "/getCountryByIP",
  GET_CURRENCY_CODE: "/getCurrencyCode",
  GET_RECORD: "/getRecord",
  GET_STATES: "/getStatesByCountry",
  GET_NEW_UNIQUE_RAFFLE_TICKET: "/getNewUniqueRaffleTicket",
  GET_PROFILES: "/getProfilesRecord",
  GET_STATS: "/getStats",
  GET_COUNTRY_BY_IP_STATS: "/getCountryByIPStats",
  GET_ROTATING_BANNER_AD: "/getRotatingBannerAd",
  GET_ROTATING_BANNER_ADS_ALL: "/getRotatingBannerAdsAll",
  GET_USER_PROFILE_DATA: "/getUserProfileData",
  GET_ALL_ADS_VALUE: "/getAlladsValueFilteredByCountry",
  GET_LABEL_AND_DATASETS: "/getlabelAndDataSets",
  INIT_KINTO_PROFILE: "/initKintoProfile",
  INIT_USER_PROFILE: "/initUserProfile",
  GET_TERMS: "/getTerms",
  GET_AIRDROP_CONTENT: "/getAirdropContent",
  GET_COUNTRY_NUM_OF_ADS_OBJECT: "/getCountryNumOfAdsObject",
  GET_ALL_COUNTRY_LANGUAGE_TRANSLATIONS: "/getAllCountryLanguageTranslations",
  GET_ALL_USER_PROFILES: "/getAllUserProfiles",
  GUESS_LANGUAGE: "/guessLanguage",
  GET_AD_VIEWS_MONTHLY: "/getAdViewsMonthly",
  GET_AD_VIEWS_WEEKLY: "/getAdViewsWeekly",
  GET_ALL_COUNTRY_CRYPTO_ENABLED_STATUS: "/getAllCountryCryptoEnabledStatus",
  GET_ARTICLE_BY_ID: "/getArticleById",
  GET_TOP_ADS_METRICS: "/getTopAdsMetrics",
  GET_USER_REGISTRATIONS_METRICS: "/getUserRegistrationsMetrics",
  GET_ROTATING_BANNER_ADS_CLICKS_METRICS: "/getRotatingBannerAdsClicksMetrics",
  GET_RAFFLE_META_DATA: "/getRaffleMetaData",
  GET_RAFFLE_TICKETS_BY_NAME: "/getRaffleTickets",
  GET_MATOMO_METRICS: "/getMatomoMetrics",
  GET_NEW_DAILY_ADS_REPORT_TIME: "/getNewDailyAdsReportTime",
  GET_FORUM_POSTS: "/getForumPosts",
  GET_FORUM_POST_BY_ID: "/getForumPostById",
  GET_ARTICLES: "/getArticles",
  GET_TOP_AD_PAYMENT_STATUS: "/getTopAdPaymentStatus",
  GET_CHATS: "/getChats",
  GET_CHAT_ID: "/getChatId",
  GET_CHAT_MESSAGES: "/getChatMessages",
  KEYCLOAK_PROFILE_UPDATE: "/keycloakProfileUpdate",
  KEYCLOAK_CHANGE_PASSWORD: "/keycloakChangePassword",
  KEYCLOAK_LOGOUT: "/keycloakLogout",
  KEYCLOAK_DELETE_USER: "/keycloakDeleteUser",
  //KEYCLOAK_SEND_VERIFY_EMAIL: '/keycloakSendVerifyEmail',
  KEYCLOAK_VERIFY_EMAIL_CODE: "/keycloakVerifyEmailCode",
  KEYCLOAK_SET_EMAIL_VERIFIED: "/keycloakSetEmailVerified",
  KEYCLOAK_SEND_EMAIL: "/keycloakSendEmail",
  KEYCLOAK_CONTACT_US: "/keycloakContactUs",
  GET_INIT_DATA_ON_BACKGROUND: "/getInitDataOnBackground",
  MODIFY_AD: "/modifyAd",
  NEW_TOP_AD: "/newTopAd",
  NEW_PAID_ADS_RECORD: "/newPaidAdsRecord",
  NEW_FORUM_POST: "/newForumPost",
  NEW_ARTICLE: "/newArticle",
  NEW_AIRDROP_FORM: "/newAirdropForm",
  PICK_THREE_WINNERS: "/pickThreeWinners",
  SEND_VERIFY_EMAIL_CODE_FROM_AIRDROP: "/sendVerifyEmailCodeFromAirdrop",
  SEND_VERIFY_EMAIL_CODE_FROM_PROFILE: "/sendVerifyEmailCodeFromProfile",
  SAVE_AD: "/saveAd",
  SAVE_ARTICLE: "/saveArticle",
  SAVE_TOPIC: "/saveTopic",
  SAVE_AD_IN_ENGLISH: "/saveAdInEnglish",
  SEND_VERIFY_EMAIL_CODE: "/sendVerifyEmailCode",
  PICK_RAFFLE_WINNERS: "/pickRaffleWinners",
  RETRIEVE_NEW_ADS: "/retrieveNewAds",
  SAVE_FCM_USER_TOKEN: "/saveFcmUserToken",
  SEND_NOTIFICATIONS_TO_ALL_USERS: "/sendNotificationsToAllUsers",
  SUPERADMIN_SEND_EMAIL: "/superadminSendEmail",
  SUPERADMIN_DELETE_USER: "/superadminDeleteUser",
  SUPERADMIN_CREATE_NEW_USER: "/superadminCreateNewUser",
  SUPERADMIN_SEND_INIT_PASSWORDS: "/sendInitPasswords",
  SUPERADMIN_ASSIGN_COUNTRY_MANAGER_ROLE: "/superadminAssignCountryManagerRole",
  SUPERADMIN_BULK_EMAIL: "/superadminBulkEmail",
  SUPERADMIN_REMOVE_COUNTRY_MANAGER_ROLE: "/superadminRemoveCountryManagerRole",
  SUPERADMIN_ASSIGN_DEV_ROLE: "/superadminAssignDevRole",
  SUPERADMIN_REMOVE_DEV_ROLE: "/superadminRemoveDevRole",
  SUPERADMIN_ASSIGN_MODERATOR_ROLE: "/superadminAssignForumModeratorRole",
  SUPERADMIN_REMOVE_MODERATOR_ROLE: "/superadminRemoveForumModeratorRole",

  SUPERADMIN_NEW_INFLUENCER: "/superadminNewInfluencer",
  SUPERADMIN_UPDATE_INFLUENCER: "/superadminUpdateInfluencer",
  SUPERADMIN_DELETE_INFLUENCER: "/superadminDeleteInfluencer",
  SUPERADMIN_GET_ALL_INFLUENCERS: "/superadminGetAllInfluencers",
  SUPERADMIN_GET_ALL_AIRDROPS: "/superadminGetAllAirdrops",
  SUPERADMIN_VERIFY_AIRDROP_TWITTER_ID: "/superadminVerifyAirdropTwitterId",
  SUPERADMIN_GET_AIRDROP_WINNERS: "/superadminGetAirdropWinners",
  SUPERADMIN_PICK_MEGA_DRAW_AIRDROP_WINNERS:
    "/superadminPickMegaDrawAirdropWinners",
  TOGGLE_COUNTRY_LANGUAGE_TRANSLATION_STATUS:
    "/toggleCountryLanguageTranslationStatus",
  TOGGLE_COUNTRY_CRYPTO_ENABLED_STATUS: "/toggleCryptoEnabledStatus",

  REMOVE_SALES_ROLE: "/removeSalesRole",
  HAS_EFFECTIVE_ROLE: "/hasEffectiveRole",
  UPLOAD_FILES_FOR_AD: "/uploadFilesForAd",
  UPLOAD_FILES_FOR_ROTATING_BANNER_AD: "/uploadFilesForRotatingBannerAd",
  UPDATE_KINTO_PROFILE: "/updateKintoProfile",
  UPDATE_NUM_ADS_AND_IMAGES: "/updateNumAdsAndImages",
  UPDATE_ROTATING_BANNER_AD: "/updateRotatingBannerAd",
  UPDATE_ROTATING_BANNER_AD_FIELD: "/updateRotatingBannerAdField",
  UPDATE_AD: "/updateAd",
  UPDATE_PROFILE: "/updateProfile",
  UPDATE_TOP_AD: "/updateTopAd",
  UPDATE_REPLY: "/updateReply",
  UPDATE_ROTATING_BANNER_AD_CLICKS: "/increaseRotatingBannerAdClicks",
  UPDATE_TOP_AD_CLICKS: "/increaseTopAdClicks",
  UPDATE_NEW_DAILY_ADS_REPORT_TIME: "/updateNewDailyAdsReportTime",
  UPDATE_FORUM_POST: "/updateForumPost",
  UPDATE_ARTICLE: "/updateArticle",
  //UPDATE_AD_VIEWS: "/increaseAdViews",
  UPDATE_FAVOURITE_ADS: "/updateFavouriteAds",
  VERIFY_EMAIL_CODE: "/verifyEmailCode",

  GET_ABOUT: "/getAbout",
  GET_CAREERS: "/getCareers",
  GET_PRIVACY_POLICY: "/getPrivacyPolicy",
  GET_LABELS: "/getLabels",
  UPDATE_RAFFLE_META: "/updateRaffleMeta",
  JOIN_STREAM: "/isUserJoinedStream",

  NEW_INVOICE: "/newInvoice",
  GET_INVOICE: "/getInvoice",
  DELETE_INVOICE: "/deleteInvoice",
  UPDATE_INVOICE: "/updateInvoice",
  GET_DATA_FOR_NEW_INVOICE: "/getDataForNewInvoice",
  GET_ALL_INVOICES: "/getAllInvoices",

  SUPERADMIN_ROLE_STRING: "superadmin",
  COUNTRY_MANAGER_ROLE: "countrymanager",
  SALES_ROLE: "sales",
  DEV_ROLE: "dev",
  FORUM_MODERATOR_ROLE: "ForumModerator",

  ADMIN_USER_ID: "admin",

  PASSWORD_MIN_LENGTH: 6,
  PASSWORD_MAX_LENGTH: 64,

  BUCKET_MOBILITY_GURU_NAME: "MobilityGuru",
  COLLECTION_PROFILES_NAME: "Profiles",
  COLLECTION_PROFILES_PERMISSIONS: {
    "record:create": ["system.Authenticated"],
    write: ["account:admin"],
  },
  COLLECTION_ADS_NAME: "Ads",
  COLLECTION_ADS_PERMISSIONS: {
    read: ["system.Everyone"],
    "record:create": ["system.Authenticated"],
    write: ["account:admin"],
  },
  COLLECTION_FORUM_NAME: "Forum",
  KINTO_COLLECTION_ARTICLES_NAME: "Articles",
  KINTO_COLLECTION_RAFFLES_NAME: "Raffles",
  COLLECTION_ROTATING_BANNER_ADS_NAME: "RotatingBannerAds",
  COLLECTION_TOP_ADS_NAME: "TopAds",

  RAFFLE_RECORD_ID: "Raffle202012",

  // routes
  ROUTE_ABOUT: "/About",
  ROUTE_AD_VIEW: "/ad-view",
  ROUTE_ARTICLES: "/articles",
  ROUTE_ARTICLE_VIEW: "/article-view",
  ROUTE_ARTICLE_CELL: "/article-cell",
  ROUTE_AIRDROP_FORM: "/airdrop-form",
  ROUTE_CAREERS: "/Careers",
  ROUTE_DELETE_YOUR_ACCOUNT_GUIDE: "/delete-your-account-guide",
  ROUTE_EDIT_AD: "/edit-ad",
  ROUTE_EDIT_ARTICLE: "/edit-article",
  ROUTE_EDIT_INVOICE: "/edit-invoice",
  ROUTE_FAQ: "/FAQ",
  ROUTE_FAVOURITE_ADS: "/favourite-ads",
  ROUTE_INVOICE: "/invoice",
  ROUTE_INVOICES: "/invoices",
  ROUTE_FORUM: "/forum",
  ROUTE_HOME: "/home",
  ROUTE_LIVE_ADS: "/live-ads",
  ROUTE_LOGIN: "/login",
  ROUTE_MY_ADS: "/my-ads",
  ROUTE_MY_ARTICLES: "/my-articles",
  ROUTE_POST_NEW_TOPIC: "/post-new-topic",
  ROUTE_PRIVACY: "/Privacy",
  ROUTE_PROFILE: "/profile",
  ROUTE_SALES_DASHBOARD: "/sales",
  ROUTE_RAFFLE: "/raffle",
  ROUTE_SUBMIT_AD: "/submit-ad",
  ROUTE_SUBMIT_ARTICLE: "/submit-article",
  ROUTE_SUBMIT_ARTICLE_HELPER: "/submit-article-helper",
  ROUTE_SUBMIT_INVOICE: "/submit-invoice",
  ROUTE_SUPERADMIN_DASHBOARD: "/superadmin",
  ROUTE_TERMS: "/Terms",
  ROUTE_TEST: "/Test",
  ROUTE_THIRD_PARTY_LOGIN_GUIDE: "/third-party-login-guide",
  ROUTE_USER_ADS: "/user-ads",
  ROUTE_WELCOME: "/",
  ROUTE_COUNTRYMANAGER_DASHBOARD: "/countrymanager",
  ROUTE_DEV_DASHBOARD: "/devDashboard",
  ROUTE_WALLET: "/wallet",
  ROUTE_UNSUBSCRIBE: "/unsubscribe",
  ROUTE_ROTATING_BANNER_SETUP: "/rotating-banner-setup",
  ROUTE_TOKEN_GUIDE: "/GURUGuide",
  ROUTE_MESSAGES: "/messages",
  ROUTE_PURCHASE_ADS: "/purchase-ads",

  MAIN_CATEGORIES: [
    "Mobility Transport",
    "Assistive Technology",
    "Bathroom & Kitchen",
    "Consumables",
    "Electronics",
    "Furniture",
    "Jobs",
    "Kids",
    "Real Estate",
    "Services",
    "Travel",
    "Miscellaneous",
  ],

  MAIN_CATEGORIES_ENUM: {
    MOBILITY_TRANSPORT: 0,
    ASSISTIVE_TECHNOLOGY: 1,
    "BATHROOM_&_KITCHEN": 2,
    CONSUMABLES: 3,
    ELECTRONICS: 4,
    FURNITURE: 5,
    JOBS: 6,
    KIDS: 7,
    REAL_ESTATE: 8,
    SERVICES: 9,
    TRAVEL: 10,
    MISCELLANEOUS: 11,
  },

  SUB_CATEGORIES_LIST: [
    [
      "Boats",
      "Camper Vans",
      "Caravans",
      "Cars",
      "Mobility Scooters",
      "Tricycles",
      "Wheelchairs",
      "Wheelchair & Scooter Parts/Accs",
      "Other Vehicles",
    ],
    [
      "Canes",
      "Crutches",
      "Hoists",
      "Platforms",
      "Ramps",
      "Swivel Chair",
      "Walkers",
      "Breathing Equipment",
      "Other Assistive Technology",
    ],
    [
      "Appliances",
      "Shower Chairs",
      "Commode Accessories",
      "Risers",
      "Dining Items",
      "Taps",
      "Other Bathroom & Kitchen",
    ],
    [
      "Disposables",
      "Gloves",
      "Handwash",
      "Mats",
      "Perfumes",
      "Sanitizer",
      "Wipes",
      "Other Consumables",
    ],
    ["Computers", "Tablets", "Telephones", "TVs", "Other Electronics"],
    [
      "Beds",
      "Chairs",
      "Home Lifts",
      "Massage Chairs",
      "Stair Lifts",
      "Tables",
      "Other Furniture",
    ],
    [
      "Admin & Accounts",
      "Elderly Care Assistant",
      "Food & Beverages",
      "Medical & Healthcare",
      "Personal Care",
      "Sales",
      "Social Worker",
      "Support Worker",
      "Transportation",
      "Other Jobs",
    ],
    [
      "Toys & Games",
      "Stroller",
      "Kids Walker",
      "Clothes",
      "Shoes",
      "Car seat",
      "Other Kids",
    ],
    ["For Sale", "Rental", "Wanted"],
    [
      "Domestic Cleaning",
      "Electricians",
      "Handyman",
      "Health & Wellbeing",
      "Lawn Maintenance",
      "Plumbing",
      "Physio & Rehab",
      "Other Services",
    ],
    ["Accommodation", "Cruises", "Holidays", "Tours", "Other Travel"],
  ],

  JOB_TYPES: ["Casual", "Commission", "Full-Time", "Part-Time", "Volunteer"],

  ADVERTISED_BYS: ["Agency", "Private"],

  SALARY_TYPES: ["Annual", "Commission", "Hourly", "Volunteer"],

  HERE_GEOCODING: process.env.VUE_APP_HERE_GEOCODING,

  HERE_API_KEY: process.env.VUE_APP_HERE_API_KEY,

  HERE_APP_ID: process.env.VUE_APP_HERE_APP_ID,

  BACKEND00_FILES_URL: process.env.VUE_APP_BACKEND00_FILES_URL,

  // Phone
  COUNTRY_OZ: "Australia +61",

  TITLE_MAX: 30,

  EMAIL_PATTERN:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  // https://ihateregex.io/expr/url/
  URL_PATTERN:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/,

  EMAIL_MAX: 345,
  AD_DESC_MAX: 1000,
  // ARTICLE_DESC_MAX: 1000,

  MERCHANT_MAX: 100,

  MAX_RANDOM_ADS: 16,

  MAX_FILE_SIZE_BYTES: 358400, //256000, //153600 ,//61440 //4194304,

  EMAIL_VERIFICATION_LENGTH: 6,

  // seconds before JWT expiry that'll trigger a refresh 10
  OIDC_REFRESH_TOKEN_BUFFER_SECS: 10,

  EMAIL_SUBJECT_MAX_LENGTH: 120,
  EMAIL_MESSAGE_MAX_LENGTH: 1000,

  GIVEN_NAME: "given_name",
  FAMILY_NAME: "family_name",

  FORUM_CATEGORIES: ["How-Tos", "Feature Requests", "Miscellaneous"],

  SWEAR_WORDS: [
    "anal",
    "anus",
    "arse",
    "ass",
    "ballsack",
    "balls",
    "bastard",
    "bitch",
    "biatch",
    "bloody",
    "blowjob",
    "blow job",
    "bollock",
    "bollok",
    "boner",
    "boob",
    "bugger",
    "bum",
    "butt",
    "buttplug",
    "clitoris",
    "cock",
    "coon",
    "crap",
    "cunt",
    "damn",
    "dick",
    "dildo",
    "dyke",
    "fag",
    "feck",
    "fellate",
    "fellatio",
    "felching",
    "fuck",
    "f u c k",
    "fudgepacker",
    "fudge packer",
    "flange",
    "Goddamn",
    "God damn",
    "hell",
    "homo",
    "jerk",
    "jizz",
    "knobend",
    "knob end",
    "labia",
    "lmao",
    "lmfao",
    "muff",
    "nigger",
    "nigga",
    "omg",
    "penis",
    "piss",
    "poop",
    "prick",
    "pube",
    "pussy",
    "queer",
    "scrotum",
    "sex",
    "shit",
    "s hit",
    "sh1t",
    "slut",
    "smegma",
    "spunk",
    "tit",
    "tosser",
    "turd",
    "twat",
    "vagina",
    "wank",
    "whore",
    "wtf",
  ],

  AD_TYPES_ENUM: {
    MY_ADS: 0,
    PUBLIC_ADS: 1,
    USER_ADS: 2,
  },

  ARTICLE_TYPES_ENUM: {
    MY_ARTICLES: 0,
    PUBLIC_ADS: 1,
    USER_ADS: 2,
  },

  ROLE_TYPE_ENUM: {
    SUPERADMIN: 0,
    COUNTRYMANAGER: 1,
    SALES: 2,
    DEV: 3,
  },

  IMAGE_MAX_WIDTH: process.env.VUE_APP_IMAGE_MAX_WIDTH, //640,//480,//220, //640 -> 4
  IMAGE_MAX_HEIGHT: process.env.VUE_APP_IMAGE_MAX_HEIGHT, //480,//360,//165, //480-> 3

  MAX_ADS: 20,
  //PASSWORD_MIN_LENGTH : 6

  PAYPAL_SANDBOX_CLIENT_ID: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT_ID,

  PAYPAL_PRODUCTION_CLIENT_ID: process.env.VUE_APP_PAYPAL_PRODUCTION_CLIENT_ID,

  VUE_APP_ADS_PER_PAGE: 20,

  ROTATING_BANNER_AD_VALID_MONTHS: 1,

  FORM_GROUPS: [
    "DC",
    "Crypto Caravan",
    "Crypto Talkz",
    "MobilityGuru",
    "Friend",
    "Influencer",
    "Other",
  ],
  VUE_APP_MAX_AIRDROP_FORMS: Number(process.env.VUE_APP_MAX_AIRDROP_FORMS),

  RECAPTCHA_SITE_KEY: process.env.VUE_APP_RECAPTCHA_SITE_KEY,

  RECAPTCHA_SECRET_KEY: process.env.VUE_APP_RECAPTCHA_SECRET_KEY,

  GOOGLE_TRANSLATE_API_MAX_CHARACTERS: Number(
    process.env.VUE_APP_GOOGLE_TRANSLATE_API_MAX_CHARACTERS
  ),

  GOOGLE_TRANSLATE_API_CALL_INTERVAL: Number(
    process.env.VUE_APP_GOOGLE_TRANSLATE_API_CALL_INTERVAL
  ),

  ME_SPEAK_LOCALES: {
    ca: "ca",
    cs: "cs",
    de: "de",
    el: "el",
    en: "en/en",
    eo: "eo",
    es: "es",
    //"la": "es-la",
    fi: "fi",
    fr: "fr",
    hu: "hu",
    it: "it",
    kn: "kn",
    la: "la",
    lv: "lv",
    nl: "nl",
    pl: "pl",
    pt: "pt",
    ro: "ro",
    sk: "sk",
    sv: "sv",
    tr: "tr",
    //zh: "zh",
  },

  GOOGLE_TRANSLATE_COOKIE_NAME:
    process.env.VUE_APP_GOOGLE_TRANSLATE_COOKIE_NAME,

  GOOGLE_TRANSLATE_COOKIE_DOMAINS:
    process.env.VUE_APP_GOOGLE_TRANSLATE_COOKIE_DOMAINS,

  GOOGLE_TRANSLATE_COOKIE_DOT_DOMAIN:
    process.env.VUE_APP_GOOGLE_TRANSLATE_COOKIE_DOT_DOMAIN,

  MG_ICON_COLOURS: [
    "#3B727E",
    "#BE37F3",
    "#E53B7A",
    "#97d45f",
    "#ff6251",
    "#4670ba",
    "#5e30eb",
    "#e4ef65",
    "#ff8549",
    "#fecb3e",
    "#ffb43f",
    "#fef76b",
  ],

  STREAM_API_KEY: process.env.VUE_APP_STREAM_API_KEY,

  STREAM_CHATS_PER_SCREEN: 10,

  STREAM_CHATS_OFFSET: 10, //use for pagination of chats

  STREAM_MESSAGES_PER_SCREEN: 20,

  STREAM_MESSSAGE_MAX: 5000,

  DEPAY_API_KEY: process.env.VUE_APP_DEPAY_API_KEY,

  GURU_TOKEN_ADDRESS: process.env.VUE_APP_GURU_TOKEN_ADDRESS,

  ADMIN_WALLET_ADDRESS: process.env.VUE_APP_ADMIN_WALLET_ADDRESS,

  MONTH_NAMES: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  invoiceTypes: {
    SELLER: 0,
    BUYER: 1,
  },

  FCM_TOKEN_TYPES: ["fcmWeb", "fcmAndroid", "fcmIos"],

  FIREBASE_MESSAGING_KEY_PAIR: process.env.VUE_APP_FIREBASE_MESSAGING_KEY_PAIR,

  FIREBASE_API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,

  FIREBASE_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,

  FIREBASE_PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,

  FIREBASE_STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,

  FIREBASE_MESSAGING_SENDER_ID:
    process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,

  FIREBASE_APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,

  PAID_ADS_VALID_DAYS: process.env.VUE_APP_PAID_ADS_VALID_DAYS,

  keycloak: null,
});
