<template>
  <!--<!doctype html>-->
  <html xmlns="http://www.w3.org/1999/xhtml" lang xml:lang>
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>Careers-MobilityGuru-2019</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <div class="ma-2">
        <h3 class="text-center" id="careers">
          Careers<MeSpeak size="40" :text="meSpeakText"></MeSpeak>
        </h3>
        <!-- <div v-html="content"></div>-->
      </div>
      <h5 id="sales-agents"></h5>
      <p>
        MobilityGuru is a new marketplace where products and services can be
        advertised for free and minimal cost for some categories. Marketplace
        items cater for the support to the 12% of the global population that
        have a disability and elderly.
      </p>
      <p>
        We are keen to provide this opportunity to people on an attractive
        commission basis, with benefits of working from home or anywhere
        worldwide. Currently we seek people to assist in promoting for the
        Australian market place. The site name is mobilityguru.org.
      </p>
      <p>
        All training, guidance and target market will be provided. The tools
        required are computer/tablet skills, internet and telephone connection.
      </p>
      <p>Please contact Anthony at mobilityguru.a@gmail.com.</p>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </body>
    <!-- <Loading :isLoading="isLoading"></Loading>-->
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "./MeSpeak";
//import Loading from "./Loading.vue";
export default {
  name: "Careers",
  components: {
    MeSpeak,
    //Loading,
  },
  data: () => ({
    meSpeakText: "",
    title: "",
    content: "",
    isLoading: false,
  }),
  metaInfo: {
    title: "Mobility Guru Careers",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Careers",
      },
      {
        name: "keywords",
        content:
          "careers, jobs, opportunities, work from home, remote, work from anywhere",
      },
    ],
  },
  mounted: async function () {
    this.init();
  },

  /*watch: {
    "$store.state.selectedLanguageCode": async function () {
      await this.init();
    },
  },*/

  methods: {
    async init() {
      /* this.isLoading = true;
      let res = await this.getCareers(this.$i18n.locale);
      this.printJson(res);
      this.title = res.translatedContent.title;
      this.content = res.translatedContent.content;
      this.meSpeakText = `${this.title}`;
      this.meSpeakText += `${this.content}`;
      this.isLoading = false;*/
      this.meSpeakText = `Careers, `;
      this.meSpeakText += `1. Sales Agents: `;
      this.meSpeakText += `MobilityGuru is a new marketplace where products and services can be advertised for free and minimal cost for some categories. Marketplace items cater for the support to the 12% of the global population that have a disability and elderly. `;
      this.meSpeakText += `We are keen to provide this opportunity to people on an attractive commission basis, with benefits of working from home or anywhere worldwide. Currently we seek people to assist in promoting for the Australian market place. The site name is mobilityguru.org. `;
      this.meSpeakText += ` All training, guidance and target market will be provided. The tools required are computer/tablet skills, internet and telephone connection. `;
      this.meSpeakText += `Please contact Anthony at mobilityguru.a@gmail.com. `;
    },
  },
};
</script>
