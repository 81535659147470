<template>
  <v-dialog v-model="showAcknowledgeLocal" width="500" persistent>
    <v-card>
      <MyCardTitle :title="title" @close-window="clicked()"></MyCardTitle>
      <br />

      <v-layout justify-center>
        <div :style="{ color: color }" v-html="message"></div>
      </v-layout>

      <!--
      <p class="text-center">{{ message }}</p>
      -->

      <v-layout justify-center>
        <MyBtn
          v-if="!hideAcknowledgeBtn"
          btnName="acknowledge"
          :iconName="thumbIcon"
          @btn-clicked="clicked()"
          :iconColor="color"
          :key="myBtnKey"
        >
        </MyBtn>
        <MyBtn
          v-else
          :btnName="actionName"
          :iconName="mdiArrowRightThick"
          @btn-clicked="actionClicked()"
          iconColor="green"
        >
        </MyBtn>
      </v-layout>
      <br />
    </v-card>
  </v-dialog>
</template>

<script>
import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";

import {
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiArrowRightThick,
} from "@mdi/js";

export default {
  name: "Loading",
  components: {
    MyBtn,
    MyCardTitle,
  },
  props: [
    "showAcknowledge",
    "title",
    "message",
    "ok",
    "showActionBtn",
    "actionName",
  ],
  data: () => ({
    showAcknowledgeLocal: false,

    mdiThumbDownOutline: mdiThumbDownOutline,
    mdiThumbUpOutline: mdiThumbUpOutline,
    mdiArrowRightThick: mdiArrowRightThick,

    color: "",
    thumbIcon: "",
    myBtnKey: 0,
    hideAcknowledgeBtn: false,
  }),
  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.showAcknowledgeLocal = this.showAcknowledge;
      this.initColourIcon();
      /*
      this.debug(`
        MyAcknowledge message = ${this.message}, ok = ${this.ok}, color = ${this.color}`);
      */
    },

    initColourIcon() {
      if (this.ok == null) {
        this.color = "green";
        this.thumbIcon = mdiThumbUpOutline;
      } else if (this.ok == true) {
        this.color = "green";
        this.thumbIcon = mdiThumbUpOutline;
      } else {
        this.color = "red";
        this.thumbIcon = mdiThumbDownOutline;
      }
      this.myBtnKey++;
    },

    clicked() {
      this.$emit("btn-clicked");
    },

    actionClicked() {
      this.$emit("action-clicked");
    },
  },
};
</script>
