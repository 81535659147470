<template>
  <v-card>
    <v-card v-if="fileUrls.length > 0" class="ma-0" elevation="0" outlined>
      <v-window v-model="fileIndex">
        <v-window-item v-for="fileUrl in fileUrls" :key="`card-${fileUrl}`">
          <v-card color="transparent" class="d-flex justify-center">
            <v-img
              :src="fileUrl"
              transition="scale-transition"
              width="400px"
              min-width="360px"
              :height="windowHeight"
              contain
              id="image"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="brown"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-space-between">
        <!-- <v-btn text @click="prev">
        <v-icon>{{ mdiChevronLeft }}</v-icon>
      </v-btn>
      <v-btn text @click="next">
        <v-icon>{{ mdiChevronRight }}</v-icon>
      </v-btn>
      -->
        <v-item-group v-model="fileIndex" class="text-center" mandatory>
          <v-item
            v-for="fileUrl in fileUrls"
            :key="`btn-${fileUrl}`"
            v-slot="{ active, toggle }"
          >
            <v-btn
              tile
              text
              class="mr-1 ml-1"
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-img
                contain
                height="30"
                width="30"
                :src="fileUrl"
                :lazy-src="fileUrl"
              ></v-img>
            </v-btn>
          </v-item>
        </v-item-group>
        <MyBtn
          :iconName="mdiArrowExpandAll"
          :disabled="fileUrls.length == 0"
          @btn-clicked="showFullScreen()"
          tooltip="Full Screen"
        ></MyBtn>
      </v-card-actions>

      <v-dialog v-model="showFullScreenImage" scrollable height="screen.height">
        <v-card>
          <MyCardTitle
            :title="title"
            @close-window="showFullScreenImage = false"
          ></MyCardTitle>

          <v-card-text align="center" justify="center">
            <v-img
              :src="fileUrls[fileIndex]"
              :lazy-src="fileUrls[fileIndex]"
              id="imageFullScreen"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="brown"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>

          <v-card-actions>
            <v-row justify="center">
              <v-col></v-col>
              <MyBtn
                :disabled="fileIndex == 0"
                :iconName="mdiSkipPrevious"
                :tooltip="$t(`previousImage`)"
                @btn-clicked="prev()"
              ></MyBtn>
              <v-col></v-col>
              <p v-if="fileUrls.length > 0">
                {{ $t(`numImages`) }} {{ fileIndex + 1 }}
                {{ $t(`of`) }}
                {{ fileUrls.length }}
              </p>

              <v-col></v-col>
              <MyBtn
                :disabled="
                  fileIndex == fileUrls.length - 1 || fileUrls.length == 0
                "
                :iconName="mdiSkipNext"
                :tooltip="$t(`nextImage`)"
                @btn-clicked="next()"
              ></MyBtn>

              <v-col></v-col>
            </v-row>

            <br />
            <br />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card height="300px" min-width="360px" v-else>
      <v-row align="center" justify="center" style="height: 350px">
        <div>
          <v-icon x-large>{{ mdiImageOffOutline }}</v-icon>
        </div>
      </v-row>
    </v-card>
    <v-row no-gutters dense id="rightTop">
      <MyIcon :iconName="mdiEye" :label="totalViews" tooltip="Views"></MyIcon>
      <MySocialShare :URL="currentUrl"></MySocialShare>

      <MyIcon
        :iconName="favourite ? mdiHeart : mdiHeartOutline"
        label="Like"
        @btn-clicked="updateFavourite()"
      ></MyIcon>

      <MyAcknowledge
        :showAcknowledge="showAcknowledge"
        :title="acknowledgeTitle"
        :message="acknowledgeMessage"
        @btn-clicked="closeMyAcknowledge"
        :key="myAcknowledgeKey"
        :showActionBtn="showActionBtn"
        :actionName="actionName"
        @action-clicked="goToProfileWithHash('#SetAddress')"
      ></MyAcknowledge>
    </v-row>
  </v-card>
</template>

<script>
import {
  mdiRecord,
  mdiChevronRight,
  mdiChevronLeft,
  mdiSkipNext,
  mdiSkipPrevious,
  mdiArrowExpandAll,
  mdiShare,
  mdiHeart,
  mdiHeartOutline,
  mdiEye,
  mdiImageOffOutline,
} from "@mdi/js";
import MyAcknowledge from "./MyAcknowledge";
import MyBtn from "../components/MyBtn.vue";
import MyCardTitle from "../components/MyCardTitle.vue";
import MyIcon from "../components/MyIcon.vue";
import MySocialShare from "../components/MySocialShare.vue";
import Config from "../config";
export default {
  name: "ImagesWindows",
  props: ["fileUrls", "totalViews"],
  components: { MyBtn, MyCardTitle, MyIcon, MySocialShare, MyAcknowledge },
  data: () => ({
    length: 3,
    fileIndex: 0,
    mdiRecord: mdiRecord,
    mdiChevronRight: mdiChevronRight,
    mdiChevronLeft: mdiChevronLeft,
    mdiSkipNext: mdiSkipNext,
    mdiSkipPrevious: mdiSkipPrevious,
    mdiArrowExpandAll: mdiArrowExpandAll,
    mdiShare: mdiShare,
    mdiHeart: mdiHeart,
    mdiHeartOutline: mdiHeartOutline,
    mdiEye: mdiEye,
    mdiImageOffOutline: mdiImageOffOutline,
    title: "",
    windowHeight: 300,
    showFullScreenImage: false,
    currentUrl: "",
    favourite: false,
    showActionBtn: false,
    actionName: "",

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    myAcknowledgeKey: "MyAcknowledge0",
    myAcknowledgeKeyCounter: 0,
  }),
  created() {
    window.addEventListener("resize", this.changeWidth);
  },

  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },
  mounted: function () {
    this.currentUrl = `${Config.APP_SERVER_IP}${Config.ROUTE_AD_VIEW}?adType=${this.$route.query.adType}%26id=${this.$route.query.id}`;
    this.favourite = this.$store.state.favouriteAds.includes(
      this.$route.query.id
    );
  },
  methods: {
    next() {
      this.fileIndex =
        this.fileIndex + 1 === this.fileUrls.length ? 0 : this.fileIndex + 1;
    },
    prev() {
      this.fileIndex =
        this.fileIndex - 1 < 0 ? this.fileUrls.length - 1 : this.fileIndex - 1;
    },

    changeWidth() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.windowHeight = window.innerHeight * 0.3;
      } else {
        this.windowHeight = 400;
      }
    },
    showFullScreen() {
      this.showFullScreenImage = true;
    },

    async updateFavourite() {
      if (await this.isLoggedInOIDC(true)) {
        this.favourite = !this.favourite;
        //update to server
        await this.updateFavouriteAds(this.$route.query.id, this.favourite);
        //when this function successfully completed the socket.io will send broadcast message favourited and the related userId will receive it store it
        this.$emit("favourite-removed");
      } else {
        //Code for showing the alert (MyAcknowledge)
        this.acknowledgeMessage = `Please LOGIN or SIGNUP`;
        this.acknowledgeTitle = "Login Required";
        this.showMyAcknowledge();
      }
    },

    showMyAcknowledge() {
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
      this.showAcknowledge = true;
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },
  },
};
</script>
