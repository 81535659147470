<template>
  <div id="map">
    <!--In the following div the HERE Map will render-->
    <div
      id="mapContainer"
      style="height: 300px; width: 100%"
      ref="hereMap"
    ></div>
  </div>
</template>

<script>
import Config from "../config.js";

export default {
  name: "HereMap",
  props: {
    address: String,
    // center: Object,
    // center object { lat: 40.730610, lng: -73.935242 }
  },
  data: () => ({
    platform: null,
    apikey: Config.HERE_API_KEY,
    map: null,
    HH: null,
    ui: null,
    locationsContainer: null,
    geocoder: null,
    markers: null,
    app_id: Config.HERE_APP_ID,
    // You can get the API KEY from developer.here.com
  }),

  created() {
    this.platform = new window.H.service.Platform({
      app_id: this.appId,
      apikey: this.apikey,
    });
    this.geocoder = this.platform.getGeocodingService();
  },
  mounted: async function () {
    // Initialize the platform object:
    /* this.platform = new window.H.service.Platform({
      apikey: this.apikey,
    });*/
    // this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    initializeHereMap() {
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 10,
        center: { lat: 40.73061, lng: -73.935242 },

        // center object { lat: 40.730610, lng: -73.935242 }
      });

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // add UI
      const ui = H.ui.UI.createDefault(map, maptypes);
      // End rendering the initial map
      this.debug(`This is the address to geocode ${this.address}`);
      this.geocoder.geocode(
        { searchText: this.address },
        (data) => {
          if (data.Response.View.length > 0) {
            if (data.Response.View[0].Result.length > 0) {
              var coords =
                data.Response.View[0].Result[0].Location.DisplayPosition;
              map.setCenter({ lat: coords.Latitude, lng: coords.Longitude });
              const marker = new H.map.Marker({
                lat: coords.Latitude,
                lng: coords.Longitude,
              });
              marker.setData(`<p>${this.address}</p>`);
              marker.addEventListener(
                "tap",
                function (evt) {
                  // event target is the marker itself, group is a parent event target
                  // for all objects that it contains
                  var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    // read custom data
                    content: evt.target.getData(),
                  });
                  // show info bubble
                  ui.addBubble(bubble);
                },
                false
              );
              map.addObject(marker);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },
};
</script>
