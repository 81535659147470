<template>
  <SubmitInvoiceHelper> </SubmitInvoiceHelper>
</template>

<script>
import SubmitInvoiceHelper from "./SubmitInvoiceHelper";

import Config from "../config.js";

export default {
  name: "SubmitInvoice",
  components: {
    SubmitInvoiceHelper,
  },
  props: [],

  beforeMount: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },
  //  computed: () => ({
  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({}),

  mounted: async function () {},

  methods: {
    async init() {
      this.debug(`init`);
    },
  }, // methods
};
</script>
