<template>
  <div>
    <h1>{{ $t(`testing`) }}</h1>

    <v-row justify="center">
      <MyBtn btnName="testing" @btn-clicked="test()"></MyBtn>
    </v-row>
  </div>
</template>

<script>
//const test = require('ava');

//import ava from "ava";

import Config from "../config.js";

export default {
  name: "Test",

  mounted: async function () {
    this.init();
  },

  data: () => ({
    IS_DEV: false,
  }),
  methods: {
    init() {
      this.IS_DEV = Config.IS_DEV;
      if (this.IS_DEV === false) {
        this.$router.push(Config.ROUTE_HOME);
      }
    },
  }, // methods
};
</script>
