<template>
  <v-container
    class="pa-0"
    fluid
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
    <v-tabs
      fixed-tabs
      :key="tabsKey"
      height="41"
      optional
      background-color="white"
      icons-and-text
      hide-sliders
      :show-arrows="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : showArrows
      "
    >
      <v-tab
        @mouseover="showSubCategories(item.tooltip, item.subCategories)"
        class="pa-1 mr-1 iconMenu"
        v-for="(item, index) in this.category"
        :key="index"
        @click="goToMainCategory(item.tooltip)"
        :style="`
          width: 50px !important;
          font-size: 6px
          font-color: #612e11 !important;
          font-weight: 1000;
          border: 2px solid ${item.colour};
        `"
      >
        <span class="pa-0"> {{ $t(`${item.tooltip}`) }}</span>
        <v-img
          transition="scale-transition"
          class="mt-2"
          contain
          width="35"
          height="22"
          :src="item.avatar"
        />
      </v-tab>
    </v-tabs>

    <v-dialog
      hide-overlay
      v-model="dialog"
      max-width="500"
      content-class="menuDialog"
      class="transparent"
    >
      <v-card
        @mouseleave="dialog = false"
        style="border: 2px solid #612e11 !important"
        color="fillColour"
        elevation="0"
        min-width="320"
        max-width="500"
      >
        <v-card-text class="mt-1">
          <v-row justify="end" no-gutters>
            <v-icon @click="dialog = false" color="green" small>{{
              mdiClose
            }}</v-icon>
          </v-row>
          <v-row no-gutters class="">
            <v-col cols="6">
              <v-spacer></v-spacer>
              <v-card
                @click="goToMainCategory(mainCategory)"
                class="orangeText pt-1 pb-1 mr-1"
                elevation="0"
              >
                <v-row no-gutters justify="center">
                  <v-img contain height="60" width="60" :src="imgSrc"></v-img>
                </v-row>
                <v-row no-gutters justify="center" :key="textKey">
                  <span>{{ $t(`${mainCategory}`) }}</span>
                </v-row>
              </v-card>
              <v-spacer></v-spacer>
            </v-col>
            <v-col cols="6" :key="textKey">
              <!--   <h4 class="text-center">Sub Categories</h4>-->
              <v-list
                class="pa-0"
                elevation="0"
                v-for="(subCategory, index) in subCategories"
                :key="index"
              >
                <v-card
                  elevation="0"
                  tile
                  class="orangeText"
                  @click="
                    routerModify('subCategory', subCategory), (dialog = false)
                  "
                >
                  <v-list-item dense>
                    <v-img
                      contain
                      height="40"
                      width="40"
                      :src="
                        subCategoryIconsImagePath[mainCategory][subCategory]
                      "
                    ></v-img>
                    <v-list-item>{{ $t(`${subCategory}`) }}</v-list-item>
                  </v-list-item>
                </v-card>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// mdi
import { mdiPhone, mdiCloseBoxOutline } from "@mdi/js";
import Config from "../config.js";
export default {
  name: "IconsMenu",
  data: () => ({
    mdiPhone: mdiPhone,
    mdiClose: mdiCloseBoxOutline,
    tabsKey: 0,
    MAIN_CATEGORIES_ENUM: {},
    MAIN_CATEGORIES: [],
    SUB_CATEGORIES_LIST: {},
    category: [],
    showArrows: false,
    subCategories: [],
    subCategoryIconsImagePath: {}, //this store the icons paths object for every subcategories using maincategory as key value
    mainCategory: "",
    subCategory: "",
    dialog: false,
    imgSrc: "",
    imgSrcSub: "",
    textKey: 0,
    MG_ICON_COLOURS: [],
  }),
  mounted: async function () {
    await this.init();
  },

  watch: {
    async $route() {
      if (this.$route.path == Config.ROUTE_LIVE_ADS) {
        this.showArrows = true;
      }
    },
  },
  methods: {
    async init() {
      this.MAIN_CATEGORIES_ENUM = Config.MAIN_CATEGORIES_ENUM;
      this.MAIN_CATEGORIES = Config.MAIN_CATEGORIES;
      this.SUB_CATEGORIES_LIST = Config.SUB_CATEGORIES_LIST;
      this.MG_ICON_COLOURS = Config.MG_ICON_COLOURS;
      for (var i = 0; i < this.MAIN_CATEGORIES.length; i++) {
        this.category[i] = {
          tooltip: this.MAIN_CATEGORIES[i],
          avatar: "/icons/" + this.MAIN_CATEGORIES[i] + ".svg",
          subCategories: this.SUB_CATEGORIES_LIST[i],
          colour: this.MG_ICON_COLOURS[i],
        };

        let subCategoryImagesObject = {}; //icons paths will be asssigned to subcategory names key values inside this object using the below for loop

        //checking for undefined becaaue `Miscellaneous` main category does not have sub categories
        if (this.SUB_CATEGORIES_LIST[i] !== undefined) {
          let selectedSubCategoriesForLoop = this.SUB_CATEGORIES_LIST[i];

          //Loop through the subcategories of every main category and create an object with path to icons images
          /**Example
           * {
           *  Boats: `/icons/Mobility Transport/Boats.png`,
              Camper Vans: `/icons/Mobility Transport/Camper Vans.png`,
              Caravans: `/icons/Mobility Transport/Caravans.png`,
              Cars: `/icons/Mobility Transport/Cars.png`,
              Mobility Scooters: `/icons/Mobility Transport/Mobility Scooters.png`,
              .. 
              ..
              ..
           * }
           */

          for (let j = 0; j < selectedSubCategoriesForLoop.length; j++) {
            //subcategories loop start

            //`Wheelchair & Scooter Parts/Accs` name can not be added for images so added an if condition to look for it and change the path as the image name exist
            if (
              selectedSubCategoriesForLoop[j] ==
              "Wheelchair & Scooter Parts/Accs"
            ) {
              subCategoryImagesObject[
                selectedSubCategoriesForLoop[j]
              ] = `/icons/${this.MAIN_CATEGORIES[i]}/Wheelchair & Scooter PartsAccs.png`;
            } else {
              subCategoryImagesObject[
                selectedSubCategoriesForLoop[j]
              ] = `/icons/${this.MAIN_CATEGORIES[i]}/${selectedSubCategoriesForLoop[j]}.png`;
            }

            // loop end
          }

          //Assigining the icons path object for main category
          this.subCategoryIconsImagePath[this.MAIN_CATEGORIES[i]] =
            subCategoryImagesObject;
        }
      }
      console.log(this.category);
      this.tabsKey++;
      this.dialog = false;
    },

    mouseOver() {
      this.debug(`mouse over`);
      this.showArrows = true;
    },

    mouseLeave() {
      this.debug(`mouse leave`);
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "md"
      ) {
        this.showArrows = true;
      } else {
        this.showArrows = false;
      }
    },

    showSubCategories(mainCategory, subCategories) {
      this.debug(subCategories);
      if (subCategories !== undefined) {
        this.imgSrc = "/icons/" + mainCategory + ".svg";
        this.mainCategory = mainCategory;
        this.subCategories = subCategories;
        this.dialog = true;
      } else {
        this.dialog = false;
      }
      this.textKey++;
    },
    closeSubCategories() {
      this.dialog = false;
    },

    goToMainCategory(selectedMainCategory, subCategories) {
      this.debug(selectedMainCategory + this.dialog);
      if (this.dialog == true) {
        this.dialog = false;
        this.routerModify("mainCategory", selectedMainCategory);
        this.tabsKey++;
      } else {
        if (selectedMainCategory == "Miscellaneous") {
          this.dialog = false;
          this.routerModify("mainCategory", selectedMainCategory);
          this.tabsKey++;
        } else {
          this.showSubCategories(selectedMainCategory, subCategories);
        }
      }
      this.dialog = false;
    },
  },
};
</script>
