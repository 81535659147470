<template>
  <v-container>
    <v-row justify="center" no-gutters v-show="showPaypalButton === true">
      <PayPalCheckout
        :items="myItems"
        :key="payPalBtnKey"
        :client_id="client_id"
        :product="product"
        :currency="currencyCode"
        @payment-completed="paymentCompleted"
        @payment-cancelled="paymentCancelled"
        @payment-error="paymentError"
      ></PayPalCheckout>
    </v-row>
    <br />
    <h2 class="text-center">{{ $t(`or`) }}</h2>
    <v-row
      justify="center"
      v-if="showPaypalButton === true"
      no-gutters
      class="mt-1 text-center"
    >
      <DepayWidget
        :payment="true"
        :amount="priceGuru"
        :showBuyGuru="true"
        @depay-payment-confirmed="depayPaymentConfirmed"
      ></DepayWidget>
    </v-row>
    <br />
    <span v-if="paymentExpiredMessage.length > 0">{{
      paymentExpiredMessage
    }}</span>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
  </v-container>
</template>

<script>
//import PayPal from "vue-paypal-checkout";
//import Loading from "./Loading";
//import MyAcknowledge from "./MyAcknowledge";
import Config from "../config.js";
import MyAcknowledge from "./MyAcknowledge";
import PayPalCheckout from "./PayPalCheckout";
import DepayWidget from "./DepayWidget.vue";
export default {
  name: "Payments",
  components: {
    //Loading,
    MyAcknowledge,
    PayPalCheckout,
    DepayWidget,
  },

  props: ["price", "currencyCode", "priceGuru", "description"],
  computed: {},
  mounted: async function () {
    if (await this.isLoggedInOIDC(true)) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },
  data: () => ({
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    isLoading: false,
    messageLoader: "",
    percentageLoader: null,

    acknowledgeMessage: "",
    acknowledgeTitle: "",
    showAcknowledge: false,

    client_id: "",

    payPalBtnKey: "payPalBtnKey0",

    myItems: [
      {
        description: "some description",
        quantity: "1",
        unit_amount: {
          currency_code: "AUD",
          value: "30.00",
        },
        name: "Payment",
      },
    ],

    product: {
      price: "30.00",
      description: "Payment",
    },
    showPaypalButton: false,
    paymentExpiredMessage: "",
  }),

  methods: {
    paymentAuthorized: function (data) {
      this.debug("payment authorized");
      this.debug(data);
      //this.pay(data);
    },
    paymentCompleted: function (data) {
      this.debug("paymemt completed");
      this.debug(data);
      let paymentData = {};
      paymentData["payPalData"] = data[0];
      paymentData["price"] = this.price;
      paymentData["currencyCode"] = this.currencyCode;
      paymentData["paymentType"] = "paypal";
      this.paymentSuccess(paymentData);
    },
    paymentCancelled: function (data) {
      this.debug("payment cancelled");
      this.debug(data);
    },

    paymentError: function (err) {
      this.debug("payment error");
      this.debug(err);
      this.setAcknowledgeError("Order not completed");
    },
    depayPaymentConfirmed(data) {
      this.debug("paymemt completed");
      this.debug(data);
      let paymentData = {};
      data["priceGuru"] = this.priceGuru;
      data["token"] = Config.GURU_TOKEN_ADDRESS;
      paymentData["depayData"] = data;
      paymentData["paymentType"] = "depay";
      this.paymentSuccess(paymentData);
    },
    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.showAcknowledge = true;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    async init() {
      this.isLoading = true;
      this.debug(`cnfig ${Config.PAYPAL_SANDBOX_CLIENT_ID}`);

      if (Config.IS_DEV === true) {
        //  this.payPalEnv = "sandbox";
        this.client_id = Config.PAYPAL_SANDBOX_CLIENT_ID;
      } else {
        //this.payPalEnv = "production";
        this.client_id = Config.PAYPAL_PRODUCTION_CLIENT_ID;
      }

      this.product.price = this.price;
      this.product.description = this.description;
      this.myItems[0].description = this.description;
      this.myItems[0].name = this.description;
      this.myItems[0].unit_amount.currency_code = this.currencyCode;
      this.myItems[0].unit_amount.value = this.price;
      //this.mainInit();

      this.showPaypalButton = true;
      this.debug(`price = ${this.currencyCode} ${this.price}`);
      this.printJson(this.product);
      this.printJson(this.myItems);
      this.payPalBtnKey++;
      this.payPalBtnKey = "payPalBtnKey" + this.payPalBtnKey;

      await this.synchSetTimeout(1);
      this.isLoading = false;
      await this.synchSetTimeout(1);
    },

    async paymentSuccess(paymentData) {
      this.$emit("payment-success", paymentData);
    },
  },
};
</script>
