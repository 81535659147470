<template>
  <div class="pr-1 pl-1" style="height: 40px">
    <v-tooltip v-if="tooltip != null" top color="green">
      <template v-slot:activator="{ on }">
        <v-col class="pa-0 text-center" align-self="center">
          <v-icon color="borderColour" v-on="on" @click="clicked()">
            {{ iconName }}
          </v-icon>
          <p class="caption mt-n1 text-center">{{ label }}</p>
        </v-col>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>

    <v-col v-else class="pa-0 text-center" align-self="center">
      <v-icon color="borderColour" @click="clicked()">
        {{ iconName }}
      </v-icon>

      <p class="caption mt-n1 text-center">{{ label }}</p>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "MyIcon.vue",
  props: ["iconName", "label", "tooltip"],

  data: () => ({}),
  methods: {
    clicked() {
      this.$emit("btn-clicked");
    },
  },
};
</script>
