<template>
  <DashboardHelper :roleType="roleType"> </DashboardHelper>
</template>

<script>
import DashboardHelper from "./DashboardHelper";
import Config from "../config.js";

export default {
  name: "DevDashboard",
  components: {
    DashboardHelper,
  },
  metaInfo: {
    title: "Mobilility Dev",
    //titleTemplate: "%s | Public Ads",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Countrymanager Dashboard",
      },
      { name: "keywords", content: "Develpoper,MobilityGuru" },
    ],
  },
  data: () => ({
    roleType: "",
  }),

  mounted: async function () {
    this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    init() {
      this.debug(this.$store.state.isDev);
      if (this.$store.state.isDev === true) {
        this.debug("DEV_ROLE");
        this.roleType = Config.ROLE_TYPE_ENUM.DEV;
      }
    },
  }, // methods
};
</script>
