<template>
  <v-row>
    <MyDropDown
      :tooltip="tooltip"
      :itemName="$t('state')"
      :dropDownItem="selectedState"
      :dropDownItems="states"
      @update-items="updateState"
    ></MyDropDown>
    <v-progress-circular
      class="ml-1"
      v-if="statesLoading === true"
      indeterminate
      color="green"
    ></v-progress-circular>
  </v-row>
</template>
<script>
import MyDropDown from "./MyDropDown";

export default {
  name: "SelectState",
  props: ["selectedState", "states", "tooltip"],
  components: {
    MyDropDown,
  },
  // props: [],
  data: () => ({
    statesLoading: false,
  }),

  watch: {
    setLoading(newState, oldState) {
      // Our fancy notification (2).
      console.log(`${oldState} --->>>${newState}`);
      this.statesLoading = newState;
    },
  },
  mounted: async function () {
    await this.init();
  },

  computed: {
    setLoading() {
      return this.$store.state.statesLoading;
    },
  },

  methods: {
    async init() {
      /*if (this.$store.state.states.length === 0) {
        await this.getStates(this.$store.state.countryByIP);
        this.debug("states loaded from backend");
      } else {
        this.debug("states loaded already");
      }*/
    },

    async updateState(index) {
      let state = this.states[index];
      this.debug("selected state" + state);
      this.$emit("state-finalized", index);
      //await this.routerModify("condition", this.setCondition, this.adType);
    },
  },
};
</script>
