<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <p>
          Please fill the <strong>Email</strong> and
          <strong>Password</strong> textfields with the Email and password
          associated with your <strong>Mobility Guru</strong> account.<br />
          <em><a href="https://mobilityguru.org/login">Click here</a></em> to
          login
          <MeSpeak
            text="Please fill the Email and
          Passwordtextfields with the Email and password
          associated with your Mobility Guru account. Click the link to go to login"
          >
          </MeSpeak>
        </p>
        <ul>
          <ul>
            <v-img
              :src="require(`./pics/signInPc0.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="Log In"
            />
            <v-img
              :src="require(`./pics/signInPhone0.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="Log In"
            />
          </ul>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "signIn",
  components: { MeSpeak },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru FAQ",
      },
      {
        name: "keywords",
        content: "Sign Up",
      },
    ],
  },
};
</script>
