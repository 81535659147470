<template>
  <v-dialog
    :key="cropperKey"
    scrollable
    class=""
    v-model="showCropperLocal"
    width="800"
    persistent
    :fullscreen="$vuetify.breakpoint.xs ? true : false"
  >
    <v-row justify="center" no-gutters>
      <v-card width="800">
        <MyCardTitle :title="title" @close-window="closeCropper"></MyCardTitle>
        <br />
        <v-card-text height="450">
          <v-row justify="center">
            <span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="zoom(0.2)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiMagnifyPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`in`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="zoom(-0.2)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiMagnifyMinus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`out`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="move(-10, 0)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{
                      mdiArrowLeftBoldBoxOutline
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`left`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="move(10, 0)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{
                      mdiArrowRightBoldBoxOutline
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`sright`) }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="move(0, -10)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{
                      mdiArrowUpBoldBoxOutline
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`up`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="move(0, 10)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{
                      mdiArrowDownBoldBoxOutline
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`down`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="flipX"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiFlipHorizontal }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`x`) }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="flipY"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiFlipVertical }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`-y`) }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="rotate(90)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiRotateRight }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`+90`) }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    align="center"
                    @click="rotate(-90)"
                    color="fillColour"
                    v-on="on"
                    small
                  >
                    <v-icon color="green">{{ mdiRotateLeft }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`-90`) }}</span>
              </v-tooltip>
            </span>
          </v-row>
          <br />
          <v-row class="ma-1" justify="center" no-gutters>
            <MyBtn
              btnName="save"
              :iconName="mdiContentSaveEdit"
              @btn-clicked="cropImage"
            ></MyBtn>
          </v-row>
          <div>
            <!--<div class="header">
          <h2>Vue CropperJS</h2>
          <a href="https://github.com/Agontuk/vue-cropperjs">Github</a>
        </div>
        <hr />

        <input
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
        /> -->

            <div class="content">
              <v-row class="ma-1" justify="center" no-gutters>
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="IMAGE_MAX_WIDTH / IMAGE_MAX_HEIGHT"
                      :src="imgSrcLocal"
                    />
                  </div>
                  <!--  <div class="actions">
                  <a href="#" role="button" @click.prevent="zoom(0.2)">
                    Zoom In
                  </a>
                  <a href="#" role="button" @click.prevent="zoom(-0.2)">
                    Zoom Out
                  </a>
                  <a href="#" role="button" @click.prevent="move(-10, 0)">
                    Move Left
                  </a>
                  <a href="#" role="button" @click.prevent="move(10, 0)">
                    Move Right
                  </a>
                  <a href="#" role="button" @click.prevent="move(0, -10)">
                    Move Up
                  </a>
                  <a href="#" role="button" @click.prevent="move(0, 10)">
                    Move Down
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(90)">
                    Rotate +90deg
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(-90)">
                    Rotate -90deg
                  </a>
                  <a ref="flipX" href="#" role="button" @click.prevent="flipX">
                    Flip X
                  </a>
                  <a ref="flipY" href="#" role="button" @click.prevent="flipY">
                    Flip Y
                  </a>
                  <a href="#" role="button" @click.prevent="cropImage"> Crop </a>
                  <a href="#" role="button" @click.prevent="reset"> Reset </a>
                  <a href="#" role="button" @click.prevent="getData">
                    Get Data
                  </a>
                  <a href="#" role="button" @click.prevent="setData">
                    Set Data
                  </a>
                  <a href="#" role="button" @click.prevent="getCropBoxData">
                    Get CropBox Data
                  </a>
                  <a href="#" role="button" @click.prevent="setCropBoxData">
                    Set CropBox Data
                  </a>
                  <a href="#" role="button" @click.prevent="showFileChooser">
                    Upload Image
                  </a>
                </div>

                <textarea v-model="data" />
              </section>
              <section class="preview-area">
              <p>Preview</p>
              <div class="preview" />
              <p>Cropped Image</p>
              <div class="cropped-image">
                <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                <div v-else class="crop-placeholder" />
              </div>-->
                </section>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import MyCardTitle from "./MyCardTitle";
import Config from "../config.js";
import MyBtn from "./MyBtn";
import {
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiArrowLeftBoldBoxOutline,
  mdiArrowRightBoldBoxOutline,
  mdiArrowDownBoldBoxOutline,
  mdiArrowUpBoldBoxOutline,
  mdiFlipHorizontal,
  mdiFlipVertical,
  mdiContentSaveEdit,
  mdiHorizontalRotateCounterclockwise,
  mdiHorizontalRotateClockwise,
  mdiRotateLeft,
  mdiRotateRight,
} from "@mdi/js";
export default {
  components: {
    VueCropper,
    MyCardTitle,
    MyBtn,
  },
  props: ["showCropper", "imgSrc", "title"],
  data: () => ({
    showCropperLocal: false,
    imgSrcLocal: "",
    mdiMagnifyMinus: mdiMagnifyMinus,
    mdiMagnifyPlus: mdiMagnifyPlus,
    mdiArrowLeftBoldBoxOutline: mdiArrowLeftBoldBoxOutline,
    mdiArrowRightBoldBoxOutline: mdiArrowRightBoldBoxOutline,
    mdiArrowDownBoldBoxOutline: mdiArrowDownBoldBoxOutline,
    mdiArrowUpBoldBoxOutline: mdiArrowUpBoldBoxOutline,
    mdiFlipHorizontal: mdiFlipHorizontal,
    mdiFlipVertical: mdiFlipVertical,
    mdiContentSaveEdit: mdiContentSaveEdit,
    mdiHorizontalRotateClockwise: mdiHorizontalRotateClockwise,
    mdiHorizontalRotateCounterclockwise: mdiHorizontalRotateCounterclockwise,

    mdiRotateLeft: mdiRotateLeft,
    mdiRotateRight: mdiRotateRight,

    cropImg: "",
    data: null,
    cropperKey: 0,
    IMAGE_MAX_WIDTH: Config.IMAGE_MAX_WIDTH,
    IMAGE_MAX_HEIGHT: Config.IMAGE_MAX_HEIGHT,
  }),
  computed: {},

  mounted: function () {
    this.init();
  },

  watch: {
    imgSrc: function () {
      this.cropperKey++;
    },
  },

  methods: {
    init() {
      this.imgSrcLocal = this.imgSrc;

      this.showCropperLocal = this.showCropper;
      this.debug(`Data of pic`);
      this.debug(this.data);
      this.cropperKey++;
      /*      let customData = {};
      customData["width"] = 800;
      customData["height"] = 500;
      this.data = customData;*/
      // this.setCropBoxData();
    },
    closeCropper() {
      this.showCropperLocal = false;
      this.$emit("close-cropper");
    },

    /*saveImage(savedData) {
      this.$emit("save-cropped", savedData);
    },*/
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showCropperLocal = false;
      this.$emit("save-cropped", this.cropImg);
    },
    flipX() {
      const dom = this.$refs.cropper;
      let data = dom.getData();

      let scaleX = data.scaleX;
      if (scaleX > 0) {
        scaleX = -1;
      } else {
        scaleX = 1;
      }

      dom.scaleX(scaleX);
    },

    flipY() {
      const dom = this.$refs.cropper;
      let data = dom.getData();

      let scaleY = data.scaleY;
      if (scaleY > 0) {
        scaleY = -1;
      } else {
        scaleY = 1;
      }

      dom.scaleY(scaleY);
    },

    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
      this.debug(`Data of pic`);
      this.debug(this.data);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      // if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
      this.debug(`Data of pic`);
      this.debug(this.data);
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrcLocal = event.target.result;
          this.$emit("imgSrc-changed", event.target.result);
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}
input[type="file"] {
  display: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}
.header h2 {
  margin: 0;
}
.header a {
  text-decoration: none;
  color: black;
}*/
.content {
  display: flex;
  justify-content: space-between;
}
.cropper-area {
  max-width: 800px;
  min-width: 300px;
}
.actions {
  margin-top: 1rem;
}
.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
</style>
