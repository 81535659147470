<template>
  <v-row no-gutters>
    <v-col cols="12" lg="6" md="6" sm="6" class="pa-0">
      <h3>{{ $t(`sDetails`) }}</h3>
      <span>{{ $t(`name`) }}: {{ seller.given_name }}</span>
      <br />
      <span>{{ $t(`email`) }}: {{ seller.username }}</span>
      <br />
      <span>{{ $t(`merchant`) }}: {{ seller.merchant }}</span>
      <br />
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="6" class="pa-1">
      <h3>{{ $t(`bDetails`) }}</h3>
      <span>{{ $t(`name`) }}: {{ buyer.given_name }}</span>
      <br />
      <span>{{ $t(`email`) }}: {{ buyer.username }}</span>
      <br />
      <span>{{ $t(`merchant`) }}: {{ buyer.merchant }}</span>
      <br />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SellerAndBuyerInfo",
  props: ["seller", "buyer"],
};
</script>
