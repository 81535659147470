<template>
  <v-dialog v-model="showYesNoLocal" width="500" persistent :key="myYesNoKey">
    <v-card>
      <MyCardTitle :title="title" @close-window="no()"></MyCardTitle>
      <br />

      <div class="text-center" v-html="message"></div>

      <v-layout justify-center>
        <v-btn @click="no()" class="bg fg--text">
          <v-icon left large color="red">
            {{ mdiCloseOctagonOutline }}
          </v-icon>
          &nbsp; {{ $t(`no`) }}
        </v-btn>

        &emsp;

        <v-btn @click="yes()" class="bg fg--text">
          <v-icon left large color="green">
            {{ mdiCheckBold }}
          </v-icon>
          &nbsp; {{ $t(`yes`) }}
        </v-btn>
      </v-layout>
      <br />
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheckBold, mdiCloseOctagonOutline } from "@mdi/js";

import MyCardTitle from "./MyCardTitle";

export default {
  name: "MyYesNo",
  components: {
    MyCardTitle,
  },
  props: ["showYesNo", "title", "message"],

  data: () => ({
    showYesNoLocal: false,
    myYesNoKey: 0,
    mdiCheckBold: mdiCheckBold,
    mdiCloseOctagonOutline: mdiCloseOctagonOutline,
  }),

  mounted: function () {
    this.init();
  },

  watch: {
    showYesNo: function () {
      this.myYesNoKey++;
    },
  },

  methods: {
    init() {
      this.showYesNoLocal = this.showYesNo;
      this.debug("MyYesNo init()");
    },

    no() {
      this.$emit("no-clicked");
    },

    yes() {
      this.$emit("yes-clicked");
    },
  },
};
</script>
