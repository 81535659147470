import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "myVuex",
  storage: window.localStorage,
});

const state = {
  version: "v2.42.0",

  btoaStr: "",

  isLoggedIn: false, // only used by App.vue
  gotProfileFromDB: false,

  user: null,
  tokens: null,
  accessTokenDecoded: {},

  openid: null,

  kintoHttp: null,

  bucketMobilityGuru: null,

  collectionProfiles: null,
  collectionAds: null,
  collectionForum: null,
  collectionRaffles: null,
  collectionRotatingBannerAds: null,

  httpCollectionProfiles: null,
  httpBucketMobilityGuru: null,
  httpCollectionAds: null,
  httpCollectionForum: null,
  httpCollectionRaffles: null,
  httpCollectionRotatingBannerAds: null,

  addressObj: {
    number: "",
    street: "",
    suburb: "",
    state: "",
    postcode: "",
  },
  hasAddress: false,

  phone: "",
  hasPhone: false,

  merchant: "",

  syncedAds: false,

  myAdIds: [],
  syncedMyAdIds: false,
  myAds: null,

  liveAdIds: [],
  syncedLiveAdIds: false,
  liveAds: null,

  userAdIds: [],
  syncedUserAdIds: false,
  userAds: null,

  topAdIds: [],

  pageIndex: 0,
  totalAds: 0,

  allAdIds: [],

  isSuperAdmin: false,
  isCountrymanager: false,
  isSales: false,
  isDev: false,
  isForumModerator: false,

  superadminId: "",

  currentIP: "",
  lastIP: "",
  savedIP: false, // get IP only once per login

  currentPath: "",
  debugOutputEnabled: false,
  bonus: "",

  emailSubscription: true,

  phonePrefix: "", //Phone prefix
  countryByIP: "",
  country: "",
  countries: [],
  currencyCode: "",
  currencyCodeByIP: "",
  states: [],
  statesLoading: false,
  countryObject: {},
  airdropFormObject: {
    status: null,
    email: "",
    facebookId: "",
    youTubeSubscribed: null,
    walletAddress: "",
    selectedGroup: "",
    twitterId: "",
    influencerCode: "",
  },

  selectedLanguageCode: "en",
  languageCodeByIP: "",
  languageCodesObject: {},
  messages: {}, //use for vuei18n
  showTranslatingSnackbar: false,
  localeGT: {},
  notranslateApp: false,
  notranslateAppPrevious: false,
  meSpeakStreamCache: {},

  streamConnected: false,
  unreadChannels: 0,
  unreadMessages: 0,
  newMessage: {},
  toggleBuyGuru: false,
  fcmWebToken: "",
  fcmWebTokenAuthorized: "",
  favouriteAds: [], //stores the ids of favourited ads,
  cryptoEnabled: true,
};

const mutations = {
  set(state, [name, val]) {
    state[name] = val;
  },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  plugins: [vuexLocal.plugin],
});
