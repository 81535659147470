//import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vue from "vue";

import Vuetify from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {},
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        fillColour: "#F6EADE",
        borderColour: "#612E11",
        bg: "#F6EADE", // background colour
        fg: "#612E11", // foreground colour
        hoverColour: colors.orange,
        warnColour: colors.red,
        okColour: colors.green,
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
});
