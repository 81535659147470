<template>
  <ArticlesListHelper> </ArticlesListHelper>
</template>

<script>
import { mdiForum, mdiMenu } from "@mdi/js";

import ArticlesListHelper from "./ArticlesListHelper";

export default {
  name: "Articles",
  components: {
    ArticlesListHelper,
  },

  data: () => ({
    mdiForum: mdiForum,
    mdiMenu: mdiMenu,
  }),
};
</script>
