import { render, staticRenderFns } from "./MyYesNo.vue?vue&type=template&id=b3c2d1bc"
import script from "./MyYesNo.vue?vue&type=script&lang=js"
export * from "./MyYesNo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports