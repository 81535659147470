<template>
  <v-tooltip top color="success">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="green">{{ mdiInformation }}</v-icon>
      </v-btn>
    </template>
    <span>{{ info }}</span>
  </v-tooltip>
</template>
<script>
import { mdiInformation } from "@mdi/js";
export default {
  name: "MyTooltipInfo",
  props: ["info"],
  data: () => ({
    mdiInformation: mdiInformation,
  }),
};
</script>
