<template>
  <v-dialog
    v-model="showRefreshLocal"
    width="500"
    persistent
    :key="myRefreshKey"
  >
    <v-card>
      <MyCardTitle
        title="new_version_available"
        :showClose="false"
      ></MyCardTitle>
      <br />

      <p class="text-center">{{ $t(`refresh_browser`) }}</p>
      <p class="text-center">{{ $t(`thankyou`) }}</p>

      <!--
      <p class="text-center">{{ message }}</p>
      -->

      <v-layout justify-center>
        <MyBtn
          btnName="refresh"
          :iconName="mdiRefreshCircle"
          @btn-clicked="refresh()"
          iconColor="green"
          :disabled="disabledBtn"
        >
        </MyBtn>
      </v-layout>

      <br />

      <v-layout justify-center v-if="disabledBtn === true">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
        <br />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiRefreshCircle } from "@mdi/js";

import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";

export default {
  name: "Loading",
  components: {
    MyBtn,
    MyCardTitle,
  },
  props: ["showRefresh"],
  data: () => ({
    showRefreshLocal: false,
    myRefreshKey: 0,
    mdiRefreshCircle: mdiRefreshCircle,
    disabledBtn: false,
  }),
  mounted: function () {
    this.init();
  },

  watch: {
    showRefresh: function () {
      this.myRefreshKey++;
    },
  },

  methods: {
    init() {
      this.showRefreshLocal = this.showRefresh;
      this.debug(`showRefresh = ${this.showRefresh}`);
    },

    async refresh() {
      this.disabledBtn = true;

      // Added this part if the user's profile not reloaded after the perform backward compatibility
      this.$store.commit("set", ["gotProfileFromDB", false]);

      await this.getCountries();
      location.reload(true);
    },
  },
};
</script>
