<template>
  <v-dialog v-model="isWelcome" max-width="1600px" fullscreen>
    <v-card height="600px" class="fillColour">
      <br />
      <v-row justify="center">
        <v-img :src="logo" alt="MobilityGuru Logo" max-width="200"></v-img>
      </v-row>
      <br />

      <h1 class="text-center brown--text">
        {{ $t(`splash_text1`) }}
      </h1>
      <br />
      <h2 class="text-center brown--text">
        {{ $t(`splash_text2`) }}
      </h2>
      <br />
      <br />
      <p class="text-center brown--text">
        {{ $t(`splash_text3`) }}
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
import importedLogo from "./pics/logoTransBG.png";

import Config from "../config.js";

export default {
  name: "Welcome",

  data: () => ({
    isWelcome: false,
    logo: importedLogo,
  }),
  mounted: function () {
    this.init();
  },

  methods: {
    async init() {
      //this.isWelcome = false;
      //await this.synchSetTimeout(1);
      //this.isWelcome = false;
      await this.synchSetTimeout(1);
      this.$router.push(Config.ROUTE_HOME);
    },
  },
};
</script>
