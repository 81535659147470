<template>
  <span :key="counterKey"
    >{{ counterNumber }}<span v-if="isLoadingCounter == true">.00</span></span
  >
</template>
<script>
// props: delay- loading time of number
export default {
  name: "AnimatedCounter",
  props: ["value", "delay", "isDouble"],
  data: () => ({
    counter: 0,
    interval: 50,
    counterInterval: "",
    counterKey: 0,
    counterIncrement: 100,
    isLoadingCounter: false,
    counterNumber: 0,
  }),

  mounted() {
    if (this.value != 0) {
      if (this.isDouble == true) {
        this.isLoadingCounter = true;
      }

      this.counterIncrement = (
        parseFloat(this.value) /
        (parseInt(this.delay) / this.interval)
      ).toFixed(0);
      if (this.counterIncrement == 0) {
        this.counterIncrement = 1;
      }
      this.updateCounter();
      this.counterInterval = setInterval(() => {
        this.updateCounter();
      }, this.interval);
    }
  },

  methods: {
    async updateCounter() {
      if (this.counter < this.value) {
        if (this.value > 200) {
          this.counter += parseInt(this.counterIncrement);
          // this.counter += this.counterIncrement.toFixed(0);
        } else {
          this.counter++;
          this.counter.toLocaleString();
        }
      } else {
        this.counter = this.value;
        clearInterval(this.counterInterval);
        this.isLoadingCounter = false;
      }

      this.counterNumber = parseFloat(this.counter).toLocaleString();
      // console.log(this.counterNumber);
    },
  },
};
</script>
