<template>
  <v-card min-width="320" max-width="1000" class="mx-auto mt-5">
    <v-row>
      <v-card-title>
        <h1 class="display-1">FAQ</h1>
      </v-card-title>
    </v-row>

    <v-expansion-panels>
      <v-expansion-panel v-for="(item, index) in this.faqItems" :key="index">
        <v-expansion-panel-header class="">
          <v-card elevation="0"
            ><h4>{{ index + 1 }}.{{ item.heading }}</h4></v-card
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- <h4 class="ml-4">Step {{ index + 1 }}:</h4>
          <ul>
            <span>{{ steps.step }}</span>
          </ul>-->

          <signUp v-if="item.component == 'signUp'"></signUp>

          <signIn v-if="item.component == 'signIn'"></signIn>

          <verifyEmail v-if="item.component == 'verifyEmail'"></verifyEmail>

          <submitAd v-if="item.component == 'submitAd'"></submitAd>
          <modifyAd v-if="item.component == 'modifyAd'"></modifyAd>
          <deleteAd v-if="item.component == 'deleteAd'"></deleteAd>

          <createTopAd v-if="item.component == 'createTopAd'"></createTopAd>
          <rotatingBannerAd
            v-if="item.component == 'rotatingBannerAd'"
          ></rotatingBannerAd>
          <cryptoPaymentUsingDepay
            v-if="item.component == 'cryptoPaymentUsingDepay'"
          ></cryptoPaymentUsingDepay>
          <messageSeller
            v-if="item.component == 'messageSeller'"
          ></messageSeller>
          <invoice v-if="item.component == 'invoice'"></invoice>

          <anotherCountry
            v-if="item.component == 'anotherCountry'"
          ></anotherCountry>
          <multipleCountries
            v-if="item.component == 'multipleCountries'"
          ></multipleCountries>
          <paidAds v-if="item.component == 'paidAds'"></paidAds>
          <!--<ul>
            <v-card outlined elevation="0" @click="viewFullScreen(steps.photo)">
              <v-img
                :src="require(`../components/pics/FAQ/${steps.photo}.png`)"
                class="pa-0"
                contain
              />
            </v-card>
          </ul> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="showFullScreen">
      <v-card>
        <MyCardTitle title="" @close-window="showFullScreen = false">
        </MyCardTitle>
        <br />
        <!--<v-img
          :src="require(`../components/pics/FAQ/${imgName}.png`)"
          class="pa-0"
          contain
        />-->
      </v-card>
    </v-dialog>
    <Loading :isLoading="isLoading"></Loading>
  </v-card>
</template>

<script>
//import { mdiMenu } from "@mdi/js";

//import Config from "../config.js";
import Loading from "./Loading";
import MyCardTitle from "./MyCardTitle";
import signUp from "./faqComponents/signUp";
import signIn from "./faqComponents/signIn";
import verifyEmail from "./faqComponents/verifyEmail";
import submitAd from "./faqComponents/submitAd";
import modifyAd from "./faqComponents/modifyAd";
import deleteAd from "./faqComponents/deleteAd";

import createTopAd from "./faqComponents/createTopAd";
import rotatingBannerAd from "./faqComponents/rotatingBannerAd";
import messageSeller from "./faqComponents/messageSeller";
import invoice from "./faqComponents/invoice";

import anotherCountry from "./faqComponents/anotherCountry";
import multipleCountries from "./faqComponents/multipleCountries";
import paidAds from "./faqComponents/paidAds";
//import MeSpeak from "./MeSpeak";

export default {
  name: "FAQ",
  components: {
    MyCardTitle,
    Loading,
    signUp,
    signIn,
    verifyEmail,
    submitAd,
    modifyAd,
    deleteAd,

    createTopAd,
    rotatingBannerAd,
    messageSeller,
    invoice,

    anotherCountry,
    multipleCountries,
    paidAds,
    // MeSpeak,
  },

  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content:
          "Get help for common problems occuring while using Mobility Guru website",
      },
      {
        name: "keywords",
        content:
          "faq, help, aid, questions, answers, threads, knowledgebase, instructions",
      },
    ],
  },
  data: () => ({
    faqItems: [],
    isLoading: false,
    showFullScreen: false,
    imgName: 0,
  }),
  mounted: function () {
    this.init();
  },

  /*computed: {},
  watch: {
    async $route() {
      await this.init();
    },
  },*/

  methods: {
    async init() {
      //  this.isLoading = true;
      // await this.mainInit();

      this.faqItems = [
        {
          heading: "Sign Up",
          component: "signUp",
        },
        {
          heading: "Log In",
          component: "signIn",
        },
        {
          heading: "Verify email",
          component: "verifyEmail",
        },
        {
          heading: "Submit Ad",
          component: "submitAd",
        },
        {
          heading: "Edit Ad",
          component: "modifyAd",
        },
        {
          heading: "Delete Ad",
          component: "deleteAd",
        },
        {
          heading: "Create Top Ad",
          component: "createTopAd",
        },
        {
          heading: "Rotating Banner Ad",
          component: "rotatingBannerAd",
        },
        {
          heading: "Message Seller",
          component: "messageSeller",
        },
        {
          heading: "Invoice",
          component: "invoice",
        },
        {
          heading: "Submit Ads in Multiple Countries",
          component: "multipleCountries",
        },
        {
          heading: "Submit an Existing Ad Another Country",
          component: "anotherCountry",
        },
        {
          heading: "Paid Ads",
          component: "paidAds",
        },
      ];

      //this.trackUser();

      //  this.isLoading = false;
    },

    /*  viewFullScreen(index) {
      this.debug(index);
      this.showFullScreen = true;
      this.imgName = index;
    },*/
  },
};
</script>
