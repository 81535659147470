<template>
  <v-row justify="center" no-gutters>
    <v-card
      width="1600"
      class="mx-auto"
      color="transparent"
      elevation="0"
      id="transparentBack"
    >
      <v-card-title>
        <v-row class="justify-center" no-gutters>
          <h5 v-if="$vuetify.breakpoint.xs">{{ $t(`no_favourite`) }}</h5>
          <h3 v-else>{{ $t(`favourite`) }}</h3>
        </v-row>
      </v-card-title>

      <v-row class="justify-center" no-gutters>
        <v-container v-if="this.ads.length > 0">
          <v-row justify="center">
            <v-card
              v-for="(ad, index) in this.ads"
              :key="index"
              min-width="250px"
              max-width="250px"
              class="ma-1 cellBorder"
              hover
            >
              <AdCell
                :ad="ad"
                v-show="ad != undefined"
                @favourite-removed="init()"
              ></AdCell>
            </v-card>
          </v-row>
        </v-container>

        <h6 v-else v-show="isLoading == false" class="caption">
          {{ $t(`noAds`) }}
        </h6>
      </v-row>
      <v-row v-show="isLoading" justify="center">
        <v-card
          min-width="250px"
          max-width="250px"
          v-for="n in 5"
          :key="n"
          class="ma-1"
          outlined
        >
          <SkeletonLoader type="card"> </SkeletonLoader>
        </v-card>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import Config from "../config.js";
import AdCell from "./AdCell.vue";
import SkeletonLoader from "./SkeletonLoader.vue";

export default {
  name: "FavouriteAds",
  components: {
    AdCell,
    SkeletonLoader,
  },

  metaInfo: {
    title: "Mobility Guru Favourite Ads",
    meta: [
      {
        name: "My Favourites ads",
        content:
          "Ask for help, help other members or request new website features.",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },

  data: () => ({
    isSuperAdmin: false,
    isForumModerator: false,
    collectionAds: null,
    collectionProfiles: null,
    accessTokenDecoded: {},

    isLoading: false,
    adIds: [],
    ads: [],

    ROUTE_AD_CELL: Config.ROUTE_AD_CELL,
    ROUTE_FAVOURITE_ADS: Config.ROUTE_FAVOURITE_ADS,
  }),

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  computed: {},

  methods: {
    async init() {
      this.ads = [];
      this.adIds = [];
      this.isLoading = true;
      let results = {};

      let start = new Date();
      this.adId = this.$route.query.id;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.adIds = this.$store.state.favouriteAds;
      this.ROUTE_LOGIN = Config.ROUTE_LOGIN;
      if (this.adIds !== undefined && this.adIds.length > 0) {
        results = await this.getFavouriteAdsByIds(this.adIds);
        this.printJson(results, "Collection Favourites Ads in Ads");
      } else {
        this.ads = [];
        this.adIds = [];
      }
      if (results.ok == true) {
        this.ads = results.ads;
        this.adIds = results.adIds;
        console.log(this.ads);
      }

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`AdView load time = ${diffs} s`);
      this.isLoading = false;
    },
  },
};
</script>
