<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <MyIcon
        v-bind="props"
        :iconName="mdiShare"
        :label="$t(`share`)"
        @btn-clicked="menu = true"
      >
      </MyIcon>
    </template>

    <v-card outlined class="pa-3" rounded="25">
      <h3>Share!</h3>
      <v-row no-gutters>
        <MyIcon
          :iconName="mdiWhatsapp"
          :label="$t(`whatsApp`)"
          @btn-clicked="shareToWhatsapp"
        >
        </MyIcon>
        <MyIcon
          :iconName="mdiTelegram"
          :label="$t(`telegram`)"
          @btn-clicked="shareToTelegram"
        >
        </MyIcon>
        <MyIcon
          :iconName="mdiFacebook"
          :label="$t(`facebook`)"
          @btn-clicked="shareToFaceBook"
        >
        </MyIcon>
        <MyIcon
          :iconName="mdiTwitter"
          :label="$t(`twitter`)"
          @btn-clicked="shareToTwitter"
        >
        </MyIcon>
        <MyIcon
          :iconName="mdiContentCopy"
          :label="$t(`copy`)"
          @btn-clicked="copyToClipboard(decodeURIComponent(`${URL}`))"
        >
        </MyIcon>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import {
  mdiShare,
  mdiFacebook,
  mdiTwitter,
  mdiWhatsapp,
  mdiTelegram,
  mdiContentCopy,
} from "@mdi/js";
import MyIcon from "../components/MyIcon.vue";
export default {
  name: "MySocialShare",
  props: ["URL"],
  components: { MyIcon },
  data: () => ({
    mdiShare: mdiShare,
    mdiFacebook: mdiFacebook,
    mdiTwitter: mdiTwitter,
    mdiWhatsapp: mdiWhatsapp,
    mdiTelegram: mdiTelegram,
    mdiContentCopy: mdiContentCopy,
    menu: false,
    params: "menubar=no,toolbar=no,status=no,width=570,height=570",
    text: "Hi there, Check this ad ",
  }),
  methods: {
    shareToWhatsapp() {
      let Shareurl = `https://api.whatsapp.com/send?text= ${this.text} + %20 +${this.URL}`;
      window.open(Shareurl, "NewWindow", this.params);
    },
    shareToTelegram() {
      let Shareurl = `https://t.me/share/url?url=${this.URL} &text= ${this.text}  `;
      window.open(Shareurl, "NewWindow", this.params);
    },
    shareToFaceBook() {
      let shareUrl = `http://www.facebook.com/sharer/sharer.php?u=${this.URL}`;
      window.open(shareUrl, "NewWindow", this.params);
    },

    shareToTwitter() {
      let Shareurl = `https://twitter.com/intent/tweet?url=${this.URL}&text=${this.text}`;
      window.open(Shareurl, "NewWindow", this.params);
    },
  },
};
</script>
