var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.cardKey,staticClass:"ma-2 mgcard",attrs:{"color":_vm.isSelected ? 'orange' : '',"width":_vm.$vuetify.breakpoint.xs ? _vm.screenWidth * 0.83 : 300}},[(_vm.mainCategory == _vm.MISCELLANEOUS_STR)?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.mainCategoryClicked(_vm.mainCategory, '')}}},[_c('v-list-item-avatar',{attrs:{"left":"","tile":""}},[_c('img',{staticClass:"pa-1",attrs:{"height":"40","width":"40","src":_vm.avatar}})]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`${_vm.mainCategory}`)))]),_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiChevronDown))])],1)],1):_c('v-menu',{staticStyle:{"z-index":"10000000000"},attrs:{"transition":"slide-x-transition","bottom":"","max-width":!_vm.$vuetify.breakpoint.xs
        ? 280
        : _vm.LONG_STR_CATEGORY_NAMES.includes(_vm.mainCategory)
        ? 240
        : 210,"offset-x":true,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.mainCategoryClicked(_vm.mainCategory, '')}}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"left":"","tile":""}},[_c('img',{staticClass:"pa-1",attrs:{"height":"40","width":"40","src":_vm.avatar}})]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`${_vm.mainCategory}`)))]),_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiChevronDown))])],1)],1)]}}])},[_c('v-list',{staticClass:"mgdrop"},_vm._l((_vm.subCategories),function(subCategory,index){return _c('v-list-item',{key:index,staticClass:"mgbtn",attrs:{"dense":""},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){_vm.isSelected = false},"click":function($event){return _vm.finalizeCategories(_vm.mainCategory, subCategory)}}},[_c('v-list-item-icon',[_c('img',{attrs:{"height":"30","width":"30","src":`/icons/${_vm.mainCategory}/${
              subCategory == 'Wheelchair & Scooter Parts/Accs'
                ? 'Wheelchair & Scooter PartsAccs'
                : subCategory
            }.png`}})]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`${subCategory}`)))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }