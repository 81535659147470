<template>
  <v-card>
    <div ref="paypal"></div>

    <Loading :isLoading="isLoading"></Loading>
  </v-card>
</template>
<script>
//import Config from "../config.js";
import Loading from "./Loading";

export default {
  name: "PayPalCheckout",
  props: ["client_id", "currency", "product", "items"],
  components: {
    Loading,
  },
  mounted: function () {
    this.init();
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async init() {
      if (this.client_id !== undefined && this.client_id.length > 0) {
        const script = document.createElement("script");

        script.src = `https://www.paypal.com/sdk/js?client-id=${
          this.client_id
        }&currency=${this.currency.toString()}`;
        this.debug(script.src);
        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);
      }
    },

    setLoaded() {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: this.currency,
                    value: this.product.price,
                    breakdown: {
                      item_total: {
                        currency_code: this.currency,
                        value: this.product.price,
                      },
                    },
                  },
                  items: this.items,
                },
              ],

              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
          },
          onApprove: async (data, actions) => {
            this.isLoading = true;
            const order = await actions.order.capture();
            //this.paidFor = true;
            this.printJson(order.purchase_units);
            this.printJson(data);
            this.$emit("payment-completed", order.purchase_units);
            this.isLoading = false;
          },
          onCancel: async (data) => {
            // Show a cancel page, or return to cart
            this.printJson(data, "cancel data");
            this.$emit("payment-cancelled", data);
          },
          onError: (err) => {
            this.debug(err);
            this.$emit("payment-error", err);
          },
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>
