<template>
  <v-col cols="12" sm="6" md="6" :key="DateKey">
    <v-dialog
      ref="dialog"
      v-model="showDatePicker"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :disabled="disablePicker"
          outlined
          dense
          v-model="dateWithTime"
          label="Pick Date and Time"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showDatePicker = false">
          {{ $t(`cancel`) }}
        </v-btn>
        <v-btn text color="primary" @click="saveDate()">
          {{ $t(`ok`) }}
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog
      ref="dialog"
      v-model="showTimePicker"
      :return-value.sync="time"
      persistent
      width="290px"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Picker in dialog"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>-->
      <v-time-picker
        format="24hr"
        v-if="showTimePicker"
        v-model="time"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showTimePicker = false">
          {{ $t(`cancel`) }}
        </v-btn>
        <v-btn
          :disabled="time === null"
          text
          color="primary"
          @click="saveTimeWithDate"
        >
          {{ $t(`ok`) }}
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </v-col>
</template>
<script>
export default {
  name: "DateTimePicker",
  props: ["dateWithTimeOrig", "disabled", "disableSlectTime"],

  data: () => ({
    time: null,
    showTimePicker: false,
    DateKey: 0,
    showDatePicker: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateWithTime: "",
    disablePicker: false,
  }),
  mounted: async function () {
    await this.init();
  },
  methods: {
    async init() {
      this.dateWithTime = this.dateWithTimeOrig;
      if (this.disabled !== undefined) {
        this.disablePicker = this.disabled;
      }
    },
    saveDate() {
      this.$refs.dialog.save(this.date);

      this.dateWithTime = new Date(this.date);
      this.debug(this.dateWithTime);
      this.DateKey++;
      this.showDatePicker = false;
      if (this.disableSlectTime == true) {
        this.$emit("date-time-picked", this.dateWithTime);
      } else {
        this.showTimePicker = true;
      }
    },

    saveTimeWithDate() {
      this.$refs.dialog.save(this.time);
      this.debug(this.time.length);
      this.dateWithTime = this.submittableDate(this.date, this.time);
      this.debug(this.dateWithTime);
      this.$emit("date-time-picked", this.dateWithTime);
    },
    submittableDate(selectedDate, selectedTime) {
      const date = new Date(selectedDate);
      if (typeof selectedTime === "string") {
        let hours = selectedTime.substring(0, 2);
        const minuetes = selectedTime.substring(3, 5);
        this.debug(hours);
        this.debug(minuetes);
        /*let hours = selectedTime.match(/^(\d+)/)[1];
        const minuetes = selectedTime.match(/:(\d+)/)[1];*/
        date.setHours(hours);
        date.setMinutes(minuetes);
      } else {
        date.setHours(selectedTime.getHours());
        date.setMinutes(selectedTime.getMinutes());
      }
      return date;
    },
  },
};
</script>
