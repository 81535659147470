<template>
  <v-col>
    <v-data-table
      :search="search"
      :headers="tableHeaders"
      :items="tableRecords"
      :items-per-page="itemsperPageLocal"
      class="elevation-1"
    >
      <template v-slot:[`item.record`]="{ item }">
        <span v-if="Object.keys(item.record).length == 0">{{ $t(`no`) }}</span>
        <v-list multiple dense>
          <v-list-item
            v-for="(objKey, index) in Object.keys(item.record)"
            :key="index"
          >
            <v-list-item-title class="">
              <span>{{ objKey }}</span> <v-spacer></v-spacer>
            </v-list-item-title>
            <v-list-item-action-text
              ><span class="body-1" v-text="item.record[objKey]"></span
            ></v-list-item-action-text>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
export default {
  name: "MatomoDataTable",
  props: ["tableRecords", "itemsperPage"],
  data: () => ({
    //tableRecords: [],
    tableHeaders: [
      { text: "Date Range", value: "range" },
      { text: "Data", value: "record" },
    ],
    search: "",
    itemsperPageLocal: 1,
  }),

  mounted: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.printJson(this.tableRecords, "init ");
      if (this.itemsperPage !== undefined) {
        this.itemsperPageLocal = this.itemsperPage;
      }
    },
  },
};
</script>
