<template>
  <v-card class="mb-2" elevation="0">
    <v-container fluid>
      <v-col cols="12" style="font-size: 18px">
        <b v-if="article.title" class="">{{ article.title }}</b>

        <v-card-subtitle style="font-size: 15px">
          <b v-if="article.category" class="">{{ article.category }}</b>

          <span
            id="content"
            style="padding-top: 20px"
            v-html="article.articleDescription"
          >
          </span>
        </v-card-subtitle>

        <v-card-actions class="ma-0">
          <span class="mr-5" style="font-size: 13px">{{
            this.calculateDays(article.createdDate)
          }}</span>
          <v-spacer></v-spacer>
          <button type="button" class="btn btn-info" @click="goToArticleView()">
            {{ $t(`seeMore`) }}
          </button>
        </v-card-actions>
      </v-col>
    </v-container>
  </v-card>
</template>

<script>
import Config from "../config.js";
export default {
  name: "ArticleCell",

  props: ["article"],

  components: {},

  computed: {
    seemore() {
      let goToArticleView = false;
      if (
        this.accessTokenDecoded.sub ||
        this.isSuperAdmin === true ||
        this.isForumModerator
      ) {
        goToArticleView = true;
      }
      this.debug(`goToArticleView = ${goToArticleView}`);
      return goToArticleView;
    },
  },

  data: () => ({
    isSuperAdmin: false,
    isForumModerator: false,
    collectionArticles: null,
    accessTokenDecoded: {},
    isLoading: false,
    articleId: "",
  }),

  mounted: async function () {
    await this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    async init() {
      this.isLoading = true;

      this.articleId = this.article.id;
      this.articleId = this.$route.query.articleId;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.isForumModerator = this.$store.state.isForumModerator;
      this.debug("Super Admin", this.isSuperAdmin);
      this.debug(`article id from query = ${this.article.id}`);
      // let result = await this.getArticleById(this.articleId);

      // this.articleId = result.article;

      this.isLoading = false;
    },

    async goToArticleView() {
      this.debug(`article Id = ${this.article.id}`);
      await this.$router.push({
        path: Config.ROUTE_ARTICLE_VIEW,
        query: {
          articleId: this.article.id,
        },
      });
    },
  },
};
</script>
