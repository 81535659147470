<template>
  <html xmlns="http://www.w3.org/1999/xhtml" lang xml:lang>
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>MG-web-site-Terms-and-Conditions-v2</title>
    </head>

    <body>
      <div class="ma-1">
        <v-row justify="center" v-if="fromRegistration === true">
          <MyBtn
            btnName="Go back to Registration"
            :iconName="mdiTransferLeft"
            @btn-clicked="goBackToRegistration(tab_id)"
          ></MyBtn>
        </v-row>

        <center>
          <h1>
            MobilityGuru Website Terms and Conditions of Use
            <MeSpeak size="40" :text="meSpeakText"></MeSpeak>
          </h1>
        </center>
        <p>
          <link rel="stylesheet" type="text/css" href="style.css" />
        </p>

        <br />

        <!-- <div v-if="termsJson == true" class="ma-5">
          <ol type="1">
            <li v-for="i in numOfContents" :key="i" v-html="content[i]"></li>
          </ol>
        </div>
        <div v-else v-html="content"></div>-->
        <ol type="1">
          <li>
            <p>
              <strong>About the Website</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  Welcome to MobilityGuru.org (the ‘<b>Website</b>’). The
                  Website facilitates interactions between:
                </p>
                <ul>
                  <li>
                    <p>User (the ‘<b>Receiver</b>’); and</p>
                  </li>
                  <li>
                    <p>
                      MobilityGuru Ltd (the ‘Provider’), making it easier for
                      the Receiver and the Provider to locate, communicate,
                      arrange payment and deliver the services in a fast and
                      secure manner (the ‘<b>Services </b>’).
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  The Website is operated by MobilityGuru Ltd (ACN 12857765) .
                  Access to and use of the Website, or any of its associated
                  products or Services, is provided by MobilityGuru Ltd.Please
                  read these terms and conditions (the ‘<b>Terms</b>’)
                  carefully. By using, browsing and/or reading the Website, this
                  signifies that you have read, understood and agree to be bound
                  by the Terms. If you do not agree with the Terms, you must
                  cease usage of the Website, or any of its products or
                  Services, immediately.
                </p>
              </li>
              <li>
                <p>
                  MobilityGuru Ltd reserves the right to review and change any
                  of the Terms by updating this page at its sole discretion.
                  When MobilityGuru Ltd updates the Terms, it will use
                  reasonable endeavours to provide you with notice of updates of
                  the Terms. Any changes to the Terms take immediate effect from
                  the date of their publication. Before you continue, we
                  recommend you keep a copy of the Terms for your records.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Acceptance of the Terms </strong>
            </p>
            <p>
              You accept the Terms by registering for the Services and/or making
              any payment as required under the Terms for use of the Services.
              You may also accept the Terms by clicking to accept or agree to
              the Terms where and if this option is made available to you by
              MobilityGuru Ltd in the user interface.
            </p>
          </li>
          <li>
            <p>
              <strong>The Services</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  In order to access the Services, both the Receiver and the
                  Provider are required to register for an account through the
                  Website (the ‘<b>Account</b>’).
                </p>
              </li>
              <li>
                <p>
                  As part of the registration process, or as part of your
                  continued use of the Services, you may be required to provide
                  personal information about yourself (such as identification or
                  contact details), including:
                </p>
                <ul>
                  <li>
                    <p>Email address</p>
                  </li>
                  <li>
                    <p>Preferred username</p>
                  </li>
                  <li>
                    <p>Mailing address</p>
                  </li>
                  <li>
                    <p>Telephone number</p>
                  </li>
                  <li>
                    <p>Password</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  You warrant that any information you give to MobilityGuru Ltd
                  in the course of completing the registration process will
                  always be accurate, correct and up to date.
                </p>
              </li>
              <li>
                <p>
                  Once you have completed the registration process, you will be
                  a registered member of the Website (‘<b>Member</b>’) and agree
                  to be bound by the Terms.
                </p>
              </li>
              <li>
                <p>
                  You may not use the Services and may not accept the Terms if:
                </p>
                <ul>
                  <li>
                    <p>
                      you are not of legal age to form a binding contract with
                      MobilityGuru Ltd; or
                    </p>
                  </li>
                  <li>
                    <p>
                      you are a person barred from receiving the Services under
                      the laws of Australia or other countries including the
                      country in which you are resident or from which you use
                      the Services.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Your obligations as a Member </strong>
            </p>
            <ol start="4" type="1">
              <li>
                As a Member, you agree to comply with the following:<br />

                <ul class="numeric-decimals">
                  <li>
                    you will not share your profile with any other person;
                  </li>
                  <li>
                    you will use the Services only for purposes that are
                    permitted by:
                    <ul>
                      <li>the Terms; and</li>
                      <li>
                        any applicable law, regulation or generally accepted
                        practices or guidelines in the relevant jurisdictions;
                      </li>
                    </ul>
                  </li>
                  <li>
                    you have the sole responsibility for protecting the
                    confidentiality of your password and/or email address. Use
                    of your password by any other person may result in the
                    immediate cancellation of the Services;
                  </li>
                  <li>
                    any use of your registration information by any other
                    person, or third parties, is strictly prohibited. You agree
                    to immediately notify MobilityGuru of any unauthorized use
                    of your password or email address or any breach of security
                    of which you have become aware;
                  </li>
                  <li>
                    you must not expressly or impliedly impersonate another
                    Member or use the profile or password of another Member at
                    any time;
                  </li>
                  <li>
                    any content that you broadcast, publish, upload, transmit,
                    post or distribute on the Website (‘Your Content’) will
                    always be accurate, correct and up to date and you will
                    maintain reasonable records of Your Content.
                  </li>
                  <li>
                    access and use of the Website is limited, non-transferable
                    and allows for the sole use of the Website by you for the
                    purposes of MobilityGuru providing the Services;<br />
                  </li>
                  <li>
                    you will not use the Services or the Website in connection
                    with any commercial endeavours except those that are
                    specifically endorsed or approved by the management of
                    MobilityGuru;
                  </li>
                  <li>
                    you will not use the Services or Website for any illegal
                    and/or unauthoriseduse which includes collecting email
                    addresses of Members by electronic or other means for the
                    purpose of sending unsolicited email or unauthorised framing
                    of or linking to the Website;
                  </li>
                  <li>
                    you agree that commercial advertisements, affiliate links,
                    and other forms of solicitation may be removed from the
                    Website without notice and may result in termination of the
                    Services. Appropriate legal action will be taken by
                    MobilityGuru for any illegal or unauthorised use of the
                    Website; and
                  </li>
                  <li>
                    you acknowledge and agree that any automated use of the
                    Website or its Services is prohibited.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Using the Website as the Receiver </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>Selling Products and Services</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Using the Website as the Provider </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>Advertising</p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Payment </strong>/strong></p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  By MobilityGuru Ltd offering the Services to you, you agree
                  that:
                </p>
                <ul>
                  <li>
                    <p>20 Ads free</p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  All payments made in the course of your use of the Services
                  are made using PayPal or MoibilityGuru. In using the Website,
                  the Services or when making any payment in relation to your
                  use of the Services, you warrant that you have read,
                  understood and agree to be bound by the PayPal or
                  MoibilityGuru terms and conditions which are available on
                  their website.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Refund Policy </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  Since MobilityGuru Ltd is only a facilitator in introducing
                  the Receiver to the Provider and providing a system to make
                  safe payment, MobilityGuru Ltd does not hold any liability to
                  the Receiver directly and will not personally refund them any
                  payments made in the use of Services.
                </p>
              </li>
              <li>
                <p>
                  Notwithstanding the above clause, if a Receiver is unsatisfied
                  with the services provided by the Provider or believes that
                  they may be entitled to a refund, then MobilityGuru Ltd
                  requires the Receiver to:
                </p>
                <ul>
                  <li>
                    <p>
                      contact the Provider directly to request a refund; and
                    </p>
                  </li>
                  <li>
                    <p>
                      if contacting the Provider is not successful after
                      fourteen (14) days, contact MobilityGuru Ltd through the
                      ‘Contact Us’ section of the Website outlining why you
                      believe you are entitled to a refund so we are able to
                      determine if the Provider should be removed from the
                      Services.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  If contacted by a Receiver who is requesting a refund pursuant
                  to the above clause, the Provider agrees that it will
                  immediately:
                </p>
                <ul>
                  <li>
                    <p>
                      complete the MobilityGuru Ltd refund request form (the
                      ‘Request Form’) provided on the Website; and
                    </p>
                  </li>
                  <li>
                    <p>
                      provide both the Request Form and the email from the
                      Receiver requesting the refund to MobilityGuru Ltd.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  If the Provider agrees to a refund it is acknowledged that the
                  Provider will instruct MobilityGuru Ltd to refund all or part
                  of the payments made to the Receiver directly, less any fees
                  and charges incurred by MobilityGuru Ltd in processing the
                  refund.
                </p>
              </li>
              <li>
                <p>
                  Both the Receiver and Provider agree that they will comply
                  with the Refund Policy contained in this Clause of these
                  Terms.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Copyright and Intellectual Property </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  The Website, the Services and all of the related products of
                  MobilityGuru Ltd are subject to copyright. The material on the
                  Website is protected by copyright under the laws of Australia
                  and through international treaties. Unless otherwise
                  indicated, all rights (including copyright) in the Services
                  and compilation of the Website (including but not limited to
                  text, graphics, logos, button icons, video images, audio
                  clips, Website, code, scripts, design elements and interactive
                  features) or the Services are owned or controlled for these
                  purposes, and are reserved by MobilityGuru Ltd or its
                  contributors.
                </p>
              </li>
              <li>
                <p>
                  All trademarks, service marks and trade names are owned,
                  registered and/or licensed by MobilityGuru Ltd, who grants to
                  you a worldwide, non-exclusive, royalty-free, revocable
                  license whilst you are a Member to:
                </p>
                <ul>
                  <li>
                    <p>use the Website pursuant to the Terms;</p>
                  </li>
                  <li>
                    <p>
                      copy and store the Website and the material contained in
                      the Website in your device’s cache memory; and
                    </p>
                  </li>
                  <li>
                    <p>
                      print pages from the Website for your own personal and
                      non-commercial use. MobilityGuru Ltd does not grant you
                      any other rights whatsoever in relation to the Website or
                      the Services. All other rights are expressly reserved by
                      MobilityGuru Ltd.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  MobilityGuru Ltd retains all rights, title and interest in and
                  to the Website and all related Services. Nothing you do on or
                  in relation to the Website will transfer any:
                </p>
                <ul>
                  <li>
                    <p>
                      business name, trading name, domain name, trade mark,
                      industrial design, patent, registered design or copyright,
                      or
                    </p>
                  </li>
                  <li>
                    <p>
                      a right to use or exploit a business name, trading name,
                      domain name, trade mark or industrial design, or
                    </p>
                  </li>
                  <li>
                    <p>
                      a thing, system or process that is the subject of a
                      patent, registered design or copyright (or an adaptation
                      or modification of such a thing, system or process) to
                      you.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  You may not, without the prior written permission of
                  MobilityGuru Ltd and the permission of any other relevant
                  rights owners: broadcast, republish, up-load to a third party,
                  transmit, post, distribute, show or play in public, adapt or
                  change in any way the Services or third party Services for any
                  purpose, unless otherwise provided by these Terms. This
                  prohibition does not extend to materials on the Website, which
                  are freely available for re-use or are in the public domain.
                </p>
              </li>
              <li>
                <p>
                  Where you broadcast, publish, upload, transmit, post or
                  distribute Your Content on the Website, then you grant to
                  MobilityGuru Ltd a non-exclusive, transferrable, perpetual,
                  royalty-free, irrevocable, worldwide licence to broadcast,
                  republish, up-load to a third party, transmit, post,
                  distribute, show or play in public, adapt or change Your
                  Content.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Privacy </strong>
            </p>
            <p>
              MobilityGuru Ltd takes your privacy seriously and any information
              provided through your use of the Website and/or Services are
              subject to MobilityGuru Ltd’s Privacy Policy, which is available
              on the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>General Disclaimer </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  Nothing in the Terms limits or excludes any guarantees,
                  warranties, representations or conditions implied or imposed
                  by law, including the Australian Consumer Law (or any
                  liability under them) which by law may not be limited or
                  excluded.
                </p>
              </li>
              <li>
                <p>
                  Subject to this clause, and to the extent permitted by law:
                </p>
                <ul>
                  <li>
                    <p>
                      all terms, guarantees, warranties, representations or
                      conditions which are not expressly stated in the Terms are
                      excluded; and
                    </p>
                  </li>
                  <li>
                    <p>
                      MobilityGuru Ltd will not be liable for any special,
                      indirect or consequential loss or damage (unless such loss
                      or damage is reasonably foreseeable resulting from our
                      failure to meet an applicable Consumer Guarantee), loss of
                      profit or opportunity, or damage to goodwill arising out
                      of or in connection with the Services or these Terms
                      (including as a result of not being able to use the
                      Services or the late supply of the Services), whether at
                      common law, under contract, tort (including negligence),
                      in equity, pursuant to statute or otherwise.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  Use of the Website and the Services is at your own risk.
                  Everything on the Website and the Services is provided to you
                  “as is” and “as available” without warranty or condition of
                  any kind. None of the affiliates, directors, officers,
                  employees, agents, contributors and licensors of MobilityGuru
                  Ltd make any express or implied representation or warranty
                  about the Services or any products or Services (including the
                  products or Services of MobilityGuru Ltd) referred to on the
                  Website. This includes (but is not restricted to) loss or
                  damage you might suffer as a result of any of the following:
                </p>
                <ul>
                  <li>
                    <p>
                      failure of performance, error, omission, interruption,
                      deletion, defect, failure to correct defects, delay in
                      operation or transmission, computer virus or other harmful
                      component, loss of data, communication line failure,
                      unlawful third party conduct, or theft, destruction,
                      alteration or unauthorised access to records;
                    </p>
                  </li>
                  <li>
                    <p>
                      the accuracy, suitability or currency of any information
                      on the Website, the Services, or any of its Services
                      related products (including third party material and
                      advertisements on the Website);
                    </p>
                  </li>
                  <li>
                    <p>
                      costs incurred as a result of you using the Website, the
                      Services or any of the products of MobilityGuru Ltd; and
                    </p>
                  </li>
                  <li>
                    <p>
                      the Services or operation in respect to links which are
                      provided for your convenience.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  You acknowledge that MobilityGuru Ltd Website and the Services
                  are only intended to facilitate the interactions between the
                  Receiver and the Provider and does not offer any services
                  other than the Services and MobilityGuru Ltd holds no
                  liability to you as a result of any conduct of the Members or
                  the misuse of Your Content by any party (including other
                  Members).
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Limitation of Liability </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  MobilityGuru Ltd’s total liability arising out of or in
                  connection with the Services or these Terms, however arising,
                  including under contract, tort (including negligence), in
                  equity, under statute or otherwise, will not exceed the
                  resupply of the Services to you.
                </p>
              </li>
              <li>
                <p>
                  You expressly understand and agree that MobilityGuru Ltd, its
                  affiliates, employees, agents, contributors and licensors
                  shall not be liable to you for any direct, indirect,
                  incidental, special consequential or exemplary damages which
                  may be incurred by you, however caused and under any theory of
                  liability. This shall include, but is not limited to, any loss
                  of profit (whether incurred directly or indirectly), any loss
                  of goodwill or business reputation and any other intangible
                  loss.
                </p>
              </li>
              <li>
                <p>
                  You acknowledge and agree that MobilityGuru Ltd holds no
                  liability for any direct, indirect, incidental, special
                  consequential or exemplary damages which may be incurred by
                  you as a result of providing Your Content to the Website.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Termination of Contract </strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  If you want to terminate the Terms, you may do so by providing
                  MobilityGuru Ltd with Immediately days’ notice of your
                  intention to terminate by sending notice of your intention to
                  terminate to MobilityGuru Ltd via the ‘Contact Us’ link on our
                  homepage.
                </p>
              </li>
              <li>
                <p>
                  MobilityGuru Ltd may at any time, terminate the Terms with you
                  if:
                </p>
                <ul>
                  <li>
                    <p>
                      you have breached any provision of the Terms or intend to
                      breach any provision;
                    </p>
                  </li>
                  <li>
                    <p>MobilityGuru Ltd is required to do so by law;</p>
                  </li>
                  <li>
                    <p>
                      MobilityGuru Ltd is transitioning to no longer providing
                      the Services to Members in the country in which you are
                      resident or from which you use the service; or
                    </p>
                  </li>
                  <li>
                    <p>
                      the provision of the Services to you by MobilityGuru Ltd
                      is, in the opinion of MobilityGuru Ltd, no longer
                      commercially viable.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  Subject to local applicable laws, MobilityGuru Ltd reserves
                  the right to discontinue or cancel your membership at any time
                  and may suspend or deny, in its sole discretion, your access
                  to all or any portion of the Website or the Services without
                  notice if you breach any provision of the Terms or any
                  applicable law or if your conduct impacts MobilityGuru Ltd’s
                  name or reputation or violates the rights of those of another
                  party.
                </p>
              </li>
              <li>
                <p>
                  When the Terms come to an end, all of the legal rights,
                  obligations and liabilities that you and MobilityGuru Ltd have
                  benefited from, been subject to (or which have accrued over
                  time whilst the Terms have been in force) or which are
                  expressed to continue indefinitely, shall be unaffected by
                  this cessation, and the provisions of this clause shall
                  continue to apply to such rights, obligations and liabilities
                  indefinitely.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Indemnity</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  You agree to indemnify MobilityGuru Ltd, its affiliates,
                  employees, agents, contributors, third party content providers
                  and licensors from and against:
                </p>
                <ul>
                  <li>
                    <p>
                      all actions, suits, claims, demands, liabilities, costs,
                      expenses, loss and damage (including legal fees on a full
                      indemnity basis) incurred, suffered or arising out of or
                      in connection with Your Content;
                    </p>
                  </li>
                  <li>
                    <p>
                      any direct or indirect consequences of you accessing,
                      using or transacting on the Website or attempts to do so;
                      and/or
                    </p>
                  </li>
                  <li>
                    <p>any breach of the Terms.</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Dispute Resolution</strong>
            </p>
            <ul class="numeric-decimals">
              <li>
                <p>
                  <b>Compulsory:</b> <br />
                  If a dispute arises out of or relates to the Terms, either
                  party may not commence any Tribunal or Court proceedings in
                  relation to the dispute, unless the following clauses have
                  been complied with (except where urgent interlocutory relief
                  is sought).
                </p>
              </li>
              <li>
                <p>
                  <b>Notice:</b> <br />
                  A party to the Terms claiming a dispute (‘Dispute’) has arisen
                  under the Terms, must give written notice to the other party
                  detailing the nature of the dispute, the desired outcome and
                  the action required to settle the Dispute.
                </p>
              </li>
              <li>
                <p>
                  <b>Resolution:</b> <br />
                  On receipt of that notice (‘Notice’) by that other party, the
                  parties to the Terms (‘Parties’) must:
                </p>
                <ul>
                  <li>
                    <p>
                      Within 5 days of the Notice endeavour in good faith to
                      resolve the Dispute expeditiously by negotiation or such
                      other means upon which they may mutually agree;
                    </p>
                  </li>
                  <li>
                    <p>
                      If for any reason whatsoever, 5 days after the date of the
                      Notice, the Dispute has not been resolved, the Parties
                      must either agree upon selectionof a mediator or request
                      that an appropriate mediator be appointed by the President
                      of the Arbitration body or his or her nominee;
                    </p>
                  </li>
                  <li>
                    <p>
                      The Parties are equally liable for the fees and reasonable
                      expenses of a mediator and the cost of the venue of the
                      mediation and without limiting the foregoing undertake to
                      pay any amounts requested by the mediator as a
                      pre-condition to the mediation commencing. The Parties
                      must each pay their own costs associated with the
                      mediation;
                    </p>
                  </li>
                  <li>
                    <p>The mediation will be held in Online, Australia.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <b>Confidential:</b> <br />
                  All communications concerning negotiations made by the Parties
                  arising out of and in connection with this dispute resolution
                  clause are confidential and to the extent possible, must be
                  treated as “without prejudice” negotiations for the purpose of
                  applicable laws of evidence.
                </p>
              </li>
              <li>
                <p>
                  <b>Termination of Mediation:</b> <br />
                  If 5 days have elapsed after the start of a mediation of the
                  Dispute and the Dispute has not been resolved, either Party
                  may ask the mediator to terminate the mediation and the
                  mediator must do so.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Venue and Jurisdiction </strong>
            </p>
            <p>
              The Services offered by MobilityGuru Ltd is intended to be viewed
              by residents of Australia. In the event of any dispute arising out
              of or in relation to the Website, you agree that the exclusive
              venue for resolving any dispute shall be in the courts of
              Queensland, Australia.
            </p>
          </li>
          <li>
            <p>
              <strong>Governing Law</strong>
            </p>
            <p>
              The Terms are governed by the laws of Queensland, Australia. Any
              dispute, controversy, proceeding or claim of whatever nature
              arising out of or in any way relating to the Terms and the rights
              created hereby shall be governed, interpreted and construed by,
              under and pursuant to the laws of Queensland, Australia, without
              reference to conflict of law principles, notwithstanding mandatory
              rules. The validity of this governing law clause is not contested.
              The Terms shall be binding to the benefit of the parties hereto
              and their successors and assigns.
            </p>
          </li>
          <li>
            <p>
              <strong>Independent Legal Advice</strong>
            </p>
            <p>
              Both parties confirm and declare that the provisions of the Terms
              are fair and reasonable and both parties having taken the
              opportunity to obtain independent legal advice and declare the
              Terms are not against public policy on the grounds of inequality
              or bargaining power or general grounds of restraint of trade.
            </p>
          </li>
          <li>
            <p>
              <strong>Severance</strong>
            </p>
            <p>
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </p>
          </li>
        </ol>
        <p><strong>Approved by</strong></p>
        <p>Anthony Cash and Kean Som Oui</p>
        <p>Date: April 30, 2021</p>

        <p align="center">
          <strong>END of DOCUMENT</strong>
        </p>
        <v-row justify="center" v-if="fromRegistration === true">
          <MyBtn
            btnName="Go back to Registration"
            iconName="mdi-transfer-left"
            @btn-clicked="goBackToRegistration(tab_id)"
          ></MyBtn>
        </v-row>
      </div>
    </body>
  </html>
</template>

<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}

ol li {
  display: block;
} /* hide original list counter */
ol > li:first-child {
  counter-reset: item;
} /* reset counter */
ol > li {
  counter-increment: item;
  position: relative;
} /* increment counter */
ol > li:before {
  content: counters(item, ".") ".   ";
  position: absolute;
  margin-right: 100%;
  right: 8px;
} /* print counter */

/* Nested lower alphabet list*/
ul.numeric-decimals {
  counter-reset: section;
}
ul.numeric-decimals > li {
  list-style-type: none;
}
ul.numeric-decimals > li:before {
  counter-increment: section;
  content: counter(section, lower-alpha) ")";
  position: absolute;
  left: 5px;
}

/*Nested lower roman numbered listed inside the alphabet list */
ul.numeric-decimals li ul {
  counter-reset: subsection;
}
ul.numeric-decimals li ul li:before {
  counter-increment: subsection;
  content: counter(subsection, lower-roman) ". ";
  position: absolute;
  left: 25px;
}
</style>

<script>
import { mdiTransferLeft } from "@mdi/js";
import MeSpeak from "./MeSpeak";

import MyBtn from "./MyBtn";

export default {
  name: "Terms",
  metaInfo: {
    title: "Mobility GuruTerms",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Terms & Conditions",
      },
      {
        name: "keywords",
        content: "terms, conditions",
      },
    ],
  },
  components: {
    MyBtn,
    MeSpeak,
  },
  data: () => ({
    mdiTransferLeft: mdiTransferLeft,
    fromRegistration: false,
    tab_id: "",
    meSpeakText: "",
    meSpeakText1: "",
    meSpeakText2: "",
    meSpeakText3: "",
    meSpeakText4: "",
    meSpeakText5: "",
    meSpeakText6: "",
    meSpeakText7: "",
    meSpeakText8: "",
    meSpeakText9: "",
    meSpeakText10: "",
    meSpeakText11: "",
    meSpeakText12: "",
    meSpeakText13: "",
    meSpeakText14: "",
    meSpeakText15: "",
    meSpeakText16: "",
    meSpeakText17: "",
    meSpeakText18: "",
    meSpeakText19: "",
    meSpeakText20: "",
    title: "",
    content: "",
    numOfContents: 0,
    termsJson: false,
  }),
  mounted: async function () {
    this.init();
  },
  /*watch: {
    "$store.state.selectedLanguageCode": async function () {
      await this.init();
    },
  },*/
  methods: {
    async init() {
      let results = this.checkFromRegistration();
      this.tab_id = results.tab_id;
      this.fromRegistration = results.fromRegistration;
      this.loadMeSpeakText();

      /*let res = await this.getTerms(this.$i18n.locale);
      this.printJson(res);
      this.title = res.translatedContent.title;
      this.content = res.translatedContent.content;
      if (typeof this.content === "string") {
        this.termsJson = false;
      } else {
        this.numOfContents = Object.keys(this.content).length;
        this.meSpeakText = `${this.title}`;
        this.meSpeakText += `${this.content}`;
        this.termsJson = true;
      }*/
    },

    async loadMeSpeakText() {
      this.meSpeakText1 =
        "1.	About the Website (a)	Welcome to MobilityGuru.org (the 'Website'). The Website facilitates interactions between: (i)	User (the 'Receiver '); and: (ii)	MobilityGuru Ltd (the 'Provider '): ,making it easier for the Receiver and the Provider to locate, communicate, arrange payment and deliver the services in a fast and secure manner (the 'Services '): (b)	The Website is operated by MobilityGuru Ltd (ACN 12857765). Access to and use of the Website, or any of its associated products or Services, is provided by MobilityGuru Ltd. Please read these terms and conditions (the 'Terms ') carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of its products or Services, immediately.: (c)	MobilityGuru Ltd reserves the right to review and change any of the Terms by updating this page at its sole discretion. When MobilityGuru Ltd updates the Terms, it will use reasonable endeavours to provide you with notice of updates of the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records. ";

      this.meSpeakText2 = `2.	Acceptance of the Terms: You accept the Terms by registering for the Services and/or making any payment as required under the Terms for use of the Services. You may also accept the Terms by clicking to accept or agree to the Terms where and if this option is made available to you by MobilityGuru Ltd in the user interface.`;

      this.meSpeakText3 = `3.	The Services

      (a)	In order to access the Services, both the Receiver and the Provider are required to register for an account through the Website (the 'Account').

      (b)	As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:

      (i)	Email address

      (ii)	Preferred username

      (iii)	Mailing address

      (iv)	Telephone number

      (v)	Password

      (c)	You warrant that any information you give to MobilityGuru Ltd in the course of completing the registration process will always be accurate, correct and up to date.

      (d)	Once you have completed the registration process, you will be a registered member of the Website ('Member ') and agree to be bound by the Terms.

      (e)	You may not use the Services and may not accept the Terms if:

      (i)	you are not of legal age to form a binding contract with MobilityGuru Ltd; or

      (ii)	you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.`;

      this.meSpeakText4 = `4.	Your obligations as a Member

      4.1.As a Member, you agree to comply with the following:

      (a)	you will not share your profile with any other person;

      (b)	you will use the Services only for purposes that are permitted by:

      (i)	the Terms; and

      (ii)	any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;

      (c)	you have sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Services;

      (d)	any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify MobilityGuru Ltd of any unauthorised use of your password or email address or any breach of security of which you have become aware;

      (e)	you must not expressly or impliedly impersonate another Member or use the profile or password of another Member at any time;

      (f)	any content that you broadcast, publish, upload, transmit, post or distribute on the Website ('Your Content') will always be accurate, correct and up to date and you will maintain reasonable records of Your Content.

      (g)	you agree not to harass, impersonate, stalk, threaten another Member of

      the Website (where interaction with other Members is made available to you);

      (h)	access and use of the Website is limited, non-transferable and allows for the sole use of the Website by you for the purposes of providing the Services;

      (i)	you will not use the Services or the Website in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of MobilityGuru Ltd;

      (j)	you will not use the Services or Website for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Website;

      (k)	you agree that commercial advertisements, affiliate links and other forms of solicitation may be removed from Member profiles without notice and may result in termination of the Services. Appropriate legal action will be taken by MobilityGuru Ltd for any illegal or unauthorised use of the Website; and

      (l)	you acknowledge and agree that any automated use of the Website or its Services is prohibited.`;

      this.meSpeakText5 = `5.	Using the Website as the Receiver

      (a)	Selling Products and Services`;

      this.meSpeakText6 = `6.	Using the Website as the Provider

      (a)	Advertising`;

      this.meSpeakText7 = `7.	Payment

      (a)	By MobilityGuru Ltd offering the Services to you, you agree that:

      (i)	20 Ads free

      (b)	All payments made in the course of your use of the Services are made using PayPal or MoibilityGuru. In using the Website, the Services or when making any payment in relation to your use of the Services, you warrant that you have read, understood and agree to be bound by the PayPal or MoibilityGuru terms and conditions which are available on their website.`;

      this.meSpeakText8 = `8.	Refund Policy


      (a)	Since MobilityGuru Ltd is only a facilitator in introducing the Receiver to the Provider and providing a system to make safe payment, MobilityGuru Ltd does not hold any liability to the Receiver directly and will not personally refund them any payments made in the use of Services.

      (b)	Notwithstanding the above clause, if a Receiver is unsatisfied with the services provided by the Provider or believes that they may be entitled to a refund, then MobilityGuru Ltd requires the Receiver to:

      (i)	contact the Provider directly to request a refund; and

      (ii)	if contacting the Provider is not successful after fourteen (14) days, contact MobilityGuru Ltd through the 'Contact Us' section of the Website outlining why you believe you are entitled to a refund so we are able to determine if the Provider should be removed from the Services.

      (c)	If contacted by a Receiver who is requesting a refund pursuant to the above clause, the Provider agrees that it will immediately:

      (i)	complete the MobilityGuru Ltd refund request form (the 'Request Form') provided on the Website; and

      (ii)	provide both the Request Form and the email from the Receiver requesting the refund to MobilityGuru Ltd.

      (d)	If the Provider agrees to a refund it is acknowledged that the Provider will instruct MobilityGuru Ltd to refund all or part of the payments made to the Receiver directly, less any fees and charges incurred by MobilityGuru Ltd in processing the refund.

      (e)	Both the Receiver and Provider agree that they will comply with the Refund Policy contained in this Clause of these Terms.`;

      this.meSpeakText9 = `9.	Copyright and Intellectual Property

      (a)	The Website, the Services and all of the related products of MobilityGuru Ltd are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website, code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by MobilityGuru Ltd or its contributors.

      (b)	All trademarks, service marks and trade names are owned, registered and/or licensed by MobilityGuru Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:

      (i)	use the Website pursuant to the Terms;

      (ii)	copy and store the Website and the material contained in the Website in your device's cache memory; and

      (iii)	print pages from the Website for your own personal and non-commercial use.

      MobilityGuru Ltd does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by MobilityGuru Ltd.

      (c)	MobilityGuru Ltd retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:

      (i)	business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or

      (ii)	a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or

      (iii)	a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process) to you.

      (d)	You may not, without the prior written permission of MobilityGuru Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.

      (e)	Where you broadcast, publish, upload, transmit, post or distribute Your Content on the Website, then you grant to MobilityGuru Ltd a non-exclusive, transferrable, perpetual, royalty-free, irrevocable, worldwide licence to broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change Your Content.`;

      this.meSpeakText10 = `10.	Privacy

      MobilityGuru Ltd takes your privacy seriously and any information provided through your use of the Website and/or Services are subject to MobilityGuru Ltd's Privacy Policy, which is available on the Website.`;

      this.meSpeakText11 = `11.	General Disclaimer


      (a)	Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.

      (b)	Subject to this clause, and to the extent permitted by law:

      (i)	all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and

      (ii)	MobilityGuru Ltd will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.

      (c)	Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of MobilityGuru Ltd make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of MobilityGuru Ltd) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:

      (i)	failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;

      (ii)	the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website);

      (iii)	costs incurred as a result of you using the Website, the Services or any of the products of MobilityGuru Ltd; and

      (iv)	the Services or operation in respect to links which are provided for your convenience.

      (d)	You acknowledge that MobilityGuru Ltd Website and the Services are only intended to facilitate the interactions between the Receiver and the Provider and does not offer any services other than the Services and MobilityGuru Ltd holds no liability to you as a result of any conduct of the Members or the misuse of Your Content by any party (including other Members).`;

      this.meSpeakText12 = `12.	Limitation of Liability

      (a)	MobilityGuru Ltd's total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.

      (b)	You expressly understand and agree that MobilityGuru Ltd, its affiliates, employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.

      (c)	You acknowledge and agree that MobilityGuru Ltd holds no liability for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you as a result of providing Your Content to the Website.`;

      this.meSpeakText13 = `13.	Termination of Contract

      (a)	If you want to terminate the Terms, you may do so by providing MobilityGuru Ltd with Immediately days' notice of your intention to terminate by sending notice of your intention to terminate to MobilityGuru Ltd via the 'Contact Us' link on our homepage.

      (b)	MobilityGuru Ltd may at any time, terminate the Terms with you if:

      (i)	you have breached any provision of the Terms or intend to breach any provision;

      (ii)	MobilityGuru Ltd is required to do so by law;

      (iii)	MobilityGuru Ltd is transitioning to no longer providing the Services to Members in the country in which you are resident or from which you use the service; or

      (iv)	the provision of the Services to you by MobilityGuru Ltd is, in the opinion of MobilityGuru Ltd, no longer commercially viable.

      (c)	Subject to local applicable laws, MobilityGuru Ltd reserves the right to discontinue or cancel your membership at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts MobilityGuru Ltd's name or reputation or violates the rights of those of another party.

      (d)	When the Terms come to an end, all of the legal rights, obligations and liabilities that you and MobilityGuru Ltd have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such rights, obligations and liabilities indefinitely.`;

      this.meSpeakText14 = `14.	Indemnity

      (a)	You agree to indemnify MobilityGuru Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:

      (i)	all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content;

      (ii)	any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so; and/or

      (iii)	any breach of the Terms.`;

      this.meSpeakText15 = `15.	Dispute Resolution

        (a)	Compulsory:

        If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).

        (b)	Notice:

        A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.

        (c)	Resolution:

        On receipt of that notice ('Notice') by that other party, the parties to the Terms ('Parties ') must:

        (i)	Within 5 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;

        (ii)	If for any reason whatsoever, 5 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection

        of a mediator or request that an appropriate mediator be appointed by the President of the Arbitration body or his or her nominee;

        (iii)	The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;

        (iv)	The mediation will be held in Online, Australia.

        (d)	Confidential

        All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.

        (e)	Termination of Mediation:

        If 5 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.
        `;

      this.meSpeakText16 = `
      16.	Venue and Jurisdiction

      The Services offered by MobilityGuru Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Queensland, Australia.`;

      this.meSpeakText17 = `17.	Governing Law

      The Terms are governed by the laws of Queensland, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Queensland, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
      `;

      this.meSpeakText18 = `18.	Independent Legal Advice

      Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
      `;

      this.meSpeakText19 = `
      19.	Severance

      If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.
      `;

      this.meSpeakText20 = `Approved BY: Anthony Cash and Kean Som Oui


      Date: April 30, 2021
      `;

      this.meSpeakText =
        this.meSpeakText1 +
        this.meSpeakText2 +
        this.meSpeakText3 +
        this.meSpeakText4 +
        this.meSpeakText5 +
        this.meSpeakText6 +
        this.meSpeakText7 +
        this.meSpeakText8 +
        this.meSpeakText9 +
        this.meSpeakText10 +
        this.meSpeakText11 +
        this.meSpeakText12 +
        this.meSpeakText13 +
        this.meSpeakText14 +
        this.meSpeakText15 +
        this.meSpeakText16 +
        this.meSpeakText17 +
        this.meSpeakText18 +
        this.meSpeakText19;
    },
  },
};
</script>
