<template>
  <v-dialog v-model="isShowMyPhoneLocal" width="800" persistent>
    <v-card>
      <MyCardTitle title="setPhone" @close-window="closeWindow()"></MyCardTitle>
      <br />

      <!--<v-card-text>
        <MyDropDown
          itemName="Country:"
          :dropDownItem="country"
          :dropDownItems="countries"
          @update-items="updateCountry"
        ></MyDropDown>
      </v-card-text>-->

      <v-card-text>
        <!-- <MyDropDown
          itemName="My Phone Number is a:"
          :dropDownItem="phoneType"
          :dropDownItems="phoneTypes"
          @update-items="updatePhoneType"
        ></MyDropDown>

        <br />-->
        <!--
        <MyDropDown
          v-if="phoneType == 'Phone Number'"
          itemName='Area Code:'
          :dropDownItem='areaCode'
          :dropDownItems='areaCodes'
          @update-items='updateAreaCode'></MyDropDown>

        <br/>
        -->
        <!-- <MyDropDown
          v-if="phoneType == 'Phone Word'"
          itemName="Phone Word Prefix:"
          :dropDownItem="phoneWordPrefix"
          :dropDownItems="phoneWordPrefixes"
          @update-items="updatePhoneWordPrefix"
        ></MyDropDown>-->

        <v-text-field
          v-model="phonePostfix"
          :placeholder="placeHolderText"
          @input="checkIsValid()"
          :type="phonePostfixType"
          :class="phonePostfixClass"
        >
          <template v-slot:prepend>
            <MyDropDown
              v-if="isPrefixArray === true"
              itemName=""
              :dropDownItem="prefixArrayItem"
              :dropDownItems="prefixArrayItems"
              @update-items="updatePhoneWordPrefix"
            ></MyDropDown>
            <span v-else>{{ phonePrefix }}</span>
          </template>
        </v-text-field>

        <v-layout justify-center>
          <MyBtn
            btnName="done"
            :disabled="!isValidPhone"
            :iconName="mdiThumbUpOutline"
            @btn-clicked="finalizePhone()"
          >
          </MyBtn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiThumbUpOutline } from "@mdi/js";

import Config from "../config.js";
import MyBtn from "./MyBtn";
import MyDropDown from "./MyDropDown";
import MyCardTitle from "./MyCardTitle";

export default {
  name: "MyPhone",
  components: {
    MyBtn,
    MyCardTitle,
    MyDropDown,
  },
  props: ["isShowMyPhone", "origPhone", "phonePrefixNum"],
  data: () => ({
    isShowMyPhoneLocal: false,

    mdiThumbUpOutline: mdiThumbUpOutline,

    isPrefixArray: false,
    prefixArrayItems: [],
    prefixArrayItem: "",

    country: Config.COUNTRY_OZ,
    countries: [Config.COUNTRY_OZ],

    phoneType: "Phone Number",
    phoneTypes: ["Phone Number", "Phone Word"],

    /*
    areaCode: '"04", Mobile Phones, (Australia)',
    areaCodes: [
      '"02", Central East, (NSW, ACT)',
      '"03", South East, (VIC, TAS)',
      '"04", Mobile Phones, (Australia)',
      '"07", North East, (QLD)',
      '"08", Central & West, (WA, SA, NT)'
    ],
    areaCodeUse: '4',
    areaCodesUse: [
      '2',
      '3',
      '4',
      '7',
      '8'
    ],
    */

    phoneWordPrefix: "1800",
    phoneWordPrefixes: ["13", "1300", "1800"],

    phonePrefix: "",
    phonePostfix: "",
    placeHolderText: "412345678",
    phonePostfixType: "number",
    phonePostfixClass: "inputNoSpinners",
    isValidPhone: false,
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.isShowMyPhoneLocal = this.isShowMyPhone;
      this.debug(`MyPhone init ${this.isShowMyPhoneLocal}`);
      this.updatePhonePrefix();
      this.checkIsValid();
    },

    /*
    getPhonePostfix() {
      if (this.origPhone.length > 0) {
        this.phonePostfix = this.origPhone.substring(
      }
    }
    */

    closeWindow() {
      this.isShowMyPhoneLocal = false;
      this.$emit("close-window");
    },

    updateCountry(index) {
      if (this.country != this.countries[index]) {
        this.country = this.countries[index];
      }
    },

    updatePhoneType(index) {
      this.phoneType = this.phoneTypes[index];
      this.updatePhonePrefix();
    },

    /*
    updateAreaCode(index) {
      this.areaCode = this.areaCodes[index]
      this.areaCodeUse = this.areaCodesUse[index]
      this.updatePhonePrefix()
    },
    */

    updatePhoneWordPrefix(index) {
      this.phonePrefix = this.prefixArrayItems[index];
      this.prefixArrayItem = this.phonePrefix;
      this.checkIsValid();
      //this.updatePhonePrefix();
    },

    updatePhonePrefix() {
      /*if (this.country == Config.COUNTRY_OZ) {
        this.phonePrefix = "+61";
      }*/
      this.debug(
        `country code ${typeof this.phonePrefixNum} Number: ${
          this.phonePrefixNum
        }`
      );
      let phonePrefixNumType = typeof this.phonePrefixNum;
      if (phonePrefixNumType === "number") {
        this.isPrefixArray = false;
        this.phonePrefix = `+${this.phonePrefixNum}`;
      } else if (phonePrefixNumType === "object") {
        this.isPrefixArray = true;
        this.prefixArrayItems = [];
        for (let i = 0; i < this.phonePrefixNum.length; i++) {
          this.prefixArrayItems.push(`+${this.phonePrefixNum[i]}`);
        }
        this.prefixArrayItem = this.prefixArrayItems[0];
        this.phonePrefix = this.prefixArrayItem;
      }
      this.placeHolderText = "412345678";

      /*if (this.phoneType == "Phone Number") {
        //        this.phonePrefix += this.areaCodeUse
 
        this.phonePostfixType = "number";
        this.phonePostfixClass = "inputNoSpinners";
        this.debug("n");
      } else {
        this.phonePrefix += this.phoneWordPrefix;
        this.placeHolderText = "Please complete phone word.";
        this.phonePostfixType = "text";
        this.phonePostfixClass = "";
        this.debug("t");
      }*/
      this.phonePostfix = "";
    },

    checkIsValid() {
      let isValid = false;

      let phoneNumber = this.phonePrefix + this.phonePostfix;

      if (
        this.phonePostfix.length > 7 &&
        this.phonePostfix.length < 11 &&
        phoneNumber != this.origPhone
      ) {
        isValid = true;
      }

      /* if (this.phoneType == "Phone Number") {
        if (this.phonePostfix.length == 9 && phoneNumber != this.origPhone) {
          isValid = true;
        }
      } else {
        // phoneType = Phone Word

        if (
          this.phonePostfix.length > 0 &&
          this.phonePostfix.length < 10 &&
          phoneNumber != this.origPhone
        ) {
          isValid = true;
        }
      }*/

      this.isValidPhone = isValid;
    },

    finalizePhone() {
      if (this.isValidPhone) {
        this.isValidPhone = false;
        this.$emit("phone-finalized", this.phonePrefix + this.phonePostfix);
      }
    },
  }, // methods
};
</script>
