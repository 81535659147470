<template>
  <SubmitAdHelper :adId="adId"> </SubmitAdHelper>
</template>

<script>
//  minLength,
import Config from "../config.js";

import SubmitAdHelper from "./SubmitAdHelper";

export default {
  name: "EditAd",
  components: {
    SubmitAdHelper,
  },
  props: [],

  //  computed: () => ({
  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({
    adId: "blahblah",
  }),

  validations: {},

  beforeMount: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    init() {
      this.adId = this.$route.query.id;
      this.debug("EditAd.vue adId = " + this.adId);
    },
  }, // methods
};
</script>
