<template>
  <v-card
    :key="cardKey"
    class="ma-2 mgcard"
    :color="isSelected ? 'orange' : ''"
    :width="$vuetify.breakpoint.xs ? screenWidth * 0.83 : 300"
  >
    <v-list-item
      dense
      v-if="mainCategory == MISCELLANEOUS_STR"
      @click="mainCategoryClicked(mainCategory, '')"
    >
      <v-list-item-avatar left tile>
        <img height="40" width="40" class="pa-1" :src="avatar" />
      </v-list-item-avatar>
      <v-list-item-title>{{ $t(`${mainCategory}`) }}</v-list-item-title>

      <v-list-item-action>
        <v-icon> {{ mdiChevronDown }}</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-menu
      style="z-index: 10000000000"
      v-else
      transition="slide-x-transition"
      bottom
      :max-width="
        !$vuetify.breakpoint.xs
          ? 280
          : LONG_STR_CATEGORY_NAMES.includes(mainCategory)
          ? 240
          : 210
      "
      :offset-x="true"
      open-on-hover
    >
      <template v-slot:activator="{ on, attrs }">
        <!--<v-btn color="primary" dark v-bind="attrs" v-on="on"> Dropdown </v-btn>-->
        <v-list-item
          dense
          v-bind="attrs"
          v-on="on"
          @click="mainCategoryClicked(mainCategory, '')"
        >
          <v-list-item-avatar left tile>
            <img height="40" width="40" class="pa-1" :src="avatar" />
          </v-list-item-avatar>
          <v-list-item-title>{{ $t(`${mainCategory}`) }}</v-list-item-title>

          <v-list-item-action>
            <v-icon> {{ mdiChevronDown }}</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-list class="mgdrop">
        <v-list-item
          @mouseover="changeColor"
          @mouseleave="isSelected = false"
          class="mgbtn"
          dense
          @click="finalizeCategories(mainCategory, subCategory)"
          v-for="(subCategory, index) in subCategories"
          :key="index"
        >
          <v-list-item-icon>
            <img
              height="30"
              width="30"
              :src="`/icons/${mainCategory}/${
                subCategory == 'Wheelchair & Scooter Parts/Accs'
                  ? 'Wheelchair & Scooter PartsAccs'
                  : subCategory
              }.png`"
            />
          </v-list-item-icon>
          <v-list-item-title>{{ $t(`${subCategory}`) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
  <!-- <div>
    <div
      class="text-center orangeText"
      @click="finalizeCategories(mainCategory, '')"
    >
      <v-row class="" justify="center">
        <img height="80" width="85" class="pa-1" :src="avatar" />
      </v-row>
      <br />
      <b>{{ mainCategory }}</b>
    </div>
    <v-divider></v-divider>
    <v-list-item-group>
      <v-card
        elevation="0"
        height="25"
        @click="finalizeCategories(mainCategory, subCategory)"
        dense
        class="orangeText"
        v-for="(subCategory, index) in subCategories"
        :key="index"
      >
        <span class="ml-2">{{ subCategory }}</span>
      </v-card>
    </v-list-item-group>
  </div>-->
</template>

<script>
import { mdiChevronDown } from "@mdi/js";
export default {
  name: "CategoryCell",
  props: ["mainCategory", "subCategories", "avatar"],

  computed: {},
  mounted: async function () {
    await this.init();
  },
  created() {
    window.addEventListener("resize", this.changeWidth);
  },

  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },

  data: () => ({
    mdiChevronDown: mdiChevronDown,
    MISCELLANEOUS_STR: "Miscellaneous",
    LONG_STR_CATEGORY_NAMES: ["Assistive Technology", "Mobility Transport"],
    screenWidth: 250,
    isSelected: false,
    cardKey: 0,
  }),
  methods: {
    async init() {
      this.changeWidth();
    },

    mainCategoryClicked(main) {
      if (this.MISCELLANEOUS_STR == main) {
        this.finalizeCategories(main, "");
      }
    },
    finalizeCategories(main, sub) {
      this.$emit("categories-finalized", main, sub);
    },

    changeWidth() {
      if (this.$vuetify.breakpoint.xs) {
        this.screenWidth = window.innerWidth;
      } else {
        this.screenWidth = 300;
      }
      //  this.debug("width arranged");
    },
    changeColor() {
      this.isSelected = true;
      //   this.cardKey++;
      // this.debug("aaaa");
    },
  },
};
</script>
