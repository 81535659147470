<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            <strong
              >If want Purchase Ads you have 3 ways to do Purchase Ads</strong
            >
            <MeSpeak
              text="If want Purchase Ads you have 3 ways to do Purchase Ads"
            >
            </MeSpeak>
          </li>

          <li>
            First way is Press the <strong>My MobilityGuru</strong> button in
            the menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> like as a
            below.
            <MeSpeak
              text="First way is Press the My MobilityGuru button in the menu dialog/ Profile like as a below"
            >
            </MeSpeak>

            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
          </li>
          <br />
          <li>
            After clicking the <strong>My MobilityGuru</strong> button, your
            profile will pop up and you have to Click the
            <a href="https://mobilityguru.org/purchase-ads">PURCHASE ADS</a>
            button.
            <MeSpeak
              text="
            After clicking the My MobilityGuru button, your profile will pop up and you have to Click the purchase Ads button."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/paidAdsPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="paidAdsPc"
              />
              <v-img
                :src="require(`./pics/paidAdsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="paidAdsMob"
              />
            </ul>
            <br />
          </li>

          <li>
            The second way is when you select the countries from bottom sheet,
            it will appear how many ads are left that you can post. And if
            another country select is made in the event that ads are not
            available, then popup message will appear from bottom for
            <strong>Purchase Ads</strong> and you can click on it.

            <MeSpeak
              text="Second way is when you select the countries from bottom sheet, it will appear how many ads are left that you can post. 
              And if another country select is made in the event that ads are not available, then popup message will appear from bottom for Purchase Ads nd you can click on it."
            >
            </MeSpeak>

            <ul>
              <v-img
                :src="require(`./pics/countryLimitPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="countryLimitPc"
              />
              <v-img
                :src="require(`./pics/countryLimitMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="countryLimitMob"
              />
            </ul>
          </li>
          <br />

          <li>
            The third way is when you going to submit an ad if you are running
            out of Ads, then a popup message will appear, and you can click
            Acknowledge button in there and it will be directed to the
            <strong>Purchase Ads</strong> page.

            <MeSpeak
              text="The third way is when you going to submit an ad if you are running out of Ads, 
              then a popup message will appear, and you can click Acknowledge button in there and it will be directed to the purchase ads page"
            >
            </MeSpeak>

            <ul>
              <v-img
                :src="require(`./pics/20AdsPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="20AdsPc"
              />
              <v-img
                :src="require(`./pics/20AdsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="20AdsMob"
              />
            </ul>
          </li>
          <br />

          <li>
            Now you can see the <strong>purchase Ads</strong> page<MeSpeak
              text="
           Now you can see the purchase Ads page"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/purchesAdsPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="purchesAdsPc"
              />
              <v-img
                :src="require(`./pics/purchesAdsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="purchesAdsMob"
              />
            </ul>
            <br />
          </li>

          <li>
            Select the number of ads how much you wants to buy
            <MeSpeak
              text="Select the number of ads how much you wants to buy"
            ></MeSpeak>
          </li>

          <li>
            <strong
              >All Additional ads purchased are valid for 365 days from the date
              of purchase.</strong
            >
            <MeSpeak
              text="All Additional ads purchased are valid for 365 days from the date of purchase."
            ></MeSpeak>
          </li>

          <li>
            And click to <strong>Confirm Proceed to Payment</strong>
            <MeSpeak text="And click to Confirm $ Proceed to Payment"></MeSpeak>
          </li>
          <br />

          <li>
            Select your prefer payment method<MeSpeak
              text="Select your prefer payment method."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/adPayMethodPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="adPayMethodPc"
              />
              <v-img
                :src="require(`./pics/adPayMethodMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="adPayMethodMob"
              />
            </ul>
            <br />
          </li>
          <br />
          <li>
            Choose the <strong>Payment Method</strong> you are willing to pay.
            <MeSpeak
              text="Choose the payment method you are willing to pay."
            ></MeSpeak>
          </li>

          <li>
            <strong>Credit/ Debit Card</strong> - If you are willing to pay
            using <strong>Credit/Debit Card</strong>, you can fill the
            form.<MeSpeak
              text="Credit/ Debit Card- If you are willing to pay using Credit/Debit Card, you can fill the form."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/cardPayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="cardPayment"
                />
              </v-row>
            </ul>
          </li>
          <br />
          <li>
            <strong>PayPal</strong> - If you are willing to pay using
            <strong>PayPal</strong>, you can fill the form.<MeSpeak
              text="PayPal- If you are willing to pay using PayPal, you can fill the form."
            ></MeSpeak>

            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/pay2.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="Pay2"
                /> </v-row
              ><br />
              <ul>
                <br />
                <v-row justify="center">
                  <v-img
                    :src="require(`./pics/paypal.png`)"
                    align-center
                    height="650"
                    width="450"
                    contain
                    alt="paypal"
                  />
                </v-row>
              </ul>
            </ul>
            <br /><br />
          </li>
          <br />
          <li>
            <strong>Pay Using Guru Token</strong> - If you are willing to pay
            using <strong>Guru Token</strong>, you can select a
            <strong>wallet and scan the QR code</strong> relevant to
            wallet.<MeSpeak
              text="Pay Using Guru Token- If you are willing to pay using Guru Token, you can select a wallet and scan the QR code relevant to wallet."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/payDepay.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="payDepay"
                />
              </v-row>
            </ul>
          </li>

          <ul>
            <v-row justify="center">
              <v-img
                :src="require(`./pics/walletQR.png`)"
                align-center
                height="650"
                width="450"
                contain
                alt="walletQR"
              />
            </v-row>
          </ul>
          <br />

          <br />

          <li>
            After scanning the <strong>QR code</strong> you will see the popup
            for the payment. and also there are show payment values of
            <strong>GURU token</strong> and
            <strong>your currency</strong>.<MeSpeak
              text="After scanning the QR code you will see the popup for the payment. and also there are show payment values of GURU token and your currency."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/changePayment.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="changePayment"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <br />
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
