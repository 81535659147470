<template>
  <div>
    <v-container class="" fluid>
      <v-row align="center" justify="center" class="ma-1">
        <v-col lg="3" md="3" sm="12">
          <v-row justify="center">
            <v-card
              class="mb-1 transparent"
              width="260"
              align="center"
              height="435"
              :key="cardKey"
            >
              <MyBtn
                btnName="submitAd"
                :iconName="mdiSendOutline"
                @btn-clicked="gotoSubmitAd()"
                class="mt-1 mr-2"
              >
              </MyBtn>
              <!--<v-img
                :src="logo"
                alt="MobilityGuru Logo"
                contain
                height="150"
              ></v-img>-->
              <a
                href="https://www.omdpethats.com/"
                target="_blank"
                style="text-decoration: none"
              >
                <v-img
                  transition="scale-transition"
                  class="mt-1"
                  :src="OmdPetHats"
                  alt="OMD Pet Hats"
                  contain
                  width="260"
                  height="190"
                >
                  <v-btn x-small color="transparent" elevation="0" class="mt-1"
                    ><span class="black--text"
                      >Sunny Companian Hats
                    </span></v-btn
                  >
                  <v-btn
                    x-small
                    color="blue-grey darken-2"
                    class="mgcenter"
                    dark
                    ><span>{{ $t(`clickVisit`) }}</span>
                  </v-btn>
                </v-img>
              </a>
              <!--  <h2>MOBILITYGURU LTD</h2>-->

              <h3 class="text-center">
                <!--  {{ slogan }}-->
                {{ $t(`homepage_title`) }}
                <!--{{ $t(`message.slogan`) }}-->
              </h3>

              <div v-if="isLoadingStats">
                <v-progress-circular
                  color="green"
                  indeterminate
                  v-if="isLoadingStats"
                ></v-progress-circular>
              </div>

              <div v-else>
                <b class="deep-purple--text text-h5 notranslate">
                  <span>{{ statCurrencyCode }} </span>
                  <AnimatedCounter
                    :delay="delay"
                    :isDouble="true"
                    :value="totalAdsValue"
                  ></AnimatedCounter>
                </b>
                <h3 class="text-center deep-purple--text">
                  <!--  Value - Items Posted-->
                  {{ $t(`homepage_subtitle2`) }}
                </h3>
                <!--  <h4>
                  {{ $store.state.countryByIP }} {{ countryNumOfAds }} ads
                </h4>
                
                <h4 class="text-center">
                  <AnimatedCounter
                    :delay="delay"
                    :value="numberOfUsers"
                  ></AnimatedCounter>
                  Users,
                  <AnimatedCounter
                    :delay="delay"
                    :value="numberOfAds"
                  ></AnimatedCounter>
                  Ads
                </h4>
                -->
              </div>

              <v-row justify="space-between" no-gutters>
                <span class="mr-4"></span>
                <v-row class="" justify="center" no-gutters>
                  <MeSpeak streamKey="slogan" :text="slogan"></MeSpeak>
                </v-row>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-card class="mr-1 transparent" elevation="0">
                      <v-progress-circular
                        color="green"
                        indeterminate
                        v-if="isLoadingStats"
                      ></v-progress-circular>
                      <v-icon
                        class="mgicon"
                        v-on="on"
                        @click="showCharts"
                        color="green"
                        v-if="isLoadingStats == false"
                      >
                        {{ mdiChartLine }}</v-icon
                      >
                    </v-card>
                  </template>
                  <span>
                    <!--{{ $t(`message.metrics_tooltip`) }}-->{{
                      $t(`metrics`)
                    }}
                  </span>
                </v-tooltip>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
        <v-col lg="9" md="9" sm="12">
          <v-row justify="center" class="">
            <v-col cols="12" lg="7">
              <v-card class="fillColour" align="center" elevation="0">
                <v-carousel
                  hide-delimiters
                  height="310"
                  :cycle="true"
                  class="fillColour hidden-sm-and-up"
                  vertical-delimiters
                >
                  <GlobalBanner></GlobalBanner>

                  <v-carousel-item
                    :key="`${index}${pic}`"
                    v-for="(pic, index) in topCorousel"
                  >
                    <v-card
                      elevation="0"
                      id="imageCarousel"
                      class="fillColour"
                      min-height="250"
                      max-height="300"
                      min-width="300"
                      height=""
                    >
                      <!--
                    <div v-if="index === 0">
                      <a
                        href="https://mobilityguru.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img id="imageCarousel" :src="pic.image" alt="" />
                      </a>
                    </div>

                    <div v-else>
                    --->

                      <!-- TODO: why doensn't the link open in a new tab?
                    -->
                      <a
                        @click="increaseBannerAdClick(pic.id)"
                        v-if="pic.url.length > 0"
                        :href="pic.url"
                        target="_blank"
                      >
                        <v-img
                          contain
                          id="imageCarousel"
                          transition="scale-transition"
                          :src="pic.image"
                          alt=""
                        >
                          <v-btn
                            x-small
                            color="blue-grey darken-2"
                            class="mgcenter"
                            dark
                            ><span>{{ $t(`clickVisit`) }}</span>
                          </v-btn>
                        </v-img>
                      </a>
                      <v-img
                        transition="scale-transition"
                        contain
                        v-else
                        id="imageCarousel"
                        :src="pic.image"
                        alt=""
                      />
                      <!---
                    </div>
                    --->
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  :show-arrows="false"
                  height="400"
                  :cycle="true"
                  class="fillColour"
                  vertical-delimiters
                  v-show="$vuetify.breakpoint.xs != true"
                >
                  <GlobalBanner></GlobalBanner>

                  <v-carousel-item
                    :key="`${index}${pic}`"
                    v-for="(pic, index) in topCorousel"
                  >
                    <v-card
                      elevation="0"
                      id="imageCarousel"
                      class="fillColour"
                      min-width="320"
                    >
                      <!---
                         width="800"
                    <div v-if="index === 0">
                      <a
                        href="https://mobilityguru.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img id="imageCarousel" :src="pic.image" alt="" />
                      </a>
                    </div>

                    <div v-else>
                    --->
                      <a
                        v-if="pic.url.length > 0"
                        :href="pic.url"
                        @click="increaseBannerAdClick(pic.id)"
                        target="_blank"
                      >
                        <v-img
                          contain
                          id="imageCarousel"
                          :src="pic.image"
                          transition="scale-transition"
                          alt=""
                        >
                          <v-btn
                            x-small
                            color="blue-grey darken-2"
                            class="mgcenter"
                            dark
                            ><span>{{ $t(`clickVisit`) }}</span>
                          </v-btn>
                        </v-img>
                      </a>
                      <v-img
                        transition="scale-transition"
                        contain
                        v-else
                        id="imageCarousel"
                        :src="pic.image"
                        alt=""
                      />
                      <!--
                    </div>
                    -->
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
                <!--
              <img
              :src="basketball"
              alt="group of people in wheelchair playing basketball"
              style="object-fit: contain; width: 500px;"
              />
              -->
                <h2 class="text-center">
                  <!-- Advertise on mobilityguru.org-->
                  {{ $t(`homepage_subtitle1`) }}
                  <!--{{ $t(`message.banner_caption`) }}-->
                  <MeSpeak
                    streamKey="home1"
                    text="Free advertising on mobilityguru.org"
                  ></MeSpeak>
                </h2>
              </v-card>
            </v-col>

            <v-col
              lg="5"
              v-show="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
            >
              <v-card elevation="0" class="transparent" align="center">
                <v-img height="400" :src="adventure" alt="" contain></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!--<v-row justify="end" no-gutters class="mr-2 mt-1 mb-n3">
      <SelectCountry @country-finalized="changeCountry"></SelectCountry>
    </v-row>-->
    <v-container>
      <h2 class="text-center">
        <!-- Random Ads-->
        {{ $t(`randomAds`) }}
        <!-- {{ $t(`message.random_ads`) }}-->
        <MeSpeak streamKey="home2" text="Random Ads"></MeSpeak>
      </h2>

      <br />

      <!--div class="text-center" v-if="isLoading">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </div>-->
      <v-row v-if="isLoading" justify="center">
        <v-card
          min-width="250px"
          max-width="250px"
          v-for="n in $vuetify.breakpoint.xs ? 3 : 6"
          :key="n"
          class="ma-1"
          outlined
        >
          <SkeletonLoader type="text, image, paragraph"> </SkeletonLoader>
        </v-card>
      </v-row>
      <v-row justify="center">
        <v-card
          v-for="(randomAd, index) in randomAds"
          :key="index"
          min-width="250px"
          max-width="250px"
          class="ma-1 cellBorder"
          hover
        >
          <AdCell :ad="randomAd" :adType="adType"></AdCell>
        </v-card>

        <!--
        <v-card
          :key="ads[currentIndex].id"
          @click="gotoAdView(ads[currentIndex].id, adType)"
          min-width="250px"
          max-width="250px"
          class="ma-1"
          hover
        >
          <AdCell :ad="ads[currentIndex]" :adType="adType"></AdCell>
        </v-card>
        -->
      </v-row>

      <!--
      <v-row justify="center">
        <MyBtn
          :iconName="mdiSkipPrevious"
          @btn-clicked="decrementAdIndex()"
          tooltip="Previous"
        ></MyBtn>

        <MyBtn
          :iconName="mdiSkipNext"
          @btn-clicked="incrementAdIndex()"
          tooltip="Next"
        ></MyBtn>
      </v-row>
      -->
    </v-container>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
      :showActionBtn="showActionBtn"
      :actionName="actionName"
      @action-clicked="goToProfileWithHash(hashProfile)"
    ></MyAcknowledge>

    <StatCharts
      :key="chartKey"
      :labelAndDataSets="labelAndDataSets"
      @close-window="closeCharts"
      :isShowCharts="isShowCharts"
      :currencyCode="statCurrencyCode"
    ></StatCharts>

    <!--
    <Loading :isLoading="isLoading"></Loading>
    -->
  </div>
</template>

<script>
import {
  mdiMinus,
  mdiSendOutline,
  mdiSkipNext,
  mdiSkipPrevious,
  mdiChartLine,
  //  mdiHeart,
  // mdiHeartOutline,
} from "@mdi/js";

// import MyIcon from "../components/MyIcon.vue";
import AdCell from "./AdCell";
import MyAcknowledge from "./MyAcknowledge";
import Config from "../config.js";

import importedProstheticLimb from "./pics/prostheticLimb.jpg";
import adventure from "./pics/adventure.jpg";
//import importedVan from "./pics/van-with-wheelchair-access.jpg";
import importedBasketball from "./pics/MobilityGuru Basketball_90.jpg";
import importedLogo from "./pics/logo.png";
import gifLogo from "./pics/logo.gif";
import universalStore from "./pics/van-with-wheelchair-access.jpg";
//import AmanaTakaful from "./pics/amanaTakaful.jpg";
import OmdPetHats from "./pics/omdpethats.jpg";
//import importedMGTokenInfo from "./pics/MobilityGuru Token Information.jpg";
//import promoteBanner from "./pics/promote-here-for-great-exposure.jpg";
//import importedEmail from "./pics/email.jpg";

//import Loading from "./Loading";
import MeSpeak from "./MeSpeak";
import MyBtn from "./MyBtn";

import StatCharts from "./StatCharts";
import AnimatedCounter from "./AnimatedCounter";
//import SelectCountry from "./SelectCountry";
import GlobalBanner from "./GlobalBanner.vue";
import SkeletonLoader from "./SkeletonLoader.vue";

export default {
  name: "Home",
  components: {
    AdCell,
    //Loading,
    MyBtn,
    MyAcknowledge,
    MeSpeak,
    StatCharts,
    AnimatedCounter,
    //SelectCountry,
    GlobalBanner,
    SkeletonLoader,
  },
  metaInfo: {
    title: "Mobility Guru Home",
    //titleTemplate: "%s | Buy and Sell Australia",
    meta: [
      {
        name: "description",
        content:
          "Free Advertising to sell anything for the disability services industry.",
      },
      {
        name: "keywords",
        content:
          "free, classified, ads, advertisements, Disabled, Disability, Services, Elderly, marketplace",
      },
    ],
  },

  watch: {
    onLogout(newState, oldState) {
      this.debug(`${oldState} --->${newState}`);
      if (newState == false) {
        this.updateMetrics();
      }
    },
  },
  computed: {
    onLogout() {
      return this.$store.state.isLoggedIn;
    },

    AddFavourite() {
      let updateFavourite = false;
      if (
        this.$store.state.isSuperAdmin === true ||
        this.$store.state.favouriteAds === true ||
        this.$store.state.isForumModerator == true
      ) {
        updateFavourite = true;
      }
      this.debug(`updateFavourite = ${updateFavourite}`);
      return updateFavourite;
    },
  },
  data: () => ({
    chartIndex: 0,
    chartKey: "chart0",
    myAcknowledgeKey: 0,
    mdiMinus: mdiMinus,
    mdiChartLine: mdiChartLine,
    mdiSendOutline: mdiSendOutline,
    mdiSkipPrevious: mdiSkipPrevious,
    mdiSkipNext: mdiSkipNext,
    numberOfUsers: 0,
    numberOfAds: 0,
    totalAdsValue: 0,
    currencyConverted: true,
    statCurrencyCode: "",
    favourite: false,
    // mdiHeart: mdiHeart,
    // mdiHeartOutline: mdiHeartOutline,

    slogan:
      "MobilityGuru classified ads marketplace for products and services for the disability community.",

    BACKEND00_FILES_URL: Config.BACKEND00_FILES_URL,
    adType: Config.AD_TYPES_ENUM.PUBLIC_ADS,

    prostheticLimb: importedProstheticLimb,
    //van: importedVan,
    basketball: importedBasketball,
    //raffle2020: raffle2020,
    //promoteBanner: promoteBanner,
    topCorousel: [],
    adventure: adventure,

    logo: importedLogo,
    gifLogo: gifLogo,
    universalStore: universalStore,
    //AmanaTakaful: AmanaTakaful,
    //MGTokenInfo: importedMGTokenInfo,
    //emailPic: importedEmail,

    OmdPetHats: OmdPetHats,

    showSubmitAdBtn: false,

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",

    picsDir: "./pics",

    ads: [],
    adIds: [],

    randomAdIndexes: [],
    currentIndex: 0,
    cycleTimer: 6000, // random ads cycle timer ms

    isLoading: false,
    collectionRotatingBannerAds: null,

    isShowCharts: false,
    collectionMetricsData: [],
    labelAndDataSets: {},
    isLoadingStats: false,
    cardKey: 0,
    delay: 2000, // milliseconds
    adsIndex: 0,
    adsKey: "ads0",
    showActionBtn: false,
    actionName: "",
    bannerAds: [],
    randomAds: [],
    hashProfile: "",
    countryNumOfAds: 0,
  }),
  mounted: function () {
    this.init();
    this.favourite = this.$store.state.favouriteAds.includes(
      this.$route.query.id
    );
  },
  methods: {
    async init() {
      //this.slogan = this.$t(`message.slogan`);
      console.log("home init");
      /* if (this.$store.isLoggedIn === true) {
        if (await this.isLoggedInOIDC()) {
          this.$router.push(Config.ROUTE_LOGIN);
        }
      }*/

      this.isLoading = true;

      let start = new Date();

      if (this.topCorousel.length === 0) {
        //this.addRotatingImage(this.promoteBanner, "");
        //this.addRotatingImage(this.MGTokenInfo, "https://mobilityguru.io/");
        //this.addRotatingImage(this.prostheticLimb, "");
        //this.addRotatingImage(this.basketball, "", "");
      }
      await this.updateMetrics();

      if (Config.IS_DEV === true) {
        let a = process.env;
        this.debug(`process.env = ${a}`);
      }

      // Flag sync as false to force refresh whenever user goes to /home
      //this.$store.commit("set", ["syncedAds", false]);
      //this.$store.commit("set", ["syncedLiveAdIds", false]);
      //this.$store.commit("set", ["syncedMyAdIds", false]);

      // await this.getRandomAdIndexes();

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`Home load time = ${diffs} s`);

      //await this.synchSetTimeout(1);
      this.isLoading = false;
      //await this.synchSetTimeout(1);

      //  this.nextAdIndexTimer();
    },

    nextAdIndexTimer() {
      this.currentIndex = (this.currentIndex + 1) % this.randomAdIndexes.length;
      setTimeout(() => {
        this.nextAdIndexTimer();
      }, this.cycleTimer);
    },

    incrementAdIndex() {
      this.currentIndex = (this.currentIndex + 1) % this.randomAdIndexes.length;
    },

    decrementAdIndex() {
      this.currentIndex--;
      if (this.currentIndex === -1) {
        this.currentIndex = this.randomAdIndexes.length - 1;
      }
    },

    /*getNextUniqueIndex(adsTotal) {
      let len = this.randomAdIndexes.length;
      let unique = false;
      while (unique == false) {
        let index = Math.round(Math.random() * (adsTotal - 1));

        let found = false;
        for (let i = 0; i < len; i++) {
          if (this.randomAdIndexes[i] == index) {
            found = true;
            break;
          }
        }

        if (found == false) {
          unique = true;
          this.randomAdIndexes.push(index);
        }
      }
    },

    //TODO get random ads from backend
    async getRandomAdIndexes() {
      let result = await this.refreshLiveAdIds(
        0,
        this.numberOfAds,
        {},
        {},
        undefined
      );
      this.ads = result.ads;
      this.adIds = result.adIds;

      let adsTotal = this.adIds.length;
      this.debug(`adsTotal = ${adsTotal}`);

      let maxRandomAds = Math.min(Config.MAX_RANDOM_ADS, adsTotal);

      for (let i = 0; i < maxRandomAds; i++) {
        this.getNextUniqueIndex(adsTotal);
      }
      for (let j = 0; j < this.randomAdIndexes.length; j++) {
        this.randomAds.push(this.ads[this.randomAdIndexes[j]]);
      }
      result = await this.translateMultipleAds(this.randomAds);
      if (result.ok == true) {
        this.randomAds = result.ads;
      }
      this.debug(this.randomAdIndexes);
    },*/

    async goToRoute(routeStr) {
      await this.synchSetTimeout(1);
      this.isShowMenu = false;
      await this.synchSetTimeout(1);

      if (this.$route.path != routeStr) {
        this.$router.push(routeStr);
      }
    },

    async gotoSubmitAd() {
      /*if (
        this.$store.state.isLoggedIn === true &&
        this.$store.state.accessTokenDecoded.email_verified === true
      ) {
        await this.goToRoute(Config.ROUTE_SUBMIT_AD);
      } else {
        this.acknowledgeTitle = "Email Unverified";
        this.acknowledgeMessage =
          "Please note you need to be a Signed up member to submit an ad. Please Signup or Login to place a free ad.";
        // "Please verify your email before submitting an ad.";*/
      let res = await this.isSubmitAdAvailable();
      if (res.ok === true) {
        this.showAcknowledge = true;
        this.acknowledgeTitle = res.acknowledgeTitle;
        this.acknowledgeMessage = res.acknowledgeMessage;
        this.showActionBtn = res.showActionBtn;
        this.actionName = res.actionName;
        if (this.acknowledgeTitle == "Set Address") {
          this.hashProfile = "#SetAddress";
        } else {
          this.hashProfile = "#verifyEmail";
        }
        this.showAcknowledge = true;
        this.myAcknowledgeKey++;
      }
      // }
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKey++;
    },

    showCharts() {
      this.chartIndex++;
      this.chartKey = `chart${this.chartIndex}`;
      this.isShowCharts = true;
    },
    closeCharts() {
      this.isShowCharts = false;
      this.chartIndex++;
      this.chartKey = `chart${this.chartIndex}`;
    },

    async updateMetrics() {
      this.isLoadingStats = true;

      let statsResult = await this.getStats();

      //this.cardKey++;
      if (statsResult.ok === true) {
        this.numberOfUsers = statsResult.numberOfUsers;
        this.numberOfAds = statsResult.numberOfAds;
        this.totalAdsValue = statsResult.totalAdsValue;
        this.currencyConverted = statsResult.currencyConverted;
        //this.collectionMetricsData = statsResult.collectionMetricsData;
        //this.labelAndDataSets = statsResult.labelAndDataSets;
        this.statCurrencyCode = statsResult.currencyCode;

        this.bannerAds = statsResult.bannerAds;
        this.randomAds = statsResult.randomAds;
        this.countryNumOfAds = statsResult.countryNumOfAds;
        this.addRotatingBannerAds(this.bannerAds);
        /*let result = await this.translateMultipleAds(this.randomAds);
        if (result.ok == true) {
          this.randomAds = result.ads;
        }*/
      }
      this.isLoadingStats = false;

      console.log(`isLoadingStats = ${this.isLoadingStats}`);
    },
    /*async changeCountry() {
      this.isLoading = true;
      this.$store.commit("set", ["syncedAds", false]);
      this.$store.commit("set", ["syncedLiveAdIds", false]);
      this.$store.commit("set", ["syncedMyAdIds", false]);
      this.debug("change random ads");
      this.randomAdIndexes = [];
      this.ads = [];
      this.adIds = [];
      await this.getRandomAdIndexes();
      this.isLoading = false;
      //this.adsIndex++;
      //this.adsKey = `ads${this.adsIndex}`;
    },*/

    async addRotatingBannerAds(bannerAds) {
      this.printJson(bannerAds, "addRotatingBannerAds bannerAds");
      let len = bannerAds.length;
      for (let i = 0; i < len; i++) {
        let bannerAd = bannerAds[i];
        if ("image" in bannerAd) {
          let link = "";
          if ("link" in bannerAd) {
            link = bannerAd.link;
          }

          this.addRotatingImage(
            this.BACKEND00_FILES_URL + "/" + bannerAd.image,
            link,
            bannerAd.id
          );
        }
      }
    },

    /* imageUrl - URL of image to display
     * url - the URL the image links to
     */
    addRotatingImage(imageUrl, url, bannerId) {
      this.debug(`addRotatingImage() url = ${url}`);
      this.topCorousel.push({
        image: imageUrl,
        url: url,
        id: bannerId,
      });
    },

    async increaseBannerAdClick(bannerId) {
      this.debug(bannerId);
      await this.increaseRotatingBannerAdClicks(bannerId);
    },

    async updateFavourite() {
      this.favourite = !this.favourite;
      //update to server
      await this.updateFavouriteAds(this.$route.query.id, this.favourite);
      //when this function successfully completed the socket.io will send broadcast message favourited and the related userId will receive it store it
    },
  }, // methods
};
</script>
