<template>
  <v-card width="800" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1" ref="placeAd">
        <span v-if="newAd">{{ $t(`placeAd`) }}</span>
        <span v-else>
          <span v-if="duplicateAd">{{ $t(`duplicateAd`) }}</span>
          <span v-else>{{ $t(`editAd`) }}</span>
        </span>
      </h1>
    </v-card-title>

    <div v-if="this.isLoggedInOIDC()">
      <v-container>
        <MyBtn
          class="mb-2"
          :disabled="!newAd"
          btnName="change_category"
          :iconName="mdiSelect"
          @btn-clicked="selectCategory = true"
        ></MyBtn>
        <br />
        <span>{{ $t(`check_category_msg`) }} <br /></span>
        <br />
        <MyDropDown
          :itemName="$t('mainCategory')"
          :dropDownItem="mainCategory"
          :dropDownItems="mainCategories"
          :disabled="!newAd"
          @update-items="updateMainCategory"
          ref="mainCategory"
        ></MyDropDown>
        <br />

        <div
          v-if="
            mainCategory != mainCategories[MAIN_CATEGORIES_ENUM.MISCELLANEOUS]
          "
        >
          <MyDropDown
            :itemName="$t('subCategory')"
            :dropDownItem="subCategory"
            :dropDownItems="subCategories"
            :disabled="!newAd"
            @update-items="updateSubCategory"
            ref="subCategory"
          ></MyDropDown>
        </div>

        <v-text-field
          :label="$t('title')"
          :prepend-icon="mdiFormatTitle"
          v-model="title"
          counter="30"
          :rules="[rules.required, rules.counter30]"
          required
          ref="title"
          placeholder="Include item type for best search result eg ‘Shower Chair’"
        />

        <div
          v-if="
            mainCategory == mainCategories[MAIN_CATEGORIES_ENUM.REAL_ESTATE]
          "
        >
          <div v-if="subCategory != 'Wanted'">
            <MyDropDown
              :itemName="$t('forSaleBy')"
              :dropDownItem="forSaleBy"
              :dropDownItems="forSaleBys"
              @update-items="updateForSaleBy"
            ></MyDropDown>
            <br />
          </div>

          <span class="subtitle-1 font-weight-bold">{{ $t(`startDate`) }}</span>
          {{ startDate }}
          <v-btn class="bg fg--text" @click="showSelectStartDate = true">
            {{ selectDateBtnStr }}
          </v-btn>
          <br />

          <div v-if="showSelectStartDate">
            <v-row justify="center">
              <v-date-picker
                v-model="startDate"
                @change="calendarClicked"
              ></v-date-picker>
            </v-row>
          </div>

          <br />
        </div>

        <div v-if="mainCategory == mainCategories[MAIN_CATEGORIES_ENUM.JOBS]">
          <MyDropDown
            :itemName="$t(`adType`)"
            :dropDownItem="adType"
            :dropDownItems="adTypes"
            @update-items="updateAdType"
          >
          </MyDropDown>
          <br />

          <MyDropDown
            :itemName="$t(`jobType`)"
            :dropDownItem="jobType"
            :dropDownItems="jobTypes"
            @update-items="updateJobType"
          ></MyDropDown>
          <br />

          <MyDropDown
            :itemName="$t(`advertisedBy`)"
            :dropDownItem="advertisedBy"
            :dropDownItems="advertisedBys"
            @update-items="updateAdvertisedBy"
          ></MyDropDown>
          <br />

          <div v-if="jobType != VOLUNTEER_STR">
            <MyDropDown
              :itemName="$t(`salaryType`)"
              :dropDownItem="salaryType"
              :dropDownItems="salaryTypes"
              @update-items="updateSalaryType"
              ref="jobType"
            ></MyDropDown>
            <br />

            <div v-if="salaryType != VOLUNTEER_STR">
              <MyDropDown
                :itemName="$t(`currency`)"
                :dropDownItem="currencyCode"
                :dropDownItems="currencyCodes"
                @update-items="updateCurrencyCodeOfAd"
              ></MyDropDown>

              <v-text-field
                :label="'Salary (' + currencyCode + ')'"
                :prefix="currencyCode"
                v-model="salary"
                type="number"
                :rules="[rules.required, rules.positive]"
              />
              <br />
            </div>
          </div>
        </div>
        <!-- Jobs -->

        <div v-if="mainCategory != mainCategories[MAIN_CATEGORIES_ENUM.JOBS]">
          <MyDropDown
            :itemName="$t(`priceOption`)"
            :dropDownItem="priceOption"
            :dropDownItems="priceOptions"
            @update-items="updatePriceOption"
          ></MyDropDown>
          <br />
          <MyDropDown
            v-if="priceOption != 'Free'"
            :itemName="$t(`currency`)"
            :dropDownItem="currencyCode"
            :dropDownItems="currencyCodes"
            @update-items="updateCurrencyCodeOfAd"
          ></MyDropDown>

          <v-text-field
            v-if="priceOption != 'Free'"
            :label="'Price (' + currencyCode + ')'"
            v-model="price"
            :prefix="currencyCode"
            type="number"
            :rules="[rules.required, rules.positive]"
          />
          <br />
        </div>

        <MyDropDown
          :itemName="$t(`addressOption`)"
          :dropDownItem="addressOption"
          :dropDownItems="addressOptions"
          @update-items="updateAddressOption"
          ref="address"
        ></MyDropDown>
      </v-container>

      <v-container justify="center">
        <p v-if="refreshAddressMsg != ''" class="fail">
          {{ refreshAddressMsg }}
        </p>
      </v-container>

      <v-container>
        <span v-if="userHasAddress === false"
          >{{ $t(`pleaseSet`) }}
          <a
            ><b @click="goToProfileWithHash('#SetAddress')">{{
              $t(`myMobilityGuru`)
            }}</b></a
          >
          {{ $t(`pageTo`) }} <b>{{ $t(`getAddress`) }}</b
          >.</span
        >
        <br />
        <MyBtn
          :btnName="useMyAddressBtnString"
          @btn-clicked="useMyAddress()"
          :iconName="mdiNotebookCheckOutline"
          :disabled="userHasAddress === false"
        ></MyBtn>

        <MyBtn
          :btnName="setAddressBtnString"
          :iconName="mdiLocationEnter"
          @btn-clicked="showMyAddress()"
        ></MyBtn>
      </v-container>

      <v-card-text class="body-1">
        <b>{{ $t(`address`) }}</b>
        <br />
        <div v-if="addressChosen">
          <div v-if="addressOption == 'Show Full Address' && addressChosen">
            <div v-if="'full' in addressObj">{{ addressObj.full }}</div>

            <!-- backwards compatibility -->
            <div v-else>
              {{ addressObj.number }} {{ addressObj.street }}
              <br />
              {{ addressObj.suburb }}, {{ addressObj.state }}
              {{ addressObj.postcode }}
            </div>
          </div>

          <div
            class="body-1"
            v-if="addressOption == 'Show State & Suburb Only' && addressChosen"
          >
            {{ addressObj.suburb }}, {{ addressObj.state }},
            {{ addressObj.postcode }}
          </div>
        </div>

        <div v-else>{{ $t(`press`) }}</div>
      </v-card-text>

      <v-container>
        <v-row class="ml-2" no-gutters>
          <MyDropDown
            :itemName="$t('country')"
            :dropDownItem="countryOfAd"
            :dropDownItems="countries"
            @update-items="updateCountryOfAd"
            ref="adCountry"
            :disabled="!duplicateAd"
          ></MyDropDown>
        </v-row>
        <v-row class="ml-2">
          <b>
            {{ $t(`email`) }}
            <span
              v-if="accessTokenDecoded.email_verified === true"
              class="green--text"
              >&nbsp; {{ $t(`verified`) }}</span
            >
            <span class="red--text" v-else>&nbsp; {{ $t(`unverified`) }}</span>
          </b>
          <CheckIcon
            height="20"
            width="50"
            v-if="accessTokenDecoded.email_verified === true"
            :check="accessTokenDecoded.email_verified"
          ></CheckIcon>
        </v-row>

        <v-row class="body-1 ml-2">
          <p ref="myEmail">{{ accessTokenDecoded.preferred_username }}</p>
        </v-row>

        <br />

        <span class="body-1 green--text">
          <b>{{ $t(`phoneOption`) }}</b>
        </span>
        <br />
        <span v-if="userHasPhone === false"
          >{{ $t(`please`) }}
          <a
            ><b @click="goToProfileWithHash('#SetPhone')">{{ $t(`my`) }}</b></a
          >
          {{ $t(`page`) }} <b>"{{ $t(`usePhone`) }}"</b>.</span
        >
        <br />
        <MyBtn
          :disabled="userHasPhone === false"
          btnName="usePhone"
          :iconName="mdiPhone"
          @btn-clicked="useMyPhone()"
          ref="useMyPhoneBtn"
        ></MyBtn>

        <MyBtn
          :btnName="setPhoneString"
          :iconName="mdiContentSaveEditOutline"
          @btn-clicked="showMyPhone"
        ></MyBtn>

        <MyBtn
          v-if="adHasPhone"
          btnName="removePhone"
          :iconName="mdiCloseOctagonOutline"
          @btn-clicked="removePhone()"
        >
        </MyBtn>
        <br />
        <br />
        <MyDropDown
          :dropDownItem="phoneOption"
          :dropDownItems="phoneOptions"
          @update-items="updatePhoneOption"
        >
        </MyDropDown>

        <div class="body-1" v-if="adHasPhone">
          <b>{{ $t(`phone`) }}</b>
          <br />
          {{ phone }}
        </div>
        <br />
        <br />

        <MyDropDown
          v-if="adHasPhone"
          :itemName="$t('contactMethod')"
          :dropDownItem="contactMethod"
          :dropDownItems="contactMethods"
          @update-items="updateContactMethod"
        ></MyDropDown>

        <br />
        <div
          v-if="
            mainCategory ==
              mainCategories[MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT] &&
            subCategory != 'Wheelchair & Scooter Parts/Accs'
          "
        >
          <v-text-field
            :label="$t('make')"
            v-model="make"
            counter="30"
            :rules="[rules.required, rules.counter30]"
          />

          <v-text-field
            :label="$t('model')"
            v-model="model"
            counter="30"
            :rules="[rules.required, rules.counter30]"
            ref="model"
          />

          <div v-if="subCategory == 'Boats'">
            <v-text-field
              :label="$t('engine')"
              v-model="engineHours"
              type="number"
              :rules="[rules.required, rules.positive]"
            />
          </div>
          <div v-else>
            <v-text-field
              :label="$t('kilometres')"
              v-model="kilometres"
              type="number"
              :rules="[rules.required, rules.positive]"
              ref="kilometres"
            />
          </div>

          <v-text-field
            :label="$t('colour')"
            v-model="colour"
            counter="30"
            :rules="[rules.required, rules.counter30]"
            ref="colour"
          />

          <v-text-field
            :label="$t('year')"
            v-model="year"
            type="number"
            :rules="[
              rules.required,
              rules.positive,
              () => ruleYearLessThanCurrent(year),
            ]"
            ref="year"
          />

          <div
            v-if="
              subCategory == 'Cars' ||
              subCategory == 'Camper Vans' ||
              subCategory == 'Boats' ||
              subCategory == 'Other Vehicles'
            "
          >
            <v-text-field
              :label="$t('hp')"
              v-model="horsepower"
              type="number"
              :rules="[rules.positive]"
              ref="horsepower"
            />

            <v-text-field
              :label="$t('cylinder')"
              v-model="numCylinders"
              type="number"
              :rules="[rules.positive]"
            />

            <v-text-field
              :label="$t('capacity')"
              v-model="engineCapacity"
              type="number"
              :rules="[rules.positive]"
            />
          </div>
        </div>

        <br />

        <div
          v-if="
            mainCategory != mainCategories[MAIN_CATEGORIES_ENUM.JOBS] &&
            mainCategory != mainCategories[MAIN_CATEGORIES_ENUM.SERVICES] &&
            mainCategory != mainCategories[MAIN_CATEGORIES_ENUM.TRAVEL]
          "
        >
          <MyDropDown
            :itemName="$t('condition')"
            :dropDownItem="condition"
            :dropDownItems="conditions"
            @update-items="updateCondition"
          ></MyDropDown>
        </div>

        <div v-if="mainCategory == mainCategories[MAIN_CATEGORIES_ENUM.TRAVEL]">
          <MyDropDown
            :itemName="$t('wheelchairCompliant')"
            :dropDownItem="wheelchairCompliant"
            :dropDownItems="wheelchairCompliants"
            @update-items="updateWheelchairCompliant"
          ></MyDropDown>
        </div>
        <br />
        <div v-if="subCategory == 'Tricycles' || subCategory == 'Wheelchairs'">
          <MyDropDown
            :itemName="$t('type')"
            :dropDownItem="selectedVehicleType"
            :dropDownItems="vehicleTypes"
            @update-items="updateVehicletype"
          ></MyDropDown>
        </div>
        <br />

        <b>{{ $t("description") }}</b>
        <v-textarea
          :label="$t('description')"
          v-model="description"
          background-color="fillColour"
          counter="1000"
          :rules="[rules.required, rules.description1000]"
          auto-grow
          clearable
          filled
          outlined
          solo
        />

        <!--
        <v-row align='center' justify='center' v-if='enableAddPicButton'>
          <v-btn
            @click='addExtraPic()'
            color='fillColour'
          >
            <v-icon 
              left
              large
            >
              mdi-plus-circle
            </v-icon>
            Add Another Picture
          </v-btn> 
        </v-row>
        -->
        <b>{{ $t("photos") }}</b>
        {{ currentNumDraftFiles }} of {{ maxFiles }}
        {{ $t("numImages") }}
        <ul>
          <li>{{ $t("add_up_to_8_photos") }}</li>
          <li>
            {{ $t("computers_only") }}
          </li>
          <li>{{ $t("rotate_or_move") }}</li>
        </ul>
        <upload-btn
          icon
          accept="image/*"
          @file-update="addMultipleDraftFilesURLs"
          :color="getUploadMultBtnColour()"
          large
          :disabled="currentNumDraftFiles >= maxFiles"
          multiple
          :key="uploadMultBtnKey"
          fixedWidth="300px"
        >
          <template slot="icon">
            <v-icon :color="getUploadMultBtnIconColour()" large>{{
              mdiPlusBoxMultipleOutline
            }}</v-icon>

            <span :class="getUploadBtnTextColour()">
              {{ $t("click_to_select_photos") }}
            </span>
          </template>
        </upload-btn>
        <br />

        <v-row>
          <v-card
            class="ma-2"
            v-for="(fileURL, index) in draftFilesURLs"
            :key="index"
            width="250px"
            height="230px"
          >
            <v-row
              v-if="fileURL === ''"
              style="height: 200px"
              align="center"
              justify="center"
            >
              {{ $t("can_upload") }} {{ maxFiles - currentNumDraftFiles }}
              {{ $t("more") }}
              <!--
              <upload-btn
                icon
                accept="image/*"
                @file-update="addDraftFileURL($event, index)"
                :color="getUploadBtnColour(index)"
                fixedWidth="170px"
                :disabled="index > currentNumDraftFiles"
              >
                <template slot="icon">
                  <v-icon :color="getUploadBtnIconColour(index)" large>
                    {{ mdiImagePlus }}
                  </v-icon>

                  <span :class="getUploadBtnTextColour(index)">Pick Photo</span>
                </template>
              </upload-btn>
              -->
            </v-row>

            <div v-if="fileURL !== ''">
              <v-row justify="center">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        align="center"
                        @click="movePicLeft(index)"
                        color="fillColour"
                        v-on="on"
                        :disabled="index == 0"
                        small
                      >
                        <v-icon color="green">{{
                          mdiArrowLeftBoldOutline
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("left") }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        align="center"
                        @click="removePic(index)"
                        color="fillColour"
                        v-on="on"
                        small
                      >
                        <v-icon color="green">{{ mdiCloseOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("imageViewers.remove") }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        align="center"
                        @click="cropPic(index)"
                        color="fillColour"
                        v-on="on"
                        small
                        v-if="draftFiles[index] !== null"
                      >
                        <v-icon color="green">{{ mdiCropFree }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("crop") }}</span>
                  </v-tooltip>

                  <!--
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        align="center"
                        @click="rotatePic(index)"
                        color="fillColour"
                        v-on="on"
                        small
                      >
                        <v-icon color="green">{{ mdiRotateRight }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Rotate Clockwise</span>
                  </v-tooltip>
                  -->

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        align="center"
                        @click="movePicRight(index)"
                        color="fillColour"
                        v-on="on"
                        :disabled="index >= currentNumDraftFiles - 1"
                        small
                      >
                        <v-icon color="green">{{
                          mdiArrowRightBoldOutline
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("right") }}</span>
                  </v-tooltip>
                </span>
              </v-row>
              <br />

              <div align="center">
                <!--
                <a :href="fileURL" download>
                -->
                <v-img
                  :src="fileURL"
                  width="180px"
                  height="180px"
                  contain
                  :id="'image' + index"
                ></v-img>
                <!--
                </a>
                -->
              </div>

              <!--
              <v-card>
                <span
                  v-if="
                    isFileObject(draftFiles[index]) == true ||
                    isBlobObject(draftFiles[index]) == true
                  "
                  >{{ draftFiles[index].size }} bytes</span
                >

                <span
                  v-if="
                    isFileObject(draftFiles[index]) == false &&
                    isBlobObject(draftFiles[index]) == false &&
                    savedFilesSizes.length > 0 &&
                    index < savedFilesSizes.length
                  "
                  >{{ savedFilesSizes[index] }} bytes</span
                >
              </v-card>
              -->
            </div>
          </v-card>
        </v-row>
        <br />
        <!--
        <v-row justify="start" no-gutters>
          <MyDropDown
            itemName="Ad Locale"
            :dropDownItem="selectedLanguage"
            :dropDownItems="languageNames"
            @update-items="updateLanguage"
            ref="subCategory"
          ></MyDropDown>
          <v-progress-circular
            v-if="localeLoading"
            indeterminate
          ></v-progress-circular>
        </v-row>
        -->
      </v-container>

      <br />
      <div class="flex-container-center">
        <div v-if="!showErrorMessage" class="ok">{{ message }}</div>

        <div v-if="showErrorMessage" class="fail">{{ message }}</div>
      </div>

      <canvas
        id="myCanvas"
        width="640"
        height="480"
        style="display: none"
      ></canvas>

      <br />

      <div
        class="ma-1"
        v-if="newAd || duplicateAd"
        :key="multipleAdsSectionKey"
      >
        <v-divider></v-divider>
        <b
          >{{ $t("createAd") }}
          <span class="green--text">{{ $t("bulk") }}</span></b
        >
        <v-row align="center" justify="start" no-gutters>
          <MyBtn
            btnName="selectCountries"
            @btn-clicked="showMultipleCountriesSelect"
            :iconName="mdiMap"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>
        <p v-if="Object.keys(convertedPriceForCountries).length > 0">
          {{ Object.keys(convertedPriceForCountries).length }}
          {{ $t("selected") }}
        </p>
        <p v-else>{{ $t("select_country_msg") }}</p>

        <v-card
          v-for="(countryObject, index) in convertedPriceForCountries"
          :key="index"
          rounded
          elevtion="0"
          color="transparent"
          class="pa-1 mb-2"
        >
          <v-row no-gutters>
            <p>{{ countryObject.country }}</p>
            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="removeCountry(countryObject.country)"
                  align="center"
                  color="fillColour"
                  v-on="on"
                  small
                >
                  <v-icon color="red">{{ mdiDelete }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("remove") }} {{ countryObject.country }}</span>
            </v-tooltip>
          </v-row>
          <v-text-field
            dense
            v-if="showAmountTextFieldForMultipleCountries"
            outlined
            :label="'Amount (' + countryObject.currencyCode + ')'"
            v-model="countryObject.convertedPrice"
            :prefix="countryObject.currencyCode"
            type="number"
            :rules="[rules.positive]"
          />

          <span
            class="red--text"
            v-if="
              showAmountTextFieldForMultipleCountries &&
              countryObject.convertedPrice.length == 0
            "
          >
            {{ $t("converted") }}
          </span>
        </v-card>

        <v-divider
          class="mb-2"
          v-if="Object.keys(convertedPriceForCountries).length > 0"
        ></v-divider>

        <v-row
          no-gutters
          v-if="Object.keys(convertedPriceForCountries).length > 0"
        >
          <MyBtn
            btnName="trans"
            @btn-clicked="showTranslateOptionMenu"
            :iconName="mdiTranslate"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>
        <p
          v-if="Object.keys(convertedPriceForCountries).length > 0"
          class="green--text"
        >
          {{ $t("addTrans") }}
        </p>
        <div v-if="showTranslateTextFields">
          <v-card
            outlined
            rounded
            elevtion="0"
            class="pa-1 mb-2 mt-1"
            v-for="(countryObject, index) in convertedPriceForCountries"
            :key="index"
          >
            <v-row no-gutters
              >{{ countryObject.country }} - {{ countryObject.languageName }}
              <v-spacer></v-spacer>
              <MyBtn
                v-if="
                  'translateSupported' in countryObject &&
                  countryObject.translateSupported == true &&
                  countryObject.languageCode != localeCodeOfText
                "
                btnName="translate"
                @btn-clicked="translateAndShowTextFields(countryObject.country)"
                :iconName="mdiTranslate"
                :disabled="isLoading"
              ></MyBtn>
              <MyBtn
                btnName="Add Translated text"
                v-if="
                  ('manualTranslate' in countryObject &&
                    countryObject.manualTranslate == true) ||
                  countryObject.languageCode == localeCodeOfText
                "
                @btn-clicked="
                  showTextFieldsToAddTranslate(countryObject.country)
                "
                :iconName="mdiTranslate"
                :disabled="isLoading"
              ></MyBtn>
            </v-row>
            <p v-if="countryObject.languageCode != localeCodeOfText">
              {{ $t("translate") }}
              <span v-if="localeOfText !== undefined && localeOfText.length > 0"
                >{{ $t("from") }} {{ localeOfText }}
                {{ $t("language.language") }}</span
              >
              <span
                v-if="
                  'languageName' in countryObject &&
                  countryObject.languageName.length > 0
                "
              >
                {{ $t("to") }} {{ countryObject.languageName }}
                {{ $t("language.language") }}</span
              >
              <span
                v-if="
                  'manualTranslate' in countryObject &&
                  countryObject.manualTranslate == true
                "
              >
                {{ $t("other") }}</span
              >
            </p>
            <p v-else>
              {{ $t("cannot") }}
            </p>
            <div
              v-if="
                'showTextFields' in countryObject &&
                countryObject.showTextFields == true
              "
            >
              <v-text-field
                dense
                outlined
                :label="$t('title')"
                :prepend-icon="mdiFormatTitle"
                counter="30"
                :rules="[rules.counter30]"
                v-model="countryObject.title"
                :placeholder="$t('include')"
                @input="
                  changeTitleInConvertedObject(countryObject.country, $event)
                "
              />
              <br />
              <v-textarea
                dense
                v-model="countryObject.description"
                @input="
                  changeDescriptionInConvertedObject(
                    countryObject.country,
                    $event
                  )
                "
                :label="$t('description')"
                background-color="fillColour"
                counter="1000"
                :rules="[rules.description1000]"
                auto-grow
                clearable
                filled
                outlined
                solo
              />
            </div>
          </v-card>
        </div>
      </div>
      <v-divider></v-divider>
      <br />

      <v-row align="center" justify="center" v-if="newAd">
        <MyBtn
          btnName="save_new_ad"
          @btn-clicked="saveAd()"
          :iconName="mdiContentSave"
          :disabled="isLoading"
        ></MyBtn>
      </v-row>

      <v-row align="center" justify="center" v-else>
        <MyBtn
          v-if="duplicateAd"
          btnName="save_as_new"
          :disabled="isLoading"
          @btn-clicked="saveDuplicateNewAd()"
        >
        </MyBtn>

        <MyBtn
          v-else
          btnName="save_changes"
          :disabled="isLoading"
          @btn-clicked="saveChanges()"
        ></MyBtn>
      </v-row>
      <br />
      <br />
    </div>

    <v-dialog class="mt-10" v-model="selectCategory" max-width="610">
      <v-card class="mx-auto">
        <MyCardTitle
          :title="$t('change_category')"
          @close-window="closeSelectCategory()"
        ></MyCardTitle>

        <v-card
          width="600"
          min-width="600"
          class="ma-0"
          v-for="(item, index) in this.category"
          :key="index"
          elevation="0"
        >
          <!-- <v-card
                height="200"
                  class="ma-5 orangeText"
                  elevation="0"
                  v-if="item.tooltip == 'Miscellaneous'"
                  @click="viewSubCategory(item)"
                >
                  <v-row class="" justify="center">
                    <img height="80" width="85" :src="item.avatar" />
                  </v-row>
                  <br />

                  <v-row justify="center"
                    ><span>{{ item.tooltip }}</span></v-row
                  >
                </v-card>-->
          <CategoryCell
            @categories-finalized="saveCategories"
            :mainCategory="item.tooltip"
            :avatar="item.avatar"
            :subCategories="item.subCategories"
          ></CategoryCell>
        </v-card>
        <br /><br />
      </v-card>

      <!--  <v-card max-height="8000px">
        <v-col v-if="showCategories" cols="12">
          <v-card
            min-width="150"
            color="fillColour"
            class="ma-5"
            v-for="(item, index) in this.category"
            :key="index"
            elevation="0"
          >
            <v-rowclass="">
              <v-col lg="6" md="6" sm="12">
                <v-card
                  class="ma-5 orangeText"
                  elevation="0"
                  @click="viewSubCategory(item)"
                >
                  <v-row class="" justify="center">
                    <img height="80" width="85" :src="item.avatar" />
                  </v-row>
                  <br />

                  <v-row justify="center"
                    ><span>{{ item.tooltip }}</span></v-row
                  >
                </v-card>
              </v-col>
              <v-col lg="6" md="6" sm="12">
                <v-card
                  v-if="item.tooltip != 'Miscellaneous'"
                  elevation="0"
                  color="fillColour"
                >
                  <v-card-title>Use this for:</v-card-title>
                  <v-list-item-group color="primary">
                    <v-list-item
                      class="orangeText"
                      v-for="(subCategory, index) in item.subCategories"
                      :key="index"
                    >
                      <v-list-item-content
                        @click="saveCategories(item.tooltip, subCategory)"
                      >
                        <v-list-item-title>{{ subCategory }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-else>
          <v-row class="ml-5 mb-2">
            <v-icon
              color="green"
              medium
              @click="showCategories = !showCategories"
              >{{ mdiArrowLeftBoldOutline }}</v-icon
            >
          </v-row>
          <v-row class="mt-n8" justify="center">
            <h4>Click on Item to Select</h4>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-list-item-group color="primary">
                  <v-list-item
                    class="orangeText"
                    v-for="(subCategory, index) in selectedItem.subCategories"
                    :key="index"
                  >
                    <v-list-item-content
                      @click="saveCategories(selectedItem.tooltip, subCategory)"
                    >
                      <v-list-item-title>{{ subCategory }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card>-->
    </v-dialog>

    <!--<MyAddress
      :isShowMyAddress="isShowMyAddress"
      :inAddressObj="addressObj"
      @close-window="isShowMyAddress = false"
      @address-finalized="finalizeAddress"
      :key="myAddressKey"
    ></MyAddress>-->

    <MyAddressInternational
      :isShowMyAddress="isShowMyAddress"
      :inAddressObj="addressObj"
      @close-window="closeMyAddressInternational"
      @address-finalized="finalizeAddress"
      :key="myAddressKey"
      :validateWithoutNoAndStreet="true"
    ></MyAddressInternational>

    <MyPhone
      :isShowMyPhone="isShowMyPhone"
      @close-window="hideMyPhone()"
      @phone-finalized="finalizePhone"
      :phonePrefixNum="$store.state.phonePrefix"
      :key="myPhoneKeyString"
    ></MyPhone>

    <Loading
      :isLoading="isLoading"
      :message="messageLoader"
      :percentage="percentageLoader"
      :loadData="loadData"
      :key="loadingKey"
    ></Loading>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeAcknowledge"
      :key="myAcknowledgeKey"
      :showActionBtn="showActionBtn"
      :actionName="actionName"
      @action-clicked="showPurchaseAdsScreen()"
    ></MyAcknowledge>

    <MyYesNo
      :showYesNo="showYesNoExif"
      :title="yesNoTitleExif"
      :message="yesNoMessageExif"
      @yes-clicked="removeExif(imageExifIndex)"
      @no-clicked="showYesNoExif = false"
    ></MyYesNo>

    <MyYesNo
      :showYesNo="showYesNoCompress"
      :title="yesNoTitleCompress"
      :message="yesNoMessageCompress"
      @yes-clicked="compressImage(imageCompressIndex)"
      @no-clicked="noCompress(imageCompressIndex)"
      :key="showYesNoCompressKey"
    ></MyYesNo>

    <VueCropper
      @close-cropper="showCropper = false"
      @save-cropped="saveCroppedImage"
      @imgSrc-changed="updateImgSrc"
      :showCropper="showCropper"
      :title="cropperTitle"
      :imgSrc="imgSrc"
      :key="cropperKeyString"
    ></VueCropper>

    <SelectMultipleCountries
      :isShowBottomSheet="isShowSelectMultipleCountries"
      :key="selectMultipleCountryKey"
      :maxLimit="maxCoutriesToSelect"
      :selectedCountries="selectedCountries"
      @countries-selected="countriesSelected"
      :disabledCountries="disabledCountries"
      @reached-maxads="showAlertOnAdsLimitReached"
    >
    </SelectMultipleCountries>
    <v-snackbar
      color="orange"
      timeout="10000"
      v-model="showSnackbar"
      multi-line
      style="z-index: 1000000000000"
    >
      <span class="black--text" v-html="snackbarMessage"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="goToPurchaseAds()"
          >Purchase Ads</v-btn
        ><v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <!-- <v-dialog
      v-model="isShowPurchaseAds"
      :key="showPurchaseAdsKey"
      persistent
      max-width="800"
    >
      <v-card class="mx-auto">
        <MyCardTitle
          title="Paid Ads"
          @close-window="isShowPurchaseAds = false"
        ></MyCardTitle>
        <PaidAds :showPayment="true" :showHistory="true"></PaidAds>
      </v-card>
    </v-dialog> -->

    <MyYesNo
      :showYesNo="showYesNoCheckTranslate"
      :title="yesNoTitleCheckTranslate"
      :message="yesNoMessageCheckTranslate"
      @yes-clicked="loadTranslationsAvailability()"
      @no-clicked="closeTranslateOptionsMenu()"
      :key="showYesNoCheckTranslateKey"
    ></MyYesNo>

    <!--<v-dialog
      class="mt-10"
      v-model="showSelectLanguage"
      max-width="600"
      persistent
    >
      <v-card>
        <MyCardTitle
          title="Select the Language of your ad from below dropdown"
          @close-window="closeSelectLanguage"
        ></MyCardTitle>
        <v-row justify="center" no-gutters>
          <span class="caption">
            Closing this dialog will select <b>English</b> as language</span
          >
        </v-row>
        <br />
        <v-row justify="center" no-gutters>
          <MyDropDown
            itemName="Select Language"
            :dropDownItem="selectedLanguage"
            :dropDownItems="languageNames"
            @update-items="updateLanguage"
            ref="subCategory"
          ></MyDropDown>
        </v-row>
        <br />
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
//import {deleteDatabase} from 'kinto/lib/adapters/IDB.js'

//  minLength,
//import { required, between} from 'vuelidate/lib/validators'

import {
  mdiNotebookCheckOutline,
  mdiArrowLeftBoldOutline,
  mdiArrowRightBoldOutline,
  mdiCashUsdOutline,
  mdiCloseOutline,
  mdiCloseOctagonOutline,
  mdiContentSave,
  mdiFormatTitle,
  mdiLocationEnter,
  mdiPhone,
  mdiContentSaveEditOutline,
  mdiImagePlus,
  mdiPlusBoxMultipleOutline,
  mdiRotateRight,
  mdiSelect,
  mdiCropFree,
  mdiMap,
  mdiDelete,
  mdiTranslate,
} from "@mdi/js";

import Config from "../config.js";

import MyAcknowledge from "./MyAcknowledge";
import MyAddressInternational from "./MyAddressInternational";
import MyBtn from "./MyBtn";
import MyDropDown from "./MyDropDown";
import MyPhone from "./MyPhone";
import MyYesNo from "./MyYesNo";
import Loading from "./Loading";
import MyCardTitle from "./MyCardTitle";
import CategoryCell from "./CategoryCell";
import VueCropper from "./VueCropper";
import CheckIcon from "./CheckIcon.vue";
import SelectMultipleCountries from "./SelectMultipleCountries.vue";
//import PaidAds from "./PaidAds.vue";

import Compressor from "compressorjs";
//import EXIF from "exif-js";
import piexif from "piexifjs";

// https://www.npmjs.com/package/vuetify-upload-button
import UploadButton from "vuetify-upload-button";

import Vue from "vue";

// lodash
//import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

// https://www.npmjs.com/package/uuid
//const uuidv1 = require("uuid/v1");

export default {
  name: "SubmitAd",
  components: {
    Loading,
    MyAcknowledge,
    MyAddressInternational,
    MyBtn,
    MyDropDown,
    MyPhone,
    MyYesNo,
    "upload-btn": UploadButton,
    MyCardTitle,
    CategoryCell,
    VueCropper,
    CheckIcon,
    SelectMultipleCountries,
    // PaidAds,
  },
  props: ["adId"],

  metaInfo: {
    title: "Submit Ad | Mobility Guru",
    meta: [
      {
        name: "description",
        content: "Submit free ads",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  //  computed: () => ({
  computed: {
    /*    currentYear() {
      return (new Date()).getFullYear()
    },*/
    /*
    enableAddPicButton() {

    }*/
  },

  watch: {
    /*title: debounce(function () {
      this.guessLocaleOfAd();
    }, 1000),

    description: debounce(function () {
      this.guessLocaleOfAd();
    }, 1000),*/
  },
  //TODO: create collection for each main / sub cat
  data: () => ({
    mdiNotebookCheckOutline: mdiNotebookCheckOutline,
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiArrowRightBoldOutline: mdiArrowRightBoldOutline,
    mdiCashUsdOutline: mdiCashUsdOutline,
    mdiCloseOutline: mdiCloseOutline,
    mdiCloseOctagonOutline: mdiCloseOctagonOutline,
    mdiContentSave: mdiContentSave,
    mdiFormatTitle: mdiFormatTitle,
    mdiPhone: mdiPhone,
    mdiLocationEnter: mdiLocationEnter,
    mdiContentSaveEditOutline: mdiContentSaveEditOutline,
    mdiImagePlus: mdiImagePlus,
    mdiPlusBoxMultipleOutline: mdiPlusBoxMultipleOutline,
    mdiRotateRight: mdiRotateRight,
    mdiSelect: mdiSelect,
    mdiCropFree: mdiCropFree,
    mdiMap: mdiMap,
    mdiDelete: mdiDelete,
    mdiTranslate: mdiTranslate,

    newAd: false,

    selectCategory: false,
    showCategories: true,
    selectedItem: {},

    bucketMobilityGuru: null,

    collectionAds: null,

    mainCategory: "",

    subCategory: "",

    title: "",
    titleError: "",
    description: "",
    showErrorMessage: false,
    message: "",

    // current state of list of files (unsaved to Kinto database)
    draftFiles: [],
    /* Current state of list of files as absolute URLs for ad (unsaved to
     * Kinto database)
     */
    draftFilesURLs: [],

    // Original ads list of files
    //adsFiles: [],
    // Original list of files as absolute URLs the ad has.
    adsFilesURLs: [],

    currentNumDraftFiles: 0,
    rotationAngles: [],

    maxFiles: 8,

    mainCategories: [],
    subCategories: [],

    adTypes: ["I'm hiring", "I'm looking for work"],
    adType: "",

    jobType: "",
    jobTypes: [],

    advertisedBy: "",
    advertisedBys: [],

    salaryType: "",
    salaryTypes: [],

    salary: "",

    priceOptions: ["Amount", "Negotiable", "Free"],
    priceOption: "",
    price: "",

    phoneOptions: [
      "Show the phone number publicly",
      "Only show to logged in users",
    ],
    phoneOption: "Only show to logged in users",

    userHasAddress: false,
    userHasPhone: false,
    adHasPhone: false,
    phone: "",
    setPhoneString: "",
    isShowMyPhone: false,
    myPhoneKey: 0,
    myPhoneKeyString: "myPhone0",

    addressOptions: ["Show Full Address", "Show State & Suburb Only"],
    addressOption: "Show State & Suburb Only",

    addressObj: {
      number: "",
      street: "",
      suburb: "",
      state: "",
      postcode: "",
    },
    myAddressKey: 0,
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    showActionBtn: false,
    actionName: "",

    addressChosen: false,
    isShowMyAddress: false,
    phonePublic: false,

    useMyAddressBtnString: "getAddress",
    setAddressBtnString: "anotherAddress",

    /* this is used to check if user did a first time change to full address
     * when we are in edit ad mode (newAd == false).
     *
     * We need to warn user to select address again as we don't record
     * parts of the address that isn't used for best security (those parts
     * are never sent over the internet)
     */
    firstTimeChangeAddressOption: true,
    refreshAddressMsg: "",

    condition: "",
    conditions: ["used", "new"],

    wheelchairCompliant: "",
    wheelchairCompliants: ["No", "Yes"],

    selectedVehicleType: "",
    vehicleTypes: ["Manual", "Electric"],

    MAIN_CATEGORIES_ENUM: {},
    //MAIN_CATEGORIES_ENUM: {},
    SUB_CATEGORIES_LIST: [],
    category: [],

    VOLUNTEER_STR: "Volunteer",

    make: "",
    model: "",
    engineHours: "",
    kilometres: "",
    colour: "",
    year: "",
    horsepower: "",
    numCylinders: "",
    engineCapacity: "",

    forSaleBy: "",
    forSaleBys: ["Owner", "Agent"],
    showSelectStartDate: false,
    startDate: "",
    selectDateBtnStr: "Select Date",

    adOrig: {},

    uploadMultBtnKey: 0,

    isLoading: false,
    messageLoader: "",
    percentageLoader: null,
    loadData: null,
    uploadStartTime: null,

    showAcknowledge: false,
    acknowledgeMessage: "",
    acknowledgeTitle: "Error",
    acknowledgeOk: false,

    currentYear: 0,

    showYesNoExif: false,
    yesNoTitleExif: "EXIF Data Detected!",
    yesNoMessageExif:
      "Do you want to remove image EXIF data<br/>(Recommended to protect your privacy).",
    imageExifIndex: 0,

    showYesNoCompress: false,
    yesNoTitleCompress: "Image Too Large!",
    yesNoMessageCompress:
      "Image is larger than " +
      Config.MAX_FILE_SIZE_BYTES +
      " bytes. Want to compress image?",
    imageCompressIndex: 0,

    accessTokenDecoded: {},

    contactMethod: "",
    contactMethods: ["Email", "SMS", "Phone"],

    currencyCode: "",

    IMAGE_MAX_WIDTH: Config.IMAGE_MAX_WIDTH,
    IMAGE_MAX_HEIGHT: Config.IMAGE_MAX_HEIGHT,

    //savedFilesSizes: [],
    showCropper: false,
    cropperTitle: "",
    imgSrc: "",
    selectedImageName: "",
    selectedImageIndex: "",
    cropperKey: 0,
    cropperKeyString: "cropper0",

    maxAdsReached: false,
    numAds: 0,
    maxNumberAds: Config.MAX_ADS,

    loadingIndex: 0,
    loadingKey: "loadingKey0",

    showSelectLanguage: false,
    languageNames: [],
    languageISOCodes: [],
    selectedLocale: "",
    selectedLanguage: "",
    localeLoading: false,

    rules: {
      required: (value) => value.length > 0 || "Required.",
      positive: (value) =>
        (typeof value !== "undefined" &&
          (value.length == 0 || parseFloat(value) > 0)) ||
        "Must be larger than 0",
      counter30: (value) =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      description1000: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
    },
    showYesNoCompressKey: "showYesNoCompress0",
    showYesNoCompressCounter: 0,

    countryOfAd: "",
    countries: [],
    disableAdCountry: true,
    currencyCodes: [],
    showSnackbar: false,
    snackbarMessage: "",

    duplicateAd: false,
    origValuesWithCurrenciesObject: {},

    //variables related to select multiple countries
    isShowSelectMultipleCountries: false,
    selectMultipleCountryKey: "selectMultiple0",
    selectMultipleCountryIndex: 0,
    selectedCountries: [],
    convertedPriceForCountries: {},
    amountConverted: 0,
    baseCurrencyConverted: "",
    showAmountTextFieldForMultipleCountries: false,
    maxCoutriesToSelect: 0,
    disabledCountries: [],

    showYesNoCheckTranslate: false,
    yesNoTitleCheckTranslate: "Check translation availability",
    yesNoMessageCheckTranslate:
      "This will show options for the selected countries oiginal locales only, Press Yes to proceed",
    showYesNoCheckTranslateIndex: 0,
    showYesNoCheckTranslateKey: "showTranslate0",
    showTranslateTextFields: false,
    localeOfText: "",
    localeCodeOfText: "",
    multipleAdsSectionKey: "multipleAdsSection0",
    multipleAdsSectionIndex: 0,
    isShowPurchaseAds: false,
    showPurchaseAdsIndex: 0,
    showPurchaseAdsKey: "showPurchase0",
  }),

  /*
  validations: {
    title: {
      required,
      between: between(1, 60)
    },
    description: {
      required,
      between: between(1, 600)
    }
  },*/

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      await this.init();

      // for some reason the images aren't loaded until after init() exits
      this.applyAllRotationAngles();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    getSubCategories() {
      let ok = false;
      let len = Config.MAIN_CATEGORIES.length;
      for (let i = 0; i < len; i++) {
        if (this.mainCategory == Config.MAIN_CATEGORIES[i]) {
          if (
            this.mainCategory !==
            this.mainCategories[this.MAIN_CATEGORIES_ENUM.MISCELLANEOUS]
          ) {
            this.subCategories = Config.SUB_CATEGORIES_LIST[i];
            this.subCategory = this.subCategories[0];
          }
          ok = true;

          break;
        }
      }
      return ok;
    },

    getCollections() {},

    viewSubCategory(item) {
      if (item.tooltip == "Miscellaneous") {
        this.saveCategories(item.tooltip);
      } else {
        this.showCategories = false;
        this.selectedItem = item;
      }
    },

    saveCategories(mainCategory, subcategory) {
      this.mainCategory = mainCategory;
      this.getSubCategories();
      this.subCategory = subcategory;
      this.closeSelectCategory();
    },

    updatePriceOptions() {
      let lastItem = this.priceOptions[this.priceOptions.length - 1];
      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
      ) {
        if (lastItem == "Free") {
          this.priceOptions.pop();
        }
      } else {
        if (lastItem != "Free") {
          this.priceOptions.push("Free");
        }
      }
    },

    cropPic(index) {
      if (this.draftFilesURLs[index] != null) {
        this.imgSrc = this.draftFilesURLs[index];

        this.selectedImageIndex = index;

        this.printJson(this.draftFiles, "saved");

        /*
        if (this.draftFiles[index] === null) {
          // existing file already saved, use draftFilesURLs to get name
          this.selectedImageName = this.getFileNameFromURL(this.draftFilesURLs[index]);
        } else {
        */
        // new file yet to be saved, use draftFiles to get name
        this.selectedImageName = this.draftFiles[index].name;
        //}

        this.cropperTitle = `Crop Image ${this.selectedImageName}`;

        this.debug(this.selectedImageName);

        this.showCropperMethod();
      }
    },
    saveCroppedImage(cropImg) {
      //    this.draftFilesURLs[this.selectedImageIndex] = cropImg;
      // this.debug(this.draftFiles[this.selectedImageIndex], "draft file is the array");
      //let name = `${this.draftFiles[this.selectedImageIndex].name}`;
      let name = this.selectedImageName;
      this.debug(`new name to the file ${name}`);
      let file = this.dataURLToFile(cropImg, name);

      //this.removePic(this.selectedImageIndex);

      this.addDraftFileURL(file, this.selectedImageIndex);

      this.hideCropper();

      //this.containerKey++;
      this.uploadMultBtnKey++;
    },
    changeRoute() {
      if (this.maxAdsReached === true) {
        //this.$router.go(-1);
        this.pushRoute(Config.ROUTE_PURCHASE_ADS);
      }
    },

    closeAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
      this.changeRoute();
    },
    async init() {
      this.showLoading();
      /* this.selectedLocale = this.$i18n.locale;
      this.selectedLanguage =
        this.$store.state.languageCodesObject[this.selectedLocale];*/
      let res = await this.getUserProfileData();
      let ok = res.ok;

      if (ok) {
        let profile = res.profile;
        if ("maxNumberAds" in profile) {
          this.maxNumberAds = profile.maxNumberAds;
        }
        if ("numAds" in profile) {
          this.numAds = profile.numAds;
        }

        if (Number(this.maxNumberAds) <= Number(this.numAds)) {
          this.maxAdsReached = true;
          this.setAcknowledgeError(
            `Your max number of Ads ${this.maxNumberAds} has been used, Please purchase ads.`
          );
        }

        if (this.maxAdsReached === false) {
          let start = new Date();

          //reduce 1 because a new ad is saved before saving the bulk update of multiple countries.
          this.maxCoutriesToSelect =
            Number(this.maxNumberAds) - Number(this.numAds) - 1;

          await this.mainInit();

          this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

          this.currentYear = new Date().getFullYear();

          this.currencyCode = this.$store.state.currencyCode;
          //this.currencyCodes = [this.$store.state.currencyCode];

          this.MAIN_CATEGORIES_ENUM = Config.MAIN_CATEGORIES_ENUM;
          let i;
          for (i = 0; i < this.maxFiles; i++) {
            this.draftFiles[i] = null;
          }
          this.currentNumDraftFiles = 0;
          this.draftFilesURLs[this.currentNumDraftFiles] = "";

          this.userHasAddress = this.$store.state.hasAddress;
          this.userHasPhone = this.$store.state.hasPhone;

          for (let i = 0; i < Config.MAIN_CATEGORIES.length; i++) {
            this.mainCategories.push(Config.MAIN_CATEGORIES[i]);
          }

          this.MAIN_CATEGORIES = Config.MAIN_CATEGORIES;
          this.SUB_CATEGORIES_LIST = Config.SUB_CATEGORIES_LIST;

          for (let i = 0; i < this.MAIN_CATEGORIES.length; i++) {
            this.category[i] = {
              tooltip: this.MAIN_CATEGORIES[i],
              avatar: "/icons/" + this.MAIN_CATEGORIES[i] + ".svg",
              subCategories: this.SUB_CATEGORIES_LIST[i],
            };
          }

          this.jobTypes = Config.JOB_TYPES;
          this.advertisedBys = Config.ADVERTISED_BYS;
          this.salaryTypes = Config.SALARY_TYPES;

          if (this.adId == null) {
            this.selectCategory = true;
            this.debug("new ad");
            this.newAd = true;
            this.currencyCodes = [this.$store.state.currencyCode];

            this.mainCategory = this.mainCategories[0];

            this.forSaleBy = this.forSaleBys[0];

            this.adType = this.adTypes[0];

            this.jobType = this.jobTypes[0];

            this.advertisedBy = this.advertisedBys[0];

            this.salaryType = this.salaryTypes[0];

            this.priceOption = this.priceOptions[0];

            this.contactMethod = this.contactMethods[0];

            this.condition = this.conditions[0];
            this.wheelchairCompliant = this.wheelchairCompliants[0];

            this.setPhoneString = "Set Phone";
            this.countryOfAd = this.$store.state.countryByIP;
          } else {
            this.newAd = false;

            //check for change country and save as new ad
            if (this.$route.hash == "#countrychange") {
              this.duplicateAd = true;
            } else {
              this.duplicateAd = false;
              this.$refs.placeAd.scrollIntoView();
            }

            let res = await this.getAdRecordByAdId(this.adId);
            this.adOrig = res.ad;

            this.mainCategory = this.adOrig.mainCategory;
            this.title = this.adOrig.title;
            this.priceOption = this.adOrig.priceOption;
            this.price = this.adOrig.price;

            this.addressChosen = true;

            this.addressObj = this.adOrig.addressObj;
            if ("street" in this.adOrig.addressObj) {
              // full address
              this.addressOption = this.addressOptions[0];

              this.useMyAddressBtnString = "Same As My Address";
              this.setAddressBtnString = "anotherAddress";
            } else {
              // state & suburb only
              this.addressOption = this.addressOptions[1];

              this.useMyAddressBtnString = "getAddress";
              this.setAddressBtnString = "anotherAddress";
            }

            if ("country" in this.adOrig) {
              this.countryOfAd = this.adOrig.country;
            }

            //currency code of ad
            if ("currencyCode" in this.adOrig) {
              this.currencyCode = this.adOrig.currencyCode;
              if (
                this.currencyCodes.includes(this.adOrig.currencyCode) == false
              ) {
                this.currencyCodes.push(this.adOrig.currencyCode);
              }
            }

            if ("phone" in this.adOrig) {
              this.adHasPhone = true;
              if (
                "phonePublic" in this.adOrig &&
                this.adOrig.phonePublic === true
              ) {
                this.phonePublic = true;
              } else if ("phonePublic" in this.adOrig === false) {
                this.phonePublic = false;
              }

              this.setPhoneString = "Change Phone";

              this.phone = this.adOrig.phone;
            } else {
              this.adHasPhone = false;
              this.setPhoneString = "Set Phone";
            }
            this.condition = this.adOrig.condition;
            this.description = this.adOrig.description;

            if ("contactMethod" in this.adOrig) {
              this.contactMethod = this.adOrig.contactMethod;
            }

            // Jobs
            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
            ) {
              this.adType = this.adOrig.adType;
              this.jobType = this.adOrig.jobType;
              this.advertisedBy = this.adOrig.advertisedBy;
              this.salaryType = this.adOrig.salaryType;
              this.salary = this.adOrig.salary;
            }

            // Mobililty Transport
            if (
              this.mainCategory ==
                this.mainCategories[
                  this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT
                ] &&
              this.subCategory != "Wheelchair & Scooter Parts/Accs"
            ) {
              this.make = this.adOrig.make;
              this.model = this.adOrig.model;
              this.kilometres = this.adOrig.kilometres;
              this.colour = this.adOrig.colour;
              this.year = this.adOrig.year;
              this.selectedVehicleType = this.adOrig.vehicleType;
              // Car specific fields checked after call to getSubCategories()
            }

            if (
              this.mainCategory ==
                this.mainCategories[
                  this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT
                ] &&
              this.subCategory == "Wheelchairs" &&
              this.subCategory == "Tricycles"
            ) {
              this.selectedVehicleType = this.adOrig.vehicleType;
            }

            // Real Estate
            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
            ) {
              this.forSaleBy = this.adOrig.forSaleBy;
              this.startDate = this.adOrig.startDate;

              this.selectDateBtnStr = "Change Date";
            }

            // Travel
            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
            ) {
              if (this.adOrig.wheelchairCompliant == true) {
                this.wheelchairCompliant = "Yes";
              } else {
                this.wheelchairCompliant = "No";
              }
            }

            // images
            //await this.getAdsFiles();

            // deep copy any existing adFiles objects and adsFilesURLs
            /*
          for (i = 0; i < this.adsFiles.length; i++) {
            let file = this.adsFiles[i];

            this.draftFiles[i] = file;
            this.draftFilesURLs[i] = this.adsFilesURLs[i];

            this.printJson(file, "adsFiles " + i);

            if ("rotationDeg" in file) {
              this.debug("a " + file.rotationDeg);
              this.rotationAngles[i] = file.rotationDeg;
            }
          }
          */

            if ("rotationAngles" in this.adOrig) {
              this.rotationAngles = this.deepCopyArrary(
                this.adOrig.rotationAngles
              );
            }

            this.currentNumDraftFiles = this.adOrig.savedFiles.length;

            this.debug(
              `init a currentNumDraftFiles = ${this.currentNumDraftFiles}`
            );

            let i;
            for (i = 0; i < this.currentNumDraftFiles; i++) {
              this.adsFilesURLs[i] =
                Config.BACKEND00_FILES_URL + "/" + this.adOrig.savedFiles[i];

              this.draftFilesURLs[i] = this.adsFilesURLs[i];
              this.draftFiles[i] = null;
            }

            //this.savedFilesSizes = this.deepCopyArrary(this.adOrig.savedFilesSizes);

            // always add one more pick photo for user unless we reached 8
            if (i < this.maxFiles) {
              this.draftFilesURLs[i] = "";
            }
          } // existing ad

          this.getSubCategories();

          if (this.newAd != true) {
            this.subCategory = this.adOrig.subCategory;

            // specific fields in Mobile Transport > Cars
            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT]
            ) {
              if (
                this.subCategory == "Cars" ||
                this.subCategory == "Camper Vans" ||
                this.subCategory == "Boats" ||
                this.subCategory == "Other Vehicles"
              ) {
                if ("horsepower" in this.adOrig) {
                  this.horsepower = this.adOrig.horsepower;
                }

                if ("numCylinders" in this) {
                  this.numCylinders = this.adOrig.numCylinders;
                }

                if ("engineCapacity" in this.adOrig) {
                  this.engineCapacity = this.adOrig.engineCapacity;
                }
              }

              if (this.subCategory == "Boats") {
                this.engineHours = this.adOrig.engineHours;
              }
            }
          }

          this.updatePriceOptions();

          //update countries dropdown
          this.countries = [];
          for (let i = 0; i < this.$store.state.countries.length; i++) {
            if (this.$store.state.countries[i].title !== "All") {
              this.countries.push(this.$store.state.countries[i].title);
            }
          }

          let end = new Date();
          let diffs = (end - start) / 1000;
          this.debug(`SubmitAdHelper load time = ${diffs} s`);
        }
      }
      this.hideLoading();
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.acknowledgeOk = false;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.acknowledgeOk = true;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    async checkInputs() {
      let ok = true;
      let message = "";
      this.debug("typeof this.year = " + typeof this.year);
      if (
        this.mainCategory !==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.MISCELLANEOUS]
      ) {
        if (this.subCategory.length == 0) {
          this.$refs.mainCategory.$el.scrollIntoView();
          this.setAcknowledgeError("Please select a Sub Category");
          ok = false;
        }
      }

      if (this.title.length == 0) {
        this.$refs.mainCategory.$el.scrollIntoView();
        this.setAcknowledgeError("Please enter a Title.");
        ok = false;
      } else {
        let checkArray = this.checkSwear(this.title);
        if (checkArray.length != 0) {
          this.$refs.mainCategory.$el.scrollIntoView();
          this.setAcknowledgeError(
            "Please remove these word/s '" + checkArray.join() + "' from title."
          );
          ok = false;
        }
      }

      if (this.title.length == 0) {
        this.$refs.mainCategory.$el.scrollIntoView();
        this.setAcknowledgeError("Please enter a Title.");
        ok = false;
      }

      if (this.title.length > Config.TITLE_MAX) {
        this.$refs.mainCategory.$el.scrollIntoView();
        this.setAcknowledgeError(
          "Title: Please do not exceed " + Config.TITLE_MAX + " characters."
        );
        ok = false;
      }

      if (ok == true) {
        if (
          this.mainCategory ==
            this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE] &&
          this.startDate == ""
        ) {
          this.message = "Please enter a start date";
          this.showErrorMessage = true;
          this.$refs.mainCategory.$el.scrollIntoView();
          this.setAcknowledgeError(this.message);
          ok = false;
        }
      }

      if (ok == true) {
        if (
          this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
        ) {
          if (
            this.jobType != this.VOLUNTEER_STR &&
            this.salaryType != this.VOLUNTEER_STR
          ) {
            if (this.salary == "") {
              message = "Please enter a salary if not a Volunteer job.";

              this.$refs.jobType.$el.scrollIntoView();
              this.setAcknowledgeError(message);

              ok = false;
            } else if (parseFloat(this.salary) <= 0) {
              message = "Salary: Must be larger than 0.";

              ok = false;

              this.$refs.jobType.$el.scrollIntoView();
              this.setAcknowledgeError(message);
            }
          }
        } else {
          if (this.priceOption != "Free") {
            if (this.price.length == 0) {
              if (
                this.mainCategory ==
                this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
              ) {
                message = "Please enter a price.";
              } else {
                message = "Please enter a price (if not Free).";
              }

              this.$refs.title.$el.scrollIntoView();
              this.setAcknowledgeError(message);
              ok = false;
            } else if (parseFloat(this.price) <= 0) {
              this.$refs.title.$el.scrollIntoView();
              this.setAcknowledgeError("Price: Must be larger than 0.");
              ok = false;
            }
          }
        }
      }

      if (ok == true) {
        if (this.refreshAddressMsg != "") {
          this.message = this.refreshAddressMsg;
          this.showErrorMessage = true;
          ok = false;
        }
      }

      if (ok == true) {
        if (this.addressChosen == false) {
          ok = false;

          this.$refs.title.$el.scrollIntoView();
          this.setAcknowledgeError("Please enter Address or state/suburb.");
        }
      }

      if (
        ok == true &&
        this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT] &&
        this.subCategory != "Wheelchair & Scooter Parts/Accs"
      ) {
        if (this.make == "") {
          this.$refs.myEmail.scrollIntoView();
          this.setAcknowledgeError("Please enter Make.");
          ok = false;
        } else if (this.make.length > Config.TITLE_MAX) {
          this.$refs.myEmail.scrollIntoView();
          this.setAcknowledgeError(
            "Make: Please do not exceed " + Config.TITLE_MAX + " characters."
          );

          ok = false;
        } else if (this.model == "") {
          this.$refs.myEmail.scrollIntoView();
          this.setAcknowledgeError("Please enter Model.");
          ok = false;
        } else if (this.model.length > Config.TITLE_MAX) {
          this.$refs.myEmail.scrollIntoView();
          this.setAcknowledgeError(
            "Model: Please do not exceed " + Config.TITLE_MAX + " characters."
          );
          ok = false;
        }

        if (ok == true) {
          if (this.subCategory == "Boats") {
            if (this.engineHours == "") {
              this.$refs.useMyPhoneBtn.$el.scrollIntoView();
              this.setAcknowledgeError("Please enter Engine Hours.");
              ok = false;
            } else if (this.engineHours <= 0) {
              this.$refs.useMyPhoneBtn.$el.scrollIntoView();
              this.setAcknowledgeError("Engine Hours: Must be larger than 0.");
              ok = false;
            }
          } else {
            if (this.kilometres == "") {
              this.$refs.useMyPhoneBtn.$el.scrollIntoView();
              this.setAcknowledgeError("Please enter Kilometres.");
              ok = false;
            } else if (this.kilometres <= 0) {
              this.$refs.useMyPhoneBtn.$el.scrollIntoView();
              this.setAcknowledgeError("Kilometres: Must be larger than 0.");
              ok = false;
            }
          }
        }

        if (ok == true) {
          if (this.colour == "") {
            this.$refs.model.$el.scrollIntoView();
            this.setAcknowledgeError("Please enter Colour.");
            ok = false;
          } else if (this.colour.length > Config.TITLE_MAX) {
            this.$refs.model.$el.scrollIntoView();
            this.setAcknowledgeError(
              "Colour: Please do not exceed " +
                Config.TITLE_MAX +
                " characters."
            );
            ok = false;
          } else if (this.year == "") {
            this.$refs.model.$el.scrollIntoView();
            this.setAcknowledgeError("Please enter Year.");
            ok = false;
          } else if (parseFloat(this.year) <= 0) {
            this.$refs.model.$el.scrollIntoView();
            this.setAcknowledgeError("Year: Must be larger than 0.");
            ok = false;
          } else if (parseFloat(this.year) > this.currentYear) {
            this.$refs.model.$el.scrollIntoView();
            this.setAcknowledgeError(
              "Year must be no more than " + this.currentYear
            );
            ok = false;
          }
        }

        if (ok == true && this.subCategory == "Cars") {
          if (
            typeof this.horsepower !== "undefined" &&
            this.horsepower.length > 0 &&
            parseFloat(this.horsepower) < 0
          ) {
            this.$refs.colour.$el.scrollIntoView();
            this.setAcknowledgeError("Horsepower: Must be larger than 0.");
            ok = false;
          } else if (
            typeof this.numCylinders !== "undefined" &&
            this.numCylinders.length > 0 &&
            parseFloat(this.numCylinders) < 0
          ) {
            this.$refs.year.$el.scrollIntoView();
            this.setAcknowledgeError(
              "Number of Cylinders: Must be larger than 0."
            );
            ok = false;
          } else if (
            typeof this.engineCapacity !== "undefined" &&
            this.engineCapacity.length > 0 &&
            parseFloat(this.engineCapacity) < 0
          ) {
            this.$refs.horsepower.$el.scrollIntoView();
            this.setAcknowledgeError("Engine Capacity: Must be larger than 0.");
            ok = false;
          }
        } // Cars subCategory

        if (ok == true) {
          if (
            this.subCategory == "Tricycles" ||
            this.subCategory == "Wheelchairs"
          ) {
            if (this.selectedVehicleType == "") {
              this.$refs.useMyPhoneBtn.$el.scrollIntoView();
              this.setAcknowledgeError("Please select the type of vehicle.");
              ok = false;
            }
          }
        } // Tricycles and Wheelchairs
      } // Mobility Transport Category

      if (ok == true) {
        if (this.description.length == 0) {
          message = "Please enter a Description.";
          //this.showErrorMessage = true
          this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          ok = false;
        } else if (this.description.length > Config.AD_DESC_MAX) {
          this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(
            "Description: Please do not exceed " +
              Config.AD_DESC_MAX +
              " characters."
          );
          ok = false;
        } else {
          let checkArray = this.checkSwear(this.description);
          if (checkArray.length != 0) {
            this.$refs.useMyPhoneBtn.$el.scrollIntoView();
            this.setAcknowledgeError(
              "Please remove these word/s '" +
                checkArray.join() +
                "' from description."
            );
            ok = false;
          }
        }
      }

      if (ok === true) {
        if (this.currencyCode === "") {
          this.setAcknowledgeError(
            "Currency not updated for your address. Please update your address  again"
          );
          ok = false;
        }
      }

      /*if (ok === true) {
        let text = this.title + " " + this.description;
        let res = await this.guessLanguage(text);
        if (res.ok == true) {
          ok = true;
          this.selectedLanguage = res.languageName;
          this.selectedLocale = res.languageCode;
        } else {
          ok = false;
        }
        if (ok == false) {
          ok = false;
          //Show dropdown to select language
          this.languageNames = [];
          this.languageISOCodes = res.languageISOCodesArray;
          this.showSelectLanguage = true;
          this.debug(`total languages ${this.languageISOCodes.length}`);
          for (let i = 0; i < this.languageISOCodes.length; i++) {
            let langobj = this.languageISOCodes[i];
            this.printJson(langobj, "object" + i);
            this.languageNames.push(langobj.name);
          }
          while (this.showSelectLanguage === true) {
            await this.synchSetTimeout(200);
            this.debug(".");
          }
          ok = true;
        }
      }*/

      return ok;
    },

    /*
    async uploadFile(file) {
      let ok = false;

      let url = Config.NODE_SERVER_IP + Config.UPLOAD_FILES_FOR_AD;

      this.debug(`uploadeFile() url = ${url}, file.name = ${file.name}`);
      this.printJson(file, "file");

      let headers = {
        Authorization:
          this.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      // Build form data
      let payload = new FormData();

      // Multipart attachment
      payload.append("file", file, file.name);

      try {
        let res = await fetch(url, {
          method: "POST",
          headers: headers,
          body: payload,
        });

        let jsonRes = await res.json();
        this.printJson(jsonRes, "uploadFile() POST result");

        ok = true;
      } catch (e) {
        this.debug(e);
      }

      return ok;
    },
    */

    showProgress(event) {
      this.debug(`Uploaded ${event.loaded} of ${event.total} bytes`);
    },

    /*
    showLoadEnd() {
    },
    */

    /* requestType - GET, POST.
     * url - endpoint to hit.
     * requestHeaders - eg.
     *   let headers = {
     *     Authorization:
     *       this.accessTokenDecoded.typ +
     *       " " +
     *       this.$store.state.tokens.access_token,
     *     Accept: "application/json",
     *   };
     *
     * Note: To use this inside the Promise() we first assign this to a self
     * variable:
     * https://stackoverflow.com/questions/41286568/returning-promise-from-api-and-putting-into-global-data
     */
    synchXHR(requestType, url, headers, payload) {
      // use self in place of this inside the Promise() executor
      const self = this;
      self.percentageLoader = 0;
      self.uploadStartTime = Date.now();

      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "json";
        xhr.open(requestType, url);

        for (const header in headers) {
          xhr.setRequestHeader(header, headers[header]);
        }

        xhr.upload.onprogress = function (event) {
          self.debug(`Uploaded ${event.loaded} of ${event.total} bytes`);
          // self.messageLoader += `Uploaded ${event.loaded} of ${event.total} bytes<br/>`;
          let pc = (event.loaded / event.total) * 100.0;
          if (pc > 0) {
            let speed =
              event.loaded / ((Date.now() - self.uploadStartTime) / 1000);
            self.loadData = `Uploaded${event.loaded} of ${
              event.total
            } bytes <br/>${speed} bytes/s<br/>${
              (event.total - event.loaded) / speed
            } seconds remaining`;
          } else {
            self.loadData = `Uploaded${event.loaded} of ${event.total} bytes <br/>Calculating Remaining Time `;
          }
          self.percentageLoader = pc;
          // self.messageLoader += `${pc}% <br/>`;
        };

        xhr.onloadend = function () {
          self.messageLoader += "\nDone! ";
          if (xhr.status == 200) {
            self.debug("xhr.onloadend success");
            self.debug(`${xhr.statusText}`);
            resolve(xhr.response);
          } else {
            self.debug("xhr.onloadend error " + this.status);
            self.debug(`${xhr.statusText}`);
            reject(`${xhr.status} ${xhr.statusText}`);
          }
        };

        xhr.onerror = function () {
          reject(`${xhr.status} ${xhr.statusText}`);
        };

        xhr.send(payload);
      });
    },

    async uploadFilesForAd(files, adId) {
      this.percentageLoader = 0;
      let jsonRes = {
        ok: false,
        savedFiles: [],
      };

      this.debug(`files.length = ${files.length}`);
      //if (files.length > 0) {
      let url = Config.NODE_SERVER_IP + Config.UPLOAD_FILES_FOR_AD;

      this.printJson(files, "files");

      let headers = {
        Authorization:
          this.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      // Build form data
      let payload = new FormData();

      // Multipart attachment
      this.messageLoader += "<br/>Batch uploading<br/>";
      let numValidFiles = 0;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file !== undefined && file !== null) {
          this.debug(`uploadeFiles() file ${i}`);
          this.debug(file);
          payload.append(`file${i}`, file);
          this.messageLoader += `${i}. ${file.name}<br/>`;
          numValidFiles++;
        }
      }

      this.debug(`numValidFiles = ${numValidFiles}`);

      payload.append("adId", adId);

      if (numValidFiles > 0) {
        try {
          jsonRes = await this.synchXHR("POST", url, headers, payload);
          // convert xhrResponse (string) into an Object.
          // jsonRes = JSON.parse(xhrResponse);
          this.printJson(jsonRes);
          this.debug(`upload completed, ${jsonRes}`);
        } catch (e) {
          this.debug(e);
        }
      } else {
        jsonRes = {
          ok: true,
          savedFiles: [],
          //savedFilesSizes: [],
        };
      }

      return jsonRes;
    },

    async saveAd() {
      if (await this.isLoggedInOIDC()) {
        this.messageLoader = "Checking inputs...";

        let ok = await this.checkInputs();
        let ok1 = await this.checkInputsForMultipleCountriesSelection();

        if (ok && ok1) {
          this.showLoading();
          this.messageLoader += "ok<br/>";

          let finalAddressObj = this.addressObj;
          if (this.addressOption == "Show State & Suburb Only") {
            finalAddressObj = {
              suburb: this.addressObj.suburb,
              state: this.addressObj.state,
              postcode: this.addressObj.postcode,
              country: this.addressObj.country,
            };
          }

          // TODO: add timezone support
          try {
            let record = {
              mainCategory: this.mainCategory,
              title: this.title,
              ownerGivenName: this.accessTokenDecoded.given_name,
              country: this.countryOfAd,
            };

            if (this.$store.state.merchant !== "") {
              record["merchant"] = this.$store.state.merchant;
            }

            if (
              this.mainCategory !=
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.MISCELLANEOUS]
            ) {
              record["subCategory"] = this.subCategory;
            }

            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
            ) {
              record["adType"] = this.adType;
              record["jobType"] = this.jobType;
              record["advertisedBy"] = this.advertisedBy;

              if (this.jobType != this.VOLUNTEER_STR) {
                record["salaryType"] = this.salaryType;
                if (this.salaryType != this.VOLUNTEER_STR) {
                  record["salary"] = this.salary;
                }
              }
            } else {
              // not Job
              record["priceOption"] = this.priceOption;
              record["price"] = this.price;
            }

            if (
              this.mainCategory !=
                this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS] &&
              this.mainCategory !=
                this.mainCategories[this.MAIN_CATEGORIES_ENUM.SERVICES] &&
              this.mainCategory !=
                this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
            ) {
              record["condition"] = this.condition;
            }

            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT]
            ) {
              record["make"] = this.make;
              record["model"] = this.model;

              if (this.subCategory == "Boats") {
                record["engineHours"] = this.engineHours;
              } else {
                record["kilometres"] = this.kilometres;
              }

              record["colour"] = this.colour;
              record["year"] = this.year;

              if (this.horsepower != "") {
                record["horsepower"] = this.horsepower;
              }

              if (this.numCylinders != "") {
                record["numCylinders"] = this.numCylinders;
              }

              if (this.engineCapacity != "") {
                record["engineCapacity"] = this.engineCapacity;
              }

              if (this.selectedVehicleType != "") {
                record["vehicleType"] = this.selectedVehicleType;
              }
            }

            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
            ) {
              if (this.subCategory != "Wanted") {
                record["forSaleBy"] = this.forSaleBy;
              }

              record["startDate"] = this.startDate;
            }

            if (
              this.mainCategory ==
              this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
            ) {
              if (this.wheelchairCompliant == "No") {
                record["wheelchairCompliant"] = false;
              } else {
                record["wheelchairCompliant"] = true;
              }
            }

            record["addressObj"] = finalAddressObj;

            if (this.adHasPhone == true) {
              if (this.phoneOption == this.phoneOptions[0]) {
                this.phonePublic = true;
              }
              this.debug(`PhonePublic Status ${this.phonePublic}`);
              record["phone"] = this.phone;
              record["phonePublic"] = this.phonePublic;
            }

            record["contactMethod"] = this.contactMethod;

            record["description"] = this.description;
            record["published"] = true;

            record["currencyCode"] = this.currencyCode;

            //record["locale"] = this.selectedLocale;

            // hit /saveAd
            let url = Config.NODE_SERVER_IP + Config.SAVE_AD;

            let headers = {
              Authorization:
                this.accessTokenDecoded.typ +
                " " +
                this.$store.state.tokens.access_token,
              Accept: "application/json",
            };

            let payload = new FormData();

            payload.append("ad", JSON.stringify(record));

            let fetchData = {
              method: "POST",
              headers: headers,
              body: payload,
            };

            let adId = "";
            try {
              this.messageLoader += "ok<br/>Uploading ad...";

              let res = await fetch(url, fetchData);
              let jsonRes = await res.json();
              ok = jsonRes.ok;

              if (ok) {
                adId = jsonRes.adId;
              } else {
                this.setAcknowledgeError(jsonRes.message);
              }
            } catch (e) {
              this.debug(e);
              this.setAcknowledgeError(e);
              ok = false;
            }
            let savedFilesToAdd = [];
            if (ok) {
              let uploadFilesResult = await this.uploadFilesForAd(
                this.draftFiles,
                adId
              );
              ok = uploadFilesResult.ok;
              savedFilesToAdd = uploadFilesResult.savedFiles;
              if (ok === false) {
                this.setAcknowledgeError(uploadFilesResult.message);
              }
            }

            if (ok) {
              //check for multiple countries and post them
              await this.checkAndSaveMultipleAds(adId, savedFilesToAdd);

              //let adType = Config.AD_TYPES_ENUM.MY_ADS;

              //await this.saveAdInEnglish(adId);
              this.hideLoading();
              //await this.synchSetTimeout(1);

              // this.gotoAdView(adId, adType, "promoteAd");
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.hideLoading();
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    removePic(index) {
      if (this.draftFilesURLs[index] != null) {
        let i;
        for (i = index; i < this.currentNumDraftFiles - 1; i++) {
          this.draftFiles[i] = this.draftFiles[i + 1];

          this.rotationAngles[i] = this.rotationAngles[i + 1];
          this.applyRotationAngle(i);

          this.draftFilesURLs[i] = this.draftFilesURLs[i + 1];

          //this.savedFilesSizes[i] = this.savedFilesSizes[i + 1];
          this.debug(`${i + 1} -> ${i}`);
        }

        //this.draftFiles[i] = null;
        this.draftFiles.splice(i, 1);

        //Vue.set(this.draftFilesURLs, i, '')
        this.draftFilesURLs.splice(i, 1);

        this.rotationAngles.splice(i, 1);

        //this.savedFilesSizes.splice(i, 1);

        if (this.currentNumDraftFiles == this.maxFiles) {
          // add the pick photo card to the end when we go from 8 to 7 images
          this.draftFilesURLs.push("");
        }

        this.currentNumDraftFiles--;

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    /* Usage
     * const dataURLs = await this.fileListToBase64(this.draftFiles)
     */
    /*
    async fileListToBase64(fileList) {
      // create function which return resolved promise
      // with data:base64 string
      function getBase64(file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      }
      // here will be array of promisified functions
      const promises = []

      // loop through fileList with for loop
      for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]))
      }

      // array with base64 strings
      return await Promise.all(promises)
    }, */

    /* references:
     * 1. https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
     * 2. https://simon-schraeder.de/posts/filereader-async/
     */
    readAsDataURLAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },

    // view-source:http://seikichi.github.io/tiff.js/upload.html
    /*
    readAsArrayBufferAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
      });
    },
    */

    updateMainCategory(index) {
      this.debug("updateMainCategory()");
      this.mainCategory = this.mainCategories[index];
      this.getSubCategories();
      this.updatePriceOptions();
    },

    updateSubCategory(index) {
      this.subCategory = this.subCategories[index];
    },

    updateForSaleBy(index) {
      this.forSaleBy = this.forSaleBys[index];
    },

    updateAdType(index) {
      this.adType = this.adTypes[index];
    },

    updateJobType(index) {
      this.jobType = this.jobTypes[index];
    },

    updateAdvertisedBy(index) {
      this.advertisedBy = this.advertisedBys[index];
    },

    updateSalaryType(index) {
      this.salaryType = this.salaryTypes[index];
    },

    updatePriceOption(index) {
      this.priceOption = this.priceOptions[index];
    },

    updatePhoneOption(index) {
      this.phoneOption = this.phoneOptions[index];
      this.debug(this.phoneOption);
      if (index == 0) {
        this.phonePublic = true;
      } else if (index == 1) {
        this.phonePublic = false;
      }
    },

    updateAddressOption(index) {
      if (this.addressOption != this.addressOptions[index]) {
        this.addressOption = this.addressOptions[index];
        if (this.addressOption == "Show Full Address") {
          this.useMyAddressBtnString = "Same As My Address";
          this.setAddressBtnString = "anotherAddress";

          if (this.newAd == false) {
            if (
              this.firstTimeChangeAddressOption == true &&
              "number" in this.addressObj == ""
            ) {
              this.refreshAddressMsg =
                'Please now click one of "' +
                this.useMyAddressBtnString +
                '" or "' +
                this.setAddressBtnString +
                '" to complete the address.';

              this.firstTimeChangeAddressOption = false;
            } else {
              this.refreshAddressMsg = "";
            }
          }
        } else {
          this.useMyAddressBtnString = "getAddress";
          this.setAddressBtnString = "anotherAddress";
        }
        //this.addressChosen = false
      }
    },

    cancelNoFullAddressMessage() {
      if (this.message == this.refreshAddressMsg) {
        this.message = "";
        this.showErrorMessage = false;
      }
      this.refreshAddressMsg = "";
    },

    useMyAddress() {
      this.addressObj = this.$store.state.addressObj;
      this.currencyCode = this.$store.state.currencyCode;
      this.addressChosen = true;
      this.countryOfAd = this.addressObj.country;

      this.cancelNoFullAddressMessage();
    },

    finalizeAddress(addressObj, currencyCode) {
      this.isShowMyAddress = false;
      this.debug(currencyCode);
      this.addressObj = addressObj;
      this.addressChosen = true;
      if (this.currencyCodes.includes(currencyCode) == false) {
        this.currencyCodes.push(currencyCode);
      }
      this.currencyCode = currencyCode;
      this.countryOfAd = this.addressObj.country;

      this.cancelNoFullAddressMessage();
      this.myAddressKey++;
    },

    async finalizePhone(phoneNumber) {
      this.phone = phoneNumber;

      this.setPhoneString = "Change Phone";

      this.adHasPhone = true;

      this.hideMyPhone();
    },

    useMyPhone() {
      this.phone = this.$store.state.phone;
      this.setPhoneString = "Change Phone";

      this.adHasPhone = true;
    },

    removePhone() {
      this.setPhoneString = "Set Phone";

      this.phone = "";
      this.contactMethod = this.contactMethods[0];
      this.adHasPhone = false;
    },

    updateCondition(index) {
      this.condition = this.conditions[index];
    },

    updateWheelchairCompliant(index) {
      this.wheelchairCompliant = this.wheelchairCompliants[index];
    },

    updateVehicletype(index) {
      this.selectedVehicleType = this.vehicleTypes[index];
    },

    calendarClicked() {
      this.showSelectStartDate = false;
      this.selectDateBtnStr = "Change Date";
    },

    async EXIFGetAllTags(file) {
      let result = {};
      try {
        let dataURL = await this.readAsDataURLAsync(file);

        // piexifjs only works for jpeg
        if (dataURL.indexOf("data:image/jpeg;base64,") > 0) {
          result = piexif.load(dataURL);

          this.printJson(result, "EXIFGetAllTags result");
        }
      } catch (e) {
        console.error(e);
      }

      return result;
    },

    /*
    EXIFGetAllTagsOrig(file) {
      return new Promise((resolve) => {
        EXIF.getData(file, function () {
          resolve(EXIF.getAllTags(this));
        });
      });
    },
    */

    /* event is a file:

      name: "image1.jpeg"
      lastModified: 1581573364898
      lastModifiedDate: Thu Feb 13 2020 13:56:04 GMT+0800 (Australian Western Standard Time) {}
      webkitRelativePath: ""
      size: 500722
      type: "image/jpeg"
      exifdata: {Make: "Apple", Model: "iPhone 6 Plus", Orientation: 6, XResolution: Number, YResolution: Number, …}
      iptcdata: {}
      __proto__: File
    */

    async gotEXIF(file) {
      let isExif = false;

      let allMetaData = await this.EXIFGetAllTags(file);
      this.printJson(allMetaData, "allMetaData");

      if (
        "Exif" in allMetaData === true &&
        isEmpty(allMetaData.Exif) === false
      ) {
        isExif = true;
      }

      return isExif;
    },

    isFileObject(theObject) {
      return theObject instanceof File;
    },

    isBlobObject(theObject) {
      return theObject instanceof Blob;
    },

    syncCanvasToFile(ctx, fileName, mime, quality) {
      return new Promise((resolve) => {
        ctx.canvas.toBlob(
          (blob) => {
            this.debug(`syncCanvasToFile, ${fileName}, ${mime}, ${quality}`);
            this.debug(blob); //output image as a blob
            const file = this.blobToFile(blob, fileName, mime);
            resolve(file);
          },
          mime,
          quality
        );
      });
    },

    syncLoadImage(dataURL) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
          resolve(img);
        };

        img.src = dataURL;
      });
    },

    /* returns the new width x height of the shrunken image keeping aspect ratio
     * Takes the original Image
     * If image already fits inside 640x480, the new widths & height remains the same
     */
    getDesiredImageDimensions(imageOrig) {
      let newWidth;
      let newHeight;

      let needsResizing = false;

      if (
        imageOrig.width <= this.IMAGE_MAX_WIDTH &&
        imageOrig.height <= this.IMAGE_MAX_HEIGHT
      ) {
        newWidth = imageOrig.width;
        newHeight = imageOrig.height;
        needsResizing = false;
      } else {
        const widthRatio = imageOrig.width / this.IMAGE_MAX_WIDTH;
        const heightRatio = imageOrig.height / this.IMAGE_MAX_HEIGHT;

        /* if image is shrunk, the image width will be the last to fit
         * inside our dimensions, use width to calculate the scaling factor.
         */
        let scalingFactor;
        if (widthRatio > heightRatio) {
          scalingFactor = this.IMAGE_MAX_WIDTH / imageOrig.width;
          newWidth = this.IMAGE_MAX_WIDTH;
          newHeight = scalingFactor * imageOrig.height;
        } else {
          // use the height to calculate scalingFactor
          scalingFactor = this.IMAGE_MAX_HEIGHT / imageOrig.height;
          newHeight = this.IMAGE_MAX_HEIGHT;
          newWidth = scalingFactor * imageOrig.width;
        }

        needsResizing = true;
      }

      return {
        width: newWidth,
        height: newHeight,
        needsResizing: needsResizing,
      };
    },

    /* Resizes image to fit inside a 640 x 480 rectangle
     * If image already fits inside 640 x 480, nothing is changed.
     */
    async resizeImage(origFile) {
      let dataURLOrig = await this.readAsDataURLAsync(origFile);

      const img = await this.syncLoadImage(dataURLOrig);

      let dimensions = this.getDesiredImageDimensions(img);

      const width = dimensions.width;
      const height = dimensions.height;

      let file;

      if (dimensions.needsResizing === true) {
        var canvas = document.getElementById("myCanvas");

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const quality = 1.0;
        const mime = origFile.type;
        const dataURLResized = ctx.canvas.toDataURL(mime, quality);

        file = this.dataURLToFile(dataURLResized, origFile.name);
      } else {
        file = origFile;
      }

      return file;
    },

    async addDraftFileURL(event, index) {
      let file = await this.resizeImage(event);

      let urlObj = URL.createObjectURL(file);

      this.dummyDataURL = urlObj;

      Vue.set(this.draftFiles, index, file);
      this.printArray(this.draftFiles, "draftFiles");

      // increment currentNumDraftFiles if we're not an image from Vue Cropper

      /* only if we are adding a new file
       * don't update currentNumDraftFiles or append the new file cell if
       * we are replacing an exisint image from Vue Cropper
       */
      if (index === this.currentNumDraftFiles) {
        if (this.currentNumDraftFiles < this.maxFiles) {
          this.currentNumDraftFiles++;
        }

        // only show the add another file cell if we are not maxed out
        if (this.currentNumDraftFiles < this.maxFiles) {
          this.draftFilesURLs[this.currentNumDraftFiles] = "";
        }
      }

      Vue.set(this.draftFilesURLs, index, urlObj);

      this.printArray(this.draftFilesURLs, "draftFilesURLs");

      this.debug(`addDraftFileURL ${index}`);
      if ((await this.gotEXIF(file)) == true) {
        await this.removeExif(index);
      }

      if (file.size > Config.MAX_FILE_SIZE_BYTES) {
        this.isLoading = false;
        this.imageCompressIndex = index;
        this.yesNoMessageCompress = `Image ${file.name} is larger than ${Config.MAX_FILE_SIZE_BYTES} bytes. Want to compress image?`;
        this.showYesNoCompressCounter++;
        this.showYesNoCompressKey = `showYesNoCompress${this.showYesNoCompressCounter}`;
        this.showYesNoCompress = true;
      }

      while (this.showYesNoCompress) {
        await this.synchSetTimeout(200);
      }

      /*
      if (this.compressed === true) {
        Vue.set(this.savedFilesSizes, index, this.draftFiles[index].size);
        //Vue.set(this.savedFilesSizes, index, file.size);
      }
      */
      this.rotationAngles[index] = 0;
    },

    /*
    async addDraftFileURLOrig(event, index) {
      this.dummyURLResized = await this.resizeImage(event);

      this.debug(`addDraftFileURL ${index}`);
      this.debug(event);
      this.printJson(event);
      this.debug(this.dummyURLResized);

      let urlObj = URL.createObjectURL(event);

      this.dummyDataURL = urlObj;
      this.debug(urlObj);
      this.debug(this.dummyDataURL);

      Vue.set(this.draftFiles, index, event);
      this.printArray(this.draftFiles, "draftFiles");

      // increment currentNumDraftFiles
      if (this.currentNumDraftFiles < this.maxFiles) {
        this.currentNumDraftFiles++;
      }

      // after increment, add another pick photo card only if we < 8
      if (this.currentNumDraftFiles < this.maxFiles) {
        this.draftFilesURLs[this.currentNumDraftFiles] = "";
      }

      Vue.set(this.draftFilesURLs, index, urlObj);

      this.printArray(this.draftFilesURLs, "draftFilesURLs");

      if ((await this.gotEXIF(event)) == true) {
        await this.removeExif(index);
      }

      this.debug("size = " + event.size);

      if (event.size > Config.MAX_FILE_SIZE_BYTES) {
        this.imageCompressIndex = index;
        this.yesNoMessageCompress = `Image ${event.name} is larger than ${Config.MAX_FILE_SIZE_BYTES} bytes. Want to compress image?`;
        this.showYesNoCompress = true;
      }

      while (this.showYesNoCompress) {
        await this.synchSetTimeout(200);
      }

      if (this.compressed === true) {
        Vue.set(this.savedFilesSizes, index, this.draftFiles[index].size);
        //Vue.set(this.savedFilesSizes, index, event.size);
      }
      this.rotationAngles[index] = 0;
    },
    */

    //**dataURL to blob**
    dataURLtoBlob(dataURL) {
      var arr = dataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
    blobToFile(theBlob, fileName, type) {
      return new File([theBlob], fileName, {
        type: type,
        lastModified: Date.now(),
      });
    },

    dataURLToFile(dataURL, name) {
      let blob = this.dataURLtoBlob(dataURL);
      let file = this.blobToFile(blob, name, blob.type);
      return file;
    },

    async removeExif(index) {
      this.showLoading();

      try {
        let fileOrig = this.draftFiles[index];

        let dataURL = await this.readAsDataURLAsync(fileOrig);

        let exifStripped = piexif.remove(dataURL);

        let blob = this.dataURLtoBlob(exifStripped);

        let file = this.blobToFile(blob, fileOrig.name, fileOrig.type);

        this.printJson(blob, "file");

        this.draftFiles[index] = file;

        if ((await this.gotEXIF(file)) == true) {
          //this.setAcknowledgeError("Error: removed EXIF failed");
        } else {
          /*this.setAcknowledge(
            "Success: removed EXIF successfully.",
            "Success!"
          );
          */
          // update draftFilesURLs
          let urlObj = URL.createObjectURL(file);
          Vue.set(this.draftFilesURLs, index, urlObj);
        }
      } catch (e) {
        this.message = e;
        this.showErrorMessage = true;
      }

      this.showYesNoExif = false;

      this.hideLoading();
    },

    movePicLeft(index) {
      if (index > 0) {
        let left = this.draftFiles[index - 1];
        this.draftFiles[index - 1] = this.draftFiles[index];
        this.draftFiles[index] = left;

        left = this.draftFilesURLs[index - 1];
        this.draftFilesURLs[index - 1] = this.draftFilesURLs[index];

        Vue.set(this.draftFilesURLs, index, left);

        let tmpDeg = this.rotationAngles[index - 1];
        this.rotationAngles[index - 1] = this.rotationAngles[index];
        this.rotationAngles[index] = tmpDeg;

        this.applyRotationAngle(index);
        this.applyRotationAngle(index - 1);

        /*
        let leftSize = this.savedFilesSizes[index - 1];
        this.savedFilesSizes[index - 1] = this.savedFilesSizes[index];
        this.savedFilesSizes[index] = leftSize;
        */

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    applyRotationAngle(index) {
      let img = document.getElementById("image" + index);
      let deg = this.rotationAngles[index];

      img.style.transform = "rotate(" + deg + "deg)";
    },

    applyAllRotationAngles() {
      this.debug(`applyAllRotationAngles a ${this.currentNumDraftFiles}`);
      this.debug(this.rotationAngles);
      for (let i = 0; i < this.currentNumDraftFiles; i++) {
        this.applyRotationAngle(i);
      }

      this.debug("applyAllRotationAngles z");
    },

    rotatePic(index) {
      this.debug("rotatePic a");
      this.rotationAngles[index] = (this.rotationAngles[index] + 90) % 360;
      this.printArray(this.rotationAngles);

      this.debug("rotatePic b");
      this.applyRotationAngle(index);

      this.debug("rotatePic c");
    },

    movePicRight(index) {
      if (index < this.currentNumDraftFiles - 1) {
        let right = this.draftFiles[index + 1];
        this.draftFiles[index + 1] = this.draftFiles[index];
        this.draftFiles[index] = right;

        right = this.draftFilesURLs[index + 1];
        this.draftFilesURLs[index + 1] = this.draftFilesURLs[index];

        Vue.set(this.draftFilesURLs, index, right);

        let tmpDeg = this.rotationAngles[index + 1];
        this.rotationAngles[index + 1] = this.rotationAngles[index];
        this.rotationAngles[index] = tmpDeg;

        this.applyRotationAngle(index);
        this.applyRotationAngle(index + 1);

        /*
        let rightSize = this.savedFilesSizes[index + 1];
        this.savedFilesSizes[index + 1] = this.savedFilesSizes[index];
        this.savedFilesSizes[index] = rightSize;
        */

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    async deleteRemovedImages(adIdToDelete) {
      let ok = false;
      let hasFilesToRemove = false;

      let filesToDelete = [];

      /* Newly added files will be blobs in draftFilesURLs. We ignore them
       * when searching for deleted files
       */
      let currentFilenames = this.getCurrentFilenamesExcludeBlobs();
      this.printArray(
        currentFilenames,
        "deleteRemovedImages() currentFilenames"
      );

      // find files that are to be deleted
      for (let i = 0; i < this.adOrig.savedFiles.length; i++) {
        let filenameOrig = this.adOrig.savedFiles[i];

        let found = false;

        for (let j = 0; j < currentFilenames.length; j++) {
          let filename = currentFilenames[j];
          if (filenameOrig === filename) {
            found = true;
            this.debug(`found ${filenameOrig}`);
            break;
          }
        }

        if (found === false) {
          this.debug(`could not find ${filenameOrig}`);
          filesToDelete.push(filenameOrig);
        }
      }

      this.printArray(filesToDelete, "filesToDelete");
      if (filesToDelete.length > 0) {
        hasFilesToRemove = true;
        let url = Config.NODE_SERVER_IP + Config.DELETE_ADFILES_FOR_SINGLE_AD;

        this.debug(`url = ${url}`);

        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };
        this.printJson(headers, "headers");

        let payload = new FormData();

        payload.append("adId", adIdToDelete);
        payload.append("filesToDelete", JSON.stringify(filesToDelete));

        let fetchData = {
          method: "POST",
          headers: headers,
          body: payload,
        };

        this.messageLoader += `Deleting file(s):<br>`;
        for (let i = 0; i < filesToDelete.length; i++) {
          this.messageLoader += `  ${i}. ${filesToDelete[i]}<br/>`;
        }

        try {
          let res = await fetch(url, fetchData);

          let jsonRes = await res.json();
          this.printJson(jsonRes, "deleteRemovedImages() POST result");
          ok = jsonRes.ok;

          if (jsonRes.ok === false) {
            this.setAcknowledgeError(jsonRes.message);
          }
        } catch (e) {
          this.debug(e);
          this.setAcknowledgeError(e);
          ok = false;
        }
      } else {
        hasFilesToRemove = false;
      }

      if (filesToDelete.length === 0) {
        ok = true;
      }

      return {
        ok: ok,
        hasFilesToRemove: hasFilesToRemove,
      };
    },

    getFilenameFromURL(url) {
      let lastSlashIndex = url.lastIndexOf("/");
      let filename = url.substring(lastSlashIndex + 1);

      return filename;
    },

    getCurrentFilenames(excludeBlobs = false) {
      let filenames = [];

      this.printArray(this.draftFiles, "getCurrentFilenames() draftFiles");

      this.printArray(
        this.draftFilesURLs,
        "getCurrentFilenames() draftFilesURLs"
      );

      for (let i = 0; i < this.currentNumDraftFiles; i++) {
        let draftFile = this.draftFiles[i];
        this.debug(`${i}, draftFile = ${draftFile}`);
        if (draftFile === null) {
          // get name from draftFilesURLs
          let url = this.draftFilesURLs[i];
          let filename = this.getFilenameFromURL(url);
          filenames.push(filename);
        } else {
          // get name from draftFile (an unsaved file)
          if (excludeBlobs === false) {
            filenames.push(draftFile.name);
          }
        }
        this.debug(`getCurrentFilenames ${i}. ${filenames[i]}`);
      }
      this.printArray(filenames, "getCurrentFilenames()");

      return filenames;
    },

    getCurrentFilenamesExcludeBlobs() {
      let excludeBlobs = true;
      return this.getCurrentFilenames(excludeBlobs);
    },

    getBlobFiles() {
      let files = [];
      let filesNewIndexes = [];

      for (let i = 0; i < this.currentNumDraftFiles; i++) {
        let draftFile = this.draftFiles[i];
        if (draftFile !== null) {
          files.push(draftFile);
          filesNewIndexes.push(i);
        }
        this.debug(`getBlobFiles ${i}. ${files[i]}`);
      }

      return {
        files: files,
        filesNewIndexes: filesNewIndexes,
      };
    },

    async writeAdTextChanges(currentFilenames) {
      this.message = "";
      this.showErrorMessage = false;

      let record = this.adOrig;
      this.printJson(this.adOrig, "writeAdTextChanges adOrig");
      let changed = false;

      if (isEqual(currentFilenames, this.adOrig.savedFiles) === false) {
        record["savedFiles"] = currentFilenames;
        changed = true;
      }

      if (this.adOrig.title != this.title) {
        record["title"] = this.title;
        changed = true;
      }

      if (this.adOrig.priceOption != this.priceOption) {
        record["priceOption"] = this.priceOption;
        changed = true;
      }

      if (this.adOrig.price != this.price) {
        record["price"] = this.price;
        changed = true;
      }

      let addressObjFinal = {};
      if (this.addressOption == "Show State & Suburb Only") {
        addressObjFinal = {
          state: this.addressObj.state,
          suburb: this.addressObj.suburb,
          postcode: this.addressObj.postcode,
          country: this.addressObj.country,
        };
      } else {
        addressObjFinal = this.addressObj;
      }

      if (this.adOrig.country != this.countryOfAd) {
        record["country"] = this.countryOfAd;
        changed = true;
      }

      if (this.adOrig.currencyCode != this.currencyCode) {
        record["currencyCode"] = this.currencyCode;
        changed = true;
      }

      if (isEqual(this.adOrig.addressObj, addressObjFinal) === false) {
        record["addressObj"] = addressObjFinal;
        changed = true;
      }

      if (this.phone.length > 0 && this.adOrig.phone != this.phone) {
        record["phone"] = this.phone;
        changed = true;
      }

      if (
        "phonePublic" in this.adOrig === false ||
        this.phonePublic != this.adOrig.phonePublic
      ) {
        record["phonePublic"] = this.phonePublic;
        changed = true;
      }

      if (this.adOrig.contactMethod != this.contactMethod) {
        record["contactMethod"] = this.contactMethod;
        changed = true;
      }

      if (this.adOrig.condition != this.condition) {
        record["condition"] = this.condition;
        changed = true;
      }

      if (this.adOrig.description != this.description) {
        record["description"] = this.description;
        changed = true;
      }

      // Jobs
      if (
        this.mainCategory == this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
      ) {
        if (this.adOrig.adType != this.adType) {
          record["adType"] = this.adType;
          changed = true;
        }

        if (this.adOrig.jobType != this.jobType) {
          record["jobType"] = this.jobType;
          changed = true;
        }

        if (this.adOrig.advertisedBy != this.advertisedBy) {
          record["advertisedBy"] = this.advertisedBy;
          changed = true;
        }

        if (this.adOrig.salaryType != this.salaryType) {
          record["salaryType"] = this.salaryType;
          changed = true;
        }

        if (this.adOrig.salary != this.salary) {
          record["salary"] = this.salary;
          changed = true;
        }
      }

      // Mobility Transport
      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT]
      ) {
        if (this.adOrig.make != this.make) {
          record["make"] = this.make;
          changed = true;
        }

        if (this.adOrig.model != this.model) {
          record["model"] = this.model;
          changed = true;
        }

        if (this.adOrig.kilometres != this.kilometres) {
          record["kilometres"] = this.kilometres;
          changed = true;
        }

        if (this.adOrig.colour != this.colour) {
          record["colour"] = this.colour;
          changed = true;
        }

        if (this.adOrig.year != this.year) {
          record["year"] = this.year;
          changed = true;
        }

        if (
          this.subCategory == "Cars" ||
          this.subCategory == "Camper Vans" ||
          this.subCategory == "Boats" ||
          this.subCategory == "Other Vehicles"
        ) {
          if (this.adOrig.horsepower != this.horsepower) {
            record["horsepower"] = this.horsepower;
            changed = true;
          }

          if (this.adOrig.numCylinders != this.numCylinders) {
            record["numCylinders"] = this.numCylinders;
            changed = true;
          }

          if (this.adOrig.engineCapacity != this.engineCapacity) {
            record["engineCapacity"] = this.engineCapacity;
            changed = true;
          }
        }

        if (this.subCategory == "Boats") {
          record["engineHours"] = this.engineHours;
          changed = true;
        }

        if (
          this.subCategory == "Wheelchairs" ||
          this.subCategory == "Tricycles"
        ) {
          if (this.adOrig.vehicleType != this.selectedVehicleType) {
            record["vehicleType"] = this.selectedVehicleType;
            changed = true;
          }
        }
      }

      // Real Estate
      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
      ) {
        if (this.adOrig.forSaleBy != this.forSaleBy) {
          record["forSaleBy"] = this.forSaleBy;
          changed = true;
        }

        if (this.adOrig.startDate != this.startDate) {
          record["startDate"] = this.startDate;
          changed = true;
        }
      }

      // Travel
      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
      ) {
        let wheelchairCompliantBool =
          this.wheelchairCompliant == "Yes" ? true : false;

        if (this.adOrig.wheelchairCompliant != wheelchairCompliantBool) {
          record["wheelchairCompliant"] = wheelchairCompliantBool;
          changed = true;
        }
      }

      if ("rotationAngles" in this.adOrig) {
        if (
          isEqual(this.adOrig.rotationAngles, this.rotationAngles) === false
        ) {
          record["rotationAngles"] = this.rotationAngles;
          changed = true;
          this.debug("adOrig rotationAngles differ");
        } else {
          this.debug("adOrig rotationAngles same what");
        }
      }

      // lastEdited
      record.lastEdited = new Date();
      //record["locale"] = this.selectedLocale;
      this.debug(`writeAdTextChanges() changed = ${changed}`);
      if (changed == true) {
        this.messageLoader += "updating ad...";

        try {
          let result = await this.modifyAd(record);
          this.debug(result, "writeAdTextChanges() result");
          if (result.ok) {
            this.messageLoader += "ok<br/>";
          } else {
            this.messageLoader += result.message + "<br/>";
          }
        } catch (e) {
          this.message = e;
          this.showErrorMessage = true;
          this.debug("writeAdTextChanges failed: " + e);
          this.messageLoader += "failed<br/>";
        }
      }
    },

    async uploadNewFiles(adId) {
      let ok = false;
      let uploadedNewFiles = false;

      let getBlobFilenamesResult = this.getBlobFiles();
      let filesNew = getBlobFilenamesResult.files;
      let filesNewIndexes = getBlobFilenamesResult.filesNewIndexes;

      this.debug("filesNew");
      this.debug(filesNew);
      let uploadFilesResult;

      if (filesNew.length > 0) {
        for (let k = 0; k < filesNew.length; k++) {
          this.messageLoader += `<br/>Uploading ${k}. ${filesNew[k].name}...`;
        }

        uploadFilesResult = await this.uploadFilesForAd(filesNew, adId);
        this.printJson(uploadFilesResult, "uploadFilesResult");
        ok = uploadFilesResult.ok;
        if (uploadFilesResult.ok) {
          this.messageLoader += "<br/>ok<br/>";
        }

        uploadedNewFiles = true;
      }

      let currentFilenames = this.getCurrentFilenames();

      // update savedFilesSizes, currentFilenames

      this.debug("filesNewIndexes");
      this.debug(filesNewIndexes);
      for (let l = 0; l < filesNewIndexes.length; l++) {
        this.debug(`${l} a`);
        let filesNewIndex = filesNewIndexes[l];
        /*
        Vue.set(
          this.savedFilesSizes,
          filesNewIndex,
          uploadFilesResult.savedFilesSizes[l]
        );
        */

        this.debug(`currentFilenames`);
        this.debug(currentFilenames);

        this.debug(`uploadFilesResult`);
        this.debug(uploadFilesResult);
        currentFilenames[filesNewIndex] = uploadFilesResult.savedFiles[l];

        this.debug(`c`);
      }

      if (filesNew.length === 0) {
        ok = true;
      }

      return {
        ok: ok,
        currentFilenames: currentFilenames,
        uploadedNewFiles: uploadedNewFiles,
      };
    },

    async saveChanges() {
      if (await this.isLoggedInOIDC()) {
        let ok = await this.checkInputs();

        if (ok == true) {
          this.showLoading();
          this.messageLoader = "";

          let delResult = await this.deleteRemovedImages(this.adId);
          ok = delResult.ok;
          if (ok === false) {
            this.setAcknowledgeError("Error: Failed to remove image/s.");
          }

          let uploadNewFilesResult = [];
          if (ok === true) {
            uploadNewFilesResult = await this.uploadNewFiles(this.adId);
            ok = uploadNewFilesResult.ok;
            if (ok === false) {
              this.setAcknowledgeError("Error: Failed to upload image/s.");
            }
          }
          this.printJson(uploadNewFilesResult, "uploadNewFilesResult");

          if (ok === true) {
            this.printArray(
              uploadNewFilesResult.currentFilenames,
              "saveChanges() currentFilenames"
            );

            await this.writeAdTextChanges(
              uploadNewFilesResult.currentFilenames
            );

            let adType = Config.AD_TYPES_ENUM.MY_ADS;

            this.hideLoading();
            await this.synchSetTimeout(1);

            this.gotoAdView(this.adId, adType, "promoteAd");
          }

          this.hideLoading();
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async modifyAdOrig() {
      if (await this.isLoggedInOIDC()) {
        let ok = await this.checkInputs();
        let filesAddedOrRemoved = false;

        if (ok == true) {
          this.showLoading();
          this.messageLoader = "";

          let delResult = await this.deleteRemovedImages(this.adId);
          ok = delResult.ok;
          if (ok === false) {
            this.setAcknowledgeError("Error: Failed to remove image/s.");
          } else {
            if (delResult.hasFilesToRemove === true) {
              filesAddedOrRemoved = true;
            }
          }

          let uploadNewFilesResult = [];
          if (ok === true) {
            uploadNewFilesResult = await this.uploadNewFiles(this.adId);
            ok = uploadNewFilesResult.ok;
            if (ok === false) {
              this.setAcknowledgeError("Error: Failed to upload image/s.");
            } else {
              if (uploadNewFilesResult.uploadNewFiles === true) {
                filesAddedOrRemoved = true;
              }
            }
          }
          this.printJson(uploadNewFilesResult, "uploadNewFilesResult");

          if (ok === true) {
            this.printArray(
              uploadNewFilesResult.currentFilenames,
              "modifyAd() currentFilenames"
            );

            await this.writeAdTextChanges(
              uploadNewFilesResult.currentFilenames
            );

            if (filesAddedOrRemoved === true) {
              let updateNumResult = await this.updateNumAdsAndImages();
              this.printJson(updateNumResult, "updateNumResult");
            }

            this.$store.commit("set", ["syncedMyAdIds", false]);
            this.$store.commit("set", ["syncedLiveAdIds", false]);

            let adType = Config.AD_TYPES_ENUM.MY_ADS;

            this.hideLoading();
            await this.synchSetTimeout(1);

            this.gotoAdView(this.adId, adType, "promoteAd");
          }

          this.hideLoading();
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    getUploadBtnColour(index) {
      let colour = "fillColour";
      if (index > this.currentNumDraftFiles) {
        colour = "grey lighten-2";
      }
      return colour;
    },

    getUploadBtnIconColour(index) {
      let colour = "green";
      if (index > this.currentNumDraftFiles) {
        colour = "grey lighten-1";
      }
      return colour;
    },

    getUploadBtnTextColour(index) {
      let colour = "body-1 black--text";
      if (index > this.currentNumDraftFiles) {
        colour = "body-1 grey--text lighten-1";
      }
      return colour;
    },

    getUploadMultBtnColour() {
      let colour = "fillColour";
      if (this.currentNumDraftFiles >= this.maxFiles) {
        colour = "grey lighten-2";
      }
      return colour;
    },

    getUploadMultBtnIconColour() {
      let colour = "green";
      if (this.currentNumDraftFiles >= this.maxFiles) {
        colour = "grey lighten-1";
      }
      return colour;
    },

    getUploadMultBtnTextColour() {
      let colour = "body-1 black--text";
      if (this.currentNumDraftFiles >= this.maxFiles) {
        colour = "body-1 grey--text lighten-1";
      }
      return colour;
    },

    async addMultipleDraftFilesURLs(files) {
      this.showLoading();

      this.debug("addMultipleDraftFilesURLs");
      this.debug(files);

      if (files != null) {
        let numImagesSelected = files.length;

        this.debug("len = " + numImagesSelected);

        let maxImagesRemaining = this.maxFiles - this.currentNumDraftFiles;
        this.debug("maxImagesRemaining = " + maxImagesRemaining);

        let maxImagesToAdd = Math.min(maxImagesRemaining, numImagesSelected);
        this.debug("maxImagesToAdd = " + maxImagesToAdd);

        let draftFilesURLStartIndex = this.currentNumDraftFiles;
        for (let i = 0; i < maxImagesToAdd; i++) {
          let draftFilesURLIndex = draftFilesURLStartIndex + i;

          await this.addDraftFileURL(files[i], draftFilesURLIndex);
        }
      }

      /* force our multi file uplaod button to clear it's files so if user
       * selects the same file/s, it'll still emit the file-update event
       */
      this.uploadMultBtnKey++;

      this.hideLoading();
    },

    showMyAddress() {
      this.myAddressKey++;

      this.isShowMyAddress = true;
      this.debug("showMyAddress " + this.myAddressKey);
    },

    ruleYearLessThanCurrent() {
      return (
        parseFloat(this.year) <= this.currentYear ||
        "Year must be no more than " + this.currentYear
      );
    },

    compressImageAsync(index, quality) {
      return new Promise((resolve, reject) => {
        this.messageLoader +=
          "<br/>Trying " +
          Math.round(quality * 100) +
          "% compression quality...";

        let theFile = this.draftFiles[index];
        this.debug("theFile");
        this.debug(theFile);

        new Compressor(theFile, {
          quality: quality,
          convertSize: Config.MAX_FILE_SIZE_BYTES,
          success(result) {
            this.messageLoader += `${result.size} bytes<br/>`;
            //resolve(this.blobToFile(result, result.name));
            resolve(result);
          },
          error(err) {
            this.debug(err.message);
            return reject(err.message);
          },
        });
      });
    },

    async compressImage(index) {
      this.showYesNoCompress = false;
      this.showYesNoCompressCounter++;
      this.showYesNoCompressKey = `showYesNoCompress${this.showYesNoCompressCounter}`;

      await this.synchSetTimeout(200);
      this.showLoading();

      this.debug(`compressImage() start... isLoading = ${this.isLoading}`);

      this.messageLoader = "";

      try {
        let compressedFile = null;
        let quality = 1.0;
        let failed = false;

        do {
          if (quality > 0.1) {
            quality -= 0.2;
          } else if (quality < 0.1) {
            failed = true;
            break;
          }
          compressedFile = await this.compressImageAsync(index, quality);
          this.debug(`compressedFile size = ${compressedFile.size}`);
        } while (compressedFile.size > Config.MAX_FILE_SIZE_BYTES);

        if (failed == true) {
          this.removePic(index);

          this.setAcknowledgeError(
            "Error: Failed to compress image. It is probably much too large"
          );
        } else {
          this.debug("compressedFile before");
          this.debug(compressedFile);

          if (compressedFile instanceof Blob) {
            this.debug("compressedFile is a Blob found");
            compressedFile = this.blobToFile(
              compressedFile,
              compressedFile.name,
              compressedFile.type
            );
          } else {
            this.debug("compressedFile is not a Blob found");
          }

          this.debug("compressedFile after");
          this.debug(compressedFile);

          Vue.set(this.draftFiles, index, compressedFile);

          let urlObj = URL.createObjectURL(compressedFile);
          Vue.set(this.draftFilesURLs, index, urlObj);

          this.setAcknowledge(
            "Success: image is now " +
              compressedFile.size +
              " bytes at " +
              Math.round(quality * 100) +
              "% quality"
          );
        }
      } catch (e) {
        this.removePic(index);
        this.message = e;
        this.showErrorMessage = true;
        this.debug(e);
      }

      this.debug("compressImage() done");

      this.messageLoader = "";

      this.compressed = true;

      this.hideLoading();
    },

    noCompress(index) {
      this.removePic(index);
      this.showYesNoCompress = false;
      this.showYesNoCompressCounter++;
      this.showYesNoCompressKey = `showYesNoCompress${this.showYesNoCompressCounter}`;

      this.setAcknowledge("It has been removed.", "Image Too Large");
      this.compressed = false;
    },

    updateContactMethod(index) {
      this.contactMethod = this.contactMethods[index];
    },

    closeSelectCategory() {
      this.showCategories = true;
      this.selectCategory = false;
    },

    updateImgSrc(imgSrcUpdated) {
      this.imgSrc = imgSrcUpdated;
    },

    showLoading() {
      this.loadingIndex++;
      this.loadingKey = `loadingKey${this.loadingIndex}`;
      this.isLoading = true;
    },

    hideLoading() {
      this.loadingIndex++;
      this.loadingKey = `loadingKey${this.loadingIndex}`;
      this.isLoading = false;
    },

    closeMyAddressInternational() {
      this.myAddressKey++;
      this.isShowMyAddress = false;
      this.debug("showMyAddress " + this.myAddressKey);
    },

    showCropperMethod() {
      this.showCropper = true;
      this.cropperKey++;
      this.cropperKeyString = `cropper${this.cropperKey}`;
    },

    hideCropper() {
      this.showCropper = false;
      this.cropperKey++;
      this.cropperKeyString = `cropper${this.cropperKey}`;
    },

    showMyPhone() {
      this.myPhoneKey++;
      this.myPhoneKeyString = `myPhone${this.myPhoneKey}`;
      this.isShowMyPhone = true;
    },

    hideMyPhone() {
      this.myPhoneKey++;
      this.myPhoneKeyString = `myPhone${this.myPhoneKey}`;
      this.isShowMyPhone = false;
    },

    /*async goToProfile(hash) {
      await this.$router.push(Config.ROUTE_PROFILE + hash);
    },
    },*/

    /*updateLanguage(index) {
      this.debug("updateLanguage()");
      this.selectedLocale = this.languageISOCodes[index].code;
      this.selectedLanguage = this.languageISOCodes[index].name;
      this.debug(`${this.selectedLanguage} ==> ${this.selectedLocale}`);
      this.showSelectLanguage = false;
    },
    closeSelectLanguage() {
      this.selectedLocale = "en";
      this.selectedLanguage = "English";
      this.showSelectLanguage = false;
      this.debug(`${this.selectedLanguage} ==> ${this.selectedLocale}`);
    },*/

    /*async guessLocaleOfAd() {
      this.localeLoading = true;
      if (this.title.length > 0 || this.description.length > 0) {
        let text = this.title + " " + this.description;
        let res = await this.guessLanguage(text);
        if (res.ok == true) {
          // ok = true;
          this.selectedLanguage = res.languageName;
          this.selectedLocale = res.languageCode;
        } else {
          this.selectedLocale = this.$i18n.locale;

          this.selectedLanguage =
            this.$store.state.languageCodesObject[this.selectedLocale];
        }
        if (
          this.selectedLanguage == undefined ||
          this.selectedLanguage.length == 0
        ) {
          this.selectedLanguage =
            this.$store.state.languageCodesObject[this.selectedLocale];
        }
        //update dropdown to select language
        this.languageNames = [];
        this.languageISOCodes = res.languageISOCodesArray;

        this.debug(`total languages ${this.languageISOCodes.length}`);
        for (let i = 0; i < this.languageISOCodes.length; i++) {
          let langobj = this.languageISOCodes[i];
          this.printJson(langobj, "object" + i);
          this.languageNames.push(langobj.name);
        }
      }
      this.localeLoading = false;
    },*/

    async updateCountryOfAd(index) {
      if (this.selectedCountries.includes(this.countries[index]) == false) {
        this.countryOfAd = this.countries[index];
        this.debug(this.countryOfAd);

        let priceToConvert = 0;
        let doConversion = true;
        let adIsNotJob = true;
        if (
          this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
        ) {
          adIsNotJob = false;
          if (this.jobType != this.VOLUNTEER_STR) {
            if (this.salaryType != this.VOLUNTEER_STR) {
              priceToConvert = this.salary;
            } else {
              doConversion = false;
            }
          } else {
            doConversion = false;
          }
        } else {
          adIsNotJob = true;
          if (this.priceOption != "Free") {
            if (this.price.length > 0) {
              priceToConvert = this.price;
            }
          } else {
            doConversion = false;
          }
        }
        if (doConversion == true) {
          let message =
            "Value of 'price' or 'salary' is not converted due to an error. Please type the value";
          let title = "Currency convertion update";
          this.origValuesWithCurrenciesObject[this.currencyCode] =
            priceToConvert;

          let convertResults = await this.convertPricesForCountries(
            this.currencyCode,
            priceToConvert,
            [this.countryOfAd]
          );

          if (convertResults.ok == true) {
            let result = convertResults.result;
            if (this.countryOfAd in result) {
              message = `Currency and value changed for your ad from ${
                this.currencyCode
              } ${priceToConvert} to ${result[this.countryOfAd].currencyCode} ${
                result[this.countryOfAd].convertedPrice
              }`;
              this.currencyCode = result[this.countryOfAd].currencyCode;
              if (adIsNotJob) {
                this.price = result[this.countryOfAd].convertedPrice;
              } else {
                this.salary = result[this.countryOfAd].convertedPrice;
              }
              if (this.currencyCodes.includes(this.currencyCode) == false) {
                this.currencyCodes.push(this.currencyCode);
              }
              this.origValuesWithCurrenciesObject[this.currencyCode] =
                result[this.countryOfAd].convertedPrice;
            }
          }
          this.setAcknowledge(message, title);
        }
      } else {
        this.setAcknowledgeError(
          "country already selected in multiple country selection"
        );
      }
    },

    async updateCurrencyCodeOfAd(index) {
      this.currencyCode = this.currencyCodes[index];
      if (this.currencyCode in this.origValuesWithCurrenciesObject) {
        if (
          this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
        ) {
          this.salary = this.origValuesWithCurrenciesObject[this.currencyCode];
        } else {
          this.price = this.origValuesWithCurrenciesObject[this.currencyCode];
        }
      }
    },

    //This will check for the country (Changed from original country), images (check for images removed or newly addded) and then create a new ad for the data.
    async saveDuplicateNewAd() {
      if (await this.isLoggedInOIDC()) {
        let ok = await this.checkInputs();
        let ok1 = await this.checkInputsForMultipleCountriesSelection();

        if (ok == true && ok1 == true) {
          if (this.adOrig.country == this.countryOfAd) {
            this.setAcknowledgeError(
              "country should be different from original ad's country"
            );
            ok = false;
          }
          if (ok == true) {
            this.showLoading();
            this.messageLoader = "";

            let record = this.getAdRecordWithCommonFileds();
            /*if (
              "initialAdId" in this.adOrig &&
              this.adOrig.initialAdId.length > 0
            ) {
              record["initialAdId"] = this.adOrig.initialAdId;
            }*/
            record["duplicated"] = true;

            //save ad and get id
            let url = Config.NODE_SERVER_IP + Config.SAVE_AD;

            let headers = {
              Authorization:
                this.accessTokenDecoded.typ +
                " " +
                this.$store.state.tokens.access_token,
              Accept: "application/json",
            };

            let payload = new FormData();

            payload.append("ad", JSON.stringify(record));

            payload.append("duplicate", true);

            let fetchData = {
              method: "POST",
              headers: headers,
              body: payload,
            };

            let adId = "";
            try {
              this.messageLoader += "ok<br/>Uploading ad...";
              let res = await fetch(url, fetchData);
              let jsonRes = await res.json();
              ok = jsonRes.ok;

              if (ok) {
                adId = jsonRes.adId;
              } else {
                this.setAcknowledgeError(jsonRes.message);
              }
            } catch (e) {
              this.debug(e);
              this.setAcknowledgeError(e);
              ok = false;
            }

            if (ok) {
              let res = await this.getAdRecordByAdId(adId);
              record = res.ad;
              if (
                "savedFiles" in this.adOrig &&
                this.adOrig.savedFiles.length > 0
              ) {
                record["savedFiles"] = this.adOrig.savedFiles;
              }

              //let adType = Config.AD_TYPES_ENUM.MY_ADS;
              let delResult = await this.deleteRemovedImages(adId);
              ok = delResult.ok;
              if (ok === false) {
                this.setAcknowledgeError("Error: Failed to remove image/s.");
              }

              let uploadNewFilesResult = [];
              if (ok === true) {
                uploadNewFilesResult = await this.uploadNewFiles(adId);
                ok = uploadNewFilesResult.ok;
                if (ok === false) {
                  this.setAcknowledgeError("Error: Failed to upload image/s.");
                }
              }
              this.printJson(uploadNewFilesResult, "uploadNewFilesResult");

              if (ok === true) {
                this.printArray(
                  uploadNewFilesResult.currentFilenames,
                  "saveChanges() currentFilenames"
                );

                record.lastEdited = new Date();

                if (
                  isEqual(
                    uploadNewFilesResult.currentFilenames,
                    this.adOrig.savedFiles
                  ) === false
                ) {
                  record["savedFiles"] = uploadNewFilesResult.currentFilenames;
                }
                //  if (changed == true) {
                this.messageLoader += "updating ad...";

                try {
                  let result = await this.modifyAd(record);
                  this.debug(result, "writeAdTextChanges() result");
                  if (result.ok) {
                    this.messageLoader += "ok<br/>";
                  } else {
                    this.messageLoader += result.message + "<br/>";
                  }
                } catch (e) {
                  this.message = e;
                  this.showErrorMessage = true;
                  this.debug("writeAdTextChanges failed: " + e);
                  this.messageLoader += "failed<br/>";
                }
                //   }

                //check for multiple countries and post them
                let savedFilesToAdd = [];
                if ("savedFiles" in record && record.savedFiles != undefined) {
                  savedFilesToAdd = record.savedFiles;
                }
                await this.checkAndSaveMultipleAds(adId, savedFilesToAdd);

                //this.hideLoading();
                //await this.synchSetTimeout(1);

                //this.gotoAdView(adId, adType, "promoteAd");
              }
            }
          }
        } else {
          this.$router.push(Config.ROUTE_LOGIN);
        }
      }
    },

    getAdRecordWithCommonFileds() {
      let finalAddressObj = this.addressObj;
      if (this.addressOption == "Show State & Suburb Only") {
        finalAddressObj = {
          suburb: this.addressObj.suburb,
          state: this.addressObj.state,
          postcode: this.addressObj.postcode,
          country: this.addressObj.country,
        };
      }

      // TODO: add timezone support

      let record = {
        mainCategory: this.mainCategory,
        title: this.title,
        ownerGivenName: this.accessTokenDecoded.given_name,
        country: this.countryOfAd,
      };

      if (this.$store.state.merchant !== "") {
        record["merchant"] = this.$store.state.merchant;
      }

      if (
        this.mainCategory !=
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.MISCELLANEOUS]
      ) {
        record["subCategory"] = this.subCategory;
      }

      if (
        this.mainCategory == this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
      ) {
        record["adType"] = this.adType;
        record["jobType"] = this.jobType;
        record["advertisedBy"] = this.advertisedBy;

        if (this.jobType != this.VOLUNTEER_STR) {
          record["salaryType"] = this.salaryType;
          if (this.salaryType != this.VOLUNTEER_STR) {
            record["salary"] = this.salary;
          }
        }
      } else {
        // not Job
        record["priceOption"] = this.priceOption;
        record["price"] = this.price;
      }

      if (
        this.mainCategory !=
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS] &&
        this.mainCategory !=
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.SERVICES] &&
        this.mainCategory !=
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
      ) {
        record["condition"] = this.condition;
      }

      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.MOBILITY_TRANSPORT]
      ) {
        record["make"] = this.make;
        record["model"] = this.model;

        if (this.subCategory == "Boats") {
          record["engineHours"] = this.engineHours;
        } else {
          record["kilometres"] = this.kilometres;
        }

        record["colour"] = this.colour;
        record["year"] = this.year;

        if (this.horsepower != "") {
          record["horsepower"] = this.horsepower;
        }

        if (this.numCylinders != "") {
          record["numCylinders"] = this.numCylinders;
        }

        if (this.engineCapacity != "") {
          record["engineCapacity"] = this.engineCapacity;
        }

        if (this.selectedVehicleType != "") {
          record["vehicleType"] = this.selectedVehicleType;
        }
      }

      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.REAL_ESTATE]
      ) {
        if (this.subCategory != "Wanted") {
          record["forSaleBy"] = this.forSaleBy;
        }

        record["startDate"] = this.startDate;
      }

      if (
        this.mainCategory ==
        this.mainCategories[this.MAIN_CATEGORIES_ENUM.TRAVEL]
      ) {
        if (this.wheelchairCompliant == "No") {
          record["wheelchairCompliant"] = false;
        } else {
          record["wheelchairCompliant"] = true;
        }
      }

      record["addressObj"] = finalAddressObj;

      if (this.adHasPhone == true) {
        if (this.phoneOption == this.phoneOptions[0]) {
          this.phonePublic = true;
        }
        this.debug(`PhonePublic Status ${this.phonePublic}`);
        record["phone"] = this.phone;
        record["phonePublic"] = this.phonePublic;
      }

      record["contactMethod"] = this.contactMethod;

      record["description"] = this.description;
      record["published"] = true;

      record["currencyCode"] = this.currencyCode;

      return record;
    },

    showMultipleCountriesSelect() {
      let ok = false;
      let message = "";
      if (
        this.mainCategory == this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
      ) {
        ok = true;
        if (this.jobType != this.VOLUNTEER_STR) {
          if (this.salaryType != this.VOLUNTEER_STR) {
            if (this.salary.length > 0) {
              ok = true;
            } else {
              ok = false;
            }
          }
        } else {
          this.showAmountTextFieldForMultipleCountries = false;
        }
        message = "Please type the salary value";
      } else {
        if (this.price.length > 0) {
          ok = true;
        }
        message = "Please type the price value";
      }
      if (ok == true) {
        this.selectMultipleCountryIndex++;
        this.selectMultipleCountryKey = `selectMultiple${this.selectMultipleCountryIndex}`;
        this.disabledCountries = [this.countryOfAd];
        if (
          this.duplicateAd == true &&
          this.disabledCountries.includes(this.adOrig.country) == false
        ) {
          this.disabledCountries.push(this.adOrig.country);
        }
        this.isShowSelectMultipleCountries = true;
      } else {
        this.setAcknowledgeError(message);
      }
    },

    async countriesSelected(countries) {
      let ok = true;
      this.showAmountTextFieldForMultipleCountries = true;

      this.selectedCountries = countries;
      this.printJson(this.selectedCountries, "selected countries ");
      let countriesToConvertPrice = [];

      for (let i = 0; i < this.selectedCountries.length; i++) {
        let country = this.selectedCountries[i];
        if (country in this.convertedPriceForCountries == false) {
          countriesToConvertPrice.push(country);
        }
      }

      if (countriesToConvertPrice.length > 0) {
        this.showLoading();
        this.messageLoader = "Converting prices for other available currencies";
        let priceToConvert = 0;
        if (
          this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
        ) {
          if (this.jobType != this.VOLUNTEER_STR) {
            if (this.salaryType != this.VOLUNTEER_STR) {
              priceToConvert = this.salary;
            } else {
              this.showAmountTextFieldForMultipleCountries = false;
            }
          } else {
            this.showAmountTextFieldForMultipleCountries = false;
          }
        } else {
          if (this.priceOption != "Free") {
            if (this.price.length > 0) {
              priceToConvert = this.price;
            }
          } else {
            this.showAmountTextFieldForMultipleCountries = false;
          }
        }
        this.baseCurrencyConverted = this.currencyCode;
        this.amountConverted = priceToConvert;
        let convertResults = await this.convertPricesForCountries(
          this.currencyCode,
          priceToConvert,
          countriesToConvertPrice
        );
        if (convertResults.ok == true) {
          ok = true;
          let result = convertResults.result;
          for (let country in result) {
            this.convertedPriceForCountries[country] = result[country];
          }
          let errorMessage = "";
          let showMissingError = false;
          for (let i = 0; i < this.selectedCountries.length; i++) {
            let country = this.selectedCountries[i];
            if (country in this.convertedPriceForCountries == false) {
              showMissingError = true;
              this.selectedCountries.splice(i, 1);
              errorMessage += `- ${country} </br>`;
            }
          }
          if (showMissingError) {
            errorMessage += `data missing for above countries. removed from selected list`;
            this.setAcknowledgeError(errorMessage);
            ok = false;
          }
        } else {
          this.setAcknowledgeError(convertResults.message);
          ok = false;
        }
      }
      this.hideLoading();
      return ok;
    },

    async checkInputsForMultipleCountriesSelection() {
      let ok = true;
      //check for converted price
      if (this.showAmountTextFieldForMultipleCountries == true) {
        let priceToCheck = 0;
        if (
          this.mainCategory ==
          this.mainCategories[this.MAIN_CATEGORIES_ENUM.JOBS]
        ) {
          priceToCheck = this.salary;
        } else {
          priceToCheck = this.price;
        }

        this.debug(
          this.currencyCode +
            "" +
            this.baseCurrencyConverted +
            "" +
            +"" +
            parseFloat(priceToCheck) +
            "" +
            parseFloat(this.amountConverted)
        );
        this.debug(
          this.currencyCode != this.baseCurrencyConverted ||
            parseFloat(priceToCheck) != parseFloat(this.amountConverted)
        );
        this.debug("checking for currency change or value chaneg");
        if (
          this.currencyCode != this.baseCurrencyConverted ||
          parseFloat(priceToCheck) != parseFloat(this.amountConverted)
        ) {
          this.convertedPriceForCountries = {};
          ok = await this.countriesSelected(this.selectedCountries);
        }
      }

      //check for empty price/salary converted textfields
      if (ok == true) {
        for (let i = 0; i < this.selectedCountries.length; i++) {
          let country = this.selectedCountries[i];
          let countryObj = this.convertedPriceForCountries[country];
          if (
            countryObj.convertedPrice == undefined ||
            countryObj.convertedPrice.length == 0
          ) {
            ok = false;
            this.setAcknowledgeError(
              "Please check the converted price and fill them for posting in multiple countries"
            );
            break;
          } else if (parseFloat(countryObj.convertedPrice) < 0) {
            ok = false;
            this.setAcknowledgeError(
              "Please make the values as positive for posting in multiple countries"
            );
            break;
          }

          if (
            "title" in countryObj &&
            countryObj.title.length > Config.TITLE_MAX
          ) {
            ok = false;
            this.setAcknowledgeError(
              "Ad title exceeded the character limit for country - " +
                countryObj.country
            );
            break;
          }

          if (
            "description" in countryObj &&
            countryObj.description.length > Config.AD_DESC_MAX
          ) {
            ok = false;
            this.setAcknowledgeError(
              "Ad description exceeded the character limit for country - " +
                countryObj.country
            );
            break;
          }
        }
      }

      return ok;
    },

    removeCountry(country) {
      for (let i = 0; i < this.selectedCountries.length; i++) {
        if (this.selectedCountries[i] == country) {
          this.selectedCountries.splice(i, 1);
          break;
        }
      }
      delete this.convertedPriceForCountries[country];
    },

    async checkAndSaveMultipleAds(adId, savedFiles) {
      let ok = false;
      if (await this.isLoggedInOIDC()) {
        let numOfMultipleCountriesSelected = Object.keys(
          this.convertedPriceForCountries
        );
        if (numOfMultipleCountriesSelected.length > 0) {
          let record = this.getAdRecordWithCommonFileds();

          record["duplicated"] = true;
          record["savedFiles"] = savedFiles;
          let adsRecord = {};
          //create the das object fir batch write
          this.messageLoader += `Creating ads for </br>`;
          for (let i = 0; i < numOfMultipleCountriesSelected.length; i++) {
            let country = numOfMultipleCountriesSelected[i];
            let countryObj = this.convertedPriceForCountries[country];
            let modifiedRecord = Object.assign({}, record);

            modifiedRecord["currencyCode"] = countryObj.currencyCode;
            modifiedRecord["country"] = country;
            if ("price" in record) {
              modifiedRecord["price"] = countryObj.convertedPrice;
            } else if ("salary" in record) {
              modifiedRecord["salary"] = countryObj.convertedPrice;
            }

            if ("title" in countryObj && countryObj.title.length > 0) {
              modifiedRecord["title"] = countryObj.title;
            }

            if (
              "description" in countryObj &&
              countryObj.description.length > 0
            ) {
              modifiedRecord["description"] = countryObj.description;
            }
            adsRecord[country] = Object.assign({}, modifiedRecord);

            this.messageLoader += `${i + 1} - ${country} </br>`;
          }
          this.printJson(adsRecord, "adsRecord");

          //save ad and get id
          let url = Config.NODE_SERVER_IP + Config.SAVE_AD;

          let headers = {
            Authorization:
              this.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
            Accept: "application/json",
          };

          let payload = new FormData();

          payload.append("adsRecord", JSON.stringify(adsRecord));

          //payload.append("duplicate", true);

          payload.append("batch", true);

          let fetchData = {
            method: "POST",
            headers: headers,
            body: payload,
          };

          //let adId = "";
          try {
            this.messageLoader += "ok<br/>Uploading ads...";
            let res = await fetch(url, fetchData);
            let jsonRes = await res.json();
            ok = jsonRes.ok;
            let savedAds = jsonRes.savedAds;

            if (ok) {
              this.hideLoading();
              //show ads saved message with listof saved ads and if there any ads missed from saving
              let title = `${savedAds.length + 1} ads saved successfully`;
              let successMessage = `1. ${this.countryOfAd} saved <br>`;
              let countries = Object.keys(adsRecord);
              let failedCountries = [];
              this.printJson(countries, "passed countries");
              for (let i = 0; i < savedAds.length; i++) {
                let savedAd = savedAds[i];
                if (countries.includes(savedAd.country) == false) {
                  successMessage += `${i + 2}. ${savedAd.country} failed <br>`;
                  failedCountries.push(savedAd.country);
                } else {
                  successMessage += `${i + 2}. ${savedAd.country} saved <br>`;
                }
              }
              if (failedCountries.length > 0) {
                successMessage += `${failedCountries.length} ads saved failed for countries -  ${failedCountries} <br>`;
              }
              this.setAcknowledge(successMessage, title);

              //redirect to my ads page
              while (this.showAcknowledge === true) {
                await this.synchSetTimeout(200);
                this.debug(".");
              }
              this.$router.push(Config.ROUTE_MY_ADS);
            } else {
              this.setAcknowledgeError(jsonRes.message);
            }
          } catch (e) {
            this.debug(e);
            this.setAcknowledgeError(e);
            ok = false;
          }
        } else {
          await this.synchSetTimeout(1);
          let adType = Config.AD_TYPES_ENUM.MY_ADS;
          this.gotoAdView(adId, adType, "promoteAd");
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    showTranslateOptionMenu() {
      this.showYesNoCheckTranslateIndex++;
      this.showYesNoCheckTranslateKey = `showTranslate${this.showYesNoCheckTranslateIndex}`;
      this.showYesNoCheckTranslate = true;
    },

    closeTranslateOptionsMenu() {
      this.showYesNoCheckTranslateIndex++;
      this.showYesNoCheckTranslateKey = `showTranslate${this.showYesNoCheckTranslateIndex}`;
      this.showYesNoCheckTranslate = false;
    },

    async loadTranslationsAvailability() {
      if (this.title.length > 0 && this.description.length > 0) {
        this.closeTranslateOptionsMenu();
        this.showLoading();
        let text = `${this.title} ${this.description}`;
        let countries = Object.keys(this.convertedPriceForCountries);
        let result = await this.checkForTranlationAvailability(text, countries);
        if (result.ok == true) {
          this.localeOfText = result.localeOfText;
          this.localeCodeOfText = result.localeCodeOfText;
          let localesInfo = result.localesInfo;
          let numOfMultipleCountriesSelected = Object.keys(
            this.convertedPriceForCountries
          );
          for (let i = 0; i < numOfMultipleCountriesSelected.length; i++) {
            let country = numOfMultipleCountriesSelected[i];
            this.convertedPriceForCountries[country]["manualTranslate"] = false;
            this.convertedPriceForCountries[country][
              "translateSupported"
            ] = false;
            this.convertedPriceForCountries[country]["showTextFields"] = false;
            this.convertedPriceForCountries[country]["title"] = "";
            this.convertedPriceForCountries[country]["description"] = "";
            this.printJson(localesInfo, "convertedPriceForCountries  0");
            if (country in localesInfo) {
              let localeInfoObject = localesInfo[country];
              if (
                "code" in localeInfoObject &&
                localeInfoObject.code.length > 0
              ) {
                this.convertedPriceForCountries[country]["languageCode"] =
                  localeInfoObject.code;
              }

              if (
                "name" in localeInfoObject &&
                localeInfoObject.name.length > 0
              ) {
                this.convertedPriceForCountries[country]["languageName"] =
                  localeInfoObject.name;
              }

              if (
                "translateSupported" in localeInfoObject &&
                localeInfoObject.translateSupported == true
              ) {
                this.convertedPriceForCountries[country]["translateSupported"] =
                  localeInfoObject.translateSupported;
              } else {
                this.convertedPriceForCountries[country][
                  "manualTranslate"
                ] = true;
              }
            }
          }

          this.printJson(
            this.convertedPriceForCountries,
            "convertedPriceForCountries"
          );
          this.showTranslateTextFields = true;
        }
        this.hideLoading();
      } else {
        this.setAcknowledgeError("Please type title and description of the ad");
      }
    },

    showTextFieldsToAddTranslate(country) {
      this.convertedPriceForCountries[country]["showTextFields"] = true;
      this.multipleAdsSectionIndex++;
      this.multipleAdsSectionKey = `multipleAdsSection0${this.multipleAdsSectionIndex}`;
    },

    async translateAndShowTextFields(country) {
      //call backend for translation
      let jsonToTranslate = {
        title: this.title,
        description: this.description,
      };
      let result = await this.translateJsonWithSeparator(
        this.localeCodeOfText,
        this.convertedPriceForCountries[country].languageCode,
        jsonToTranslate
      );
      if (result.ok == true) {
        let translatedJson = result.translatedJson;
        if ("title" in translatedJson && translatedJson.title.length > 0) {
          this.convertedPriceForCountries[country]["title"] =
            translatedJson.title;
        }

        if (
          "description" in translatedJson &&
          translatedJson.description.length > 0
        ) {
          this.convertedPriceForCountries[country]["description"] =
            translatedJson.description;
        }
      } else {
        this.setAcknowledgeError("Please add translations by typing");
      }
      this.convertedPriceForCountries[country]["showTextFields"] = true;
      this.multipleAdsSectionIndex++;
      this.multipleAdsSectionKey = `multipleAdsSection0${this.multipleAdsSectionIndex}`;
    },

    changeTitleInConvertedObject(country, change) {
      if (change.length > 0) {
        this.convertedPriceForCountries[country]["title"] = change;
      } else {
        this.convertedPriceForCountries[country]["title"] = "";
      }

      this.printJson(
        this.convertedPriceForCountries,
        "convertedPriceForCountries"
      );
    },

    changeDescriptionInConvertedObject(country, change) {
      if (change.length > 0) {
        this.convertedPriceForCountries[country]["description"] = change;
      } else {
        this.convertedPriceForCountries[country]["description"] = "";
      }

      this.printJson(
        this.convertedPriceForCountries,
        "convertedPriceForCountries"
      );
    },

    showAlertOnAdsLimitReached() {
      this.debug("showAlertOnAdsLimitReached()");
      this.showSnackbar = true;
      this.snackbarMessage =
        "Max Ads limit reached, Clicking <b>PURCHASE ADS</b> button will leave this page";
      /* this.acknowledgeOk = true;
      this.showAcknowledge = true;
      this.acknowledgeTitle = "Max Ads limit reached";
      this.acknowledgeMessage =
        "Purchase more ads using fiat or crypto will be available soon...";
      this.showActionBtn = true;
      this.actionName = "Purchase Ads";
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;*/
    },

    /*showPurchaseAdsScreen() {
      this.showSnackbar = false;
      this.isShowSelectMultipleCountries = false;
      this.selectMultipleCountryIndex++;
      this.selectMultipleCountryKey = `selectMultiple${this.selectMultipleCountryIndex}`;

      this.showPurchaseAdsIndex++;
      this.showPurchaseAdsKey = `showPurchase${this.selectMultipleCountryIndex}`;

      this.isShowPurchaseAds = true;
      //
    },*/

    async goToPurchaseAds() {
      const answer = window.confirm(
        "Do you really want to leave? you may have unsaved changes!"
      );
      if (answer) {
        await this.pushRoute(Config.ROUTE_PURCHASE_ADS);
      }
    },
  }, // methods
};
</script>
