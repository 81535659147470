<template>
  <v-app v-if="isGlobalLoading">
    <v-container fill-height fluid class="fillColour">
      <v-row justify="center" align="center">
        <v-progress-circular
          v-if="isTranslationsLoading"
          display="block"
          color="green"
          indeterminate
        ></v-progress-circular>

        <v-card v-else elevation="0" class="fillColour">
          <br />
          <v-row justify="center">
            <v-img :src="logo" alt="MobilityGuru Logo" max-width="200"></v-img>
          </v-row>
          <br />

          <h1 class="text-center brown--text">
            {{ $t(`splash_text1`) }}
          </h1>
          <br />
          <h2 class="text-center brown--text">
            {{ $t(`splash_text2`) }}
          </h2>
          <br />
          <br />
          <p class="text-center brown--text">
            {{ $t(`splash_text3`) }}
          </p>
          <br />
          <v-row justify="center" align="center">
            <v-progress-circular
              display="block"
              color="green"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
  <v-app v-else :key="appKey" :class="notranslateClass">
    <v-app-bar dense v-show="this.$route.path !== '/'" app color="white">
      <v-col class="pa-0">
        <v-row no-gutters class="mt-4">
          <v-col
            class="pa-0"
            style="text-align: end"
            :cols="$vuetify.breakpoint.xs ? 8 : $vuetify.breakpoint.sm ? 6 : 4"
            :class="showBonusBadge ? `pr-2` : `pr-0`"
          >
            <v-card
              class=""
              height="55"
              color="white"
              background-color="blue"
              elevation="0"
            >
              <v-text-field
                :label="$t('search')"
                class="mr-2"
                v-model="search"
                color="#612E11"
                dense
                outlined
                v-on:keyup.enter="routerModify('search', search)"
                background-color="white"
              >
                <!--  <template v-slot:prepend>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="green" @click="drawer = !drawer">{{
                      mdiFilter
                    }}</v-icon>
                  </template>
                  Ad Filters
                </v-tooltip>
              </template>$t(`message.search`){{ $t(`message.search_ads`) }}-->
                <template v-slot:append-outer>
                  <MyBtn
                    btnName="search"
                    :iconName="mdiMagnify"
                    @btn-clicked="routerModify('search', search)"
                    class="mt-n2 hidden-sm-and-down"
                    :lightBorder="true"
                  ></MyBtn>

                  <!--- small search icon, no label -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="routerModify('search', search)"
                        color="fillColour"
                        small
                        justify="center"
                        class="mt-n2 hidden-md-and-up"
                        v-on="on"
                        height="38px"
                      >
                        <v-icon color="green">{{ mdiMagnify }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t(`search`) }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-card>
          </v-col>
          <!--
          <v-card
            width="650"
            height="50"
            color="fillColour"
            background-color="blue"
            elevation="0"
            v-show="$vuetify.breakpoint.xs === true"
          >
            <v-text-field
              label="Search for2"
              class="mt-2 mr-8"
              v-model="search"
              color="#612E11"
              dense
              outlined
              v-on:keyup.enter="routerModify('search', search)"
              background-color="white"
            >
              <template v-slot:append-outer>
                <MyBtn
                  btnName="Search Ads2"
                  :iconName="mdiMagnify"
                  @btn-clicked="routerModify('search', search)"
                  class="mt-n2 hidden-md-and-down"
                ></MyBtn>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="routerModify('search', search)"
                      color="fillColour"
                      small
                      justify="center"
                      class="mt-n2 hidden-md-and-down"
                      v-on="on"
                      height="38px"
                    >
                      <v-icon color="green">{{ mdiMagnify }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Search for</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card>
          -->
          <!-- <v-img
            :src="logoTextRight"
            alt="MobilityGuru Logo"
            max-width="200"
            height="60"
            class="mr-10 hidden-sm-and-down"
          ></v-img>
          <v-img
            :src="logoWord"
            alt="MobilityGuru Logo"
            max-width="75"
            max-height="90"
            class="mt-n3 hidden-md-and-up"
          ></v-img>$t(`message.clear_filters`)-->
          <!--Search Ads button turns into Clear Filter when in the live-ads path -->
          <MyBtn
            v-if="showClearFilters"
            btnName="clearFilter"
            :iconName="mdiFilterRemoveOutline"
            @btn-clicked="clearFilter()"
            class="ml-2 mr-2 hidden-sm-and-down"
            :lightBorder="true"
          ></MyBtn>
          <!-- <MyBtn
            v-if="
              this.$route.path !== this.ROUTE_LIVE_ADS &&
                this.$route.path !== this.ROUTE_MY_ADS
            "
            btnName="Search Ads"
            :iconName="mdiDatabaseSearch"
            @btn-clicked="goToAdSearch()"
            class="mt-1 mr-2 hidden-sm-and-down"
          ></MyBtn>$t(`message.public_ads`)-->
          <MyBtn
            v-if="
              this.$route.path == this.ROUTE_MY_ADS ||
              this.$route.query.myAds === true
            "
            btnName="publicAds"
            :iconName="mdiDatabaseSearch"
            @btn-clicked="goToRoute(ROUTE_LIVE_ADS)"
            class="mr-2 hidden-sm-and-down"
            :lightBorder="true"
          ></MyBtn>
          <v-spacer></v-spacer>
          <v-col
            style="text-align: end"
            :cols="$vuetify.breakpoint.xs ? 4 : $vuetify.breakpoint.sm ? 5 : 2"
            :class="showBonusBadge ? `pr-2` : `pr-0`"
          >
            <!--<v-badge
              v-show="!$vuetify.breakpoint.xs"
              style="z-index: 1"
              transition="slide-x-transition"
              :color="
                $store.state.unreadMessages != `0` ? `green` : `transparent`
              "
              :content="
                $store.state.unreadMessages != `0`
                  ? $store.state.unreadMessages
                  : ''
              "
              overlap
              offset-y="-5"
            >
            </v-badge>-->
            <v-badge
              transition="slide-x-transition"
              :color="
                $store.state.unreadMessages != `0` ? `green` : `transparent`
              "
              :content="
                $store.state.unreadMessages != `0`
                  ? $store.state.unreadMessages
                  : ''
              "
              left
              offset-x="15"
              offset-y="18"
              overlap
            >
              <MyBtn
                :btnName="
                  this.$store.state.isLoggedIn
                    ? this.$store.state.accessTokenDecoded.given_name
                    : 'menu'
                "
                :iconName="mdiMenuOpen"
                @btn-clicked="isShowMenu = true"
                class=""
                :lightBorder="true"
              ></MyBtn>

              <template v-slot:badge>
                <!-- :color="showBonusBadge ? `brown` : `transparent`"
              :bordered="showBonusBadge"
              v-if="showBonusBadge"  <span>{{ $store.state.bonus }}</span>-->
              </template>
            </v-badge>

            <!-- hidden-md-and-down <SelectLanguage :key="langKey"> hidden-sm-and-down</SelectLanguage>
            <MyBtn
              btnName="toggle no translate "
              :iconName="mdiDatabaseSearch"
              @btn-clicked="toggleNoTranslate()"
              class="notranslate"
            ></MyBtn>-->
          </v-col>

          <!--
          <MyBtn
            :btnName="
              this.$store.state.isLoggedIn
                ? this.$store.state.accessTokenDecoded.given_name
                : 'Menu'
            "
            :iconName="mdiMenuOpen"
            @btn-clicked="isShowMenu = true"
            class="mt-2"
            v-show="$vuetify.breakpoint.xs != true"
          ></MyBtn>
          <v-badge
            bordered
            v-if="showBonusBadge"
            v-show="$vuetify.breakpoint.xs != true"
            right
            color="brown"
            offset-x="15"
            offset-y="25"
          >
            <template v-slot:badge>
              <span>{{ $store.state.bonus }}</span>
            </template>
          </v-badge>
          -->
        </v-row>

        <!-- <v-row no-gutters class="mt-1">
          <v-col
            v-show="!$vuetify.breakpoint.xs"
            :cols="$vuetify.breakpoint.xs ? 6 : $vuetify.breakpoint.sm ? 3 : 2"
          >
            <MyBtn
              :btnName="buyGURUText"
              @btn-clicked="buyGuru()"
              class="mt-n3"
              :lightBorder="true"
            ></MyBtn>
          </v-col>

          <v-col
            :cols="
              $vuetify.breakpoint.xs ? 12 : $vuetify.breakpoint.sm ? 9 : 10
            "
          >
            <v-row>
              <IconsMenu></IconsMenu>
            </v-row>
          </v-col>-->

        <!--  <v-spacer></v-spacer>

          <v-col
            :cols="$vuetify.breakpoint.xs ? 4 : $vuetify.breakpoint.sm ? 2 : 2"
            style="text-align: right"
            :class="showBonusBadge ? `pr-2` : `pr-0`"
          >
            <v-badge
              :bordered="showBonusBadge"
              right
              :color="showBonusBadge ? `brown` : `transparent`"
              offset-x="15"
              offset-y="1"
            >
              <MyBtn
                :btnName="
                  this.$store.state.isLoggedIn
                    ? this.$store.state.accessTokenDecoded.given_name
                    : 'Menu'
                "
                :iconName="mdiMenuOpen"
                @btn-clicked="isShowMenu = true"
                class="mt-n3"
                :lightBorder="true"
              ></MyBtn>

              <template v-slot:badge v-if="showBonusBadge">
                <span>{{ $store.state.bonus }}</span>
              </template>
            </v-badge>
          </v-col>-->

        <!--<v-col
            cols="1"
            v-if="
              this.$vuetify.breakpoint.name === 'xs' ||
              this.$vuetify.breakpoint.name === 'sm' ||
              this.$vuetify.breakpoint.name === 'md'
            "
          >
          </v-col>

          <v-col cols="2" v-if="this.$vuetify.breakpoint.name === 'xs'"></v-col>-->
        <!--
             offset-x="15"
              offset-y="12"
          <v-col cols="1" lg="1" md="1" :sm="3" xs="4"></v-col>
          -->
        <!--</v-row>-->
      </v-col>
      <template v-slot:extension>
        <v-col class="pa-0" v-show="!$vuetify.breakpoint.xs" :cols="3">
          <MyBtn
            :btnName="buyGURUText"
            @btn-clicked="buyGuru()"
            class="mt-n3"
            :lightBorder="true"
          ></MyBtn>
        </v-col>

        <!--Slider icons Menu with labels -->
        <v-col
          class="pa-0"
          :cols="$vuetify.breakpoint.xs ? 12 : $vuetify.breakpoint.sm ? 9 : 9"
        >
          <IconsMenu></IconsMenu>
        </v-col>
      </template>
    </v-app-bar>

    <v-main class="back">
      <!--<br /><v-banner
        v-if="$route.path !== ROUTE_AIRDROP_FORM"
        v-show="$store.state.deliveryServicesAvailable == false"
        color="yellow"
        :single-line="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      >
        <v-avatar slot="icon" color="fillColour accent-4" size="40">
          <v-img height="40" width="40" contain :src="tokenLogo"></v-img>
        </v-avatar>
        <span class="font-weight-bold text-center"
          >MobilityGuru AirDrop 🎁, Fill the form to recieve GURU tokens
        </span>
        <template v-slot:actions>
          <v-btn class="" color="pink" text @click="">
            Apply now</v-btn
          >
        </template>
      </v-banner>-->

      <v-row no-gutters v-if="IS_DEV" class="red text-center">
        <v-spacer></v-spacer>
        <div class="yellow">Dev Mode</div>
        <v-spacer></v-spacer>
      </v-row>

      <router-view class="back"> </router-view>

      <br />
      <v-row no-gutters v-if="IS_DEV" class="red text-center">
        <v-spacer></v-spacer>
        <div class="yellow">Dev Mode</div>
        <v-spacer></v-spacer>
      </v-row>
    </v-main>
    <br />

    <!-- Footer of the App -->

    <v-footer
      inset
      v-show="this.$route.path !== '/'"
      color="fillColour"
      :padless="true"
    >
      <v-container color="fillColour" class="spacing-playground py-0 pb-10">
        <br />
        <v-row no-gutters justify-sm="center">
          <v-card class="pa-2" color="fillColour" elevation="0">
            <v-row justify="center">
              <v-btn
                height="20"
                class="text-capitalize"
                text
                @click.stop="goToAbout()"
                >{{ $t(`about`) }}</v-btn
              >
              <!--{{ $t(`message.about`) }}<MeSpeak text="about"></MeSpeak>-->
            </v-row>

            <v-row justify="center">
              <v-btn
                height="20"
                class="text-capitalize"
                text
                @click.stop="goToCareers()"
                >{{ $t(`careers`) }}</v-btn
              >
              <!--{{ $t(`message.careers`) }}<MeSpeak text="Careers"></MeSpeak>-->
            </v-row>

            <v-row justify="center">
              <v-btn
                height="20"
                class="text-capitalize"
                text
                @click="goToTerms"
                >{{ $t(`terms`) }}</v-btn
              >
              <!--{{ $t(`message.terms&conditions`) }}<MeSpeak text="Terms & Conditions"></MeSpeak>-->
            </v-row>
            <v-row justify="center">
              <v-btn
                height="20"
                class="text-capitalize"
                text
                @click.stop="goToPrivacy"
                >{{ $t(`privacy`) }}</v-btn
              >
              <!--{{ $t(`message.privacy_policy`) }}<MeSpeak text="Privacy policy"></MeSpeak>-->
            </v-row>
            <!--
            <v-row>
              <v-btn
                height="20"
                class="text-capitalize"
                text
                @click.stop="goToRaffle"
                >Raffle</v-btn
              ><MeSpeak text="Raffle"></MeSpeak>
            </v-row>
            -->
            <v-row justify="center">
              <v-btn
                text
                class="text-capitalize"
                height="20"
                @click.stop="goToFaq"
                >{{ $t(`faq`) }}</v-btn
              >
            </v-row>
            <v-row justify="center">
              <v-btn
                text
                class="text-capitalize"
                height="20"
                @click.stop="pushRoute(ROUTE_TOKEN_GUIDE, {})"
                >{{ $t(`addGuru`) }}</v-btn
              >
            </v-row>
            <!--{{ $t(`message.guru_token_guide`) }}{{ $t(`message.mobilityguru_airdrop`) }}-->
            <!-- <v-row justify="center">
              <v-btn
                text
                class="text-capitalize"
                height="20"
                @click.stop="pushRoute(ROUTE_AIRDROP_FORM, {})"
              >
                {{ $t(`airdrop`) }}</v-btn
              >
            </v-row>-->
            <v-row justify="center">
              <v-btn
                text
                class="text-capitalize"
                height="20"
                @click.stop="pushRoute(ROUTE_THIRD_PARTY_LOGIN_GUIDE, {})"
                >{{ $t(`third`) }}</v-btn
              >
            </v-row>
            <v-row justify="center">
              <v-btn
                text
                class="text-capitalize"
                height="20"
                @click.stop="pushRoute(ROUTE_DELETE_YOUR_ACCOUNT_GUIDE, {})"
                >{{ $t(`delete_account_guide`) }}</v-btn
              >
            </v-row>
            <v-row justify="center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a
                    href="https://www.facebook.com/Mobilityguru.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    v-on="on"
                  >
                    <v-icon color="blue">{{ mdiFacebook }}</v-icon>
                  </a>
                </template>
                <span>{{ $t(`facebook`) }}</span>
              </v-tooltip>

              <!--<v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a
                    href="https://t.me/MobilityGuru"
                    target="_blank"
                    rel="noopener noreferrer"
                    v-on="on"
                  >
                    <v-icon color="blue">{{ mdiTelegram }}</v-icon>
                  </a>
                </template>
                <span>MobilityGuru on Telegram</span>
              </v-tooltip>-->

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a
                    href="https://twitter.com/MobilityguruT/status/1400079959389995012?s=20"
                    target="_blank"
                    rel="noopener noreferrer"
                    v-on="on"
                  >
                    <v-icon color="blue">{{ mdiTwitter }}</v-icon>
                  </a>
                </template>
                <span>{{ $t(`twitter`) }}</span>
              </v-tooltip>
            </v-row>

            <br />
          </v-card>

          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <v-card class="pa-2" color="fillColour" elevation="0">
            <v-row justify="center">
              <MyBtn
                btnName="contact"
                @btn-clicked="contactUsClicked()"
                :iconName="mdiContactsOutline"
                class="ma-1"
              >
              </MyBtn>
            </v-row>
            <v-row justify="center">
              <a
                style="text-decoration: none"
                href="https://mobilityguru.org/?showlanding=no"
              >
                <b>https://mobilityguru.org</b>
              </a>
            </v-row>
            <v-row justify="center">
              No: 167556836
              <!--<MeSpeak CN: 12857765
                :text="`Company number: ${insertSpaces('12857765')}`"
                class="mr-2"
              ></MeSpeak>-->
            </v-row>

            <!--v-row justify="center" justify-lg="center">
              © MobilityGuru LTD
            </v-row>-->
            <br />
          </v-card>

          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
          <v-card class="pa-2" color="fillColour" elevation="0">
            <v-row justify="center">
              <v-img
                :src="require('./components/pics/LogoTextRight.png')"
                class="pa-0"
                contain
                height="60"
                width="320"
              />
            </v-row>

            <v-row justify="center">
              <MeSpeak
                streamKey="footer"
                size="40"
                :text="`about: careers: Terms and conditions: privacy policy: faq:  company_number
              )}: ${insertSpaces('12857765')}:© MobilityGuru`"
              ></MeSpeak>
              <!--`${$t(`message.about`)}: ${$t(`message.careers`)}: ${$t(
                  `message.terms&conditions`
                )}: ${$t(`message.privacy_policy`)}: ${$t(`message.faq`)}: ${$t(
                  `message.company_number`
                )}: ${insertSpaces('12857765')}:© MobilityGuru LTD`-->
            </v-row>
          </v-card>
        </v-row>
      </v-container>
    </v-footer>

    <!-- Filter drawer-->
    <v-navigation-drawer
      id="scroll-target"
      elevation="0"
      class="overflow-y-auto fillColour"
      v-model="drawer"
      app
      width="300"
    >
      <FilterSection @hide-drawer="drawer = !drawer"></FilterSection>
    </v-navigation-drawer>

    <!-- dialogs -->

    <v-dialog v-model="isShowPayPal">
      <v-card>
        <MyCardTitle
          title="Buy"
          @close-window="isShowPayPal = false"
        ></MyCardTitle>

        <h2 class="text-center">{{ $t(`buy`) }}</h2>
        <br />
        <v-row justify="center">
          <PayPalCheckout
            :items="myItems"
            :key="payPalBtnKey"
            :client_id="client_id"
            :product="product"
            currency="USD"
            @payment-completed="paymentCompleted"
            @payment-cancelled="paymentCancelled"
            @payment-error="paymentError"
          >
          </PayPalCheckout>
        </v-row>

        <br />
        <h2 class="text-center">{{ $t(`cryptoWallet`) }}</h2>

        <v-row class="text-center" justify="center" no-gutters>
          <DepayWidget :sale="true"></DepayWidget>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isShowMenu"
      transition="scroll-y-transition"
      open-delay="1"
    >
      <v-card>
        <MyCardTitle
          title="menu"
          @close-window="isShowMenu = false"
        ></MyCardTitle>
        <br />
        <!--<v-row justify="center" class="hidden-lg-and-up">
          <SelectLanguage @language-updated="isShowMenu = false" :key="langKey">
          </SelectLanguage>
        </v-row>-->

        <v-row justify="center">
          <MyBtn
            btnName="home"
            :iconName="mdiHomeVariant"
            @btn-clicked="goToHome()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            btnName="categories"
            :iconName="mdiFilter"
            @btn-clicked="openAdFilters"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="my"
            :iconName="mdiAccountSettingsOutline"
            @btn-clicked="goToProfile()"
            class="ma-1"
          ></MyBtn>
        </v-row>
        <v-row justify="center">
          <!--<MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="My Messages"
            :iconName="mdiMessage"
            @btn-clicked="goToMessages()"
            class="ma-1"
          ></MyBtn>-->
          <messages-with-badge
            @btn-clicked="goToMessages()"
            :button="true"
          ></messages-with-badge>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="showClearFilters"
            btnName="clearFilter"
            :iconName="mdiDatabaseSearch"
            @btn-clicked="clearFilter()"
            class="ma-1"
          ></MyBtn>
          <MyBtn
            v-if="
              this.$route.path !== this.ROUTE_LIVE_ADS &&
              this.$route.path !== this.ROUTE_MY_ADS &&
              this.$route.path !== this.ROUTE_USER_ADS
            "
            btnName="search"
            :iconName="mdiDatabaseSearch"
            @btn-clicked="goToAdSearch()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="
              this.$route.path == this.ROUTE_MY_ADS ||
              this.$route.query.myAds === true
            "
            btnName="publicAds"
            :iconName="mdiDatabaseSearch"
            @btn-clicked="goToRoute(ROUTE_LIVE_ADS)"
            class="ma-1"
          >
          </MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="showSubmitAdBtn"
            btnName="submitAd"
            :iconName="mdiSendOutline"
            @btn-clicked="appSubmitAd()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="wallet"
            :iconName="mdiWallet"
            @btn-clicked="goToWallet()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            :btnName="buyGURUText"
            :iconName="mdiCash"
            @btn-clicked="buyGuru()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            btnName="forum"
            :iconName="mdiForum"
            @btn-clicked="gotoForum()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            btnName="blog"
            :iconName="mdiBlogger"
            @btn-clicked="gotoArticles()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row
          justify="center"
          v-if="
            this.$store.state.isForumModerator || this.$store.state.isSuperAdmin
          "
        >
          <MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="article"
            :iconName="mdiPen"
            @btn-clicked="gotoSubmitArticle()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="favorite"
            :iconName="mdiHeart"
            @btn-clicked="gotoFavouriteAds()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="showLoginBtn"
            btnName="login"
            :iconName="mdiLogin"
            @btn-clicked="goToLogin()"
            class="ma-1"
          ></MyBtn>
        </v-row>

        <v-row justify="center">
          <MyBtn
            v-if="!showLoginBtn"
            btnName="logout"
            :iconName="mdiLogout"
            @btn-clicked="goToLogout()"
            class="ma-1"
          >
          </MyBtn>
        </v-row>

        <div v-if="this.$store.state.isSales === true">
          <br />
          <v-divider></v-divider>
          <p class="text-center">
            {{ $t(`welcome`) }}
            <b class="red--text">&nbsp; {{ $t(`sales`) }}</b>
          </p>

          <v-row justify="center">
            <MyBtn
              btnName="salesDash"
              :iconName="mdiWrenchOutline"
              @btn-clicked="goToSalesDashboard()"
              class="ma-1"
            ></MyBtn>
          </v-row>
        </div>

        <div v-if="this.$store.state.isDev === true">
          <br />
          <v-divider></v-divider>
          <p class="text-center">
            {{ $t(`welcome`) }}
            <b class="red--text">&nbsp; {{ $t(`developer`) }}</b>
          </p>

          <v-row justify="center">
            <MyBtn
              btnName="devDash"
              :iconName="mdiWrenchOutline"
              @btn-clicked="goToDevDashboard()"
              class="ma-1"
            >
            </MyBtn>
          </v-row>
        </div>

        <div v-if="this.$store.state.isCountrymanager === true">
          <br />
          <v-divider></v-divider>
          <p class="text-center">
            {{ $t(`welcome`) }}
            <b class="red--text">&nbsp; {{ $t(`cManager`) }}</b>
          </p>

          <v-row justify="center">
            <MyBtn
              btnName="cManagerDash"
              :iconName="mdiWrenchOutline"
              @btn-clicked="goToCountrymanagerDashboard()"
              class="ma-1"
            ></MyBtn>
          </v-row>
        </div>

        <div v-if="this.$store.state.isSuperAdmin === true">
          <br />
          <v-divider></v-divider>
          <p class="text-center">
            {{ $t(`welcome`) }}
            <b class="red--text">&nbsp; {{ $t(`sAdmin`) }}</b>
          </p>

          <v-row justify="center">
            <MyBtn
              btnName="sAdminDash"
              :iconName="mdiWrenchOutline"
              @btn-clicked="goToSuperadminDashboard()"
              class="ma-1"
            ></MyBtn>
          </v-row>
        </div>

        <div v-if="IS_DEV === true">
          <br />
          <v-divider></v-divider>
          <p class="text-center">{{ $t(`devOnly`) }}</p>

          <v-row justify="center">
            <MyBtn
              btnName="testing"
              @btn-clicked="goToSuperadminDashboard()"
              class="ma-1"
            ></MyBtn>
          </v-row>
        </div>

        <br />
      </v-card>
    </v-dialog>

    <MyEmail
      :isShowMyEmail="isShowMyEmail"
      :fromDisabled="fromDisabled"
      dialogTitle="Contact Us"
      :from="from"
      ownerGivenName="Anthony"
      :subject="subject"
      @close-window="closeMyEmail"
      :key="myEmailKey"
    ></MyEmail>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :ok="acknowledgeOk"
      :key="myAcknowledgeKey"
      :showActionBtn="showActionBtn"
      :actionName="actionName"
      @action-clicked="goToProfileWithHash('#SetAddress')"
    ></MyAcknowledge>

    <Loading
      :isLoading="isLoading"
      :message="messageLoader"
      :key="loadingKey"
    ></Loading>

    <MyRefresh :showRefresh="showRefresh" :key="showRefreshKey"></MyRefresh>
    <v-snackbar
      color="green"
      timeout="-1"
      v-model="$store.state.showTranslatingSnackbar"
      left
    >
      <span class="black--text" v-html="`<b>Translating..</b>`"></span>

      <template v-slot:action>
        <v-progress-circular indeterminate></v-progress-circular>
      </template>
    </v-snackbar>

    <v-bottom-sheet inset v-model="showBottomSheet">
      <v-card class="orange">
        <v-row class="black--text pt-2 ml-2 mr-2" no-gutters>
          <span class="notranslate">Translate back to English</span>
          <span class="mr-2 ml-2">/</span>
          <!-- <span>Translate back to English</span>-->
          <span>{{ $t(`translate_back`) }}</span>
        </v-row>

        <v-card-actions class="pa-0">
          <v-btn color="pink" text class="notranslate" @click="showOriginal()"
            >Translate</v-btn
          >
          <v-btn
            color="pink"
            text
            class="notranslate"
            @click="showBottomSheet = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-snackbar
      color="orange"
      timeout="300000"
      v-model="showNewAdBroadcast"
      :top="$vuetify.breakpoint.xs ? true : false"
      left
      class="black--text"
    >
      <v-row justify="space-between" no-gutters class="pink--text mt-n4"
        ><span class="title">New Ad!</span>
        <v-spacer></v-spacer>
        <v-btn
          class=""
          text
          color="pink"
          @click="gotoAdViewHelper(broadcastedAd.id)"
          >view</v-btn
        >
        <v-icon class="" color="pink" @click="showNewAdBroadcast = false">{{
          mdiClose
        }}</v-icon>
      </v-row>
      <span v-if="'title' in broadcastedAd" class="black--text title">{{
        broadcastedAd.title
      }}</span>
      <br />

      <span
        v-if="'price' in broadcastedAd"
        class="black--text body font-weight-bold"
        >Price: {{ broadcastedAd.currencyCode }}
        <span class="">{{ broadcastedAd.price }}</span></span
      >

      <br />
      <span
        v-if="'description' in broadcastedAd"
        class="black--text caption font-weight-bold"
        ><span v-if="broadcastedAd.description.length > 50"
          >{{ broadcastedAd.description.substring(0, 50) }}...</span
        >
        <span v-else>{{
          broadcastedAd.description.substring(0, 53)
        }}</span></span
      >
      <!--<template v-slot:action>
        <v-img
          height="60"
          width="60"
          v-if="'savedFiles' in broadcastedAd"
          :src="BACKEND00_FILES_URL + '/' + broadcastedAd.savedFiles[0]"
          :lazy-src="BACKEND00_FILES_URL + '/' + broadcastedAd.savedFiles[0]"
        ></v-img>
      </template>-->
    </v-snackbar>

    <!--  :top="$vuetify.breakpoint.xs ? true : false"-->
    <v-snackbar
      color="orange"
      timeout="300000"
      v-model="showRecentAds"
      bottom
      left
      class="black--text mb-12"
    >
      <v-carousel
        height="150"
        cycle
        class="pa-0"
        hide-delimiter-background
        :show-arrows="recentAds.length > 1"
        hide-delimiters
      >
        <v-carousel-item v-for="(recentAd, index) in recentAds" :key="index">
          <v-row justify="space-between" no-gutters class="pink--text"
            ><span class="title">Recent Ads!</span>
            <v-spacer></v-spacer>
            <v-btn
              class=""
              text
              color="pink"
              @click="gotoAdViewHelper(recentAd.id)"
              >view</v-btn
            >
            <v-icon class="" color="pink" @click="showRecentAds = false">{{
              mdiClose
            }}</v-icon>
          </v-row>
          <v-row no-gutters justify="center">
            <v-img
              height="80"
              width="80"
              contain
              transition="scale-transition"
              v-if="
                'savedFiles' in recentAd && recentAd.savedFiles[0] !== undefined
              "
              :src="BACKEND00_FILES_URL + '/' + recentAd.savedFiles[0]"
            ></v-img>
            <v-icon v-else size="80"> {{ mdiImageOffOutline }}</v-icon>
          </v-row>
          <span v-if="'title' in recentAd" class="black--text title">{{
            recentAd.title
          }}</span>
          <!-- <br />

          <span
            v-if="'price' in recentAd"
            class="black--text body font-weight-bold"
            >Price: {{ recentAd.currencyCode }}
            <span class="">{{ recentAd.price }}</span></span
          >

          <br />
          <span
            v-if="'description' in recentAd"
            class="black--text caption font-weight-bold"
            ><span v-if="recentAd.description.length > 50"
              >{{ recentAd.description.substring(0, 50) }}...</span
            >
            <span v-else>{{
              recentAd.description.substring(0, 53)
            }}</span></span
          >-->
        </v-carousel-item>
      </v-carousel>
    </v-snackbar>

    <!--Message Notification snackbar -->
    <v-snackbar
      class=""
      top
      rounded="10"
      color="orange"
      timeout="-1"
      v-model="showMessageNotification"
      left
    >
      <v-row no-gutters class="mt-n3">
        <v-icon color="green" class="pt-1 mr-2" small>{{
          mdiMessageAlert
        }}</v-icon
        ><span class="caption black--text"> Message Notification </span>
        <v-spacer></v-spacer>
        <v-icon color="brown" @click="showMessageNotification = false">{{
          mdiClose
        }}</v-icon>
      </v-row>

      <v-row no-gutters class="mt-1" justify="end">
        <span class="black--text subtitle-2"
          >You have {{ $store.state.unreadChannels }}
          {{ $store.state.unreadChannels == 1 ? `Chat ` : `Chats ` }}with
          {{ $store.state.unreadMessages }}
          {{ $store.state.unreadMessages == 1 ? `Message` : `Messages` }}</span
        >
        <v-spacer></v-spacer>
        <v-btn class="mgThinBtn ml-2 black--text" small @click="goToMessages">
          <v-icon left color="green">{{ mdiMessage }}</v-icon
          >Read
        </v-btn>
      </v-row>
    </v-snackbar>

    <!-- <div
      v-show="!notranslateApp"
      id="google_translate_element"
      @change="onGTEChange($event)"
    ></div>-->
    <LocaleChanger
      style="position: fixed; bottom: 8px; left: 10px"
    ></LocaleChanger>
  </v-app>
</template>

<style>
@import "./assets/styles/styles.css";
@import "./assets/styles/style2.css";
@import "./assets/styles/main.css";
</style>
<script>
// @ is an alias to /src (in a vue-cli generated project)
import store from "./store";

import Config from "./config.js";

//lodash
import isEmpty from "lodash/isEmpty";

import Loading from "./components/Loading";

import MyAcknowledge from "./components/MyAcknowledge";

import MyBtn from "./components/MyBtn";
import MyCardTitle from "./components/MyCardTitle";
import MyEmail from "./components/MyEmail";
import MyRefresh from "./components/MyRefresh";
import MeSpeak from "./components/MeSpeak";
import FilterSection from "./components/FilterSection.vue";
import IconsMenu from "./components/IconsMenu.vue";

import PayPalCheckout from "./components/PayPalCheckout";

import importedLogoTextRight from "./components/pics/LogoTextRight.png";
import importedLogoWord from "./components/pics/logoWord.png";
import tokenLogo from "./assets/backgroundPics/background02.jpg";
import importedLogo from "./components/pics/logoTransBG.png";

import Cookies from "js-cookie";

import DepayWidget from "./components/DepayWidget.vue";

import LocaleChanger from "./components/LocaleChanger.vue";
// lodash
//import debounce from "lodash/debounce";

// mdi
import {
  mdiAccountSettingsOutline,
  mdiContactsOutline,
  mdiCash,
  mdiDatabaseSearch,
  mdiFacebook,
  mdiFilterRemoveOutline,
  mdiForum,
  mdiHomeVariant,
  mdiLogout,
  mdiLogin,
  mdiMagnify,
  mdiMenuOpen,
  mdiSendOutline,
  mdiTelegram,
  mdiTwitter,
  mdiWrenchOutline,
  mdiWallet,
  mdiFilter,
  mdiCloseOutline,
  mdiImageOffOutline,
  mdiMessage,
  mdiMessageFlash,
  mdiHeart,
  mdiPen,
  mdiBlogger,
} from "@mdi/js";
//import SelectLanguage from "./components/SelectLanguage.vue";
import MessagesWithBadge from "./components/MessagesWithBadge.vue";
export default {
  name: "App",
  components: {
    MyAcknowledge,
    MyBtn,
    MyCardTitle,
    MyRefresh,
    Loading,
    MyEmail,
    MeSpeak,
    PayPalCheckout,
    FilterSection,
    // SelectLanguage,
    IconsMenu,
    MessagesWithBadge,
    DepayWidget,
    LocaleChanger,
  },
  metaInfo: {
    title: "Mobility Guru",
    /* meta: [
      {
        name: "description",
        content:
          "Ask for help, help other members or request new website features."
      },
    ]*/
  },

  destroyed() {
    this.$socket.$unsubscribe("chatMessage");
    this.$socket.$unsubscribe("versionVueApp");
    this.$socket.$unsubscribe("newAd");
    this.$socket.$unsubscribe("user");
    this.$socket.$unsubscribe("chatMarkedAsRead");
    this.$socket.$unsubscribe("AllChatsMarkedAsRead");
  },
  computed: {
    showLoginBtn() {
      this.debug(
        `showLoginBtn this.$store.state.isLoggedIn = ${this.$store.state.isLoggedIn}`
      );
      return (
        !this.$store.state.isLoggedIn && this.$route.path != Config.ROUTE_LOGIN
      );
    },

    showSubmitAdBtn() {
      let showSubmit =
        this.$store.state.isLoggedIn &&
        this.$route.path != Config.ROUTE_SUBMIT_AD;

      this.debug(`showSubmit = ${showSubmit}`);

      return showSubmit;
    },

    showClearFilters() {
      let showFilter = false;
      if (
        this.$route.path == this.ROUTE_LIVE_ADS ||
        this.$route.path == this.ROUTE_MY_ADS ||
        this.$route.path == this.ROUTE_USER_ADS
      ) {
        if (isEmpty(this.$route.query) === false) {
          showFilter = true;
        }
        /*if (
          Object.keys(this.$route.query).length == 1 &&
          this.$route.query.userId != undefined
        ) {
          showFilter = false;
        } else {
          showFilter = true;
        }*/
      }
      return showFilter;
    },

    showBonusBadge() {
      let showBadge = this.$store.state.isLoggedIn;

      if (this.$store.state.bonus == "") {
        showBadge = false;
        this.debug(this.$store.state.bonus);
      } else {
        showBadge = true;
      }
      this.debug("showBonusBadge()");
      this.debug(this.$store.state.bonus, showBadge);

      return showBadge;
    },

    buyGURUText() {
      this.debug(this.$vuetify.breakpoint.name);
      let text = "";

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          text = "Buy GURU";
          break;
        case "sm":
          text = "Buy US$20 GURU";
          break;
        case "md":
          text = "Buy US$20 GURU";
          break;
        case "lg":
          text = "Buy US$20 GURU";
          break;
        default:
          text = "Buy US$20 GURU at MKT PRICE";
          break;
      }

      return text;
    },

    /*
    tokenGuideText() {
      let text = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          text = "Token Guide";
          break;
        default:
          text = "Add GURU \n Token Guide";
          break;
      }
      return text;
    },
    */
  }, // computed

  watch: {
    /* This watch the title text field and if it is empty the field will be removed from the route */
    /*search: debounce(function () {
      if (this.search === "") {
        this.routerModify("search", "");
      }
    }, 1000),
    */
    /*"$store.state.selectedLanguageCode": async function () {
      this.langIndex++;
      this.langKey = `lang${this.langIndex}`;
    },*/
    "$store.state.notranslateApp": async function () {
      if (
        this.$store.state.notranslateApp !==
        this.$store.state.notranslateAppPrevious
      ) {
        /* this.$store.commit("set", [
          "notranslateAppPrevious",
          this.$store.state.notranslateApp,
        ]);
        location.reload();*/
      }
    },

    "$store.state.unreadMessages": async function () {
      this.showSnackBarNotification();
    },

    "$store.state.toggleBuyGuru": async function () {
      this.debug("1`11111111111111");
      this.buyGuru();
    },

    /*async $route(to, from) {
      this.debug(to.path);
      this.debug(from.path);
      if (
        from.path == Config.ROUTE_MESSAGES &&
        to.path !== Config.ROUTE_MESSAGES
      ) {
        await this.disconnectStream();
      }
    },*/
  },

  data: () => ({
    // mdi
    mdiAccountSettingsOutline: mdiAccountSettingsOutline,
    mdiContactsOutline: mdiContactsOutline,
    mdiCash: mdiCash,
    mdiDatabaseSearch: mdiDatabaseSearch,
    mdiFacebook: mdiFacebook,
    mdiFilterRemoveOutline: mdiFilterRemoveOutline,
    mdiForum: mdiForum,
    mdiHomeVariant: mdiHomeVariant,
    mdiMagnify: mdiMagnify,
    mdiMenuOpen: mdiMenuOpen,
    mdiLogout: mdiLogout,
    mdiLogin: mdiLogin,
    mdiSendOutline: mdiSendOutline,
    mdiTelegram: mdiTelegram,
    mdiTwitter: mdiTwitter,
    mdiWrenchOutline: mdiWrenchOutline,
    mdiWallet: mdiWallet,
    mdiFilter: mdiFilter,
    mdiClose: mdiCloseOutline,
    mdiImageOffOutline: mdiImageOffOutline,
    mdiMessage: mdiMessage,
    mdiMessageAlert: mdiMessageFlash,
    mdiHeart: mdiHeart,
    mdiPen: mdiPen,
    mdiBlogger: mdiBlogger,

    // pathLiveAds: "",
    // pathMyAds: "",
    IS_DEV: false,
    ROUTE_LIVE_ADS: "",
    ROUTE_MY_ADS: "",
    ROUTE_USER_ADS: "",
    logoTextRight: importedLogoTextRight,
    logoWord: importedLogoWord,
    logo: importedLogo,
    category: [],
    bonus: "0",

    isShowMenu: false,
    isShowPayPal: false,
    displayName: "",

    showAcknowledge: false,

    //  isWelcome: false,
    isLoading: false,
    messageLoader: "",

    acknowledgeTitle: "",
    acknowledgeMessage: "",
    isShowMyEmail: false,
    fromDisabled: false,
    from: "",
    subCategory: "",
    search: "",
    subject: "",

    myEmailIndex: 0,
    myEmailKey: "emailKey0",

    showRefresh: false,
    showRefreshKey: "showRefresh0",
    showRefreshKeyCounter: 0,

    loadingIndex: 0,
    loadingKey: "loadingKey0",

    appIndex: 0,
    appKey: "app0",

    //langIndex: 0,
    //langKey: "app0",

    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    acknowledgeOk: true,
    showActionBtn: false,
    actionName: "",
    showBottomSheet: false,
    BACKEND00_FILES_URL: Config.BACKEND00_FILES_URL,

    myItems: [
      {
        description: "Buy US$20 GURU at market price.",
        quantity: "1",
        unit_amount: {
          currency_code: "USD",
          value: "20.00",
        },
        name: "GURU Tokens",
      },
    ],
    payPalBtnCounter: 0,
    payPalBtnKey: "payPalBtnKey0",
    client_id: "",
    product: {
      price: "20.00",
      description: "GURU Tokens",
    },
    ROUTE_TOKEN_GUIDE: Config.ROUTE_TOKEN_GUIDE,
    ROUTE_AIRDROP_FORM: Config.ROUTE_AIRDROP_FORM,
    ROUTE_THIRD_PARTY_LOGIN_GUIDE: Config.ROUTE_THIRD_PARTY_LOGIN_GUIDE,
    ROUTE_DELETE_YOUR_ACCOUNT_GUIDE: Config.ROUTE_DELETE_YOUR_ACCOUNT_GUIDE,

    drawer: false,
    tokenLogo: tokenLogo,

    btnNameSearch: "search",
    notranslateApp: false,
    notranslateClass: "",

    showNewAdBroadcast: false,
    broadcastedAd: {},
    recentAds: [],
    showRecentAds: false,
    showMessageNotification: false,

    isGlobalLoading: true,
    isTranslationsLoading: true,
    languagesUpdatedAt: 0,
  }),

  store: store,

  // we want to call init() before other comnponents have mounted
  // before create
  mounted: async function () {
    this.isGlobalLoading = true;
    await this.init();
    this.isGlobalLoading = false;
    this.appIndex++;
    this.appKey = `app${this.appIndex}`;

    //load if user is logged in
    if (await this.isLoggedInOIDC(true)) {
      //await this.connectClientToStream();

      this.syncProfileFromServer();
      await this.getChatsFromBackend();
      this.showSnackBarNotification();
    }
  },

  created() {
    // var self = this;
    //This will stop watching the channel and disconnect the user
    window.addEventListener("beforeunload", this.leaving);
    /*window.addEventListener("beforeunload", async function (event) {
      //event.preventDefault();
      await self.leaving();
    });*/
  },

  methods: {
    setupSocketListeners() {
      this.$socket.$subscribe("versionVueApp", (version) => {
        if (this.$store.state.version === version) {
          this.debug(`versionVueApp: same version: ${version}`);
        } else {
          this.debug(`versionVueApp: different version ${version}`);
          this.$store.commit("set", ["version", version]);

          // clear countries so we get them reloaded via backend00
          this.$store.commit("set", ["countries", []]);

          this.showRefresh = true;
          this.showRefreshKeyCounter++;
          this.showRefreshKey = `showRefresh${this.showRefreshKeyCounter}`;
          this.debug(
            `showRefreshKey = ${this.showRefreshKey}, showRefresh = ${this.showRefresh}`
          );
        }
      });

      this.$socket.$subscribe("newAd", async (ad) => {
        this.debug(this.$store.state.countryByIP);
        if (this.$store.state.countryByIP == ad.country) {
          this.printJson(ad, "socket ad");
          //this.showNewAdBroadcast = true;
          //this.broadcastedAd = ad;
          while (this.$route.path == Config.ROUTE_SUBMIT_AD) {
            await this.synchSetTimeout(200);
            this.debug(".");
          }
          await this.showRecentAdsSnackbar();
        }
      });

      this.$socket.$subscribe("user", async (user) => {
        if (await this.isLoggedInOIDC(true)) {
          if (this.$store.state.accessTokenDecoded.sub == user.id) {
            this.printJson(user, "user broadcated");
            this.$store.commit("set", ["favouriteAds", user.favouriteAds]);
          } else {
            console.log("non");
          }
        }
      });
      this.$socket.$subscribe("chatMessage", async (messageObj) => {
        // if (this.$route.path != Config.ROUTE_MESSAGES) {
        await this.updateOnNewMessage(messageObj);
        this.$store.commit("set", ["newMessage", messageObj]);
        //  }
        /*if (messageObj.to == this.$store.state.accessTokenDecoded.sub) {
          this.printJson(messageObj, "messageObj broadcated");
          let chats = this.$store.state.unreadChannels + 1;
          let messages = this.$store.state.unreadMessages + 1;

          this.$store.commit("set", ["unreadMessages", messages]);
          this.$store.commit("set", ["unreadChannels", chats]);
        }*/
      });

      this.$socket.$subscribe("chatMarkedAsRead", async (data) => {
        this.chatMarkedAsRead(data);
      });

      this.$socket.$subscribe("AllChatsMarkedAsRead", async (userId) => {
        this.debug(`allmarkedasRead ${userId}`);
        if (
          userId == this.$store.state.accessTokenDecoded.sub
          // && this.$route.path != Config.ROUTE_MESSAGES
        ) {
          await this.getChatsFromBackend();
        }
      });

      this.$socket.$subscribe("languagesUpdatedAt", async (data) => {
        if (this.languagesUpdatedAt != data) {
          this.debug("resetting language");
          await this.resetMessages(this.$i18n.locale);
        }
      });
    },

    async init() {
      let start = new Date();
      /*let callRecentAds = false;
      if (this.$store.state.countryByIP == "") {
        callRecentAds = true;
      }*/

      this.debug(
        `this.$store.state.selectedLanguageCode = ${this.$store.state.selectedLanguageCode}`
      );
      this.isTranslationsLoading = true;
      this.$i18n.locale = this.$store.state.selectedLanguageCode;
      if (this.$i18n.locale != "en") {
        await this.loadMessages("en");
      }
      await this.loadMessages(this.$i18n.locale);
      this.isTranslationsLoading = false;
      this.setupSocketListeners();

      /*if (
        this.$store.state.countryByIP === "" ||
        this.$store.state.languageCodeByIP === ""
      ) {*/

      //await this.getCountryByIP();
      await this.getAppData();

      /*} else {
        this.debug(
          `Country by IP: ${this.$store.state.countryByIP}, userselected country ${this.$store.state.country}`
        );
      }*/
      this.notranslateApp = this.$store.state.notranslateApp;
      if (this.notranslateApp == true) {
        this.notranslateClass = "notranslate";
      } else {
        this.notranslateClass = "";
      }
      if (this.notranslateApp == false) {
        //check for locale selected and language by IP
        /*let locale = await this.getLocaleOfGT();
        if (locale.length > 2) {
          locale = locale.substring(1, 3);
        }
        console.log(
          `locale selected now = ${locale} vs in store ${this.$store.state.selectedLanguageCode}`
        );
        if (locale !== this.$store.state.selectedLanguageCode) {
          let cookieValue = `/en/${this.$store.state.selectedLanguageCode}`;
          //check for chinese language code
          if (this.$store.state.selectedLanguageCode == `zh`) {
            cookieValue = `/en/zh-CN`;
          }

          console.log(`language by user selected ${cookieValue}`);

          this.setAllGoogleTranslateCookies(cookieValue);

          console.log(
            `setting ${Config.GOOGLE_TRANSLATE_COOKIE_NAME} = [${cookieValue}]`
          );
        }*/

        if (this.$store.state.selectedLanguageCode !== "en") {
          this.showBottomSheet = true;
        }
      }

      //countries arefetched in above getCountryByIP()
      //await this.getCountries();

      //await this.getLabels(this.$i18n.locale);
      //this.printJson(this.$store.state.messages, "messages with locales");
      //this.langIndex++;
      //this.langKey = `lang${this.langIndex}`;
      this.debug(`${Object.keys(this.$route.query).length}`);
      this.printJson(this.$route.query, "The query in the path");
      this.ROUTE_LIVE_ADS = Config.ROUTE_LIVE_ADS;
      this.ROUTE_MY_ADS = Config.ROUTE_MY_ADS;
      this.ROUTE_USER_ADS = Config.ROUTE_USER_ADS;
      this.IS_DEV = Config.IS_DEV;
      //this.MAIN_CATEGORIES_ENUM = Config.MAIN_CATEGORIES_ENUM;
      //this.MAIN_CATEGORIES = Config.MAIN_CATEGORIES;
      //this.SUB_CATEGORIES_LIST = Config.SUB_CATEGORIES_LIST;
      this.bonus = this.$store.state.bonus;

      /*for (var i = 0; i < this.MAIN_CATEGORIES.length; i++) {
        this.category[i] = {
          tooltip: this.MAIN_CATEGORIES[i],
          avatar: "/icons/" + this.MAIN_CATEGORIES[i] + ".svg",
          subCategories: this.SUB_CATEGORIES_LIST[i],
        };
      }*/

      if (Config.IS_DEV === true) {
        //  this.payPalEnv = "sandbox";
        this.client_id = Config.PAYPAL_SANDBOX_CLIENT_ID;
      } else {
        //this.payPalEnv = "production";
        this.client_id = Config.PAYPAL_PRODUCTION_CLIENT_ID;
      }

      this.debug(`Vue version = ${this.getVueVersion()}`);
      //this.googleTranslateElementInit();

      this.showRecentAdsSnackbar();

      this.clearIndexedDB();

      let end = new Date();
      this.debug(`App load time = ${end - start} ms`);
      this.getFirebaseToken();
      this.$store.commit("set", ["showTranslatingSnackbar", false]);
    },

    async goToRoute(routeStr) {
      await this.synchSetTimeout(1);
      this.isShowMenu = false;
      await this.synchSetTimeout(1);

      if (
        this.$route.path != routeStr ||
        isEmpty(this.$route.query) === false
      ) {
        this.$router.push(routeStr);
      }
    },

    async goToLogin() {
      await this.goToRoute(Config.ROUTE_LOGIN);
    },

    async goToProfile() {
      await this.goToRoute(Config.ROUTE_PROFILE);
    },

    async goToHome() {
      await this.goToRoute(Config.ROUTE_HOME);
    },

    async goToWallet() {
      await this.goToRoute(Config.ROUTE_WALLET);
    },

    async goToAdSearch() {
      this.search = "";
      if (
        this.$route.path == this.ROUTE_MY_ADS ||
        this.$route.query.myAds === true
      ) {
        await this.goToRoute(Config.ROUTE_MY_ADS);
      } else {
        await this.goToRoute(Config.ROUTE_LIVE_ADS);
      }
    },

    /*async gotoSubmitAd() {
      if (this.$store.state.accessTokenDecoded.email_verified === true) {
        await this.goToRoute(Config.ROUTE_SUBMIT_AD);
      } else {
        this.acknowledgeTitle = "Email Unverified";
        this.acknowledgeMessage =
          "Please verify your email before submitting an ad.";

        this.showAcknowledge = true;
      }
    },*/

    async gotoForum() {
      await this.goToRoute(Config.ROUTE_FORUM);
    },

    async gotoSubmitArticle() {
      await this.goToRoute(Config.ROUTE_SUBMIT_ARTICLE);
    },

    async gotoFavouriteAds() {
      await this.goToRoute(Config.ROUTE_FAVOURITE_ADS);
    },

    async goToSuperadminDashboard() {
      await this.goToRoute(Config.ROUTE_SUPERADMIN_DASHBOARD);
    },

    async goToCountrymanagerDashboard() {
      await this.goToRoute(Config.ROUTE_COUNTRYMANAGER_DASHBOARD);
    },

    async goToSalesDashboard() {
      await this.goToRoute(Config.ROUTE_SALES_DASHBOARD);
    },

    async goToDevDashboard() {
      await this.goToRoute(Config.ROUTE_DEV_DASHBOARD);
    },

    async gotoArticles() {
      await this.goToRoute(Config.ROUTE_ARTICLES);
    },

    async goToLogout() {
      this.isLoading = true;
      this.messageLoader = "Logging Out...";

      let ok = await this.logout();

      if (ok == true) {
        this.isLoading = false;

        this.acknowledgeTitle = "Logged Out";
        this.acknowledgeMessage = "You have logged out successfully!";

        this.showAcknowledge = true;
        await this.synchSetTimeout(1);

        if (this.$route.path != Config.ROUTE_HOME) {
          this.$router.push(Config.ROUTE_HOME);
        }
      }

      this.isLoading = false;
      await this.synchSetTimeout(1);
    },

    async goToAbout() {
      await this.goToRoute(Config.ROUTE_ABOUT);
    },

    async goToCareers() {
      await this.goToRoute(Config.ROUTE_CAREERS);
    },

    async goToTerms() {
      await this.goToRoute(Config.ROUTE_TERMS);
    },

    async goToPrivacy() {
      await this.goToRoute(Config.ROUTE_PRIVACY);
    },
    async goToFaq() {
      await this.goToRoute(Config.ROUTE_FAQ);
    },
    async goToRaffle() {
      await this.goToRoute(Config.ROUTE_RAFFLE);
    },

    contactUsClicked() {
      if (this.$store.state.isLoggedIn) {
        this.from = this.$store.state.accessTokenDecoded.preferred_username;
      } else {
        this.from = "";
      }
      this.myEmailKey++;

      this.isShowMyEmail = true;
    },

    async gotoTest() {
      await this.goToRoute(Config.ROUTE_TEST);
    },

    async appSubmitAd() {
      this.debug("appSubmitAd()");
      this.isShowMenu = false;
      let res = await this.isSubmitAdAvailable();
      if (res.ok === true) {
        this.debug(res);
        this.showAcknowledge = true;
        this.acknowledgeTitle = res.acknowledgeTitle;
        this.acknowledgeMessage = res.acknowledgeMessage;
        this.myAcknowledgeIndex++;
        this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
        this.showActionBtn = res.showActionBtn;
        this.actionName = res.actionName;
      }
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    paymentCompleted: function (data) {
      this.printJson(data, "payment complete data");
      this.setAcknowledge(
        "Please wait for us to confirm payment cleared and send you the 400 GURU tokens.",
        "Payment Successful!"
      );
      this.isShowPayPal = false;
    },

    paymentCancelled: function (data) {
      this.printJson(data, "payment cancelled data");
    },

    paymentError: function (err) {
      this.printJson(err, "payment error data");
    },

    setAcknowledge(message, title, ok) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.acknowledgeOk = ok;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = "myAcknowledge" + this.myAcknowledgeKey;
    },

    buyGuru() {
      //this.payPalBtnCounter++;
      //this.payPalBtnKey = `payPalBtnKey${this.payPalBtnCounter}`;
      this.isShowPayPal = true;
    },
    openAdFilters() {
      this.drawer = true;
      this.isShowMenu = false;
    },

    closeMyEmail(obj) {
      let message =
        "Email sent! We will be in touch with you shortly. Thank you!";
      let ok = true;

      if (obj !== undefined) {
        if (obj.ok === false) {
          message = "Email sending failure!";
          ok = false;
        }

        this.setAcknowledge(message, "Email Sending", ok);
        this.myEmailIndex++;
        this.myEmailKey = "emailKey" + this.myEmailIndex;
      }

      this.isShowMyEmail = false;
    },

    /* converts the string in GOOGLE_TRANSLATE_COOKIE_DOMAINS into an array
     */
    readInGoogleTranslateCookieDomains() {
      let domains = Config.GOOGLE_TRANSLATE_COOKIE_DOMAINS.split(",");
      console.log(`domains = [${domains}], ${domains.length}`);
      return domains;
    },

    setAllGoogleTranslateCookies(value) {
      let googleCookieDomains = this.readInGoogleTranslateCookieDomains();
      let len = googleCookieDomains.length;
      let cookieName = Config.GOOGLE_TRANSLATE_COOKIE_NAME;

      console.log("Removing cookies");

      // remove cookie with domin = mobilityguru.org
      Cookies.remove(cookieName);

      // remove cookie with domin = .mobilityguru.org
      let options = {
        domain: Config.VUE_APP_GOOGLE_TRANSLATE_COOKIE_DOT_DOMAIN,
      };
      Cookies.remove(cookieName, options);

      console.log("Setting cookies");
      for (let i = 0; i < len; i++) {
        let domain = googleCookieDomains[i];

        let cookie = Cookies.get(cookieName, options);
        console.log(`  ${i} cookie ${cookieName} = ${cookie}`);
        console.log(`  setting ${cookieName}=[${value}], domain [${domain}]`);
        let options = {
          domain: domain,
        };
        Cookies.set(cookieName, value, options);
      }
    },

    onGTEChange(event) {
      this.$store.commit("set", ["localeGT", event.target.value]);
      this.$store.commit("set", ["selectedLanguageCode", event.target.value]);

      this.debug(`App.vue onGTEChange() value = ${event.target.value}`);
    },

    /*async resetGTE() {
      let cookieValue = "/en/en";
      this.setAllGoogleTranslateCookies(cookieValue);
      await this.synchSetTimeout(1000);
      this.googleTranslateElementInit();
      this.appIndex++;
      this.appKey = `app${this.appIndex}`;
    },*/

    async showOriginal() {
      this.$store.commit("set", ["selectedLanguageCode", "en"]);
      this.$i18n.locale = this.$store.state.selectedLanguageCode;
      await this.loadMessages(this.$i18n.locale);
      this.debug(this.$store.state.selectedLanguageCode);

      this.showBottomSheet = false;

      //location.reload();
    },

    toggleNoTranslate() {
      this.notranslateApp = !this.notranslateApp;
      this.$store.commit("set", ["notranslateApp", this.notranslateApp]);
      if (this.notranslateApp == true) {
        this.notranslateClass = "notranslate";
      } else {
        this.notranslateClass = "";
      }

      //uncomment below if we want to remove the selected language from dropdown
      //this.showOriginal();

      location.reload();
      //this.reloadGTE();
    },

    /* reloadGTE() {
      Element.prototype.remove = function () {
        this.parentElement.removeChild(this);
      };
      NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
        for (var i = this.length - 1; i >= 0; i--) {
          if (this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
          }
        }
      };
      document.getElementById("google_translate_element").removeChild();
      //var parent = document.getElementById("app");
      //parent.parentNode.removeChild("google_translate_element");
      //await this.synchSetTimeout(1000);
      this.googleTranslateElementInit();
    },*/

    gotoAdViewHelper(adId) {
      this.gotoAdView(adId, Config.AD_TYPES_ENUM.PUBLIC_ADS, "recentAds");
    },

    async showRecentAdsSnackbar() {
      // if (callRecentAds == true) {
      let recentAdsRes = await this.loadRecentAds(
        this.$store.state.countryByIP
      );
      if (recentAdsRes.ok == true) {
        this.recentAds = recentAdsRes.ads;
        if (this.recentAds.length > 0) {
          this.showRecentAds = true;
        } else {
          this.showRecentAds = false;
        }
      }
      // }
    },

    async goToMessages() {
      this.showMessageNotification = false;
      await this.goToRoute(Config.ROUTE_MESSAGES);
    },
    async leaving() {
      this.debug("disconnecting stream " + this.$store.state.streamConnected);
      // await this.disconnectStream();
      return true;
    },

    showSnackBarNotification() {
      this.printJson(
        parseFloat(this.$store.state.unreadMessages) > 0 &&
          parseFloat(this.$store.state.unreadChannels) > 0,
        "showSnackbar"
      );
      if (
        parseFloat(this.$store.state.unreadMessages) > 0 &&
        parseFloat(this.$store.state.unreadChannels) > 0
      ) {
        if (this.$route.path !== Config.ROUTE_MESSAGES) {
          this.showMessageNotification = true;
        }
      } else {
        this.showMessageNotification = false;
      }
    },
  }, // methods
};
</script>
