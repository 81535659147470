<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            You can use this option to publish a fresh ad across
            <strong>Multiple Countries.</strong> Press the
            <strong>My MobilityGuru</strong> button in the menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> as below.
            <MeSpeak
              text="You can use this option to publish a fresh ad across multiple countries. Press the
             button in the menu dialog/
            Profile as below."
            >
            </MeSpeak>
          </li>
          <br />

          <li>
            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
          <li>
            After clicking the <strong>My MobilityGuru</strong> button, your
            profile will pop up and you have to Click the
            <a href="https://mobilityguru.org/submit-ad">Submit Ad</a> button.
            <MeSpeak
              text="
            After clicking the My MobilityGuru button, your profile will pop up and you have to Click the Submit Ad button."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/submit0.2.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="profile"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <li>
            Now you can see the <strong>Submit Ad</strong> page and place your
            Ad there.
            <MeSpeak
              text="
           Now you can see the View Submit Ad page and place your Ad there."
            ></MeSpeak>
          </li>
          <li>
            After filling all details about your Ad and you can see
            <strong>Select Country</strong> option in bottom of the page.
            <MeSpeak
              text="After filling all details about your Ad and you can see Select Country option in bottom of the page"
            ></MeSpeak>
          </li>
          <ul>
            <v-img
              :src="require(`./pics/selectCountryPc.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="selectCountryPc"
            />
            <v-img
              :src="require(`./pics/selectCountryMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="selectCountryMob"
            />
          </ul>
          <br />

          <li>
            A bottom sheet with a list of selectable countries will open when
            you click <strong>Select Country.</strong
            ><MeSpeak
              text="A bottom sheet with a list of selectable countries will open when you click Select Country."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/selectedCountryPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="selectCountryPc"
              />
              <v-img
                :src="require(`./pics/selectedCountryMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="selectedCountryMob"
              />
            </ul>
            <br />
          </li>

          <li>
            You can select countries as you want. After that you can see the
            <strong>Confirm </strong>button in bottom of right corner.<MeSpeak
              text="You can select countries as you want. After that you can see the button in bottom of right corner."
            ></MeSpeak>
          </li>
          <li>
            When you click <strong>Confirm</strong> button, Currency will
            automatically <strong>converted</strong> based on your
            <strong>Selected Countries.</strong>
            <MeSpeak
              text="When you click Confirm button, Currency will automatically converted based on your Selected Countries"
            ></MeSpeak>
          </li>

          <li>
            And after you can see
            <strong> Check for Translation</strong> Button and
            <strong>Translate </strong>it for needed country.<MeSpeak
              text="And after you can see Check for Translation Button and Translate for needed country."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/checkTranslatePc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="checkTranslatePc"
              />
              <v-img
                :src="require(`./pics/checkTranslateMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="checkTranslateMob"
              />
            </ul>
            <br />
          </li>

          <li>
            And you can <strong>Translate </strong>your
            <strong>Ad Title </strong>and <strong>Description </strong>in
            there.<MeSpeak
              text="And you can Translate your ad Title and Description in there."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/titleTranslatePc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="titleTranslatePc"
              />
              <v-img
                :src="require(`./pics/titleTranslateMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="titleTranslateMob"
              />
            </ul>
            <br />
          </li>
          <li>
            After done with your setup, click the
            <strong>Submit Ad.</strong> And it will be appear in
            <strong
              ><a href="https://mobilityguru.org/my-ads">My Ads</a></strong
            >
            and Public.
            <MeSpeak
              text="After done with your setup, click the Submit Ad. And it will be appear in My Ads and Public."
            ></MeSpeak>
          </li>
          <br />

          <br />
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "multipleCountries",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
