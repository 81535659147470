<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            Please click the <strong>Menu</strong> button in the corner of the
            App bar above and the click
            <strong>LOG IN OR SIGN UP</strong> button from the pop up dialog
            <MeSpeak
              text="Please click the Menu button in the corner of the
            App bar above and the click LOG IN OR SIGN UP button from the pop up dialog"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/signUpPc0.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Login or SignUp"
              />
              <v-img
                :src="require(`./pics/signUpPhone0.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Login or SignUp"
              />
            </ul>
          </li>
          <li>
            You will be ridirected to the login page then press the button named
            <strong>Register</strong>
            <MeSpeak
              text=" You will be ridirected to the login page then press the button named Register"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/signUpPc1.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Register"
              />
              <v-img
                :src="require(`./pics/signUpPhone1.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="register"
              />
            </ul>
          </li>
          <li>
            On Register page<MeSpeak text="On Register pagef"></MeSpeak>
            <ol type="1">
              <li>
                Please read our
                <em
                  ><a href="https://mobilityguru.org/Privacy"
                    >Privacy Policy</a
                  ></em
                >
                and the
                <em
                  ><a href="https://mobilityguru.org/Terms"
                    >Terms &amp; Conditions</a
                  ></em
                >, and accept.<MeSpeak
                  text=" Please read our Privacy Policy and the Terms &amp; conditions, and accept"
                ></MeSpeak>
              </li>
              <li>
                Enter your first name and last name<MeSpeak
                  text="Enter your first name and last name"
                ></MeSpeak>
              </li>
              <li>
                Enter your email address correctly<MeSpeak
                  text="Enter your email address correctly"
                ></MeSpeak>
              </li>
              <li>
                Enter a valid password and confirm it again<MeSpeak
                  text="nter a valid password and confirm it again"
                ></MeSpeak>
              </li>
              <li>
                Then confirm you are not a robot by clicking the checkbox -
                <strong>I’m not a robot</strong
                ><MeSpeak
                  text="Then confirm you are not a robot by clicking the checkbox - I’m not a robot"
                ></MeSpeak>
              </li>
              <li>
                Finally press the <strong>Register</strong> button<MeSpeak
                  text=" Finally press the Register button"
                ></MeSpeak>
                <ul>
                  <v-img
                    :src="require(`./pics/signUpPc2.png`)"
                    class="ma-3 hidden-xs-only"
                    contain
                    alt="Forms"
                  />
                  <v-img
                    :src="require(`./pics/signUpPhone2.png`)"
                    class="mr-5 mt-5 hidden-sm-and-up"
                    contain
                    alt="Forms"
                  />
                </ul>
              </li>
            </ol>
          </li>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "signUp",
  components: { MeSpeak },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
