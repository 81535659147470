<template>
  <v-card>
    <v-container :key="containerKey">
      <!--<v-row justify="center">
        <MyBtn
          btnName="Pay"
          :iconName="mdiCurrencyUsdCircleOutline"
          @btn-clicked="pay()"
        ></MyBtn>
      </v-row>

      <b>Banner Image:</b>-->

      <!--  <ol>
          <li>Add up to 8 photos.</li>
          <li>
            For computers only - Hold down shift and click on photos to select.
          </li>
          <li>Rotate or move photo by tapping icons above each photo added.</li>
        </ol>-->

      <div v-if="imageUploaded === false">
        <upload-btn
          icon
          @file-update="addDraftFileURL"
          :color="getUploadMultBtnColour()"
          large
          :disabled="currentNumDraftFiles > 0"
          :key="uploadMultBtnKey"
          fixedWidth="300px"
        >
          <template slot="icon">
            <v-icon :color="getUploadMultBtnIconColour()" large>{{
              mdiPlusBoxMultipleOutline
            }}</v-icon>

            <span :class="getUploadBtnTextColour()">
              {{ $t(`select_image`) }}
            </span>
          </template>
        </upload-btn>
        <br />
      </div>
      <div v-else>
        <p>{{ $t(`current`) }}</p>
        <v-img :src="imageUrl" height="150px" contain> </v-img>
        <br />
        <v-row justify="center">
          <MyBtn
            btnName="delete"
            :iconName="mdiTrashCanOutline"
            @btn-clicked="deleteImage()"
          ></MyBtn>
        </v-row>
      </div>

      <v-row v-if="imageUploaded === false">
        <v-card
          class="ml-5"
          v-for="(fileURL, index) in draftFilesURLs"
          :key="index"
          width="300px"
          height="230px"
        >
          <v-row
            v-if="fileURL === ''"
            style="height: 200px"
            align="center"
            justify="center"
          >
            {{ $t(`can`) }} {{ maxFiles - currentNumDraftFiles }}
            {{ $t(`photo`) }}
            <!--
              <upload-btn
                icon
                accept="image/*"
                @file-update="addDraftFileURL($event, index)"
                :color="getUploadBtnColour(index)"
                fixedWidth="170px"
                :disabled="index > currentNumDraftFiles"
              >
                <template slot="icon">
                  <v-icon :color="getUploadBtnIconColour(index)" large>
                    {{ mdiImagePlus }}
                  </v-icon>

                  <span :class="getUploadBtnTextColour(index)">Pick Photo</span>
                </template>
              </upload-btn>
              -->
          </v-row>

          <div v-if="fileURL !== ''">
            <v-row justify="center" no-gutters>
              <span>
                <!--<v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      align="center"
                      @click="movePicLeft(index)"
                      color="fillColour"
                      v-on="on"
                      :disabled="index == 0"
                      small
                    >
                      <v-icon color="green">{{
                        mdiArrowLeftBoldOutline
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Move Left</span>
                </v-tooltip>-->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      align="center"
                      @click="removePic(index)"
                      color="fillColour"
                      v-on="on"
                      small
                    >
                      <v-icon color="green">{{ mdiCloseOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(`remove`) }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      align="center"
                      @click="cropPic(index)"
                      color="fillColour"
                      v-on="on"
                      small
                      :disabled="disableCrop"
                    >
                      <v-icon color="green">{{ mdiCropFree }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(`crop`) }}</span>
                </v-tooltip>

                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      align="center"
                      @click="rotatePic(index)"
                      color="fillColour"
                      v-on="on"
                      small
                    >
                      <v-icon color="green">{{ mdiRotateRight }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Rotate Clockwise</span>
                </v-tooltip>-->

                <!--  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      align="center"
                      @click="movePicRight(index)"
                      color="fillColour"
                      v-on="on"
                      :disabled="index >= currentNumDraftFiles - 1"
                      small
                    >
                      <v-icon color="green">{{
                        mdiArrowRightBoldOutline
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Move Right</span>
                </v-tooltip>-->
              </span>
            </v-row>

            <div align="center">
              <a :href="fileURL" download>
                <v-img
                  :src="fileURL"
                  width="180px"
                  height="180px"
                  contain
                  :id="'image' + index"
                ></v-img>
              </a>
            </div>

            <!--
              <v-card>
                <span
                  v-if="
                    isFileObject(draftFiles[index]) == true ||
                    isBlobObject(draftFiles[index]) == true
                  "
                  >{{ draftFiles[index].size }} bytes</span
                >

                <span
                  v-if="
                    isFileObject(draftFiles[index]) == false &&
                    isBlobObject(draftFiles[index]) == false &&
                    savedFilesSizes.length > 0 &&
                    index < savedFilesSizes.length
                  "
                  >{{ savedFilesSizes[index] }} bytes</span
                >
              </v-card>
              -->
          </div>
        </v-card>
      </v-row>
    </v-container>
    <canvas
      id="myCanvas"
      width="640"
      height="480"
      style="display: none"
    ></canvas>

    <div v-if="imageUploaded === false">
      <v-row align="center" justify="center" no-gutters class="pt-1">
        <MyBtn
          btnName="upload"
          @btn-clicked="saveImage()"
          :iconName="mdiContentSave"
          :disabled="isLoading || disableUpload"
        ></MyBtn>
      </v-row>
    </div>
    <v-text-field
      @input="linkChanged"
      :label="$t('valid_url_message')"
      :prepend-icon="mdiImage"
      v-model="imageLink"
      :rules="[rules.required, rules.description600, rules.urlPattern]"
      required
      ref="title"
    />
    <v-row align="center" justify="center" no-gutters class="pt-1">
      <MyBtn
        btnName="saveLink"
        @btn-clicked="saveLink()"
        :iconName="mdiContentSave"
        :disabled="isLoading || disableUploadLink"
      ></MyBtn>
    </v-row>

    <br />
    <v-divider></v-divider>
    <br />
    <h2 class="text-center">{{ $t(`status`) }}</h2>
    <p>{{ $t(`time`) }} {{ daysLeftText }}</p>
    <p v-if="!rotatingBannerAd.approved" class="red--text">
      {{ $t(`banner_ad_wait_msg`) }}
    </p>
    <div v-else>
      <p class="green--text">{{ $t(`banner_approved_msg`) }}</p>

      <p v-if="rotatingBannerAd.isLive" class="green--text">
        {{ $t(`banner_published_msg`) }}
      </p>
      <p v-else class="red--text">{{ $t(`banner_paused_msg`) }}</p>

      <v-row
        align="center"
        justify="center"
        no-gutters
        class="pt-1"
        v-if="rotatingBannerAd.approved"
      >
        <MyBtn
          btnName="publish"
          @btn-clicked="toggleBannerAdIsLive(bannerId, rotatingBannerAd.isLive)"
          :disabled="bannerIsLiveWait"
          v-if="!rotatingBannerAd.isLive"
        ></MyBtn>
        <MyBtn
          btnName="pause"
          @btn-clicked="toggleBannerAdIsLive(bannerId, rotatingBannerAd.isLive)"
          :disabled="bannerIsLiveWait"
          v-if="rotatingBannerAd.isLive"
        ></MyBtn>
        <v-progress-circular
          v-if="bannerIsLiveWait"
          indeterminate
          color="brown"
        ></v-progress-circular>
      </v-row>
    </div>

    <v-row align="center" justify="center" no-gutters class="pt-2">
      <MyBtn
        v-if="showSelectImageButton"
        btnName="insert"
        @btn-clicked="showSelectImagesDialog"
        :iconName="mdiSelect"
        :disabled="isLoading"
      ></MyBtn>
    </v-row>

    <v-row align="center" justify="center" no-gutters class="pt-2">
      <MyBtn
        v-if="showDeleteBtn"
        btnName="delete"
        @btn-clicked="showYesNoDelete"
        :iconName="mdiDelete"
        :disabled="isLoading"
      ></MyBtn>
    </v-row>

    <v-progress-linear
      color="primary"
      rounded
      indeterminate
      v-if="isLoading"
    ></v-progress-linear>

    <VueCropper
      :key="cropperKey"
      @close-cropper="closeVueCropper"
      @save-cropped="saveCroppedImage"
      @imgSrc-changed="updateImgSrc"
      :showCropper="showCropper"
      :title="cropperTitle"
      :imgSrc="imgSrc"
    ></VueCropper>
    <v-dialog v-model="showSelectBannerImage" persistent width="600">
      <v-card :key="containerKey">
        <MyCardTitle
          title="Select an image to show in Rotating Banner"
          @close-window="showSelectBannerImage = false"
        ></MyCardTitle>
        <v-card-text v-if="rotatingBannerAdOrig !== null">
          <v-card v-if="'bannerObject' in RotatingBannerAdLocal">
            <v-row justify="center" no-gutters>
              <h4>{{ $t(`selected`) }}</h4>
            </v-row>
            <v-row no-gutters
              ><span class="mr-2">{{ $t(`status`) }} </span
              ><span
                v-if="RotatingBannerAdLocal.bannerObject.approved === true"
              >
                {{ $t(`approved`) }}
              </span>
              <span v-else>{{ $t(`pending`) }}</span></v-row
            >
            <span class="ma-1"
              >{{ $t(`urll`) }}
              {{ RotatingBannerAdLocal.bannerObject.URL }}</span
            >
            <v-row no-gutters>
              <v-card class="ma-1">
                <!-- <v-row justify="end" no-gutters>
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          align="center"
                          @click="removeSelected(index)"
                          color="fillColour"
                          v-on="on"
                          small
                        >
                          <v-icon color="green">{{ mdiCloseOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Photo</span>
                    </v-tooltip>
                  </span>
                </v-row>-->
                <v-img width="250" height="200" :src="imageUrl"> </v-img>
              </v-card>
            </v-row>
            <br />
          </v-card>
          <br />
          <!--<v-card v-if="'url' in rotatingBannerAdOrig">
            <v-row justify="center" no-gutters>
              <h4>Uploaded URL Image</h4>
            </v-row>
            <v-row no-gutters>
              <v-card
                class="ma-1"
                @click="updateRotatingBannerAdImage(rotatingBannerAdOrig.url)"
              >
                <v-img width="250" height="200" :src="rotatingBannerAdOrig.url">
                </v-img>
              </v-card>
            </v-row>
          </v-card>-->
        </v-card-text>
      </v-card>
    </v-dialog>
    <Loading
      :isLoading="isLoading"
      :message="messageLoader"
      :percentage="percentageLoader"
    ></Loading>
    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
    <MyYesNo
      :key="yesNoDeleteKey"
      :showYesNo="showYesNo"
      :title="yesNoTitle"
      :message="yesNoMessage"
      @yes-clicked="deleteBannerFromProfile"
      @no-clicked="closeYesno"
    ></MyYesNo>
  </v-card>
</template>
<script>
import {
  mdiCurrencyUsdCircleOutline,
  mdiNotebookCheckOutline,
  mdiArrowLeftBoldOutline,
  mdiArrowRightBoldOutline,
  mdiCashUsdOutline,
  mdiCloseOutline,
  mdiCloseOctagonOutline,
  mdiContentSave,
  mdiFormatTitle,
  mdiLocationEnter,
  mdiPhone,
  mdiContentSaveEditOutline,
  mdiImagePlus,
  mdiPlusBoxMultipleOutline,
  mdiRotateRight,
  mdiSelect,
  mdiImage,
  mdiCropFree,
  mdiDelete,
  mdiTrashCanOutline,
} from "@mdi/js";

import UploadButton from "vuetify-upload-button";

import Vue from "vue";

import MyBtn from "./MyBtn";
import Loading from "./Loading";

import MyAcknowledge from "./MyAcknowledge";
import MyCardTitle from "./MyCardTitle";
import Config from "../config.js";
import MyYesNo from "./MyYesNo";

import isEmpty from "lodash/isEmpty";

import VueCropper from "./VueCropper";

import piexif from "piexifjs";
export default {
  name: "RotatingBannerAdImages",
  components: {
    MyBtn,
    Loading,
    "upload-btn": UploadButton,
    MyAcknowledge,
    VueCropper,
    MyCardTitle,
    MyYesNo,
  },

  props: [],

  computed: {
    showSelectImage() {
      let showSelectImage = false;
      if (
        //"paid" in this.rotatingBannerAd &&
        this.currentNumDraftFiles >= this.maxFiles
      ) {
        showSelectImage = true;
      }
      return showSelectImage;
    },

    showUploadImageBtn() {
      let showBtn = false;
      if (this.draftFiles.length > 0) {
        this.printJson(this.rotatingBannerAd);
        showBtn = true;
      }
      return showBtn;
    },

    showUploadUrlButton() {
      let showBtn = false;
      if ("paid" in this.rotatingBannerAd && this.imageLink.length > 0) {
        showBtn = true;
      }
      return showBtn;
    },
    /*showSelectImageButton() {
      let showSelect = false;
      if (this.rotatingBannerAd !== null || this.rotatingBannerAd !== undefined) {
        if ("savedFiles" in this.rotatingBannerAd && this.rotatingBannerAd.savedFiles.length > 0) {
          showSelect = true;
        }
      }
      return showSelect;
    },*/
    /*   disableCropButton() {
      let disable = false;
      if (
        "savedFiles" in this.rotatingBannerAdOrig &&
        this.rotatingBannerAdOrig !== null
      ) {
        disable = true;
      }
      return disable;
    },*/
  },
  data: () => ({
    bannerId: "",
    bannerIsLiveWait: false,
    cropperIndex: 0,
    cropperKey: "chart0",
    daysLeftText: "",
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    RotatingBannerAdLocal: {},
    mdiCurrencyUsdCircleOutline: mdiCurrencyUsdCircleOutline,
    mdiNotebookCheckOutline: mdiNotebookCheckOutline,
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiArrowRightBoldOutline: mdiArrowRightBoldOutline,
    mdiCashUsdOutline: mdiCashUsdOutline,
    mdiCloseOutline: mdiCloseOutline,
    mdiCloseOctagonOutline: mdiCloseOctagonOutline,
    mdiContentSave: mdiContentSave,
    mdiFormatTitle: mdiFormatTitle,
    mdiPhone: mdiPhone,
    mdiLocationEnter: mdiLocationEnter,
    mdiContentSaveEditOutline: mdiContentSaveEditOutline,
    mdiImagePlus: mdiImagePlus,
    mdiPlusBoxMultipleOutline: mdiPlusBoxMultipleOutline,
    mdiRotateRight: mdiRotateRight,
    mdiSelect: mdiSelect,
    mdiImage: mdiImage,
    mdiCropFree: mdiCropFree,
    mdiDelete: mdiDelete,
    mdiTrashCanOutline: mdiTrashCanOutline,

    isLoading: false,
    messageLoader: "",
    percentageLoader: null,
    // rotatingBannerAd: {},
    collectionProfiles: null,
    accessTokenDecoded: null,
    profileRecord: {},

    showImage: false,
    imageUrl: "", // URL of an already saved rotating image
    imageLink: "", // URL image links to
    imageLinkOrig: "",
    // current state of list of files (unsaved to Kinto database)
    draftFiles: [],
    /* Current state of list of files as absolute URLs for ad (unsaved to
     * Kinto database)
     */
    draftFilesURLs: [],

    // Original ads list of files
    //adsFiles: [],
    // Original list of files as absolute URLs the ad has.
    adsFilesURLs: [],

    currentNumDraftFiles: 0,
    rotationAngles: [],
    maxFiles: 1,
    uploadMultBtnKey: 10,

    acknowledgeMessage: "",
    acknowledgeOk: true,
    acknowledgeTitle: "",
    showAcknowledge: false,
    containerKey: 0,
    rotatingBannerAd: {},
    rotatingBannerAdOrig: null,
    disableCrop: false,

    showCropper: false,
    cropperTitle: "",
    imgSrc: "",
    selectedImageIndex: "",
    selectedImageName: "",

    IMAGE_MAX_WIDTH: Config.IMAGE_MAX_WIDTH,
    IMAGE_MAX_HEIGHT: Config.IMAGE_MAX_HEIGHT,

    showSelectBannerImage: false,

    collectionRotatingBannerAds: null,
    selectedImageUrls: [],
    disableUpload: true,
    disableUploadLink: true,
    showDeleteBtn: false,
    imageCropped: false,
    imageUploaded: false,
    showYesNo: false,
    yesNoTitle: "",
    yesNoMessage: "",
    yesNoCounter: 0,
    yesNoDeleteKey: "deleteBanner0",
    showSelectImageButton: false,
    regex: null,

    rules: {
      required: (value) => value.length > 0 || "Required.",
      positive: (value) =>
        value.length == 0 || parseFloat(value) > 0 || "Must be larger than 0",
      counter30: (value) =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
      urlPattern: (value) => {
        return Config.URL_PATTERN.test(value) || "Invalid URL.";
      },
    },
  }),
  mounted: function () {
    this.init();
  },

  watch: {
    rotatingBannerAd: function () {
      this.containerKey++;
    },
  },

  methods: {
    async init() {
      this.isLoading = true;

      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

      this.draftFiles = [];
      this.adsFilesURLs = [];
      this.draftFilesURLs = [];
      this.currentNumDraftFiles = 0;

      let profilesResult = await this.getProfilesRecord(
        this.accessTokenDecoded.sub
      );

      this.imageUrl = "";
      if (profilesResult.ok === true) {
        this.profileRecord = profilesResult.result.data;

        if ("rotatingBannerAdIds" in this.profileRecord) {
          if (this.profileRecord.rotatingBannerAdIds.length > 0) {
            this.bannerId = this.profileRecord.rotatingBannerAdIds[0];

            let result = await this.getRotatingBannerAd(this.bannerId);

            if (result.ok) {
              this.rotatingBannerAd = result.bannerAd;
              this.printJson(this.rotatingBannerAd, "rotatingBannerAd");

              this.daysLeftText = this.getDaysLeftNice(
                this.rotatingBannerAd.daysLeft,
                this.rotatingBannerAd.lastPublished
              );

              if (
                "image" in this.rotatingBannerAd &&
                this.rotatingBannerAd.image.length > 0
              ) {
                this.imageUrl =
                  Config.BACKEND00_FILES_URL +
                  "/" +
                  this.rotatingBannerAd.image;

                this.image = this.rotatingBannerAd.image;

                this.debug(`imageUrl = ${this.imageUrl}`);
                this.imageUploaded = true;
              } else {
                this.imageUploaded = false;
              }

              if ("link" in this.rotatingBannerAd) {
                this.imageLinkOrig = this.rotatingBannerAd.link;
                this.imageLink = this.imageLinkOrig;
                this.debug(`imageLink = ${this.imageLink}`);
              }
            }
          }
        }
      }

      if (this.rotatingBannerAd !== undefined) {
        this.RotatingBannerAdLocal = this.rotatingBannerAd;
        this.printJson(this.RotatingBannerAdLocal, "rotating banner data");
      }

      this.rotatingBannerAdOrig = this.rotatingBannerAd;

      this.debug(`currentNumDraftFiles = ${this.currentNumDraftFiles}`);

      this.isLoading = false;
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
      this.acknowledgeOk = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.acknowledgeOk = true;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    linkChanged() {
      this.debug(this.imageLink);
      this.debug(this.imageLink.length);
      if (
        this.imageLink === this.imageLinkOrig ||
        this.imageLink.length === 0
      ) {
        this.disableUploadLink = true;
      } else {
        if (this.regex === null) {
          this.regex = new RegExp(Config.URL_PATTERN);
        }
        let matched = this.regex.test(this.imageLink);
        if (matched) {
          this.disableUploadLink = false;
        } else {
          this.disableUploadLink = true;
        }
      }
      this.debug(this.disableUploadLink);
    },

    async updateProfile(rotatingBannerAds) {
      let ok = false;
      const updatedProfile = {
        id: this.accessTokenDecoded.sub,
        rotatingBannerAds: rotatingBannerAds,
      };

      /*let options = {
        patch: true,
      };*/

      try {
        let res = await this.updateProfilesRecord(updatedProfile);
        this.printJson(res, "profile update");
        ok = res.ok;
        this.showDeleteBtn = true;
        this.disableUpload = true;
        this.disableUploadUrl = true;
        this.showSelectImageButton = true;

        this.debug("rotatingBannerAds Updated");
      } catch (e) {
        this.debug("err " + e);
      }
      return ok;
    },

    getUploadBtnColour(index) {
      let colour = "fillColour";
      if (index > this.currentNumDraftFiles) {
        colour = "grey lighten-2";
      }
      return colour;
    },

    getUploadBtnIconColour(index) {
      let colour = "green";
      if (index > this.currentNumDraftFiles) {
        colour = "grey lighten-1";
      }
      return colour;
    },

    getUploadBtnTextColour(index) {
      let colour = "body-1 black--text";
      if (index > this.currentNumDraftFiles) {
        colour = "body-1 grey--text lighten-1";
      }
      return colour;
    },

    getUploadMultBtnColour() {
      let colour = "fillColour";
      if (this.currentNumDraftFiles > 0) {
        colour = "grey lighten-2";
      }
      return colour;
    },

    getUploadMultBtnIconColour() {
      let colour = "green";
      if (this.currentNumDraftFiles >= this.maxFiles) {
        colour = "grey lighten-1";
      }
      return colour;
    },

    getUploadMultBtnTextColour() {
      let colour = "body-1 black--text";
      if (this.currentNumDraftFiles >= this.maxFiles) {
        colour = "body-1 grey--text lighten-1";
      }
      return colour;
    },

    cropPic(index) {
      if (this.draftFilesURLs[index] != null) {
        this.imgSrc = this.draftFilesURLs[index];
        /*   fetch(this.imgSrc)
  .then(res => res.blob()) // Gets the response and returns it as a blob
  .then(blob => {
    // Here's where you get access to the blob
    // And you can use it for whatever you want
    // Like calling ref().put(blob)

    // Here, I use it to make an image appear on the page
    let objectURL = URL.createObjectURL(blob);
    let myImage = new Image();
    myImage.src = objectURL;
      this.debug(myImage);
});*/

        this.cropperTitle = "Crop Image";
        this.selectedImageIndex = index;
        this.selectedImageName = this.draftFiles[index].name;
        this.debug(this.selectedImageName);
        this.showCropper = true;
        this.cropperIndex++;
        this.cropperKey = `croppper${this.cropperIndex}`;
      }
    },
    saveCroppedImage(cropImg) {
      //    this.draftFilesURLs[this.selectedImageIndex] = cropImg;
      // this.debug(this.draftFiles[this.selectedImageIndex], "draft file is the array");
      //let name = `${this.draftFiles[this.selectedImageIndex].name}`;
      let name = this.selectedImageName;
      this.debug(`new name to the file ${name}`);
      let file = this.dataURLToFile(cropImg, name);
      this.removePic(this.selectedImageIndex);
      this.imageCropped = true;
      this.addDraftFileURL(file, this.selectedImageIndex, true);
      this.showCropper = false;
      this.cropperIndex++;
      this.cropperKey = `cropper${this.cropperIndex}`;
      this.containerKey++;
    },

    removePic(index) {
      if (this.draftFilesURLs[index] != null) {
        let i;
        for (i = index; i < this.currentNumDraftFiles - 1; i++) {
          this.draftFiles[i] = this.draftFiles[i + 1];

          /*this.rotationAngles[i] = this.rotationAngles[i + 1];
          this.applyRotationAngle(i);*/

          this.draftFilesURLs[i] = this.draftFilesURLs[i + 1];

          //this.savedFilesSizes[i] = this.savedFilesSizes[i + 1];
          this.debug(`${i + 1} -> ${i}`);
        }

        //this.draftFiles[i] = null;
        this.draftFiles.splice(i, 1);

        //Vue.set(this.draftFilesURLs, i, '')
        this.draftFilesURLs.splice(i, 1);

        //this.rotationAngles.splice(i, 1);
        if (this.rotatingBannerAd !== undefined) {
          if ("savedFiles" in this.rotatingBannerAd) {
            this.RotatingBannerAdLocal["savedFiles"].splice(i, 1);
            this.$emit("rotatingBannerAd-changed", this.RotatingBannerAdLocal);
          }
          /* if ("rotationAngles" in this.rotatingBannerAd) {
            this.rotatingBannerAd["rotationAngles"].splice(i, 1);
          }*/
        }
        //this.savedFilesSizes.splice(i, 1);

        if (this.currentNumDraftFiles == this.maxFiles) {
          // add the pick photo card to the end when we go from 8 to 7 images
          this.draftFilesURLs.push("");
        }

        this.currentNumDraftFiles--;

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    movePicLeft(index) {
      if (index > 0) {
        let left = this.draftFiles[index - 1];
        this.draftFiles[index - 1] = this.draftFiles[index];
        this.draftFiles[index] = left;

        left = this.draftFilesURLs[index - 1];
        this.draftFilesURLs[index - 1] = this.draftFilesURLs[index];

        Vue.set(this.draftFilesURLs, index, left);

        /* let tmpDeg = this.rotationAngles[index - 1];
        this.rotationAngles[index - 1] = this.rotationAngles[index];
        this.rotationAngles[index] = tmpDeg;

        this.applyRotationAngle(index);
        this.applyRotationAngle(index - 1);*/

        /*
        let leftSize = this.savedFilesSizes[index - 1];
        this.savedFilesSizes[index - 1] = this.savedFilesSizes[index];
        this.savedFilesSizes[index] = leftSize;
        */

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    /* applyRotationAngle(index) {
      let img = document.getElementById("image" + index);
      let deg = this.rotationAngles[index];

      img.style.transform = "rotate(" + deg + "deg)";
    },

    applyAllRotationAngles() {
      this.debug(`applyAllRotationAngles a ${this.currentNumDraftFiles}`);
      this.debug(this.rotationAngles);
      for (let i = 0; i < this.currentNumDraftFiles; i++) {
        this.applyRotationAngle(i);
      }

      this.debug("applyAllRotationAngles z");
    },

    rotatePic(index) {
      this.debug("rotatePic a");
      this.rotationAngles[index] = (this.rotationAngles[index] + 90) % 360;
      this.printArray(this.rotationAngles);

      this.debug("rotatePic b");
      this.applyRotationAngle(index);

      this.debug("rotatePic c");
    },*/

    movePicRight(index) {
      if (index < this.currentNumDraftFiles - 1) {
        let right = this.draftFiles[index + 1];
        this.draftFiles[index + 1] = this.draftFiles[index];
        this.draftFiles[index] = right;

        right = this.draftFilesURLs[index + 1];
        this.draftFilesURLs[index + 1] = this.draftFilesURLs[index];

        Vue.set(this.draftFilesURLs, index, right);

        /*let tmpDeg = this.rotationAngles[index + 1];
        this.rotationAngles[index + 1] = this.rotationAngles[index];
        this.rotationAngles[index] = tmpDeg;

        this.applyRotationAngle(index);
        this.applyRotationAngle(index + 1);*/

        /*
        let rightSize = this.savedFilesSizes[index + 1];
        this.savedFilesSizes[index + 1] = this.savedFilesSizes[index];
        this.savedFilesSizes[index] = rightSize;
        */

        this.printArray(this.draftFiles, "draftFiles");
        this.printArray(this.draftFilesURLs, "draftFilesURLs");
      }
    },

    /* async addMultipleDraftFilesURLs(file) {
      this.isLoading = true;

      this.disableCrop = false;
      this.debug("addMultipleDraftFilesURLs");
      this.debug(file);

      if (file != null) {
        let numImagesSelected = 1;

        this.debug("len = " + numImagesSelected);

        let maxImagesRemaining = this.maxFiles - this.currentNumDraftFiles;
        this.debug("maxImagesRemaining = " + maxImagesRemaining);

        let maxImagesToAdd = Math.min(maxImagesRemaining, numImagesSelected);
        this.debug("maxImagesToAdd = " + maxImagesToAdd);

        let draftFilesURLStartIndex = this.currentNumDraftFiles;
        for (let i = 0; i < maxImagesToAdd; i++) {
          let draftFilesURLIndex = draftFilesURLStartIndex + i;

          await this.addDraftFileURL(file, draftFilesURLIndex);
        }
      }

      /* force our multi file uplaod button to clear it's files so if user
       * selects the same file/s, it'll still emit the file-update event
       */
    /* this.disableUpload = false;
      this.uploadMultBtnKey++;

      this.isLoading = false;
    },*/

    async addDraftFileURL(event) {
      let index = 0;
      let file = await this.resizeImage(event);

      this.debug(`addDraftFileURL ${index}`);

      let urlObj = URL.createObjectURL(file);

      this.dummyDataURL = urlObj;

      Vue.set(this.draftFiles, index, file);
      this.printArray(this.draftFiles, "draftFiles");

      // increment currentNumDraftFiles
      if (this.currentNumDraftFiles < this.maxFiles) {
        this.currentNumDraftFiles++;
      }

      // after increment, add another pick photo card only if we < 8
      if (this.currentNumDraftFiles < this.maxFiles) {
        this.draftFilesURLs[this.currentNumDraftFiles] = "";
      }

      Vue.set(this.draftFilesURLs, index, urlObj);

      this.printArray(this.draftFilesURLs, "draftFilesURLs");

      if (this.imageCropped === false) {
        if ((await this.gotEXIF(file)) == true) {
          await this.removeExif(index);
        }
      }
      this.imageCropped = false;

      if (file.size > Config.MAX_FILE_SIZE_BYTES) {
        this.imageCompressIndex = index;
        this.yesNoMessageCompress = `Image ${file.name} is larger than ${Config.MAX_FILE_SIZE_BYTES} bytes. Want to compress image?`;
        this.showYesNoCompress = true;
      }

      while (this.showYesNoCompress) {
        await this.synchSetTimeout(200);
      }

      /*
      if (this.compressed === true) {
        Vue.set(this.savedFilesSizes, index, this.draftFiles[index].size);
        //Vue.set(this.savedFilesSizes, index, file.size);
      }
      */
      this.disableUpload = false;
      this.disableCrop = false;

      //this.rotationAngles[index] = 0;
    },

    async gotEXIF(file) {
      let isExif = false;

      let allMetaData = await this.EXIFGetAllTags(file);
      this.printJson(allMetaData, "allMetaData");

      if (
        "Exif" in allMetaData === true &&
        isEmpty(allMetaData.Exif) === false
      ) {
        isExif = true;
      }

      return isExif;
    },

    async removeExif(index) {
      this.isLoading = true;

      try {
        let fileOrig = this.draftFiles[index];

        let dataURL = await this.readAsDataURLAsync(fileOrig);

        let exifStripped = piexif.remove(dataURL);

        let blob = this.dataURLtoBlob(exifStripped);

        let file = this.blobToFile(blob, fileOrig.name, fileOrig.type);

        this.printJson(blob, "file");

        this.draftFiles[index] = file;

        if ((await this.gotEXIF(file)) == true) {
          //this.setAcknowledgeError("Error: removed EXIF failed");
        } else {
          /*this.setAcknowledge(
            "Success: removed EXIF successfully.",
            "Success!"
          );
          */
          // update draftFilesURLs
          let urlObj = URL.createObjectURL(file);
          Vue.set(this.draftFilesURLs, index, urlObj);
        }
      } catch (e) {
        this.message = e;
        this.showErrorMessage = true;
      }

      this.showYesNoExif = false;

      this.isLoading = false;
    },

    async EXIFGetAllTags(file) {
      this.printJson(file, "file");
      let result = {};
      try {
        let dataURL = await this.readAsDataURLAsync(file);
        result = piexif.load(dataURL);
        this.printJson(result, "EXIFGetAllTags result");
      } catch (e) {
        console.error(e);
      }

      return result;
    },

    /* Resizes image to fit inside a 640 x 480 rectangle
     * If image already fits inside 640 x 480, nothing is changed.
     */
    async resizeImage(origFile) {
      let dataURLOrig = await this.readAsDataURLAsync(origFile);

      const img = await this.syncLoadImage(dataURLOrig);

      let dimensions = this.getDesiredImageDimensions(img);

      const width = dimensions.width;
      const height = dimensions.height;

      let file;

      if (dimensions.resized === true) {
        var canvas = document.getElementById("myCanvas");

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const quality = 1.0;
        const mime = origFile.type;
        const dataURLResized = ctx.canvas.toDataURL(mime, quality);

        file = this.dataURLToFile(dataURLResized, origFile.name);
      } else {
        file = origFile;
      }

      return file;
    },
    readAsDataURLAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },

    syncLoadImage(dataURL) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
          resolve(img);
        };

        img.src = dataURL;
      });
    },

    getDesiredImageDimensions(imageOrig) {
      let newWidth;
      let newHeight;

      let resized = false;

      if (
        imageOrig.width <= this.IMAGE_MAX_WIDTH &&
        imageOrig.height <= this.IMAGE_MAX_HEIGHT
      ) {
        newWidth = imageOrig.width;
        newHeight = imageOrig.height;
        resized = false;
      } else {
        const widthRatio = imageOrig.width / this.IMAGE_MAX_WIDTH;
        const heightRatio = imageOrig.height / this.IMAGE_MAX_HEIGHT;

        /* if image is shrunk, the image width will be the last to fit
         * inside our dimensions, use width to calculate the scaling factor.
         */
        let scalingFactor;
        if (widthRatio > heightRatio) {
          scalingFactor = this.IMAGE_MAX_WIDTH / imageOrig.width;
          newWidth = this.IMAGE_MAX_WIDTH;
          newHeight = scalingFactor * imageOrig.height;
        } else {
          // use the height to calculate scalingFactor
          scalingFactor = this.IMAGE_MAX_HEIGHT / imageOrig.height;
          newHeight = this.IMAGE_MAX_HEIGHT;
          newWidth = scalingFactor * imageOrig.width;
        }

        resized = true;
      }

      return {
        width: newWidth,
        height: newHeight,
        resized: resized,
      };
    },

    dataURLToFile(dataURL, name) {
      let blob = this.dataURLtoBlob(dataURL);
      let file = this.blobToFile(blob, name, blob.type);
      return file;
    },
    // https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
    blobToFile(theBlob, fileName, type) {
      return new File([theBlob], fileName, {
        type: type,
        lastModified: Date.now(),
      });
    },

    //**dataURL to blob**
    dataURLtoBlob(dataURL) {
      var arr = dataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    async getImage(url) {
      let res = await fetch(url);
      this.printJson(res, "image Result ");
    },

    async getDataURL(url, callback) {
      var image = new Image();
      image.src = url;

      image.onload = function () {
        var canvas = document.createElement("myCanvas");
        canvas.width = 640; // or 'width' if you want a special/scaled size
        canvas.height = 480; // or 'height' if you want a special/scaled size

        canvas.getContext("2d").drawImage(image, 0, 0);

        // Get raw image data
        callback(
          canvas
            .toDataURL("image/png")
            .replace(/^data:image\/(png|jpg);base64,/, "")
        );

        // ... or get as Data URI
        callback(canvas.toDataURL("image/png"));
      };
    },

    async saveImage() {
      let ok = false;
      if (await this.isLoggedInOIDC()) {
        if (this.draftFiles.length > 0) {
          this.isLoading = true;
          this.messageLoader = "Checking inputs...";

          if (this.bannerId.length === 0) {
            this.bannerId = await this.getBannerId();
          }

          let uploadFilesResult = await this.uploadFilesForRotatingBannerAd(
            this.draftFiles,
            this.bannerId
          );
          ok = uploadFilesResult.ok;

          if (ok) {
            this.image = uploadFilesResult.savedFile;

            let url =
              Config.NODE_SERVER_IP + Config.UPDATE_ROTATING_BANNER_AD_FIELD;
            let headers = {
              Authorization:
                this.accessTokenDecoded.typ +
                " " +
                this.$store.state.tokens.access_token,
              Accept: "application/json",
            };

            // Build form data
            let payload = new FormData();

            payload.append("field", "image");
            payload.append("value", this.image);
            payload.append("bannerId", this.bannerId);

            try {
              let res = await fetch(url, {
                method: "POST",
                headers: headers,
                body: payload,
              });

              let jsonRes = await res.json();
              ok = jsonRes.ok;

              if (ok === true) {
                this.imageUrl = Config.BACKEND00_FILES_URL + "/" + this.image;

                this.imageUploaded = true;

                this.setAcknowledge("Done.", "Image saved ok");
              } else {
                this.setAcknowledgeError(jsonRes.message);
              }
            } catch (e) {
              this.debug(e);
            }
          } else {
            this.setAcknowledgeError("Upload failed");
          }

          this.containerKey++;
          this.isLoading = false;
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
      return ok;
    },

    async deleteImage() {
      let ok = false;
      this.debug(`bannerId = ${this.bannerId}, image = ${this.image}`);
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";

        let url =
          Config.NODE_SERVER_IP + Config.DELETE_ROTATING_BANNER_AD_IMAGE;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        // Build form data
        let payload = new FormData();

        payload.append("image", this.image);
        payload.append("bannerId", this.bannerId);
        this.debug(`a url = ${url}, image = ${this.image}`);

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "DELETE_ROTATING_BANNER_AD_IMAGE");
          ok = jsonRes.ok;

          if (ok === true) {
            this.image = "";

            // update local copy of rotatingBannerAd
            this.setFalseAdApprovedIsLive();

            this.imageUploaded = false;
            this.uploadMultBtnKey++;

            let index = 0;
            Vue.delete(this.draftFiles, index);
            Vue.delete(this.draftFilesURLs, index);

            this.setAcknowledge("Done.", "Image deleted ok");
            this.currentNumDraftFiles = 0;
            this.disableUpload = true;
            this.debug(`imageUploaded = ${this.imageUploaded}`);
          } else {
            this.setAcknowledgeError(jsonRes.message);
          }
        } catch (e) {
          this.debug(e);
        }

        this.containerKey++;
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
      return ok;
    },

    setFalseAdApprovedIsLive() {
      this.rotatingBannerAd.approved = false;
      this.rotatingBannerAd.isLive = false;
    },

    async uploadFilesForRotatingBannerAd(files, bannerId) {
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.percentageLoader = 0;
      let jsonRes = {
        ok: false,
        savedFiles: [],
      };

      this.debug(`files.length = ${files.length}`);
      //if (files.length > 0) {
      let url =
        Config.NODE_SERVER_IP + Config.UPLOAD_FILES_FOR_ROTATING_BANNER_AD;

      this.printJson(files, "files");

      let headers = {
        Authorization:
          this.accessTokenDecoded.typ +
          " " +
          this.$store.state.tokens.access_token,
        Accept: "application/json",
      };

      // Build form data
      let payload = new FormData();

      // Multipart attachment
      this.messageLoader += "<br/>Batch uploading<br/>";
      let numValidFiles = 0;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file !== undefined && file !== null) {
          this.debug(`uploadeFiles() file ${i}`);
          this.debug(file);
          payload.append(`file${i}`, file);
          payload.append("rotatingBannerAdId", bannerId);
          this.messageLoader += `${i}. ${file.name}<br/>`;
          numValidFiles++;
        }
      }

      this.debug(`numValidFiles = ${numValidFiles}`);

      if (numValidFiles > 0) {
        try {
          jsonRes = await this.synchXHR("POST", url, headers, payload);
          // convert xhrResponse (string) into an Object.
          // jsonRes = JSON.parse(xhrResponse);
          this.printJson(jsonRes);
          this.debug(`upload completed, ${jsonRes}`);
        } catch (e) {
          this.debug(e);
        }
      } else {
        jsonRes = {
          ok: true,
          savedFiles: [],
          //savedFilesSizes: [],
        };
      }

      return jsonRes;
    },

    synchXHR(requestType, url, headers, payload) {
      // use self in place of this inside the Promise() executor
      const self = this;
      self.percentageLoader = 0;
      self.uploadStartTime = Date.now();

      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "json";
        xhr.open(requestType, url);

        for (const header in headers) {
          xhr.setRequestHeader(header, headers[header]);
        }

        xhr.upload.onprogress = function (event) {
          self.debug(`Uploaded ${event.loaded} of ${event.total} bytes`);
          // self.messageLoader += `Uploaded ${event.loaded} of ${event.total} bytes<br/>`;
          let pc = (event.loaded / event.total) * 100.0;
          if (pc > 0) {
            let speed =
              event.loaded / ((Date.now() - self.uploadStartTime) / 1000);
            self.loadData = `Uploaded${event.loaded} of ${
              event.total
            } bytes <br/>${speed} bytes/s<br/>${
              (event.total - event.loaded) / speed
            } seconds remaining`;
          } else {
            self.loadData = `Uploaded${event.loaded} of ${event.total} bytes <br/>Calculating Remaining Time `;
          }
          self.percentageLoader = pc;
          // self.messageLoader += `${pc}% <br/>`;
        };

        xhr.onloadend = function () {
          self.messageLoader += "\nDone! ";
          if (xhr.status == 200) {
            self.debug("xhr.onloadend success");
            self.debug(`${xhr.statusText}`);
            resolve(xhr.response);
          } else {
            self.debug("xhr.onloadend error " + this.status);
            self.debug(`${xhr.statusText}`);
            reject(`${xhr.status} ${xhr.statusText}`);
          }
        };

        xhr.onerror = function () {
          reject(`${xhr.status} ${xhr.statusText}`);
        };

        xhr.send(payload);
      });
    },

    async saveLink() {
      let ok = false;
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";

        this.debug(
          `saveLink() bannerId = ${this.bannerId}, len = ${
            this.bannerId.length
          }, ${typeof this.bannerId}`
        );

        if (this.bannerId.length === 0) {
          this.bannerId = await this.getBannerId();
        }

        let url =
          Config.NODE_SERVER_IP + Config.UPDATE_ROTATING_BANNER_AD_FIELD;
        let headers = {
          Authorization:
            this.accessTokenDecoded.typ +
            " " +
            this.$store.state.tokens.access_token,
          Accept: "application/json",
        };

        // Build form data
        let payload = new FormData();

        payload.append("field", "link");
        payload.append("value", this.imageLink);
        payload.append("bannerId", this.bannerId);
        console.log(`bannerId = ${this.bannerId}`);

        try {
          let res = await fetch(url, {
            method: "POST",
            headers: headers,
            body: payload,
          });

          let jsonRes = await res.json();
          this.printJson(jsonRes, "UPDATE_ROTATING_BANNER_AD_FIELD");
          ok = jsonRes.ok;

          if (ok === true) {
            this.setAcknowledge("Done.", "Image link saved ok");

            // update local copy of rotatingBannerAd
            this.setFalseAdApprovedIsLive();

            this.imageLinkOrig = this.imageLink;
            this.disableUploadLink = true;
          } else {
            this.setAcknowledgeError(
              jsonRes.message,
              "Image link saved failed"
            );
          }
        } catch (e) {
          this.debug(e);
        }

        this.containerKey++;
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
      return ok;
    },

    async saveLinkOrig() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";
        this.debug(this.draftFiles + "ddsvvvvsvss");

        let ok = true;
        if ("paypalData" in this.rotatingBannerAd === false) {
          this.setAcknowledgeError("Please finish the payment");
          ok = false;
        } else if (this.imageLink.length === 0) {
          this.setAcknowledgeError("Please input a valid URL");
          ok = false;
        } else {
          if (
            this.rotatingBannerAdOrig != null &&
            "url" in this.rotatingBannerAdOrig
          ) {
            this.debug("no changes");
            this.printJson(this.rotatingBannerAdOrig);
            if (this.imageLink === this.rotatingBannerAdOrig.url) {
              this.setAcknowledgeError("No changes detected in url");
              this.debug("no changes");
              ok = false;
            }
          }
          //          ok = true;
        }

        if (ok === true) {
          this.messageLoader += "ok<br/>";

          this.RotatingBannerAdLocal["url"] = this.imageLink;
          this.$emit("rotatingBannerAd-changed", this.RotatingBannerAdLocal);

          let rotatingBannerAds = [];
          rotatingBannerAds.push(this.RotatingBannerAdLocal);
          this.printJson(rotatingBannerAds, "to be updated");

          try {
            let res = await this.updateProfile(rotatingBannerAds);
            this.debug(res);
          } catch (e) {
            console.error(e);
          }

          this.containerKey++;
        }
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async showSelectImagesDialog() {
      this.showSelectBannerImage = true;
    },

    async updateRotatingBannerAdImage(image) {
      //let ok = false;
      //TODO: check for the bannerObj for changes, remove current if condition

      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      let isBannerObjectchanged = true;
      let bannerObject = {};
      /* profileId: this.accessTokenDecoded.sub,
        approved: false,
        imageUrl: image,
        URL: this.imageUrl,
      };*/
      if ("bannerObject" in this.rotatingBannerAd) {
        bannerObject = this.rotatingBannerAd.bannerObject;
        this.debug("1");
        if ("imageLink" in this.rotatingBannerAd.bannerObject) {
          if (this.rotatingBannerAd.bannerObject.imageLink === image) {
            this.debug("2");
            isBannerObjectchanged = false;
          }
        }
        if (
          "URL" in this.rotatingBannerAd.bannerObject &&
          isBannerObjectchanged === false
        ) {
          if (this.rotatingBannerAd.bannerObject.URL === this.imageLink) {
            this.debug("3");
            isBannerObjectchanged = false;
          } else {
            isBannerObjectchanged = true;
          }
        }
      } else {
        bannerObject = {
          profileId: this.accessTokenDecoded.sub,
          username: this.accessTokenDecoded.preferred_username,
          approved: false,
          imageLink: image,
          URL: this.imageLink,
        };
      }

      if (isBannerObjectchanged === true) {
        bannerObject["imageLink"] = image;
        bannerObject["URL"] = this.imageLink;
        bannerObject["approved"] = false;
        this.showSelectBannerImage = false;
        this.isLoading = true;
        this.debug("changes detected");
        //update the bannerObject to profile (will be  approved be superadmin after checking)
        this.RotatingBannerAdLocal["bannerObject"] = bannerObject;

        let rotatingBannerAds = [];
        rotatingBannerAds.push(this.RotatingBannerAdLocal);

        try {
          let res = await this.updateProfile(rotatingBannerAds);
          this.debug(res);
          if (res === true) {
            let title = "Rotating Banner Ad - waiting approval";
            let message = `${this.accessTokenDecoded.preferred_username} - (${this.accessTokenDecoded.sub})'s Rotating banner is waiting fr approval`;
            await this.sendDebugEmail(message, title);
          }
        } catch (e) {
          console.error(e);
        }
        this.isLoading = false;
      } else {
        this.debug(" NO changes detected");

        this.setAcknowledgeError("No changes detected");
      }
    },

    removeSelected(index) {
      this.debug(this.selectedImageUrls[index]);
      this.selectedImageUrls.splice(index, 1);
      //this.containerKey;
    },

    async deleteBannerFromProfile() {
      let ok = false;
      try {
        if (await this.isLoggedInOIDC()) {
          this.closeYesno();
          this.isLoading = true;
          this.messageLoader = "Deleting Banner Ads...";
          this.messageLoader += "ok<br/>";
          if ("url" in this.rotatingBannerAd) {
            //this.rotatingBannerAd["url"] = this.imageUrl;
            delete this.RotatingBannerAdLocal["url"];
            this.$emit("rotatingBannerAd-changed", this.RotatingBannerAdLocal);
          }

          if ("savedFiles" in this.rotatingBannerAd) {
            //this.rotatingBannerAd["url"] = this.imageUrl;
            //unlink files using backend00

            delete this.RotatingBannerAdLocal["savedFiles"];

            this.$emit("rotatingBannerAd-changed", this.RotatingBannerAdLocal);
          }

          if ("bannerObject" in this.rotatingBannerAd) {
            delete this.RotatingBannerAdLocal["bannerObject"]["imageLink"];
            if ("URL" in this.RotatingBannerAdLocal["bannerObject"]) {
              delete this.RotatingBannerAdLocal["bannerObject"]["URL"];
            }
            this.RotatingBannerAdLocal["bannerObject"]["approved"] = false;
            this.$emit("rotatingBannerAd-changed", this.RotatingBannerAdLocal);
          }

          let rotatingBannerAds = [];
          rotatingBannerAds.push(this.RotatingBannerAdLocal);

          let res = await this.updateProfile(rotatingBannerAds);

          ok = res;
          this.showDeleteBtn = false;
          this.showSelectImageButton = false;

          await this.init();
          this.containerKey++;
          this.isLoading = false;
        } else {
          this.$router.push(Config.ROUTE_LOGIN);
        }
      } catch (e) {
        console.error(e);
      }
      return ok;
    },

    async deleteBannerImagesFromRotatingBannerAds() {
      let ok = false;
      let message = "";
      try {
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        let updatedRotatingBannerAd = {
          profileId: this.accessTokenDecoded.sub,
          imageUrls: [],
        };
        this.printJson(updatedRotatingBannerAd);
        this.messageLoader += `Updating image to carousel`;
        let result = await this.updateRotatingBannerAd(updatedRotatingBannerAd);
        ok = result.ok;
        message = result.message;
      } catch (e) {
        console.error(e);
      }

      return {
        ok: ok,
        message: message,
      };
    },

    async deleteBanner() {
      if (await this.isLoggedInOIDC()) {
        let res = await this.deleteBannerImagesFromRotatingBannerAds();
        this.debug("dsdsdsdsd" + res);
        if (res.ok === true) {
          let finalRes = await this.deleteBannerFromProfile();
          this.debug(finalRes);
          if (finalRes === true) {
            this.$emit("banner-deleted");
          }
        }
      }
    },

    updateImgSrc(imgSrcUpdated) {
      this.imgSrc = imgSrcUpdated;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    closeVueCropper() {
      this.showCropper = false;
      this.cropperIndex++;
      this.cropperKey = `croppper${this.cropperIndex}`;
    },

    showYesNoDelete() {
      this.yesNoTitle = "Delete the Rotating Banner ad data";
      this.yesNoMessage =
        "If Rotating banner is available in Home page, it will be removed after approval of admin. You can upload an image again before the expiry of payment.";
      this.yesNoCounter++;
      this.yesNoDeleteKey = `deleteBanner${this.yesNoCounter}`;
      this.showYesNo = true;
      // deleteBanner
    },

    closeYesno() {
      this.yesNoCounter++;
      this.yesNoDeleteKey = `deleteBanner${this.yesNoCounter}`;
      this.showYesNo = false;
    },

    async getBannerId() {
      let bannerId = "";
      let profilesResult = await this.getProfilesRecord(
        this.accessTokenDecoded.sub
      );

      if (profilesResult.ok === true) {
        this.profileRecord = profilesResult.result.data;

        if ("rotatingBannerAdIds" in this.profileRecord) {
          if (this.profileRecord.rotatingBannerAdIds.length > 0) {
            bannerId = this.profileRecord.rotatingBannerAdIds[0];
          }
        }
      }
      return bannerId;
    },

    async toggleBannerAdIsLive(bannerId, isLive) {
      this.bannerIsLiveWait = true;

      let ok;

      ok = await this.setBannerAdIsLive(bannerId, !isLive);
      // if it was successful, update our local copy of rotatingBannerAd.isLive
      if (ok) {
        this.rotatingBannerAd.isLive = !isLive;
      }

      this.bannerIsLiveWait = false;
      return ok;
    },
  }, // methods
};
</script>
