<template>
  <v-col>
    <v-row no-gutters>
      <h3 class="text-center">{{ title }}</h3>
      <v-spacer></v-spacer>
      <v-progress-circular
        indeterminate
        v-show="isLoading"
      ></v-progress-circular>
    </v-row>
    <InvoicesTable v-show="!isLoading" :invoices="invoices"></InvoicesTable>
    <span>{{ message }}</span>
  </v-col>
</template>

<script>
import Config from "../config.js";
import InvoicesTable from "./InvoicesTable.vue";
export default {
  name: "InvoiceView",
  components: { InvoicesTable },

  metaInfo: {
    title: "Invoices | Mobility Guru",
    meta: [
      {
        name: "description",
        content: "View invoice, GURU token invoice",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },
  props: [],

  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({
    isLoading: true,
    invoices: [],
    title: "",

    message: "",
    userId: "",
  }),

  beforeMount: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    async init() {
      this.isLoading = true;
      let filters = {};
      let ok = true;
      this.userId = this.$store.state.accessTokenDecoded.sub;
      if (this.$route.query.type == Config.invoiceTypes.SELLER) {
        filters["sellerId"] = this.userId;
        this.title = "Seller Invoices";
      } else if (this.$route.query.type == Config.invoiceTypes.BUYER) {
        filters["buyerId"] = this.userId;
        this.title = "Buyer Invoices";
      } else {
        ok = false;
        this.message = `Invalid user type`;
      }
      if (ok == true) {
        let result = await this.getAllInvoices(filters);
        this.invoices = result.invoices;
        if (result.ok == false) {
          this.message = result.message;
        }
      } else {
        this.message = "";
      }
      this.isLoading = false;
    },
  },
};
</script>
