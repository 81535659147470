<template>
  <div class="locale-changer">
    <v-row no-gutters class="pa-1">
      <!--   <p>{{ $t('language') }}</p>-->
      <select
        v-model="$i18n.locale"
        @change="onSelect"
        style="
          border: 1px solid black !important;
          border-radius: 5px !important;
        "
      >
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ $store.state.languageCodesObject[lang] }}
        </option>
      </select>
      <v-progress-circular
        color="green"
        indeterminate
        v-show="isLoading"
      ></v-progress-circular>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LocaleChanger",
  data() {
    return {
      langs: Object.keys(this.$store.state.languageCodesObject),
      isLoading: false,
    };
  },

  methods: {
    async onSelect(val) {
      this.printJson(val, "Value of change");
      this.debug(`changing to ${this.$i18n.locale}`);
      this.$store.commit("set", ["selectedLanguageCode", this.$i18n.locale]);
      this.isLoading = true;
      await this.loadMessages(this.$i18n.locale);
      this.isLoading = false;
    },
  },
};
</script>
