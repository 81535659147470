<template>
  <v-col
    ref="top"
    class="d-flex justify-center pa-0"
    v-touch="{
      left: () => gotoNextMyAd(),
      right: () => gotoPreviousMyAd(),
    }"
  >
    <v-col :key="adKey" cols="12" lg="10" xl="10" md="11" sm="12">
      <v-row no-gutters justify="center">
        <v-card
          v-if="isLoading || loadingSpinner"
          min-width="320"
          :width="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '800' : '500'
          "
          max-width="900"
          elevation="0"
        >
          <SkeletonLoader type="image,table-heading, article"></SkeletonLoader>
        </v-card>
        <!-- section 1-->
        <v-col
          v-else
          class="pa-0"
          :cols="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? `12` : `6`"
        >
          <ImagesWindows
            :totalViews="totalViews"
            :fileUrls="adsFilesURLs"
          ></ImagesWindows>
          <!--Actions for owner of ad are here -->

          <v-container
            fluid
            v-if="
              this.$store.state.isLoggedIn && (myAds == true || userAds == true)
            "
            style="text-align: start"
          >
            <v-divider></v-divider>
            <h3>{{ $t(`userActions`) }}</h3>

            <v-row align="center" justify="center" no-gutters>
              <v-col>
                <v-row justify="end">
                  <b>
                    <span v-if="published" class="green--text">{{
                      $t(`published`)
                    }}</span>
                    <span v-else class="red--text">{{ $t(`adPaused`) }}</span>
                  </b>
                </v-row>
              </v-col>

              <v-col cols="1"></v-col>

              <v-col>
                <v-switch
                  :disabled="isLoadingAdStatus"
                  class="mt-5"
                  v-model="published"
                  @click="setPublishAd(published)"
                  :label="published == true ? $t(`published`) : $t(`paused`)"
                >
                </v-switch>
                <v-progress-circular
                  class="mt-n12"
                  v-if="isLoadingAdStatus"
                  indeterminate
                  color="green"
                  size="25"
                >
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-divider class="mb-1"></v-divider>
            <v-row
              v-if="accessTokenDecoded.sub === ad.owner"
              justify="center"
              no-gutters
            >
              <v-tooltip top color="green">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="promotebtn"
                    @click="makeTopCategoryAd()"
                    :disabled="
                      isLoadingAdStatus ||
                      paymentStatus === 'COMPLETED' ||
                      paymentStatus === 'PENDING' ||
                      published === false
                    "
                  >
                    <v-icon> &nbsp; {{ mdiPublish }} &nbsp; </v-icon
                    >{{ $t(`click_to_promote`) }}
                  </v-btn>
                </template>
                <span> Puts your ad at top of live ads</span>
              </v-tooltip>
              <span class="caption ml-2" v-show="paymentStatus == 'PENDING'"
                >{{ $t(`paymentPending`) }}
                <v-progress-circular
                  indeterminate
                  class="mr-2 ml-2"
                  v-if="isLoadingPaymentStatus"
                >
                </v-progress-circular>
              </span>
              <v-switch
                :disabled="
                  isLoadingAdStatus ||
                  paymentStatus !== 'COMPLETED' ||
                  published === false
                "
                v-show="topAdActive"
                class="mt-n1 ml-5"
                v-model="topAdPublished"
                @click="setPublishedTopAd(topAdPublished)"
                :label="topAdPublished == true ? $t(`published`) : $t(`paused`)"
              >
              </v-switch>
            </v-row>
            <v-row justify="center" no-gutters v-show="topAdActive">
              <v-col cols="11" lg="6" md="8" sm="11">
                <v-text-field
                  @input="websiteLinkChanged()"
                  outlined
                  v-model="website"
                  :label="$t('websiteLink')"
                  :prefix="imageLinkPrefix"
                >
                  <template v-slot:append-outer>
                    <v-tooltip top :disabled="disableSaveWebsite">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="disableSaveWebsite"
                          class="bg fg--text"
                          v-on="on"
                          @click="saveWebsite()"
                        >
                          <v-icon class="mb-2">{{ mdiContentSave }}</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        <!--{{ $t(`message.website_tooltip`) }}-->{{
                          $t(`saveLink`)
                        }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1" lg="1" md="1" sm="1">
                <v-progress-circular
                  v-if="isLoadingTopAdStatus"
                  indeterminate
                  color="green"
                  size="25"
                >
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-divider class="mb-1"></v-divider>
          </v-container>
        </v-col>
        <v-card
          v-if="isLoading || loadingSpinner"
          min-width="320"
          :width="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '800' : '500'
          "
          max-width="900"
          elevation="0"
        >
          <SkeletonLoader
            type="card-heading, divider, table-heading, list-item-three-line, article"
          ></SkeletonLoader>
        </v-card>

        <!--section 2 -->
        <v-col
          v-else
          class="pl-2"
          :cols="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? `12` : `6`"
        >
          <p
            class="green--text font-weight-bold text-center"
            v-if="topAdPublished"
          >
            {{ $t(`topAd`) }}
            <span v-if="myAds">({{ topAd.type }})</span>
            <a
              v-show="topAd.website.length > 0"
              target="_blank"
              style="text-decoration: none"
              v-else
              :href="`https://${topAd.website}`"
              >{{ $t(`visit`) }}</a
            >
          </p>
          <v-divider v-if="topAdPublished" class=""> </v-divider>
          <span class="headline notranslate pa-0">
            {{ title }}
          </span>

          <span
            v-if="
              ad.mainCategory != JOBS_STR &&
              ad.mainCategory != SERVICES_STR &&
              ad.mainCategory != TRAVEL_STR
            "
            >,
            <b class="pl-2">
              <span class="green--text" v-if="ad.condition == 'new'">{{
                $t(`new`)
              }}</span>
              <span class="blue--text" v-else>{{ $t(`used`) }}</span>
            </b>
          </span>
          <MeSpeak size="40" :text="meSpeakText" class="pl-10"></MeSpeak>

          <div>
            <span class="text-center pa-0 caption">
              {{ $t(`${ad.mainCategory}`) }}
              <span v-if="ad.mainCategory != MISCELLANEOUS_STR">
                <b class="caption pa-0">&rarr;</b>
                {{ $t(`${ad.subCategory}`) }}
              </span>
            </span>
          </div>
          <div>
            <span v-if="ad.mainCategory != JOBS_STR" class="">
              <span class="priceTag pr-2 pl-2"
                ><span>
                  {{ $t(`price`) }} (<span
                    class="notranslate"
                    v-if="'currencyCode' in ad"
                    >{{ ad.currencyCode }}</span
                  >):
                </span>
                <b v-if="ad.priceOption != 'Free'">{{ ad.price }} </b></span
              >
            </span>
            <span class="green--text" v-if="ad.priceOption == 'Negotiable'">
              {{ $t(`Negotiable`) }}</span
            >
            <span class="green--text" v-if="ad.priceOption == 'Free'">{{
              $t(`Free`)
            }}</span>

            <span
              v-if="
                ad.mainCategory == TRAVEL_STR && ad.wheelchairCompliant == true
              "
              class="green--text"
            >
              {{ $t(`wheelchairCompliant`) }}</span
            >
          </div>

          <div v-if="ad.mainCategory == REAL_ESTATE_STR">
            <span v-if="ad.subCategory != WANTED_STR">
              <b>{{ $t(`forSaleBy`) }}: </b>
              {{ ad.forSaleBy }}
              <br />
            </span>
            <b>{{ $t(`startDate`) }}: </b>
            {{ ad.startDate }}

            <br />
            <br />
          </div>

          <div v-if="ad.mainCategory == JOBS_STR">
            <b>{{ $t(`adType`) }}: </b>
            {{ ad.adType }}
            <br />
            <b>{{ $t(`jobType`) }}:</b>
            {{ ad.jobType }}
            <br />
            <div v-if="ad.advertisedBy.length > 0">
              <b>{{ $t(`advertisedBy`) }}:</b>
              {{ ad.advertisedBy }}
              <br />
            </div>
            <div v-if="ad.jobType != VOLUNTEER_STR">
              <b>{{ $t(`salaryType`) }}:</b>
              {{ ad.salaryType }}
              <br />
              <div v-if="ad.salaryType != VOLUNTEER_STR">
                <span class="priceTag">
                  <b
                    >{{ $t(`salary`) }} (<span v-if="'currencyCode' in ad">{{
                      ad.currencyCode
                    }}</span
                    >):</b
                  >
                  ${{ ad.salary }} <br
                /></span>
              </div>
            </div>
            <br />
          </div>

          <p class="mt-1">
            <b>
              <v-icon>{{ mdiGoogleMaps }}</v-icon>
            </b>
            <span class="notranslate" v-html="addressText"></span>
            <br />
          </p>
          <v-row no-gutters class="mb-1">
            <MyBtn
              style="background-color: green !important"
              :iconName="mdiMessage"
              iconColor="white"
              v-if="myAds === false && userAds === false"
              :disabled="messageDisabled || isLoadingchat"
              btnName="messageSeller"
              @btn-clicked="goToMessages('#inquiry')"
              class="mb-1 white--text"
            ></MyBtn>
            <v-progress-circular
              indeterminate
              v-show="isLoadingchat"
            ></v-progress-circular>
            <span
              class="red--text ml-1"
              v-show="messageDisabled && isLoadingchat == false"
            >
              <span v-show="isLoggedIn == false"
                ><a
                  class="red--text"
                  style="text-decoration: none"
                  @click="goToLogin"
                  >{{ $t(`logInToMessage`) }}</a
                >
              </span>
            </span>
          </v-row>

          <v-row no-gutters class="mb-1">
            <MyBtn
              style="background-color: green !important"
              :iconName="mdiReceipt"
              iconColor="white"
              v-if="myAds === false && userAds === false"
              :disabled="messageDisabled || isLoadingchat"
              btnName="request"
              @btn-clicked="goToMessages('#request-invoice')"
              class="mb-1 white--text"
            >
            </MyBtn>
            <MyTooltipInfo
              v-if="myAds === false && userAds === false"
              info="When seller create invoice, you will receive the price in GURU tokens for the service or product. "
            >
            </MyTooltipInfo>
            <span
              class="red--text ml-1"
              v-show="messageDisabled && isLoadingchat == false"
            >
              <span v-show="isLoggedIn == false"
                ><a
                  class="red--text"
                  style="text-decoration: none"
                  @click="goToLogin"
                  >{{ $t(`requestInvoice`) }}</a
                >
              </span>
            </span>
          </v-row>

          <span
            v-if="'username' in ad"
            v-show="isLoggedIn == true && emailDisabled == false"
          >
            <v-icon>{{ mdiEmail }}</v-icon> <b>{{ ad.username }}</b> <br />
          </span>
          <MyBtn
            class="mt-1"
            v-if="myAds === false && userAds === false"
            :disabled="emailDisabled"
            btnName="emailSeller"
            :iconName="mdiEmailSendOutline"
            @btn-clicked="showMyEmail()"
            :key="emailBtnKey"
          >
          </MyBtn>

          <p v-if="isLoggedIn === false" class="red--text">
            {{ $t(`logInToEmailSeller`) }}.
          </p>

          <p
            v-if="accessTokenDecoded.email_verified === false"
            class="red--text"
          >
            {{ $t(`verifyEmail`) }}
          </p>

          <p v-if="accessTokenDecoded.sub === ad.owner" class="red--text">
            {{ $t(`yourOwnAd`) }}
          </p>

          <p
            v-if="showPhone === false && isLoggedIn === false"
            class="red--text"
          >
            {{ $t(`logInToSeePhone`) }}
          </p>

          <p class="mt-2" v-if="showPhone && obscure === false">
            <b>
              <v-icon>{{ mdiPhone }}</v-icon>
            </b>
            {{ ad.phone }}
          </p>

          <v-row no-gutters class="ml-0 mt-2" v-if="showPhone && obscure">
            <p>
              <b>
                <v-icon>{{ mdiPhone }}</v-icon>
              </b>
              {{ ad.phone.substr(0, 3) }}xxxxxxxxx
            </p>
            <MyBtn
              class="ml-5"
              btnName="clickToShow"
              :iconName="mdiEye"
              @btn-clicked="obscure = false"
            ></MyBtn>
          </v-row>

          <p
            v-if="'phone' in ad && accessTokenDecoded.email_verified === false"
            class="red--text"
          >
            {{ $t(`verifyPhone`) }}
          </p>

          <br v-if="obscure" />

          <span v-if="'contactMethod' in ad">
            <b>{{ $t(`contactMethod`) }}</b>
            {{ ad.contactMethod }} <br />
          </span>

          <b
            ><span class="pa-0" v-if="'ownerGivenName' in ad"
              >{{ $t(`seller`) }} {{ ad.ownerGivenName }} <br /></span
          ></b>

          <div v-if="ad.mainCategory == MOBILE_ME_STR">
            <b>{{ $t(`make`) }}</b>
            {{ ad.make }}

            <br />
            <b>{{ $t(`model`) }}</b>
            {{ ad.model }}
            <div v-if="ad.subCategory == BOATS_STR">
              <b>{{ $t(`engineHours`) }}</b>
              {{ ad.engineHours }}
            </div>
            <div v-else>
              <b>{{ $t(`kilometres`) }}</b>
              {{ ad.kilometres }}
            </div>

            <b>{{ $t(`colour`) }}</b>
            {{ ad.colour }}
            <br />
            <b>{{ $t(`year`) }}</b>
            {{ ad.year }}

            <div v-if="'horsepower' in ad && ad.horsepower != ''">
              <b>{{ $t(`hp`) }}</b>
              {{ ad.horsepower }}
            </div>

            <div v-if="'numCylinders' in ad && ad.numCylinders != ''">
              <b>{{ $t(`cylinder`) }}</b>
              {{ ad.numCylinders }}
            </div>

            <div v-if="'engineCapacity' in ad && ad.engineCapacity != ''">
              <b>{{ $t(`capacity`) }}</b>
              {{ ad.engineCapacity }}
            </div>

            <div
              v-show="
                ad.subCategory == 'Wheelchairs' || ad.subCategory == 'Tricycles'
              "
              v-if="'vehicleType' in ad && ad.vehicleType != ''"
            >
              <b>{{ $t(`type`) }} </b>
              {{ ad.vehicleType }}
            </div>
          </div>
          <v-layout>
            <p>
              <b>{{ $t(`description`) }}</b>
              <br />
              <span class="line-break-and-tab notranslate">{{
                description
              }}</span>
              <!--{{ $t(`message.description`) }}<MeSpeak :text="`Description: ${ad.description}`"></MeSpeak>-->
            </p>
          </v-layout>
          <b>
            <span class="caption" v-if="'lastEdited' in ad"
              >{{ $t(`posted`) }} {{ dateLabel }} <br /></span
          ></b>
          <b>
            <span class="caption" v-if="'lastEdited' in ad"
              >{{ $t(`postedCountry`) }} {{ ad.country }} <br /></span
          ></b>
        </v-col>
      </v-row>

      <!-- <h1 class="text-center display-1" ref="top">
        {{ pageTitle }}
       
      </h1>
      <p class="text-center">
        {{ pageCaption
        }}
      </p>
      <br />
      <v-row justify="center" no-gutters class="mt-n5">
        <MeSpeak size="40" :text="meSpeakText"></MeSpeak>
      </v-row>-->
      <!-- <MeSpeak :text="pageCaption"></MeSpeak>-->
      <!--<MeSpeak :text="meSpeakText"></MeSpeak>-->
      <!-- <v-container fluid>
        <v-row justify="space-around">
          <v-card color="transparent" elevation="0">
            <MyBtn
              btnName="Previous Ad"
              :disabled="adsIndex == 0"
              :iconName="mdiSkipPrevious"
              @btn-clicked="gotoPreviousMyAd()"
            ></MyBtn>
          </v-card>

          <div>
            Ad {{ adsIndex + 1 }} of {{ totalAds }}
          </div>

          <v-card color="transparent" elevation="0">
            <MyBtn
              btnName="Next Ad"
              :disabled="adsIndex == totalAds - 1"
              :iconName="mdiSkipNext"
              @btn-clicked="gotoNextMyAd()"
            ></MyBtn>
          </v-card>
        </v-row>
      </v-container>-->
      <!-- $t(`message.next_ad`){{ $t(`message.ad`) }} $t(`message.previous_ad`) <MeSpeak :text="`Ad ${adsIndex + 1} of ${totalAds}`"></MeSpeak>-->

      <!--{{
                  $t(`message.ad_paused`)
                }}{{
                  $t(`message.ad_live`)
                }<MyBtn
            v-if="published"
            btnName="Pause Ad"
            :iconName="mdiPauseOctagonOutline"
            @btn-clicked="setPublishAd(false)"
            :disabled="isLoadingAdStatus"
          ></MyBtn>

          <MyBtn
            v-else
            btnName="Publish Ad"
            :iconName="mdiPublish"
            @btn-clicked="setPublishAd(true)"
            :disabled="isLoadingAdStatus"
          ></MyBtn>-->
      <!--  <v-container
        fluid
        v-if="
          this.$store.state.isLoggedIn && (myAds == true || userAds == true)
        "
        style="text-align: center"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col>
            <v-row justify="end">
              <b>
                <span v-if="published" class="green--text">Live</span>
                <span v-else class="red--text">Ad Paused</span>
              </b>
            </v-row>
          </v-col>

          <v-col cols="1"></v-col>

          <v-col>
           
            <v-switch
              :disabled="isLoadingAdStatus"
              class="mt-5"
              v-model="published"
              @click="setPublishAd(published)"
              :label="published == true ? `Published` : `Paused`"
            >
            </v-switch>
            <v-progress-circular
              class="mt-n12"
              v-if="isLoadingAdStatus"
              indeterminate
              color="green"
              size="25"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-divider class="mb-1"></v-divider>
        <v-row
          v-if="accessTokenDecoded.sub === ad.owner"
          justify="center"
          no-gutters
          ><v-tooltip top color="green">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="promotebtn"
                @click="makeTopCategoryAd()"
                :disabled="
                  isLoadingAdStatus ||
                  paymentStatus === 'COMPLETED' ||
                  paymentStatus === 'PENDING' ||
                  published === false
                "
              >
                <v-icon> &nbsp; {{ mdiPublish }} &nbsp; </v-icon>Click to
                promote ad</v-btn
              >
            </template>
            <span> Puts your ad at top of live ads</span>
          </v-tooltip>
          <span class="caption ml-2" v-show="paymentStatus == 'PENDING'"
            >PENDING payment
            <v-progress-circular
              indeterminate
              class="mr-2 ml-2"
              v-if="isLoadingPaymentStatus"
            ></v-progress-circular
          ></span>
          <v-switch
            :disabled="
              isLoadingAdStatus ||
              paymentStatus !== 'COMPLETED' ||
              published === false
            "
            v-show="topAdActive"
            class="mt-n1 ml-5"
            v-model="topAdPublished"
            @click="setPublishedTopAd(topAdPublished)"
            :label="topAdPublished == true ? `Published` : `Paused`"
          ></v-switch>
         
        </v-row>
        <v-row justify="center" no-gutters v-show="topAdActive">
          <v-col cols="11" lg="6" md="8" sm="11">
            <v-text-field
              @input="websiteLinkChanged()"
              outlined
              v-model="website"
              label="Website Link"
              :prefix="imageLinkPrefix"
            >
              <template v-slot:append-outer>
                <v-tooltip top :disabled="disableSaveWebsite">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="disableSaveWebsite"
                      class="bg fg--text"
                      v-on="on"
                      @click="saveWebsite()"
                    >
                      <v-icon class="mb-2">{{ mdiContentSave }}</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Save Website
                    Link</span
                  >
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1" lg="1" md="1" sm="1">
            <v-progress-circular
              v-if="isLoadingTopAdStatus"
              indeterminate
              color="green"
              size="25"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>-->

      <!-- <p class="green--text font-weight-bold text-center" v-if="topAdPublished">
        Top Ad
        <span v-if="myAds">({{ topAd.type }})</span>
        <a
          v-show="topAd.website.length > 0"
          target="_blank"
          style="text-decoration: none"
          v-else
          :href="`https://${topAd.website}`"
        >
          - Visit Website</a
        >
      </p>
      <v-divider class=""> </v-divider>
      <h2 class="text-center notranslate">
        {{ title }}
   </h2>

      <p class="text-center">
        {{ ad.mainCategory }}
        <span v-if="ad.mainCategory != MISCELLANEOUS_STR">
          <b class="display-1">&rarr;</b>
          {{ ad.subCategory
          }}
        </span>
    
      </p>-->

      <v-container fluid v-show="!isLoading && !loadingSpinner">
        <v-row justify="center" v-if="addressText.length > 0">
          <MyBtn
            btnName="showMap"
            :iconName="showMapIcon"
            @btn-clicked="toggleMap()"
          ></MyBtn>
        </v-row>
        <br />

        <HereMap
          :key="mapKey"
          v-if="showMapBtnText === 'Hide Map' && addressText.length > 0"
          :address="addressText"
        >
        </HereMap>

        <v-layout v-if="userAds === false && myAds === false" justify-center>
          <MyBtn
            :disabled="
              !this.$store.state.isLoggedIn ||
              accessTokenDecoded.sub === ad.owner
            "
            btnName="reportAd"
            :iconName="mdiEmailSendOutline"
            @btn-clicked="reportAd(), debug(userAds), debug(myAds)"
          ></MyBtn>
        </v-layout>
        <br />
        <v-layout justify-center>
          <MyBtn
            btnName="showAllAds"
            :iconName="mdiFileEye"
            @btn-clicked="gotoSellerAds(ad.owner, adType)"
            :disabled="accessTokenDecoded.sub === ad.owner"
          ></MyBtn>
        </v-layout>

        <div v-if="myAds == true || userAds == true">
          <br />
          <v-layout justify-center>
            <MyBtn
              btnName="editAd"
              :iconName="mdiContentSaveEditOutline"
              @btn-clicked="editAd('')"
            ></MyBtn>
          </v-layout>
          <br />

          <v-layout justify-center v-if="userAds === false">
            <MyBtn
              btnName="postAsNew"
              :iconName="mdiRefreshCircle"
              @btn-clicked="editAd('#countrychange')"
            >
            </MyBtn>
          </v-layout>
          <br v-if="userAds === false" />
          <v-layout justify-center>
            <MyBtn
              btnName="delete"
              :iconName="mdiDelete"
              @btn-clicked="showDelAdConfirm = true"
            ></MyBtn>
          </v-layout>
          <br />
          <v-layout v-if="myAds === true && userAds === false" justify-center>
            <MyBtn
              btnName="submitAd"
              :iconName="mdiSend"
              @btn-clicked="adViewSubmitAd()"
            ></MyBtn>
            <br />
          </v-layout>
        </div>
        <br />
        <br />
        <div class="ma-1">
          <v-row justify="center">
            {{ $t(`ads`) }} {{ adsIndex + 1 }} {{ $t(`of`) }}
            {{ totalAds }}
          </v-row>
        </div>
        <br />

        <div>
          <v-row justify="space-around">
            <v-card color="transparent" elevation="0">
              <MyBtn
                btnName="previousAd"
                :disabled="adsIndex == 0"
                :iconName="mdiSkipPrevious"
                @btn-clicked="gotoPreviousMyAd()"
              ></MyBtn>
            </v-card>

            <v-card color="transparent" elevation="0">
              <MyBtn
                btnName="nextAd"
                :disabled="adsIndex == totalAds - 1"
                :iconName="mdiSkipNext"
                @btn-clicked="gotoNextMyAd()"
              ></MyBtn>
            </v-card>
          </v-row>
        </div>

        <div v-if="$vuetify.breakpoint.xs">
          <br />
          <v-row justify="center">{{ $t(`swipe`) }}</v-row>
        </div>
      </v-container>
    </v-col>
    <!--<Loading :isLoading="isLoading"></Loading>-->

    <v-dialog v-model="showDelAdConfirm">
      <v-card>
        <MyCardTitle
          title="deleteAd"
          @close-window="showDelAdConfirm = false"
        ></MyCardTitle>
        <!--$t(`message.confirm_delete_ad`)-->
        <v-layout justify-center>
          <v-btn @click="showDelAdConfirm = false" class="bg fg--text">
            <v-icon left large color="green">{{
              mdiArrowLeftBoldOutline
            }}</v-icon
            >&nbsp; {{ $t(`no`) }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn @click="delAdConfirmYesClicked()" class="bg fg--text">
            <v-icon left large color="red">{{ mdiTrashCanOutline }}</v-icon
            >&nbsp; {{ $t(`yes`) }}
          </v-btn>
        </v-layout>
        <br />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFullScreenImage" scrollable height="screen.height">
      <v-card>
        <MyCardTitle
          :title="title"
          @close-window="showFullScreenImage = false"
        ></MyCardTitle>

        <v-card-text align="center" justify="center">
          <v-img
            :src="adsFilesURLs[adsFilesIndex]"
            :lazy-src="adsFilesURLs[adsFilesIndex]"
            id="imageFullScreen"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="brown"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col></v-col>
            <MyBtn
              :disabled="adsFilesIndex == 0"
              :iconName="mdiSkipPrevious"
              :tooltip="$t(`previousImage`)"
              @btn-clicked="previousPic()"
            ></MyBtn>
            <v-col></v-col>
            <p v-if="adsFilesURLs.length > 0">
              {{ $t(`numImages`) }} {{ adsFilesIndex + 1 }}
              {{ $t(`of`) }}
              {{ adsFilesURLs.length }}
            </p>

            <v-col></v-col>
            <MyBtn
              :disabled="
                adsFilesIndex == adsFilesURLs.length - 1 ||
                adsFilesURLs.length == 0
              "
              :iconName="mdiSkipNext"
              :tooltip="$t(`nextImage`)"
              @btn-clicked="nextPic()"
            ></MyBtn>

            <v-col></v-col>
          </v-row>

          <br />
          <br />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDelReason">
      <v-card>
        <MyCardTitle
          title="reason"
          @close-window="showDelReason = false"
        ></MyCardTitle>
        <br />
        <p>{{ $t(`pickReason`) }}</p>

        <v-container class="px-0">
          <v-radio-group v-model="radioGroup">
            <v-radio
              v-for="(reason, index) in deleteReasons"
              :key="index"
              :label="reason"
              :value="reason"
            ></v-radio>
          </v-radio-group>
        </v-container>
        <!--  <v-textarea`
          class="ma-2"
          label="Reason"
          v-model="reason"
          background-color="fillColour"
          counter="600"
          :rules="[rules.required, rules.description600]"
          auto-grow
          clearable
          filled
          outlined
          solo
        />-->

        <v-layout justify-center>
          <!--<v-btn @click="showDelAdConfirm = false" class="bg fg--text">
            <v-icon left large color="green">{{
              mdiArrowLeftBoldOutline
            }}</v-icon
            >&nbsp; No
          </v-btn>

          <v-spacer></v-spacer>-->

          <v-btn @click="myDeleteAd()" class="bg fg--text">
            <v-icon left large color="red">{{ mdiTrashCanOutline }}</v-icon
            >&nbsp;Delete
          </v-btn>
        </v-layout>
        <br />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showNewAds" width="800">
      <v-card class="fillColour">
        <MyCardTitle
          title="newAds"
          @close-window="showNewAds = false"
        ></MyCardTitle>
        <v-divider></v-divider>
        <br />
        <v-row>
          <v-carousel
            class="fillColour"
            cycle
            height="400"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item v-for="(ad, i) in newAds" :key="i" class="ma-2">
              <v-row justify="center">
                <v-sheet
                  color="white"
                  min-width="250"
                  max-width="300"
                  class="black--text ma-1"
                  hover
                >
                  <br />
                  <AdCell :ad="ad" :adType="adType"></AdCell>
                </v-sheet>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-card>
    </v-dialog>

    <MyEmail
      dialogTitle="Email Seller"
      :isShowMyEmail="isShowMyEmail"
      :from="accessTokenDecoded.preferred_username"
      :fromDisabled="true"
      :adId="ad.id"
      :subject="ad.title"
      :ownerGivenName="ad.ownerGivenName"
      @close-window="closeEmailSeller"
      :key="myEmailKey"
      :to="ad.username"
    ></MyEmail>

    <MyEmail
      dialogTitle="footer.contact"
      :isShowMyEmail="isShowReport"
      :from="accessTokenDecoded.preferred_username"
      :fromDisabled="true"
      :adId="ad.id"
      :subject="'Reporting Ad (Title: ' + ad.title + ')  Ad Id(' + adId + ')'"
      :ownerGivenName="'Anthony'"
      @close-window="closeContactUs"
      :key="myReportKey"
    ></MyEmail>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
      :showActionBtn="showActionBtn"
      :actionName="actionName"
      @action-clicked="goToProfileWithHash('#SetAddress')"
    ></MyAcknowledge>

    <PayPalPopup
      :key="showPayPalPopupBtnKey"
      :isShowPayPal="isShowPayPalPopup"
      @paymentCompleted="paymentCompleted"
      @depayPaymentCompleted="paymentCompleted"
      @paymentCancelled="paymentCancelled"
      @paymentError="paymentError"
      :country="ad.country"
      :mainCategory="ad.mainCategory"
      :subCategory="ad.mainCategory != MISCELLANEOUS_STR ? ad.subCategory : ''"
    ></PayPalPopup>
  </v-col>
</template>

<script>
import {
  mdiAccountRemove,
  mdiArrowExpandAll,
  mdiArrowLeftBoldOutline,
  mdiContentSaveEditOutline,
  mdiEmailSendOutline,
  mdiEye,
  mdiEyeOff,
  mdiPauseOctagonOutline,
  mdiPublish,
  mdiSend,
  mdiSkipNext,
  mdiSkipPrevious,
  mdiTrashCanOutline,
  mdiImageOffOutline,
  mdiFileEye,
  mdiContentSave,
  mdiMessageOutline,
  mdiReceipt,
  mdiGoogleMaps,
  mdiEmail,
  mdiPhone,
  mdiShare,
  mdiHeart,
  mdiHeartOutline,
  mdiDelete,
  mdiRefreshCircle,
} from "@mdi/js";

import Config from "../config.js";

//import Loading from "./Loading";

import MyAcknowledge from "./MyAcknowledge";
import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";
import MyEmail from "./MyEmail";
import AdCell from "./AdCell";
import HereMap from "./HereMap";
import MeSpeak from "./MeSpeak";
import PayPalPopup from "./PayPalPopup.vue";
import MyTooltipInfo from "./MyTooltipInfo.vue";
import ImagesWindows from "./ImagesWindows.vue";
import SkeletonLoader from "../components/SkeletonLoader.vue";

export default {
  name: "AdView",
  components: {
    //Loading,
    MyAcknowledge,
    MyCardTitle,
    MyBtn,
    MyEmail,
    AdCell,
    HereMap,
    MeSpeak,
    PayPalPopup,
    MyTooltipInfo,
    ImagesWindows,
    SkeletonLoader,
  },

  props: [],

  computed: {
    showMerchant() {
      let showMerchant = true;
      //this.debug("Merchant of the add ", this.ad.merchant);
      if (this.ad.merchant === undefined || this.ad.merchant === "") {
        showMerchant = false;
      }

      return showMerchant;
    },

    showPhone() {
      let showPhone = false;
      if ("phone" in this.ad) {
        if ("phonePublic" in this.ad && this.ad.phonePublic === true) {
          showPhone = true;
        } else if ("phonePublic" in this.ad && this.ad.phonePublic === false) {
          if (this.isLoggedIn) {
            if (this.accessTokenDecoded.email_verified === true) {
              showPhone = true;
            }
          }
        }
      } else {
        showPhone = false;
      }
      return showPhone;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.path == Config.ROUTE_WELCOME ||
        from.path == Config.ROUTE_HOME ||
        from.path == Config.ROUTE_SUBMIT_AD ||
        from.path == Config.ROUTE_EDIT_AD
      ) {
        console.log(
          `ad view eoute enter from = ${from.path} ${Config.ROUTE_WELCOME}`
        );
        vm.isInit = true;
        console.log(vm.isInit);
      }
    });
  },

  data: () => ({
    mdiAccountRemove: mdiAccountRemove,
    mdiArrowExpandAll: mdiArrowExpandAll,
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiContentSaveEditOutline: mdiContentSaveEditOutline,
    mdiEmailSendOutline: mdiEmailSendOutline,
    mdiEye: mdiEye,
    mdiEyeOff: mdiEyeOff,
    mdiPauseOctagonOutline: mdiPauseOctagonOutline,
    mdiPublish: mdiPublish,
    mdiSend: mdiSend,
    mdiSkipNext: mdiSkipNext,
    mdiSkipPrevious: mdiSkipPrevious,
    mdiTrashCanOutline: mdiTrashCanOutline,
    mdiImageOffOutline: mdiImageOffOutline,
    mdiFileEye: mdiFileEye,
    mdiContentSave: mdiContentSave,
    mdiMessage: mdiMessageOutline,
    mdiReceipt: mdiReceipt,
    mdiGoogleMaps: mdiGoogleMaps,
    mdiPhone: mdiPhone,
    mdiEmail: mdiEmail,
    mdiShare: mdiShare,
    mdiHeart: mdiHeart,
    mdiHeartOutline: mdiHeartOutline,
    mdiDelete: mdiDelete,
    mdiRefreshCircle: mdiRefreshCircle,

    bucketMobilityGuru: null,
    collectionAds: null,
    pageTitle: "",
    pageCaption: "",

    titleError: "",
    description: "",
    published: false,
    profile: null,
    showErrorMessage: false,
    message: "",

    adId: "",
    myAds: false,
    publicAds: false,
    userAds: false,
    ad: {},
    topAd: {},
    adType: "",

    addressText: "",
    addressTextMespeak: "",

    adsFilesURLs: [],
    adsFilesIndex: 0,

    isLoading: false,
    isLoadingAdStatus: false,

    // only used if in My Ads mode
    allAdIds: [],
    totalAds: 0,
    adsIndex: 0,
    pageIndex: 0,

    JOBS_STR: "Jobs",
    MOBILE_ME_STR: "Mobility Transport",
    REAL_ESTATE_STR: "Real Estate",
    SERVICES_STR: "Services",
    TRAVEL_STR: "Travel",
    MISCELLANEOUS_STR: "Miscellaneous",

    WANTED_STR: "Wanted",

    BOATS_STR: "Boats",

    VOLUNTEER_STR: "Volunteer",

    showFullScreenImage: false,

    showDelAdConfirm: false,

    rotationAngles: [],

    isShowMyEmail: false,
    isShowReport: false,
    reportDisabled: false,

    isLoggedIn: false,
    accessTokenDecoded: {},
    emailDisabled: true,
    messageDisabled: true,

    emailBtnKey: "emailBtn0",
    emailBtnKeyCounter: 2,

    myEmailKey: 100,
    myReportKey: 1,
    myReportBtnKey: "reportBtn0",
    myReportBtnKeyCounter: 0,

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    acknowledgeOk: true,
    myAcknowledgeKey: "MyAcknowledge0",
    myAcknowledgeKeyCounter: 0,

    loadingSpinner: false,
    asPublic: false,
    obscure: true,
    reason: "",
    showDelReason: false,
    newAds: [],
    showNewAds: false,
    mapKey: 0,
    adCounter: 0,
    adKey: "ad0",

    showMapBtnText: "Show Map",
    showMapIcon: mdiEye,
    deleteReasons: [
      "Item sold through MobilityGuru",
      "Item lost",
      "Item sold through another marketplace",
      "Decided I want to keep it",
      "Rather not say",
    ],
    radioGroup: null,

    meSpeakText: "",
    //topCategotyTooltip: "",
    isShowPayPalPopup: false,

    showPayPalPopupBtnKey: "showPayPalPopupBtn0",
    showPayPalPopupBtnKeyCounter: 0,
    topAdActive: false, //true if the payment is not expired
    topAdPublished: false, //can be set by user
    isLoadingTopAdStatus: false,
    website: "",
    disableSaveWebsite: true,
    imageLinkPrefix: "https://",
    paymentStatus: "",
    topAdId: "",
    isInit: false,
    translatedAd: {},
    originalAd: {},
    title: "",
    translated: false,

    showActionBtn: false,
    actionName: "",
    increaseCount: false,
    isLoadingPaymentStatus: false,
    isLoadingchat: false,
    dateLabel: "",
    totalViews: 0,

    rules: {
      required: (value) => value.length > 0 || "Required.",
      positive: (value) =>
        value.length == 0 || parseFloat(value) > 0 || "Must be larger than 0",
      counter30: (value) =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
    },
  }),

  validations: {},

  mounted: async function () {
    await this.init();
  },

  watch: {
    async $route() {
      this.debug(`hash = ${this.$route.hash}`);
      this.isLoading = true;
      // if (this.$route.hash.length > 0) {
      await this.gotoAd();
      this.isLoggedIn = await this.isLoggedInOIDC();
      this.isLoading = false;
      //  }
    },
    /*"$store.state.selectedLanguageCode": async function () {
      await this.init();
    },*/
  },

  methods: {
    async loadMeSpeakText() {
      this.meSpeakText = this.pageTitle + "  " + this.pageCaption + ":";
      this.meSpeakText += `Ad ${this.adsIndex + 1} of ${this.totalAds}: `;

      this.meSpeakText += `Title: ${this.ad.title}: `;
      this.meSpeakText += `main category: ${this.ad.mainCategory}:  sub category: ${this.ad.subCategory}: `;
      if (
        this.$store.state.isLoggedIn &&
        (this.myAds == true || this.userAds == true)
      ) {
        if (this.published === true) {
          this.meSpeakText += `Ad is Live.`;
        } else {
          this.meSpeakText += `Ad is Paused`;
        }
      }
      if (this.ad.mainCategory != this.JOBS_STR) {
        if (this.ad.priceOption != "Free") {
          this.meSpeakText += `Price: ${this.insertSpaces(
            this.ad.currencyCode
          )} : ${this.ad.price}: `;
          if (this.ad.priceOption == "Negotiable") {
            this.meSpeakText += `Negotiable: `;
          }
        } else {
          this.meSpeakText += `Free: `;
        }
      }
      if (
        this.ad.mainCategory != this.JOBS_STR &&
        this.ad.mainCategory != this.SERVICES_STR &&
        this.ad.mainCategory != this.TRAVEL_STR
      ) {
        this.meSpeakText += `Condition: ${this.ad.condition}: `;
      }
      if (
        this.ad.mainCategory == this.TRAVEL_STR &&
        this.ad.wheelchairCompliant == true
      ) {
        this.meSpeakText += `Wheelchair Compliant: `;
      }

      if (this.ad.mainCategory == this.REAL_ESTATE_STR) {
        if (this.ad.subCategory != this.WANTED_STR) {
          this.meSpeakText += `For Sale by : ${this.ad.forSaleBy}: `;
        }
        this.meSpeakText += `Starting date available: ${this.insertSpaces(
          this.ad.startDate
        )}: `;
      }

      if (this.ad.mainCategory == this.JOBS_STR) {
        this.meSpeakText += `Ad Type: ${this.ad.adType}: Job Type: ${this.ad.jobType}: `;
        if (this.ad.advertisedBy.length > 0) {
          this.meSpeakText += `Advertised By: ${this.ad.advertisedBy}: `;
        }

        if (this.ad.jobType != this.VOLUNTEER_STR) {
          this.meSpeakText += `Salary Type: ${this.ad.salaryType}: `;
          if (this.ad.salaryType != this.VOLUNTEER_STR) {
            this.meSpeakText += `Salary : ${this.insertSpaces(
              this.ad.currencyCode
            )} ${this.ad.salary}: `;
          }
        }
      }
      this.meSpeakText += `Address: ${this.addressTextMespeak}: `;

      if (this.isLoggedIn === false) {
        this.meSpeakText += ` Please log in to email seller: `;
      }
      if (this.accessTokenDecoded.email_verified === false) {
        this.meSpeakText += `Please verify your email to email seller: `;
      }
      if (this.accessTokenDecoded.sub === this.ad.owner) {
        this.meSpeakText += ` This is your own ad: `;
      }
      if (this.showPhone === false && this.isLoggedIn === false) {
        this.meSpeakText += `Please log in to see phone: `;
      }
      if (this.showPhone && this.obscure === false) {
        this.meSpeakText += `Phone Number: ${this.insertSpaces(
          this.ad.phone
        )}: `;
      }

      if (
        "phone" in this.ad &&
        this.accessTokenDecoded.email_verified === false
      ) {
        this.meSpeakText += `  Please verify your email to see phone: `;
      }

      if ("contactMethod" in this.ad) {
        this.meSpeakText += `Preferred Contact Method: ${this.ad.contactMethod}: `;
      }

      if ("ownerGivenName" in this.ad) {
        this.meSpeakText += `Seller: ${this.ad.ownerGivenName}: `;
      }
      if (this.ad.mainCategory == this.MOBILE_ME_STR) {
        this.meSpeakText += `Make: ${this.ad.make}: `;
        this.meSpeakText += `Model: ${this.ad.model}: `;
        if (this.ad.subCategory == this.BOATS_STR) {
          this.meSpeakText += `Engine Hours: ${this.ad.engineHours}: `;
        } else {
          this.meSpeakText += `Kilometres: ${this.ad.kilometres}: `;
        }
        this.meSpeakText += `Colour: ${this.ad.colour}: `;
        this.meSpeakText += `Year: ${this.ad.year}: `;

        if ("horsepower" in this.ad && this.ad.horsepower != "") {
          this.meSpeakText += `Horsepower: ${this.ad.horsepower} HP: `;
        }

        if ("numCylinders" in this.ad && this.ad.numCylinders != "") {
          this.meSpeakText += `Number of Cylinders : ${this.ad.numCylinders}: `;
        }

        if ("engineCapacity" in this.ad && this.ad.engineCapacity != "") {
          this.meSpeakText += `Engine Capacity : ${this.ad.engineCapacity} Litres: `;
        }
      }
      this.meSpeakText += `Description: ${this.ad.description}: `;

      this.debug(this.meSpeakText);
    },

    async getAdsIndex(adId) {
      let index = -1;

      /*
      if (this.myAds === true && (await this.isLoggedInOIDC(false))) {
        this.adIds = this.$store.state.myAdIds;
      } else if (this.publicAds === true) {
        this.adIds = this.$store.state.liveAdIds;
      } else if (this.userAds === true && (await this.isLoggedInOIDC(false))) {
        this.adIds = this.$store.state.userAdIds;
      }
      */

      //this.debug(`${typeof this.adIds} ${typeof this.adIds.length} ${this.adIds.length} ${this.adIds}`)
      this.totalAds = this.allAdIds.length;
      this.debug(`getAdsIndex totalAds = ${this.totalAds}`);

      console.log(`totalAds = ${this.totalAds}`);
      for (let i = 0; i < this.totalAds; i++) {
        if (adId == this.allAdIds[i]) {
          index = i;
          break;
        }
      }

      return index;
    },

    applyRotationAngleFullScreen(i) {
      if (this.showFullScreenImage == true) {
        let img = document.getElementById("imageFullScreen");

        let deg = this.rotationAngles[i];

        img.style.transform = "rotate(" + deg + "deg)";

        this.debug("deg = " + deg);

        if (deg == 90) {
          img.style.transform += " translate(24%, 0%)";
        }

        if (deg == 270) {
          img.style.transform += " translate(-24%, 0%)";
        }
      }
    },

    applyRotationAngle(i) {
      let img = document.getElementById("image");

      if (typeof this.rotationAngles === "undefined") {
        let deg = this.rotationAngles[i];

        img.style.transform = "rotate(" + deg + "deg)";
      }
      /*
      if (deg == 90) {
        img.style.transform += ' translate(24%, 0%)'
      }

      if (deg == 270) {
        img.style.transform += ' translate(-24%, 0%)'
      }
      */
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    async showFullScreen() {
      this.showFullScreenImage = true;

      await this.synchSetTimeout(1);

      this.applyRotationAngleFullScreen(this.adsFilesIndex);
    },

    updateEmailDisabledState() {
      this.emailDisabled =
        this.isLoggedIn === false ||
        this.accessTokenDecoded.email_verified === false ||
        this.accessTokenDecoded.sub === this.ad.owner;

      this.emailBtnKeyCounter++;

      this.emailBtnKey = "emailBtn" + this.emailBtnKeyCounter;
    },

    updateMessageSellerDisabledState() {
      this.messageDisabled =
        this.isLoggedIn === false ||
        //  this.accessTokenDecoded.email_verified === false ||
        this.accessTokenDecoded.sub === this.ad.owner;

      //this.emailBtnKeyCounter++;

      // this.emailBtnKey = "emailBtn" + this.emailBtnKeyCounter;
    },

    updateReportDisabledState() {
      this.reportDisabled =
        this.isLoggedIn === false ||
        this.accessTokenDecoded.sub === this.ad.owner;
      this.myReportBtnKeyCounter++;
      this.myReportBtnKey = "reportBtn" + this.myReportBtnKeyCounter;
    },

    async retrieveNewAds() {
      let ok = false;

      if ((await this.isLoggedInOIDC(false)) === true) {
        let url = `${Config.NODE_SERVER_IP}${Config.RETRIEVE_NEW_ADS}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.$store.state.accessTokenDecoded.typ +
              " " +
              this.$store.state.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          let jsonRes = await res.json();

          ok = jsonRes.ok;
          if (ok) {
            this.newAds = jsonRes.ads;
            this.showNewAds = true;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        ok = false;
      }

      return ok;
    },

    async showAd(adId) {
      //this.isLoading = true;
      //Get the ad from backend00
      //TODO: add a condition to hide the ad from if the ad is not published(only for liveAds and visible for myAds and userAds)
      //also if no data recieved (which means ad may be deleted)
      // let { data } = await this.collectionAds.get(adId);
      let adsByOwner = false;
      if (this.myAds == true || this.userAds == true) {
        adsByOwner = true;
      }
      /*if (this.increaseCount == true) {
        this.increaseAdViews(this.adId);
      }*/
      let adRes = await this.getAdRecordByAdId(
        adId,
        this.isInit,
        adsByOwner,
        this.increaseCount
      );
      this.printJson(adRes, `showAd()`);
      this.allAdIds = this.$store.state.allAdIds;
      this.printJson(this.allAdIds, `this.allAdIds`);

      if (adRes.ok === true) {
        this.originalAd = adRes.ad;
        this.translatedAd = adRes.ad;
        this.topAd = adRes.topAd;
        if ("number" in this.originalAd.addressObj) {
          this.addressText =
            this.originalAd.addressObj.number +
            " " +
            this.originalAd.addressObj.street +
            "<br/>" +
            this.originalAd.addressObj.suburb +
            ", " +
            this.originalAd.addressObj.state +
            " " +
            this.originalAd.addressObj.postcode +
            ", " +
            this.originalAd.addressObj.country;

          /*this.addressTextMespeak =
            this.originalAd.addressObj.number +
            " " +
            this.originalAd.addressObj.street +
            " " +
            this.originalAd.addressObj.suburb +
            ", " +
            this.originalAd.addressObj.state +
            " " +
            this.insertSpacesForNumbers(this.originalAd.addressObj.postcode);*/
        } else {
          this.addressText =
            this.originalAd.addressObj.suburb +
            ", " +
            this.originalAd.addressObj.state +
            " " +
            this.originalAd.addressObj.postcode +
            ", " +
            this.originalAd.addressObj.country;

          /*this.addressTextMespeak = this.insertSpacesForNumbers(
            this.addressText
          );*/
        }

        this.originalAd["addressText"] = this.addressText;
        /*if ("locale" in this.originalAd) {
          this.debug(`locale of ad ${this.originalAd["locale"]}`);
          if (this.$i18n.locale !== this.originalAd.locale) {
            this.debug(`starting translation`);
            let adToTranslate = {};
            let keysToRemove = [
              "id",
              "owner",
              "price",
              "ownerGivenName",
              "lastModified",
              "lastEdited",
              "last_modified",
              "last_edited",
              "phone",
              "createdDate",
              "mainCategory",
              "subCategory",
              "addressObj",
              "locale",
              "country",
              "savedFiles",
              "rotationAngles",
              "published",
              "currencyCode",
              "phonePublic",
              "condition",
              "priceOption",
            ];
            for (let key in this.originalAd) {
              if (keysToRemove.includes(key) == false) {
                adToTranslate[key] = this.originalAd[key] + "\n";
              }
            }
            //adToTranslate["title"] = this.ad.title + "\n";
            //adToTranslate["description"] = this.ad.description;
            let resTrans = await this.translateJsonWithSeparator(
              this.originalAd.locale,
              this.$i18n.locale,
              adToTranslate
            );

            if (resTrans.ok == true) {
              let translatedJson = resTrans.translatedJson;
              for (let keyT in translatedJson) {
                this.translatedAd[keyT] = translatedJson[keyT];
              }
              this.ad = this.translatedAd;
              this.addressText = this.ad.addressText;
              this.translated = true;
            } else {
              this.ad = this.originalAd;
            }
            this.debug(`end of translation`);
          } else {
            this.ad = this.originalAd;
          }
        } else {
          this.debug(`ad is the selected locale`);
          this.ad = this.originalAd;
        }*/
        this.ad = this.originalAd;
        this.addressTextMespeak = this.insertSpacesForNumbers(this.addressText);

        this.printJson(this.ad);
        /*
        if ("full" in this.ad.addressObj) {
        this.addressText = this.ad.addressObj.full;

        this.addressTextMespeak = this.insertSpacesForNumbers(this.addressText);
        }
        */
        this.printJson(this.ad, "final ad");

        if ("title" in this.translatedAd) {
          this.title = this.translatedAd.title;
        } else {
          this.title = this.ad.title;
        }
        if ("description" in this.translatedAd) {
          this.description = this.translatedAd.description;
        } else {
          this.description = this.ad.description;
        }
        this.published = this.ad.published;
        //this.topCategotyTooltip = `Puts your ad at top of live ads`;

        if ("id" in this.topAd) {
          this.topAdId = this.topAd.id;
        }
        if ("paymentStatus" in this.topAd) {
          this.paymentStatus = this.topAd.paymentStatus;
          if (
            this.topAd.paymentStatus === "COMPLETED" ||
            this.topAd.paymentStatus === "PENDING"
          ) {
            this.topAdActive = true;
          } else {
            this.topAdActive = false;
          }
        } else {
          this.topAdActive = false;
          this.paymentStatus = "";
        }

        if (
          "topAdPublished" in this.topAd &&
          this.topAd.paymentStatus === "COMPLETED"
        ) {
          this.topAdPublished = this.topAd.topAdPublished;
        } else {
          this.topAdPublished = false;
        }

        if ("website" in this.topAd) {
          this.website = this.topAd.website;
        }

        let baseURL = Config.BACKEND00_FILES_URL;

        this.printJson(this.ad);
        this.adsFilesURLs = [];
        for (let i = 0; i < this.ad.savedFiles.length; i++) {
          this.adsFilesURLs.push(baseURL + "/" + this.ad.savedFiles[i]);
        }
        this.debug("adsFilesURLs");
        this.debug(this.adsFilesURLs);

        if ("rotationAngles" in this.ad) {
          this.rotationAngles = this.ad.rotationAngles;
          this.adsFilesIndex = 0;
          this.applyRotationAngle(this.adsFilesIndex);
        }

        this.updateEmailDisabledState();
        this.updateReportDisabledState();
        this.loadMeSpeakText();
        this.updateMessageSellerDisabledState();
        this.dateLabel = this.calculateDays(this.ad.lastEdited);
        this.mapKey++;

        //polling for topAd paymentStatus from backend00
        if (
          this.myAds === true &&
          this.topAdActive == true &&
          this.paymentStatus == "PENDING"
        ) {
          this.updateTopAdPaymentStatus(this.topAdId);
        }
        if ("totalViews" in this.ad) {
          this.totalViews = this.ad.totalViews;
        }

        this.adCounter++;
        this.adKey = `ad${this.adCounter}`;
      }
      //show top ad features dialog

      if (
        this.myAds === true &&
        this.$route.hash == "#promoteAd" &&
        this.paymentStatus == "" &&
        this.published == true
      ) {
        this.makeTopCategoryAd();
      }
      //this.isLoading = false;
    },

    async updateFavourite() {
      if (await this.isLoggedInOIDC(true)) {
        this.favourite = !this.favourite;
        //update to server
        await this.updateFavouriteAds(this.adId, this.favourite);
        //when this function successfully completed the socket.io will send broadcast message favourited and the related userId will receive it store it
        this.$emit("favourite-removed");
      } else {
        //Code for showing the alert (MyAcknowledge)
        this.acknowledgeMessage = `Please LOGIN or SIGNUP`;
        this.acknowledgeTitle = "Login Required";
        this.showMyAcknowledge();
      }
    },

    async init() {
      this.isLoading = true;
      this.increaseCount = false;

      let start = new Date();

      //this.pageIndex = this.$store.state.pageIndex;

      this.$refs.top.scrollIntoView();

      this.isLoggedIn = await this.isLoggedInOIDC();

      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

      this.adId = this.$route.query.id;

      this.adType = this.$route.query.adType;
      this.obscure = true;

      this.debug(
        `value of this.$route.query.myAds = ${this.$route.query.adType}`
      );

      this.debug(`prevPath = ${this.$store.state.currentPath}`);
      /*if (this.$store.state.currentPath == Config.ROUTE_SUBMIT_AD) {
        await this.retrieveNewAds();
      }*/
      if (this.adType == Config.AD_TYPES_ENUM.MY_ADS) {
        this.myAds = true;
        this.isInit = true;
      } else if (this.adType == Config.AD_TYPES_ENUM.PUBLIC_ADS) {
        this.publicAds = true;
        this.isInit = true;
      } else if (this.adType == Config.AD_TYPES_ENUM.USER_ADS) {
        this.userAds = true;
        this.isInit = true;
      }

      /* if (typeof this.$route.query.myAds === "string") {
        this.debug("myAds is a string");
        this.myAds = this.$route.query.myAds === "true";
      } else if (typeof this.$route.query.myAds === "boolean") {
        this.debug("myAds is a bool");
        this.myAds = this.$route.query.myAds;
      }

      this.debug(
        `adId = ${this.adId}, myAds = ${this.myAds}, ${typeof this
          .myAds}, this.$route.query.myAds = ${
          this.$route.query.myAds
        }, isLoggedIn = ${this.isLoggedIn}`
      );*/
      this.pageCaption = `Hit previous/next buttons to scroll through all Ads`;
      //this.$t("message.adViewCaption");

      if (this.myAds === true && this.isLoggedIn === true) {
        this.debug("init a");
        //this.pageTitle = "My Ads (live & paused)";
        /*this.pageTitle =
          this.$t("message.my_ads") +
          " (" +
          this.$t("message.live") +
          " & " +
          this.$t("message.paused") +
          " )";*/
        this.pageCaption =
          "Hit previous/next buttons to scroll through all your Ads";
        this.bucketMobilityGuru = this.$store.state.bucketMobilityGuru;

        this.collectionAds = this.$store.state.collectionAds;
        this.collectionAdsFiles = this.$store.state.collectionAdsFiles;

        //ToDO: add a method to refresh the ad if we want in changing pages
        //await this.refreshMyAdIds();
      } else if (
        this.adType == Config.AD_TYPES_ENUM.USER_ADS &&
        this.isLoggedIn === true
      ) {
        this.debug("init a");
        this.pageTitle = "User Ads (live & paused)";
        /* this.pageTitle =
          this.$t("message.user_ads") +
          " (" +
          this.$t("message.live") +
          " & " +
          this.$t("message.paused") +
          " )";*/
        this.pageCaption =
          "Hit previous/next buttons to scroll through all user Ads";
        this.bucketMobilityGuru = this.$store.state.bucketMobilityGuru;

        this.collectionAds = this.$store.state.collectionAds;
        this.collectionAdsFiles = this.$store.state.collectionAdsFiles;

        //await this.refreshMyAdIds();
      } else if (
        (this.myAds === true || this.userAds === true) &&
        this.isLoggedIn == false
      ) {
        this.$router.push(Config.ROUTE_LOGIN);
      } else {
        this.pageTitle = "Public Ads";
        this.pageCaption =
          "Hit previous/next buttons to scroll through all public Ads";
        /*this.pageTitle =
          this.$t("message.public_ads") +
          " (" +
          this.$t("message.live") +
          " & " +
          this.$t("message.paused") +
          " )";*/

        await this.mainInit();
        this.bucketMobilityGuru = this.$store.state.bucketMobilityGuru;

        this.collectionAds = this.$store.state.collectionAds;
        this.collectionAdsFiles = this.$store.state.collectionAdsFiles;
        this.increaseCount = true;
      }

      try {
        //  this.isInit = false;
        this.debug(`isInit - ${this.isInit}`);
        await this.showAd(this.adId);

        this.isInit = false;
        this.adsIndex = await this.getAdsIndex(this.adId);

        //this.adCounter++;
        //this.adKey = `ad${this.adCounter}`;
      } catch (e) {
        this.debug("err: " + e);
      }

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`AdView load time = ${diffs} s`);

      this.isLoading = false;
    },

    async setPublishAd(publish) {
      let ok = false;
      //if (publish != this.published) {
      this.isLoading = true;
      this.isLoadingAdStatus = true;

      try {
        let updatedAd = {
          id: this.adId,
          published: publish,
          owner: this.ad.owner,
        };

        /*let options = {
            patch: true,
          };*/

        //await this.collectionAds.update(updatedAd, options);
        let res = await this.updateAd(updatedAd, false);
        ok = res.ok;
        if (ok === false) {
          this.setAcknowledgeError(`Update ad error`);
        } else {
          await this.setPublishedTopAd(publish);
          this.published = publish;
        }
      } catch (e) {
        this.debug("err " + e);
      }

      this.isLoading = false;
      this.isLoadingAdStatus = false;
      //this.$store.commit("set", ["syncedMyAdIds", false]);
      this.$store.commit("set", ["syncedLiveAdIds", false]);
      // }
      return ok;
    },

    async myDeleteAd() {
      let ok;
      if (this.radioGroup === null) {
        this.setAcknowledgeError(`Please select a reason`);
        ok = false;
      } else {
        ok = true;
      }
      if (ok) {
        try {
          this.isLoading = true;
          this.showDelReason = false;
          /* let result = await this.deleteRaffleTicket(this.adId);
        if (result.ok === true) {
          this.debug(`Raffle ticket deleted for this ad ${result.ticket}`);
        } else {
          this.debug(
            `Raffle ticket not exist or this ad is a valid ticket for draw`
          );
        }*/
          let deleteAdRes = await this.deleteAd(
            this.adId,
            this.deleteReasons[this.radioGroup]
          );

          this.acknowledgeTitle = "Delete Ad";

          if (deleteAdRes.ok) {
            // await this.refreshMyAdIds();

            this.isLoading = false;
            this.showDelAdConfirm = false;

            this.acknowledgeMessage = "Ad deleted successfully.";
            this.acknowledgeOk = true;

            this.showMyAcknowledge();

            while (this.showAcknowledge === true) {
              await this.synchSetTimeout(200);
              this.debug(".");
            }
            if (this.adType == Config.AD_TYPES_ENUM.MY_ADS) {
              this.$router.push(Config.ROUTE_MY_ADS);
            } /* else if (this.adType == Config.AD_TYPES_ENUM.PUBLIC_ADS) {
            this.$router.push(Config.ROUTE_LIVE_ADS);
          }*/ else if (this.adType == Config.AD_TYPES_ENUM.USER_ADS) {
              this.$router.push({
                path: Config.ROUTE_USER_ADS,
                query: {
                  userId: this.ad.owner,
                },
              });
            }
          } else {
            this.acknowledgeMessage = deleteAdRes.message;
            this.acknowledgeOk = false;

            this.isLoading = false;
            this.showDelAdConfirm = false;

            this.showMyAcknowledge();
          }
        } catch (e) {
          this.debug("err " + e);
        }
      }
    },

    nextPic() {
      if (this.adsFilesIndex < this.adsFilesURLs.length - 1) {
        this.adsFilesIndex++;
        this.applyRotationAngle(this.adsFilesIndex);

        this.debug("showFullScreenImage = " + this.showFullScreenImage);

        if (this.showFullScreenImage == true) {
          this.applyRotationAngleFullScreen(this.adsFilesIndex);
        }
      }
    },

    previousPic() {
      if (this.adsFilesIndex > 0) {
        this.adsFilesIndex--;
        this.applyRotationAngle(this.adsFilesIndex);

        if (this.showFullScreenImage == true) {
          this.applyRotationAngleFullScreen(this.adsFilesIndex);
        }
      }
    },

    async gotoPreviousMyAd() {
      this.loadingSpinner = true;
      if (this.adsIndex > 0) {
        this.adsIndex--;

        this.adId = this.allAdIds[this.adsIndex];

        this.gotoAdView(this.adId, this.adType);

        this.adsFilesIndex = 0;
        // await this.showAd(this.adId);
      }
      this.loadingSpinner = false;
    },

    async gotoNextMyAd() {
      this.loadingSpinner = true;
      if (this.adsIndex < this.totalAds - 1) {
        this.adsIndex++;

        this.adId = this.allAdIds[this.adsIndex];

        this.gotoAdView(this.adId, this.adType);

        this.adsFilesIndex = 0;
        //  await this.showAd(this.adId);
      }
      this.loadingSpinner = false;
    },

    async gotoAd() {
      this.adId = this.$route.query.id;
      this.printJson(this.allAdIds, "all ad ids");
      for (let i = 0; i < this.totalAds; i++) {
        if (this.allAdIds[i] == this.adId) {
          this.adsIndex = i;
          break;
        }
      }

      this.adsFilesIndex = 0;
      await this.showAd(this.adId);
    },

    editAd(hash = "") {
      this.$router.push({
        path: Config.ROUTE_EDIT_AD + hash,
        query: {
          id: this.adId,
        },
      });
    },

    showMyEmail() {
      this.myEmailKey++;
      this.debug(`myEmailKey = ${this.myEmailKey}`);
      this.isShowMyEmail = true;
    },

    reportAd() {
      this.myReportKey++;
      this.debug(`myReportKey = ${this.myReportKey}`);
      this.isShowReport = true;
    },

    showMyAcknowledge() {
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
      this.showAcknowledge = true;
    },

    toggleMap() {
      if (this.showMapBtnText === "Show Map") {
        this.showMapBtnText = "Hide Map";
        this.showMapIcon = mdiEyeOff;
      } else {
        this.showMapBtnText = "Show Map";
        this.showMapIcon = mdiEye;
      }
    },

    closeEmailSeller() {
      this.isShowMyEmail = false;
      this.myEmailKey++;
    },

    closeContactUs() {
      this.isShowReport = false;
      this.myReportKey++;
    },
    async adViewSubmitAd() {
      let res = await this.isSubmitAdAvailable();
      if (res.ok === true) {
        this.debug(res);
        this.showAcknowledge = true;
        this.acknowledgeTitle = res.acknowledgeTitle;
        this.acknowledgeMessage = res.acknowledgeMessage;
        this.showActionBtn = res.showActionBtn;
        this.actionName = res.actionName;
        this.myAcknowledgeIndex++;
        this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
      }
    },
    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },
    async makeTopCategoryAd() {
      this.isLoggedIn = await this.isLoggedInOIDC();
      if (this.isLoggedIn == true) {
        this.showPayPalPopupBtnKeyCounter++;
        this.showPayPalPopupBtnKey = `showPayPalPopupBtn${this.showPayPalPopupBtnKeyCounter}`;
        this.isShowPayPalPopup = true;
      } else {
        this.acknowledgeMessage = `Login and continue`;
        this.acknowledgeTitle = "Login session expired";
        this.showMyAcknowledge();
      }
    },

    async paymentCompleted(data, selectedCategory, website, paymentType) {
      this.printJson(data, "payment complete data");
      this.isShowPayPalPopup = false;
      this.isLoading = true;
      let record = {
        //payPalData: data[0],
        adId: this.ad.id,
        /*userId: this.ad.owner,
        dateActivated: Date.now(),
        country: this.ad.country,
        topAdPublished: true,
         paymentStatus: "PENDING",*/
        type: selectedCategory,
        website: website,
      };
      //have used all lowercase string
      if (paymentType == "paypal") {
        record["payPalData"] = data[0];
        record["paymentType"] = paymentType;
      } else if (paymentType == "depay") {
        //data["paymentType"] = paymentType;
        //data["guruAmount"] = 0.000001;
        data["token"] = Config.GURU_TOKEN_ADDRESS;
        record["paymentType"] = paymentType;
        record["depayData"] = data;
      }
      if (selectedCategory === "noCategory") {
        record["noCategory"] = true;
        this.acknowledgeMessage = "Your Ad is added to Top (No-Category) Ad";
      } else if (selectedCategory === "mainCategory") {
        record["mainCategory"] = this.ad.mainCategory;
        this.acknowledgeMessage = "Your Ad is added to Top Main Category Ads";
      } else if (selectedCategory === "subCategory") {
        this.acknowledgeMessage = "Your Ad is added to Top Sub Category Ads";

        record["subCategory"] = this.ad.subCategory;
      }
      let res = await this.createTopAd(record);
      this.isLoading = false;
      if (res.ok === true) {
        this.acknowledgeTitle = "Payment Successful!";
        await this.showAd(this.ad.id);
      } else {
        this.acknowledgeMessage = `Available categories of Top Ads; ${res.availableCategories}`;
        this.acknowledgeTitle = "Error on Top Ads update";
      }

      this.isLoading = false;
      this.showMyAcknowledge();
    },

    paymentCancelled: function (data) {
      this.printJson(data, "payment cancelled data");
    },

    paymentError: function (err) {
      this.printJson(err, "payment error data");
    },

    async setPublishedTopAd(published) {
      let updatedAd = {
        id: this.topAdId,
        topAdPublished: published,
        adId: this.ad.id,
        userId: this.ad.owner,
      };
      await this.updateTopAd(updatedAd);
    },

    async saveWebsite() {
      let updatedAd = {
        id: this.topAdId,
        website: this.website,
        adId: this.ad.id,
        userId: this.ad.owner,
      };
      await this.updateTopAd(updatedAd);
      this.ad.website = this.website;
      this.disableSaveWebsite = true;
    },
    async updateTopAd(updatedAd) {
      let ok = false;
      try {
        this.isLoadingTopAdStatus = true;
        let res = await this.updateAd(updatedAd, true);
        ok = res.ok;
        this.isLoadingTopAdStatus = false;
        if (ok === false) {
          this.setAcknowledgeError(`Update ad error`);
          this.topAdPublished = false;
        }
      } catch (e) {
        this.debug("err " + e);
      }

      return ok;
    },

    websiteLinkChanged() {
      if (this.website == this.ad.website || this.website.length == 0) {
        this.disableSaveWebsite = true;
      } else {
        this.disableSaveWebsite = false;
      }
    },

    delAdConfirmYesClicked() {
      this.showDelAdConfirm = false;
      this.showDelReason = true;

      console.log("deleteReasons");
      console.log(this.deleteReasons);
    },

    async updateTopAdPaymentStatus(topAdId) {
      this.isLoadingPaymentStatus = true;
      let timeOutID = setTimeout(async () => {
        let result = await this.getTopAdPaymentStatus(topAdId);
        if (result.ok == true && result.paymentStatus == "COMPLETED") {
          this.debug("payment COMPLETED");
          this.paymentStatus = result.paymentStatus;
          clearTimeout(timeOutID);
          this.isLoadingPaymentStatus = false;
          this.topAdPublished = true;
          this.adCounter++;
          this.adKey = `ad${this.adCounter}`;
        } else {
          this.debug(`waiting for paymentStatus confirmation`);
          await this.updateTopAdPaymentStatus(topAdId);
        }
      }, 30000); //check every 30 seconds until COMPLETED
    },

    async goToMessages(hash = "") {
      if (this.$store.state.isLoggedIn == true) {
        this.isLoadingchat = true;
        let result = await this.initializeChannelInBackend(this.ad.owner);
        this.isLoadingchat = false;
        if (result.ok == true) {
          let query = {
            channelId: result.channelId,
          };
          if (hash !== undefined && hash.length > 0) {
            query["adId"] = this.ad.id;
          }
          await this.pushRoute(Config.ROUTE_MESSAGES, query, hash);
        } else {
          this.acknowledgeMessage = `Error`;
          this.acknowledgeTitle = result.message;
          this.showMyAcknowledge();
        }
      } else {
        this.acknowledgeMessage = `Login and continue`;
        this.acknowledgeTitle = "Login Failed";
        this.showMyAcknowledge();
      }
    },
    goToLogin() {
      this.$router.push(Config.ROUTE_LOGIN);
    },
  },
};

/**
 * <v-row height="500px" justify="center" no-gutters>
          <v-card class="ma-1">
            <!--  <v-card
              height="400px"
              min-width="360px"
              width="400px"
              v-if="
                adsFilesURLs.length != 0 ||
                isLoading == true ||
                loadingSpinner === true
              "
            >
              <v-img
                :src="adsFilesURLs[adsFilesIndex]"
                transition="scale-transition"
                width="400px"
                min-width="360px"
                height="400px"
                contain
                id="image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="brown"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
            <v-card height="400px" min-width="360px" v-else>
              <v-row align="center" justify="center" style="height: 350px">
                <div>
                  <v-icon x-large>{{ mdiImageOffOutline }}</v-icon>
                </div>
              </v-row>
            </v-card>

            <v-card height="37px" min-width="360px">
              <v-row justify="space-around">
                <MyBtn
                  :disabled="adsFilesIndex == 0"
                  :iconName="mdiSkipPrevious"
                  tooltip="Previous Image"
                  @btn-clicked="previousPic()"
                ></MyBtn>

                <p v-if="adsFilesURLs.length > 0">
                  Image {{ adsFilesIndex + 1 }} of
                  {{ adsFilesURLs.length }}
                </p>
                <p v-else>No Image</p>

                <MyBtn
                  :disabled="
                    adsFilesIndex == adsFilesURLs.length - 1 ||
                    adsFilesURLs.length == 0
                  "
                  :iconName="mdiSkipNext"
                  tooltip="Next Image"
                  @btn-clicked="nextPic()"
                ></MyBtn>

                <MyBtn
                  :iconName="mdiArrowExpandAll"
                  :disabled="adsFilesURLs.length == 0"
                  @btn-clicked="showFullScreen()"
                  tooltip="Full Screen"
                ></MyBtn>
              </v-row>
            </v-card>
          </v-card>-->
          <!--{{ $t(`message.negotiable`) }}$t(`message.next_image`) $t(`message.full_screen`){{ $t(`message.price`) }}-->

 * <v-card
            min-width="360px"
            class="ml-12 mt-2"
            elevation="0"
            color="transparent"
          >
            <div>
              <span v-if="ad.mainCategory != JOBS_STR">
                <span class="priceTag pr-2 pl-2"
                  ><span>
                    Price (<span
                      class="notranslate"
                      v-if="'currencyCode' in ad"
                      >{{ ad.currencyCode }}</span
                    >):
                  </span>
                  <b v-if="ad.priceOption != 'Free'"
                    >{{ ad.price }}
                    <!--<MeSpeak
                  :text="`Price: ${insertSpaces('AUD')} : ${ad.price}`"
                ></MeSpeak
              > {{
                $t(`message.free`)
              }}--></b
                  ></span
                >
              </span>
              <span class="green--text" v-if="ad.priceOption == 'Negotiable'">
                Negotiable</span
              >
              <span class="green--text" v-if="ad.priceOption == 'Free'"
                >Free</span
              >

              <p
                v-if="
                  ad.mainCategory != JOBS_STR &&
                  ad.mainCategory != SERVICES_STR &&
                  ad.mainCategory != TRAVEL_STR
                "
              >
                <b>
                  <span class="green--text" v-if="ad.condition == 'new'"
                    >New</span
                  >
                  <span class="blue--text" v-else>Used</span>
                </b>
                <!--{{
                    $t(`message.new`)
                  }<MeSpeak :text="`Condition: ${ad.condition}`"></MeSpeak>-->
              </p>

              <span
                v-if="
                  ad.mainCategory == TRAVEL_STR &&
                  ad.wheelchairCompliant == true
                "
                class="green--text"
              >
                Wheelchair Compliant<!--<MeSpeak
                :text="`Wheelchair Compliant`"
              ></MeSpeak
            >--></span
              >
            </div>

            <div v-if="ad.mainCategory == REAL_ESTATE_STR">
              <span v-if="ad.subCategory != WANTED_STR">
                <b>For Sale By: </b>
                {{ ad.forSaleBy }}
                <!--<MeSpeak :text="`For Sale by : ${ad.forSaleBy}`"></MeSpeak>-->
                <br />
              </span>
              <b>Staring Date Available: </b>
              {{ ad.startDate }}
              <!--<MeSpeak
              :text="`Starting Date Available: ${insertSpaces(ad.startDate)}`"
            ></MeSpeak>-->
              <br />
              <br />
            </div>

            <div v-if="ad.mainCategory == JOBS_STR">
              <b>Ad Type: </b>
              {{ ad.adType }}
              <!--{{ $t(`message.ad_type`) }}<MeSpeak :text="`Ad Type: ${ad.adType}`"></MeSpeak>-->
              <br />
              <b>Job Type:</b>
              {{ ad.jobType }}
              <!--<MeSpeak :text="`Job Type: ${ad.jobType}`"></MeSpeak>-->
              <br />
              <div v-if="ad.advertisedBy.length > 0">
                <b>Advertised By:</b>
                {{ ad.advertisedBy }}
                <!--{{ $t(`message.salary_type`) }} <MeSpeak :text="`Advertised By: ${ad.advertisedBy}`"></MeSpeak>-->
                <br />
              </div>
              <div v-if="ad.jobType != VOLUNTEER_STR">
                <b>Salary Type:</b>
                {{ ad.salaryType }}
                <!-- <MeSpeak :text="`Salary Type: ${ad.salaryType}`"></MeSpeak>-->
                <br />
                <div v-if="ad.salaryType != VOLUNTEER_STR">
                  <kbd class="green">
                    <b
                      >Salary (<span v-if="'currencyCode' in ad">{{
                        ad.currencyCode
                      }}</span
                      >):</b
                    >
                    ${{ ad.salary }}
                    <!--<MeSpeak
                  :text="`Salary: ${insertSpaces(`AUD `)} ${ad.salary}`"
                ></MeSpeak>-->
                    <br
                  /></kbd>
                </div>
              </div>
              <br />
            </div>

            <p>
              <b>Address:</b>
              <br />
              <span class="notranslate" v-html="addressText"></span>
              <!--<MeSpeak :text="addressTextMespeak"></MeSpeak>-->
              <br />
            </p>
            <v-row no-gutters class="mb-1">
              <MyBtn
                style="background-color: green !important"
                :iconName="mdiMessage"
                iconColor="white"
                v-if="myAds === false && userAds === false"
                :disabled="messageDisabled || isLoadingchat"
                btnName="Message Seller"
                @btn-clicked="goToMessages"
                class="mb-1 white--text"
              ></MyBtn>
              <v-progress-circular
                indeterminate
                v-show="isLoadingchat"
              ></v-progress-circular>
              <span
                class="red--text ml-1"
                v-show="messageDisabled && isLoadingchat == false"
              >
                <span v-show="isLoggedIn == false"
                  ><a
                    class="red--text"
                    style="text-decoration: none"
                    @click="goToLogin"
                    >Log in to Message</a
                  >
                </span>
              </span>
            </v-row>

            <v-row no-gutters class="mb-1">
              <MyBtn
                style="background-color: green !important"
                :iconName="mdiReceipt"
                iconColor="white"
                v-if="myAds === false && userAds === false"
                :disabled="messageDisabled || isLoadingchat"
                btnName="Request Invoice"
                @btn-clicked="goToMessages('#request-invoice')"
                class="mb-1 white--text"
              ></MyBtn>
              <MyTooltipInfo
                info="When seller create invoice, you will receive the price in GURU tokens for the service or product. "
              ></MyTooltipInfo>
              <!--  <v-progress-circular
                indeterminate
                v-show="isLoadingchat"
              ></v-progress-circular>-->
              <span
                class="red--text ml-1"
                v-show="messageDisabled && isLoadingchat == false"
              >
                <span v-show="isLoggedIn == false"
                  ><a
                    class="red--text"
                    style="text-decoration: none"
                    @click="goToLogin"
                    >Log in to Request Invoice</a
                  >
                </span>
              </span>
            </v-row>

            <span
              v-if="'username' in ad"
              v-show="isLoggedIn == true && emailDisabled == false"
            >
              Email: <b>{{ ad.username }}</b> <br />
            </span>
            <MyBtn
              class="mt-1"
              v-if="myAds === false && userAds === false"
              :disabled="emailDisabled"
              btnName="Email Seller"
              :iconName="mdiEmailSendOutline"
              @btn-clicked="showMyEmail()"
              :key="emailBtnKey"
            ></MyBtn>

            <p v-if="isLoggedIn === false" class="red--text">
              Please log in to email seller.<!-- {{ $t(`message.login_msg`) }}<MeSpeak
              :text="` Please log in to email seller.`"
            ></MeSpeak>-->
            </p>

            <p
              v-if="accessTokenDecoded.email_verified === false"
              class="red--text"
            >
              Please verify your email to email seller.<!--  {{ $t(`message.email_verify_msg`) }}<MeSpeak
              :text="`Please verify your email to email seller.`"
            ></MeSpeak>-->
            </p>

            <p v-if="accessTokenDecoded.sub === ad.owner" class="red--text">
              This is your own ad.
              <!--{{ $t(`message.own_ad_msg`) }}<MeSpeak :text="` This is your own ad.`"></MeSpeak>-->
            </p>

            <p
              v-if="showPhone === false && isLoggedIn === false"
              class="red--text"
            >
              Please log in to see phone.
              <!--   {{ $t(`message.show_phone_msg`) }}<MeSpeak :text="`Please log in to see phone.`"></MeSpeak>-->
            </p>

            <br />
            <p v-if="showPhone && obscure === false">
              <b>Phone:</b>
              {{ ad.phone }}
              <!-- <MeSpeak
              :text="`Phone Number: ${insertSpaces(ad.phone)}`"
            ></MeSpeak>-->
            </p>

            <br />
            <v-row class="ml-0" v-if="showPhone && obscure">
              <p>
                <b>Phone:</b>
                {{ ad.phone.substr(0, 3) }}xxxxxxxxx
              </p>
              <MyBtn
                class="ml-5"
                btnName="Click to Show"
                :iconName="mdiEye"
                @btn-clicked="obscure = false"
              ></MyBtn>
            </v-row>

            <p
              v-if="
                'phone' in ad && accessTokenDecoded.email_verified === false
              "
              class="red--text"
            >
              Please verify your email to see phone.<!--<MeSpeak
              {{ $t(`message.email_verify1_msg`) }}  :text="`  Please verify your email to see phone.`"
            ></MeSpeak>-->
            </p>

            <br />

            <p v-if="'contactMethod' in ad">
              <b>Preferred Contact Method:</b>
              {{ ad.contactMethod }}
              <!--{{ $t(`message.prefered_contact`) }} <MeSpeak
              :text="`Preferred Contact Method: ${ad.contactMethod}`"
            ></MeSpeak>-->
            </p>

            <p v-if="'ownerGivenName' in ad">
              Seller : {{ ad.ownerGivenName }}
              <!--{{ $t(`message.seller`) }}<MeSpeak :text="`Seller: ${ad.ownerGivenName}`"></MeSpeak>-->
            </p>
            <!--
            <p
            v-if="showMerchant"
            class="body-2 text-no-wrap"
            style="width: 3.8rem;">
            {{ ad.merchant }}
            </p>
            -->

            <div v-if="ad.mainCategory == MOBILE_ME_STR">
              <b>Make:</b>
              {{ ad.make }}
              <!--<MeSpeak :text="`Make: ${ad.make}`"></MeSpeak>-->
              <br />
              <b>Model:</b>
              {{ ad.model
              }}<!--<MeSpeak :text="`Model: ${ad.model}`"></MeSpeak>-->
              <div v-if="ad.subCategory == BOATS_STR">
                <b>Engine Hours:</b>
                {{ ad.engineHours
                }}<!--<MeSpeak :text="`Engine Hours: ${ad.engineHours}`"></MeSpeak>-->
              </div>
              <div v-else>
                <b>Kilometres:</b>
                {{ ad.kilometres }}
                <!--              <MeSpeak :text="`Kilometres: ${ad.kilometres}`"></MeSpeak>-->
              </div>

              <b>Colour:</b>
              {{ ad.colour
              }}<!--<MeSpeak :text="`Colour: ${ad.colour}`"></MeSpeak>-->
              <br />
              <b>Year:</b>
              {{ ad.year }}
              <!--<MeSpeak :text="`Year: ${ad.year}`"></MeSpeak>-->
              <div v-if="'horsepower' in ad && ad.horsepower != ''">
                <b>Horsepower HP:</b>
                {{ ad.horsepower }}
                <!--<MeSpeak :text="`Horsepower: ${ad.horsepower} HP`"></MeSpeak>-->
              </div>

              <div v-if="'numCylinders' in ad && ad.numCylinders != ''">
                <b>Number of Cylinders:</b>
                {{ ad.numCylinders }}
                <!--<MeSpeak
                :text="`Number of Cylinders : ${ad.numCylinders}`"
              ></MeSpeak>-->
              </div>

              <div v-if="'engineCapacity' in ad && ad.engineCapacity != ''">
                <b>Engine Capacity (Litres):</b>
                {{ ad.engineCapacity }}
                <!-- <MeSpeak
                :text="`Engine Capacity : ${ad.engineCapacity} Litres`"
              ></MeSpeak>-->
              </div>
            </div>
          </v-card>
        </v-row>
 */
</script>
