<template>
  <SubmitInvoiceHelper :invoiceId="invoiceId"> </SubmitInvoiceHelper>
</template>

<script>
import SubmitInvoiceHelper from "./SubmitInvoiceHelper";

//import Config from "../config.js";

export default {
  name: "EditInvoice",
  components: {
    SubmitInvoiceHelper,
  },
  props: [],

  //  computed: () => ({
  computed: {},

  //TODO: create collection for each main / sub cat
  data: () => ({
    invoiceId: "",
  }),

  mounted: async function () {
    // if (await this.isLoggedInOIDC()) {
    this.init();
    /*} else {
      this.$router.push(Config.ROUTE_LOGIN);
    }*/
  },

  methods: {
    init() {
      this.invoiceId = this.$route.query.invoiceId;
      this.debug("EditInvoice.vue invoiceෆId = " + this.invoiceId);
    },
  },
};
</script>
