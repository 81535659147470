<template>
  <v-col class="pa-0">
    <v-col cols="12" sm="10">
      <v-text-field
        outlined
        dense
        v-model="search"
        :label="$t('search')"
        clearable
      >
        <template slot="append-outer">
          <MyBtn
            class="mt-n1"
            btnName="search"
            v-if="!$vuetify.breakpoint.xs"
            :iconName="mdiMagnify"
          ></MyBtn>
          <MyBtn class="mt-n1" btnName="" v-else :iconName="mdiMagnify"></MyBtn>
        </template>
      </v-text-field>
    </v-col>
    <v-data-table
      :search="search"
      :headers="tableHeaders"
      :items="invoices"
      :items-per-page="itemsperPageLocal"
      class="elevation-1"
    >
      <template v-slot:[`item.buyer`]="{ item }">
        <span>{{ item.buyer.given_name }}</span>
      </template>
      <template v-slot:[`item.seller`]="{ item }">
        <span>{{ item.seller.given_name }}</span>
      </template>
      <template v-slot:[`item.ad`]="{ item }">
        <a @click="gotoAdView(item.ad.id, 1)">{{ item.ad.title }} </a>
      </template>
      <template v-slot:[`item.view`]="{ item }">
        <a @click="viewInvoice(item.id)">{{ $t(`view`) }}</a>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span class="caption">{{ calculateDays(item.createdDate) }}</span>
      </template>
      <template v-slot:[`item.last_modified`]="{ item }">
        <span class="caption">{{
          calculateDays(new Date(item.last_modified))
        }}</span>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import Config from "../config.js";
import MyBtn from "./MyBtn";
export default {
  name: "IvoicesTable",
  props: ["invoices"],
  components: { MyBtn },
  data: () => ({
    mdiMagnify: mdiMagnify,
    //tableRecords: [],
    tableHeaders: [
      { text: "Invoice Number", value: "invoiceNo" },
      { text: "Buyer", value: "buyer" },
      { text: "Seller", value: "seller" },
      { text: "Ad", value: "ad" },
      { text: "View", value: "view" },
      { text: "Created Date", value: "createdDate" },
      { text: "Last Modified", value: "last_modified" },
    ],
    search: "",
    itemsperPageLocal: 10,
  }),

  mounted: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.printJson(this.tableRecords, "init ");
      if (this.itemsperPage !== undefined) {
        this.itemsperPageLocal = this.itemsperPage;
      }
    },

    async viewInvoice(id) {
      let query = {
        id: id,
      };
      await this.pushRoute(Config.ROUTE_INVOICE, query);
    },
  },
};
</script>
