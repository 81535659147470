<template>
  <v-dialog
    v-model="isShowPayPalLocal"
    transition="scroll-y-reverse-transition"
    open-delay="100"
    max-width="600"
  >
    <v-card v-if="isLoading" height="150" elevation="0" class="mx-auto">
      <br />
      <v-row justify="center" no-gutters>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-row>
    </v-card>
    <v-card v-else class="mx-auto" style="text-align: center">
      <MyCardTitle
        title="Top Ads Feature"
        @close-window="isShowPayPalLocal = false"
      ></MyCardTitle>
      <v-container fluid v-show="availableCategories.length > 0">
        <h2 class="text-center grey rounded white--text">
          {{ $t(`promote`) }}
        </h2>
        <v-row justify="center" no-gutters>
          <v-radio-group v-model="radioGroup" class="ma-1">
            <v-radio
              color="black"
              v-for="(category, index) in availableCategories"
              :key="index"
              :value="category"
              :class="index == 0 ? `red` : index == 1 ? `blue` : `green`"
              class="rounded pa-1"
            >
              <template v-slot:label>
                <v-row
                  no-gutters
                  v-if="category === 'noCategory'"
                  class="ma-1 white--text"
                >
                  <span
                    >{{ $t(`best`) }}
                    {{ parseFloat(noCategoryPrice).toFixed(2) }}
                    {{ currencyCode }} {{ $t(`of`) }} {{ noCategoryPriceGuru }}
                    {{ $t(`guru`) }}
                  </span>
                </v-row>
                <v-row
                  v-if="category === 'mainCategory'"
                  class="ma-1 white--text"
                >
                  <span
                    >{{ $t(`focused`) }}
                    {{ parseFloat(mainCategoryPrice).toFixed(2) }}
                    {{ currencyCode }} {{ $t(`of`) }}
                    {{ mainCategoryPriceGuru }} {{ $t(`guru`) }}</span
                  >
                </v-row>
                <v-row
                  v-if="category === 'subCategory'"
                  class="ma-1 white--text"
                >
                  <span
                    >{{ $t(`most`) }}
                    {{ parseFloat(subCategoryPrice).toFixed(2) }}
                    {{ currencyCode }} {{ $t(`of`) }}
                    {{ subCategoryPriceGuru }} {{ $t(`guru`) }}</span
                  >
                </v-row>
                <!--<v-row>
              <span class="ml-5">{{ category }}</span>
              <v-spacer></v-spacer>
              <v-icon color="fillColour" class="mr-5">mdi-file-edit</v-icon>
            </v-row>-->
              </template>
            </v-radio>
          </v-radio-group>
        </v-row>

        <span class="title rounded grey pa-1 white--text">
          {{ $t(`link`) }}</span
        >
        <v-row justify="center" no-gutters>
          <v-col class="pa-0" cols="12" lg="12" md="12" sm="12">
            <v-text-field
              class="ma-2"
              :prefix="imageLinkPrefix"
              :rules="[rules.description600]"
              v-model="website"
              dense
              outlined
              :label="$t(`link`)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card
          elevation="0"
          v-if="showPayPalBtn === true"
          class="fillColour"
          outlined
        >
          <span class="title rounded fillColour pa-1"
            >{{ $t(`proceed`) }}
            <span class="subtitle1">
              {{ $t(`price`) }} {{ currencyCode }} {{ price }}</span
            ></span
          >
        </v-card>

        <v-row
          justify="center"
          v-if="showPayPalBtn === true"
          no-gutters
          class="mt-2"
        >
          <PayPalCheckout
            :items="myItems"
            :client_id="client_id"
            :product="product"
            :currency="currencyCode"
            @payment-completed="paymentCompleted"
            @payment-cancelled="paymentCancelled"
            @payment-error="paymentError"
          ></PayPalCheckout>
        </v-row>

        <v-card
          elevation="0"
          class="mt-4 fillColour"
          v-if="showPayPalBtn === true"
          outlined
        >
          <span class="title rounded fillColour pa-1"
            >{{ $t(`or`) }} {{ topAdPriceInGuru }} {{ $t(`guru`) }}
          </span>
        </v-card>
        <v-row
          justify="center"
          v-if="showPayPalBtn === true"
          no-gutters
          class=""
        >
          <DepayWidget
            :payment="true"
            :amount="topAdPriceInGuru"
            :showBuyGuru="true"
            @depay-payment-confirmed="depayPaymentConfirmed"
          ></DepayWidget>
        </v-row>
      </v-container>

      <!--<h3 class="text-center">GURU in Process</h3>-->
      <v-row
        no-gutters
        justify="center"
        v-if="availableCategories.length === 0 && !isLoading"
      >
        <span class="red--text"> {{ $t(`no`) }}</span>
      </v-row>
      <br />
      <v-row
        no-gutters
        justify="center"
        class="ma-3 text-center grey rounded white--text"
      >
        <span class="subtitle-1">
          {{ $t(`click`) }}
          <span class="black--text title"
            ><v-icon color="green" large>{{ mdiClose }} </v-icon>
            {{ $t(`close`) }}</span
          >
          {{ $t(`to`) }}
        </span>
      </v-row>
      <br />
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiCloseOutline } from "@mdi/js";
import Config from "../config.js";
import PayPalCheckout from "./PayPalCheckout.vue";
import MyCardTitle from "./MyCardTitle";
import DepayWidget from "./DepayWidget.vue";

//    :key="payPalBtnKey"
export default {
  name: "PayPalPopup",
  props: ["isShowPayPal", "country", "mainCategory", "subCategory"],
  components: { PayPalCheckout, MyCardTitle, DepayWidget },
  data: () => ({
    mdiClose: mdiCloseOutline,
    imageLinkPrefix: "https://",
    website: "",
    radioGroup: null,
    isLoading: true,
    showPayPalBtn: false,
    availableCategories: [],
    availableCategoriesLabels: [],
    rules: {
      required: (value) => value.length > 0 || "Required.",
      positive: (value) =>
        value.length == 0 || parseFloat(value) > 0 || "Must be larger than 0",
      counter30: (value) =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
    },
    priceRecord: {},
    currencyCode: "AUD",
    noCategoryPrice: 20,
    mainCategoryPrice: 15,
    subCategoryPrice: 10,
    noCategoryPriceGuru: 1600,
    mainCategoryPriceGuru: 1200,
    subCategoryPriceGuru: 800,
    topAdPriceInGuru: "",
    price: 20.0,
    myItems: [
      {
        description: "",
        quantity: "1",
        unit_amount: {
          currency_code: "AUD",
          value: "20.00",
        },
        name: "Top Ad",
      },
    ],
    product: {
      price: "20.00",
      description: "",
    },
    /*payPalBtnKey: "payPalBtnKey0",*/
    client_id: "",
    isShowPayPalLocal: false,
    selectedCategory: "",
    payPalIndex: 0,
    payPalKey: "paypal0",
    MISCELLANEOUS_STR: "Miscellaneous",
  }),
  watch: {
    radioGroup: function () {
      this.printJson(this.radioGroup);
      this.clicked(this.radioGroup);
    },

    website: function () {
      this.clicked(this.radioGroup);
    },
  },
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      console.log(`isShowPayPal ()${this.isShowPayPal}`);
      this.isShowPayPalLocal = this.isShowPayPal;
      if (Config.IS_DEV === true) {
        //  this.payPalEnv = "sandbox";
        this.client_id = Config.PAYPAL_SANDBOX_CLIENT_ID;
      } else {
        //this.payPalEnv = "production";
        this.client_id = Config.PAYPAL_PRODUCTION_CLIENT_ID;
      }
      console.log(`client_id ()${this.client_id}`);
      if (this.country !== undefined && this.country.length > 0) {
        let topAdRes = await this.getAvailableTopAdCategories(
          this.country,
          this.mainCategory,
          this.subCategory
        );
        // this.printJson(topAdRes.priceRecord, "topAdRes");
        this.availableCategories = topAdRes.availableCategories;
        this.priceRecord = topAdRes.priceRecord;

        if ("currencyCode" in this.priceRecord) {
          this.currencyCode = this.priceRecord.currencyCode;
        }
        if ("noCategory" in this.priceRecord) {
          this.noCategoryPrice = this.priceRecord.noCategory;
        }
        if ("mainCategory" in this.priceRecord) {
          this.mainCategoryPrice = this.priceRecord.mainCategory;
        }
        if ("subCategory" in this.priceRecord) {
          this.subCategoryPrice = this.priceRecord.subCategory;
        }

        if ("noCategoryPriceGuru" in this.priceRecord) {
          this.noCategoryPriceGuru = this.priceRecord.noCategoryPriceGuru;
        }
        if ("mainCategoryPriceGuru" in this.priceRecord) {
          this.mainCategoryPriceGuru = this.priceRecord.mainCategoryPriceGuru;
        }
        if ("subCategoryPriceGuru" in this.priceRecord) {
          this.subCategoryPriceGuru = this.priceRecord.subCategoryPriceGuru;
        }
        this.printJson(this.availableCategories, "availableCategories");
        if (this.mainCategory == this.MISCELLANEOUS_STR) {
          this.availableCategories.splice(
            this.availableCategories.indexOf("subCategory"),
            1
          );
        }
        this.printJson(this.priceRecord, "priceRecord");
      }
      /*if (this.availableCategories.length > 0) {
        
      }*/
      console.log(`init ()${this.isLoading}`);
      await this.synchSetTimeout(1);
      this.isLoading = false;
    },

    paymentCompleted: function (data) {
      //this.printJson(data, "payment complete data");

      this.$emit(
        "paymentCompleted",
        data,
        this.selectedCategory,
        this.website,
        "paypal"
      );
      this.isShowPayPalLocal = false;
    },

    paymentCancelled: function (data) {
      //this.printJson(data, "payment cancelled data");
      this.$emit("paymentCancelled", data);
    },

    paymentError: function (err) {
      // this.printJson(err, "payment error data");
      this.$emit("paymentError", err);
    },
    clicked(selectedOption) {
      if (selectedOption !== null /*&& this.website.length > 0*/) {
        this.debug(selectedOption);
        this.selectedCategory = selectedOption;
        let description = "";
        //let price = "";
        if (selectedOption === "noCategory") {
          description =
            "Top Ad above all live ads(without categories selected) payment";
          this.price = this.noCategoryPrice;
          this.topAdPriceInGuru = this.noCategoryPriceGuru;
        } else if (selectedOption === "mainCategory") {
          description =
            "Top Ad above all selected main category live ads payment";
          this.price = this.mainCategoryPrice;
          this.topAdPriceInGuru = this.mainCategoryPriceGuru;
        } else if (selectedOption === "subCategory") {
          description =
            "Top Ad above all selected sub category live ads payment";
          this.price = this.subCategoryPrice;
          this.topAdPriceInGuru = this.subCategoryPriceGuru;
        }

        this.myItems[0].description = description;
        this.myItems[0]["unit_amount"].value = this.price;
        this.myItems[0]["unit_amount"].currency_code = this.currencyCode;
        this.printJson(this.myItems);
        this.product.description = description;
        this.product.price = this.price;
        //this.payPalIndex++;
        //this.payPalKey = `paypal${this.payPalIndex}`;
        this.showPayPalBtn = true;
      } else {
        this.showPayPalBtn = false;
      }
    },

    depayPaymentConfirmed(transaction) {
      transaction["guruAmount"] = this.topAdPriceInGuru;
      this.$emit(
        "depayPaymentCompleted",
        transaction,
        this.selectedCategory,
        this.website,
        "depay"
      );
      this.isShowPayPalLocal = false;
    },
  },
};
</script>
