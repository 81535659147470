<template>
  <v-col class="pa-0">
    <v-carousel-item>
      <v-card
        elevation="0"
        id="imageCarousel"
        class="fillColour"
        min-width="320"
      >
        <a href="https://www.ceylonexpeditions.com/" target="_blank">
          <v-img
            contain
            id="imageCarousel"
            :src="ceylonExpeditions"
            transition="scale-transition"
            alt=""
            ><v-btn dark x-small color="blue-grey darken-2" class="mgcenter"
              ><span>{{ $t(`clickVisit`) }}</span></v-btn
            ></v-img
          >
        </a>
      </v-card>
    </v-carousel-item>
    <!-- <v-carousel-item>
      <v-card
        class="mx-auto"
        color="fillColour"
        elevation="0"
        :height="$vuetify.breakpoint.xs ? '' : '400'"
      >
        <v-row no-gutters :class="$vuetify.breakpoint.xs ? '' : 'pl-12'">
          <v-col
            style="
              background-color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 6px solid #9c27b0 !important;
            "
            :cols="$vuetify.breakpoint.xs ? '12' : '6'"
            class="pa-0"
          >
            <v-img
              :height="$vuetify.breakpoint.xs ? '300' : '380'"
              :src="homeBanner1"
              transition="scale-transition"
              v-if="$vuetify.breakpoint.xs"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              ><v-overlay
                absolute
                :value="true"
                color="white"
                style="background: rgba(255, 250, 250, 0.6); font-size: 20"
              >
                <v-card elevation="0" color="transparent" class="black--text">
                  <h3 class="purple--text mb-1">ADVERTISE NOW</h3>
                  <h3 class="red--text mb-1 mt-1">SEARCH NOW</h3>
                  <h3 class="light-blue--text mb-1 mt-1">
                    <b class="notranslate"> 😍 </b>Disability Items
                  </h3>
                  <h3 class="deep-orange--text mb-1 mt-1">
                    <b class="notranslate"> 😍 </b>
                    Disability Services
                  </h3>
                  <h3 class="light-green--text mb-1 mt-1">
                    <b class="notranslate"> 😍 </b>
                    Buy/Sell New & Used
                  </h3>
                  <h3 class="blue--text mt-2">
                    <b class="notranslate"> 😍 </b>
                    Wheelchair Friendly Facilities
                  </h3>

                  <h3 class="green--text mb-2 mt-2">
                    <b class="notranslate"> 😍 </b>Independent & Happy
                  </h3>
                </v-card>
              </v-overlay>
            </v-img>

            <v-card v-else elevation="0" color="white" class="black--text">
              <h2 class="purple--text mb-2">ADVERTISE NOW</h2>
              <h2 class="red--text mb-2 mt-2">SEARCH NOW</h2>
              <h2 class="light-blue--text mb-2 mt-2">
                <b class="notranslate"> 😍 </b>Disability Items
              </h2>
              <h2 class="deep-orange--text mb-2 mt-2">
                <b class="notranslate"> 😍 </b>Disability Services
              </h2>
              <h2 class="light-green--text mb-2 mt-2">
                <b class="notranslate"> 😍 </b>Buy/Sell New & Used
              </h2>
              <h2 class="blue--text mt-2">
                <b class="notranslate"> 😍 </b>Wheelchair Friendly Facilities
              </h2>
              <h2 class="green--text mb-2 mt-2">
                <b class="notranslate"> 😍 </b>Independent & Happy
              </h2>
            </v-card>
          </v-col>
          <v-col cols="6" class="pa-0" v-show="!$vuetify.breakpoint.xs">
            <v-card class="mx-auto" tile elevation="0" color="transparent">
              <v-img
                transition="scale-transition"
                :height="$vuetify.breakpoint.xs ? '300' : '380'"
                :src="homeBanner1"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-carousel-item>-->

    <!--<v-carousel-item>
      <v-col class="pa-0" id="imageCarousel">
        <v-img
          contain
          id="imageCarousel"
          min-width="320"
          :src="basketball"
        ></v-img> </v-col
    ></v-carousel-item>-->
    <!-- <v-carousel-item>
      <v-card
        class="mx-auto"
        color="white"
        elevation="0"
        :height="$vuetify.breakpoint.xs ? '300' : '400'"
      >
        <v-row no-gutters :class="$vuetify.breakpoint.xs ? '' : 'pl-12'">
          <v-col
            style="display: flex; justify-content: center; align-items: center"
            class="pa-0"
          >-->
    <!--   :cols="$vuetify.breakpoint.xs ? '12' : '6'"
          <v-img
              v-if="$vuetify.breakpoint.xs"
              :height="$vuetify.breakpoint.xs ? '300' : '380'"
              :src="iconsBanner"
              :lazy-src="iconsBanner"
              contain
            ></v-img>-->

    <!--<v-card
              v-if="$vuetify.breakpoint.xs"
              class="ma-3"
              outlined
              color="fillColour"
              shaped
            >
              <v-col align-self="center">
                <br />
                <v-row justify="center" no-gutters class="mb-1">
                  <MyBtn
                    style="background-color: green !important"
                    iconColor="white"
                    btnName="MESSAGE SELLER"
                    class="mb-1 white--text"
                  ></MyBtn>
                  <MyTooltipInfo
                    info="Now you can message sellers directly in our website."
                  ></MyTooltipInfo>
                </v-row>
                <br /><br />
                <v-spacer></v-spacer>
                <v-row justify="center" no-gutters>
                  <span class="blue--text title"> For any information </span>
                </v-row>
                <br /><br />
                <v-spacer></v-spacer>
                <v-row justify="center" no-gutters>
                  <span class="title"> Login & MESSAGE </span>
                </v-row>
              </v-col>
            </v-card>-->
    <!-- <v-img
              :height="$vuetify.breakpoint.xs ? '300' : '380'"
              contain
              :src="iconsBanner"
              :lazy-src="iconsBanner"
           
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              ><v-overlay
                absolute
                :value="true"
                color="white"
                style="background: rgba(255, 250, 250, 0.6); font-size: 20"
              >
                <v-card elevation="0" color="transparent" class="black--text">
                  <v-img
                    style="background: rgba(255, 250, 250, 0.6); font-size: 20"
                    :height="$vuetify.breakpoint.xs ? '300' : '380'"
                    :src="basketball"
                    :lazy-src="basketball"
                  >
                  </v-img>
                <h2 class="noun">Advertise & Earn GURU</h2>
                  <br v-if="$store.state.selectedLanguageCode !== 'ta'" />
                  <h3 class="mb-3 font-weight-bold">
                    <b class="notranslate"> 🔥 </b> Submit 1 ad = no cost
                  </h3>
                  <br v-if="$store.state.selectedLanguageCode == 'en'" />
                  <h3 class="mb-3 font-weight-bold">
                    <b class="notranslate"> 🔥 </b> Submit 20 ads = no cost
                  </h3>
                  <br v-if="$store.state.selectedLanguageCode == 'en'" />
                  <span class="mb-3">Advertise disability items only</span>
                  <br />
                  <br v-if="$store.state.selectedLanguageCode == 'en'" />
                  <span class="mb-3">
                    <b class="notranslate"> 😍 </b> Support MobilityGuru
                    Community <b class="notranslate"> 😍 </b></span
                  >
                </v-card>
              </v-overlay>
            </v-img>
          v-else
          -->

    <!-- <v-card elevation="0" color="white" class="black--text">
              <v-img
                :height="
                  $vuetify.breakpoint.xs
                    ? '300'
                    : $vuetify.breakpoint.sm
                    ? '320'
                    : '380'
                "
                :src="basketball"
                :lazy-src="basketball"
              ></v-img>-->
    <!--<h2>Advertise & Earn GURU</h2>
              <br />
              <h3 class="mb-3 font-weight-bold">
                <b class="notranslate"> 🔥 </b>Submit 1 ad = no cost
              </h3>
              <br />
              <h3 class="mb-3 font-weight-bold">
                <b class="notranslate"> 🔥 </b> Submit 20 ads = no cost
              </h3>
              <br />
              <span class="mb-3">Advertise disability items only</span>
              <br />
              <br />
              <span class="mb-3">
                <b class="notranslate"> 😍 </b> Support MobilityGuru Community
                <b class="notranslate"> 😍 </b></span
              >
            </v-card>
          </v-col>-->
    <!-- <v-col
            cols="6"
            class="pa-0"
            v-show="!$vuetify.breakpoint.xs"
            align-self="center"
          >
            <v-card class="ma-3" outlined color="fillColour" shaped>
              <v-col align-self="center">
                <br />
                <v-row
                  justify="center"
                  no-gutters
                  class="mb-1"
                  v-if="!$vuetify.breakpoint.xs"
                >
                  <MyBtn
                    style="background-color: green !important"
                    iconColor="white"
                    btnName="MESSAGE SELLER"
                    class="mb-1 white--text"
                  ></MyBtn>
                  <MyTooltipInfo
                    info="Now you can message sellers directly in our website."
                  ></MyTooltipInfo>
                </v-row>
                <br /><br />
                <v-spacer></v-spacer>
                <v-row justify="center" no-gutters>
                  <span class="blue--text title"> For any information </span>
                </v-row>
                <br /><br />
                <v-spacer></v-spacer>
                <v-row justify="center" no-gutters>
                  <span class="title"> Login & MESSAGE </span>
                </v-row>
              </v-col>
            </v-card>-->
    <!--  <v-card class="max-auto" elevation="0" color="transparent">
            <v-img
                :height="$vuetify.breakpoint.xs ? '300' : '380'"
                contain
                :src="iconsBanner"
                :lazy-src="iconsBanner"
              >
              </v-img>
            </v-card></v-col>
        </v-row>
      </v-card>
    </v-carousel-item>-->
  </v-col>
</template>
<script>
import iconsBanner from "./pics/iconsBanner.jpg";
import homeBanner1 from "./pics/homeBanner1.jpg";
import importedBasketball from "./pics/MobilityGuru Basketball_90.jpg";
import ceylonExpeditions from "./pics/ceylonExpeditions.jpg";

//import MyTooltipInfo from "../components/MyTooltipInfo.vue";
//import MyBtn from "../components/MyBtn.vue";

export default {
  name: "GlobalBanner",
  components: {
    /*MyTooltipInfo, MyBtn*/
  },
  data: () => ({
    iconsBanner: iconsBanner,
    basketball: importedBasketball,
    homeBanner1: homeBanner1,
    ceylonExpeditions: ceylonExpeditions,
  }),
};
</script>
