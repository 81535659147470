<template>
  <div class="ml-2 mb-2 mt-1">
    <v-row no-gutters>
      <v-icon class="mt-n1" large> {{ mdiAccountCircle }} </v-icon>
      <h4 class="ml-2">{{ post.owner }}</h4>
      <p class="ml-2" v-if="$route.query.category == undefined">
        {{ $t(`postedIn`) }}
        <b v-if="'category' in localPost">{{ localPost.category }}</b>
      </p>
      <p v-else>&nbsp;</p>
      <v-spacer></v-spacer>
      <!-- <p class="mr-10 mt-3 ">{{post.createdDate}}</p>
      <v-icon class="mr-10" color="black-darken" medium @click="51">mdi-dots-vertical</v-icon>-->
    </v-row>

    <v-container fluid>
      <v-card elevation="0" min-width="280px" class="mt-n2 mr-7">
        <b v-if="'title' in post" class="ml-1">{{ localPost.title }}</b>
        <br />
        <p v-if="'message' in post" class="ml-2">{{ localPost.message }}</p>

        <v-row class="ml-3 mt-n3" no-gutters>
          <span v-if="showReply">
            <a class="text-right" @click="replyPost()">{{ $t(`reply`) }}</a>
          </span>
          <a v-if="showEdit" class="text-right ml-5" @click="editPost()">{{
            $t(`edit`)
          }}</a>
          <a v-if="showReport" class="text-right ml-5" @click="handleClick">{{
            $t(`report`)
          }}</a>
          <v-spacer></v-spacer>
          <span class="mr-5" style="font-size: 13px">{{
            this.calculateDays(localPost.createdDate)
          }}</span>
          <a
            style="font-size: 13px"
            v-if="numOfReplies !== 0 && showReplies === false"
            @click="loadReplies"
            class="mr-5"
            >{{ $t(`show`) }} ({{ numOfReplies }})
            <v-icon color="blue"> {{ mdiArrowDown }} </v-icon></a
          >
          <a
            style="font-size: 13px"
            v-if="showReplies === true"
            @click="showReplies = !showReplies"
            class="mr-5"
            >{{ $t(`hide`) }}
            <v-icon color="blue"> {{ mdiArrowUp }} </v-icon></a
          >
        </v-row>
        <v-container v-if="showReplies" fluid>
          <v-card
            v-for="(reply, index) in this.localPost.replies"
            :key="index"
            min-width="280px"
            max-width="1000px"
            elevation="0"
            outlined
            class="ma-1"
          >
            <!--  <ReplyView :reply="reply" :postOrig="postOrig"></ReplyView>-->
            <v-row class="ml-5" no-gutters>
              <v-icon> {{ mdiAccountCircle }} </v-icon>
              <h4 v-if="'owner' in reply" class="ml-2">
                {{ reply.owner }}
              </h4>
            </v-row>

            <span
              v-if="'reply' in reply"
              class="ml-16"
              v-text="reply.reply"
            ></span>

            <v-row class="ml-10" no-gutters>
              <a
                v-if="
                  $store.state.isLoggedIn &&
                  accessTokenDecoded.sub == reply.postedBy
                "
                class="ml-8"
                @click="replyPost()"
                >{{ $t(`edit`) }}</a
              >
            </v-row>
          </v-card>
          <v-row justify="center" no-gutters>
            <v-progress-circular
              indeterminate
              v-if="isloadingReplies"
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mdiAccountCircle, mdiArrowDown, mdiArrowUp } from "@mdi/js";

import Config from "../config.js";
//import isEmpty from "lodash/isEmpty";

export default {
  name: "PostView",
  components: {},
  props: ["post"],

  //  computed: () => ({
  computed: {
    showEdit() {
      let editPost = false;
      if (
        this.post.postedBy == this.accessTokenDecoded.sub ||
        this.isSuperAdmin === true ||
        this.isForumModerator
      ) {
        editPost = true;
      }
      this.debug(`EditPost = ${editPost}`);
      return editPost;
    },
    showReport() {
      let reportTopic = false;
      if (
        this.post.postedBy == this.accessTokenDecoded.sub ||
        this.isSuperAdmin === true ||
        this.isForumModerator
      ) {
        reportTopic = false;
      } else {
        reportTopic = true;
      }
      this.debug(`reportTopic = ${reportTopic}`);

      return reportTopic;
    },
    showReply() {
      let replyTopic = this.$store.state.isLoggedIn;
      this.debug(`replyTopic = ${replyTopic}`);

      return replyTopic;
    },

    /*  replyExist() {
      let numReplies;
      if ("replies" in this.post ) {
        numReplies = true;
      }
      return numReplies;
    }*/
  },

  //TODO: create collection for each main / sub cat
  data: () => ({
    mdiArrowDown: mdiArrowDown,
    mdiArrowUp: mdiArrowUp,
    mdiAccountCircle: mdiAccountCircle,

    collectionForum: null,
    accessTokenDecoded: {},
    postid: "",
    isSuperAdmin: false,
    isForumModerator: false,
    numOfReplies: 0,
    showReplies: false,
    isloadingReplies: false,
    localPost: {},
  }),

  validations: {},

  mounted: async function () {
    await this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    async init() {
      // await this.mainInit();
      //let mustBeLoggedIn = this.$store.state.isLoggedIn;
      this.localPost = this.post;
      this.postId = this.localPost.id;
      this.showReplies = false;
      /*if (isEmpty(this.post.replies)) {
        this.numOfReplies = 0;
      } else {
        this.numOfReplies = this.post.replies.length;
      }*/
      if ("numOfReplies" in this.post) {
        this.numOfReplies = this.post.numOfReplies;
      }

      /*  this.isLoading = true;
        this.collectionForum = this.$store.state.collectionForum;
        this.printJson(collectionForum,"1111111111111111");*/
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.isForumModerator = this.$store.state.isForumModerator;
      this.debug("Super Admin", this.isSuperAdmin);

      //this.numOfReplies = this.post.replies.length;
      this.debug("Length of the replies in the Post", this.numOfReplies);
    },

    handleClick() {
      this.debug("reply");
    },

    async editPost() {
      this.postId = this.post.id;
      this.debug(`post Id = ${this.postId}`);
      await this.$router.push({
        path: Config.ROUTE_POST_NEW_TOPIC,
        query: {
          postId: this.postId,
        },
      });
    },

    async replyPost() {
      this.postId = this.post.id;
      let reply = true;
      this.debug(`post Id = ${this.postId}`);
      await this.$router.push({
        path: Config.ROUTE_POST_NEW_TOPIC,
        query: {
          postId: this.postId,
          reply: reply,
        },
      });
    },

    async loadReplies() {
      this.isloadingReplies = true;

      this.showReplies = !this.showReplies;
      let res = await this.getForumPostById(this.postId);
      if (res.ok == true) {
        this.localPost = res.post;
      }
      this.isloadingReplies = false;
    },
  }, // methods
};
</script>
