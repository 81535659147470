<template>
  <span>
    <v-img
      :height="heightOrig"
      :width="widthOrig"
      contain
      :alt="`${check}`"
      v-if="check === true"
      :src="checkSvg"
    ></v-img>
    <v-img
      :height="heightOrig"
      :width="widthOrig"
      contain
      :alt="`${check}`"
      v-else
      :src="crossSvg"
    ></v-img>
  </span>
</template>
<script>
import checkSvg from "./pics/check.svg";
import crossSvg from "./pics/cross.svg";

export default {
  name: "CheckIcon",
  props: ["check", "height", "width"],
  mounted: function () {
    this.init();
  },
  data: () => ({
    checkSvg: checkSvg,
    crossSvg: crossSvg,
    heightOrig: 30,
    widthOrig: 50,
  }),
  methods: {
    init() {
      if (this.height !== undefined) {
        this.heightOrig = this.height;
      }

      if (this.width !== undefined) {
        this.widthOrig = this.width;
      }
    },
  },
};
</script>
