<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>invoice</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>

    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            If want to <strong>Request Invoice</strong> of the product, firsly
            you want to choose the relavent Ad from the
            <strong>Homepage Random Ads </strong> area or
            <strong>Filter</strong> in
            <a href="https://mobilityguru.org/home">Homepage</a> filter section
            as below.
            <MeSpeak
              text="If want to request invoice of the product, firsly you want to choose the relavent Ad from the Homepage Random Ads area or filter in homepage filter section as
            below."
            >
            </MeSpeak>
            <br />
            <ul>
              <v-img
                :src="require(`./pics/adsPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="adsPc"
              />
              <v-img
                :src="require(`./pics/adsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="adsMob"
              />
            </ul>
            <br />
          </li>
          <li>
            After choosing the <strong>Ad</strong> You will directing to the
            <strong>Relevant Ad</strong> view page.
            <MeSpeak
              text="
            After choosing the Ad You will directing to the Relevant Ad view page."
            ></MeSpeak>
          </li>
          <br />

          <li>
            Now you can see the <strong>Request Invoice</strong> Button there.
            <MeSpeak
              text="
            Now you can see the Request Invoice Button there."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/invoicebtnPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="invoicebtnPc"
              />
              <v-img
                :src="require(`./pics/invoicebtnMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="invoicebtnMob"
              />
            </ul>
          </li>
          <li>
            After clicking <strong>Request Invoice</strong> button. you will
            directing to the <strong>Request Invoice chat</strong> and
            automatically generate a <strong>Request Invoice message</strong>.
            <MeSpeak
              text="
           After clicking Request Invoice button. you will directing to the Request Invoice chat and automatically generate a Request Invoice message."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/invoicemsgPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="invoicemsgPc"
              />
              <v-img
                :src="require(`./pics/invoicemsgMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="invoicemsgMob"
              />
            </ul>
            <br />
          </li>
          <br />
          <li>
            As a Seller you can see <strong>Notification</strong> in profile
            button. and after you clicking this button, you will directing to
            the <strong>Main Menu</strong>. There you can see
            <strong>My Messages</strong> button. you can check the recived
            messages after click.
            <MeSpeak
              text="
           As a Seller you can see Notification in profile button. and after you clicking this button, you will directing to the Main Menu. There you can see My Messages button. you can check the recived messages after click."
            >
            </MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/notificationPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="notification"
              />
              <v-img
                :src="require(`./pics/notificationMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="notificationMob"
              />
            </ul>
            <br />
            <ul>
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/message.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="message"
                />
              </v-row>
            </ul>
          </li>
          <br />
          <li>
            In there you can see the relevant message and requested invoices.
            and you can <strong>create invoices</strong> which requested.
            <MeSpeak
              text="
           In there you can see the relevant message and requested invoices. and you can create the invoices which requested."
            >
            </MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/createPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="createPc"
              />
              <v-img
                :src="require(`./pics/createMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="createMob"
              />
            </ul>
            <br />
          </li>
          <li>
            Now u can see which you <strong>created invoice</strong>.
            <MeSpeak
              text="
            Now u can see which you created invoices."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/newinvoicePc.png`)"
                  align-center
                  class="ma-3 hidden-xs-only"
                  height="650"
                  width="450"
                  contain
                  alt="newinvoicePc"
                />
              </v-row>
              <v-img
                :src="require(`./pics/newinvoiceMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="newinvoiceMob"
              />
            </ul>
          </li>
          <br />
          <li>
            After
            <strong>Requesting Invoice</strong> you can check your invoice as a
            buyer. Press the <strong>My MobilityGuru</strong> button in the menu
            dialog/ <a href="https://mobilityguru.org/profile">Profile</a> like
            as a below.
            <MeSpeak
              text="After Requesting Invoice you can check your invoice as a buyer. Press the My MobilityGuru button in the menu dialog Profile like as a below"
            >
            </MeSpeak>
            <br />

            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>

          <li>
            Choose the <strong>MY INVOICE AS BUYER</strong> if you are a buyer.
            or choose the <strong>MY INVOICE AS SELLER</strong> if you are a
            seller.
            <MeSpeak
              text="Choose the MY INVOICE AS BUYER if you are a buyer. or choose the MY INVOICE AS SELLER if you are a seller."
            >
            </MeSpeak>
          </li>

          <li>
            There you can see <strong>MY INVOICE AS BUYER</strong> button. and
            after you clicking this button, you will directing to the
            <strong>Buyer Invoice</strong> page. There you can see your
            requested invoices and you can check your choosing Ad relevant
            requested invoice
            <MeSpeak
              text="
           There you can see MY INVOICE AS BUYER button. and after you clicking this button, you will directing to the Buyer Invoice page. There you can see your requested invoices and you can check your choosing Ad relevant requested invoice."
            >
            </MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/buyerbtnPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="buyerbtnPc"
              />
              <v-img
                :src="require(`./pics/buyerbtnMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="buyerbtnMob"
              />
            </ul>
          </li>
          <br />
          <ul>
            <v-img
              :src="require(`./pics/buyerpagePc.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="buyerpagePc"
            />
            <v-img
              :src="require(`./pics/buyerpageMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="buyerpageMob"
            />
          </ul>
          <br />
          <ul>
            <v-img
              :src="require(`./pics/adinvoicePc.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="adinvoicePc"
            />
            <v-img
              :src="require(`./pics/adinvoiceMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="adinvoiceMob"
            />
          </ul>

          <br />
          <li>
            After
            <strong>Creating Invoice</strong> you can check your invoice as a
            seller. Press the <strong>My MobilityGuru</strong> button in the
            menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> like as a
            below.
            <MeSpeak
              text="After Creating Invoice you can check your invoice as a seller. Press the My MobilityGuru button in the menu dialog Profile like as a below"
            >
            </MeSpeak>
            <br />

            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>

          <li>
            After that you can check your <strong>invoices </strong>as a seller.
            <MeSpeak
              text="
            After that you can check your invoices as a seller."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/sellerbtnPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="sellerbtnPc"
              />
              <v-img
                :src="require(`./pics/sellerbtnMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="sellerbtnMob"
              />
            </ul>
          </li>
          <br />
          <br />
          <ul>
            <v-img
              :src="require(`./pics/sellerpagePC.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="sellerpagePC"
            />
            <v-img
              :src="require(`./pics/sellerpageMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="sellerpageMob"
            />
          </ul>
          <br />
          <ul>
            As a buyer you can pay the GURU tokens to product/service in invoice
            page.
            <MeSpeak
              text="As a buyer you can pay the GURU tokens to product/service in invoice page.  "
            ></MeSpeak>
            <v-img
              :src="require(`./pics/adinvoicePc.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="adinvoicePc"
            />
            <v-img
              :src="require(`./pics/adinvoiceMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="adinvoiceMob"
            />
          </ul>
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}

span.smallcaps {
  font-variant: small-caps;
}

span.underline {
  text-decoration: underline;
}

div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}

ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
