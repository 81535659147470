<template>
  <DashboardHelper :roleType="roleType"> </DashboardHelper>
</template>

<script>
import DashboardHelper from "./DashboardHelper";
import Config from "../config.js";

export default {
  name: "CountrymanagerDashboard",
  components: {
    DashboardHelper,
  },
  metaInfo: {
    title: "Mobilility Countrymanager",
    //titleTemplate: "%s | Public Ads",
    meta: [
      {
        name: "description",
        content: "Mobility Guru Countrymanager Dashboard",
      },
      { name: "keywords", content: "countrymanager" },
    ],
  },
  data: () => ({
    roleType: "",
  }),

  mounted: async function () {
    this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    init() {
      if (this.$store.state.isCountrymanager === true) {
        this.roleType = Config.ROLE_TYPE_ENUM.COUNTRYMANAGER;
      }
    },
  }, // methods
};
</script>
