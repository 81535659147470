<template>
  <v-card-title class="fillColour" primary-title>
    <v-row justify="space-between">
      <div>
        {{ $t(title) }}
      </div>

      <v-btn
        v-if="myShowClose === true"
        class="closebtn ma-1"
        outlined
        @click="closeWindow"
      >
        <v-icon left color="green" large>
          {{ mdiCloseOutline }}
        </v-icon>
        <span class="hidden-sm-and-down"> &nbsp; {{ $t(`close`) }} </span>
      </v-btn>
    </v-row>
  </v-card-title>
</template>

<script>
import { mdiCloseOutline } from "@mdi/js";

export default {
  name: "MyCardTitle",
  props: ["title", "showClose"],

  data: () => ({
    mdiCloseOutline: mdiCloseOutline,
    myShowClose: true,
  }),

  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      if (this.showClose === null) {
        this.myShowClose = true;
      } else {
        if (this.showClose === false) {
          this.myShowClose = false;
        } else {
          // default
          this.myShowClose = true;
        }
      }
    },

    closeWindow() {
      this.$emit("close-window");
    },
  }, // methods
};
</script>
