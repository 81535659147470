<template>
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
    <head>
      <meta charset="utf-8" />
      <meta name="generator" content="pandoc" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>signup</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body>
      <v-card class="mr-5" elevation="0">
        <ul>
          <li>
            If user want to
            <strong>Post As a New Ad in Another Country</strong> then Press the
            <strong>My MobilityGuru</strong> button in the menu dialog/
            <a href="https://mobilityguru.org/profile">Profile</a> like as a
            below.
            <MeSpeak
              text="If user want to Post As a New Ad in Another Country, Press the My MobilityGuru button in the menu dialog/ Profile like as a below"
            >
            </MeSpeak>
            <br />

            <ul>
              <v-img
                :src="require(`./pics/myMobility.jpeg`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="Submit Ad"
              />
              <v-img
                :src="require(`./pics/myMobilityMob.jpeg`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="Submit Ad"
              />
            </ul>
            <br />
          </li>
          <li>
            After clicking the <strong>My MobilityGuru</strong> button, your
            profile will pop up and you have to Click the
            <a href="https://mobilityguru.org/my-ads">View My Ads</a> button.
            <MeSpeak
              text="
            After clicking the My MobilityGuru button, your profile will pop up and you have to Click the View My Ads button."
            ></MeSpeak>
            <ul>
              <br />
              <v-row justify="center">
                <v-img
                  :src="require(`./pics/profile.png`)"
                  align-center
                  height="650"
                  width="450"
                  contain
                  alt="profile"
                />
              </v-row>
            </ul>
            <br />
          </li>
          <li>
            Now you can see the <strong>View My Ads</strong> page<MeSpeak
              text="
           Now you can see the View My Ads page"
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/myAds.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="myAds"
              />
              <v-img
                :src="require(`./pics/myadsMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="myadsMob"
              />
            </ul>
            <br />
          </li>

          <li>
            Click on the ad you want to
            <strong>Post As a New Ad in Another Country</strong>
            <MeSpeak
              text="Click on the ad you want to Post As a New Ad in Another Country"
            ></MeSpeak>
          </li>
          <li>
            After that, you will see your selected related ad details page and
            you will see the
            <strong>Post As a New Ad in Another Country</strong> button also
            there.<MeSpeak
              text="After that, you will see your selected related ad details page and you will see the Promote Ad button also there."
            ></MeSpeak>
            <ul>
              <v-img
                :src="require(`./pics/anotherCountryPc.png`)"
                class="ma-3 hidden-xs-only"
                contain
                alt="anotherCountryPc"
              />
              <v-img
                :src="require(`./pics/anotherCountryMob.png`)"
                class="mr-5 mt-5 hidden-sm-and-up"
                contain
                alt="anotherCountryMob"
              />
            </ul>
            <br />
          </li>

          <li>
            Now you can see the
            <strong>New Ad - Post on another country</strong> page and selected
            ad details.
            <MeSpeak
              text="
           Now you can see the New Ad - Post on another country page and selected ad details."
            ></MeSpeak>
          </li>
          <li>
            After checking the details, you can change
            <strong>Another Country</strong> by clicking
            <strong>Country of Ad</strong> option. and you can see the drop down
            list of countries.
            <MeSpeak
              text="After checking the details, you can change Another Country by clicking Country of Ad option. and you can see the drop down list of countries."
            ></MeSpeak>
          </li>
          <ul>
            <v-img
              :src="require(`./pics/countryOfAdPc.png`)"
              class="ma-3 hidden-xs-only"
              contain
              alt="countryOfAdPc"
            />
            <v-img
              :src="require(`./pics/countryOfAdMob.png`)"
              class="mr-5 mt-5 hidden-sm-and-up"
              contain
              alt="countryOfAdMob"
            />
          </ul>
          <br />

          <li>
            After done with selecting your preferred country, click the
            <strong>Save as new Ad.</strong> And it will be appear in
            <strong><a href="https://mobilityguru.org/my-ads">My Ads</a></strong
            >as a New Ad
            <MeSpeak
              text="After done with selecting your preferred country, click the Save as ne Ad. And it will be appear in My Ads as a New Ad."
            ></MeSpeak>
          </li>
          <br />
          <br />
        </ul>
      </v-card>
    </body>
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>
<script>
import MeSpeak from "../MeSpeak";
export default {
  name: "submitAd",
  components: {
    MeSpeak,
  },
  metaInfo: {
    title: "Mobility Guru FAQ",
    meta: [
      {
        name: "description",
        content: "Mobility Guru About",
      },
      {
        name: "keywords",
        content: "about",
      },
    ],
  },
  mounted: async function () {},
  methods: {},
};
</script>
