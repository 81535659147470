<template>
  <v-col class="pa-0">
    <v-card max-width="1000" class="mx-auto">
      <v-card-title>
        MobilityGuru AirDrop
        <MeSpeak size="40" :text="meSpeakText"></MeSpeak>
      </v-card-title>
      <v-card-text class="black--text">
        <!--<div v-html="content"></div>-->
        <p>
          MobilityGuru is airdropping a total of 333,000 Guru Tokens to
          applicants. Complete the simple whitelist airdrop form, to receive
          Guru Tokens from the total airdrop pool. MobilityGuru Token Airdrop
        </p>
        details: <br />
        <ul style="list-style-type: none">
          <li><span translate="no">💰</span> AirDrop – MobilityGuru Token</li>
          <li>
            <span translate="no">💸</span> MobilityGuru Token price - $0.05
          </li>
          <li>
            <span translate="no">💴</span> Estimated prize pool value, over
            $16,000
          </li>
          <li>
            <span translate="no">🎁</span> Airdrop Start date: Wednesday 14th
            July, 2021 7.30pm AEST
          </li>
        </ul>
        Distribution Date: After Guru Token Airdrop promotion ends. <br />

        Step-by-step guide: In order to be eligible for the Airdrop, you must
        complete all the steps as below <br /><br />Optional Bonus Entry:
        Retweet MobilityGuru link on ‘Twitter Pinned Post’ and comment:- “I am
        participating in @MobilityGuruofficial airdrop” and tag 2 friends
        Airdrop with hashtags, #mobilityGuru and receive a bonus entry into the
        draw to win 9,000 tokens.<br /><br />
        Each participant will receive 100 MobilityGuru Tokens upon completion of
        the above procedure. Upon receipt of every 100 completed forms, there
        will be a prize draw of 100 – 500 Guru Tokens. For every 100 people that
        complete the task, they will go in to the draw to receive an extra 100
        or 500 tokens. Once we have the 2500 completed forms, all participants
        will be entered into the final mega draw.
        <br />
        <br />
        1st Place 21,000 Guru Tokens <br />
        2nd Place 11,000 Guru Tokens <br />
        3rd Place 9,000 Guru Tokens <br />
        4th Place 7,000 Guru Tokens <br />
        5th Place 3,000 Guru Tokens
        <br /><br />

        📌<a
          style="text-decoration: none"
          href="https://mobilityguru.io/wp-content/uploads/2021/07/Add-GURU-Tokens-Trust-Wallet.pdf"
          >Download Add GURU Token Guide - Trust Wallet</a
        ><br />
        📌
        <a href="https://trustwallet.com/" style="text-decoration: none"
          >Download Trust Wallet</a
        >(if required) <br />
        <br />

        <v-divider ref="email"></v-divider>
        Trust Wallet download instructions:- <br />
        1. Your number placement will be assigned based on your email received.
        <br />
        2. Random number generator will select the winners for all prizes and
        <br />
        3. We will send the winner's Guru Tokens as soon as they are picked by
        our random number generator. <br />

        <!--
          <p
          v-if="$store.state.isLoggedIn === false"
          class="text-center red--text"
        >
          Please Log In first <br />
        </p>
        <v-row
          justify="center"
          no-gutters
          v-if="$store.state.isLoggedIn === false"
        >
          <MyBtn
            btnName="LOG IN OR SIGN UP"
            :iconName="mdiLogin"
            @btn-clicked="saveFormAndGoToLogin()"
          ></MyBtn>
        </v-row>
           -->

        <br />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="disableSubmit"
        >
          <!--email to verify-->
          <v-row class="mb-3" no-gutters>
            <b>
              Email Id
              <v-icon size="10" color="red">{{ mdiMultiplication }}</v-icon>
              <span v-if="emailVerified === true" class="green--text"
                >&nbsp; Verified</span
              >
              <span class="red--text" v-else>&nbsp; Unverified</span>
            </b>
            <CheckIcon
              height="20"
              width="50"
              :check="emailVerified"
            ></CheckIcon>
            <v-spacer></v-spacer>

            <v-progress-circular
              color="primary"
              rounded
              indeterminate
              v-if="verifyEmailLoading"
            ></v-progress-circular>

            <v-tooltip
              top
              v-if="
                verifyEmailLoading == false &&
                emailVerified == false &&
                showSendCodeButton == true
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="bg fg--text mb-2"
                  v-on="on"
                  @click="sendVerifyEmailCode()"
                  >Send Verification Code
                  <v-icon>{{ mdiEmailSendOutline }}</v-icon>
                </v-btn>
              </template>
              <span>Send Email Verification Code</span>
            </v-tooltip>
          </v-row>
          <v-col cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-text-field
              @input="saveFormData"
              @keydown.space.prevent
              label="Email"
              dense
              v-model="email"
              :rules="[rules.email, rules.email345, rules.required]"
            ></v-text-field>
          </v-col>

          <!--verify email with code-->
          <v-col cols="12" lg="6" md="8" sm="8" class="">
            <div v-if="showEmailVerificationCodeInput">
              <v-row no-gutters>
                <v-text-field
                  label="Email Verification Code"
                  v-model="verificationCode"
                  counter="6"
                  :rules="[rules.counter6]"
                  placeholder="Please enter your email verification code here."
                  @input="checkValidCode()"
                />
              </v-row>

              <v-row justify="center" no-gutters>
                <MyBtn
                  :disabled="
                    verificationCodeValid === false || verifyEmailLoading
                  "
                  btnName="Verify Code"
                  @btn-clicked="verifyCode()"
                ></MyBtn>
              </v-row>
              <br />
            </div>
          </v-col>

          <!--Mobility Guru facebook -->
          <p>
            Like our page on
            <a target="blank" href="https://www.facebook.com/Mobilityguru.org"
              >Facebook
            </a>
            and Submit Facebook ID
            <v-icon size="10" color="red">{{ mdiMultiplication }}</v-icon>
          </p>
          <v-col ref="fb" cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-text-field
              @input="saveFormData"
              label="Facebook ID"
              dense
              v-model.trim="facebookId"
              :rules="[rules.required]"
              required
            ></v-text-field>
          </v-col>
          <br />

          <!--Mobility Guru YouTube -->
          <p>
            Subscribe our YouTube Channel
            <a href="https://youtu.be/vfkQQ08ySUg">YouTube Channel</a> and like
            our MobilityGuru snap shot video on channel
            <v-icon size="10" color="red">{{ mdiMultiplication }}</v-icon>
          </p>
          <v-col ref="youTube" cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-radio-group
              @click="saveFormData"
              v-model="youTubeSubscribed"
              :rules="[rules.select]"
            >
              <v-radio
                v-for="(option, index) in yesOrNo"
                :key="index"
                :label="`${option}`"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <br />

          <!--Smart Chain address -->
          <p>
            MobilityGuru Token wallet address - Smart Chain Network
            <v-icon size="10" color="red">{{ mdiMultiplication }}</v-icon>
          </p>
          <v-col ref="wallet" cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-text-field
              @keydown.space.prevent
              @input="saveFormData"
              label="Wallet Address"
              dense
              v-model.trim="walletAddress"
              :rules="[rules.required]"
              required
            ></v-text-field>
          </v-col>
          <br />

          <!--Influencer code-->
          <p>Influencer Code</p>
          <v-col cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-text-field
              @input="saveFormData"
              label="code"
              dense
              v-model="influencerCode"
              required
              :disabled="influencerCodeDisabled"
            ></v-text-field>
          </v-col>
          <br />

          <!--Group -->
          <p>From which group did you receive the form?</p>
          <v-col ref="group" cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-radio-group @click="saveFormData" v-model="selectedGroup">
              <v-radio
                v-for="(option, index) in formGroups"
                :key="index"
                :label="`${option}`"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <br />

          <!--Bous entry-->
          <p>
            If you have done the Optional Bonus Entry please submit your Twitter
            ID
          </p>
          <v-col ref="twitter" cols="12" lg="6" md="8" sm="8" class="pa-0">
            <v-text-field
              @keydown.space.prevent
              @input="saveFormData"
              label="Twitter ID"
              dense
              v-model="twitterId"
              required
            ></v-text-field>
          </v-col>
          <!--  <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Validate
          </v-btn>

          <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>

          <v-btn color="warning" @click="resetValidation">
            Reset Validation
          </v-btn>-->

          <vue-recaptcha
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            :sitekey="RECAPTCHA_SITE_KEY"
            :language="$store.state.selectedLanguageCode"
          >
          </vue-recaptcha>

          <!-- Rusaik's original
          <vue-recaptcha
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            size="invisible"
            :sitekey="RECAPTCHA_SITE_KEY"
          >
          </vue-recaptcha>
          -->
        </v-form>
      </v-card-text>

      <v-card-actions>
        <MyBtn
          :disabled="disableSubmitBtn || disableSubmitBtn"
          btnName="submit"
          :iconName="mdiContentSaveAll"
          @btn-clicked="submitForm()"
        ></MyBtn>
      </v-card-actions>
    </v-card>
    <Loading :isLoading="isLoading"></Loading>
    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
  </v-col>
</template>

<script>
import MyBtn from "./MyBtn.vue";
import Config from "../config";
import MyAcknowledge from "./MyAcknowledge";
import Loading from "./Loading";
import VueRecaptcha from "vue-recaptcha";
import MeSpeak from "./MeSpeak";

import {
  mdiLogin,
  mdiMultiplication,
  mdiContentSaveAll,
  mdiEmailSendOutline,
} from "@mdi/js";
// lodash
import debounce from "lodash/debounce";
import CheckIcon from "./CheckIcon.vue";

export default {
  name: "AirDrop",
  components: {
    MyBtn,
    MyAcknowledge,
    Loading,
    VueRecaptcha,
    MeSpeak,
    CheckIcon,
  },

  props: [],
  data: () => ({
    mdiLogin: mdiLogin,
    mdiMultiplication: mdiMultiplication,
    mdiContentSaveAll: mdiContentSaveAll,
    mdiEmailSendOutline: mdiEmailSendOutline,
    ROUTE_LOGIN: Config.ROUTE_LOGIN,

    valid: true,
    email: "",
    facebookId: "",
    twitterId: "",
    rules: {
      required: (value) => value.length > 0 || "Required.",
      select: (value) => value !== null || "Required.",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      counter6: (value) =>
        value.length <= Config.EMAIL_VERIFICATION_LENGTH ||
        "Max " + Config.EMAIL_VERIFICATION_LENGTH + " digits",
    },
    yesOrNo: ["Yes", "No"],
    youTubeSubscribed: null,
    walletAddress: "",
    formGroups: Config.FORM_GROUPS,
    selectedGroup: null,
    disableSubmit: false,
    influencerCode: "",
    influencerCodeDisabled: false,
    airdropFormObject: {
      status: null,
      email: "",
      facebookId: "",
      youTubeSubscribed: null,
      walletAddress: "",
      selectedGroup: null,
      twitterId: "",
      influencerCode: "",
    },
    accessTokenDecoded: null,
    formSubmitted: false,

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    acknowledgeOk: true,
    myAcknowledgeKey: "MyAcknowledge0",
    myAcknowledgeKeyCounter: 0,

    isLoading: false,
    RECAPTCHA_SITE_KEY: Config.RECAPTCHA_SITE_KEY,
    recaptchaToken: "",
    meSpeakText: "",
    title: "",
    content: "",
    disableSubmitBtn: true,
    checkingEmailVerified: false,
    emailVerified: false,
    verifyEmailLoading: false,
    showSendCodeButton: false,
    showEmailVerificationCodeInput: false,
    verificationCode: "",
    verificationCodeValid: false,
  }),
  mounted: function () {
    this.init();
  },

  watch: {
    /*"$store.state.selectedLanguageCode": async function () {
      await this.init();
    },*/
    /* This watch the email text field and if it is not empty and valid check for email verified from backend00 */
    email: debounce(async function () {
      if (Config.EMAIL_PATTERN.test(this.email) == true) {
        //check email verified
        this.debug(`check email verified?`);
        this.verifyEmailLoading = true;
        let res = await this.checkEmailVerified(this.email);
        if (res.ok == true) {
          this.emailVerified = true;
        } else {
          this.emailVerified = false;
          this.showSendCodeButton = true;
        }
        if (res.codeValid == true) {
          this.showEmailVerificationCodeInput = true;
        } else {
          this.showEmailVerificationCodeInput = false;
        }
        this.checkSubmitButton();
        this.verifyEmailLoading = false;
      } else {
        this.emailVerified = false;
        this.showSendCodeButton = false;
      }
    }, 1000),
  },
  methods: {
    async init() {
      await this.getLocaleOfGT();
      /*this.airdropFormObject = {
        status: null,
        email: "",
        facebookId: "",
        youTubeSubscribed: null,
        walletAddress: "",
        selectedGroup: "",
        twitterId: "",
        influencerCode: "",
      };
      this.$store.commit("set", ["airdropFormObject", this.airdropFormObject]);*/
      /*if (this.$store.state.isLoggedIn == true) {
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
        this.email = this.accessTokenDecoded.preferred_username;
      } else {
        this.disableSubmit = true;
      }*/
      /// this.RECAPTCHA_SITE_KEY = Config.RECAPTCHA_SITE_KEY;
      this.airdropFormObject = this.$store.state.airdropFormObject;

      this.facebookId = this.airdropFormObject.facebookId;
      this.youTubeSubscribed = this.airdropFormObject.youTubeSubscribed;
      this.walletAddress = this.airdropFormObject.walletAddress;
      this.influencerCode = this.airdropFormObject.influencerCode;
      this.selectedGroup = this.airdropFormObject.selectedGroup;
      this.twitterId = this.airdropFormObject.twitterId;
      // this.formSubmitted = this.airdropFormObject.submitted;
      if (this.$route.query.influencerCode !== undefined) {
        this.influencerCode = this.$route.query.influencerCode;
        this.influencerCodeDisabled = true;
        this.saveFormData();
      }
      /*let res = await this.getAirdropContent(this.$i18n.locale);
      this.printJson(res);
      this.title = res.translatedContent.title;
      this.content = res.translatedContent.content;
      this.meSpeakText = `${this.title}`;
      this.meSpeakText += `${this.content}`;*/

      this.meSpeakText += `MobilityGuru AirDrop`;
      this.meSpeakText += `MobilityGuru is airdropping a total of 333,000 Guru Tokens to applicants. Complete the simple whitelist airdrop form, to receive Guru Tokens from the total airdrop pool. MobilityGuru Token Airdrop details:
💰AirDrop – MobilityGuru Token
💸MobilityGuru Token price - $0.05
💴Estimated prize pool value, over $16,000
🎁Airdrop Start date: Wednesday 14th July, 2021 7.30pm AEST
Distribution Date: After Guru Token Airdrop promotion ends.
Step-by-step guide: In order to be eligible for the Airdrop, you must complete all the steps as below

Optional Bonus Entry: Retweet MobilityGuru link on ‘Twitter Pinned Post’ and comment:- “I am participating in @MobilityGuruofficial airdrop” and tag 2 friends Airdrop with hashtags, #mobilityGuru and receive a bonus entry into the draw to win 9,000 tokens.

Each participant will receive 100 MobilityGuru Tokens upon completion of the above procedure. Upon receipt of every 100 completed forms, there will be a prize draw of 100 – 500 Guru Tokens. For every 100 people that complete the task, they will go in to the draw to receive an extra 100 or 500 tokens. Once we have the 2500 completed forms, all participants will be entered into the final mega draw.

1st Place 21,000 Guru Tokens
2nd Place 11,000 Guru Tokens
3rd Place 9,000 Guru Tokens
4th Place 7,000 Guru Tokens
5th Place 3,000 Guru Tokens

📌 Download Add GURU Token Guide - Trust Wallet
📌 Download Trust Wallet (if required)

Trust Wallet download instructions:-
1. Your number placement will be assigned based on your email received.
2. Random number generator will select the winners for all prizes and
3. We will send the winner's Guru Tokens as soon as they are picked by our random number generator.`;
      /*if (this.airdropFormObject.status === "submitted") {
        this.disableSubmit = true;
        this.acknowledgeTitle = `Success`;
        this.acknowledgeMessage = `You have already filled the form`;
        this.showAcknowledge = true;
        this.myAcknowledgeKeyCounter++;
        this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
        while (this.showAcknowledge === true) {
          await this.synchSetTimeout(200);
          this.debug(".");
        }

        this.$router.go(-1);
      }*/
    },
    validate() {
      this.$refs.form.validate();
      console.log(this.$refs.form);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveFormData() {
      this.airdropFormObject.email = this.email;
      this.airdropFormObject.facebookId = this.facebookId;
      this.airdropFormObject.youTubeSubscribed = this.youTubeSubscribed;
      this.airdropFormObject.walletAddress = this.walletAddress;
      this.airdropFormObject.influencerCode = this.influencerCode;
      this.airdropFormObject.selectedGroup = this.selectedGroup;
      this.airdropFormObject.twitterId = this.twitterId;
      this.airdropFormObject.status = "filling";

      this.$store.commit("set", ["airdropFormObject", this.airdropFormObject]);
      this.printJson(
        this.$store.state.airdropFormObject,
        "airdrop saved details"
      );

      this.checkSubmitButton();
    },

    checkInputs() {
      let ok = true;

      if (this.$refs.form.validate() === false) {
        if (
          this.email.length > 0 &&
          Config.EMAIL_PATTERN.test(this.email) == false
        ) {
          this.$refs.form.$el.scrollIntoView();
          ok = false;
          this.acknowledgeTitle = "Error!";
          this.acknowledgeMessage = `Please type a valid email address`;

          this.showAcknowledge = true;
          this.myAcknowledgeKeyCounter++;
          this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
        }
        if (this.facebookId.length === 0) {
          this.$refs.form.$el.scrollIntoView();
          ok = false;
        }

        if (ok == true) {
          if (this.youTubeSubscribed === null) {
            this.$refs.form.$el.scrollIntoView();
            ok = false;
          }
        }
        if (ok == true) {
          if (this.walletAddress.length === 0) {
            this.$refs.form.$el.scrollIntoView();
            ok = false;
          }
        }
      }
      return ok;
    },
    async submitForm() {
      let ok = false;

      ok = this.checkInputs();
      if (ok === true) {
        this.disableSubmit = true;
        // if (await this.isLoggedInOIDC(true)) {
        this.isLoading = true;
        this.debug(`valid data`);
        //this.$refs.recaptcha.execute();

        /*} else {
          this.$router.push(Config.ROUTE_LOGIN);
        }*/

        let airdropForm = this.airdropFormObject;
        //airdropForm["userId"] = this.accessTokenDecoded.sub;
        airdropForm["createdDate"] = Date.now();
        airdropForm["status"] = "submitted";
        airdropForm["recaptchaToken"] = this.recaptchaToken;

        let res = await this.createAirdropForm(airdropForm);
        this.isLoading = false;
        if (res.ok == true) {
          this.formSubmitted = true;
          this.airdropFormObject.status = "submitted";
          this.acknowledgeTitle = "Successfully updated";
          this.acknowledgeMessage = `Form saved and ticket created for draw.`;
          this.airdropFormObject = {
            status: null,
            email: "",
            facebookId: "",
            youTubeSubscribed: null,
            walletAddress: "",
            selectedGroup: "",
            twitterId: "",
            influencerCode: "",
          };
          this.$store.commit("set", [
            "airdropFormObject",
            this.airdropFormObject,
          ]);

          this.acknowledgeOk = true;
        } else {
          this.acknowledgeTitle = "Error!";
          this.acknowledgeMessage = res.message;
          this.acknowledgeOk = false;
          this.$refs.recaptcha.reset();
          this.recaptchaToken = "";
        }
        this.checkSubmitButton();
        this.disableSubmit = false;
        this.showAcknowledge = true;
        this.myAcknowledgeKeyCounter++;
        this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;

        while (this.showAcknowledge == true) {
          await this.synchSetTimeout(200);
          this.debug(".");
        }
        if (res.ok === true) {
          this.$router.push(Config.ROUTE_HOME);
        }
      }
    },
    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },
    async saveFormAndGoToLogin() {
      this.saveFormData();
      await this.pushRoute(this.ROUTE_LOGIN, {});
    },

    async onCaptchaVerified(recaptchaToken) {
      //this.$refs.recaptcha.reset();
      this.recaptchaToken = recaptchaToken;
      this.debug(recaptchaToken);
      this.saveFormData();
    },

    // Rusaik's original
    /*
    async onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      this.debug(recaptchaToken);
      let airdropForm = this.airdropFormObject;
      //airdropForm["userId"] = this.accessTokenDecoded.sub;
      airdropForm["createdDate"] = Date.now();
      airdropForm["status"] = "submitted";
      airdropForm["recaptchaToken"] = recaptchaToken;

      let res = await this.createAirdropForm(airdropForm);
      this.isLoading = false;
      if (res.ok == true) {
        this.formSubmitted = true;
        this.airdropFormObject.status = "submitted";
        this.acknowledgeTitle = "Successfully updated";
        this.acknowledgeMessage = `Form saved and ticket created for draw.`;
        this.airdropFormObject = {
          status: null,
          email: "",
          facebookId: "",
          youTubeSubscribed: null,
          walletAddress: "",
          selectedGroup: "",
          twitterId: "",
          influencerCode: "",
        };
        this.$store.commit("set", [
          "airdropFormObject",
          this.airdropFormObject,
        ]);
      } else {
        this.acknowledgeTitle = "Error!";
        this.acknowledgeMessage = res.message;
      }
      this.disableSubmit = false;
      this.showAcknowledge = true;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;

      while (this.showAcknowledge == true) {
        await this.synchSetTimeout(200);
        this.debug(".");
      }
      if (res.ok === true) {
        this.$router.push(Config.ROUTE_HOME);
      }
    },
    */

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.isLoading = false;
      this.disableSubmit = false;
      this.acknowledgeTitle = "Error!";
      this.acknowledgeMessage =
        "reCAPTCHA verification failed, Please submit again";

      this.showAcknowledge = true;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
      this.recaptchaToken = "";
      this.checkSubmitButton();
    },

    showAcknowledgeMessage(title, message) {
      this.acknowledgeTitle = title;
      this.acknowledgeMessage = message;

      this.showAcknowledge = true;
      this.myAcknowledgeKeyCounter++;
      this.myAcknowledgeKey = `MyAcknowledge${this.myAcknowledgeKeyCounter}`;
    },

    async checkSubmitButton() {
      if (
        Config.EMAIL_PATTERN.test(this.email) == false ||
        this.email.length == 0 ||
        this.emailVerified == false ||
        this.facebookId.length == 0 ||
        this.youTubeSubscribed == null ||
        this.walletAddress.length == 0 ||
        this.recaptchaToken.length == 0
      ) {
        this.disableSubmitBtn = true;
      } else {
        this.disableSubmitBtn = false;
      }
    },

    async sendVerifyEmailCode() {
      this.verifyEmailLoading = true;
      let resJson = await this.sendVerifyEmailFromAirdrop(this.email);
      this.printJson(resJson, "resJSon");
      if (resJson.ok == true) {
        this.showEmailVerificationCodeInput = true;
        //this.showSendCodeButton = false;
      } else {
        this.acknowledgeOk = false;
        this.showAcknowledgeMessage(
          "Error!",
          "Sending verification code failed"
        );
      }
      this.verifyEmailLoading = false;
    },

    checkValidCode() {
      if (this.verificationCode.length == Config.EMAIL_VERIFICATION_LENGTH) {
        this.verificationCodeValid = true;
      } else if (
        this.verificationCode.length > Config.EMAIL_VERIFICATION_LENGTH
      ) {
        /* WHY doesn't this work!

        this.verificationCode = 
          this.verificationCode.substring(0, Config.EMAIL_VERIFICATION_LENGTH)
          */

        this.verificationCodeValid = false;
      } else {
        this.verificationCodeValid = false;
      }
      this.debug(`verificationCodeValid = ${this.verificationCodeValid}`);
    },

    async verifyCode() {
      this.verifyEmailLoading = true;
      let resJson = await this.verifyCodeWithDB(
        this.email,
        this.verificationCode
      );
      if (resJson.ok == true && resJson.emailVerified == true) {
        this.showEmailVerificationCodeInput = false;
        this.emailVerified = true;
      } else {
        this.acknowledgeOk = false;
        this.showAcknowledgeMessage("Error!", resJson.message);
      }
      this.verifyEmailLoading = false;
    },
  },
};
</script>
