<template>
  <v-col style="text-align: center">
    <h3>Guru Token Guide</h3>
    <br />
    <v-card
      max-width="810"
      min-width="320"
      elevation="0"
      class="mx-auto"
      color="transparent"
    >
      <div>
        <div>
          <v-img src="../assets/backgroundPics/background01.jpg" contain>
            <!--
            <div>
              <div
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; left: 47%; top: 63%'
                    : 'position: absolute; left: 47%; top: 67%'
                "
                class="cls_002"
              >
                <span class="cls_002">MOBILITYGURU</span>
              </div>
              <div
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; left: 47%; top: 68%'
                    : 'position: absolute; left: 47%; top: 72%'
                "
                class="cls_002"
              >
                <span class="cls_002">ADD TOKEN</span>
              </div>
              <div
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; left: 47%; top: 72%'
                    : 'position: absolute; left: 47%; top: 77%'
                "
                class="cls_002"
              >
                <span class="cls_002">TRUST WALLET</span>
              </div>
              <div
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; left: 47%; top: 77%'
                    : 'position: absolute; left: 47%; top: 82%'
                "
                class="cls_002"
              >
                <span class="cls_002">GUIDE</span>
              </div>
            </div>
            -->
          </v-img>
        </div>
      </div>

      <v-card max-width="800" min-width="300" elevation="0" color="transparent">
        <v-img src="../assets/backgroundPics/background02.jpg" contain>
          <div
            id="centerDiv"
            style="
              background: rgba(255, 250, 250, 0.8);
              height: 100%;
              width: 100%;
              vertical-align: middle;
            "
          >
            <br />
            <br />
            <div>
              <span class="cls_003">Dear Sir/Madam,</span>
            </div>
            <div>
              <span class="cls_003"
                >We appreciate very much that you are one of our</span
              >
            </div>
            <div>
              <span class="cls_003">MobilityGuru token owners.</span>
            </div>
            <br />
            <div>
              <span class="cls_003"
                >The GURU Tokens ordered will be been sent to your</span
              >
            </div>
            <div>
              <span class="cls_003"
                >wallet address provided within the next 24 hours.</span
              >
            </div>
            <br />
            <div>
              <span class="cls_003"
                >These Tokens can in the future be used to purchase</span
              >
            </div>
            <div>
              <span class="cls_003"
                >products and services on the platform plus exchange</span
              >
            </div>
            <div>
              <span class="cls_003">them via our integrated GURU wallet.</span>
            </div>
            <br />
            <div>
              <span class="cls_003"
                >We recommend populating your MobilityGuru</span
              >
            </div>
            <div>
              <span class="cls_003"
                >Tokens into the Trust Wallet, available from most</span
              >
            </div>
            <div>
              <span class="cls_003">app stores.</span>
            </div>
          </div>
        </v-img>
      </v-card>

      <!--Page 3 -->
      <v-card max-width="800" min-width="300" elevation="0" color="transparent">
        <div>
          <div style="">
            <v-img
              src="../assets/backgroundPics/background03.jpg"
              max-width="800"
              min-width="320"
            >
              <span class="cls_008">Instruction Guide</span>
              <br />
              <br />
              <span class="cls_004"
                >To populate it into the Trust Wallet please follow
              </span>
              <span class="cls_004">this instruction guide:</span>

              <br />
              <br />
              <span class="cls_004"
                >1.Paste this smart contract address into you</span
              >
              <span class="cls_004"
                >wallet where it states Add Custom Token -</span
              >
              <br /><br />
              <span class="cls_006"
                >0xc166a8dd8e48355774ac95933f746b57a724a464</span
              >
              <br /><br />
              <br />
              <span class="cls_007"
                >2. See next page screen shots for step by step
              </span>
              <span class="cls_007">instruction guide.</span>
            </v-img>
          </div>
        </div>
      </v-card>

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background04.jpg" contain>
          <div>
            <span class="cls_008">To Add MobilityGuru Token</span>
          </div>
          <div class="mt-2">
            <span class="cls_003"
              >STEP 1 - Open your trust wallet and click on this symbol</span
            >
          </div>
        </v-img>
      </v-card>

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background05.jpg" contain>
          <div class="cls_003" id="centerDiv">
            <span class="cls_003">Step 2 Type "MobilityGuru Token"</span>
          </div>
        </v-img></v-card
      >

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background06.jpg" contain>
          <div class="cls_003" id="centerDiv">
            <span class="cls_003">STEP 3 Set Network to Smart Chain</span>
          </div>
        </v-img></v-card
      >

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background07.jpg" contain>
          <div class="cls_003" id="centerDiv">
            <span class="cls_003">STEP 4 Add Smart Contact Address</span>
          </div>
        </v-img></v-card
      >

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background08.jpg" contain>
          <span class="cls_008">To Receive Your Guru Token</span>
          <br v-if="!$vuetify.breakpoint.xs" />
          <br />
          <div class="cls_003" id="centerDiv">
            <span class="cls_003">STEP 4 Tap on "Receive"</span>
          </div>
        </v-img></v-card
      >

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background09.jpg" contain>
          <div id="centerDiv">
            <span class="cls_003">STEP 5 Copy Your MobilityGuru</span>

            <br />

            <span class="cls_003">Address and Send To Us </span>
          </div>
        </v-img></v-card
      >

      <v-card
        max-width="800px"
        min-width="300"
        elevation="0"
        color="transparent"
      >
        <v-img src="../assets/backgroundPics/background10.jpg" contain>
          <span class="cls_003"
            >After entering all information into the wallet the
          </span>

          <span class="cls_003">wallet should look like - STEP 4.</span>
          <br /><br />
          <span class="cls_003"
            >Please copy the MobilityGuru Token address as per
          </span>

          <span class="cls_003">STEP 5 and email us at</span>
          <br /><br />

          <span class="cls_003">token@mobilityguru.org</span>
          <br /><br />

          <span class="cls_003"
            >and we will send your Tokens within 24 hrs.</span
          >

          <span class="cls_003">
            Please contact your agent for any further
          </span>

          <span class="cls_003">assistance.</span>
          <br /><br />

          <span class="cls_003"
            >Follow us on twitter - MobilityGuruT for updates of
          </span>

          <span class="cls_003">our journey together.</span>
          <br /><br />
          <span class="cls_003">Thank you again.</span>
          <br /><br />
          <span class="cls_003">MobilityGuru Token Team</span>
        </v-img></v-card
      >

      <!-- <iframe
      style="overflow: hidden; max-width: 800px; min-width: 300px"
      loading="lazy"
      frameborder="0"
      :src="TokenGuide"
      width="100%"
      :height="height"
    ></iframe>-->
    </v-card>
  </v-col>
</template>
<script>
//import Config from "../config.js";

export default {
  name: "GuruGuide",
  mounted: async function () {
    this.init();
  },
  /* created() {
    window.addEventListener("resize", this.setHeights);
  },

  destroyed() {
    window.removeEventListener("resize", this.setheights);
  },*/
  data: () => ({
    //TokenGuide: "",
    //height: 500,
  }),
  methods: {
    init() {
      /* this.debug(Config.BACKEND00_FILES_URL);
      this.TokenGuide =
        Config.BACKEND00_FILES_URL + "Add GURU Tokens - Trust Wallet.pdf";
      this.debug(this.TokenGuide);
      this.height = window.innerHeight - 200;*/
    },
  },
};
</script>

<style type="text/css">
span.cls_002 {
  font-family: Arial, serif;
  font-size: 25px;
  color: rgb(251, 250, 239);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_002 {
  font-family: Arial, serif;
  font-size: 25px;
  color: rgb(251, 250, 239);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_003 {
  font-family: Arial, serif;
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_003 {
  font-family: Arial, serif;
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  position: absolute;
}
span.cls_005 {
  font-family: Arial, serif;
  font-size: 46px;
  color: rgb(255, 255, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_005 {
  font-family: Arial, serif;
  font-size: 46px;
  color: rgb(255, 255, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_004 {
  font-family: Arial, serif;
  font-size: 32px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_004 {
  font-family: Arial, serif;
  font-size: 32px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_006 {
  font-family: Arial, serif;
  font-size: 27.7px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_006 {
  font-family: Arial, serif;
  font-size: 27.7px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_007 {
  font-family: Arial, serif;
  font-size: 32.3px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_007 {
  font-family: Arial, serif;
  font-size: 32.3px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_008 {
  font-family: Arial, serif;
  font-size: 46px;
  color: rgb(251, 250, 239);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_008 {
  font-family: Arial, serif;
  font-size: 46px;
  color: rgb(251, 250, 239);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  span.cls_002 {
    font-size: 10px;
  }

  span.cls_003 {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  span.cls_002 {
    font-size: 10px;
  }

  span.cls_003 {
    font-size: 13px;
  }

  span.cls_004 {
    font-size: 13px;
  }

  span.cls_006 {
    font-size: 13px;
  }
  span.cls_007 {
    font-size: 13px;
  }
  span.cls_008 {
    font-size: 20px;
  }
}
</style>
