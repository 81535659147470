<template>
  <v-card width="800" class="mx-auto mt-5">
    <v-row justify="center">
      <MyBtn
        btnName="goBack"
        :iconName="mdiTransferLeft"
        @btn-clicked="$router.push(forum)"
        class="ma-1"
      ></MyBtn>
    </v-row>
    <v-card-title>
      <h1 class="display-1">
        <span v-if="newTopic">{{ $t(`post`) }}</span>
        <span v-else-if="reply">{{ $t(`reply`) }}</span>
        <span v-else>{{ $t(`reply`) }}</span>
      </h1>
    </v-card-title>
    <div v-if="$store.state.isLoggedIn && reply">
      <v-container class="mt-n6">
        <!--<v-row>
          <v-icon large>mdi-account-circle</v-icon>
          <h3 class="ml-2 mt-1">{{ this.postOrig.owner }}</h3>
        </v-row>-->
        <b class="ml-5">{{ $t(`title`) }}</b>
        <p class="ml-7" v-text="title"></p>

        <b class="ml-5">{{ $t(`message`) }}</b>
        <p class="ml-7" v-text="postMessage"></p>
        <v-row>
          <p v-if="'replies' in this.postOrig" class="ml-5">
            {{ $t(`replies`) }} ({{ numOfReplies }})
          </p>
          <v-spacer></v-spacer>
          <span class="mr-5" style="font-size: 13px">{{
            this.calculateDays(this.postOrig.createdDate)
          }}</span>
        </v-row>
        <v-card
          v-for="(reply, index) in this.replies"
          :key="index"
          min-width="300px"
          max-width="1000px"
          class="ma-1"
          elevation="0"
        >
          <!--  <ReplyView :reply="reply" :postOrig="postOrig"></ReplyView>-->
          <v-row class="ml-5" no-gutters>
            <v-icon large> {{ mdiAccountCircle }} </v-icon>
            <h3 v-if="'owner' in reply" class="ml-2 mt-1">{{ reply.owner }}</h3>
          </v-row>
          <v-card elevation="0" class="ml-10 mt-n4">
            <p
              v-if="'reply' in reply"
              class="mr-8 mt-3"
              v-text="reply.reply"
            ></p>
          </v-card>
          <v-row class="ml-10 mt-n3" no-gutters>
            <a
              v-if="showDelete || accessTokenDecoded.sub == reply.postedBy"
              class="mr-8"
              @click="showDeleteReply(reply.id)"
              >{{ $t(`delete`) }}</a
            >
            <a
              v-if="showDelete || accessTokenDecoded.sub == reply.postedBy"
              class="mr-8"
              @click="gotoEditReply(reply.id, reply.reply)"
              >{{ $t(`edit`) }}</a
            >
            <a
              v-if="showReport && reply.postedBy !== accessTokenDecoded.sub"
              class="text-right mr-5"
              @click="handleClick()"
              >{{ $t(`report`) }}</a
            >
          </v-row>
        </v-card>
        <a v-if="editReply" @click="(editReply = false), (postReply = ' ')">{{
          $t(`reply`)
        }}</a>
        <br />
        <v-divider></v-divider>
        <v-container v-if="editReply === true">
          <b ref="EditReply">{{ $t(`editReply`) }}</b>
          <v-textarea
            @keydown.space="preventLeadingSpace"
            :label="$t('editReply')"
            v-model="postReply"
            background-color="fillColour"
            counter="600"
            :rules="[rules.required, rules.description600]"
            ref="postReply"
            auto-grow
            clearable
            filled
            outlined
            solo
          />
          <v-row align="center" justify="center" v-if="reply">
            <MyBtn
              ref
              btnName="submitAd.changes"
              @btn-clicked="sendReply()"
              :disabled="isLoading || showReply"
            ></MyBtn>
          </v-row>
        </v-container>
        <v-container v-if="editReply === false">
          <b ref="Reply">{{ $t(`reply`) }}</b>
          <v-textarea
            @keydown.space="preventLeadingSpace"
            :label="$t('reply')"
            v-model="postReply"
            background-color="fillColour"
            counter="600"
            :rules="[rules.required, rules.description600]"
            auto-grow
            clearable
            filled
            outlined
            solo
          />
          <v-row align="center" justify="center" v-if="reply">
            <MyBtn
              btnName="send"
              @btn-clicked="sendReply()"
              :disabled="isLoading || showReply"
            ></MyBtn>
          </v-row>
        </v-container>
      </v-container>
    </div>
    <div v-else-if="$store.state.isLoggedIn">
      <v-container>
        <MyDropDown
          itemName="category"
          :dropDownItem="category"
          :dropDownItems="FORUM_CATEGORIES"
          :disabled="!newTopic"
          @update-items="updateCategory"
          ref="category"
        ></MyDropDown>

        <br />
        <b>{{ $t(`title`) }}</b>
        <v-text-field
          @keydown.space="preventLeadingSpace"
          :label="$t('title')"
          v-model="title"
          background-color="fillColour"
          counter="120"
          :rules="[rules.required, rules.title120]"
          clearable
          filled
          outlined
          solo
        />
        <br />

        <b>{{ $t(`message`) }}</b>
        <v-textarea
          @keydown.space="preventLeadingSpace"
          label="Message"
          v-model="postMessage"
          background-color="fillColour"
          counter="600"
          :rules="[rules.required, rules.description600]"
          auto-grow
          clearable
          filled
          outlined
          solo
        />
        <br />

        <v-row align="center" justify="center" v-if="newTopic">
          <MyBtn
            btnName="save"
            @btn-clicked="saveTopic()"
            :iconName="mdiContentSave"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>

        <v-row align="center" justify="center" v-if="!newTopic" no-gutters>
          <MyBtn
            btnName="save_changes"
            :disabled="isLoading || showSaveChanges"
            @btn-clicked="modifyTopic()"
          ></MyBtn>
        </v-row>
        <br />
        <v-row
          class="mt-2"
          align="center"
          justify="center"
          v-if="!newTopic"
          no-gutters
        >
          <MyBtn
            btnName="delete"
            @btn-clicked="showDeletePost"
            :disabled="isLoading"
          ></MyBtn>
        </v-row>

        <br />
      </v-container>
    </div>
    <Loading :isLoading="isLoading" :message="messageLoader"></Loading>
    <v-dialog v-model="showDelConfirm">
      <v-card>
        <MyCardTitle
          :title="`confirm ${deleteReply ? 'Reply' : 'Post'}`"
          @close-window="showDelConfirm = false"
        ></MyCardTitle>

        <v-layout justify-center>
          <v-btn @click="showDelConfirm = false" class="bg fg--text">
            <v-icon left large color="green">
              {{ mdiArrowLeftBoldOutline }}
            </v-icon>
            &nbsp; {{ $t(`no`) }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="deleteReply"
            @click="DeleteReply(selectedReplyIdToDelete)"
            class="bg fg--text"
          >
            <v-icon left large color="red"> {{ mdiTrashCanOutline }} </v-icon>
            &nbsp; {{ $t(`yes`) }}
          </v-btn>
          <v-btn v-else @click="DeletePost()" class="bg fg--text">
            <v-icon left large color="red"> {{ mdiTrashCanOutline }} </v-icon>
            &nbsp; {{ $t(`yes`) }}
          </v-btn>
        </v-layout>
        <br />
      </v-card>
    </v-dialog>
    <MyAcknowledge
      :key="acknowledgeKey"
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeAcknowledge"
    ></MyAcknowledge>
  </v-card>
</template>

<script>
import {
  mdiAccountCircle,
  mdiArrowLeftBoldOutline,
  mdiContentSave,
  mdiTransferLeft,
  mdiTrashCanOutline,
} from "@mdi/js";

import Config from "../config.js";

import MyAcknowledge from "./MyAcknowledge";
import Loading from "./Loading";
//import MyYesNo from "./MyYesNo";
import MyBtn from "./MyBtn";
import MyDropDown from "./MyDropDown";
import MyCardTitle from "./MyCardTitle";

//import clone from "lodash/clone";
//import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
//import remove from "lodash/remove";

export default {
  name: "PostNewTopic",
  components: {
    Loading,
    MyDropDown,
    MyBtn,
    MyAcknowledge,
    MyCardTitle,
  },
  props: [],

  computed: {
    showDelete() {
      let deleteReply = false;
      if (
        //this.postOrig.postedBy == this.accessTokenDecoded.sub ||
        this.isSuperAdmin === true ||
        this.isForumModerator == true
      ) {
        deleteReply = true;
      }
      this.debug(`deleteReply = ${deleteReply}`);
      return deleteReply;
    },
    showReport() {
      let reportTopic = false;
      if (
        // this.postOrig.postedBy == this.accessTokenDecoded.sub ||
        this.isSuperAdmin === true ||
        this.isForumModerator == true
      ) {
        reportTopic = false;
      } else {
        reportTopic = true;
      }
      this.debug(`reportTopic = ${reportTopic}`);

      return reportTopic;
    },
    showSaveChanges() {
      let disableEditBtn = true;
      if (this.postId !== undefined) {
        if (
          this.checkTitle !== this.title ||
          this.checkMessage !== this.postMessage
        ) {
          disableEditBtn = false;
        }

        this.debug(`showEdit = ${disableEditBtn}`);
      }
      return disableEditBtn;
    },

    showReply() {
      let showReply = false;
      if (this.postReply === "") {
        showReply = true;
        this.debug(`showReply = ${showReply}`);
      }

      return showReply;
    },
  },

  //TODO: create collection for each main / sub cat
  data: () => ({
    mdiAccountCircle: mdiAccountCircle,
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiContentSave: mdiContentSave,
    mdiTransferLeft: mdiTransferLeft,
    mdiTrashCanOutline: mdiTrashCanOutline,

    checkYes: false,
    reply: false,
    messageToReply: "",
    forum: Config.ROUTE_FORUM,
    newTopic: false,

    isLoading: false,

    category: "",
    order: "",
    FORUM_CATEGORIES: null,

    postMessage: "",
    postReply: "",
    replies: [],
    numOfReplies: "",
    editReply: false,
    replyId: null,

    messageLoader: "",
    currentYear: 0,
    accessTokenDecoded: {},
    bucketMobilityGuru: null,

    collectionProfiles: null,
    collectionForum: null,

    showAcknowledge: false,
    acknowledgeMessage: "",
    acknowledgeTitle: "Error",
    showErrorMessage: false,
    message: "",
    title: "",

    //post: null,
    postId: "",
    postOrig: {},
    check: "",

    showDelConfirm: false,
    isSuperAdmin: false,
    isForumModerator: false,
    deleteReply: false,
    selectedReplyIdToDelete: "",
    rules: {
      required: (value) => value.length > 0 || "Required.",
      /*positive: value =>
        value.length == 0 || parseFloat(value) > 0 || "Must be larger than 0",
      counter30: value =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: value => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: value =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",*/
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
      title120: (value) =>
        value.length <= Config.EMAIL_SUBJECT_MAX_LENGTH ||
        "Max " + Config.EMAIL_SUBJECT_MAX_LENGTH + " characters",
    },

    acknowledgeKey: "acknowledge0",
    acknowledgeCounter: 0,
  }),

  validations: {},

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    async init() {
      this.isLoading = true;

      this.isSuperAdmin = this.$store.state.isSuperAdmin;
      this.debug("Super Admin", this.isSuperAdmin);
      this.isForumModerator = this.$store.state.isForumModerator;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.printJson(this.accessTokenDecoded, "Collection Forum ");
      this.currentYear = new Date().getFullYear();

      //this.collectionProfiles = this.$store.state.collectionProfiles;
      //this.bucketMobilityGuru = this.$store.state.bucketMobilityGuru;
      //this.collectionForum = this.$store.state.collectionForum;
      await this.refreshForum();
      this.isLoading = false;
    },

    async refreshForum() {
      this.editReply = false;
      this.replyId = null;
      this.reply = this.$route.query.reply;
      this.postId = this.$route.query.postId;
      if (this.postId !== undefined) {
        let res = await this.getForumPostById(this.postId);

        this.postOrig = res.post;
        this.printJson(this.postOrig, "Post Orig");
      }
      if (this.postId !== undefined && this.reply !== undefined) {
        this.postMessage = this.postOrig.message;
        this.title = this.postOrig.title;
        this.replies = this.postOrig.replies;
        if (isEmpty(this.replies)) {
          this.numOfReplies = 0;
        } else {
          this.numOfReplies = this.replies.length;
        }
        //this.numOfReplies = _.size(this.replies);
      } else if (this.postId !== undefined) {
        this.newTopic = false;
        this.category = this.postOrig.category;
        this.title = this.postOrig.title;
        this.checkTitle = this.postOrig.title;
        this.checkMessage = this.postOrig.message;
        this.postMessage = this.postOrig.message;
      } else {
        this.FORUM_CATEGORIES = Config.FORUM_CATEGORIES;
        this.newTopic = true;
      }
    },

    updateCategory(index) {
      this.debug("updateMainCategory()");
      this.category = this.FORUM_CATEGORIES[index];
    },

    async writePostTextchanges() {
      this.message = "";
      this.showErrorMessage = false;
      let record = {
        id: this.postId,
      };
      let changed = false;
      if (this.postOrig.title != this.title) {
        record["title"] = this.title;
        changed = true;
      }
      if (this.postOrig.message != this.postMessage) {
        record["message"] = this.postMessage;
        changed = true;
      }

      if (changed == true) {
        this.messageLoader += "updating Post...";

        this.printJson(record, "patch record");
        try {
          let res = await this.updateForumPost(record);
          if (res.ok == true) {
            this.messageLoader += "ok<br/>";
          } else {
            this.messageLoader += "error in update<br/>";
            this.showErrorMessage = true;
            this.message = res.message;
          }
        } catch (e) {
          this.message = e;
          this.showErrorMessage = true;
          this.debug(e);
          this.messageLoader += "failed<br/>";
        }
      }
    },

    checkInputs() {
      let ok = true;
      let message = "";

      if (ok == true) {
        if (this.category === "") {
          message = "Please select a Category";
          this.$refs.category.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          //this.showErrorMessage = true;
          ok = false;
        }
      }

      if (ok == true) {
        if (this.title.length == 0) {
          message = "Please enter a Title.";
          //this.showErrorMessage = true
          //this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          ok = false;
        } else if (this.title.length > Config.EMAIL_SUBJECT_MAX_LENGTH) {
          ///this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(
            "Message: Please do not exceed " +
              Config.EMAIL_SUBJECT_MAX_LENGTH +
              " characters."
          );
          ok = false;
        }
      }

      if (ok == true) {
        if (this.postMessage.length == 0) {
          message = "Please enter a Message.";
          //this.showErrorMessage = true
          //this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          ok = false;
        } else if (this.postMessage.length > Config.AD_DESC_MAX) {
          ///this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(
            "Message: Please do not exceed " +
              Config.AD_DESC_MAX +
              " characters."
          );
          ok = false;
        }
      }

      return ok;
    },

    async modifyTopic() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        let ok = this.checkInputs();
        if (ok == true) {
          this.messageLoader = "";
          await this.writePostTextchanges();
        }
        await this.refreshForum();
        this.isLoading = false;
      }
    },

    async saveTopic() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";
        let ok = this.checkInputs();
        let order = 0;
        this.debug("Length of the Forum ", order);

        if (ok) {
          this.messageLoader += "ok<br/>";

          try {
            let result = await this.createNewForumPost(
              this.title,
              this.postMessage,
              this.category
            );

            this.printJson(result, "recordHttp createRecord");
            if (result.ok == true) {
              //let postId = result.data.id;

              this.goToMyTopics(this.accessTokenDecoded.sub);
            }
          } catch (e) {
            console.error(e);
          }
        }
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async gotoEditReply(id, reply) {
      if (this.editReply === false) {
        this.$refs.Reply.scrollIntoView();
        this.editReply = !this.editReply;
      } else {
        this.$refs.EditReply.scrollIntoView();
      }
      this.postReply = reply;
      this.replyId = id;
    },

    async sendReply() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.messageLoader = "Checking inputs...";
        let ok = true; // = this.checkInputs();
        let message = "";
        if (this.postReply.length == 0) {
          message = "Please enter a Reply.";
          //this.showErrorMessage = true;
          //this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(message);
          ok = false;
          this.isLoading = false;
        } else if (this.postReply.length > Config.AD_DESC_MAX) {
          ///this.$refs.useMyPhoneBtn.$el.scrollIntoView();
          this.setAcknowledgeError(
            "Message: Please do not exceed " +
              Config.AD_DESC_MAX +
              " characters."
          );
          ok = false;
          this.isLoading = false;
        }

        if (ok == true) {
          let newReply = false;
          if (this.replyId == null) {
            newReply = true;
          } else {
            newReply = false;
          }

          this.messageLoader += "updating Reply...";

          try {
            //let res = await this.updateForumPost(record, action, order);
            let res;
            if (newReply == true) {
              res = await this.addForumReply(this.postId, this.postReply);
            } else {
              res = await this.updateForumReply(this.replyId, this.postReply);
            }
            if (res.ok == true) {
              this.messageLoader += "ok<br/>";
            } else {
              this.messageLoader += "error in update<br/>";
              this.showErrorMessage = true;
              this.message = res.message;
            }
          } catch (e) {
            this.message = e;
            this.showErrorMessage = true;
            this.debug(e);
            this.messageLoader += "failed<br/>";
          }
          await this.refreshForum();
          this.postReply = " ";
          this.isLoading = false;
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    generateOrder(order) {
      let NewOrder;
      if (order === undefined) {
        NewOrder = 0;
      } else {
        NewOrder = ++order;
      }
      return NewOrder;
    },

    async DeleteReply(replyId) {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;

        try {
          //let res = await this.updateForumPost(record, "deleteReply", orderDel);
          let res = await this.deleteForumReply(replyId);
          if (res.ok == true) {
            this.messageLoader += "ok<br/>";
            this.showDelConfirm = false;
          } else {
            this.messageLoader += "error in update<br/>";
            this.showErrorMessage = true;
            this.message = res.message;
          }
        } catch (e) {
          this.message = e;
          this.showErrorMessage = true;
          this.debug(e);
          this.messageLoader += "failed<br/>";
        }
        await this.refreshForum();
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async DeletePost() {
      if (await this.isLoggedInOIDC()) {
        this.isLoading = true;
        this.debug("Delete Post" + this.postId);
        //delete post
        let res = await this.deleteForumPost(this.postId);
        this.debug("Post Deleted");
        if (res.ok == true) {
          this.goToMyTopics(this.accessTokenDecoded.sub);
        }
        this.isLoading = false;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    handleClick() {
      this.debug("Report");
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.showAcknowledge = true;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    closeAcknowledge() {
      this.showAcknowledge = false;
      this.acknowledgeCounter++;
      this.acknowledgeKey = `acknowledge${this.acknowledgeCounter}`;
    },

    showDeletePost() {
      this.showDelConfirm = true;
      this.deleteReply = false;
    },

    showDeleteReply(idToDelete) {
      this.showDelConfirm = true;
      this.deleteReply = true;
      this.selectedReplyIdToDelete = idToDelete;
    },
  }, // methods
};
</script>
