<template>
  <v-btn @click="clicked()" v-if="button" class="mgbtn black--text">
    <v-badge
      transition="slide-x-transition"
      left
      :color="$store.state.unreadMessages != `0` ? `green` : `transparent`"
      :content="
        $store.state.unreadMessages != `0` ? $store.state.unreadMessages : ''
      "
      overlap
      offset-y="10"
      offset-x="-10"
    >
      <v-icon left color="green"> {{ mdiMessage }} </v-icon>
    </v-badge>
    {{ $t(`message`) }}
  </v-btn>
  <v-badge
    transition="slide-x-transition"
    v-else
    :color="$store.state.unreadMessages != `0` ? `brown` : `transparent`"
    :content="
      $store.state.unreadMessages != `0` ? $store.state.unreadMessages : ''
    "
    overlap
  >
    <v-icon color="green" @click="clicked()"> {{ mdiMessage }} </v-icon>
  </v-badge>
</template>

<script>
import { mdiMessageBulleted } from "@mdi/js";

export default {
  components: {},
  name: "MessageWithBadge",
  props: ["button"],
  data: () => ({
    mdiMessage: mdiMessageBulleted,
  }),
  methods: {
    clicked() {
      this.$emit("btn-clicked");
    },
  },
};
</script>
