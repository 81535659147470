<template>
  <!--<!doctype html>-->
  <html xmlns="http://www.w3.org/1999/xhtml" lang xml:lang>
    <head>
      <meta charset="utf-8" />
      <meta name="Social Login Guide" content="Social Login Guide" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes"
      />
      <title>Social Login Guide</title>

      <!--[if lt IE 9]>
        <script src="//cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv-printshiv.min.js"></script>
      <![endif]-->
    </head>
    <body class="ml-1">
      <div class="ma-2">
        <h2 class="text-center">Third Party / Social Login</h2>
        <!-- <div v-html="content"></div>-->
      </div>
      <h5 id="sales-agents"></h5>
      <p>
        MobilityGuru supports new user registrations using email and password as
        well as new registrations using your information provided by third party
        identity providers such as Facebook and Google.
      </p>
      <p>
        Linking third party providers to existing registrations that share the
        same email is also possible.
      </p>
      <p>
        A MobilityGuru account is created regardless of whether you register
        using an email / password or sign in for the first time using a third
        party identity provider.
      </p>
      <p>
        Any future sign ins using a different third party identity provider that
        has the same email as your previous third party identity provider used
        will result in you able to link them together to the same MobilityGuru
        account.
      </p>

      <h3>
        Linking Your Third Party Account to an Existing MobilityGuru Account
      </h3>
      <br />
      <v-img
        :src="require(`./pics/ThirdPartyLoginGuide/thirdPartyLoginSelect.png`)"
        :max-width="imageWidth"
        contain
        alt="third party login select"
      />
      <p>
        If you've signed up using an email and password before then use Facebook
        (that also uses the same email) to log in, you will see this message:
      </p>
      <v-img
        :src="require(`./pics/ThirdPartyLoginGuide/addToExisting.png`)"
        contain
        :max-width="imageWidth"
        alt="add to existing account"
      />
      <p>
        Please click on <b>Add to existing account</b> to link your Facebook
        account to your previously registered MobilityGuru account.
      </p>
      <p>
        On the next screen please <b>Log in</b> with your email / password to
        link your Facebook account. This linking only needs to be done once
      </p>
      <v-img
        :src="
          require(`./pics/ThirdPartyLoginGuide/authenticateWithPasswordAccount.png`)
        "
        contain
        :width="imageWidth"
        alt="add to existing account"
      />

      <br />
      <p>
        This linking method also works if you sign in using other third party
        providers (eg. Google) after you've already registered for a
        MobilityGuru account that has the same email.
      </p>

      <h3>
        Linking Your Third Party Account to an Existing MobilityGuru Account
        Registered Using a Third Party
      </h3>
      <br />
      <p>
        It is also possible that you've never signed up before using email and a
        password but instead had used a previous third party provider (eg.
        Google) to indirectly create a MobilityGuru account (it is created
        automatically on first signing in).
      </p>
      <p>
        It is possible to link another third party provider (eg. Facebook) to
        your MobilityGuru account created using the previously signed in third
        party account (eg. Google).
      </p>

      <p>In the login screen, you Sign in with Facebook</p>
      <v-img
        :src="
          require(`./pics/ThirdPartyLoginGuide/authenticateWithFacebookAccount.png`)
        "
        contain
        :width="imageWidth"
        alt="add to existing account"
      />
      <p>
        Then see the "Account already exists" window. Click
        <b>Add to existing account.</b>
      </p>

      <v-img
        :src="require(`./pics/ThirdPartyLoginGuide/addToExisting.png`)"
        contain
        :max-width="imageWidth"
        alt="add to existing account"
      />
      <p>
        Sign in with Google to link Facebook (with the previous MobilityGuru
        account) that was created on the first sign in using your Google
        account.
      </p>

      <v-img
        :src="
          require(`./pics/ThirdPartyLoginGuide/authenticateWithGoogleAccount.png`)
        "
        contain
        :max-width="imageWidth"
        alt="authenticate with Google account"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </body>
    <!-- <Loading :isLoading="isLoading"></Loading>-->
  </html>
</template>
<style>
code {
  white-space: pre-wrap;
}
span.smallcaps {
  font-variant: small-caps;
}
span.underline {
  text-decoration: underline;
}
div.column {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
div.hanging-indent {
  margin-left: 1.5em;
  text-indent: -1.5em;
}
ul.task-list {
  list-style: none;
}
</style>

<script>
export default {
  name: "ThirdPartyLoginGuide.vue",
  components: {},
  metaInfo: {
    title: "Third Pary Login Guide",
    meta: [
      {
        name: "Third Pary Login Guide",
        content: "Third Pary Login Guide",
      },
    ],
  },
  data: () => ({
    imageWidth: "600px",
  }),
  mounted: async function () {},
  methods: {},
};
</script>
