<template>
  <div>
    <v-tooltip top v-if="tooltip != null">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <span
            v-if="itemName == 'Select Country' && !$vuetify.breakpoint.xs"
            style="font-size: 20px"
            class="font-weight-bold"
            >{{ itemName }} &nbsp;</span
          >
          <span v-else class="subtitle-1 font-weight-bold"
            >{{ itemName }} &nbsp;</span
          >
        </span>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <span v-else>
      <span
        v-if="itemName == 'Select Country' && !$vuetify.breakpoint.xs"
        style="font-size: 20px"
        class="font-weight-bold"
        >{{ itemName }} &nbsp;</span
      >
      <span v-else class="subtitle-1 font-weight-bold"
        >{{ itemName }} &nbsp;</span
      >
    </span>

    <v-menu max-height="500" border-radius>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top v-if="tooltip != null">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :small="$vuetify.breakpoint.xs ? true : false"
              color="fillColour"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              :disabled="disabled"
            >
              <v-icon left large color="green">
                {{ mdiMenuDownOutline }}
              </v-icon>

              <span class="body-2">{{ dropDownItem }}</span>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
        <v-btn
          :small="$vuetify.breakpoint.xs ? true : false"
          v-else
          color="fillColour"
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
          :disabled="disabled"
        >
          <v-icon left large color="green">
            {{ mdiMenuDownOutline }}
          </v-icon>

          <span class="body-2">{{ dropDownItem }}</span>
        </v-btn>
      </template>
      <v-card
        style="display: flex !important; flex-direction: column"
        max-height="500"
        class="mgdrop mx-auto"
      >
        <v-card-text style="overflow: auto">
          <v-list dense>
            <v-list-item
              v-for="(item, index) in dropDownItems"
              :key="index"
              @click="updateDropDownItems(index)"
            >
              <v-list-item-title class="body-1 ma-1">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiMenuDownOutline } from "@mdi/js";

export default {
  name: "MyDropDown",
  props: ["itemName", "dropDownItem", "dropDownItems", "disabled", "tooltip"],

  data: () => ({
    mdiMenuDownOutline: mdiMenuDownOutline,
  }),

  methods: {
    updateDropDownItems(index) {
      this.$emit("update-items", index);
    },
  },
};
</script>
