<template>
  <v-dialog v-model="isShowResetPasswordLocal" width="800">
    <v-card>
      <MyCardTitle
        title="resetPassword"
        @close-window="closeWindow()"
      ></MyCardTitle>
      <br />

      <v-card-text>
        <v-layout justify-center>
          <h3>
            {{ $t(`minimumCharacters`) }}
          </h3>
        </v-layout>

        <div class="flex-container-column">
          <v-text-field
            :label="$t('obscurePw1')"
            v-model="newPw"
            class="flex-center"
            :type="showNewPassword ? 'text' : 'password'"
            :prepend-icon="mdiLockPlus"
            :append-icon="showNewPassword ? mdiEye : mdiEyeOff"
            @click:append="showNewPassword = !showNewPassword"
            :rules="[rules.required, rules.passwordMin]"
          >
          </v-text-field>

          <v-text-field
            :label="$t('obscurePw2')"
            v-model="newPw2"
            class="flex-center"
            :type="showNewPassword2 ? 'text' : 'password'"
            :prepend-icon="mdiLockPlus"
            :append-icon="showNewPassword2 ? mdiEye : mdiEyeOff"
            @click:append="showNewPassword2 = !showNewPassword2"
            :rules="[rules.required, rules.passwordMin]"
          >
          </v-text-field>

          <v-divider></v-divider>

          <br />
          <p v-if="showErrorMessage" class="fail flex-center">
            {{ message }}
          </p>

          <p v-if="!showErrorMessage" class="ok flex-center">
            {{ message }}
          </p>

          <div v-if="waiting">
            <v-progress-linear indeterminate> </v-progress-linear>
          </div>
          <br />

          <MyBtn
            btnName="saveNewPassword"
            :iconName="mdiContentSave"
            @btn-clicked="changePw()"
            :disabled="waiting"
          >
          </MyBtn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave, mdiEye, mdiEyeOff, mdiLockPlus } from "@mdi/js";

import Config from "../config.js";

import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";

export default {
  name: "ResetPW",
  components: {
    MyCardTitle,
    MyBtn,
  },
  props: ["isShowResetPassword"],
  data: () => ({
    isShowResetPasswordLocal: false,
    mdiContentSave: mdiContentSave,
    mdiEyeOff: mdiEyeOff,
    mdiEye: mdiEye,
    mdiLockPlus: mdiLockPlus,

    tokens: null,
    accessTokenDecoded: {},

    newPw: "",
    showNewPassword: false,
    newPw2: "",
    showNewPassword2: false,

    showErrorMessage: false,
    message: "",

    isShowSuccess: false,

    resetPWStatusMessage: "",
    acknowledgeOk: true,
    PASSWORD_MIN_LENGTH: Config.PASSWORD_MIN_LENGTH,

    waiting: false,

    rules: {
      required: (value) => value.length > 0 || "Required.",
      passwordMin: (value) =>
        value.length >= Config.PASSWORD_MIN_LENGTH ||
        "Min " + Config.PASSWORD_MIN_LENGTH + " characters",
    },
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.isShowResetPasswordLocal = this.isShowResetPassword;
      this.tokens = this.$store.state.tokens;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
    },

    async changePw() {
      let ok = false;
      let message = "";
      this.waiting = true;

      if ((await this.isLoggedInOIDC()) == true) {
        if (this.newPw == "" || this.newPw2 == "") {
          this.message = "New passwords can't be empty";
          this.showErrorMessage = true;
        } else if (this.newPw.length < Config.PASSWORD_MIN_LENGTH) {
          this.message = `Password should have minimum ${Config.PASSWORD_MIN_LENGTH} characters`;
          this.showErrorMessage = true;
        } else if (this.newPw != this.newPw2) {
          this.message = "New passwords doesn't match";
          this.showErrorMessage = true;
        } else {
          // eg. http://localhost:3000/profileUpdate?given_name="abracadabra"
          let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_CHANGE_PASSWORD}`;

          let headers = {
            Authorization: `${this.accessTokenDecoded.typ} ${this.tokens.access_token}`,
            Accept: "application/json",
          };

          let payload = new FormData();
          payload.append("password", this.newPw);

          let fetchData = {
            method: "POST",
            headers: headers,
            body: payload,
          };

          try {
            let res = await fetch(url, fetchData);

            let jsonRes = await res.json();
            this.printJson(jsonRes, "POST change pw result");

            this.closeWindow();

            this.newPw = "";
            this.newPw2 = "";

            ok = jsonRes.ok;
            message = jsonRes.message;
            this.debug(`ok = ${ok}`);
            this.acknowledgeOk = ok;
            this.message = "Password changed ";
            if (ok === true) {
              this.message += "successfully.";
            } else {
              this.message += `failed. ${message}`;
            }
            this.resetPWStatusMessage = "Password changed successful!";
            this.debug(`resetPWStatusMessage = ${this.resetPWStatusMessage}`);

            this.emitChangePasswordStatus(this.resetPWStatusMessage, ok);
          } catch (e) {
            this.acknowledgeOk = ok;
            this.resetPWStatusMessage = e;

            this.emitChangePasswordStatus(this.resetPWStatusMessage, ok);
            console.error(e);
          }
        }
      }
      this.waiting = false;

      return ok;
    },

    gotoLogin() {
      this.isShowSuccess = false;

      this.$router.push(Config.ROUTE_LOGIN);
    },

    closeWindow() {
      this.$emit("close-window");
    },

    emitChangePasswordStatus(message, ok) {
      this.$emit("resetPW-status", message, ok);
    },
  },
};
</script>
