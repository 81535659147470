<template>
  <v-row justify="center" no-gutters>
    <v-card
      width="1600"
      class="mx-auto"
      color="transparent"
      elevation="0"
      id="transparentBack"
    >
      <v-navigation-drawer
        id="scroll-target"
        elevation="0"
        class="overflow-y-auto fillColour"
        height="400px"
        width="280px"
        v-model="drawer"
        absolute
      >
        <MyCardTitle
          :title="$t(`articles`)"
          @close-window="drawer = !drawer"
        ></MyCardTitle>
        <v-row justify="center" no-gutters>
          <MyBtn
            v-if="showPostTopicButton"
            class="ma-1"
            btnName="post"
            @btn-clicked="goToPostNewArticle()"
          ></MyBtn>
        </v-row>
        <hr />
        <v-card elevation="0" class="mx-auto" max-width="300" tile>
          <v-list-item-title
            style="font-weight: 900"
            class="text-center fillColour"
            >{{ $t(`category`) }}</v-list-item-title
          >
          <v-list-item-group color="primary">
            <v-list-item
              v-if="category !== undefined && category.length > 0"
              class="fillColour"
            >
              <v-list-item-content @click="$router.push(ROUTE_ARTICLES)">
                <v-list-item-title>{{ $t(`showAll`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="fillColour"
              v-for="(item, index) in this.FORUM_CATEGORIES"
              :key="index"
            >
              <v-list-item-content @click="goToCategory(item)">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </v-navigation-drawer>

      <v-col cols="12" class="pa-0">
        <v-card-title>
          <div class="">
            <MyBtn
              class="ma-1 hidden-sm-and-down"
              @btn-clicked="hideLeft()"
              btnName="category"
              :iconName="mdiForum"
            ></MyBtn>
            <v-icon
              left
              color="green"
              large
              class="hidden-md-and-up"
              @click="drawer = !drawer"
              >{{ mdiMenu }}</v-icon
            >
            <span>&nbsp;</span>
          </div>
          <v-row class="justify-center" no-gutters>
            <h3>{{ $t(`blog`) }}</h3>
          </v-row>
        </v-card-title>
      </v-col>

      <!--
        Listing articles section
      -->

      <v-row class="justify-center" no-gutters>
        <v-container v-if="this.articles.length > 0">
          <v-card v-for="(article, index) in this.articles" :key="index">
            <!--
              ArticleCell.vue will get the json object article and display the data isdie that. 
            -->
            <ArticleCell :article="article"></ArticleCell>
          </v-card>
        </v-container>

        <h6 v-else v-show="isLoading == false" class="caption">
          {{ $t(`no`) }}
        </h6>
      </v-row>
      <v-row v-show="isLoading" justify="center">
        <v-container>
          <v-card v-for="n in 5" :key="n" class="ma-1" outlined>
            <SkeletonLoader type="article"></SkeletonLoader>
          </v-card>
        </v-container>
      </v-row>
    </v-card>
    <!--<Loading :isLoading="isLoading"></Loading>-->
  </v-row>
</template>

<script>
import { mdiForum, mdiMenu } from "@mdi/js";

import Config from "../config.js";
//import Loading from "./Loading";
import ArticleCell from "./ArticleCell.vue";
import MyBtn from "./MyBtn";
import MyCardTitle from "./MyCardTitle";
import SkeletonLoader from "./SkeletonLoader.vue";

export default {
  name: "ArticlesListHelper",
  components: {
    //Loading,
    MyBtn,
    MyCardTitle,
    ArticleCell,
    SkeletonLoader,
  },

  metaInfo: {
    title: "Mobility Guru Blog",
    meta: [
      {
        name: "description",
        content:
          "Ask for help, help other members or request new website features.",
      },
      {
        name: "keywords",
        content:
          "forum, help, ask, aid, questions, answers, threads, knowledgebase,  this.category",
      },
    ],
  },

  // props: ["article"],

  data: () => ({
    mdiForum: mdiForum,
    mdiMenu: mdiMenu,
    hideFilter: true,
    drawer: false,
    category: "",
    ROUTE_LOGIN: "",
    collectionArticles: null,
    accessTokenDecoded: {},
    levels: [],

    FORUM_CATEGORIES: [],
    articles: [],
    isLoading: false,
    ROUTE_EDIT_ARTICLE: Config.ROUTE_EDIT_ARTICLE,
    ROUTE_ARTICLE_CELL: Config.ROUTE_ARTICLE_CELL,
    ROUTE_ARTICLES: Config.ROUTE_ARTICLES,
  }),
  mounted: function () {
    this.init();
  },

  computed: {
    showPostTopicButton() {
      let showTopic = false;
      if (
        this.$store.state.isSuperAdmin === true ||
        this.$store.state.isForumModerator == true
      ) {
        showTopic = true;
        this.debug(`showTopic = ${showTopic}`);
      }

      return showTopic;
    },
  },

  watch: {
    async $route() {
      await this.init();
    },
  },

  methods: {
    //This function will fetch all the articles from the database and will  assign it to artcles array for displaying
    async init() {
      this.articles = [];
      this.isLoading = true;
      let results = {};

      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.category = this.$route.query.category;

      this.FORUM_CATEGORIES = Config.FORUM_CATEGORIES;
      this.debug("Forum Categories" + this.FORUM_CATEGORIES);
      this.ROUTE_LOGIN = Config.ROUTE_LOGIN;
      if (this.category !== undefined) {
        this.levels = ["category"];
        results = await this.refreshArticles("category", this.category);
        this.printJson(this.articles, "Collection Articles in Articles");
      } else {
        this.category = "";
        results = await this.refreshArticles("category", this.category);
      }
      if (results.ok == true) {
        this.articles = results.articles;
      }
      this.isLoading = false;
    },

    hideLeft() {
      this.drawer = !this.drawer;
    },

    async goToCategory(item) {
      this.debug("item " + item);
      if (item == this.FORUM_CATEGORIES[0]) {
        this.category = item;
      }
      if (item == this.FORUM_CATEGORIES[1]) {
        this.category = item;
      }
      if (item == this.FORUM_CATEGORIES[2]) {
        this.category = item;
      }

      await this.$router.push({
        path: Config.ROUTE_ARTICLES,
        query: {
          category: this.category,
        },
      });
    },

    async goToPostNewArticle() {
      await this.$router.push(Config.ROUTE_SUBMIT_ARTICLE);
    },
  },
};
</script>
