<template>
  <v-card
    width="800"
    class="mx-auto mt-5"
    :key="RotatingBannerSetupKey"
    elevation="0"
    color="transparent"
  >
    <v-card-title>
      <h1 class="display-1">
        <span>{{ $t(`new_banner_ad_title`) }}</span>
      </h1>
      <p>
        {{ $t(`new_banner_ad_subtitle`) }}
        <span class="notranslate"
          >{{ bannerPrice }} {{ currencyCode }} {{ $t(`or`) }}
          {{ bannerPriceGuru }} {{ $t(`guru`) }}</span
        >.
      </p>
    </v-card-title>
    <v-container :key="containerKey">
      <RotatingBannerAdImages
        class="mt-2"
        :key="RotatingBannerAdImagesKey"
        @banner-deleted="init"
        @rotatingBannerAd-changed="updateRotatingBannerAd"
        v-show="showRotatingBannerAdImages"
      ></RotatingBannerAdImages>
      <v-row justify="center" no-gutters v-show="showPaypalButton === true">
        <!--  <PayPal
          :amount="amount"
          currency="AUD"
          :client="credentials"
          :env="payPalEnv"
          :items="myItems"
          :experience="experienceOptions"
          v-on:payment-authorized="paymentAuthorized"
          v-on:payment-completed="paymentCompleted"
          v-on:payment-cancelled="paymentCancelled"
          :button-style="btnStyle"
          :key="payPalBtnKey"
        >
        </PayPal>
        <div ref="paypal"></div>-->
        <PayPalCheckout
          :items="myItems"
          :key="payPalBtnKey"
          :client_id="client_id"
          :product="product"
          :currency="currencyCode"
          @payment-completed="paymentCompleted"
          @payment-cancelled="paymentCancelled"
          @payment-error="paymentError"
        ></PayPalCheckout>
      </v-row>
      <br />
      <h2 class="text-center" v-if="showPaypalButton === true">
        {{ $t(`or`) }}
      </h2>
      <v-row
        justify="center"
        v-if="showPaypalButton === true"
        no-gutters
        class="mt-1 text-center"
      >
        <DepayWidget
          :payment="true"
          :amount="bannerPriceGuru"
          :showBuyGuru="true"
          @depay-payment-confirmed="depayPaymentConfirmed"
        ></DepayWidget>
      </v-row>
      <br />
      <span v-if="paymentExpiredMessage.length > 0">{{
        paymentExpiredMessage
      }}</span>
    </v-container>

    <Loading
      :isLoading="isLoading"
      :message="messageLoader"
      :percentage="percentageLoader"
    ></Loading>
    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      @btn-clicked="closeMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>
  </v-card>
</template>

<script>
//import PayPal from "vue-paypal-checkout";
import Loading from "./Loading";
import MyAcknowledge from "./MyAcknowledge";
import Config from "../config.js";
//import MyAcknowledge from "./MyAcknowledge";
import PayPalCheckout from "./PayPalCheckout";
import RotatingBannerAdImages from "./RotatingBannerAdImages";
import DepayWidget from "./DepayWidget.vue";
export default {
  name: "RotatingBannerSetup",
  components: {
    Loading,
    MyAcknowledge,
    PayPalCheckout,
    RotatingBannerAdImages,
    DepayWidget,
  },

  computed: {},
  metaInfo: {
    title: "Rotating Banner | Mobility Guru",
    meta: [
      {
        name: "description",
        content: "Rotating Banner, paid features",
      },
      {
        name: "keywords",
        content: "banner",
      },
    ],
  },
  data: () => ({
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",
    isLoading: false,
    messageLoader: "",
    percentageLoader: null,
    rotatingBannerAd: {},
    collectionProfiles: null,
    accessTokenDecoded: null,
    showImage: false,
    imageUrl: "",

    // current state of list of files (unsaved to Kinto database)
    draftFiles: [],
    /* Current state of list of files as absolute URLs for ad (unsaved to
     * Kinto database)
     */
    draftFilesURLs: [],

    // Original ads list of files
    //adsFiles: [],
    // Original list of files as absolute URLs the ad has.
    adsFilesURLs: [],

    currentNumDraftFiles: 0,
    // rotationAngles: [],
    maxFiles: 1,

    uploadMultBtnKey: 10,

    acknowledgeMessage: "",
    acknowledgeTitle: "",
    showAcknowledge: false,
    containerKey: 0,
    rotatingBannerAdOrig: null,

    showCropper: false,
    cropperTitle: "",
    imgSrc: "",
    selectedImageIndex: "",
    selectedImageName: "",
    RotatingBannerAdImagesIndex: 99,
    RotatingBannerAdImagesKey: "rotatingbanner0",
    RotatingBannerSetupIndex: 0,
    RotatingBannerSetupKey: "rotatingBannerSetup0",
    /*credentials: {
      sandbox: Config.PAYPAL_SANDBOX_CLIENT_ID,
      production: Config.PAYPAL_PRODUCTION_CLIENT_ID,
    },*/
    client_id: "",

    bannerId: "",

    //payPalEnv: "",
    //amount: "1",

    //payPalBtnKeyVal: 0,
    payPalBtnKey: "payPalBtnKey0",

    myItems: [
      {
        description: "some description",
        quantity: "1",
        unit_amount: {
          currency_code: "AUD",
          value: "30.00",
        },
        name: "Rotating Banner Ad",
      },
    ],
    /*
    experienceOptions: {
      input_fields: {
        no_shipping: 1,
      },
    },
    btnStyle: {
      label: "pay",
      size: "small",
      shape: "rect",
      color: "blue",
    },*/
    product: {
      price: "30.00",
      description: "Rotating Banner Ad",
    },
    showPaypalButton: false,
    showRotatingBannerAdImages: false,
    paymentPending: true,
    paymentExpiredMessage: "",
    rules: {
      required: (value) => value.length > 0 || "Required.",
      positive: (value) =>
        value.length == 0 || parseFloat(value) > 0 || "Must be larger than 0",
      counter30: (value) =>
        value.length <= Config.TITLE_MAX ||
        "Max " + Config.TITLE_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
    },

    currencyCode: "AUD",
    bannerPrice: "30.00",
    bannerPriceGuru: "2400",
  }),
  /*mounted: function () {
    this.init();
    /* const script = document.createElement("script");
    let client_id = "";

    if (Config.IS_DEV === true) {
      client_id = Config.PAYPAL_SANDBOX_CLIENT_ID;
    } else {
      client_id = Config.PAYPAL_PRODUCTION_CLIENT_ID;
    }
    script.src = `https://www.paypal.com/sdk/js?client-id=${client_id}&currency=AUD`;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);*/
  //},
  mounted: async function () {
    if (await this.isLoggedInOIDC(true)) {
      await this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },
  methods: {
    paymentAuthorized: function (data) {
      this.debug("payment authorized");
      this.debug(data);
      //this.pay(data);
    },
    paymentCompleted: function (data) {
      this.debug("paymemt completed");
      this.debug(data);
      let paymentData = data[0];
      paymentData["paymentType"] = "paypal";
      this.createRotatingBannerAd(paymentData);
    },
    paymentCancelled: function (data) {
      this.debug("payment cancelled");
      this.debug(data);
    },

    paymentError: function (err) {
      this.debug("payment error");
      this.debug(err);
      this.setAcknowledgeError("Order not completed");
    },
    depayPaymentConfirmed(data) {
      this.debug("paymemt completed");
      this.debug(data);
      let paymentData = {};
      data["guruAmount"] = this.bannerPriceGuru;
      data["token"] = Config.GURU_TOKEN_ADDRESS;
      paymentData["depayData"] = data;
      paymentData["paymentType"] = "depay";
      this.createRotatingBannerAd(paymentData);
    },
    async init() {
      this.isLoading = true;
      this.debug(`cnfig ${Config.PAYPAL_SANDBOX_CLIENT_ID}`);

      if (Config.IS_DEV === true) {
        //  this.payPalEnv = "sandbox";
        this.client_id = Config.PAYPAL_SANDBOX_CLIENT_ID;
      } else {
        //this.payPalEnv = "production";
        this.client_id = Config.PAYPAL_PRODUCTION_CLIENT_ID;
      }
      // this.debug(`payPalEnv ${this.payPalEnv}`);
      /* this.payPalBtnKey++;
      this.payPalBtnKey = "payPalBtnKey" + this.payPalBtnKey;*/
      let result = await this.getRotatingBannerAdPricing(
        this.$store.state.countryByIP
      );
      if (result.ok == true) {
        let priceRecord = result.priceRecord;
        this.printJson(priceRecord, "returned price record");
        if ("price" in priceRecord) {
          this.bannerPrice = priceRecord.price;
        }
        if ("currencyCode" in priceRecord) {
          this.currencyCode = priceRecord.currencyCode;
        }
        if ("priceGuru" in priceRecord) {
          this.bannerPriceGuru = priceRecord.priceGuru;
        }
      }

      this.currencyCode = this.currencyCode.toString();

      this.product.price = this.bannerPrice;
      this.myItems[0].unit_amount.currency_code = this.currencyCode;
      this.myItems[0].unit_amount.value = this.bannerPrice;
      /*this.payPalBtnKey++;
      this.payPalBtnKey = "payPalBtnKey" + this.payPalBtnKey;

      this.RotatingBannerAdImagesIndex++;
      this.RotatingBannerAdImagesKey = `rotatingbanner0${this.RotatingBannerAdImagesIndex}`;*/
      this.mainInit();

      let profilesResult = await this.getProfilesRecord();

      let profilesRecord = {};
      if (profilesResult.ok === true) {
        profilesRecord = profilesResult.result.data;
        if ("rotatingBannerAdIds" in profilesRecord) {
          if (profilesRecord.rotatingBannerAdIds.length > 0) {
            this.debug(`rotatingBannerAds does exist`);
            this.showPaypalButton = false;
            this.bannerId = profilesRecord.rotatingBannerAdIds[0];

            let result = await this.getRotatingBannerAd(this.bannerId);
            let daysLeftText = "";
            if (result.ok) {
              this.rotatingBannerAd = result.bannerAd;
              daysLeftText = this.getDaysLeftNice(
                this.rotatingBannerAd.daysLeft,
                this.rotatingBannerAd.lastPublished
              );

              if (daysLeftText === "Expired") {
                this.showRotatingBannerAdImages = false;
                this.showPaypalButton = true;
              } else {
                this.showRotatingBannerAdImages = true;
                this.showPaypalButton = false;
              }
            }
          } else {
            this.debug(`rotatingBannerAds does not exist`);
            this.showPaypalButton = true;
            this.showRotatingBannerAdImages = false;
            this.paymentPending = false;
          }
        } else {
          this.showPaypalButton = true;
        }
      } else {
        this.debug("error in profile access");
        this.showPaypalButton = true;
        this.paymentPending = false;
      }

      /*  let i;
      for (i = 0; i < this.maxFiles; i++) {
        this.draftFiles[i] = null;
      }
      this.currentNumDraftFiles = 0;
      this.draftFilesURLs[this.currentNumDraftFiles] = "";*/
      this.debug(`price = ${this.currencyCode} ${this.bannerPrice}`);
      this.printJson(this.product);
      this.printJson(this.myItems);
      this.payPalBtnKey++;
      this.payPalBtnKey = "payPalBtnKey" + this.payPalBtnKey;

      this.printJson(this.rotatingBannerAd);
      this.RotatingBannerAdImagesIndex++;
      this.RotatingBannerAdImagesKey = `rotatingbanner0${this.RotatingBannerAdImagesIndex}`;

      await this.synchSetTimeout(1);
      this.isLoading = false;
      await this.synchSetTimeout(1);
    },

    setAcknowledgeError(message) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = "Error!";
      this.showAcknowledge = true;
    },

    setAcknowledge(message, title) {
      this.acknowledgeMessage = message;
      this.acknowledgeTitle = title;
      this.showAcknowledge = true;
    },

    async createRotatingBannerAd(data) {
      let ok = false;
      this.isLoading = true;

      this.printJson(data, "data");

      let url = `${Config.NODE_SERVER_IP}${Config.CREATE_ROTATING_BANNER_AD}`;
      let headers = {
        Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        Accept: "application/json",
      };

      let payload = new FormData();
      payload.append("rotatingBannerAd", JSON.stringify(data));
      payload.append("country", this.$store.state.countryByIP);
      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      console.log(`url = ${url}`);
      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "createRotatingBannerAd");

        ok = jsonRes.ok;
        if (ok === true) {
          this.bannerId = jsonRes.bannerId;
        }
      } catch (e) {
        console.error(e);
      }

      if (ok === true) {
        this.showPaypalButton = false;
        this.showRotatingBannerAdImages = true;
      }

      this.isLoading = false;
      return ok;
    },

    async payOrig(data) {
      let ok = false;
      this.isLoading = true;

      //Add this aafter superadmin approval
      //let expiry = new Date();
      //expiry.setMonth(expiry.getMonth() + 6);

      this.printJson(
        data[0].payments.captures[0],
        "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq"
      );
      //  this.rotatingBannerAd["paid"] = data.transactions[0].amount.total;
      this.rotatingBannerAd["paypalData"] = data;
      let transactionId = data[0].payments.captures[0].id;
      let currency_code = data[0].payments.captures[0].amount.currency_code;
      let value = data[0].payments.captures[0].amount.value;
      let date = data[0].payments.captures[0].update_time;
      let username = data[0].payee.email_address;
      this.debug(`value ${value}`);

      this.rotatingBannerAd["paymentStatus"] = "pending";
      this.rotatingBannerAd["transactionId"] = transactionId;
      // this.rotatingBannerAd["dateListed"] = new Date();
      // this.rotatingBannerAd["expiry"] = expiry;

      let rotatingBannerAds = [];
      rotatingBannerAds.push(this.rotatingBannerAd);
      this.isLoading = false;
      this.showPaypalButton = false;
      this.paymentPending = true;
      this.RotatingBannerSetupIndex++;
      this.RotatingBannerSetupKey = `rotatingBannerSetup${this.RotatingBannerSetupIndex}`;
      this.containerKey++;
      let res = await this.updateProfile(
        rotatingBannerAds,
        transactionId,
        value,
        currency_code,
        date,
        username
      );
      ok = res.ok;
      this.debug(res, "returned object");

      if (ok === true) {
        this.showPaypalButton = false;
        //Make below condition false if using PayPal API and /isPaymenCleared endpoint from backdend00
        this.paymentPending = true;
        //this.paymentPending = false;
        this.RotatingBannerSetupIndex++;
        this.RotatingBannerSetupKey = `rotatingBannerSetup${this.RotatingBannerSetupIndex}`;
        this.containerKey++;
        //this.showImage = true;
        //this.debug(this.showImage);
        //this.$router.push(Config.ROUTE_PROFILE);
      } else {
        this.setAcknowledgeError("Captured paypal data is not updated");
        //TO DO
      }

      this.isLoading = false;
    },

    async updateProfile(
      rotatingBannerAds,
      transactionId,
      value,
      currency_code,
      date,
      username
    ) {
      let ok = false;
      let message = "";
      const updatedProfile = {
        id: this.$store.state.accessTokenDecoded.sub,
        rotatingBannerAds: rotatingBannerAds,
      };

      try {
        let res = await this.updateProfilesRecord(updatedProfile);
        this.printJson(res, "profile update");

        ok = res.ok;
        if (ok === true) {
          let title = "New Order Recieved on PayPal - waiting approval";
          let message = `username: ${username}, Transaction id: ${transactionId}, Value: ${value}, Currency: ${currency_code}, Date: ${date}`;
          await this.sendDebugEmail(message, title);
        }
        message = res.message;
        this.printJson(res, "profile update");
      } catch (e) {
        this.debug("err " + e);
      }
      return { ok: ok, message: message };
    },

    async updateRotatingBannerAd(rotatingBannerAdUpdated) {
      this.rotatingBannerAd = rotatingBannerAdUpdated;
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },
  },
};
</script>
