<template>
  <v-card class="fillColour" elevation="0">
    <MyCardTitle
      title="filter"
      @close-window="hideDrawer()"
      style="font-size: 13px"
    ></MyCardTitle>
    <hr />

    <br />
    <v-card class="" elevation="0" color="fillColour">
      <v-row justify="center">
        <MyBtn
          v-if="showClearFilters"
          btnName="clear"
          :iconName="mdiDatabaseSearch"
          @btn-clicked="clearQueries"
        ></MyBtn>
      </v-row>
      <br />

      <v-row justify="center">
        <h3>{{ $t(`categories`) }}</h3>
      </v-row>

      <br />
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, index) in this.categories"
          :key="index"
        >
          <v-expansion-panel-header class="fillColour">
            <v-card
              max-height="30"
              elevation="0"
              class="panel"
              @click="routerModify('mainCategory', item.mainCategories, adType)"
            >
              <v-list-item dense>
                <v-list-item-icon>
                  <v-img
                    transition="scsale-transition"
                    height="25"
                    width="25"
                    :src="item.avatar"
                    contain
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title>{{
                  $t(`${item.mainCategories}`)
                }}</v-list-item-title>
              </v-list-item>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="fillColour"
            v-for="(subCategory, index) in item.subCategories"
            :key="index"
          >
            <v-card
              outlined
              elevation="0"
              @click="routerModify('subCategory', subCategory, adType)"
              class="ml-5 mt-n3 panel"
              align="left"
            >
              <v-list-item dense>
                <v-img
                  contain
                  height="25"
                  width="25"
                  :src="
                    subCategoryIconsImagePath[item.mainCategories][subCategory]
                  "
                ></v-img>
                <v-list-item>{{ $t(`${subCategory}`) }}</v-list-item>
              </v-list-item>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <hr />
      <br />
      <v-row justify="center">
        <h3>{{ $t(`search`) }}</h3>
      </v-row>
      <br />
    </v-card>

    <!-- Category Search (main & sub Categories)-->
    <v-text-field
      dense
      outlined
      class="my-text-style mt-2"
      :label="$t(`category`)"
      v-model="category"
      v-on:keyup.enter="routerModify('category', category, adType)"
    >
      <template v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mt-n2"
              color="brown darken-2"
              @click="routerModify('category', category, adType)"
              large
              v-on="on"
            >
              {{ mdiMagnify }}
            </v-icon>
          </template>
          <span>{{ $t(`category`) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <!-- Title Search-->
    <v-text-field
      dense
      outlined
      class="my-text-style"
      :label="$t(`title`)"
      v-model="title"
      v-on:keyup.enter="routerModify('title', title, adType)"
    >
      <template v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mt-n2"
              color="brown darken-2"
              @click="routerModify('title', title, adType)"
              large
              v-on="on"
            >
              {{ mdiMagnify }}
            </v-icon>
          </template>
          <span>{{ $t(`title`) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <!-- Suburb Search-->
    <v-text-field
      dense
      outlined
      class="my-text-style"
      :label="$t(`suburb`)"
      v-model="suburb"
      v-on:keyup.enter="routerModify('suburb', suburb, adType)"
    >
      <template v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mt-n2"
              color="brown darken-2"
              @click="routerModify('suburb', suburb, adType)"
              large
              v-on="on"
            >
              {{ mdiMagnify }}
            </v-icon>
          </template>
          <span>{{ $t(`suburb`) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <!-- Postcode Search-->
    <v-text-field
      dense
      outlined
      class="my-text-style"
      :label="$t(`postCode`)"
      v-model="postcode"
      type="number"
      v-on:keyup.enter="routerModify('postcode', postcode, adType)"
    >
      <template v-slot:append-outer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mt-n2"
              color="brown darken-2"
              @click="routerModify('postcode', postcode, adType)"
              large
              v-on="on"
            >
              {{ mdiMagnify }}
            </v-icon>
          </template>
          <span>{{ $t(`postCode`) }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <!-- Search by published status-->
    <MyDropDown
      class="ml-2"
      v-if="this.$route.path == this.ROUTE_USER_ADS"
      :itemName="$t('state')"
      :dropDownItem="setAdStatus"
      :dropDownItems="adStatusList"
      @update-items="updateAdStatus"
    ></MyDropDown>
    <br />

    <!-- State Search-->

    <!--<MyDropDown
      class="ml-3"
      v-if="showState"
      itemName="States"
      :dropDownItem="setState"
      :dropDownItems="$store.state.states"
      @update-items="updateStateFilter"
    ></MyDropDown>-->

    <SelectState
      tooltip="If you notice any incorrect or missing states, please contact us."
      v-if="showState"
      class="ml-3"
      :states="states"
      :selectedState="setState"
      @state-finalized="updateStateFilter"
      :key="stateKey"
    >
    </SelectState>

    <!-- Condition Search-->

    <!--<MyDropDown
      v-if="showCondition"
      itemName="Condition"
      :dropDownItem="setCondition"
      :dropDownItems="condition"
      @update-items="updateState"
    ></MyDropDown>-->

    <v-col>
      <b>{{ $t(`condition`) }}</b>

      <v-btn-toggle v-model="toggle" color="#612E11" mandatory>
        <v-btn> {{ $t(`all`) }} </v-btn>
        <v-btn> {{ $t(`new`) }} </v-btn>

        <v-btn> {{ $t(`used`) }} </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-card elevation="0" height="150" class="fillColour"> </v-card>
  </v-card>
</template>
<script>
import { mdiDatabaseSearch, mdiMenu, mdiFilter, mdiMagnify } from "@mdi/js";

import MyDropDown from "./MyDropDown";
import MyCardTitle from "./MyCardTitle";
import Config from "../config.js";
import MyBtn from "./MyBtn";
import SelectState from "./SelectState";

// lodash
import debounce from "lodash/debounce";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
export default {
  name: "FilterSection",
  components: {
    MyDropDown,
    MyCardTitle,
    MyBtn,
    SelectState,
  },
  props: ["adType", "userId"],
  /*metaInfo() {
    return {
      title:
        this.mainCategory !== undefined && this.subCategory !== undefined
          ? this.subCategory
          : this.mainCategory !== undefined
          ? this.mainCategory
          : "Mobility Guru Ads",
      meta: [
        {
          name: "description",
          content:
            this.mainCategory !== undefined && this.subCategory !== undefined
              ? this.subCategory + " for sale"
              : this.mainCategory !== undefined
              ? "Mobility Guru " + this.mainCategory
              : "Ask for help, help other members or request new website features.",
        },
        {
          name: "keywords",
          content:
            this.mainCategory !== undefined && this.subCategory !== undefined
              ? this.subCategory
              : this.mainCategory !== undefined
              ? this.mainCategory
              : "advertisements, ads, live, public",
        },
      ],
    };
  },*/
  data: () => ({
    mdiDatabaseSearch: mdiDatabaseSearch,
    mdiMenu: mdiMenu,
    mdiFilter: mdiFilter,
    mdiMagnify: mdiMagnify,

    Title: "",
    drawer: false,
    subCategory: "",
    mainCategory: "",

    MAIN_CATEGORIES_ENUM: {},
    MAIN_CATEGORIES: [],
    SUB_CATEGORIES_LIST: [],
    AD_TYPES_ENUM: {},
    ROUTE_USER_ADS: "",

    ads: [],
    adsFilesFirst: [],
    suburb: "",
    title: "",
    postcode: "",
    dateToRefreshSuburbDone: false,
    setCondition: "",
    setAdStatus: "",
    category: "",
    categories: [],
    search: "",
    subCategoryIconsImagePath: {},

    condition: ["All", "New", "Used"],
    adStatusList: ["All", "Published", "Pending"],

    toggle: 0,
    states: [],

    //v-if="hideFilter === true"
    setState: "All",
    stateKey: 1,
  }),
  mounted: async function () {
    await this.init();
  },

  watch: {
    async $route() {
      await this.init();
    },
    setStates(newStates, oldStates) {
      // Our fancy notification (2).
      console.log(`${oldStates} --->>>${newStates}`);
      this.states = newStates;
    },

    /*Detect the changes in Confition toggle button */
    toggle: function () {
      if (this.toggle === -1) {
        this.toggle = 0;
      } else {
        this.debug(this.toggle);
        this.updateState(this.toggle);
      }
    },
    /* This watch the title text field and if it is empty the field will be removed from the route */
    title: debounce(function () {
      if (this.title === "") {
        this.routerModify("title", "", this.adType);
      }
    }, 1000),
    /* This watch the suburb text field and if it is empty the field will be removed from the route */
    suburb: debounce(function () {
      if (this.suburb === "") {
        this.routerModify("suburb", "", this.adType);
      }
    }, 1000),
    /* This watch the postcode text field and if it is empty the field will be removed from the route */
    postcode: debounce(function () {
      if (this.postcode === "") {
        this.routerModify("postcode", "", this.adType);
      }
    }, 1000),
    /* This watch the postcode text field and if it is empty the field will be removed from the route */
    category: debounce(function () {
      if (this.category === "") {
        this.routerModify("category", "", this.adType);
      }
    }, 1000),
    /* search: debounce(function () {
      if (this.search !== undefined && screen.width > 963) {
        this.hideLeft();
        //this.routerModify("category", "", this.adType);
      }
    }, 1000),*/
  },

  computed: {
    setStates() {
      return this.$store.state.states;
    },

    showCondition() {
      let conditionState;
      let removeMainCat = [
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.JOBS],
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.SERVICES],
        this.MAIN_CATEGORIES[this.MAIN_CATEGORIES_ENUM.TRAVEL],
      ];
      let ENUMofCat = [
        this.MAIN_CATEGORIES_ENUM.JOBS,
        this.MAIN_CATEGORIES_ENUM.SERVICES,
        this.MAIN_CATEGORIES_ENUM.TRAVEL,
      ];
      if (this.mainCategory !== undefined) {
        let object = includes(removeMainCat, this.mainCategory);
        if (object === false) {
          conditionState = true;
        } else {
          conditionState = false;
        }
      } else if (this.category !== undefined) {
        let conArray = [];
        for (let i = 0; i < removeMainCat.length; i++) {
          let mainCategoryMatch = this.regexAdFilter(
            this.category,
            removeMainCat[i]
          );
          let searchArray = this.SUB_CATEGORIES_LIST[ENUMofCat[i]];
          for (let j = 0; j < searchArray.length; j++) {
            let subCategoryMatch = this.regexAdFilter(
              this.category,
              searchArray[j]
            );
            this.debug(mainCategoryMatch, subCategoryMatch);
            if (mainCategoryMatch !== null || subCategoryMatch !== null) {
              conArray.push(removeMainCat[i]);
            }
          }
        }
        this.debug("Array value:" + conArray);
        conditionState = isEmpty(conArray);
        this.debug("Check array:" + conditionState);
      }
      return conditionState;
    },

    showState() {
      let showState = true;
      if (this.$store.state.country === "All") {
        showState = false;
      }
      return showState;
    },

    showClearFilters() {
      let showFilter = false;
      if (
        this.$route.path == Config.ROUTE_LIVE_ADS ||
        this.$route.path == Config.ROUTE_MY_ADS ||
        this.$route.path == Config.ROUTE_USER_ADS
      ) {
        if (isEmpty(this.$route.query) === false) {
          showFilter = true;
        }
        /*if (
          Object.keys(this.$route.query).length == 1 &&
          this.$route.query.userId != undefined
        ) {
          showFilter = false;
        } else {
          showFilter = true;
        }*/
      }
      return showFilter;
    },
  },

  methods: {
    async init() {
      this.MAIN_CATEGORIES_ENUM = Config.MAIN_CATEGORIES_ENUM;
      this.MAIN_CATEGORIES = Config.MAIN_CATEGORIES;
      this.SUB_CATEGORIES_LIST = Config.SUB_CATEGORIES_LIST;
      this.AD_TYPES_ENUM = Config.AD_TYPES_ENUM;
      this.ROUTE_USER_ADS = Config.ROUTE_USER_ADS;
      this.states = this.$store.state.states;

      for (var i = 0; i < this.MAIN_CATEGORIES.length; i++) {
        this.categories[i] = {
          mainCategories: this.MAIN_CATEGORIES[i],
          subCategories: this.SUB_CATEGORIES_LIST[i],
          avatar: "/icons/" + this.MAIN_CATEGORIES[i] + ".svg",
        };

        let subCategoryImagesObject = {};

        if (this.SUB_CATEGORIES_LIST[i] !== undefined) {
          let selectedSubCategoriesForLoop = this.SUB_CATEGORIES_LIST[i];

          for (let j = 0; j < selectedSubCategoriesForLoop.length; j++) {
            if (
              selectedSubCategoriesForLoop[j] ==
              "Wheelchair & Scooter Parts/Accs"
            ) {
              subCategoryImagesObject[
                selectedSubCategoriesForLoop[j]
              ] = `/icons/${this.MAIN_CATEGORIES[i]}/Wheelchair & Scooter PartsAccs.png`;
            } else {
              subCategoryImagesObject[
                selectedSubCategoriesForLoop[j]
              ] = `/icons/${this.MAIN_CATEGORIES[i]}/${selectedSubCategoriesForLoop[j]}.png`;
            }
          }
          this.subCategoryIconsImagePath[this.MAIN_CATEGORIES[i]] =
            subCategoryImagesObject;
        }
      }
      /* This part is used for the saved URL to get the queries*/
      this.subCategory = this.$route.query.subCategory;
      this.mainCategory = this.$route.query.mainCategory;
      this.title = this.$route.query.title;
      this.suburb = this.$route.query.suburb;
      this.postcode = this.$route.query.postcode;
      this.search = this.$route.query.search;

      /*if (this.setCondition === undefined || this.setCondition === "") {
        this.setCondition = "All";
      } else */
      this.setCondition = this.$route.query.condition;
      if (this.setCondition === undefined) {
        this.setCondition = "All";
      }
      this.setAdStatus = this.$route.query.adStatus;
      if (this.setAdStatus === undefined) {
        this.setAdStatus = "All";
      }

      this.state = this.$route.query.state;
      if (this.state === undefined) {
        this.setState = "All";
      }

      this.category = this.$route.query.category;
    },
    hideDrawer() {
      this.$emit("hide-drawer");
    },

    async updateState(index) {
      this.setCondition = this.condition[index];
      this.debug("check condition" + this.setCondition);
      await this.routerModify("condition", this.setCondition, this.adType);
    },

    async updateStateFilter(index) {
      this.setState = this.$store.state.states[index];
      this.debug("check state of country" + this.setState);

      await this.routerModify("state", this.setState, this.adType);
    },

    clearQueries() {
      //this.toggle = -1;
      this.setState = "All";
      this.clearFilter(this.adType);
    },

    async updateAdStatus(index) {
      this.setAdStatus = this.adStatusList[index];
      this.debug("setAdStatus" + this.setAdStatus);
      await this.routerModify("adStatus", this.setAdStatus, this.adType);
    },

    /*async updateCountry(index) {
      let country = this.$store.state.countries[index];
      this.debug("check condition" + country);
      this.$store.commit("set", ["countryByIP", country]);
      location.reload(true);
      //await this.routerModify("condition", this.setCondition, this.adType);
    },*/
  },
};
</script>
