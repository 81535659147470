<template>
  <v-tooltip v-if="tooltip != null" top color="green">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        @click="clicked()"
        :disabled="disabled"
        class="mgbtn"
        :style="borderStyle"
      >
        <v-icon v-if="iconName != null" :color="color">
          &nbsp; {{ iconName }} &nbsp;
        </v-icon>

        <span v-if="btnName != null"> &nbsp; {{ $t(btnName) }} </span>
      </v-btn>
    </template>
    <span>{{ $t(tooltip) }}</span>
  </v-tooltip>

  <!-- tooltip = null -->

  <v-btn
    v-else
    @click="clicked()"
    :disabled="disabled"
    class="mgbtn"
    :style="borderStyle"
  >
    <v-icon v-if="iconName != null" left :color="color">
      {{ iconName }}
    </v-icon>

    <span v-if="btnName != null"> &nbsp; {{ $t(btnName) }} </span>
  </v-btn>
</template>

<script>
export default {
  name: "MyBtn",
  props: [
    "btnName",
    "disabled",
    "iconName",
    "tooltip",
    "iconColor",
    "lightBorder",
  ],

  data: () => ({
    color: "",
    borderStyle: "",
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    init() {
      this.initColorIcon();
    },

    clicked() {
      this.$emit("btn-clicked");
    },

    initColorIcon() {
      if (this.iconColor === undefined || this.iconColor === null) {
        this.color = "green";
      } else {
        this.color = this.iconColor;
      }

      if (this.lightBorder == true) {
        this.borderStyle = `border: 4px solid #8D6E63 !important;`;
      }
    },
  },
};
</script>
