<template>
  <SubmitArticleHelper :articleId="articleId"> </SubmitArticleHelper>
</template>
<script>
import Config from "../config.js";

import SubmitArticleHelper from "./SubmitArticleHelper";

export default {
  name: "EditArticle",
  components: {
    SubmitArticleHelper,
  },
  props: [],

  computed: {},

  data: () => ({
    articleId: "",
  }),

  validations: {},

  beforeMount: async function () {
    if (await this.isLoggedInOIDC()) {
      this.init();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {
    init() {
      this.articleId = this.$route.query.articleId;
      this.debug("EditArticle.vue articleId = " + this.articleId);
    },
  }, // methods
};
</script>
