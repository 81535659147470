<template>
  <v-card width="500" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">{{ $t(`myMobilityGuru`) }}</h1>
    </v-card-title>

    <v-divider></v-divider>

    <div v-if="this.$store.state.isLoggedIn">
      <v-card-text>
        <v-form class="flex-container-column">
          <v-row class="text-h6">
            <b>
              {{ $t(`Username/Email`) }}
              <span
                v-if="accessTokenDecoded.email_verified === true"
                class="green--text"
                >&nbsp; {{ $t(`verified`) }}</span
              >

              <span class="red--text" v-else
                >&nbsp; {{ $t(`unverified`) }}</span
              >
            </b>
            <CheckIcon
              height="20"
              width="50"
              v-if="accessTokenDecoded.email_verified === true"
              :check="accessTokenDecoded.email_verified"
              class="mt-1"
            ></CheckIcon>
            <v-spacer></v-spacer>

            <v-progress-circular
              color="primary"
              rounded
              indeterminate
              v-if="sendVerifyEmailLoading"
            >
            </v-progress-circular>
            <v-tooltip
              top
              v-if="
                accessTokenDecoded.email_verified === false &&
                sendVerifyEmailLoading === false
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="bg fg--text mb-2"
                  v-on="on"
                  @click="sendVerifyEmailCodeFromProfile()"
                >
                  <v-icon>{{ mdiEmailSendOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`link`) }}</span>
            </v-tooltip>
          </v-row>

          <!--   <v-row class="body-1 ml-4">
            <p>{{ accessTokenDecoded.preferred_username }}</p>
          </v-row>-->
          <v-row>
            <!--
            <v-text-field
              @keydown.space="preventLeadingSpace"
              :prepend-icon="mdiEmailVariant"
              v-model="username"
              placeholder="Please enter your email here."
              @input="usernameChanged()"
              :disabled="true"
              :rules="[rules.email, rules.email345, rules.required]"
            />
            -->

            <v-icon>{{ mdiEmailVariant }}</v-icon>
            <div class="body-1 ml-2 mt-1">
              <b>{{ username }}</b>
            </div>

            <!--
            <v-tooltip top :disabled="disableSaveUsername">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="disableSaveUsername"
                  class="bg fg--text"
                  v-on="on"
                  @click="saveUsername()"
                >
                  <v-icon>{{ mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Save Username</span>
            </v-tooltip>
            -->
          </v-row>
          <v-row v-if="usernameLoading === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>

          <br />
          <MyBtn
            :btnName="toggleShowEmailTipBtnName"
            @btn-clicked="toggleShowEmailTip()"
          >
          </MyBtn>
          <p v-if="showChangeEmailTip">
            {{ $t(`toChange`) }}
            <b>{{ $t(`deleteAccount`) }}</b> {{ $t(`below`) }}
          </p>
          <br />
          <div
            v-if="
              showEmailVerificationCodeInput &&
              accessTokenDecoded.email_verified == false
            "
          >
            <br />
            <v-row>
              <v-text-field
                :label="$t('verificationCode')"
                v-model="verificationCode"
                counter="6"
                :rules="[rules.counter6]"
                :placeholder="$t('verificationCode')"
                @input="checkValidCode()"
              />
            </v-row>

            <v-row justify="center">
              <MyBtn
                :disabled="verificationCodeValid === false || verifyCodeLoading"
                btnName="verifyCode"
                @btn-clicked="verifyCode()"
              ></MyBtn>
            </v-row>
            <br />
            <br />
            <v-row v-if="verifyCodeLoading === true">
              <v-progress-linear
                color="primary"
                rounded
                indeterminate
              ></v-progress-linear>
            </v-row>
            <br v-if="verifyCodeLoading === true" />
          </div>

          <v-row v-if="isSuperAdmin" class="body-1 ml-4">
            <p>
              {{ $t(`welcome`) }}
              <b class="red--text">&nbsp; {{ $t(`sAdmin`) }}</b>
            </p>
          </v-row>

          <v-row v-if="$store.state.isCountrymanager" class="body-1 ml-4">
            <p>
              {{ $t(`welcome`) }}
              <b class="red--text">&nbsp; {{ $t(`cManager`) }}</b>
            </p>
          </v-row>
          <v-row v-if="$store.state.isSales" class="body-1 ml-4">
            <p>
              {{ $t(`welcome`) }}
              <b class="red--text">&nbsp; {{ $t(`sales`) }}</b>
            </p>
          </v-row>
          <v-row v-if="$store.state.isDev" class="body-1 ml-4">
            <p>
              {{ $t(`welcome`) }}
              <b class="red--text">&nbsp; {{ $t(`developer`) }}</b>
            </p>
          </v-row>

          <v-row v-if="$store.state.isForumModerator" class="body-1 ml-4">
            <p>
              {{ $t(`welcome`) }}
              <b class="red--text">&nbsp; {{ $t(`forumM`) }}</b>
            </p>
          </v-row>

          <v-row v-if="showBonus === true" class="body-1 ml-4">
            <p>
              {{ $t(`walletBonus`) }}
              <b class="green--text">
                {{ $t(`founding`) }} {{ this.$store.state.bonus }}
                {{ $t(`soon`) }}
              </b>
            </p>
          </v-row>
          <!--
          <v-row class="body-1 ml-4">
            <p>
              Raffle Tickets:
              <b v-if="raffleNumbers.length > 0" class="green--text">
                {{ this.raffleNumbers }}
              </b>
              <b v-else class="green--text"> [None] </b>
            </p>
          </v-row>
          <v-row v-if="raffleLoading === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>
          <v-divider></v-divider>
          <br />
          -->
          <!--:disabled="addressLoading"   
         <v-progress-linear
            v-if="addressLoading"
            color="primary"
            rounded
            indeterminate
          ></v-progress-linear>-->
          <!--<span v-if="!raffleLoading && raffleTickets.length >0">{{ raffleTickets }}</span>-->
          <v-divider
            v-if="!raffleLoading && raffleTickets.length > 0"
          ></v-divider>
          <v-row
            v-if="!raffleLoading && raffleTickets.length > 0"
            justify="center"
            no-gutters
            :key="raffleTicketsKey"
          >
            <v-card
              width="500"
              color="transparent"
              elevation="0"
              class="mx-auto"
              v-for="(raffle, index) in raffleTickets"
              :key="index"
            >
              <span>{{ raffle.raffleName }} {{ $t(`tickets`) }}</span
              ><br />
              <b v-if="'tickets' in raffle" class="green--text">
                <span v-if="raffle.tickets.length > 0">{{
                  raffle.tickets
                }}</span>
                <span v-else>{{ $t(`noTickets`) }}</span>
              </b>
              <b v-else class="green--text"> {{ $t(`none`) }} </b>
            </v-card>
          </v-row>
          <v-progress-linear
            v-if="raffleLoading"
            color="primary"
            rounded
            indeterminate
          ></v-progress-linear>
          <v-card-text
            class="body-1"
            v-if="showRotatingBannerAdImages || showPendingPaymentMessage"
          >
            <b>{{ $t(`new_banner_ad_title`) }}</b>
          </v-card-text>
          <RotatingBannerAdImages
            class=""
            :key="RotatingBannerAdImagesKey"
            @banner-deleted="init"
            :rotatingBannerAd="rotatingBannerAd"
            @rotatingBannerAd-changed="updateRotatingBannerAd"
            v-if="showRotatingBannerAdImages"
          ></RotatingBannerAdImages>
          <h4 v-if="showPendingPaymentMessage">
            {{ $t(`wait`) }}
          </h4>
          <h3 v-if="paymentExpiredMessage.length > 0">
            {{ paymentExpiredMessage }}
          </h3>
          <br />
          <v-divider></v-divider>

          <!--
          <v-row v-if="this.$store.state.lastIP != ''" class="body-1 ml-4">
            <p>Current IP Address: {{ this.$store.state.currentIP }}</p>
          </v-row>
          <v-row v-if="this.$store.state.lastIP != ''" class="body-1 ml-4">
            <p>Last login IP Address: {{ this.$store.state.lastIP }}</p>
          </v-row>
          <v-row v-if="this.$store.state.lastIP == ''" class="body-1 ml-4">
            <p>First login IP Address: {{ this.$store.state.currentIP }}</p>
          </v-row>
          -->
          <br />

          <v-row>
            <v-text-field
              @keydown.space="preventLeadingSpace"
              :label="$t('givenName')"
              :prepend-icon="mdiAccountCircle"
              v-model="givenName"
              :placeholder="$t('givenName')"
              @input="givenNameChanged()"
              :disabled="givenNameLoading"
            />

            <v-tooltip top :disabled="disableSaveGivenName">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="disableSaveGivenName"
                  class="bg fg--text"
                  v-on="on"
                  @click="saveGivenName()"
                >
                  <v-icon>{{ mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Save Given Name</span>
            </v-tooltip>
          </v-row>
          <v-row v-if="givenNameLoading === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>

          <v-row>
            <v-text-field
              @keydown.space="preventLeadingSpace"
              :label="$t('familyName')"
              :prepend-icon="mdiAccountCircle"
              v-model="familyName"
              :placeholder="$t('familyName')"
              @input="familyNameChanged()"
              :disabled="familyNameLoading"
            />

            <v-tooltip top :disabled="disableSaveFamilyName">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="disableSaveFamilyName"
                  class="bg fg--text"
                  v-on="on"
                  @click="saveFamilyName()"
                >
                  <v-icon>{{ mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Save Family Name</span>
            </v-tooltip>
          </v-row>

          <v-row v-if="familyNameLoading === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>

          <v-row v-else>
            <v-text-field
              @keydown.space="preventLeadingSpace"
              :label="$t('merchant')"
              :prepend-icon="mdiAccountCircle"
              v-model="merchant"
              :placeholder="$t('merchant')"
              @input="merchantChanged()"
              :disabled="merchantLoading"
              :rules="[rules.merchant100]"
            />

            <v-tooltip top :disabled="disableSaveMerchant">
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="disableSaveMerchant"
                  class="bg fg--text"
                  v-on="on"
                  @click="saveMerchant()"
                >
                  <v-icon>{{ mdiContentSave }}</v-icon>
                </v-btn>
              </template>
              <span>Save Merchant</span>
            </v-tooltip>
          </v-row>

          <v-row v-if="merchantLoading === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>

          <v-divider ref="setDivider"></v-divider>
          <v-checkbox
            v-model="emailSubscription"
            :label="$t('emailSubscription')"
            color="success"
            @change="emailSubscriptionChanged()"
            :disabled="emailSubscriptionLoading"
          ></v-checkbox>
          <v-progress-linear
            v-if="emailSubscriptionLoading"
            color="primary"
            rounded
            indeterminate
          ></v-progress-linear>

          <v-divider></v-divider>
          <br />
          <MyBtn
            btnName="changePassword"
            :iconName="mdiKeyOutline"
            @btn-clicked="showResetPW"
          ></MyBtn>

          <br />
          <v-divider></v-divider>

          <v-card-text class="body-1" v-if="hasAddress">
            <b>{{ $t("address") }}</b>
            <div v-if="'full' in addressObj">
              <p>{{ addressObj.full }}</p>
            </div>
            <div v-else>
              <p>
                {{ addressObj.number }} {{ addressObj.street }}
                <br />
                {{ addressObj.suburb }}, {{ addressObj.state }}
                {{ addressObj.postcode }}
              </p>
            </div>
          </v-card-text>

          <div class="flex-container-center">
            <div v-if="showErrorMessage" class="fail">{{ message }}</div>
          </div>

          <div class="flex-container-center">
            <div v-if="!showErrorMessage" class="ok">{{ message }}</div>
          </div>

          <MyBtn
            ref="setAddress"
            :btnName="setAddressString"
            :iconName="mdiCrosshairsGps"
            @btn-clicked="showMyAddress()"
            :disabled="addressLoading"
          ></MyBtn>

          <v-progress-linear
            v-if="addressLoading"
            color="primary"
            rounded
            indeterminate
          ></v-progress-linear>

          <br />
          <v-divider></v-divider>

          <v-card-text class="body-1" v-if="hasPhone">
            <b>{{ $t("phone") }}</b>
            <p>{{ phone }}</p>
          </v-card-text>

          <span class="text-center" v-if="hideChangePhoneBtn">
            {{ $t("addressDetails") }}</span
          >

          <MyBtn
            ref="setPhone"
            :btnName="setPhoneString"
            :iconName="mdiPhone"
            @btn-clicked="showMyPhone"
            :disabled="phoneLoading || hideChangePhoneBtn"
          ></MyBtn>

          <v-progress-linear
            v-if="phoneLoading"
            color="primary"
            rounded
            indeterminate
          ></v-progress-linear>

          <br />

          <v-divider></v-divider>

          <br />
          <MyBtn
            :disabled="numAds == 0"
            btnName="myAds"
            :iconName="mdiFileEye"
            @btn-clicked="gotoViewMyAds()"
          >
          </MyBtn>

          <span class="text-center"
            >{{ numAds }} {{ $t("numAds") }} {{ maxNumberAds }},
            {{ numberOfAdsFiles }} {{ $t("numImages") }}, {{ numTopAdIds }}
            {{ $t("topAds") }}</span
          >

          <br />
          <MyBtn
            btnName="submitAd"
            :iconName="mdiSendOutline"
            @btn-clicked="profileSubmitAd()"
            iconColor="green"
            :disabled="disableSubmitAdBtn"
          ></MyBtn>
          <br />

          <MyBtn
            v-if="this.$store.state.isLoggedIn"
            btnName="myMsg"
            :iconName="mdiMessage"
            @btn-clicked="goToMessages()"
            class="ma-1"
          ></MyBtn>

          <br />

          <MyBtn
            v-if="this.$store.state.favouriteAds.length > 0"
            btnName="myFav"
            :iconName="mdiHeart"
            @btn-clicked="goToMyFavourites()"
            class="ma-1"
          ></MyBtn>
          <br />

          <v-divider></v-divider>

          <v-card-text class="body-1">
            <b>{{ $t("paidFeatures") }}</b>
          </v-card-text>
          <MyBtn
            :btnName="rotatingBannerAdMsg"
            :iconName="mdiContactlessPayment"
            @btn-clicked="goToBannerAds()"
            :disabled="disableSetBannerAdBtn"
          ></MyBtn>
          <p v-if="hasAddress === false" class="text-center red--text">
            {{ $t("addressEnable") }}
          </p>
          <p v-if="numRotatingBannerAds >= 4" class="text-center red--text">
            {{ $t("alreadyUsed") }} {{ this.$store.state.countryByIP }}.
          </p>
          <br />

          <MyBtn
            btnName="purchaseAds"
            :iconName="mdiContactlessPayment"
            @btn-clicked="pushRoute(ROUTE_PURCHASE_ADS)"
          ></MyBtn>
          <br />
          <v-divider></v-divider>

          <v-card-text class="body-1">
            <b>{{ $t("invoices") }}</b>
          </v-card-text>
          <MyBtn
            btnName="myInvoiceAsBuyer"
            :iconName="mdiReceipt"
            @btn-clicked="goToBuyerInvoices"
          ></MyBtn>
          <span class="text-center"
            >{{ numBuyerInvoices }} {{ $t("invoices") }}</span
          >
          <br />
          <MyBtn
            btnName="myInvoiceAsSeller"
            :iconName="mdiReceipt"
            @btn-clicked="goToSellerInvoices"
          ></MyBtn>

          <span class="text-center"
            >{{ numSellerInvoices }} {{ $t("invoices") }}</span
          >
          <br />
          <v-divider></v-divider>
          <v-card-text class="body-1">
            <b>{{ $t("deleteFeatures") }}</b>
          </v-card-text>
          <br />

          <v-row v-if="deletingAds === true">
            <v-progress-linear
              color="primary"
              rounded
              indeterminate
            ></v-progress-linear>
          </v-row>
          <br />

          <MyBtn
            btnName="deleteAllMyAds"
            :iconName="mdiTrashCanOutline"
            @btn-clicked="showDeleteAllMyAdsConfirm = true"
            :disabled="numAds == 0"
            iconColor="red"
          ></MyBtn>

          <br />

          <MyBtn
            v-if="isSuperAdmin === false"
            btnName="deleteAccount"
            :iconName="mdiAccountRemove"
            @btn-clicked="showDeleteConfirm = true"
            iconColor="red"
          ></MyBtn>
          <br v-if="isSuperAdmin === false" />
        </v-form>
      </v-card-text>
    </div>

    <v-dialog v-model="showDeleteConfirm" width="500" class="mx-auto">
      <v-card>
        <MyCardTitle
          :title="$t('confirm')"
          @close-window="showDeleteConfirm = false"
        ></MyCardTitle>

        <v-form class="flex-container-column ml-1 mr-1">
          <p class="fail text-center">
            <b>
              {{ $t("delete_acount_warning") }}
            </b>
          </p>
          <v-text-field
            :label="$t('username')"
            v-model="userNameConfirmation"
            :prepend-icon="mdiAccountCircle"
            @input="userNameConfirmationChanged()"
          ></v-text-field>

          <!--
          <v-text-field
            label='Password'
            v-model='pwConfirmation'
            class='flex-center'
            :type="showPassword ? 'text' : 'password'"
            prepend-icon='mdi-lock'
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append='showPassword = !showPassword'

          >
          </v-text-field>
          -->

          <p v-if="showErrorMessage" class="fail flex-center">{{ message }}</p>

          <MyBtn
            btnName="deleteAccount"
            :iconName="mdiAccountRemove"
            @btn-clicked="deleteUserWrapper()"
            :disabled="disableDeleteUser"
            iconColor="red"
          ></MyBtn>
        </v-form>
        <br />
      </v-card>
    </v-dialog>
    <!-- delete account -->

    <v-dialog v-model="showDeleteAllMyAdsConfirm" width="500">
      <v-card>
        <MyCardTitle
          :title="$t('deleteAll')"
          @close-window="showDeleteAllMyAdsConfirm = false"
        ></MyCardTitle>
        <br />

        <v-layout justify-center>
          <MyBtn
            btnName="No"
            :iconName="mdiArrowLeftBoldOutline"
            @btn-clicked="showDeleteAllMyAdsConfirm = false"
            iconColor="green"
          ></MyBtn>

          <v-spacer></v-spacer>

          <MyBtn
            btnName="Yes"
            :iconName="mdiTrashCanOutline"
            @btn-clicked="deleteAllMyAds"
            iconColor="red"
          ></MyBtn>
        </v-layout>
        <br />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDelReason">
      <v-card>
        <MyCardTitle
          title="reason"
          @close-window="showDelReason = false"
        ></MyCardTitle>

        <v-container class="px-0" fluid>
          <v-row justify="center">
            <v-radio-group v-model="radioGroup">
              <v-radio
                v-for="deleteReason in radioDeleteAdReasons"
                :key="deleteReason"
                :label="`${deleteReason}`"
                :value="deleteReason"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-container>

        <v-layout justify-center>
          <MyBtn
            btnName="del"
            :iconName="mdiTrashCanOutline"
            @btn-clicked="showDeleteAllMyAdsConfirm = true"
          >
          </MyBtn>

          <!--
          <v-btn @click="showDeleteAllMyAdsConfirm = true" class="bg fg--text">
            <v-icon left large color="red">{{ mdiTrashCanOutline }}</v-icon
            >&nbsp;Delete Ads
          </v-btn>
          -->
        </v-layout>
        <br />
      </v-card>
    </v-dialog>

    <MyAcknowledge
      :showAcknowledge="showAcknowledge"
      :title="acknowledgeTitle"
      :message="acknowledgeMessage"
      :ok="acknowledgeOk"
      @btn-clicked="hideMyAcknowledge"
      :key="myAcknowledgeKey"
    ></MyAcknowledge>

    <Loading
      :isLoading="isLoading"
      :message="messageLoader"
      :key="loadingKey"
    ></Loading>

    <MyAddressInternational
      :isShowMyAddress="isShowMyAddress"
      :inAddressObj="addressObj"
      @close-window="closeMyAddressInternational"
      @address-finalized="finalizeAddress"
      :key="myAddressKey"
    >
    </MyAddressInternational>

    <MyPhone
      :isShowMyPhone="isShowMyPhone"
      @close-window="closeMyPhone"
      @phone-finalized="finalizePhone"
      :origPhone="phone"
      :phonePrefixNum="phonePrefix"
      :key="myPhoneKey"
    ></MyPhone>

    <ResetPW
      :isShowResetPassword="isShowResetPassword"
      @close-window="closeResetPW"
      @resetPW-status="showResetPWStatus"
      :key="resetPWKey"
    ></ResetPW>

    <v-snackbar
      color="orange"
      timeout="-1"
      v-model="showSnackbarCenter"
      multi-line
      :bottom="!$vuetify.breakpoint.xs"
      :top="$vuetify.breakpoint.xs"
    >
      <span class="black--text" v-html="snackbarMessageCenter"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="closeSnackbarCenter()"
          >{{ $t("continue") }}</v-btn
        >
      </template>
    </v-snackbar>

    <v-snackbar
      color="orange"
      timeout="-1"
      v-model="showSnackbar"
      :bottom="!$vuetify.breakpoint.xs"
      :top="$vuetify.breakpoint.xs"
      left
      multi-line
    >
      <span class="black--text" v-html="snackbarMessage"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="closeSnackbar()">{{
          $t("continue")
        }}</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      color="orange"
      timeout="-1"
      v-model="showSnackbarAirdrop"
      centered
      multi-line
    >
      <span class="black--text" v-html="snackbarAirdropMessage"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="pushRoute(ROUTE_AIRDROP_FORM, {})"
          >{{ $t("continue") }}</v-btn
        >

        <v-icon
          class="ml-3"
          color="pink"
          text
          v-bind="attrs"
          @click="showSnackbarAirdrop = false"
          >{{ mdiCloseCircle }}
        </v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
//import Pusher from 'pusher-js'

import {
  mdiAccountCircle,
  mdiAccountRemove,
  mdiArrowLeftBoldOutline,
  mdiContactlessPayment,
  mdiContentSave,
  mdiCrosshairsGps,
  mdiFileEye,
  mdiEmailSendOutline,
  mdiKeyOutline,
  mdiPhone,
  mdiSendOutline,
  mdiTrashCanOutline,
  mdiEmailVariant,
  mdiCloseCircle,
  mdiMessage,
  mdiReceipt,
  mdiHeart,
} from "@mdi/js";

import Config from "../config.js";
import Loading from "./Loading";
import MyBtn from "./MyBtn";
import MyAcknowledge from "./MyAcknowledge";
import CheckIcon from "./CheckIcon.vue";
import MyCardTitle from "./MyCardTitle";
import MyPhone from "./MyPhone";
import ResetPW from "./ResetPW";
import RotatingBannerAdImages from "./RotatingBannerAdImages";

import MyAddressInternational from "./MyAddressInternational";

export default {
  name: "Profile",
  components: {
    Loading,
    MyBtn,
    MyAcknowledge,
    MyAddressInternational,
    MyCardTitle,
    MyPhone,
    ResetPW,
    RotatingBannerAdImages,
    CheckIcon,
  },
  props: [],
  data: () => ({
    mdiAccountCircle: mdiAccountCircle,
    mdiAccountRemove: mdiAccountRemove,
    mdiContactlessPayment: mdiContactlessPayment,
    mdiArrowLeftBoldOutline: mdiArrowLeftBoldOutline,
    mdiContentSave: mdiContentSave,
    mdiCrosshairsGps: mdiCrosshairsGps,
    mdiEmailSendOutline: mdiEmailSendOutline,
    mdiFileEye: mdiFileEye,
    mdiKeyOutline: mdiKeyOutline,
    mdiPhone: mdiPhone,
    mdiSendOutline: mdiSendOutline,
    mdiTrashCanOutline: mdiTrashCanOutline,
    mdiEmailVariant: mdiEmailVariant,
    mdiCloseCircle: mdiCloseCircle,
    mdiMessage: mdiMessage,
    mdiReceipt: mdiReceipt,
    mdiHeart: mdiHeart,

    tokens: null,
    accessTokenDecoded: {},
    RotatingBannerAdImagesIndex: 0,
    RotatingBannerAdImagesKey: "RotatingBannerAdImages0",

    username: "",
    givenName: "",
    givenNameLoading: false,
    raffleLoading: true,

    familyName: "",
    familyNameLoading: false,

    email: "",
    merchant: "",
    merchantOrig: "",

    showEmailVerificationCodeInput: false,
    verificationCode: "",
    verificationCodeValid: false,

    disableSaveUsername: true,
    disableSaveGivenName: true,

    disableSaveMerchant: true,

    disableSaveFamilyName: true,
    disableDeleteUser: true,

    showDeleteConfirm: false,
    userNameConfirmation: "",
    pwConfirmation: "",
    showErrorMessage: false,
    message: "",

    bucketMobilityGuru: null,

    collectionProfiles: null,

    collectionAds: null,

    showPassword: false,

    isShowMyAddress: false,

    // address
    addressObj: {
      number: "",
      street: "",
      suburb: "",
      state: "",
      postcode: "",
    },
    myAddressKey: 0,

    hasAddress: false,
    setAddressString: "",
    addressLoading: true,

    isLoading: false,
    messageLoader: "",

    isShowMyPhone: false,
    setPhoneString: "",
    phone: "",
    hasPhone: false,
    phoneLoading: true,

    phonePrefix: "",
    currencyCode: "",
    myPhoneIndex: 0,
    myPhoneKey: "phone0",

    resetPWIndex: 0,
    resetPWKey: "resetPWKey0",

    loadingIndex: 0,
    loadingKey: "loading0",

    hasPhoneIndex: 10,
    hasPhoneKey: "hasPhone0",

    showDeleteAllMyAdsConfirm: false,

    numAds: 0,
    numberOfAdsFiles: 0,
    numRotatingBannerAds: 4,

    myAds: null,

    showAcknowledge: false,
    acknowledgeTitle: "",
    acknowledgeMessage: "",
    acknowledgeOk: true,
    //  acknowledgeKey: 99,
    myAcknowledgeIndex: 0,
    myAcknowledgeKey: "myAcknowledge0",

    isShowResetPassword: false,

    isSuperAdmin: false,
    lastIP: "",

    showBonus: false,
    emailSubscription: true,

    usernameLoading: false,
    userProfileLoading: false,
    emailSubscriptionLoading: true,
    merchantLoading: true,

    sendVerifyEmailLoading: false,
    verifyCodeLoading: false,

    snackbarMessage: "",
    showSnackbar: false,
    snackbarMessageCenter: "",
    showSnackbarCenter: false,
    Raffle202012tickets: [],
    raffleNumbers: [],
    showDelReason: false,
    reason: "",

    showRotatingBannerAdImages: false,
    rotatingBannerAd: null,

    radioGroup: "",
    radioDeleteAdReasons: [
      "Item sold through MobilityGuru.",
      "Item lost.",
      "Item sold through another marketplace.",
      "Decided I want to keep it.",
      "Rather not say.",
    ],
    deletingAds: false,
    maxNumberAds: Config.MAX_ADS,

    rotatingBannerAdMsg: "rotatingBannerAdMsg",

    numBannerAds: 0,
    numTopAdIds: 0,

    showPendingPaymentMessage: false,
    paymentExpiredMessage: "",
    raffleTickets: [],
    raffleTicketsKey: "tickets0",
    raffleTicketsCounter: 0,
    rules: {
      required: (value) => value.length > 0 || "Required.",
      passwordMin: (value) =>
        value.length >= Config.PASSWORD_MIN_LENGTH ||
        "Min " + Config.PASSWORD_MIN_LENGTH + " characters",
      passwordMax: (value) =>
        value.length <= Config.PASSWORD_MAX_LENGTH ||
        "Max " + Config.PASSWORD_MAX_LENGTH + " characters",
      counter6: (value) =>
        value.length <= Config.EMAIL_VERIFICATION_LENGTH ||
        "Max " + Config.EMAIL_VERIFICATION_LENGTH + " digits",
      description600: (value) =>
        value.length <= Config.AD_DESC_MAX ||
        "Max " + Config.AD_DESC_MAX + " characters",
      email: (value) => {
        return Config.EMAIL_PATTERN.test(value) || "Invalid e-mail.";
      },
      email345: (value) =>
        value.length <= Config.EMAIL_MAX ||
        "Max " + Config.EMAIL_MAX + " characters",
      merchant100: (value) =>
        value.length <= Config.MERCHANT_MAX ||
        "Max " + Config.MERCHANT_MAX + " characters",
    },

    maxAdsReached: false,

    snackbarAirdropMessage: "",
    showSnackbarAirdrop: false,
    ROUTE_AIRDROP_FORM: Config.ROUTE_AIRDROP_FORM,
    ROUTE_PURCHASE_ADS: Config.ROUTE_PURCHASE_ADS,

    showChangeEmailTip: false,
    toggleShowEmailTipBtnName: "change",

    //pusher: null,
    /*
    channelName: '',
    channel: null,

    adsChannelName: '',
    adsChannel: null,
    */
    numSellerInvoices: 0,
    numBuyerInvoices: 0,
  }),

  watch: {
    /*
    emailSubscription: async function () {
      this.debug("Email Subscription Status" + this.emailSubscription);
      await this.toggleSubscriptionStatus(this.accessTokenDecoded.sub);
    },
    */
  },

  mounted: async function () {
    if (await this.isLoggedInOIDC()) {
      await this.init();

      //this.initPusher()
      await this.connectClientToStream();
      await this.getFirebaseToken();
    } else {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  computed: {
    hideChangePhoneBtn() {
      let hide = false;
      if (this.$store.state.phonePrefix === "") {
        hide = true;
      }
      return hide;
    },

    disableSetBannerAdBtn() {
      let disable = true;
      if (this.rotatingBannerAdMsg === "manage") {
        disable = false;
      } else if (this.hasAddress === false || this.numRotatingBannerAds >= 4) {
        disable = true;
      } else {
        disable = false;
      }
      return disable;
    },

    disableSubmitAdBtn() {
      return (
        this.maxAdsReached || this.accessTokenDecoded.email_verified === false
      );
    },
  },
  metaInfo() {
    return {
      title: "My Mobility Guru ",
      meta: [
        {
          name: "Profile page",
          content: "Mobility Guru ",
        },
        {
          name: "keywords",
          content: "advertisements, ads, live, public",
        },
      ],
    };
  },
  methods: {
    /*
    adsCreated(e) {
      this.debug('ads created ' + e);
    },

    adsUpdated(e) {
      this.debug('ads updated ' + e);
    },

    pusherProfileUpdated(e) {
      this.debug('Records updated a' + e);
    },

    pusherProfileCreated(e) {
      this.debug('Records created a' + e);
    },

    pusherProfileDeleted(e) {
      this.debug('Records delete a' + e);
    },

    initPusher() {
      this.pusher = new Pusher(Config.PUSHER_KEY, {
        cluster: 'ap4'
      });

      this.channelName = Config.BUCKET_MOBILITY_GURU_NAME + '-' +
        Config.COLLECTION_PROFILES_NAME + '-record'


      this.channel = this.pusher.subscribe(this.channelName)

      this.channel.bind('create', function(data) {
        this.debug("New records created", data);
      });
      this.channel.bind('update', function(data) {
        this.debug("Records updated", data);
      });
      this.channel.bind('delete', function(data) {
        this.debug("Records deleted", data);
      });

      this.adsChannelName = Config.BUCKET_MOBILITY_GURU_NAME + '-' +
        Config.COLLECTION_ADS_NAME
      this.adsChannel = this.pusher.subscribe(this.adsChannelName)
      this.adsChannel.bind('create', this.adsCreated(event));
      this.adsChannel.bind('update', this.adsUpdated(event));


      this.debug('a')

        
    },
    */

    async goToLogout() {
      await this.logoutPostCleanup();

      if (this.$route.path !== Config.ROUTE_HOME) {
        this.$router.push(Config.ROUTE_HOME);
      }
    },

    async deleteUserWrapper() {
      let ok = false;

      this.tokens = this.$store.state.tokens;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

      if (
        this.userNameConfirmation === this.accessTokenDecoded.preferred_username
      ) {
        this.showLoading();
        ok = await this.deleteUser(this.$store.state.accessTokenDecoded.sub);

        if (ok == true) {
          this.hideLoading();

          this.acknowledgeMessage =
            "Your Ads and Account deleted successfully! You will be redirected to Home page after acknowledging.";
          this.showMyAcknowledge(true);

          while (this.showAcknowledge) {
            await this.synchSetTimeout(200);
          }

          await this.goToLogout();
        } else {
          this.acknowledgeMessage = "Your Ads and Account failed to delete!";
          this.showMyAcknowledge(false);
        }

        this.hideLoading();
        this.showDeleteConfirm = false;
      } else {
        // we should never reach here
        this.acknowledgeMessage = "Wrong email";
        this.showMyAcknowledge(false);
      }
      return ok;
    },

    /*
    async deleteUserOrig() {
      if (await this.isLoggedInOIDC()) {
        if (
          this.userNameConfirmation ===
          this.accessTokenDecoded.preferred_username
        ) {
          this.showLoading();
          let ok = await this.deleteUserProfile();

          this.acknowledgeTitle = "Delete Account";
          if (ok == true) {
            this.hideLoading();

            this.acknowledgeMessage =
              "Your Ads and Account deleted successfully! You will be redirected to Home page after acknowledging.";
            this.showMyAcknowledge(true);

            while (this.showAcknowledge) {
              await this.synchSetTimeout(200);
            }

            await this.goToLogout();
          } else {
            this.acknowledgeMessage = "Your Ads and Account failed to delete!";
            this.showMyAcknowledge(false);
          }

          this.hideLoading();
          this.showDeleteConfirm = false;
        } else {
          // we should never reach here
          this.acknowledgeMessage = "Wrong email";
          this.showMyAcknowledge(false);
        }
      }
    },
    */

    async sendSignUpAlert() {
      this.debug("sendSignUpAler() ");
      // let ok = false;
      let message =
        "Username: " +
        this.accessTokenDecoded.preferred_username +
        "\n" +
        "Name: " +
        this.accessTokenDecoded.given_name +
        " " +
        this.accessTokenDecoded.family_name;
      let subject = "New Sign Up Alert ";
      let from = this.accessTokenDecoded.username;
      let payload = new FormData();
      payload.append("subject", subject);
      payload.append("message", message);
      payload.append("from", from);
      payload.append("toAdmin", true);

      let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_CONTACT_US}`;

      this.debug(`sendEmail() url = ${url}`);
      let headers = {
        Accept: "application/json",
      };
      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };
      try {
        this.printJson(fetchData, "fetchData");
        let res = await fetch(url, fetchData);
        let jsonRes = await res.json();

        this.printJson(jsonRes, "sendEmailHelper() jsonRes");
        this.debug("SignUp alert sent successfuly");
      } catch (e) {
        console.error(e);
      }
      console.log(`sendSignUpAlert end`);
    },

    async initUserProfile() {
      this.debug("initUserProfile");
      let ok = false;
      this.raffleLoading = false;

      if ((await this.isLoggedInOIDC()) == true) {
        let url = `${Config.NODE_SERVER_IP}${Config.INIT_USER_PROFILE}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);

          this.givenNameLoading = false;
          this.familyNameLoading = false;
          this.merchantLoading = false;
          this.emailSubscription = true;
          this.addressLoading = false;
          this.phoneLoading = false;

          ok = res.ok;
        } catch (err) {
          this.debug(err);
        }
      }

      console.log(`initUserProfile ok = ${ok}`);

      return ok;
    },

    /* gets user Kinto profile. Creates if doesn't exist
     */
    async getUserProfile() {
      let ok = false;
      let profile;

      let profileInitForFirstTime = false;

      if (await this.isLoggedInOIDC()) {
        try {
          // check if Kinto Profile exists
          let resultData = await this.getUserProfileData();
          ok = resultData.ok;
          if (ok == true) {
            this.raffleLoading = true;
            //let result = await this.getUserProfileData();
            this.printJson(resultData, `init getUserProfileData() result`);
            this.numRotatingBannerAds = resultData.numRotatingBannerAds;
            this.raffleTickets = resultData.raffleTickets;
          }
          let result;
          let profileExists = resultData.profileExists;
          console.log(profileExists == false && ok == true);
          if (profileExists == false && ok == true) {
            // if not initialize it
            result = await this.initKintoProfile();

            this.setAddressString = "setAddress";
            this.setPhoneString = "setPhone";

            //this.numRotatingBannerAds = 0;

            ok = result.ok;

            profileInitForFirstTime = true;
          } else {
            //this.numRotatingBannerAds = result.numRotatingBannerAds;

            // perform any updates using Keycloak JWT
            let jsonObject = {};
            result = await this.updateKintoProfile(jsonObject);

            ok = result.ok;
          }
          profile = result.profile;
          if (ok === false) {
            this.snackbarMessage += result.message;
            this.showSnackbar = true;

            this.givenNameLoading = false;
            this.familyNameLoading = false;
            this.merchantLoading = false;

            this.emailSubscription = false;
          } else {
            if ("address" in profile) {
              this.addressObj = profile.address;
              this.setAddressString = "setAddressString";
              this.hasAddress = true;

              this.$store.commit("set", ["addressObj", this.addressObj]);

              if ("country" in this.addressObj) {
                this.$store.commit("set", ["country", this.addressObj.country]);
                await this.getStates(this.addressObj.country);
              }
            } else {
              this.setAddressString = "setAddress";
              this.hasAddress = false;
            }
            this.addressLoading = false;

            this.$store.commit("set", ["hasAddress", this.hasAddress]);
            if ("merchant" in profile) {
              this.merchant = profile.merchant;
              this.merchantOrig = this.$store.state.merchant;
              this.$store.commit("set", ["merchant", this.merchant]);
            }
            this.merchantLoading = false;
            this.disableDeleteUser = false;

            if ("phone" in profile) {
              this.phone = profile.phone;
              this.setPhoneString = "setPhoneString";
              this.hasPhone = true;

              this.$store.commit("set", ["phone", this.phone]);
            } else {
              this.setPhoneString = "setPhone";
              this.hasPhone = false;
            }
            this.phoneLoading = false;
            this.$store.commit("set", ["hasPhone", this.hasPhone]);

            this.printJson(profile, "Profile profile");

            if ("codeCreated" in profile) {
              this.showEmailVerificationCodeInput = true;
            } else {
              this.showEmailVerificationCodeInput = false;
            }

            if ("IPaddress" in profile) {
              this.lastIP = profile.IPaddress;
              this.$store.commit("set", ["lastIP", this.lastIP]);
            }
            if ("emailSubscription" in profile) {
              this.emailSubscription = profile.emailSubscription;
              this.$store.commit("set", [
                "emailSubscription",
                this.emailSubscription,
              ]);
            }
            this.emailSubscriptionLoading = false;

            if ("phonePrefix" in profile) {
              this.phonePrefix = profile.phonePrefix;
              this.$store.commit("set", ["phonePrefix", this.phonePrefix]);
              this.myPhoneIndex++;
              this.myPhoneKey = `phone${this.myPhoneIndex}`;
            }

            if ("currencyCode" in profile) {
              if (profile.currencyCode != "") {
                this.currencyCode = profile.currencyCode;
                this.$store.commit("set", ["currencyCode", this.currencyCode]);
                this.debug(`currency of country =  ${this.currencyCode}`);
              }
            }

            if ("debugOutputEnabled" in profile) {
              this.$store.commit("set", [
                "debugOutputEnabled",
                profile.debugOutputEnabled,
              ]);
            }

            if ("bonus" in profile) {
              this.showBonus = true;
              this.$store.commit("set", ["bonus", profile.bonus]);
            }

            if ("rotatingBannerAdIds" in profile) {
              if (profile.rotatingBannerAdIds.length > 0) {
                this.rotatingBannerAdMsg = "manage";
              }
            } else {
              this.rotatingBannerAdMsg = "rotatingBannerAdMsg";
            }

            if ("numAds" in profile) {
              this.numAds = profile.numAds;
            }

            if ("numImages" in profile) {
              this.numberOfAdsFiles = profile.numImages;
            }

            if ("maxNumberAds" in profile) {
              this.maxNumberAds = profile.maxNumberAds;
              if (Number(this.numAds) >= Number(this.maxNumberAds)) {
                this.maxAdsReached = true;
              }
            }

            if ("topAdIds" in profile) {
              this.numTopAdIds = profile.topAdIds.length;
            }
            // TODO: raffles?

            if ("numBuyerInvoices" in profile) {
              this.numBuyerInvoices = profile.numBuyerInvoices;
            }

            if ("numSellerInvoices" in profile) {
              this.numSellerInvoices = profile.numSellerInvoices;
            }

            if ("favouriteAds" in profile) {
              this.$store.commit("set", ["favouriteAds", profile.favouriteAds]);
            }
          }

          this.RotatingBannerAdImagesIndex++;
          this.RotatingBannerAdImagesKey = `RotatingBannerAdImages${this.RotatingBannerAdImagesIndex}`;

          if (profileInitForFirstTime && ok) {
            this.sendVerifyEmailLoading = true;
            ok = await this.sendVerifyEmailCodeFromProfile();
            if (ok) {
              this.snackbarMessage = `Email verification code sent to ${this.accessTokenDecoded.preferred_username}. Check your your inbox or your spam mail for the code. Please log in and enter the code to start advertising.<br/>`;
              this.showSnackbar = true;
            } else {
              this.snackbarMessage = `Email verification code sending failed<br/>. Please contact MobilityGuru Tech Support.`;
              this.showSnackbar = true;
            }

            this.sendVerifyEmailLoading = false;
          }
        } catch (e) {
          console.error("err " + e);
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      console.log(`getUserProfile ok = ${ok}`);
      return ok;
    },

    async getSuperadminId() {
      let ok = false;
      let id = "";

      if ((await this.isLoggedInOIDC()) == true) {
        let url = `${Config.NODE_SERVER_IP}${Config.GET_SUPERADMIN_ID}`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);

          let jsonRes = await res.json();
          this.debug(jsonRes, "getSuperadminId() jsonRes");

          ok = jsonRes.ok;
          if (ok == true) {
            id = jsonRes.id;

            this.$store.commit("set", ["superadminId", id]);
            this.debug(`Got superadminId = ${this.$store.state.superadminId}`);
          }
        } catch (e) {
          console.error(e);
        }
      }
      return ok;
    },

    async profileInitParallel0() {
      let jobNames = ["getOIDCInfo()", "getSuperadminId()", "getIPAddress()"];

      let jobs = [];

      jobs.push(this.getOIDCInfo());
      jobs.push(this.getSuperadminId());
      jobs.push(this.getIPAddress());

      let keycloakClient = this.accessTokenDecoded.azp;

      if (
        keycloakClient in this.accessTokenDecoded.resource_access &&
        this.accessTokenDecoded.resource_access[keycloakClient].roles.includes(
          Config.SUPERADMIN_ROLE_STRING
        ) === true
      ) {
        this.$store.commit("set", ["isSuperAdmin", true]);
        this.isSuperAdmin = true;
      }

      if (
        keycloakClient in this.accessTokenDecoded.resource_access &&
        this.accessTokenDecoded.resource_access[keycloakClient].roles.includes(
          Config.COUNTRY_MANAGER_ROLE
        ) === true
      ) {
        this.$store.commit("set", ["isCountrymanager", true]);
      }

      if (
        keycloakClient in this.accessTokenDecoded.resource_access &&
        this.accessTokenDecoded.resource_access[keycloakClient].roles.includes(
          Config.SALES_ROLE
        ) === true
      ) {
        this.$store.commit("set", ["isSales", true]);
      }

      if (
        keycloakClient in this.accessTokenDecoded.resource_access &&
        this.accessTokenDecoded.resource_access[keycloakClient].roles.includes(
          Config.DEV_ROLE
        ) === true
      ) {
        this.$store.commit("set", ["isDev", true]);
      }

      if (
        keycloakClient in this.accessTokenDecoded.resource_access &&
        this.accessTokenDecoded.resource_access[keycloakClient].roles.includes(
          Config.FORUM_MODERATOR_ROLE
        ) === true
      ) {
        this.$store.commit("set", ["isForumModerator", true]);
      }
      this.tokens = this.$store.state.tokens;

      let results = [];

      let i = 0;
      for (let job of jobs) {
        results.push({
          ok: await job,
          jobName: jobNames[i],
        });
        i++;
      }

      return results;
    },

    async init() {
      if (
        this.$route.hash == "#SetPhone" ||
        this.$route.hash == "#SetAddress"
      ) {
        this.$refs.setDivider.$el.scrollIntoView();
      }
      this.userProfileLoading = true;

      let start = new Date();

      let ok = true;
      if (this.$store.state.isLoggedIn) {
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        console.log("a");
        let results = await this.taskRunner(
          this.profileInitParallel0,
          "profileInitParallel0"
        );

        let len = results.length;
        for (let i = 0; i < len; i++) {
          ok = ok && results[i].ok;
          i++;
        }

        // get accessTokenDecoded info
        this.username = this.accessTokenDecoded.preferred_username;
        this.givenName = this.accessTokenDecoded.given_name;
        this.familyName = this.accessTokenDecoded.family_name;

        ok = await this.getUserProfile();

        this.userProfileLoading = false;
        this.$store.commit("set", ["gotProfileFromDB", true]);
        this.emailSubscriptionLoading = false;
      }

      this.RotatingBannerAdImagesIndex++;
      this.RotatingBannerAdImagesKey = `RotatingBannerAdImages${this.RotatingBannerAdImagesIndex}`;

      let end = new Date();
      let diffs = (end - start) / 1000;
      this.debug(`Profile load time = ${diffs} s, ok = ${ok}`);

      console.log("d");
      /*
      if (this.$store.state.airdropFormObject.status == "filling") {
        this.showSnackbarAirdrop = true;
        this.snackbarAirdropMessage = "Continue filling airdrop form";
      }
      */
      this.radioGroup = this.radioDeleteAdReasons[0];
      /*this.raffleLoading = true;
      let result = await this.getUserProfileData();
      this.printJson(result, `init getUserProfileData() result`);
      this.numRotatingBannerAds = result.numRotatingBannerAds;
      this.raffleTickets = result.raffleTickets;*/
      this.debug(`numRotatingBannerAds = ${this.numRotatingBannerAds}`);
      this.printJson(this.raffleTickets, "raffle tickets live");
      /*if (this.raffleTickets.length > 0) {
        for (let i = 0; i < this.raffleTickets.length; i++) {
          let raffleNumbers = [];
          for (let j = 0; j < this.raffleTickets[i].tickets.length; j++) {
            raffleNumbers.push(this.raffleTickets[i].tickets[j].ticket);
          }
          this.raffleTickets[i]["raffleNumbers"] = raffleNumbers;
        }
      }*/
      this.printJson(this.raffleTickets, "raffle tickets edited");
      this.raffleTicketsCounter++;
      this.raffleTicketsKey = `tickets${this.raffleTicketsCounter}`;
      this.raffleLoading = false;

      this.setMatomoUserID();
      console.log(this.$matomo);
      return ok;
    },

    async checkCountryManagerStatus(id, role) {
      let ok = false;

      let url = `${Config.NODE_SERVER_IP}${Config.HAS_EFFECTIVE_ROLE}`;

      let headers = {
        Authorization: `${this.$store.state.accessTokenDecoded.typ} ${this.$store.state.tokens.access_token}`,
        Accept: "application/json",
      };

      let payload = new FormData();
      payload.append("userID", id);
      payload.append("role", role);
      this.debug(id);

      let fetchData = {
        method: "POST",
        headers: headers,
        body: payload,
      };

      try {
        let res = await fetch(url, fetchData);

        let jsonRes = await res.json();
        this.printJson(jsonRes, "check id for country manager status ");

        ok = jsonRes.ok;
        if (ok === true) {
          this.debug(`Success ok = ${ok}`);
        }
      } catch (e) {
        console.error(e);
      }
      return ok;
    },

    /*  async deleteKeycloakUser(userId) {
      let ok = false;

      let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_DELETE_USER}?userId=${userId}`;
      let fetchData = {
        method: "GET",
        headers: {
          Authorization:
            this.accessTokenDecoded.typ + " " + this.tokens.access_token,
        },
      };

      try {
        let res = await fetch(url, fetchData);
        let jsonRes = await res.json();

        ok = jsonRes.ok;
        if (ok == true) {
          this.debug("Deleted user from keycloak");
        }
      } catch (e) {
        console.error(e);
      }

      return ok;
    },*/

    usernameChanged() {
      if (
        this.username == this.accessTokenDecoded.preferred_username ||
        this.username.length == 0 ||
        this.username.length > Config.EMAIL_MAX ||
        Config.EMAIL_PATTERN.test(this.username) === false
      ) {
        this.disableSaveUsername = true;
      } else {
        this.disableSaveUsername = false;
      }
    },

    givenNameChanged() {
      if (
        this.givenName == this.accessTokenDecoded.given_name ||
        this.givenName.length == 0
      ) {
        this.disableSaveGivenName = true;
      } else {
        this.disableSaveGivenName = false;
      }
    },

    familyNameChanged() {
      if (
        this.familyName == this.accessTokenDecoded.family_name ||
        this.familyName.length == 0
      ) {
        this.disableSaveFamilyName = true;
      } else {
        this.disableSaveFamilyName = false;
      }
    },

    merchantChanged() {
      if (
        this.merchant === this.merchantOrig ||
        this.merchant.length == 0 ||
        this.merchant.length > Config.MERCHANT_MAX
      ) {
        this.disableSaveMerchant = true;
      } else {
        this.disableSaveMerchant = false;
      }
    },

    async finalizeAddress(addressObj, currencyCode) {
      let ok = false;
      this.closeMyAddressInternational();
      if ((await this.isLoggedInOIDC()) == true) {
        this.addressLoading = true;

        this.isShowMyAddress = false;

        this.addressObj = addressObj;
        this.hasAddress = true;
        this.$store.commit("set", ["currencyCode", currencyCode]);

        let jsonObject = {
          address: addressObj,
          currencyCode: currencyCode,
        };

        let result = await this.updateKintoProfile(jsonObject);
        let profile = result.profile;
        ok = result.ok;

        if (ok === true) {
          if ("phonePrefix" in profile && "address" in profile) {
            this.$store.commit("set", ["phonePrefix", profile.phonePrefix]);

            this.phonePrefix = this.$store.state.phonePrefix;
            this.myPhoneIndex++;
            this.myPhoneKey = `phone${this.myPhoneIndex}`;
          } else {
            ok = false;
            this.message = "no phone prefix returned";
            this.showErrorMessage = true;
          }
        } else {
          this.message = result.message;
          this.showErrorMessage = true;
        }

        if (ok == true) {
          // add to store
          this.$store.commit("set", ["hasAddress", this.hasAddress]);
          this.$store.commit("set", ["addressObj", this.addressObj]);
          this.snackbarMessage = "New address saved successfully!<br/>";
        } else {
          this.acknowledgeTitle = "Error Address Saved!";
          this.acknowledgeMessage =
            "New address saved failure: " + this.message;

          this.snackbarMessage = `<p style="color:darkred">New address saved failure: ${this.message}!</p>`;
        }

        this.addressLoading = false;
        this.showSnackbar = true;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    async finalizePhone(phoneNumber) {
      this.closeMyPhone();
      if ((await this.isLoggedInOIDC()) == true) {
        this.phoneLoading = true;

        this.phone = phoneNumber;
        this.hasPhone = true;

        let jsonObject = {
          phone: this.phone,
        };
        let result = await this.updateKintoProfile(jsonObject);

        let profile = result.profile;
        this.printJson(result, `finalizePhone() result ${this.phone}`);
        let ok = result.ok;

        if (ok === true) {
          if ("phone" in profile && profile.phone === this.phone) {
            // add to store
            this.$store.commit("set", ["hasPhone", this.hasPhone]);
            this.$store.commit("set", ["phone", this.phone]);

            this.snackbarMessage += `New Phone ${this.phone} saved successfully!<br />`;
          } else {
            this.acknowledgeMessage = "New Phone saving failure: ";
            this.snackbarMessage += `<p style="color:darkred">New Phone saving failure.</p>`;

            this.showErrorMessage = true;

            ok = false;
          }

          this.showSnackbar = true;
        } else {
          this.message = result.message;

          this.acknowledgeMessage = `New Phone saving failure: ${this.message}`;
          this.snackbarMessage += `<p style="color:darkred">New Phone saving failure: ${this.message}</p>`;

          this.showErrorMessage = true;
          this.showSnackbar = true;
        }

        this.phoneLoading = false;
      }
    },

    gotoViewMyAds() {
      this.$router.push(Config.ROUTE_MY_ADS);
    },

    async deleteAllMyAds() {
      let ok = false;
      this.showLoading();

      this.tokens = this.$store.state.tokens;
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      let userId = this.accessTokenDecoded.sub;

      ok = await this.deleteAllUserAds(userId);

      if (ok) {
        await this.getUserProfile();

        this.acknowledgeMessage = "Ads deleted ok!";
        this.showMyAcknowledge(true);
      }

      this.showDeleteAllMyAdsConfirm = false;
      this.showDelReason = false;

      this.hideLoading();

      return ok;
    },

    showMyAddress() {
      this.myAddressKey++;

      this.isShowMyAddress = true;
    },

    changeName() {
      const callback = encodeURIComponent(
        Config.APP_SERVER_IP + Config.ROUTE_LOGIN + "#tokens="
      );

      // 'http://localhost:8180/auth/realms/mobilityguruLocal/account?redirect_uri=callback'
      let url =
        this.$store.state.openid.providers[0].issuer +
        "/account?redirect_uri=" +
        callback;
      window.location = url;
    },

    async refreshToken() {
      await this.OIDCRefreshToken();
      this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
      this.printJson(this.accessTokenDecoded, "accessTokenDecoded a");
    },

    /* Updates a Keycloak user profile field.
     * - given_name
     * - family_name
     */
    async saveKeycloakDetail(field, value) {
      let ok = false;

      if ((await this.isLoggedInOIDC()) == true) {
        this.tokens = this.$store.state.tokens;
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        // eg. http://localhost:3000/profileUpdate?given_name="abracadabra"
        let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_PROFILE_UPDATE}?${field}="${value}"`;

        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          let jsonRes = await res.json();

          ok = jsonRes.ok;
          if (jsonRes.ok == true) {
            await this.refreshToken();
          }
        } catch (e) {
          this.printJson(e);
          this.message = e.error + " " + e.message;
          this.showErrorMessage = true;
        }
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }

      return ok;
    },

    // ok = true for green acknowledge thumbs up icon, false for red icon
    showMyAcknowledge(ok) {
      this.acknowledgeOk = ok;
      this.showAcknowledge = true;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    hideMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },

    /* For possible field values is the same as the comment for saveKeycloakDetail
     * The same as saveKeycloakDetail() but with showing of the acknowledge
     * dialog
     */
    async saveKeyCloakDetailWithSnackBar(field, value) {
      let fieldFriendly = "";

      if (field == "given_name") {
        this.disableSaveGivenName = true;
        this.givenNameLoading = true;
        fieldFriendly = "Given Name";
      } else if (field == "family_name") {
        this.disableSaveFamilyName = true;
        this.familyNameLoading = true;
        fieldFriendly = "Family Name";
      }

      /* 27/12/2021 since using 3rd party login it is easier if we don't allow changing
       * their email directly. They can always delete their account and reregister to change
       */
      /* else if (field == "email") {
        this.validEmail(value);
        this.disableSaveUsername = true;

        this.usernameLoading = true;
        this.sendVerifyEmailLoading = true;

        fieldFriendly = "Username";
      }
      */

      let ok = await this.saveKeycloakDetail(field, value);

      this.debug(`acknowledgeOk = ${this.acknowledgeOk}`);
      if (ok == true) {
        if (field === "email") {
          this.snackbarMessage = `<p>Ok: ${fieldFriendly} saved successfully!</p>`;
          this.showSnackbar = true;
        } else {
          this.snackbarMessageCenter += `Ok: ${fieldFriendly} saved successfully!<br />`;
        }
      } else {
        this.snackbarMessageCenter += `<p style="color:darkred">Error: ${fieldFriendly} failed to save!</p>`;

        if (field == "given_name") {
          this.disableSaveGivenName = false;
        } else if (field == "family_name") {
          this.disableSaveFamilyName = false;
        } else if (field == "email") {
          this.disableSaveUsername = false;
        }
      }
      if (field !== "email") {
        this.showSnackbarCenter = true;
      }

      if (field === "given_name") {
        this.givenNameLoading = false;
      } else if (field == "family_name") {
        this.familyNameLoading = false;
      } else if (field == "email") {
        this.usernameLoading = false;
        this.sendVerifyEmailLoading = false;
      }
    },

    async saveUsername() {
      let validEmail = this.validEmail(this.username);
      if (validEmail === true) {
        await this.saveKeyCloakDetailWithSnackBar("email", this.username);
        await this.sendVerifyEmailCodeFromProfile();
      } else {
        this.snackbarMessageCenter += `<p style="color:darkred">Error: failed to save! Invalid Email</p>`;
        this.showSnackbarCenter = true;
      }
    },

    async saveGivenName() {
      await this.saveKeyCloakDetailWithSnackBar("given_name", this.givenName);
    },

    async saveFamilyName() {
      await this.saveKeyCloakDetailWithSnackBar("family_name", this.familyName);
    },

    async saveMerchant() {
      if ((await this.isLoggedInOIDC()) == true) {
        this.disableSaveMerchant = true;
        this.merchantLoading = true;

        this.merchantOrig = this.merchant;

        let jsonObject = {
          merchant: this.merchant,
        };
        let result = await this.updateKintoProfile(jsonObject);
        let profile = result.profile;

        let ok = result.ok;

        if (ok == true && profile.merchant === this.merchant) {
          // add to store
          this.$store.commit("set", ["merchant", this.merchant]);

          this.snackbarMessage = `<p>New Merchant saved successfully!</p>`;
        } else {
          this.snackbarMessage =
            '<p style="color:darkred">New Merchant saved failure: ' +
            this.message +
            "!</p>";
        }

        this.merchantLoading = false;

        this.showSnackbar = true;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    userNameConfirmationChanged() {
      if (
        this.userNameConfirmation == this.accessTokenDecoded.preferred_username
      ) {
        this.disableDeleteUser = false;
      } else {
        this.disableDeleteUser = true;
      }
    },

    async sendVerifyEmailCodeFromProfile() {
      this.sendVerifyEmailLoading = true;
      if (this.verificationCodeValid) {
        this.verifyCodeLoading = true;
      }

      let ok = false;

      if ((await this.isLoggedInOIDC()) == true) {
        this.tokens = this.$store.state.tokens;
        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;

        this.snackbarMessage += "<p>Sending email verification code...</p>";
        this.showSnackbar = true;

        // eg. http://localhost:3000/keycloakSendVerifyEmailCode"
        let url = `${Config.NODE_SERVER_IP}${Config.SEND_VERIFY_EMAIL_CODE_FROM_PROFILE}`;
        this.debug(`url = ${url}`);
        let fetchData = {
          method: "GET",
          headers: {
            Authorization:
              this.accessTokenDecoded.typ + " " + this.tokens.access_token,
          },
        };

        try {
          let res = await fetch(url, fetchData);
          let jsonRes = await res.json();

          ok = jsonRes.ok;

          this.acknowledgeTitle = "Email Verification";
          if (jsonRes.ok == true) {
            this.snackbarMessage += `<p>Email verification code sent to ${this.accessTokenDecoded.preferred_username}. Check your your inbox or your spam mail for the code. Please login in and enter the code to start advertising</p>`;
          } else {
            console.error("sent email link failure");
            this.snackbarMessage +=
              '<p style="color:darkred">Email verification code sending failure!</p>';
          }

          this.showEmailVerificationCodeInput = true;
        } catch (e) {
          this.printJson(e);
          this.snackbarMessage += `<p style="color:darkred">${e.error} ${e.message}</p>`;
        }
      }

      this.showSnackbar = true;

      this.sendVerifyEmailLoading = false;
      this.verifyCodeLoading = false;
      return ok;
    },

    async verifyCode() {
      this.verifyCodeLoading = true;

      let ok = false;
      this.debug(`verificationCode = ${this.verificationCode}`);

      let id = this.accessTokenDecoded.sub;

      let url = `${Config.NODE_SERVER_IP}${Config.KEYCLOAK_VERIFY_EMAIL_CODE}?id=${id}&code=${this.verificationCode}`;

      let jsonRes = {};

      try {
        let res = await fetch(url);
        jsonRes = await res.json();

        ok = jsonRes.ok;
      } catch (e) {
        console.error(e);
      }
      this.printJson(jsonRes, "jsonRes");

      if (ok === true) {
        await this.OIDCRefreshToken();

        this.showEmailVerificationCodeInput = false;

        this.accessTokenDecoded = this.$store.state.accessTokenDecoded;
        this.printJson(this.accessTokenDecoded, "my accessTokenDecoded");

        this.snackbarMessage += `<p>${jsonRes.msg}</p>`;
      } else {
        this.snackbarMessage += `<p style="color:darkred;">Email Verification Failed </p>`; //${jsonRes.msg}
      }
      this.showSnackbar = true;

      this.verifyCodeLoading = false;
      return ok;
    },

    checkValidCode() {
      if (this.verificationCode.length == Config.EMAIL_VERIFICATION_LENGTH) {
        this.verificationCodeValid = true;
      } else if (
        this.verificationCode.length > Config.EMAIL_VERIFICATION_LENGTH
      ) {
        /* WHY doesn't this work!

        this.verificationCode = 
          this.verificationCode.substring(0, Config.EMAIL_VERIFICATION_LENGTH)
          */

        this.verificationCodeValid = false;
      } else {
        this.verificationCodeValid = false;
      }
      this.debug(`verificationCodeValid = ${this.verificationCodeValid}`);
    },

    async emailSubscriptionChanged() {
      if ((await this.isLoggedInOIDC()) == true) {
        this.emailSubscriptionLoading = true;

        let jsonObject = {
          emailSubscription: this.emailSubscription,
        };
        let result = await this.updateKintoProfile(jsonObject);
        let profile = result.profile;

        let ok = result.ok;
        if (
          ok === true &&
          profile.emailSubscription === this.emailSubscription
        ) {
          this.acknowledgeTitle = "Email Subscription Status";
          if (this.emailSubscription === true) {
            this.snackbarMessage += "Successfully Subscribed For Emails!<br />";
          } else {
            this.snackbarMessage += "Emails Unsubscribed Successfully!<br />";
          }
        } else {
          this.snackbarMessage +=
            '<p style="color:darkred">Email Subscription Status failure: ' +
            this.message +
            "</p>";
        }

        this.emailSubscriptionLoading = false;

        this.showSnackbar = true;
      } else {
        this.$router.push(Config.ROUTE_LOGIN);
      }
    },

    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarMessage = "";
    },

    closeSnackbarCenter() {
      this.showSnackbarCenter = false;
      this.snackbarMessageCenter = "";
    },

    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    async goToBannerAds() {
      await this.$router.push(Config.ROUTE_ROTATING_BANNER_SETUP);
    },

    closeMyAddressInternational() {
      this.isShowMyAddress = false;
      this.myAddressKey++;
    },

    closeMyPhone() {
      this.isShowMyPhone = false;
      this.myPhoneIndex++;
      this.myPhoneKey = `phone${this.myPhoneIndex}`;
    },

    showMyPhone() {
      this.isShowMyPhone = true;
      this.myPhoneIndex++;
      this.myPhoneKey = `phone${this.myPhoneIndex}`;
    },

    closeResetPW() {
      this.isShowResetPassword = false;
      this.resetPWIndex++;
      this.resetPWKey = `resetPW${this.resetPWIndex}`;
    },

    showResetPW() {
      this.isShowResetPassword = true;
      this.resetPWIndex++;
      this.resetPWKey = `resetPW${this.resetPWIndex}`;
    },

    showResetPWStatus(message, ok) {
      this.acknowledgeTitle = "Password Change Status";
      this.acknowledgeMessage = message;
      this.showMyAcknowledge(ok);
    },

    showLoading() {
      this.isLoading = true;
      this.loadingIndex++;
      this.loadingKey = `loading{this.loadingIndex}`;
    },

    hideLoading() {
      this.isLoading = false;
      this.loadingIndex++;
      this.loadingKey = `loading{this.loadingIndex}`;
    },
    async profileSubmitAd() {
      let res = await this.isSubmitAdAvailable();
      if (res.ok === true) {
        this.showAcknowledge = true;
        this.acknowledgeTitle = res.acknowledgeTitle;
        if (this.acknowledgeTitle == "Set Address") {
          this.$refs.setDivider.$el.scrollIntoView();
        } else if (this.acknowledgeTitle == "Email Unverified") {
          //  this.$refs.verify.$el.scrollIntoView();
          this.scrollToTop();
        }
        this.acknowledgeMessage = res.acknowledgeMessage;
        this.myAcknowledgeIndex++;
        this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
      }
    },

    closeMyAcknowledge() {
      this.showAcknowledge = false;
      this.myAcknowledgeIndex++;
      this.myAcknowledgeKey = `myAcknowledge${this.myAcknowledgeIndex}`;
    },
    async updateRotatingBannerAd(rotatingBannerAdUpdated) {
      this.rotatingBannerAd = rotatingBannerAdUpdated;
    },

    toggleShowEmailTip() {
      if (this.showChangeEmailTip) {
        this.showChangeEmailTip = false;
        this.toggleShowEmailTipBtnName = "change";
      } else {
        this.showChangeEmailTip = true;
        this.toggleShowEmailTipBtnName = "hidee";
      }
    },

    async goToMessages() {
      await this.pushRoute(Config.ROUTE_MESSAGES);
    },

    async goToSellerInvoices() {
      let query = {
        type: Config.invoiceTypes.SELLER,
      };
      await this.pushRoute(Config.ROUTE_INVOICES, query);
    },

    async goToBuyerInvoices() {
      let query = {
        type: Config.invoiceTypes.BUYER,
      };
      await this.pushRoute(Config.ROUTE_INVOICES, query);
    },

    async goToMyFavourites() {
      await this.pushRoute(Config.ROUTE_FAVOURITE_ADS);
    },
  }, // methods
};
</script>
