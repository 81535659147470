<template>
  <AdsListHelper :adType="adType" :userId="''"> </AdsListHelper>
</template>

<script>
import AdsListHelper from "./AdsListHelper";
import Config from "../config.js";

export default {
  name: "LiveAds",
  components: {
    AdsListHelper,
  },
  metaInfo: {
    title: "Mobilility Guru Ads",
    //titleTemplate: "%s | Public Ads",
    meta: [
      { name: "description", content: "Mobility Guru Live Public Ads" },
      { name: "keywords", content: "advertisements, ads, live, public" },
    ],
  },
  data: () => ({
    prevRoute: null,
    adType: "",
  }),

  mounted: async function () {
    this.init();
  },
  watch: {
    async $route() {
      await this.init();
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  methods: {
    init() {
      this.adType = Config.AD_TYPES_ENUM.PUBLIC_ADS;
      this.printJson(this.$route.query, "LiveAds query");
    },
  }, // methods
};
</script>
