<template>
  <ArticlesListHelper :articleType="articleType" :userId="''">
  </ArticlesListHelper>
</template>

<script>
import ArticlesListHelper from "./ArticlesListHelper";

import Config from "../config.js";

export default {
  name: "MyArticles",
  components: {
    ArticlesListHelper,
  },
  metaInfo: {},
  data: () => ({
    articleType: "",
  }),
  mounted: async function () {
    this.articleType = Config.ARTICLE_TYPES_ENUM.MY_ARTICLES;
    if ((await this.isLoggedInOIDC()) == false) {
      this.$router.push(Config.ROUTE_LOGIN);
    }
  },

  methods: {}, // methods
};
</script>
